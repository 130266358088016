import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { StringRegexConstants } from '@iapplication2/constants';
import { FieldTable, FormBuilderField, FormBuilderFieldTypeType, FormBuilderItem } from '@iapplication2/interfaces';
import { ApplicationsProcessService, CustomValidatorsService } from '@iapplication2/services';
import { StringValidator } from '@iapplication2/validators';
import _ = require('lodash');
import { Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-form-display-table',
  templateUrl: './form-display-table.component.html',
  styleUrls: ['./form-display-table.component.scss'],
})
export class FormDisplayTableComponent implements OnInit, OnDestroy {
  @Input() formTableItem: FieldTable;
  @ViewChild('groupContainer', { read: ViewContainerRef }) container;
  @Input() formFieldControl: FormControl;
  @Input() parentFormGroup: FormGroup;

  itemsInTable: FormBuilderItem[][];
  displayRequiredErrorMessage: boolean;
  isViewApplication: boolean;

  FormBuilderField;
  FormBuilderGroup;

  invalidValuesInLinkedColumnsMessage = '';

  FormControlType = FormControl;

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private customValidatorsService: CustomValidatorsService,
    private applicationsProcessService: ApplicationsProcessService
  ) {}

  ngOnInit(): void {
    this.checkViewApplicationDisplay();
    this.setInitialTableValidators();
    this.sortTableColumnsBasedOnNumber();
    this.subscribeToFormValueChanges();
    this.setTableItems();
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  setInitialTableValidators(): void {
    /* Set validators for the fields in the first row and remove other required validators from the table if present */
    this.formTableItem.items.forEach((item) => {
      if (item?.table?.position?.row === 0) {
        if (item.groupOptions) {
          this.parentFormGroup.get(item?.groupOptions?.id?.toString())?.setValidators(Validators.required);
        } else {
          this.parentFormGroup.get(item?.id?.toString())?.setValidators([StringValidator.requiredNoOnlySpaces(), Validators.required]);
        }
      } else {
        if (item.groupOptions) {
          this.parentFormGroup.get(item?.groupOptions?.id?.toString())?.removeValidators(Validators.required);
        } else {
          this.parentFormGroup.get(item?.id?.toString())?.removeValidators([StringValidator.requiredNoOnlySpaces(), Validators.required]);
        }
      }
    });
  }

  watchInvalidRows() {
    const rows = this.formTableItem?.items[this.formTableItem?.items?.length - 1]?.table?.position?.row;

    if (rows) {
      for (let row = 1; row <= rows; row++) {
        const itemsInRow = [];

        this.formTableItem.items.forEach((item) => {
          if (item?.table?.position?.row === row) {
            itemsInRow.push(item);
          }
        });

        /* Check if the row has any fields with value */
        let valuePresentOnRow = false;
        itemsInRow.forEach((item) => {
          if (item.groupOptions) {
            if (this.parentFormGroup.get(item?.groupOptions?.id?.toString())?.value) valuePresentOnRow = true;
          } else {
            if (this.parentFormGroup.get(item?.id?.toString())?.value) valuePresentOnRow = true;
          }
        });

        if (valuePresentOnRow === false) {
          /* Clear validators for each item in row, if no values are present */

          itemsInRow.forEach((item) => {
            if (item.groupOptions) {
              this.parentFormGroup.get(item?.groupOptions?.id?.toString())?.clearValidators();
              this.parentFormGroup.get(item?.groupOptions?.id?.toString())?.setErrors(null);
            } else {
              this.parentFormGroup.get(item?.id?.toString())?.clearValidators();
              this.parentFormGroup.get(item?.id?.toString())?.setErrors(null);
            }
          });
        } else {
          /* Add validators for each item in row, if values are present */

          itemsInRow.forEach((item) => {
            if (item.groupOptions) {
              this.parentFormGroup.get(item?.groupOptions?.id?.toString())?.setValidators(Validators.required);
              if (!this.parentFormGroup.get(item?.groupOptions?.id?.toString())?.value)
                this.parentFormGroup.get(item?.groupOptions?.id?.toString())?.setErrors({ required: true });
            } else {
              this.parentFormGroup.get(item?.id?.toString())?.setValidators([StringValidator.requiredNoOnlySpaces(), Validators.required]);
              if (!this.parentFormGroup.get(item?.id?.toString())?.value)
                this.parentFormGroup.get(item?.id?.toString())?.setErrors({ required: true });
            }
          });
        }
      }
    }
  }

  watchDisplayErrorMessage() {
    this.displayRequiredErrorMessage = false;
    this.formTableItem.items.forEach((item) => {
      if (item.groupOptions) {
        if (this.parentFormGroup.get(item?.groupOptions?.id?.toString()).invalid) {
          this.displayRequiredErrorMessage = true;
        }
      } else {
        if (this.parentFormGroup.get(item?.id?.toString())?.invalid) {
          this.displayRequiredErrorMessage = true;
        }
      }
    });
  }

  private subscribeToFormValueChanges(): void {
    const initialValue = this.parentFormGroup.value;

    this.parentFormGroup.valueChanges.pipe(startWith(initialValue), takeUntil(this.unsubscribe), debounceTime(500)).subscribe({
      next: (currentValue) => {
        Object.keys(initialValue).forEach((key) => {
          this.checkForLinkedColumns(key);
          if (
            _.isEqual(currentValue, initialValue) ||
            this.parentFormGroup.value[key] !== initialValue[key] ||
            this.parentFormGroup.value[key] === key ||
            this.parentFormGroup.value[key] === ''
          ) {
            if (this.parentFormGroup.value[key] || this.parentFormGroup.value[key] === '') {
              this.valueChangedForItem(key);
            } else this.resetRequiredByRowForItemsInSameRowWithItem(key);
          }
        });

        this.watchInvalidRows();
        this.watchDisplayErrorMessage();
      },
    });

    this.parentFormGroup.updateValueAndValidity();
  }

  private sortTableColumnsBasedOnNumber(): void {
    this.formTableItem.columns.sort((a, b) => a.number - b.number);
  }

  checkForLinkedColumns(key: string) {
    const itemWithId: FormBuilderField = this.getItemWithId(key);

    if (itemWithId?.fieldType?.type === FormBuilderFieldTypeType.NUMBER) {
      const columnNumber = itemWithId.table.position.column.number;

      if (this.formTableItem.columns[columnNumber]?.linkedWith) {
        const valuesInColumn: string[] = this.getFormGroupValuesFromColumn(columnNumber);
        const valuesInLinkedColumn: string[] = this.getFormGroupValuesFromLinkedColumnOfColumnWithProvidedNumber(columnNumber);

        const possibleValuesOfLinkedColumn: string[] = this.getPossibleValuesOfLinkedColumn(
          this.formTableItem.columns[columnNumber].linkedWith?.id.toString()
        );

        this.invalidValuesInLinkedColumnsMessage = this.checkIfValuesInLinkedColumnsAreValid(
          valuesInColumn,
          valuesInLinkedColumn,
          possibleValuesOfLinkedColumn,
          itemWithId
        );
      }
    }
  }

  getPossibleValuesOfLinkedColumn(linkedColumnId: string): string[] {
    const possibleValuesOfLinkedColumn: string[] = this.formTableItem.items
      .filter((field: FormBuilderField) => field.table.position.column.id.toString() === linkedColumnId.toString())?.[0]
      ?.fields?.map((field: FormBuilderField) => field.options.customFieldLabel);

    return possibleValuesOfLinkedColumn ? possibleValuesOfLinkedColumn : [];
  }

  checkIfValuesInLinkedColumnsAreValid(
    valuesInColumn: string[],
    valuesInLinkedColumn: string[],
    possibleValuesOfLinkedColumn: string[],
    itemWithId: FormBuilderItem
  ): string {
    let sum1 = 0;
    let sum2 = 0;

    valuesInColumn.forEach((value, index) => {
      if (value && valuesInLinkedColumn[index]) {
        if (this.getLabelByFieldId(valuesInLinkedColumn[index]) === possibleValuesOfLinkedColumn[0]) {
          sum1 += parseInt(value);
        }
        if (this.getLabelByFieldId(valuesInLinkedColumn[index]) === possibleValuesOfLinkedColumn[1]) {
          sum2 += parseInt(value);
        }
      }
    });

    const relevantControl = this.parentFormGroup.controls[itemWithId.id];

    if (!this.isViewApplication)
      this.setShareErrors(relevantControl);

    if (valuesInColumn?.find((value) => value === '0')) {
      return 'The share % cannot be 0';
    }

    if (valuesInColumn?.find((value: string) => parseInt(value) < 0)) {
      return 'The share % cannot be negative';
    }

    if (sum1 || sum2) {
      if (sum1 && sum2) {
        if (sum1 === 100 && sum2 === 100) {
          this.resetShareErrors(relevantControl);
          return '';
        } else if (sum1 === 100)
          if (sum2 < 100) {
            return `There is still ${100 - sum2}% missing for ${possibleValuesOfLinkedColumn[1]}`;
          } else return `There is ${sum2 - 100}% too much for ${possibleValuesOfLinkedColumn[1]}`;
        else if (sum2 === 100)
          if (sum1 < 100) return `There is still ${100 - sum1}% missing for ${possibleValuesOfLinkedColumn[0]}`;
          else return `There is ${sum1 - 100}% too much for ${possibleValuesOfLinkedColumn[0]}`;
        else if (sum1 < 100) {
          if (sum2 < 100) {
            return `There is still ${100 - sum1}% missing for ${possibleValuesOfLinkedColumn[0]} and ${100 - sum2}% missing for ${
              possibleValuesOfLinkedColumn[1]
            }
              `;
          } else
            return `There is still ${100 - sum1}% missing for ${possibleValuesOfLinkedColumn[0]} and ${sum2 - 100}% too much for ${
              possibleValuesOfLinkedColumn[1]
            }
            `;
        } else if (sum2 < 100) {
          return `There is ${sum1 - 100}% too much for ${possibleValuesOfLinkedColumn[0]} and ${100 - sum2}% missing for ${
            possibleValuesOfLinkedColumn[1]
          }
            `;
        } else
          return `There is ${sum1 - 100}% too much for ${possibleValuesOfLinkedColumn[0]} and ${sum2 - 100}% too much for ${
            possibleValuesOfLinkedColumn[1]
          }
          `;
      }
      if (sum1 < 100 && sum1 > 0) return `There is still ${100 - sum1}% missing for ${possibleValuesOfLinkedColumn[0]}`;
      if (sum1 > 100) return `There is ${sum1 - 100}% too much for ${possibleValuesOfLinkedColumn[0]}`;
      if (sum2 < 100 && sum2 > 0) return `There is still ${100 - sum2}% missing for ${possibleValuesOfLinkedColumn[1]}`;
      if (sum2 > 100) return `There is ${sum2 - 100}% too much for ${possibleValuesOfLinkedColumn[1]}`;

      this.resetShareErrors(relevantControl);
      return '';
    } else {
      this.resetShareErrors(relevantControl);
      return '';
    }
  }

  setShareErrors(control: AbstractControl) {
    const oldErrors = control?.errors;
    control?.setErrors({
      ...oldErrors,
      share: true,
    });
    control?.markAsDirty();
  }

  resetShareErrors(control: AbstractControl) {
    if (control?.value) {
      const newErrors = control.errors;
      delete newErrors?.['share'];
      if (_.isEqual(newErrors, {})) {
        control.setErrors(null);
      } else {
        control.setErrors(newErrors);
      }
    }
  }

  resetRow(rowNumber: number) {
    const idsOfFieldsInRow: string[] = [];
    const idsOfGroupsInRow: string[] = [];
    this.formTableItem?.items?.forEach((item: FormBuilderItem) => {
      if (item?.id) {
        if ((item as FormBuilderField).table?.position?.row === rowNumber) {
          idsOfFieldsInRow.push(item.id.toString());
        }
      } else {
        if (item.table?.position?.row === rowNumber) {
          idsOfGroupsInRow.push(item.groupOptions.id.toString());
        }
        item.fields?.forEach((field: FormBuilderField) => {
          if (field.table?.position?.row === rowNumber) {
            idsOfFieldsInRow.push(field.id.toString());
          }
        });
      }
    });

    idsOfFieldsInRow.forEach((id: string) => {
      if (
        this.parentFormGroup?.controls[id] &&
        ((this.parentFormGroup?.controls[id] && this.parentFormGroup?.controls[id]?.value !== null) ||
          this.parentFormGroup?.controls[id]?.value !== undefined ||
          this.parentFormGroup?.controls[id]?.value !== '')
      ) {
        this.parentFormGroup?.controls[id]?.setValue('');
        this.parentFormGroup?.controls[id]?.markAsUntouched();
        this.parentFormGroup?.controls[id]?.markAsPristine();
        this.parentFormGroup?.controls[id].setErrors(null);
      }
    });
    idsOfGroupsInRow.forEach((id: string) => {
      if (
        this.parentFormGroup?.controls[id] &&
        (this.parentFormGroup?.controls[id]?.value !== null ||
          this.parentFormGroup?.controls[id]?.value !== undefined ||
          this.parentFormGroup?.controls[id]?.value !== '')
      ) {
        this.parentFormGroup?.controls[id].setValue('');
        this.parentFormGroup?.controls[id].markAsUntouched();
        this.parentFormGroup?.controls[id].markAsPristine();
        this.parentFormGroup?.controls[id].setErrors(null);
      }
    });
  }

  valueChangedForItem(id: string) {
    const itemWithId = this.getItemWithId(id);
    this.formTableItem.items.forEach((item) => {
      if (item.table?.position.row === itemWithId?.table?.position.row) {
        if (item.groupOptions) {
          this.parentFormGroup.controls[item.groupOptions.id]?.setValidators([
            this.customValidatorsService.radioGroupRequired(item.groupOptions?.id?.toString()),
          ]);
          const errorsBeforeAddingRadioGroupRequired = this.parentFormGroup.controls[item.groupOptions.id]?.errors;
          if (this.parentFormGroup?.value[item.groupOptions.id]?.toString() === item.groupOptions.id.toString()) {
            item.requiredByTableRow = true;
            this.parentFormGroup.controls[item.groupOptions.id].setErrors({
              radioGroupRequired: true,
            });
          } else {
            item.requiredByTableRow = false;
            this.parentFormGroup.controls[item.groupOptions.id].setErrors(errorsBeforeAddingRadioGroupRequired);
          }
          if (!this.parentFormGroup.value[item.groupOptions.id]) {
            item.requiredByTableRow = false;
            this.parentFormGroup.controls[item.groupOptions.id].setErrors(errorsBeforeAddingRadioGroupRequired);
          }
        } else {
          if (
            !this.parentFormGroup.value[item.id] ||
            this.parentFormGroup.value[item.id] === '' ||
            StringRegexConstants.ONLY_SPACES.test(this.parentFormGroup.value[item.id])
          ) {
            item.requiredByTableRow = true;
          } else item.requiredByTableRow = false;
        }
      }
    });
  }

  resetRequiredByRowForItemsInSameRowWithItem(id: string) {
    const itemWithId = this.getItemWithId(id);
    const foundItemInRowWithValue = this.formTableItem.items.some((item) => {
      if (item.table?.position.row === itemWithId?.table?.position.row) {
        if (this.parentFormGroup.value[item.id]) {
          return item;
        }
      }
    });
    if (!foundItemInRowWithValue) {
      this.formTableItem.items.forEach((item) => {
        if (item.table?.position.row === itemWithId?.table?.position.row) {
          item.requiredByTableRow = false;
        }
      });
    }
  }

  getItemWithId(id: string) {
    return this.formTableItem.items.filter((item: FormBuilderItem) =>
      item.groupOptions ? item.groupOptions.id.toString() === id.toString() : item.id.toString() === id.toString()
    )[0];
  }

  getFormGroupValuesFromColumn(columnNumber: number): string[] {
    const values: string[] = [];
    this.formTableItem.items.forEach((item) => {
      if (item.table.position.column.number === columnNumber) {
        if (item.groupOptions) values.push(this.parentFormGroup.value[item.groupOptions.id]?.toString());
        else values.push(this.parentFormGroup.value[item.id]?.toString());
      }
    });
    return values;
  }

  getFormGroupValuesFromLinkedColumnOfColumnWithProvidedNumber(columnNumber: number) {
    const idOfLinkedColumn = this.formTableItem.columns[columnNumber].linkedWith?.id;
    const columnNumberOfLinkedColumn = Number(this.getColumnNumberFromColumnId(idOfLinkedColumn));
    return this.getFormGroupValuesFromColumn(columnNumberOfLinkedColumn);
  }

  getColumnNumberFromColumnId(id: number): number {
    let columnNumberOfLinkedColumn;
    this.formTableItem.items.forEach((item) => {
      if (item.table.position.column.id === id) {
        columnNumberOfLinkedColumn = item.table.position.column.number;
      }
    });
    return columnNumberOfLinkedColumn;
  }

  setTableItems() {
    this.itemsInTable = new Array(this.formTableItem.numberOfRows)
      .fill(null)
      .map(() => new Array(this.formTableItem.columns.length).fill(null));
    this.formTableItem.items.forEach((item) => {
      this.itemsInTable[item.table?.position.row][item.table?.position.column.number] = item;
    });
  }

  getLabelByFieldId(id: string): string {
    let label: string;
    this.formTableItem.items.forEach((item) => {
      if (item.fields) {
        item.fields.forEach((field) => {
          if (field.id.toString() === id) {
            label = field.options.customFieldLabel;
          }
        });
      }
    });
    return label;
  }

  counter(i: number) {
    return new Array(i);
  }

  getFormFieldControl() {
    return <FormControl>this.formFieldControl;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
