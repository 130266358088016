import { FormBuilderFieldTypeType } from './form-builder-field-type-option.model';
import { FormBuilderFieldValidatorConfiguration } from './form-builder-field-validator-configuration.model';

export interface FormBuilderFieldValidator {
  id?: number;
  label: string;
  info: string;
  type: FormBuilderFieldTypeType;
  isConfigurable: boolean;
  configuration?: FormBuilderFieldValidatorConfiguration[];
  unique: boolean;
  isManual: boolean;
  validatorKey?: string;
  [key: string]: any;
}
