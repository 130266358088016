import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { QuickQuotePageComponent } from './quick-quote-page/quick-quote-page.component';
import { FormFieldErrorModule } from '@iapplication2/ui';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { QuickQuoteFormComponent } from './quick-quote-page/quick-quote-page-sections/quick-quote-form/quick-quote-form.component';
import { QuickQuoteProductsComponent }
  from './quick-quote-page/quick-quote-page-sections/quick-quote-products/quick-quote-products.component';
import { QuickQuoteManagementListComponent } from './quick-quote-management/quick-quote-management-list.component';
import { QuickQuoteManagementPageComponent } from './quick-quote-management-page/quick-quote-management-page.component';
import { PipesModule } from '@iapplication2/pipes';
import { DirectivesModule } from '@iapplication2/directives';

@NgModule({
    imports: [
        CommonModule,
        FormFieldErrorModule,
        FormsModule,
        ReactiveFormsModule,
        CheckboxModule,
        ButtonModule,
        RadioButtonModule,
        DropdownModule,
        CalendarModule,
        TableModule,
        ToastModule,
        TooltipModule,
        ConfirmPopupModule,
        SkeletonModule,
        MultiSelectModule,
        MenuModule,
        SharedTranslateModule,
        PipesModule,
        DirectivesModule,
        RouterModule.forChild([
            {
                path: '',
                component: QuickQuoteManagementPageComponent
            },
            {
                path: 'new',
                component: QuickQuotePageComponent
            },
            {
                path: 'quick-quote/:id',
                component: QuickQuotePageComponent
            }
        ])
    ],
  declarations: [
    QuickQuotePageComponent,
    QuickQuoteFormComponent,
    QuickQuoteProductsComponent,
    QuickQuoteManagementListComponent,
    QuickQuoteManagementPageComponent,
  ],
})
export class QuickQuoteModule {}
