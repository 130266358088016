<div class="col-12 px-0">
  <div class="card policyManagement-table">
    <h5>{{ 'applicationManagement.policiesTable.title' | translate }}</h5>
    <p-table
      #dt
      [columns]="selectedCols"
      frozenWidth="110px"
      [value]="policies"
      dataKey="id"
      styleClass="p-datatable-customers"
      [rowHover]="true"
      (onLazyLoad)="loadApplications($event)"
      [lazy]="true"
      [loading]="loading"
      [paginator]="true"
      [totalRecords]="totalRecords"
      [rows]="rows"
      [rowsPerPageOptions]="rowsPerPage"
      [globalFilterFields]="['premium']"
      [scrollable]="true"
      [style]="{ width: '100%' }"
    >
      <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between table-header">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="filterGlobal($any($event.target).value)"
              [placeholder]="'applicationManagement.applicationsTable.globalSearch' | translate"
            />
          </span>
          <p-multiSelect
            [options]="scrollableCols"
            [(ngModel)]="selectedCols"
            optionLabel="header"
            [selectedItemsLabel]="'applicationManagement.applicationsTable.chooseColumns' | translate: { number: '{0}' }"
            [style]="{ minWidth: '200px' }"
            (onChange)="selectedColsChanged()"
            [placeholder]="'applicationManagement.applicationsTable.chooseColumns' | translate"
          >
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="frozenheader" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th pFrozenColumn>{{ 'applicationManagement.applicationsTable.actions' | translate }}</th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter
              [matchModeOptions]="matchModeOptions"
              type="text"
              [field]="col.field"
              display="menu"
              class="ml-auto"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-policy let-editing="editing" let-ri="rowIndex" let-columns="columns">
        <tr class="p-selectable-row" #unfrozenRows [pEditableRow]="policy">
          <td pFrozenColumn style="max-width: 79px">
            <div>
              <p-button icon="pi pi-cog" class="p-button-secondary" (click)="toggleMenu(policy, menu, $event)"></p-button>
              <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
            </div>
          </td>
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="output">
                <div *ngIf="col.field === 'id'">{{ policy.id }}</div>
                <div *ngIf="col.field === 'leadId'">
                  <p-tag [value]="policy.leadId"></p-tag>
                </div>
                <div *ngIf="col.field === 'client'">
                  <a [routerLink]="['/clients', 'client', policy.client.id]">
                    {{ policy.client.firstName }}
                    {{ policy.client.lastName }}
                  </a>
                </div>
                <div *ngIf="col.field === 'premium'">{{ policy.currency?.symbol }}{{ policy?.premium }} {{ policy.currency?.name }}/m</div>
                <div *ngIf="col.field === 'appointment'">
                  <a [routerLink]="['/applications', 'application', policy.appointment]">
                    {{ policy.appointment }}
                  </a>
                </div>
                <div *ngIf="col.field === 'products'">
                  <iapplication2-product-list-display></iapplication2-product-list-display>
                </div>
                <div *ngIf="col.field === 'emailedBy'">
                  <div *ngIf="policy.emailedBy">
                    {{ policy.emailedBy.firstName }}
                    {{ policy.emailedBy.lastName }}
                  </div>
                  <p-tag *ngIf="!policy.emailedBy" value="Not emailed" severity="info"></p-tag>
                </div>
                <div *ngIf="col.field === 'emailedDate'">
                  <div *ngIf="policy.emailedDate">
                    {{ policy.emailedDate | date }}
                  </div>
                  <p-tag *ngIf="!policy.emailedDate" value="Not emailed" severity="info"></p-tag>
                </div>
                <div *ngIf="col.field === 'viewed'">
                  <p-tag *ngIf="policy.viewed" value="Yes" severity="success"></p-tag>
                  <p-tag *ngIf="!policy.viewed" value="No" severity="warning"></p-tag>
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">{{ 'applicationManagement.policiesTable.notFound' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
