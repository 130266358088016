import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { Application, DtcPolicy, Policy, envType, ENV } from '@iapplication2/interfaces';
import { DashboardService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-dashboard-applications-widget',
  templateUrl: './dashboard-applications-widget.component.html',
  styleUrls: ['./dashboard-applications-widget.component.scss'],
})
export class DashboardApplicationsWidgetComponent implements OnInit, OnDestroy {
  recentApplications: Application[];
  loadingApplications: boolean;

  recentPolicies: Policy[];
  loadingPolicies: boolean;

  isEnvDevelop: boolean;

  recentDtcApplications: DtcPolicy[];
  loadingDtcApplications: boolean;

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(private dashboardService: DashboardService, @Inject(APP_CONFIG) public appConfig: ENV) {}

  ngOnInit(): void {
    this.loadRecentApplicationsFromBacked();
    this.loadRecentPoliciesFromBackend();
    this.loadRecentDtcPoliciesFromBackend();
    this.checkIfEnvIsDevelop();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  checkIfEnvIsDevelop() {
    this.isEnvDevelop = this.appConfig.environmentType === envType.DEVELOP;
  }

  loadRecentApplicationsFromBacked() {
    this.loadingApplications = true;
    this.dashboardService
      .getRecentApplications()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.recentApplications = res?.data;
        this.loadingApplications = false;
      });
  }

  loadRecentPoliciesFromBackend() {
    this.loadingPolicies = true;
    this.dashboardService
      .getRecentPolicies()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.recentPolicies = res.data;

        this.loadingPolicies = false;
      });
  }

  loadRecentDtcPoliciesFromBackend() {
    this.loadingDtcApplications = true;
    this.dashboardService
      .getRecentDtc()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.recentDtcApplications = res.data;
        this.loadingDtcApplications = false;
      });
  }
}
