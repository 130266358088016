export interface ApplicationFormData {
  id?: number;
  // TODO: Remove formId after changes are done and flow works with productFormId
  productFormId: number;
  fieldId: number;
  fieldTypeId: number;
  value: string;
  fillValue?: any;
}

export enum ApplicationFormPdfResponseType {
  ALL = 'all',
  LINK = 'link',
  OBJECT = 'object',
}
