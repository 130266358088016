import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilderItem } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-display-conditions-custom-dropdown-field',
  templateUrl: './display-conditions-custom-dropdown-field.component.html',
  styleUrls: ['./display-conditions-custom-dropdown-field.component.scss'],
})
export class DisplayConditionsCustomDropdownFieldComponent implements OnInit, OnChanges {
  @Input() item: FormBuilderItem;
  @Input() isHorizontal: boolean;
  displayLabel: string;

  ngOnInit(): void {
    this.createDisplayLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item.currentValue !== changes.item.previousValue) {
      this.createDisplayLabel();
    }
  }

  private createDisplayLabel(): void {
    this.displayLabel = this.item.groupOptions?.id ? this.item.groupOptions.name : this.item.options.customFieldLabel;
  }
}
