import { Component } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RegistryComponent {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function register(id: string) {
  return (cls: any) => {
    REGISTRY.set(id, cls);
    return cls as typeof cls;
  };
}
type ComponentClass = new () => Component;

const REGISTRY = new Map<string, ComponentClass>();

export function getTypeFor(name: string): ComponentClass {
  return REGISTRY.get(registryEnum[name]);
}

export enum registryEnum {
  freeText = 'freetext',
  checkbox = 'checkbox',
  dropdown = 'dropdown',
  signature = 'signature',
  date = 'date',
  number = 'number',
  table = 'table',
  radioGroup = 'radiogroup',
  checkboxGroup = 'checkboxgroup',
  disclosure = 'disclosure',
  image = 'image',
  pdf = 'pdf',
  disclaimer = 'disclaimer',
}
