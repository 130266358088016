export interface Notification {
  id?: number;
  userId: number;
  text: string;
  timestamp: Date;
  isRead: boolean;
  hasLink: boolean;
  link?: string;
}

// {
//   "id": 1,
//   "userId": 1,
//   "text": "test",
//   "timestamp": "2022-01-03T22:00:00.000Z",
//   "isRead": true,
//   "hasLink": true,
//   "link": "applications/application/11"
// }
