<div class="details-field-header flex justify-content-between">
  <h4 class="details-field-header-title">
    {{ fieldGroup.groupOptions.type.type !== FormBuilderFieldTypeTypeEnum.DISCLOSURE ? 'Group' : 'Disclosure group' }} details
  </h4>
</div>
<form [formGroup]="mainForm">
  <div formGroupName="groupForm">
    <div class="details-field-content-property my-2" fxLayout="row">
      <div class="details-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">
        {{ fieldGroup.groupOptions.type.type !== FormBuilderFieldTypeTypeEnum.DISCLOSURE ? 'Group' : 'Disclosure' }} name:
      </div>
      <div class="details-field-content-property-content" fxLayoutAlign="start center">
        <div *ngIf="!isInEditMode || (fieldGroup?.fields && fieldGroup?.fields[0]?.predefinedFieldTableId)">
          {{ fieldGroup?.groupOptions.name }}
        </div>
        <div *ngIf="isInEditMode && !(fieldGroup?.fields && fieldGroup?.fields[0]?.predefinedFieldTableId)">
          <input
            style="width: 100%"
            type="text"
            class="p-inputtext p-component"
            pInputText
            [placeholder]="fieldGroup.groupOptions.type.type !== FormBuilderFieldTypeTypeEnum.DISCLOSURE ? 'Group name' : 'Disclosure name'"
            formControlName="name"
            (input)="groupNameInputValueChanged()"
          />
        </div>
      </div>
    </div>
    <div class="details-field-content-property my-2" fxLayout="row">
      <div class="details-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">
        {{ fieldGroup.groupOptions.type.type !== FormBuilderFieldTypeTypeEnum.DISCLOSURE ? 'Group' : 'Disclosure' }} type:
      </div>
      <div class="details-field-content-property-content fieldType-chip" [ngClass]="fieldGroup?.groupOptions?.type?.type">
        {{ fieldGroup?.groupOptions?.type?.label }}
      </div>
    </div>
  </div>
</form>

<div class="group-options mt-5">
  <div class="group-options-header" fxLayout="row" fxLayoutAlign="start center">
    <h5>
      This <span *ngIf="fieldGroup.groupOptions.type.type !== FormBuilderFieldTypeTypeEnum.DISCLOSURE">group</span>
      <span *ngIf="fieldGroup.groupOptions.type.type === FormBuilderFieldTypeTypeEnum.DISCLOSURE">disclosure</span> contains the following
      options
    </h5>
    <i
      *ngIf="fieldGroup?.groupOptions?.type?.type === 'radioGroup' || fieldGroup?.groupOptions?.type?.type === 'checkboxGroup'"
      class="pi pi-exclamation-circle ml-2 mb-3"
      pTooltip="This group type requires at least 2 options to be added for it to
        be used."
    ></i>
  </div>
  <div class="group-options-content">
    <div>
      <div *ngFor="let field of fieldGroup?.fields">
        <div
          *ngIf="!field.toBeDeleted"
          class="card p-custom-shadow-3 group-options-field my-2 pr-3 pl-4 py-2"
          [pTooltip]="
            field.options.fieldDetails === FieldDetailsOptionsEnum.MARKED_AS_DEFAULT
              ? 'This is the default option'
              : field.options.fieldDetails === FieldDetailsOptionsEnum.MARKED_AS_REQUIRED
              ? 'This is the required option'
              : field.options.fieldDetails === requiredAndDefault || field.options.fieldDetails === defaultAndRequired
              ? 'This is both the required and the default option'
              : ''
          "
          [class.default-group-option]="field.options.fieldDetails === FieldDetailsOptionsEnum.MARKED_AS_DEFAULT"
          [class.required-group-option]="field.options.fieldDetails === FieldDetailsOptionsEnum.MARKED_AS_REQUIRED"
          [class.required-and-default-group-option]="
            field.options.fieldDetails === requiredAndDefault || field.options.fieldDetails === defaultAndRequired
          "
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div fxLayout="column" class="option-content">
            <div class="my-2" *ngIf="!field.isGroupOptionInEdit">
              {{
                (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
                  | translate: { Default: field?.options?.customFieldLabel }
              }}
            </div>
            <div *ngIf="field.isGroupOptionInEdit">
              <form class="p-inputgroup" [formGroup]="fieldLabelForm">
                <input class="p-inputtext p-component" pInputText formControlName="label" type="text" />
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-check"
                  styleClass="p-button-warn"
                  (click)="saveOptionChange(field)"
                  [disabled]="fieldLabelForm.get('label').errors"
                ></button>
              </form>
              <p class="mt-2" *ngIf="fieldLabelForm.get('label').errors?.duplicateFieldAndGroupOptionLabel">
                <em> This option name is already in use.</em>
              </p>
              <p class="mt-2" *ngIf="fieldLabelForm.get('label').errors?.required">
                <em> The option name is required.</em>
              </p>
            </div>
          </div>
          <button
            *ngIf="isInEditMode && !isGroupFromTable"
            pButton
            pRipple
            icon="pi pi-cog"
            (click)="toggleMenu(field, fieldMenu, $event)"
            class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
          ></button>
        </div>
      </div>
      <!-- REFACTOR -->
      <div
        fxLayout="column"
        fxLayoutAlign="start start"
        *ngIf="!isGroupFromTable && modalType !== modalTypeEnum.EDIT"
        style="margin-left: 60px"
      >
        <form [formGroup]="newOptionForm" *ngIf="isInEditMode">
          <input
            class="p-inputtext p-component mt-2"
            pInputText
            placeholder=""
            formControlName="label"
            (input)="newOptionFormInputValueChanged()"
            type="text"
            label="Add new option"
          />
        </form>
        <p class="mt-2" *ngIf="newOptionForm.get('label').errors?.duplicateFieldAndGroupOptionLabel">
          <em> This option name is already in use.</em>
        </p>
        <button
          *ngIf="isInEditMode"
          class="p-button p-button-text my-2"
          (click)="addOption()"
          [disabled]="!formValidity || !newOptionForm.get('label').value"
        >
          Add option
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isInModal || isInGroupOptionEdit">
  <p-dialog
    *ngIf="isFieldDialogOpen"
    [modal]="true"
    [(visible)]="isFieldDialogOpen"
    [style]="{ width: '40vw', minWidth: '600px' }"
    showEffect="fade"
  >
    <iapplication2-field-settings-modal
      [selectedFormBuilderItem]="selectedField"
      [modalType]="settingsDialogOptions"
      [isInFieldBuilder]="isInFieldBuilder"
      (closeDialog)="closeFieldEditDialog()"
    >
    </iapplication2-field-settings-modal>
  </p-dialog>
</div>

<p-menu #fieldMenu [popup]="true" appendTo="body" [model]="fieldItems"></p-menu>
<p-menu #newOptionsMenu [popup]="true" appendTo="body" [model]="fieldItems"></p-menu>
