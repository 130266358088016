<div fxLayout="column" class="flex align-items-center">
  <p>{{ 'admin.sectionManager.description' | translate }}</p>
  <p-messages></p-messages>
  <div
    #cdkDroplistElement
    cdkDropList
    cdkDropListData="formSectionsList"
    (cdkDropListDropped)="drop($event)"
    class="form-sections-list-container flex"
    fxLayout="column"
    [style]="{ height: scrollableAreaHeight + 'px' }"
  >
    <div
      *ngFor="let formSection of formSectionsList; let i = index"
      cdkDrag
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="card section-card mb-3 p-2"
      [ngStyle]="{ maxHeight: formSection.hasSectionType ? '100px' : '70px' }"
      [class.card-error-border]="formSection.title === ''"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
        <span cdkDragHandle>
          <img
            [src]="isDarkTheme ? './assets/custom/icons/drag-icon-light.svg' : './assets/custom/icons/drag-icon.svg'"
            class="drag-icon"
          />
        </span>
        <div fxLayout="column">
          <p-inplace [closable]="true" closeIcon="pi pi-check" class="ml-2 mt-1" fxFlex>
            <ng-template pTemplate="display">
              <span *ngIf="formSection.title; else emptyTitle">
                <b class="sub-section-title">{{ formSection.title }}</b>
                <i class="pi pi-pencil ml-2" style="font-size: 0.8rem"></i>
              </span>
              <ng-template #emptyTitle>{{ 'admin.sectionManager.emptyTitle' | translate }}</ng-template>
            </ng-template>
            <ng-template pTemplate="content">
              <input
                type="text"
                [(ngModel)]="formSection.title"
                fxFlex="100"
                pInputText
                placeholder="{{ 'admin.sectionManager.sectionTitlePlaceholder' | translate }}"
              />
            </ng-template>
          </p-inplace>
          <div class="ml-3 mt-2">
            <p-checkbox
              [(ngModel)]="formSection.hasSectionType"
              [binary]="true"
              id="sectionType"
              (onChange)="changedSectionTypeCheckbox($event, formSection)"
            ></p-checkbox>
            <label for="sectionType" class="ml-2">{{ 'admin.sectionManager.isSectionTypeTitle' | translate }}</label>
          </div>
          <div *ngIf="formSection.hasSectionType" class="ml-3 mt-2">
            <label for="sectionTypeSelection">{{ 'admin.sectionManager.whatTypeIsSection' | translate }}</label>
            <p-dropdown
              [options]="sectionTypes"
              placeholder="{{ 'admin.sectionManager.selectTypePlaceholder' | translate }}"
              [(ngModel)]="formSection.type"
              optionLabel="name"
              id="sectionTypeSelection"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-trash"
        class="p-button-rounded p-button-text p-button-danger"
        (click)="removeSection(i, $event)"
      ></button>
    </div>
  </div>
  <div fxFlex fxLayout="row" class="mt-2">
    <button
      pButton
      label="{{ 'admin.sectionManager.addSection' | translate }}"
      icon="pi pi-plus"
      class="p-button-text"
      (click)="addNewSection()"
    ></button>
    <p class="mb-1 mt-2" *ngIf="displayRevertButton">
      <em>
        <a (click)="restoreItems()" class="cursor-pointer">
          <i class="pi pi-replay" style="font-size: 10px"></i> {{ 'admin.sectionManager.restoreItems' | translate }}
        </a>
      </em>
    </p>
  </div>
</div>
<p-confirmPopup></p-confirmPopup>
