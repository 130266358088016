import { FieldDisclosureDetails } from './field-disclosure-details.model';
import { FormBuilderFieldGroupOptions } from './form-builder-field-group-options.model';
import { FormBuilderFieldTypeOption } from './form-builder-field-type-option.model';
import { FieldValidators, FormBuilderField } from './form-builder-field.model';

export interface FormBuilderGroup {
  groupOptions: FormBuilderFieldGroupOptions;
  fields: FormBuilderField[];
  fieldDisclosureOption?: FieldDisclosureDetails;
  predefinedFieldDisclosureOption?: FieldDisclosureDetails;
  fieldTypeOptions?: FormBuilderFieldTypeOption[];
  [key: string]: any;
}

export class FormBuilderGroupType implements FormBuilderGroup {
  groupOptions: FormBuilderFieldGroupOptions;
  fields: FormBuilderField[];
  fieldValidators?: FieldValidators;
  fieldDisclosureOption?: FieldDisclosureDetails;
  predefinedFieldDisclosureOption?: FieldDisclosureDetails;
  [key: string]: any;
}
