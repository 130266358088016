import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iapplication2-application-management-page',
  templateUrl: './application-management-page.component.html',
  styleUrls: ['./application-management-page.component.scss']
})
export class ApplicationManagementPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
