/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV, FieldCategory } from '@iapplication2/interfaces';
import { ReplaySubject } from 'rxjs';
import { APP_CONFIG } from '@iapplication2/app-config';

@Injectable({
  providedIn: 'root',
})
export class FieldCategoryService {
  private _categories: FieldCategory[] = [];

  public get categories(): FieldCategory[] {
    return this._categories;
  }
  public set categories(value: FieldCategory[]) {
    this._categories = value;
  }

  fatchedCategories: ReplaySubject<FieldCategory[]> = new ReplaySubject();

  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  getFieldCategories() {
    if (!this.categories.length) this.setFetchedCategoriesToSubject();
  }

  setFetchedCategoriesToSubject() {
    this.http.get('predefined-items/predefinedCategories').subscribe((res: any) => {
      this.categories = [];
      res.forEach((category) => {
        this.categories[category.position - 1] = category;
      });
      this.fatchedCategories.next(this.categories);
    });
  }

  createFieldCategory(newCategory: FieldCategory) {
    this.http.post('predefined-items/predefinedCategory', newCategory).subscribe(() => {
      this.setFetchedCategoriesToSubject();
    });
  }

  checkIfCategoryWithProvidedTitleExists(title: string, currentValue: string): boolean {
    return this.categories.filter((category) => {
      return category.title.toUpperCase() == title.toUpperCase() && title.toUpperCase() != currentValue.toUpperCase();
    }).length
      ? true
      : false;
  }

  getCategoryFromSlug(slug: string): FieldCategory {
    return this.categories.filter((category) => category.slug == slug)[0];
  }

  updateCategory(category: FieldCategory) {
    this.http.put(`predefined-items/predefinedCategory/${category.id}`, category).subscribe();
  }

  updateCategories(categories: FieldCategory[]) {
    this.http.put('predefined-items/predefinedCategories', categories).subscribe();
  }

  deleteCategory(category: FieldCategory) {
    this.http.delete(`predefined-items/predefinedCategory/${category.id}`).subscribe((res) => {
      if (res) {
        this.setFetchedCategoriesToSubject();
      }
    });
    // return this.http.delete(`${this.url}/${category.id}`);
  }
}
