<div>
  <p-table
    [value]="quickQuotes"
    [lazy]="true"
    [scrollable]="true"
    [loading]="loading"
    scrollHeight="200px"
    [autoLayout]="true"
    #customtable
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="med-col">
          {{ 'pages.globalSearch.sections.quickQuotes.table.columns.name' | translate }}
        </th>
        <th class="med-col">
          {{ 'pages.globalSearch.sections.quickQuotes.table.columns.sex' | translate }}
        </th>
        <th>
          {{ 'pages.globalSearch.sections.quickQuotes.table.columns.birthDate' | translate }}
        </th>
        <th>
          {{ 'pages.globalSearch.sections.quickQuotes.table.columns.smokerStatus' | translate }}
        </th>
        <th class="small-col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-quickQuote>
      <tr>
        <td
          class="med-col"
          [highlight]="searchedString"
          [innerHTML]="quickQuote.firstName + ' ' + quickQuote.middleName + ' ' + quickQuote.lastName"
        ></td>
        <td class="med-col" [highlight]="searchedString" [innerHTML]="quickQuote.sex"></td>
        <td class="med-col" [highlight]="searchedString" [innerHTML]="quickQuote.birthDate | date"></td>
        <td class="med-col" [highlight]="searchedString" [innerHTML]="quickQuote.smokerStatus"></td>
        <td class="small-col">
          <p-button icon="pi pi-cog" class="p-button-secondary" (click)="toggleMenu(quickQuote, menu, $event)"></p-button>
          <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="pt-3 pl-2">
    <a [routerLink]="['/quick-quotes']"
      >{{ 'pages.globalSearch.sections.quickQuotes.seeAll' | translate }} <i class="pi pi-arrow-right" style="font-size: 0.8rem"></i
    ></a>
  </div>
</div>
