<div class="p-2" fxFlex="100" [sortablejs]="formBuilderItems" [sortablejsOptions]="{ handle: '.field-handle' }">
  <div
    class="px-3 pb-1 pt-1"
    [ngClass]="isDarkTheme ? 'form-builder-sub-section-area-dark' : 'form-builder-sub-section-area'"
    fxFlex
    fxLayout="column"
  >
    <iapplication2-form-builder-area-header [formBuilderArea]="formArea" (deleteFormArea)="deleteArea($event)">
    </iapplication2-form-builder-area-header>
    <div
      cdkDropList
      [id]="formArea.id.toString()"
      [cdkDropListData]="formBuilderItems"
      [cdkDropListConnectedTo]="connectedTo"
      (cdkDropListDropped)="drop($event)"
      class="py-3 px-2"
    >
      <div *ngFor="let areaItem of formBuilderItems">
        <iapplication2-form-builder-item-display
          [formBuilderItem]="areaItem"
          [selectedCanvasObject]="selectedCanvasObject"
          [selectedFormField]="selectedFormField"
          [formAreaId]="formArea.id"
          (deletedFieldEvent)="deletedField($event)"
        >
        </iapplication2-form-builder-item-display>
      </div>
      <div *ngIf="!formArea.fields.length">Start dragging fields into this area</div>
    </div>
  </div>
</div>
