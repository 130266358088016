import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardNotificationsComponent } from './dashboard-notifications/dashboard-notifications.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule],
  declarations: [DashboardNotificationsComponent, NotificationCardComponent],
  exports: [DashboardNotificationsComponent, NotificationCardComponent],
})
export class NotificationsModule {}
