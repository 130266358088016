<b>{{ 'applicationProcess.questionnaire.requiredForProduct' | translate: { productName: product.name } }}</b>
<p-messages severity="success" *ngIf="isValid && processedQuestionnaire">
  <ng-template pTemplate>
    <div>{{ 'applicationProcess.questionnaire.validQuestionnaire' | translate }}</div>
  </ng-template>
</p-messages>
<p-messages severity="error" *ngIf="!isValid && processedQuestionnaire">
  <ng-template pTemplate>
    <div>{{ 'applicationProcess.questionnaire.invalidQuestionnaire' | translate }}</div>
  </ng-template>
</p-messages>
<div *ngFor="let section of product.questionnaireSections">
  {{ section.title }}
  <iapplication2-form-display-area
    *ngFor="let area of section.formAreas"
    [area]="area"
    [hiddenFieldIds]="product.hiddenFields"
    [areaForm]="area.areaForm"
    [isPreviewMode]="false"
    [showStaticText]="true"
    class="p-2"
  ></iapplication2-form-display-area>
</div>
