import { ProductStatus } from '@iapplication2/interfaces';
import { ProductAddon } from './product-addon.model';
import { ProductVersion } from './product-version.model';

export interface DisplayProduct {
  id: number;
  name: string;
  addons: ProductAddon[];

  //Added with optional not to crash FE
  productId?: number;
  premium?: string;
  coverage?: number;
  minCoverage?: number;
  maxCoverage?: number;
  withEverest?: boolean;
  payment?: number;
  selected?: boolean;
  requiresQuestionaire?: boolean;
  version?: ProductVersion;

  status?: ProductStatus;
  policyId?: string;
  pdf?: {
    [key: string]: string;
  };
}
