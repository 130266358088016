import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormBuilderField,
  FormBuilderStaticText,
  FormSection,
  ModalType,
  FormAreaWidthOptions,
  FormBuilderFieldTypeType,
  FieldTypeLabelOptions,
  FormBuilderItem,
} from '@iapplication2/interfaces';
import { CanvasService, FieldManagementService, FormManagementService, InteractiveFormBuilderService } from '@iapplication2/services';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iapplication2-form-builder-header',
  templateUrl: './form-builder-header.component.html',
  styleUrls: ['./form-builder-header.component.scss'],
})
export class FormBuilderHeaderComponent implements OnInit, OnDestroy {
  @Output() addNewArea: EventEmitter<FormAreaWidthOptions> = new EventEmitter<FormAreaWidthOptions>();
  @Output() sectionWasChanged: EventEmitter<FormSection> = new EventEmitter<FormSection>();
  @Input() formSectionsList: FormSection[];
  @Input() formId: number;

  selectedFormSection: FormSection;
  displayNewFieldsModal: boolean;
  addNewFieldsModalLocation = 'modal';
  selectedField: FormBuilderField;
  unsubscribe: Subject<void> = new Subject();
  @ViewChild('overlayPanel', { static: false }) overlayPanel;
  @ViewChild('staticText', { static: false }) staticText;
  sidebarAction = ModalType;
  displaySectionManager = false;

  staticTextContent: string;
  // TODO: Refactor this ↓
  newStaticTextField: FormBuilderStaticText = {
    content: '',
    fieldType: {
      id: 10,
      type: FormBuilderFieldTypeType.STATICTEXT,
      label: FieldTypeLabelOptions.STATIC_TEXT,
      dragToDraw: false,
      rgbaColorForRectangle: 'rgba(0, 0, 0, 0)',
      canBeDrawn: false,
    },
    options: {
      customFieldLabel: 'abc',
      customFieldName: 'abc2',
      visibleOnPdf: false,
    },
  };

  addFormAreaItems: MenuItem[] = [
    {
      label: 'Full width area',
      icon: 'pi pi-window-maximize',
      command: () => this.addFormArea(FormAreaWidthOptions.FULL),
    },
    {
      label: 'Half width area',
      icon: 'pi pi-window-minimize',
      command: () => this.addFormArea(FormAreaWidthOptions.HALF),
    },
  ];

  constructor(
    private sanitizer: DomSanitizer,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private fieldManagementService: FieldManagementService,
    private canvasService: CanvasService,
    private formManagementService: FormManagementService
  ) {}

  ngOnInit(): void {
    this.setInitialSection();
    this.displayNewFieldsModal = false;

    this.updateListWhenSectionListChanges();
    this.actOnSectionDeletion();
  }

  openAddNewItemOverlayPannel(event) {
    this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.FORM);
    this.overlayPanel.toggle(event);
  }

  private actOnSectionDeletion() {
    this.interactiveFormBuilderService.deletedSectionList.pipe(takeUntil(this.unsubscribe)).subscribe((data: FormSection[]) => {
      data.forEach((deletedSection) => {
        if (deletedSection.id) {
          this.interactiveFormBuilderService.deleteSectionById(deletedSection.id).pipe(takeUntil(this.unsubscribe)).subscribe();
        }
      });
    });
  }

  private updateListWhenSectionListChanges() {
    this.interactiveFormBuilderService.modifiedSectionList.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      const newSections = data.filter((section) => section.id === null);
      if (newSections.length > 0) {
        this.interactiveFormBuilderService
          .createSections(newSections)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res: FormSection[]) => {
            res.forEach((createdSection) => {
              data.forEach((existingSection, index) => {
                if (createdSection.sectionFormPosition === existingSection.sectionFormPosition && existingSection.id === null) {
                  data[index] = createdSection;
                }
              });
            });
            this.interactiveFormBuilderService
              .updateSections(data)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((res: FormSection[]) => {
                this.formSectionsList = res;
              });
          });
      } else {
        this.interactiveFormBuilderService
          .updateSections(data)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res: FormSection[]) => {
            this.formSectionsList = res;
            this.selectedFormSection = this.formSectionsList.find((section) => section.id === this.selectedFormSection.id);
            this.formManagementService.currentSectionId.next(this.selectedFormSection.id);
            this.sectionWasChanged.emit(this.selectedFormSection);
          });
      }
      this.displaySectionManager = false;
    });
  }

  changedSection(): void {
    this.sectionWasChanged.emit(this.selectedFormSection);
  }

  addFormArea(width: FormAreaWidthOptions): void {
    this.addNewArea.emit(width);
  }

  private setInitialSection(): void {
    this.selectedFormSection = this.formSectionsList[0];
    this.formManagementService.currentSectionId.next(this.selectedFormSection.id);
    this.sectionWasChanged.emit(this.selectedFormSection);
  }

  toggleDisplayNewFieldsModal(): void {
    this.displayNewFieldsModal = !this.displayNewFieldsModal;
  }

  fieldWasSelected(event: FormBuilderField): void {
    this.selectedField = event;
  }

  saveSectionManagement() {
    this.interactiveFormBuilderService.saveSectionManagement.next(void 0);
  }

  itemSelected(item: FormBuilderItem): void {
    this.overlayPanel.hide();
  }

  addStaticText(): void {
    this.newStaticTextField.content = this.staticTextContent;
    this.newStaticTextField.formId = this.canvasService.currentCanvasForm.id;

    this.fieldManagementService.createField(this.newStaticTextField).subscribe((res: FormBuilderField) => {
      this.newStaticTextField.id = res.id;
      this.newStaticTextField.options.id = res.options.id;
      this.newStaticTextField.sanitized = this.sanitizer.bypassSecurityTrustHtml(res.content);
    });
    this.staticTextContent = '';
    this.staticText.hide();
  }

  openSectionManagerModal() {
    this.displaySectionManager = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  onTextChange(event: any): void {
    this.staticTextContent = event.htmlValue;
  }
}
