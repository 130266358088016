/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArea, FormSection } from '@iapplication2/interfaces';
import { DynamicTopbarInformationService, InteractiveFormBuilderService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-form-container-page',
  templateUrl: './form-container-page.component.html',
  styleUrls: ['./form-container-page.component.scss'],
})
export class FormContainerPageComponent implements OnInit, OnDestroy {
  formSectionsList: FormSection[];
  formSection: FormSection;
  formId: string;
  sectionId: number;
  currentSectionFormGroup: FormGroup = new FormGroup({});
  isPreviewMode: boolean = false;

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private dynamicTopbarInformationService: DynamicTopbarInformationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isInPreviewMode();
    // this.formSectionsList = this.interactiveFormBuilderService.formSectionsList;
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
      this.formId = params.get('formId');
      this.sectionId = parseInt(params.get('sectionId'));
      this.dynamicTopbarInformationService.currentSectionIdForFormDisplay.next(this.sectionId);
      this.loadCurrentSection();
    });
    this.dynamicTopbarInformationService.displayDynamicInformation.next({
      displayInfo: true,
      displayComponent: 'formDisplay',
    });
  }

  isInPreviewMode() {
    this.route.pathFromRoot[2].url.pipe(takeUntil(this.unsubscribe)).subscribe((val) => {
      const test = val.some((e) => e.path.includes('preview'));
      if (test) {
        this.isPreviewMode = true;
        this.dynamicTopbarInformationService.displayDynamicInformation.next({
          displayInfo: true,
          displayComponent: 'formBuilder',
        });
      }
    });
  }

  loadCurrentSection() {
    if (!this.sectionId) {
      this.router.navigate(['section', this.formSectionsList[0].id], {
        relativeTo: this.route,
      });
    } else {
      this.getSectionDetails();
    }
  }

  getSectionDetails() {
    // this.formSection = this.interactiveFormBuilderService.getFormSectionById(this.formSectionsList.find((e) => e.id === this.sectionId));
    // this.createFormGroups();
  }

  getFormAreaWidthByFormArea(area: FormArea) {
    return area.width === 'full' ? 100 : 50;
  }

  private createFormGroups() {
    this.formSection.formAreas.forEach((area) => {
      this.currentSectionFormGroup.addControl(area.id.toString(), new FormGroup({}));
    });
  }

  getFormGroupByAreaId(areaId: string) {
    return <FormGroup>this.currentSectionFormGroup.controls[areaId];
  }

  ngOnDestroy(): void {
    this.dynamicTopbarInformationService.displayDynamicInformation.next({
      displayInfo: false,
      displayComponent: null,
    });
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
