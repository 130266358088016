<div class="p-3 disclosure-disclaimer-background" [class.playing-disclosure]="isDisclosurePlaying">
  <div fxLayout="row">
    <iapplication2-required
      *ngIf="!isViewApplication"
      [required]="(formFieldControl.errors?.required || formControl.errors?.disclosureRequired) && !group.fields?.[0]?.table"
      [disclosurePlayRequired]="formFieldControl.errors?.disclosureRequiredPlay"
    ></iapplication2-required>
    <div>
      <h5>{{ group.groupOptions.name }}</h5>
    </div>
  </div>

  <iapplication2-see-more [text]="disclosureDetails?.disclosureText" [length]="250"></iapplication2-see-more>
  <div
    *ngIf="disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.radioGroup"
    class="max-w-max"
    [pTooltip]="
      !disclosureSuccessfullyPlayed
        ? ('applicationProcess.staticApplicationDisclosures.humania.tooltipMessage.disclosureNotPlayed' | translate)
        : ''
    "
    tooltipPosition="bottom"
  >
    <div
      class="mb-2 mt-2 radio-group-option"
      [class.displayReadonlyValue]="isReadonly"
      *ngFor="let field of group.fields"
      [formGroup]="parentFormGroup"
    >
      <div
        fxLayout="row"
        class="pl-2"
        [class.requiredOption]="field.isRequiredDisclosureOption && showWarningText"
        [class.displayReadonlyValue]="isReadonly"
      >
        <iapplication2-required
          *ngIf="!isViewApplication"
          [optionRequired]="field.isRequiredDisclosureOption && showWarningText"
        ></iapplication2-required>
        <p-radioButton
          [value]="field.id"
          class="mr-1"
          [formControlName]="formControlName"
          [class.isReadOnlyRadioButton]="isReadonly"
          [tabindex]="tabIndex"
          [disabled]="isViewApplication"
        ></p-radioButton>
        {{
          (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
            | translate: { Default: field?.options?.customFieldLabel }
        }}
      </div>
    </div>
  </div>
  <div
    *ngIf="disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.checkboxGroup"
    class="max-w-max"
    [pTooltip]="
      !disclosureSuccessfullyPlayed
        ? ('applicationProcess.staticApplicationDisclosures.humania.tooltipMessage.disclosureNotPlayed' | translate)
        : ''
    "
    tooltipPosition="bottom"
  >
    <div
      class="mb-2 mt-2 checkbox-group-option"
      *ngFor="let field of group.fields"
      [formGroup]="parentFormGroup"
      [class.displayReadonlyValue]="isReadonly"
    >
      <div
        fxLayout="row"
        class="pl-2"
        [class.requiredOption]="field.isRequiredDisclosureOption && showWarningText"
        [class.displayReadonlyValue]="isReadonly"
      >
        <iapplication2-required
          *ngIf="!isViewApplication"
          [optionRequired]="field.isRequiredDisclosureOption && showWarningText"
        ></iapplication2-required>
        <p-checkbox
          [value]="field.id"
          class="mr-1"
          [formControl]="formControl"
          [class.isReadOnlyRadioButton]="isReadonly"
          [tabindex]="tabIndex"
          [disabled]="isViewApplication"
          (onChange)="onCheckboxChange()"
        ></p-checkbox>
        {{
          (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
            | translate: { Default: field?.options?.customFieldLabel }
        }}
      </div>
    </div>
  </div>
  <span
    tooltipPosition="bottom"
    [pTooltip]="
      disclosuresService.isAnyDisclosureDisclaimerPlaying
        ? ('applicationProcess.step.applicationForms.displayItem.disclosureDisclaimerAlreadyPlaying' | translate)
        : ''
    "
  >
    <button
      *ngIf="!isViewApplication"
      pButton
      (click)="toggleDisclosurePlaying()"
      [loading]="isDisclosurePlaying"
      [disabled]="disclosuresService.isAnyDisclosureDisclaimerPlaying"
      [label]="
        isDisclosurePlaying
          ? ('applicationProcess.step.applicationForms.displayItem.disclosure.playingDisclosure' | translate)
          : ('applicationProcess.step.applicationForms.displayItem.disclosure.playDisclosure' | translate)
      "
    ></button>
  </span>
  <button
    class="p-button-danger ml-3"
    *ngIf="isDisclosurePlaying"
    pButton
    (click)="toggleDisclosurePlaying()"
    [label]="'applicationProcess.step.applicationForms.displayItem.disclosure.stopDisclosure' | translate"
  ></button>
  <p-messages severity="warn" *ngIf="showWarningText && disclosureDetails?.warningText && !isViewApplication">
    <ng-template pTemplate>
      <div class="ml-2">{{ disclosureDetails?.warningText }}</div>
    </ng-template>
  </p-messages>
</div>
