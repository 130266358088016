import { Application } from './advisor-application/application.model';
import { ClientLanguage } from './advisor-application/client-language.model';
import { Policy } from './advisor-application/policy.model';
import { Country, Province } from './address.model';
export interface Client {
  id: number;
  applicationClientId?: number;
  advisorId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  language: ClientLanguage;
  email: string;
  birthDate: string;
  smokerStatus: string;
  phoneNumber: string;
  address: string;
  countryOfBirth: Country;
  province: Province;
  postalCode: string;
  city?: string;
  note?: string;
  title?: string;
  gender?: string;
  reflexSmokerStatus?: boolean;
  [key: string]: any;
}

export enum ClientFormEnum {
  TITLE = 'title',
  GENDER = 'gender',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  LANGUAGE = 'language',
  EMAIL = 'email',
  BIRTH_DATE = 'birthDate',
  SMOKER_STATUS = 'smokerStatus',
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY_OF_BIRTH = 'countryOfBirth',
  ADDRESS = 'address',
  PROVINCE = 'province',
  CITY = 'city',
  POSTAL_CODE = 'postalCode',
  NOTE = 'note',
  REFLEX_SMOKER_STATUS = 'reflexSmokerStatus',
  HUMANIA_TITLE = 'humaniaTitle',
}
