import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  FormArea,
  FormBuilderItem,
  FormSection,
  Product,
  FieldTypeEnum,
  ApplicationFormData,
  FormBuilderField,
} from '@iapplication2/interfaces';
import { FormFieldConditionsService, ApplicationsProcessService, InteractiveFormBuilderService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import _ = require('lodash');
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-product-questionnaire',
  templateUrl: './product-questionnaire.component.html',
  styleUrls: ['./product-questionnaire.component.css'],
})
export class ProductQuestionnaireComponent implements OnInit, OnChanges, OnDestroy {
  @Input() product: Product;
  @Input() productQuestionnaireForm: FormGroup;
  @Input() changeTriggered: number;
  @Output() medicalQuestionnaireChanged: EventEmitter<boolean> = new EventEmitter();
  FormGroupType = FormGroup;
  isValid: boolean;
  processedQuestionnaire = false;
  unsubscribe: Subject<unknown> = new Subject();
  @Input() formDataObject;
  selectedLanguage;
  @Input() applicationId: number;
  @Input() listOfItems: FormBuilderItem[] = [];

  handleSaveDebounced = _.debounce(() => this.handleSave(), 1000);

  constructor(
    private formFieldConditionsService: FormFieldConditionsService,
    private translate: TranslateService,
    private applicationsProcessService: ApplicationsProcessService,
    private interactiveFormBuilderService: InteractiveFormBuilderService
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.medicalQuestionnaireChanged.emit(true);
    this.watchFormChanges();
    this.prepareAreaForms().subscribe({
      next: () => {
        this.fillFormWithData(this.product.productFormId.toString());
        this.productQuestionnaireForm
          .get(this.product.productFormId.toString())
          .valueChanges.pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: () => {
              if (this.productQuestionnaireForm.dirty) {
                this.handleSaveDebounced();
              }
              this.watchFormChanges();
            },
          });
      },
    });
    this.interactiveFormBuilderService.itemValueUpdated.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      if (res.productFormId === this.product.productFormId || res?.fields?.[0].productFormId === this.product.productFormId) {
        this.triggerPredefinedLink(res);
      }
    });
  }

  private fillFormWithData(productFormId: string) {
    if (!this.formDataObject[productFormId]) {
      if (!this.formDataObject) {
        this.formDataObject = {};
      }
      this.formDataObject[productFormId] = [];
    }

    const formData = this.formDataObject[productFormId];
    if (formData) {
      formData.forEach((item) => {
        item.fieldId = parseInt(item.fieldId?.toString());
      });
      // this.applicationsProcessService.applicationFormDataArray.push(...formData);
      this.addFormDataToApplicationFormDataArray(formData);
      Object.values((this.productQuestionnaireForm.get(productFormId) as FormGroup).controls).forEach((section) => {
        Object.values((section as FormGroup).controls).forEach((area) => {
          Object.entries((area as FormGroup).controls).forEach((item) => {
            const itemValue = this.formDataObject[productFormId].filter((valueItem) => (valueItem.fieldId as unknown) == item[0])[0];
            if (itemValue) {
              itemValue.fillValue = itemValue.value;
              if (itemValue.fieldTypeId === FieldTypeEnum.date) {
                itemValue.fillValue = new Date(itemValue.fillValue);
              }
              if (itemValue.fieldTypeId === FieldTypeEnum.checkboxGroup) {
                itemValue.fillValue = itemValue.fillValue.split(',').map((value) => parseInt(value));
              }
              item[1].setValue(itemValue.fillValue, { emitEvent: false });
            }
          });
        });
      });
    }
    // if (itemValue.fieldTypeId === FieldTypeEnum.checkbox) {
    //   itemValue.fillValue = parseInt(itemValue.fillValue) === 0 ? false : parseInt(itemValue.fillValue) === 1 ? true : null;
    // }

    this.watchFormChanges();
  }

  addFormDataToApplicationFormDataArray(formData) {
    formData.forEach((elementFromFormData) => {
      if (!this.applicationsProcessService.applicationFormDataArray.includes(elementFromFormData)) {
        this.applicationsProcessService.applicationFormDataArray.push(elementFromFormData);
      }
    });
  }

  handleSave() {
    // TODO: Refactor bellow to work with multipel languages after changing the form object on the product
    const allFormsToSave = Object.entries(this.productQuestionnaireForm.controls);
    allFormsToSave.forEach((formControl) => {
      const currentProductFormId = formControl[0];
      const allSectionsFromForm = Object.values((formControl[1] as FormGroup).controls);
      allSectionsFromForm.forEach((sectionControl) => {
        const allAreasFromSection = Object.values((sectionControl as FormGroup).controls);
        allAreasFromSection.forEach((areaControl) => {
          const allFieldsFromArea = Object.entries((areaControl as FormGroup).controls);
          allFieldsFromArea.forEach(([fieldId, fieldControl]: [string, FormControl]) => {
            const indexOfFieldAndProductFormId = this.getDataArrayIndexByFieldIdAndProductFormId(fieldId, currentProductFormId);
            const value = this.getValueFromControl(fieldControl);
            const preparedValue = this.prepareValue(value);

            if (value || (typeof value === 'number' && (value as number) === 0)) {
              if (indexOfFieldAndProductFormId === -1) {
                this.handleAddNewValueToFormDataArray(fieldId, currentProductFormId, preparedValue);
              } else {
                this.handleUpdateExistingValueInFormDataArray(indexOfFieldAndProductFormId, currentProductFormId, preparedValue);
              }
            } else {
              if (value === '') {
                if (indexOfFieldAndProductFormId !== -1) {
                  this.handleRemoveFieldValue(indexOfFieldAndProductFormId);
                }
              }
            }
          });
        });
      });
    });

    this.applicationsProcessService.applicationFormDataArray = this.getNonNullValuesFromFormDataArray(
      this.applicationsProcessService.applicationFormDataArray
    );
    this.saveProcessedData();
  }

  private handleRemoveFieldValue(index: number) {
    const dataForFieldIndex = this.applicationsProcessService.applicationFormDataArray.findIndex(
      (data: ApplicationFormData) =>
        data?.fieldId.toString() === this.applicationsProcessService.applicationFormDataArray[index].value.toString()
    );
    if (dataForFieldIndex !== -1 && this.applicationsProcessService.applicationFormDataArray[dataForFieldIndex]) {
      this.applicationsProcessService.applicationFormDataArray[dataForFieldIndex].value = '';
    }
    this.applicationsProcessService.applicationFormDataArray[index].value = '';
  }

  private getDataArrayIndexByFieldIdAndProductFormId(fieldId: string, productFormId: string): number {
    return this.applicationsProcessService.applicationFormDataArray.findIndex(
      (element) => element?.productFormId === parseInt(productFormId) && element?.fieldId === parseInt(fieldId)
    );
  }

  private handleAddNewValueToFormDataArray(fieldId: string, currentProductFormId: string, value: string) {
    const fieldTypeId = this.getFieldTypeIdFromFieldId(fieldId);
    if (fieldTypeId === FieldTypeEnum.radioGroup || fieldTypeId === FieldTypeEnum.checkboxGroup) {
      this.handleAddNewGroupValueToFormDataArray(currentProductFormId, fieldTypeId, value);
    }
    this.handleAddNewFieldValueToFormDataArray(fieldId, currentProductFormId, fieldTypeId, value);
  }

  private handleUpdateExistingValueInFormDataArray(index: number, currentProductFormId: string, value: string) {
    const fieldTypeId = this.getFieldTypeIdFromFieldId(value);
    const oldFieldTypeId = this.getFieldTypeIdFromFieldId(this.applicationsProcessService.applicationFormDataArray[index]?.value);

    if (this.applicationsProcessService.applicationFormDataArray[index].value !== value) {
      if (
        fieldTypeId === FieldTypeEnum.radioGroup ||
        fieldTypeId === FieldTypeEnum.checkboxGroup ||
        oldFieldTypeId === FieldTypeEnum.radioGroup ||
        oldFieldTypeId === FieldTypeEnum.checkboxGroup
      ) {
        this.handleRemovePreviousGroupValueFromDataArray(
          this.applicationsProcessService.applicationFormDataArray[index].value,
          value,
          currentProductFormId
        );
      }

      if (this.applicationsProcessService.applicationFormDataArray[index]) {
        this.applicationsProcessService.applicationFormDataArray[index].value = value;
      }
    }
  }

  private handleRemovePreviousGroupValueFromDataArray(oldFieldId: string, newFieldId: string, currentProductFormId: string) {
    if (oldFieldId.includes(',') || newFieldId.includes(',')) {
      const allOldFields = oldFieldId.split(',');
      const allNewFields = newFieldId.split(',');

      const fieldsToBeRemoved = allOldFields.filter((fieldIdToBeRemoved: string) => !allNewFields.includes(fieldIdToBeRemoved));

      fieldsToBeRemoved.forEach((fieldIdToBeRemoved: string) => {
        const indexOfFieldToBeRemoved = this.applicationsProcessService.applicationFormDataArray.findIndex(
          (data: ApplicationFormData) =>
            data?.fieldId.toString() === fieldIdToBeRemoved.toString() && data?.productFormId.toString() === currentProductFormId.toString()
        );

        this.handleUpdateExistingValueInFormDataArray(indexOfFieldToBeRemoved, currentProductFormId, '');
      });

      const fieldsToBeAdded = allNewFields.filter((fieldIdToBeAdded: string) => !allOldFields.includes(fieldIdToBeAdded));

      fieldsToBeAdded.forEach((fieldIdToBeAdded: string) => {
        const index = this.applicationsProcessService.applicationFormDataArray.findIndex(
          (data: ApplicationFormData) =>
            data?.fieldId.toString() === allOldFields[0].toString() && data?.productFormId.toString() === currentProductFormId.toString()
        );
        this.handleAddNewFieldValueToFormDataArray(
          fieldIdToBeAdded,
          currentProductFormId,
          this.applicationsProcessService.applicationFormDataArray[index].fieldTypeId,
          'x'
        );
      });
    } else {
      const index = this.applicationsProcessService.applicationFormDataArray.findIndex(
        (data: ApplicationFormData) =>
          data?.fieldId?.toString() === oldFieldId?.toString() &&
          data?.productFormId.toString() === currentProductFormId.toString() &&
          data?.fieldId?.toString() !== data?.value?.toString()
      );
      this.handleAddNewGroupValueToFormDataArray(currentProductFormId, this.getFieldTypeIdFromFieldId(newFieldId), newFieldId);
      if (index !== -1) {
        this.handleUpdateExistingValueInFormDataArray(index, currentProductFormId, '');
      }
    }
  }

  private handleAddNewGroupValueToFormDataArray(currentProductFormId, fieldTypeId, value: string) {
    const items: FormBuilderItem[] = this.getItemsArrayByValue(value);
    items.forEach((item) => {
      this.handleAddNewFieldValueToFormDataArray(item.id, currentProductFormId, fieldTypeId, 'x');
    });
  }

  private handleAddNewFieldValueToFormDataArray(fieldId: string, currentProductFormId: string, fieldTypeId: number, value: string) {
    this.applicationsProcessService.applicationFormDataArray.push({
      productFormId: parseInt(currentProductFormId),
      fieldId: parseInt(fieldId),
      fieldTypeId: fieldTypeId,
      value: value,
    });
  }

  private getItemsArrayByValue(value: string): FormBuilderItem[] {
    const splitValue = value.split(',');
    const items: FormBuilderItem[] = [];
    splitValue.forEach((valuePart) => {
      const item = this.listOfItems.find((item) => item.id === parseInt(valuePart));
      if (item) {
        items.push(item);
      }
    });
    return items;
  }

  private getValueFromControl(control: FormControl): string {
    return control.value !== null && control.value !== undefined ? control.value : null;
  }

  private getOneIdIfListContainsMultiple(fieldId: string): string {
    return fieldId.toString().includes(',') ? fieldId.toString().split(',')[0] : fieldId.toString();
  }

  private getFieldTypeIdFromFieldId(fieldId: string): number {
    if (fieldId) {
      fieldId = this.getOneIdIfListContainsMultiple(fieldId);

      const field = this.listOfItems.find((item) => item.id?.toString() === fieldId?.toString());
      if (field) {
        return field.fieldType.id;
      }
      const group = this.listOfItems.find((item) => item.groupOptions?.id.toString() === fieldId?.toString());
      if (group) {
        return group.fieldType.id;
      }
    }
    return null;
  }

  private prepareValue(value: any): string {
    switch (true) {
      case typeof value === 'number':
        return value.toString();
      case Array.isArray(value):
        return value.toString();
      default:
        return value;
    }
  }

  private getNonNullValuesFromFormDataArray(formDataArray: ApplicationFormData[]): ApplicationFormData[] {
    return formDataArray.filter((formData) => formData?.value !== null && formData?.value !== undefined);
  }

  private saveProcessedData() {
    this.applicationsProcessService
      .saveFormData(this.applicationId, this.applicationsProcessService.applicationFormDataArray)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: ApplicationFormData[]) => {
        this.applicationsProcessService.applicationFormDataArray = this.processFieldIds(res);
      });
  }

  private processFieldIds(data: ApplicationFormData[]): ApplicationFormData[] {
    data?.forEach((dataItem) => {
      if (typeof dataItem?.fieldId === 'string') {
        dataItem.fieldId = parseInt(dataItem.fieldId);
      }
    });
    return data;
  }

  prepareAreaForms(): Observable<unknown> {
    return new Observable((observer: Observer<unknown>) => {
      this.product.questionnaireSections?.forEach((questionnaireSection: FormSection) => {
        questionnaireSection.formAreas?.forEach((formArea: FormArea) => {
          formArea.areaForm = this.productQuestionnaireForm
            .get(this.product.productFormId.toString())
            .get(questionnaireSection.id.toString())
            .get(formArea.id.toString());
        });
      });
      observer.next(true);
      observer.complete();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.watchFormChanges();
      this.prepareAreaForms().subscribe({
        next: () => {
          this.fillFormWithData(this.product.productFormId.toString());
        },
      });
    }
  }

  private watchFormChanges(): void {
    const allFields: FormBuilderItem[] = [];

    this.product?.questionnaireSections?.[0]?.formAreas?.forEach((area) => {
      area.fields?.forEach((field) => {
        allFields.push(field);
      });
    });
    this.checkFieldsConditions(allFields);
  }

  private checkFieldsConditions(fields: FormBuilderItem[]) {
    fields.forEach((field) => {
      if (field.conditions) {
        if (!this.formFieldConditionsService.checkFieldConditions(field.conditions, this.productQuestionnaireForm, field.productFormId)) {
          if (!this.product.hiddenFields.includes(field.id)) {
            this.product.hiddenFields = [...this.product.hiddenFields, field.id];
            this.formFieldConditionsService
              .getFieldFormControl(field.id, this.productQuestionnaireForm, field.productFormId)
              ?.disable({ emitEvent: false });
          }
        } else {
          if (this.product.hiddenFields.includes(field.id)) {
            this.product.hiddenFields = this.product.hiddenFields.filter((hiddenField: number) => hiddenField !== field.id);
            this.formFieldConditionsService
              .getFieldFormControl(field.id, this.productQuestionnaireForm, field.productFormId)
              ?.enable({ emitEvent: false });
          }
        }
      }
    });

    this.medicalQuestionnaireChanged.emit(true);
  }

  triggerPredefinedLink(item: FormBuilderItem) {
    switch (true) {
      case !!item.groupOptions:
        this.mapPredefinedGroupValue(item);
        break;

      default:
        this.mapPredefinedFieldValue(item);
        break;
    }
  }

  private mapPredefinedFieldValue(item: FormBuilderItem) {
    const foundField = this.applicationsProcessService.allFieldsInAllForms.find(
      (field) => field?.id === item?.id && field?.productFormId === item?.productFormId
    );
    if (foundField?.predefinedFieldId) {
      const foundFieldValue = this.getFormControlForItemInSelectedProduct(foundField)?.value;
      this.applicationsProcessService.allFieldsInAllForms.forEach((field) => {
        if (
          field.predefinedFieldId === foundField.predefinedFieldId &&
          (field.id !== foundField.id || (field.id === foundField.id && field.productFormId !== foundField.productFormId))
        ) {
          const formControlsForFieldToSetValueTo = this.getFromControlForItemByProductFormId(field);

          if (formControlsForFieldToSetValueTo.value !== foundFieldValue) {
            formControlsForFieldToSetValueTo.setValue(foundFieldValue, {
              emitEvent: false,
            });
          }
        }
      });
    }
  }

  private getFromControlForItemByProductFormId(field: FormBuilderField): FormControl {
    const itemId = field.groupOptions ? field.groupOptions.id : field.id;

    return this.productQuestionnaireForm
      ?.get(field.productFormId.toString())
      ?.get(field.sectionId?.toString())
      ?.get(field.formAreaId?.toString())
      ?.get(itemId?.toString()) as FormControl;
  }

  private mapPredefinedGroupValue(item: FormBuilderItem) {
    const foundFieldInGroup = this.applicationsProcessService.allFieldsInAllForms.filter(
      (field) => field?.groupOptions?.id === item?.groupOptions?.id && field?.productFormId === item?.fields[0].productFormId
    )[0];
    if (foundFieldInGroup?.groupOptions.predefinedGroupId) {
      const allPredefinedGroupItemsWithSameId = this.applicationsProcessService.allFieldsInAllForms.filter(
        (field) =>
          (field.groupOptions?.predefinedGroupId === foundFieldInGroup.groupOptions.predefinedGroupId &&
            field.groupOptions?.id !== foundFieldInGroup.groupOptions.id) ||
          (field.groupOptions?.id === foundFieldInGroup.groupOptions.id && field.productFormId !== foundFieldInGroup.productFormId)
      );

      const foundGroupValue = this.getFormControlForItemInSelectedProduct(foundFieldInGroup)?.value;

      const uniquePredefinedGroupsFound = [];
      allPredefinedGroupItemsWithSameId.forEach((predefinedGroupItem) => {
        const elementExists = uniquePredefinedGroupsFound?.find(
          (uniquePredefinedGroup) =>
            uniquePredefinedGroup.groupOptions?.id === predefinedGroupItem.groupOptions?.id &&
            uniquePredefinedGroup.productFormId === predefinedGroupItem.productFormId
        );
        if (!elementExists) {
          uniquePredefinedGroupsFound.push(predefinedGroupItem);
        }
      });

      if (foundGroupValue !== foundFieldInGroup.groupOptions.id) {
        const predefinedFieldOptionIdsOfValue = this.getPredefinedIdsByFieldIds(foundGroupValue);

        uniquePredefinedGroupsFound.forEach((group) => {
          this.setGroupControlValue(group, predefinedFieldOptionIdsOfValue, foundGroupValue);
        });
      } else {
        uniquePredefinedGroupsFound.forEach((field: FormBuilderField) => {
          const otherGroupControls = this.getFromControlsById(field.groupOptions.id.toString());
          otherGroupControls.forEach((groupControl: FormControl) => {
            groupControl.setValue(field.groupOptions.id, { emitEvent: false });
          });
        });
        allPredefinedGroupItemsWithSameId.forEach((field: FormBuilderField) => {
          this.getFromControlsForItemInAllProducts(field).forEach((control) => {
            if (control.value === 'x') {
              control.setValue('', { emitEvent: false });
            }
          });
        });
      }
    }
  }

  private getFromControlsById(controlId: string) {
    const foundControls: FormControl[] = [];

    const allProductFormIds = Object.keys(this.productQuestionnaireForm.controls);

    allProductFormIds.forEach((productFormId) => {
      Object.values((this.productQuestionnaireForm.get(productFormId) as FormGroup).controls).forEach((section) => {
        Object.values((section as FormGroup).controls).forEach((area) => {
          Object.entries((area as FormGroup).controls).forEach((item) => {
            if (item[0] === controlId) {
              foundControls.push(item[1] as FormControl);
            }
          });
        });
      });
    });

    return foundControls;
  }

  private getPredefinedIdsByFieldIds(foundGroupValue): number[] {
    const predefinedFieldOptionIdsOfValue = [];
    if (Array.isArray(foundGroupValue)) {
      foundGroupValue.forEach((foundOptionId) => {
        predefinedFieldOptionIdsOfValue.push(
          this.applicationsProcessService.allFieldsInAllForms.find((field) => field?.id === foundOptionId)?.predefinedFieldId
        );
      });
    } else {
      predefinedFieldOptionIdsOfValue.push(
        this.applicationsProcessService.allFieldsInAllForms.find((field) => field?.id === foundGroupValue)?.predefinedFieldId
      );
    }
    return predefinedFieldOptionIdsOfValue;
  }

  private setGroupControlValue(group: FormBuilderField, predefinedFieldOptionIdsOfValue, foundGroupValue) {
    if (group.formAreaId && group.id && group.productFormId && group.sectionId) {
      const values = [];
      predefinedFieldOptionIdsOfValue.forEach((predefinedId) => {
        const fieldIdForPredefinedFieldInPredefinedGroup = this.applicationsProcessService.allFieldsInAllForms.find(
          (field) => field.predefinedFieldId === predefinedId && field.groupOptions.id === group.groupOptions.id
        )?.id;
        values.push(fieldIdForPredefinedFieldInPredefinedGroup);
      });
      const valueToSet = Array.isArray(foundGroupValue) ? values : values[0];

      const formControlsForFieldToSetValueTo = this.getFromControlsForItemInAllProducts(group);
      formControlsForFieldToSetValueTo.forEach((formControlForFieldToSetValueTo) => {
        if (
          (Array.isArray(foundGroupValue) && !_.isEqual(formControlForFieldToSetValueTo?.value, valueToSet)) ||
          (!Array.isArray(foundGroupValue) && formControlForFieldToSetValueTo?.value !== valueToSet)
        ) {
          formControlForFieldToSetValueTo?.setValue(valueToSet, { emitEvent: false });
        }
      });
    }
  }

  private getFromControlsForItemInAllProducts(field: FormBuilderField): FormControl[] {
    const itemId = field.groupOptions ? field.groupOptions.id : field.id;

    const allProductFormIds = Object.keys(this.productQuestionnaireForm.controls);

    const foundControls: FormControl[] = [];

    allProductFormIds.forEach((productFormId) => {
      const foundControl = this.productQuestionnaireForm
        ?.get(productFormId)
        ?.get(field.sectionId?.toString())
        ?.get(field.formAreaId?.toString())
        ?.get(itemId?.toString()) as FormControl;

      if (foundControl) {
        foundControls.push(foundControl);
      }
    });

    return foundControls;
  }

  private getFormControlForItemInSelectedProduct(field: FormBuilderField): FormControl {
    const itemId = field.groupOptions ? field.groupOptions.id : field.id;

    return this.productQuestionnaireForm
      ?.get(this.product.productFormId.toString())
      ?.get(field.sectionId?.toString())
      ?.get(field.formAreaId?.toString())
      ?.get(itemId?.toString()) as FormControl;
  }

  ngOnDestroy(): void {
    this.medicalQuestionnaireChanged.emit(true);
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
