import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequiredComponent } from './required.component';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { SharedTranslateModule } from '@iapplication2/shared-translate';

@NgModule({
  declarations: [RequiredComponent],
  imports: [CommonModule, BadgeModule, TooltipModule, SharedTranslateModule],
  exports: [RequiredComponent],
})
export class RequiredModule {}
