<ul *ngIf="displayInTable" [pTooltip]="columnContent">
  <li *ngFor="let product of applicationsProcessService.currentApplication?.products">
    <b>{{ product?.name }}</b>
  </li>
</ul>

<div *ngIf="!displayInTable" class="card">
  <h5>{{ 'viewApplicationPage.products' | translate }}</h5>
  <p-table
    [value]="applicationsProcessService.currentApplication?.products"
    [resizableColumns]="true"
    [autoLayout]="true"
    [scrollable]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'viewApplicationPage.name' | translate }}</th>
        <th>{{ 'viewApplicationPage.everest' | translate }}</th>
        <th>{{ 'viewApplicationPage.policyId' | translate }}</th>
        <th>{{ 'viewApplicationPage.coverage' | translate }}</th>
        <th>{{ 'viewApplicationPage.monthlyCoverage' | translate }}</th>
        <th>{{ 'viewApplicationPage.yearlyCoverage' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>{{ product?.lokaliseKey | translate }}</td>
        <td><i [ngClass]="product?.withEverest ? 'pi pi-check' : 'pi pi-times'"></i></td>
        <td>{{ product?.policyId || '-' }}</td>
        <td>{{ product?.coverage }}</td>
        <td>{{ product?.monthlyPremium | number: '1.2-2' }}</td>
        <td>{{ product?.yearlyPremium | number: '1.2-2' }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr>
        <td></td>
        <td></td>
        <td>{{ 'viewApplicationPage.total' | translate }}</td>
        <td>
          {{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ totalMonthlyPremium | number: '1.2-2' }} /
          {{ 'viewApplicationPage.month' | translate }}
        </td>
        <td>
          {{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ totalYearlyPremium | number: '1.2-2' }} /
          {{ 'viewApplicationPage.year' | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
