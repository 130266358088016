<div class="card quickQuoteProducts-table-container">
  <h4 *ngIf="!products?.length">{{ 'quickQuotes.emptyProductList' | translate }}</h4>
  <p-table
    [value]="products"
    dataKey="name"
    responsiveLayout="scroll"
    (onRowSelect)="onProductSelection($event)"
    (onRowUnselect)="onProductUnselection($event)"
  >
    <ng-template pTemplate="body" let-product let-expanded="expanded">
      <tr>
        <td style="width: 50px">
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="product"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          ></button>
        </td>
        <td style="width: 60px" class="p-0">
          <img style="width: 40px; margin-left: 10px" [src]="product.carrier.logo" class="carrier-logo" />
        </td>
        <td>
          <strong>{{ product.carrier.name }}</strong>
        </td>
        <td>
          <strong>{{ product.name }}</strong>
        </td>
        <td>
          <strong>{{ product.rates }}</strong>
        </td>
        <td style="width: 170px">
          <p-button styleClass="p-button-sm p-button-tertiary flex flex-column startApplicationButton">
            <ng-template pTemplate="content">
              <strong>{{ 'pages.quickQuotes.singlePage.productsTable.button.startApplication' | translate }}</strong>
              {{ 'pages.quickQuotes.singlePage.productsTable.button.noneISILead' | translate }}
            </ng-template>
          </p-button>
        </td>
        <td style="width: 60px">
          <button pButton pRipple type="button" icon="pi pi-download" class="p-button-rounded p-button-text"></button>
        </td>
        <td style="width: 60px">
          <p-tableCheckbox [value]="product"></p-tableCheckbox>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-product>
      <tr>
        <td colspan="7">
          <div class="p-3 flex">
            <p-table class="illustration-table" [scrollable]="true" [value]="product.illustrations" dataKey="age">
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ 'pages.quickQuotes.singlePage.productsTable.illustrationTable.header.age' | translate }}</th>
                  <th>{{ 'pages.quickQuotes.singlePage.productsTable.illustrationTable.header.monthlyRate' | translate }}</th>
                  <th>{{ 'pages.quickQuotes.singlePage.productsTable.illustrationTable.header.yearlyRate' | translate }}</th>
                  <th>{{ 'pages.quickQuotes.singlePage.productsTable.illustrationTable.header.coverage' | translate }}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-illustration>
                <tr>
                  <td>{{ illustration?.age }}</td>
                  <td>{{ illustration?.monthlyRate }}</td>
                  <td>{{ illustration?.yearlyRate }}</td>
                  <td>{{ illustration?.coverage }}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="6">{{ 'pages.quickQuotes.singlePage.productsTable.illustrationTable.emptyStateMessage' | translate }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
