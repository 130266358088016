import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'iapplication2-see-more',
  templateUrl: './see-more.component.html',
  styleUrls: ['./see-more.component.scss'],
})
export class SeeMoreComponent implements OnInit, OnChanges {
  @Input() text: string;
  @Input() length: number;
  isHidden = false;
  showButton = true;
  displayText: string = '';

  ngOnInit(): void {
    this.prepareText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.text?.currentValue !== changes?.text?.previousValue) {
      this.prepareText();
    }
  }

  private prepareText() {
    this.isHidden = this.text?.length > this.length;
    this.showButton = this.text?.length > this.length;
    this.displayText = this.isHidden ? this.text.substring(0, this.length) : this.text;
  }

  changeTextVisibility() {
    this.isHidden = !this.isHidden;
    this.displayText = this.isHidden ? this.text.substring(0, this.length) : this.text;
  }
}
