/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DtcPolicy } from '@iapplication2/interfaces';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'iapplication2-result-section-dtc',
  templateUrl: './result-section-dtc.component.html',
  styleUrls: ['./result-section-dtc.component.scss'],
})
export class ResultSectionDtcComponent implements OnInit, OnDestroy {
  @Input() dtcApplications: DtcPolicy[];
  @Input() loading: boolean = true;
  @Input() searchedString: string;
  items: MenuItem[];

  private unsubscribe = new Subject<void>();

  ngOnInit(): void {
    this.items = [
      {
        label: 'Dtc policies options',
        items: [
          {
            label: 'Option 1',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Option 2',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
  }

  toggleMenu(dtcPolicy: DtcPolicy, menu, ev) {
    console.log(dtcPolicy);
    ev.preventDefault();
    menu.toggle(ev);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
