<div fxLayoutAlign="start center">
  <button
    *ngIf="isInEditMode"
    pButton
    pRipple
    type="button"
    icon="pi pi-times"
    (click)="deleteOptionFromSelectedOptions()"
    class="p-button-rounded p-button-sm p-button-text p-button-danger"
  ></button>
  <div class="my-2 ml-1 mr-3">
    {{ option.label }}
  </div>
  <i class="pi pi-exclamation-circle mr-2" [pTooltip]="option.info" style="color: #a3c4f5; font-size: 0.9rem"></i>
</div>
