import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StaticApplicationDisclosure, StaticApplicationDisclosureAnswer } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-static-application-disclosures',
  templateUrl: './static-application-disclosures.component.html',
  styleUrls: ['./static-application-disclosures.component.scss'],
})
export class StaticApplicationDisclosuresComponent implements OnInit, OnChanges {
  @Input() displayHumaniaDisclosure = false;
  @Output() changedStaticApplicationDisclosureAnswer: EventEmitter<unknown> = new EventEmitter();

  staticApplicationDisclosures: StaticApplicationDisclosure[];
  staticApplicationDisclosureAnswer: StaticApplicationDisclosureAnswer[] = [];
  displayStaticApplicationDisclosures = false;

  ngOnInit(): void {
    this.initializeStaticApplicationDisclosures();
    this.displayStaticApplicationDisclosures = this.shouldDisplayStaticApplicationDisclosures();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayHumaniaDisclosure) {
      this.initializeStaticApplicationDisclosures();
      this.displayStaticApplicationDisclosures = this.shouldDisplayStaticApplicationDisclosures();
    }
  }

  initializeStaticApplicationDisclosures() {
    this.staticApplicationDisclosures = [
      {
        disclosureType: 'humania',
        condition: this.displayHumaniaDisclosure,
        mp3File: 'intro.mp3',
      },
    ];
  }

  shouldDisplayStaticApplicationDisclosures(): boolean {
    return this.staticApplicationDisclosures.some((disclosure) => disclosure.condition);
  }

  onChangedStaticApplicationDisclosureAnswer() {
    this.changedStaticApplicationDisclosureAnswer.emit();
  }
}
