import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Condition } from '@iapplication2/interfaces';
import { ConditionsTypeEnum } from '@iapplication2/interfaces';

@Injectable({
  providedIn: 'root',
})
export class FormFieldConditionsService {
  private static formatString(value: string) {
    // Method which removes blank spaces from string and lowers all its cases
    if (value) {
      return value.toString()?.trim()?.toLowerCase();
    } else {
      return value;
    }
  }

  checkFieldConditions(fieldConditions: Condition[], formGroup: FormGroup, productFormId: string, field?): boolean {
    let result = true;

    if (fieldConditions.length) {
      fieldConditions.forEach((condition) => {
        const fieldFormControl = this.getFieldFormControl(condition.conditionFieldId, formGroup, productFormId);
        if (condition.conditionFieldId.toString() && !this.verifyCondition(condition, fieldFormControl)) {
          result = false;
        }
      });
    }

    return result;
  }

  getFieldFormControl(selectedFieldId: number, formGroup: FormGroup, productFormId: string): AbstractControl {
    const selectedFormGroup = formGroup.controls[productFormId] as FormGroup;
    let fieldFormControl: AbstractControl;

    if (selectedFormGroup) {
      Object.keys(selectedFormGroup.controls).forEach((sectionId) => {
        Object.keys((selectedFormGroup.controls[sectionId] as FormGroup).controls).forEach((areaId) => {
          Object.keys(((selectedFormGroup.controls[sectionId] as FormGroup).controls[areaId] as FormGroup).controls).forEach((fieldId) => {
            if (fieldId === selectedFieldId.toString()) {
              fieldFormControl = ((selectedFormGroup.controls[sectionId] as FormGroup).controls[areaId] as FormGroup).controls[fieldId];
            }
          });
        });
      });
    }

    return fieldFormControl;
  }

  private verifyCondition(condition: Condition, formControl: AbstractControl): boolean {
    if (Object.values(ConditionsTypeEnum).some((value) => value === condition.conditionType.type) && condition) {
      const formControlFormattedValue = FormFieldConditionsService.formatString(formControl?.value);
      const conditionFormattedValue = FormFieldConditionsService.formatString(condition?.value);

      switch (condition.conditionType.type) {
        case ConditionsTypeEnum.CONTAINS_STRING: {
          return formControlFormattedValue?.includes(conditionFormattedValue);
        }
        case ConditionsTypeEnum.IS_NOT_EMPTY: {
          return !!formControl.value || formControl.value === 0;
        }
        case ConditionsTypeEnum.STARTS_WITH: {
          return formControlFormattedValue?.startsWith(conditionFormattedValue);
        }
        case ConditionsTypeEnum.ENDS_WITH: {
          return formControlFormattedValue?.endsWith(conditionFormattedValue);
        }
        case ConditionsTypeEnum.IS_EMPTY: {
          return formControl.value === '' || !formControl.value;
        }
        case ConditionsTypeEnum.IS_SELECTED: {
          return formControl.value === true;
        }
        case ConditionsTypeEnum.IS_NOT_SELECTED: {
          return formControl.value === false;
        }
        case ConditionsTypeEnum.CONTAINS_CERTAIN_VALUE: {
          return formControlFormattedValue?.includes(conditionFormattedValue);
        }
        case ConditionsTypeEnum.DOES_NOT_CONTAIN_CERTAIN_VALUE: {
          if (condition.value) {
            return !formControlFormattedValue?.includes(conditionFormattedValue);
          } else {
            return true;
          }
        }
        case ConditionsTypeEnum.CERTAIN_VALUE_SELECTED: {
          return formControlFormattedValue === conditionFormattedValue;
        }
        case ConditionsTypeEnum.CERTAIN_VALUE_NOT_SELECTED: {
          return formControlFormattedValue !== conditionFormattedValue;
        }
        case ConditionsTypeEnum.IS_EXACT_DATE: {
          /* The prime ng datepicker returns a wrong value with
          one day in the past, so a work-around was needed */

          const correctDate = formControl.value as Date;
          correctDate.setDate((formControl.value as Date).getDate() + 1);

          return correctDate.toISOString() === condition.value;
        }
        case ConditionsTypeEnum.IS_NOT_EXACT_DATE: {
          const correctDate = formControl.value as Date;
          correctDate.setDate((formControl.value as Date).getDate() + 1);

          return correctDate.toISOString() !== condition.value;
        }
        case ConditionsTypeEnum.HIGHER_THAN: {
          return Number(formControl.value) > Number(condition.value);
        }
        case ConditionsTypeEnum.IS_HIGHER_THAN: {
          return Number(formControl.value) > Number(condition.value);
        }
        case ConditionsTypeEnum.HIGHER_OR_EQUAL_THAN: {
          return Number(formControl.value) >= Number(condition.value);
        }
        case ConditionsTypeEnum.IS_HIGHER_OR_EQUAL_THAN: {
          return Number(formControl.value) >= Number(condition.value);
        }
        case ConditionsTypeEnum.LOWER_THAN: {
          return Number(formControl.value) < Number(condition.value);
        }
        case ConditionsTypeEnum.IS_LOWER_THAN: {
          return Number(formControl.value) < Number(condition.value);
        }
        case ConditionsTypeEnum.LOWER_OR_EQUAL_THAN: {
          return Number(formControl.value) <= Number(condition.value);
        }
        case ConditionsTypeEnum.IS_LOWER_OR_EQUAL_THAN: {
          return Number(formControl.value) <= Number(condition.value);
        }
        case ConditionsTypeEnum.IS_EXACTLY: {
          return formControlFormattedValue === conditionFormattedValue;
        }
        case ConditionsTypeEnum.IS_NOT_EXACTLY: {
          return formControlFormattedValue !== conditionFormattedValue;
        }
        case ConditionsTypeEnum.DISCLOSURE_READ: {
          return formControl.value === true;
        }
        case ConditionsTypeEnum.DISCLOSURE_NOT_READ: {
          return formControl.value === false;
        }
        case ConditionsTypeEnum.HAS_SIGNATURE: {
          return formControl.value === true;
        }
        case ConditionsTypeEnum.DOES_NOT_HAVE_SIGNATURE: {
          return formControl.value === false;
        }
      }
    }
  }
}
