import { Province } from './address.model';
import { Sex } from './sex.enum';
import { SmokerStatus } from './smoker-status.enum';
export interface QuickQuote {
  id?: number;
  firstName: string;
  middleName: string;
  lastName: string;
  effectiveDate: Date;
  everest: boolean;
  birthDate: Date;
  sex: Sex;
  smokerStatus: SmokerStatus;
  faceAmount: number;
  province: Province;
}
