import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent, ForgotComponent, LoginComponent, SignatureComponent } from '@iapplication2/ui';
import { AuthGuardService, NotAuthGuardService } from '@iapplication2/guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@iapplication2/advisor-dashboard').then((m) => m.AdvisorDashboardModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'signature/:guid',
    component: SignatureComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthGuardService],
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    canActivate: [NotAuthGuardService],
  },
  {
    path: 'clients',
    loadChildren: () => import('@iapplication2/client-management').then((m) => m.ClientManagementModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'applications',
    loadChildren: () => import('@iapplication2/applications-management').then((m) => m.ApplicationsManagementModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'application',
    loadChildren: () => import('@iapplication2/application-process').then((m) => m.ApplicationProcessModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'quick-quotes',
    loadChildren: () => import('@iapplication2/quick-quote').then((m) => m.QuickQuoteModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'search',
    loadChildren: () => import('@iapplication2/global-search').then((m) => m.GlobalSearchModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'notifications',
    loadChildren: () => import('@iapplication2/notifications').then((m) => m.NotificationsCenterModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
