import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { AudioActionsTypes, LanguagesEnum } from '@iapplication2/interfaces';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DisclosuresService {
  isAnyDisclosureDisclaimerPlaying = false;

  constructor(private http: HttpClient, private authService: AuthService, private translateService: TranslateService) {}

  playDisclosure(mp3File: string): Observable<any> {
    const payload = this.preparePayload(mp3File, AudioActionsTypes.PLAY);
    return this.audioDisclosure(payload).pipe(
      tap({
        next: () => {
          this.isAnyDisclosureDisclaimerPlaying = true;
        },
        error: () => {
          this.isAnyDisclosureDisclaimerPlaying = false;
        },
      })
    );
  }

  stopDisclosure(mp3File: string): Observable<any> {
    const payload = this.preparePayload(mp3File, AudioActionsTypes.STOP);
    return this.audioDisclosure(payload).pipe(
      tap({
        next: () => {
          this.isAnyDisclosureDisclaimerPlaying = false;
        },
        error: () => {
          this.isAnyDisclosureDisclaimerPlaying = true;
        },
      })
    );
  }

  getListOfMp3Files() {
    return this.http.get('s3-mp3s?prefix=disclosures');
  }

  callJenieAudioApi(mp3File: string, action: Exclude<AudioActionsTypes, AudioActionsTypes.PLAY>) {
    const payload = this.preparePayload(mp3File, action);
    return this.audioDisclosure(payload);
  }

  private preparePayload(mp3File: string, action: AudioActionsTypes) {
    const languageCode: string = this.translateService.currentLang;
    const suffix = languageCode === LanguagesEnum.EN ? '' : `_${languageCode}`;
    let filePath: string = null;
    if (mp3File) {
      if (mp3File.indexOf('jenie') > -1) {
        filePath = `/${mp3File}`;
      } else {
        mp3File = mp3File.replace('.mp3', '');
        filePath = `/disclaimers-sli/disclaimer-mp3s/${languageCode}/${mp3File.replace('disclosures/', '')}${suffix}`;
      }
    }

    return {
      email: this.authService.user.email,
      action: action === 'start' || action === 'play' ? 'play' : 'stop',
      file: filePath,
      // _action: action
    };
  }

  private audioDisclosure(payload): Observable<any> {
    if (payload.email) {
      return this.http.put('application/dialer/disclosures', payload, {
        observe: 'response',
      });
    }
  }
}
