<div fxFlex fxLayout="column">
  <div fxFlex fxLayout="row" fxLayoutAlign="start center">
    <h4 [ngStyle]="{ color: 'var(--primary-color)' }">{{ 'applicationProcess.step.summary.attachments.title' | translate }}</h4>
  </div>
  <div *ngIf="applicationAttachments.length > 0" fxFlex fxLayout="column" class="pl-1">
    <div
      *ngFor="let attachedFile of applicationAttachments"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="start center"
      class="mb-2"
      [pTooltip]="attachedFile.fileName"
    >
      {{ attachedFile.fileName.length > 20 ? (attachedFile.fileName | slice: 0:20) + '..' : attachedFile.fileName }}
      <a [href]="attachedFile?.s3Link" target="_blank">
        <button pButton pRipple type="button" label="Download" class="p-button-secondary ml-2"></button>
      </a>
      <button pButton pRipple type="button" label="Delete" class="p-button-secondary ml-2" (click)="deleteFile(attachedFile)"></button>
    </div>
  </div>
  <div *ngIf="applicationAttachments.length === 0">{{ 'applicationProcess.step.summary.attachments.emptyMessage' | translate }}</div>
  <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="pl-1 mt-3">
    <p class="mb-0">{{ 'applicationProcess.step.summary.attachments.addFile' | translate }}:</p>
    <p-fileUpload
      class="ml-2 custom-upload-button"
      #logoUploadForm
      name="files[]"
      mode="basic"
      [disabled]="applicationAttachments?.length >= 5"
      [customUpload]="true"
      [auto]="true"
      (uploadHandler)="onAddAttachments($event, logoUploadForm)"
      [chooseLabel]="'applicationProcess.step.summary.attachments.upload' | translate"
      accept="application/pdf"
    >
    </p-fileUpload>
  </div>
</div>
