import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Client, ENV, envType } from '@iapplication2/interfaces';
import { ApplicationsProcessService, DashboardService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@iapplication2/app-config';

@Component({
  selector: 'iapplication2-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  clients: Client[] = [];
  loadingClients: boolean;

  isEnvDevelop: boolean;

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private dashboardService: DashboardService,
    private applicationsProcessService: ApplicationsProcessService,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit(): void {
    this.getClients();
    this.checkIfEnvIsDevelop();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  checkIfEnvIsDevelop() {
    this.isEnvDevelop = this.appConfig.environmentType === envType.DEVELOP;
  }

  getClients() {
    this.loadingClients = true;
    this.dashboardService
      .getDashboardClients()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.clients = res.data;
        this.loadingClients = false;
      });
  }

  createApplication() {
    this.applicationsProcessService
      .createNewApplicationId()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((id: { applicationId: number }) => {
        this.router.navigate(['/application/edit/' + id.applicationId]);
      });
  }
}
