export interface RatesProduct {
  id: number;
  name: string;
  code: number;
  name_fr: string;
  min_age: number;
  max_age: number;
  min_premium: number;
  min_coverage: number;
  max_coverage: number;
  version: number;
  age_type: string;
}

export interface Premium {
  age: string;
  date: string;
  premium: string;
}
