import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FieldDisplayCondition } from '@iapplication2/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ConditionalDisplayService {
  constructor(private http: HttpClient) {}

  getAllFieldsByFormId(formId: number) {
    return this.http.get(`form-builder/fields/${formId}`);
  }

  getConditionTypesByFieldTypeId(typeId: number) {
    return this.http.get(`form-builder/condition-types/${typeId}`);
  }

  saveConditions(fieldId: number, conditions: FieldDisplayCondition[]) {
    return this.http.put(`form-builder/conditions/${fieldId}`, conditions);
  }
}
