export interface Language {
  id: number;
  name: string;
  code: string;
}

export enum LanguagesEnum {
  FR = 'fr',
  EN = 'en',
}
