import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldCardComponent } from './custom-field-card/custom-field-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FieldTypeCardComponent } from './field-type-card/field-type-card.component';
import { PipesModule } from '@iapplication2/pipes';
// import the new component

@NgModule({
  imports: [CommonModule, FlexLayoutModule, PipesModule],
  declarations: [CustomFieldCardComponent, FieldTypeCardComponent],
  exports: [CustomFieldCardComponent, FieldTypeCardComponent],
})
export class FormBuilderUiModule {}
