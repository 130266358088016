import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { QuickQuote, Severity, TableCol, UserSettings } from '@iapplication2/interfaces';
import { QuickQuoteManagementService, UtilsService } from '@iapplication2/services';
import { LazyLoadEvent, MenuItem, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { TablesList } from '@iapplication2/interfaces';
import { UserSettingsService } from '@iapplication2/services';

@Component({
  selector: 'iapplication2-quick-quote-management-list',
  templateUrl: './quick-quote-management-list.component.html',
  styleUrls: ['./quick-quote-management-list.component.scss'],
  providers: [MessageService],
})
export class QuickQuoteManagementListComponent implements OnInit, OnDestroy {
  quickQuotes: QuickQuote[];
  userSettings: UserSettings;
  private unsubscribe: Subject<unknown> = new Subject();

  scrollableCols: TableCol[];

  @ViewChildren('unfrozenRows') unfrozenRows;
  @ViewChildren('frozenRows') frozenRows;
  @ViewChild('menu') menu: ElementRef;

  selectedCols: TableCol[];

  page = 0;
  rows = 5;

  previousTableEvent: any;
  totalRecords: number;

  loading = true;
  matchModeOptions: SelectItem[];
  items: MenuItem[];

  rowsPerPage: string[] = ['5', '10', '20', '50'];

  constructor(
    private quickQuoteManagementService: QuickQuoteManagementService,
    private utilsService: UtilsService,
    private messageService: MessageService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'Quick-quote options',
        items: [
          {
            label: 'Continue with quick-quote',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete quick-quote',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];

    this.scrollableCols = [
      {
        field: 'firstName',
        header: 'First name',
        width: 200,
      },
      {
        field: 'middleName',
        header: 'Middle name',
        width: 175,
      },
      {
        field: 'lastName',
        header: 'Last name',
        width: 175,
      },
      {
        field: 'effectiveDate',
        header: 'Effective date',
        width: 200,
      },
      {
        field: 'everest',
        header: 'Everest',
        width: 175,
      },
      {
        field: 'birthDate',
        header: 'Birth date',
        width: 200,
      },
      {
        field: 'sex',
        header: 'Sex',
        width: 150,
      },
      {
        field: 'smokerStatus',
        header: 'Smoker status',
        width: 200,
      },
      {
        field: 'faceAmount',
        header: 'Face amount',
        width: 200,
      },
      {
        field: 'province',
        header: 'Province',
        width: 200,
      },
    ];

    this.selectedCols = this.scrollableCols;
    this.getUserTableColumns();
  }

  loadQuickQuotes(event?: LazyLoadEvent, globalSearchValue?: string) {
    if (event) {
      this.previousTableEvent = event;
    }

    this.page = this.previousTableEvent.first / this.previousTableEvent.rows + 1;
    const rows = this.previousTableEvent?.rows.toString();
    const sortField: string = this.previousTableEvent.sortField;
    const sortOder: string = this.previousTableEvent.sortOrder === -1 ? 'ASC' : 'DESC';

    this.loading = true;
    this.quickQuoteManagementService
      .getQuickQuotes(this.page, rows, sortField, sortOder, globalSearchValue)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.quickQuotes = res.body.data as QuickQuote[];
        this.totalRecords = res.count;
        this.loading = false;
        window.setTimeout(() => {
          this.utilsService.syncronizeRowHeightForDataTable(this.frozenRows, this.unfrozenRows);
        }, 0);
      });
  }

  filterGlobal(value: string) {
    this.loadQuickQuotes(null, value);
  }

  getAge(date: string): string {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }

  toggleMenu(quickQuote: QuickQuote, menu, ev) {
    ev.preventDefault();
    this.items[0].items[0].routerLink = `quick-quote/${quickQuote.id}`;
    const deleteMenuItem = this.items[0].items[1];
    deleteMenuItem.command = () => this.deleteQuickQuote(quickQuote.id);
    menu.toggle(ev);
  }

  deleteQuickQuote(id: number) {
    this.quickQuoteManagementService
      .deleteQuickQuoteById(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.loadQuickQuotes(null, null);
          this.showSuccessMessage();
        },
        (e) => {
          this.showErrorMessage(e.message);
        }
      );
  }

  showSuccessMessage() {
    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: 'Success!',
      detail: 'The quick quote was successfully deleted!',
    });
  }

  showErrorMessage(message: string) {
    this.messageService.add({
      severity: Severity.ERROR,
      summary: 'Error!',
      detail: message,
    });
  }

  getUserTableColumns() {
    this.userSettingsService
      .getUserSeetingsByUserId(1234)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          console.log(res[0]);
          this.userSettings = res[0];
          const tableColsForUser = this.userSettings.selectedTableColumns.filter((table) => table.name === TablesList.QUICKQUOTE)[0];
          if (tableColsForUser) {
            this.selectedCols = tableColsForUser.columns;
          }
        }
      });
  }

  selectedColsChanged() {
    this.updateUserSettingsWithSelectedTableCols();
  }

  updateUserSettingsWithSelectedTableCols() {
    const tableFromUserSettings = this.userSettings.selectedTableColumns.filter(
      (selectedTable) => selectedTable.name === TablesList.QUICKQUOTE
    )[0];
    if (tableFromUserSettings) {
      tableFromUserSettings.columns = this.selectedCols;
    } else {
      this.userSettings.selectedTableColumns.push({
        name: 'quick-quote',
        columns: this.selectedCols,
      });
    }
    this.userSettingsService.updateUserSettingsByUserId(this.userSettings);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
