import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Themes } from '@iapplication2/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DarkThemeService {
  themeChanged: ReplaySubject<string> = new ReplaySubject(1);
  savedTheme: Themes;

  changeTheme(newTheme: Themes) {
    this.themeChanged.next(newTheme);
  }
}
