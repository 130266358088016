<div>
  <p-table
    [value]="applications"
    [lazy]="true"
    [scrollable]="true"
    [loading]="loading"
    scrollHeight="200px"
    [autoLayout]="true"
    #customtable
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="med-col">
          {{ 'pages.dashboard.sections.recentPolicies.tabs.recentApplications.table.columns.client' | translate }}
        </th>
        <th class="med-col">
          {{ 'pages.dashboard.sections.recentPolicies.tabs.recentApplications.table.columns.premium' | translate }}
        </th>
        <th>
          {{ 'pages.dashboard.sections.recentPolicies.tabs.recentApplications.table.columns.products' | translate }}
        </th>
        <th>
          {{ 'pages.dashboard.sections.recentPolicies.tabs.recentApplications.table.columns.state' | translate }}
        </th>
        <th class="small-col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-application>
      <tr>
        <td
          class="med-col name-column"
          [highlight]="searchedString"
          [innerHtml]="application?.fullName"
          [pTooltip]="application?.fullNameTooltip"
        ></td>
        <td
          class="med-col"
          [highlight]="searchedString"
          [innerHTML]="application?.premium"
        ></td>
        <td>
          <ul>
            <li *ngFor="let product of application.products">
              <b [highlight]="searchedString" [innerHTML]="product.name"></b>
              <span *ngIf="product.addons.length > 0">
                (<span *ngFor="let addon of product.addons; let i = index" class="addon-text">
                  <span
                    *ngIf="i < product.addons.length - 1"
                    [highlight]="searchedString"
                    [innerHTML]="addon.name + '<span>,</span>'"
                  ></span>
                  <span *ngIf="i >= product.addons.length - 1" [highlight]="searchedString" [innerHTML]="addon.name"></span> </span
                >)
              </span>
            </li>
          </ul>
        </td>
        <td>
          <iapplication2-product-status-tag [status]="application.status"></iapplication2-product-status-tag>
        </td>
        <td class="small-col">
          <p-button icon="pi pi-cog" class="p-button-secondary" (click)="menu.toggle($event)"></p-button>
          <p-menu #menu [popup]="true" [model]="onClickMenu(application)" appendTo="body"></p-menu>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="pt-3 pl-2">
    <a [routerLink]="['/applications']" [queryParams]="{ tab: 'applications' }"
      >{{ 'pages.dashboard.sections.recentPolicies.tabs.recentApplications.seeAll' | translate }}
      <i class="pi pi-arrow-right" style="font-size: 0.8rem"></i
    ></a>
  </div>
</div>
