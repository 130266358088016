import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBuilderGroup } from '@iapplication2/interfaces';
import { getTypeFor } from '../../registryComponent';
import { ApplicationsProcessService } from '@iapplication2/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-form-display-group',
  templateUrl: './form-display-group.component.html',
  styleUrls: ['./form-display-group.component.scss'],
})
export class FormDisplayGroupComponent implements AfterViewInit {
  @Input() formGroupItem: FormBuilderGroup;
  @ViewChild('groupContainer', { read: ViewContainerRef }) container;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private componentRef: ComponentRef<any>;
  @Input() formFieldControl: FormControl;
  @Input() parentFormGroup: FormGroup;
  unsubscribe: Subject<unknown> = new Subject();
  isViewApplication: boolean;

  constructor(private cdr: ChangeDetectorRef, private applicationsProcessService: ApplicationsProcessService) {}

  private static getComponentByName(componentName: string) {
    return getTypeFor(componentName);
  }

  ngAfterViewInit(): void {
    this.checkViewApplicationDisplay();
    this.dynamicallyCreateGroup();
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  dynamicallyCreateGroup() {
    const componentClass = FormDisplayGroupComponent.getComponentByName(this.formGroupItem.fields[0]?.fieldType.type);
    if (componentClass) {
      this.componentRef = this.container.createComponent(componentClass);
      this.componentRef.instance.group = this.formGroupItem;
      this.componentRef.instance.formFieldControl = this.formFieldControl;
      this.componentRef.instance.parentFormGroup = this.parentFormGroup;
      this.componentRef.instance.isReadonly = this.isViewApplication ? this.isViewApplication : this.formGroupItem.fields.some((field) => field.staticValueLink?.fieldKey);
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
