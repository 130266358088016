import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  FormBuilderField,
  FormBuilderFieldTypeEntries,
  FormBuilderFieldTypeEntriesPredefined,
  FormBuilderFieldTypeEntry,
  FormBuilderFieldTypeOption,
  FormBuilderFieldTypeType,
} from '@iapplication2/interfaces';
import { InteractiveFormBuilderTypeOptionsService } from '@iapplication2/services';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-field-details-type',
  templateUrl: './field-details-type.component.html',
  styleUrls: ['./field-details-type.component.scss'],
})
export class FieldDetailsTypeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('options', { static: false }) optionsOverlayPanel;
  @ViewChild('entriesSource', { static: false }) possibleEntriesOverlayPanel;

  @Input() isInFieldBuilder = false;
  @Input() isInEditMode = false;
  @Input() isInModal = false;
  @Input() selectedField: FormBuilderField;

  inputEntry: string;

  possiblePredefinedEntries: FormBuilderFieldTypeEntriesPredefined[];
  entrySourceTypes: unknown[] = [
    {
      label: 'Manual',
      value: 'manual',
    },
    {
      label: 'Predefined',
      value: 'predefined',
    },
  ];
  selectedEntrySourceType: 'manual' | 'predefined' = 'manual';
  previouslySelectedEntrySourceType: 'manual' | 'predefined' = this.selectedEntrySourceType;

  availablePossibleOptions: FormBuilderFieldTypeOption[];
  allPossibleOptions: FormBuilderFieldTypeOption[] = [];
  selectedOptions: FormBuilderFieldTypeOption[] = [];

  private unsubscribe = new Subject<void>();

  constructor(private interactiveFormBuilderTypeOptionsService: InteractiveFormBuilderTypeOptionsService) {}

  ngOnInit(): void {
    this.sortEntries();
    this.setSelectedOptionsAccordingToFieldTypeAndAlreadyAddedOptions(this.getTypeFromSelectedField());
    this.setPossiblePredefinedEntriesAccordingToType(this.getTypeFromSelectedField());
    this.setSelectedEntrySourceType();
  }

  getTypeFromSelectedField() {
    switch (true) {
      case !!this.selectedField.fields:
        return this.selectedField.groupOptions.type.type;
      case !!this.selectedField.columns:
        return FormBuilderFieldTypeType.TABLE;
      case !!this.selectedField.fieldType:
        return this.selectedField.fieldType.type;
    }
  }

  sortEntries() {
    if (Number(this.selectedField.fieldOptionEntries?.entries?.[0]?.value)) {
      this.selectedField.fieldOptionEntries.entries = this.selectedField.fieldOptionEntries.entries.sort(
        (a, b) => Number(a.value) - Number(b.value)
      );
    }
  }

  changedEntriesSource(): void {
    if (this.isInEditMode) {
      this.previouslySelectedEntrySourceType = this.selectedEntrySourceType;
    }
  }

  addEntry(): void {
    const entry: FormBuilderFieldTypeEntry = {
      value: this.inputEntry,
    };
    if (entry.value) {
      if (this.selectedField.fieldOptionEntries?.entries?.length) {
        this.selectedField.fieldOptionEntries.entries.push(entry);
      } else {
        this.selectedField.fieldOptionEntries = {} as FormBuilderFieldTypeEntries;
        this.selectedField.fieldOptionEntries.entries = [entry];
      }
      if (this.selectedField.fieldOptionEntries.predefined) delete this.selectedField.fieldOptionEntries.predefined;

      this.inputEntry = null;
    }
  }

  deleteManualEntry(manualEntry: FormBuilderFieldTypeEntry): void {
    this.selectedField.fieldOptionEntries.entries = this.selectedField.fieldOptionEntries?.entries.filter((entry) => entry != manualEntry);
  }

  selectPredefinedEntry(predefinedEntry: FormBuilderFieldTypeEntriesPredefined): void {
    this.selectedField.fieldOptionEntries = {} as FormBuilderFieldTypeEntries;
    this.selectedField.fieldOptionEntries.predefined = predefinedEntry;

    this.possibleEntriesOverlayPanel.hide();
  }

  deletePredefinedEntry(): void {
    if (this.selectedField.fieldOptionEntries.predefined) delete this.selectedField.fieldOptionEntries.predefined;
  }

  addOptionToSelectedOptions(currentlySelectedOption: FormBuilderFieldTypeOption): void {
    this.optionsOverlayPanel.hide();

    if (this.selectedField.fieldTypeOptions?.length) {
      this.selectedField.fieldTypeOptions.push(currentlySelectedOption);
    } else {
      this.selectedField.fieldTypeOptions = [currentlySelectedOption];
    }

    const index = this.availablePossibleOptions.findIndex((option) => _.isEqual(option, currentlySelectedOption));
    if (index > -1) {
      this.availablePossibleOptions.splice(index, 1);
      currentlySelectedOption = null;
    }
  }

  setSelectedOptionsAccordingToFieldTypeAndAlreadyAddedOptions(type: FormBuilderFieldTypeType): void {
    if (!this.allPossibleOptions.length) {
      const unsubscribeTypeOptions: Subject<void> = new Subject<void>();
      this.interactiveFormBuilderTypeOptionsService
        .getPossibleOptionsByType(type)
        .pipe(takeUntil(unsubscribeTypeOptions))
        .subscribe((res: FormBuilderFieldTypeOption[]) => {
          this.availablePossibleOptions = res;
          this.allPossibleOptions = res;
          unsubscribeTypeOptions.next(void 0);
          unsubscribeTypeOptions.complete();

          if (this.selectedField.fieldTypeOptions?.length) {
            this.selectedField.fieldTypeOptions?.forEach((selectedOption) => {
              const index = this.availablePossibleOptions?.findIndex((option) => _.isEqual(option, selectedOption));
              if (index > -1) {
                this.availablePossibleOptions.splice(index, 1);
              }
            });
          }
        });
    }
  }

  setSelectedEntrySourceType() {
    if (this.selectedField.fieldOptionEntries) {
      this.selectedEntrySourceType = this.selectedField.fieldOptionEntries.predefined ? 'predefined' : 'manual';
    } else {
      this.selectedField.fieldOptionEntries = {};
    }
  }

  setPossiblePredefinedEntriesAccordingToType(type: FormBuilderFieldTypeType): void {
    this.interactiveFormBuilderTypeOptionsService
      .getPossiblePredefinedEntriesByType(type)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FormBuilderFieldTypeEntriesPredefined[]) => {
        this.possiblePredefinedEntries = res;
      });
  }

  onOptionDeletion(option: FormBuilderFieldTypeOption) {
    this.availablePossibleOptions.push(option);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedField?.currentValue != changes.selectedField?.previousValue) {
      this.allPossibleOptions = [];
      this.setPossiblePredefinedEntriesAccordingToType(this.getTypeFromSelectedField());
      this.setSelectedOptionsAccordingToFieldTypeAndAlreadyAddedOptions(this.getTypeFromSelectedField());
      this.setSelectedEntrySourceType();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
