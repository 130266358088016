import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeNewBusinessUserComponent } from './change-new-business-user.component';
import { AdvisorCardModule } from '../advisor-card/advisor-card.module';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserCardModule } from '../user-card/user-card.module';

@NgModule({
  declarations: [ChangeNewBusinessUserComponent],
  imports: [
    CommonModule,
    AdvisorCardModule,
    ButtonModule,
    AutoCompleteModule,
    OverlayPanelModule,
    FlexLayoutModule,
    FormsModule,
    UserCardModule,
  ],
  exports: [ChangeNewBusinessUserComponent],
})
export class ChangeNewBusinessUserModule {}
