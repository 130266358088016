<div [class.isHidden]="isHidden">
  <div *ngIf="checkType.isGroup">
    <iapplication2-form-display-group
      [formGroupItem]="asFormBuilderGroup(formItem)"
      [formFieldControl]="getFormFieldControl()"
      [parentFormGroup]="parentFormGroup"
    ></iapplication2-form-display-group>
  </div>
  <div *ngIf="checkType.isField">
    <iapplication2-form-display-field
      [formField]="asFormBuilderField(formItem)"
      [formFieldControl]="getFormFieldControl()"
      [parentFormGroup]="parentFormGroup"
      [isPreviewMode]="isPreviewMode"
    ></iapplication2-form-display-field>
    <iapplication2-form-field-error
      *ngIf="parentFormGroup.controls[formItem.id]?.touched && parentFormGroup.controls[formItem.id]?.dirty && !isViewApplication"
      [control]="parentFormGroup.controls[formItem.id]"
    ></iapplication2-form-field-error>
  </div>
  <div *ngIf="checkType.isTable">
    <iapplication2-form-display-table
      [formTableItem]="asFormBuilderTable(formItem)"
      [formFieldControl]="getFormFieldControl()"
      [parentFormGroup]="parentFormGroup"
    ></iapplication2-form-display-table>
  </div>
  <div *ngIf="checkType.isText && showStaticText">
    <iapplication2-form-display-text [formText]="asFormBuilderStaticText(formItem)"></iapplication2-form-display-text>
  </div>
</div>
