import { Injectable } from '@angular/core';
import { Client } from '@iapplication2/interfaces';

@Injectable({
  providedIn: 'root',
})
export class StrictTypingService {
  asClient(object: unknown): Client {
    return object as Client;
  }
}
