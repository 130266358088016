import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  Client,
  Country,
  Product,
  ApplicationProduct,
  StaticFieldLinkType,
  FormBuilderFieldStaticFieldLink,
  Sex,
  ClientFormEnum,
  TitleEnumEn,
  FnaOption,
  TitleEnumFr,
  LanguagesEnum,
} from '@iapplication2/interfaces';
import { ApplicationsProcessService, ClientManagementService, DatesService, StaticFieldLinkService } from '@iapplication2/services';
import { FormHelper } from '@iapplication2/superclass';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { STATIC_FIELD_LINK_ALTERNATE_VALUES } from '@iapplication2/constants';

@Component({
  selector: 'iapplication2-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss'],
})
export class ClientSelectionComponent extends FormHelper implements OnInit, OnDestroy {
  @Input() clientForm: FormGroup;
  @Input() fnaForm: FormGroup;
  @Input() formSubmissionTriggered = false;
  @Output() medicalQuestionnairesAreValid: EventEmitter<boolean> = new EventEmitter();
  @Output() fnaFormIsValid: EventEmitter<boolean> = new EventEmitter();
  @Output() productSelectionChanged: EventEmitter<Product[]> = new EventEmitter();
  @Output() staticApplicationDisclosureAnswerChanged: EventEmitter<unknown> = new EventEmitter();
  @Output() isAnyHumaniaProductSelectedChanged: EventEmitter<boolean> = new EventEmitter();

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  loading = true;
  checked = false;
  options: any;
  fileKey: any;

  applicationId: number;

  clients: Client[] = [];
  selectedClient: Client;
  selectedIndex: number;

  products: any[];
  selectedProducts: Product[];
  loadedProducts: ApplicationProduct[] = [];
  currentApplicationNotes: string;

  fnaOptions: FnaOption[] = [
    { label: this.translate.instant('client_selection.fna.fillInForm'), value: 0 },
    { label: this.translate.instant('client_selection.fna.fillIfNeeded'), value: 1 },
    { label: this.translate.instant('client_selection.fna.fillUpload.label'), value: 2 },
  ];
  fnaSelection: FnaOption;
  isAnyHumaniaProductSelected = false;

  fnaLiabilitiesAerobicHoursOptions = [
    this.translate.instant('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek30OrLess'),
    this.translate.instant('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek30-2'),
    this.translate.instant('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek2-5'),
    this.translate.instant('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek5-10'),
    this.translate.instant('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek10OrMore'),
  ];

  translatedPlaceholdersForDropdowns: {
    language: string;
    countryOfBirth: string;
    province: string;
  } = {
    language: 'Pick a language.',
    countryOfBirth: 'Select country.',
    province: 'Select province.',
  };

  onIsAnyHumaniaProductSelectedChanged(isAnyHumaniaProductSelected: boolean): void {
    this.isAnyHumaniaProductSelected = isAnyHumaniaProductSelected;
    this.isAnyHumaniaProductSelectedChanged.emit(isAnyHumaniaProductSelected);
  }

  constructor(
    private route: ActivatedRoute,
    private applicationsProcessService: ApplicationsProcessService,
    private translate: TranslateService,
    private clientManagementService: ClientManagementService,
    private staticFieldLinkService: StaticFieldLinkService,
    private datesService: DatesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.applicationsProcessService.applicationChanged.subscribe((application) => {
      if (application) {
        this.selectedClient = application?.client;
        this.loadedProducts = application?.products;
        this.currentApplicationNotes = application?.notes;
        this.fillForm();
      }
    });
    this.watchClientFormChanges();
    this.watchLanguageChange();
    this.fetchDataAndFillForm();
  }

  watchClientFormChanges() {
    this.clientForm.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(400)).subscribe({
      next: () => this.mapFormDataToStaticFieldLinks(),
    });
  }

  watchLanguageChange(): void {
    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForDropdownPlaceholders();
    });
  }

  private mapFormDataToStaticFieldLinks() {
    const mappableStaticFieldLinkItems: FormBuilderFieldStaticFieldLink[] =
      this.staticFieldLinkService.getMappableStaticFieldLinkItemsByType(StaticFieldLinkType.INSURED_PERSON);

    mappableStaticFieldLinkItems.forEach((staticFieldLink: FormBuilderFieldStaticFieldLink) => {
      if (staticFieldLink.fieldKey) {
        const clientFormKeyByIapp1Id = this.staticFieldLinkService.getClientFormKeyByIapp1Id(staticFieldLink.iapp1Id);
        const clientControl = this.clientForm.get(clientFormKeyByIapp1Id);

        this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(
          StaticFieldLinkType.INSURED_PERSON,
          clientFormKeyByIapp1Id,
          clientControl?.value
        );
      }
    });
    this.mapHumaniaStaticFieldLink();
  }

  private mapHumaniaStaticFieldLink() {
    const gender = this.staticFieldLinkService.getStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.INSURED_PERSON, 'gender');
    const selectedTitle = this.staticFieldLinkService.getStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.INSURED_PERSON, 'title');
    const nonExistentHumaniaTitles = [...STATIC_FIELD_LINK_ALTERNATE_VALUES.title.DR, ...STATIC_FIELD_LINK_ALTERNATE_VALUES.title.REV];
    let humaniaTitle;
    if (gender && selectedTitle && nonExistentHumaniaTitles?.includes(selectedTitle.toUpperCase().replace('.', ''))) {
      humaniaTitle = this.getHumaniaTitleByGender(gender);
    } else {
      humaniaTitle = selectedTitle;
    }
    this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(
      StaticFieldLinkType.INSURED_PERSON,
      ClientFormEnum.HUMANIA_TITLE,
      humaniaTitle
    );
  }

  getHumaniaTitleByGender(gender: string): string {
    if (this.translate.currentLang === LanguagesEnum.EN) {
      return gender.toLowerCase() === Sex.MALE ? TitleEnumEn.MR : TitleEnumEn.MS;
    }
    if (this.translate.currentLang === LanguagesEnum.FR) {
      return TitleEnumFr.AUTRE;
    }
  }

  fetchDataAndFillForm(): void {
    const observables = [];

    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
    if (this.applicationId) {
      observables.push(this.applicationsProcessService.getFna(this.applicationId));
    } else {
      observables.push(of(null));
    }

    observables.push(this.clientManagementService.getAllClientsFiltered(1, '5', 'firstName', 'ASC', ''));
    observables.push(this.applicationsProcessService.getAllProducts());

    this.loading = true;

    forkJoin(observables)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.clients = res[1].data;
          this.products = res[2];
          this.loading = false;

          this.setFnaStateFromApplication(res[0]);

          // this.loadCurrentAdvisor(this.application.advisorId);
          // this.fillFormWithObjectValues(this.clientForm, this.application.client);

          this.fillForm();
        }
      });
  }

  setFnaStateFromApplication(fnaPayload: any) {
    const previouslyUploadedFnaFileExists = !!fnaPayload?.s3Key && !!fnaPayload?.s3Link;
    const fnaFormWasPreviouslyFilled = !!fnaPayload?.isApplicable;

    switch (previouslyUploadedFnaFileExists) {
      case true:
        this.fileKey = fnaPayload;
        this.fnaSelection = this.fnaOptions[2];
        break;
      case false:
        switch (fnaFormWasPreviouslyFilled) {
          case true:
            this.fnaForm.patchValue(fnaPayload);
            this.fnaSelection = this.fnaOptions[0];
            this.fnaFormIsValid.emit(this.fnaForm.valid);
            break;
          case false:
            this.fnaSelection = this.fnaOptions[1];
            break;
        }
    }
  }

  fillForm() {
    if (!this.selectedClient) {
      return;
    }

    this.clientForm.patchValue({
      title: this.selectedClient.title,
      gender: this.selectedClient.gender,
      firstName: this.selectedClient.firstName,
      middleName: this.selectedClient.middleName,
      lastName: this.selectedClient.lastName,
      language: this.selectedClient.language?.code || 'en',
      email: this.selectedClient.email,
      birthDate: this.selectedClient.birthDate ? this.datesService.converYearMonthDayToDayMonthYear(this.selectedClient.birthDate) : null,
      smokerStatus: this.selectedClient.smokerStatus,
      reflexSmokerStatus: this.selectedClient.reflexSmokerStatus,
      phoneNumber: this.selectedClient.phoneNumber,
      countryOfBirth: this.selectedClient.countryOfBirth || ({} as Country),
      address: this.selectedClient.address,
      province: this.selectedClient.province,
      city: this.selectedClient.city,
      postalCode: this.selectedClient.postalCode,
      note: this.currentApplicationNotes || this.selectedClient?.note || '',
    });
  }

  onProductSelectionChanged(products) {
    if (products) {
      const iappProducts = products.map((product) => {
        product.iappProduct.risk = product.risk;
        return product.iappProduct;
      });

      this.selectedProducts = iappProducts?.filter((product) => product.selected === true);
      this.applicationsProcessService.selectedProducts.next(this.selectedProducts);
      this.productSelectionChanged.emit(this.selectedProducts);
    }
  }

  hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  onMedicalQuestionnairesAreValidChanged(medicalQuestionnairesAreValid) {
    this.medicalQuestionnairesAreValid.emit(medicalQuestionnairesAreValid);
  }

  onChangedStaticApplicationDisclosureAnswer() {
    this.staticApplicationDisclosureAnswerChanged.emit();
  }

  onFnaFormIsValid(fnaFormIsValid) {
    this.fnaFormIsValid.emit(fnaFormIsValid);
  }

  selectClient(client, index) {
    this.selectedClient = client;
    this.selectedIndex = index;
    this.fillForm();
  }

  getTranslationsForDropdownPlaceholders() {
    this.translate
      .get('pages.newClient.sections.clientInformation.fields.language.placeholder')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.translatedPlaceholdersForDropdowns.language = res;
      });
    this.translate
      .get('pages.newClient.sections.clientInformation.fields.countryOfBirth.placeholder')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.translatedPlaceholdersForDropdowns.countryOfBirth = res;
      });
    this.translate
      .get('pages.newClient.sections.address.fields.province.placeholder')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.translatedPlaceholdersForDropdowns.province = res;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
