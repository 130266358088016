/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategorySlugs, FieldCategory, Themes } from '@iapplication2/interfaces';
import { DarkThemeService, FieldCategoryService, InteractiveFormBuilderService } from '@iapplication2/services';
import { MenuItem } from 'primeng/api';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { categoryTitleDuplicationValidator } from '@iapplication2/validators';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'iapplication2-field-categories',
  templateUrl: './field-categories.component.html',
  styleUrls: ['./field-categories.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class FieldCategoriesComponent implements OnInit, OnDestroy {
  fieldCategories: FieldCategory[] = [];
  currentCategoryTitle: string = '';

  fieldItems: MenuItem[];

  editCategoryForm: FormGroup;

  editing: boolean = false;

  indexOfCategoryToEdit: number;

  categorySlugEnum = CategorySlugs;

  isDarkTheme: boolean;

  private unsubscribe = new Subject<void>();

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private confirmationService: ConfirmationService,
    private fieldCategoryService: FieldCategoryService,
    private darkThemeService: DarkThemeService

  ) {}

  ngOnInit(): void {
    this.darkThemeService.themeChanged.subscribe(value => {
        this.isDarkTheme = value === Themes.DARK;
    });

    this.createcategorySettingsMenu();
    this.getListOfCategories();
    this.editCategoryForm = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      slug: new FormControl(null, [Validators.required]),
    });
    this.interactiveFormBuilderService.sidebarListWasUpdated.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getListOfCategories();
    });
  }

  getListOfCategories(): void {
    this.fieldCategoryService.getFieldCategories();
    this.fieldCategoryService.fatchedCategories.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      this.fieldCategories = res;
      console.log(this.fieldCategories);
    });
  }

  toggleMenu(item: FieldCategory, menu: any, ev: any, index: number): void {
    const editMenuItem = this.fieldItems[0].items[0];
    const deleteMenuItem = this.fieldItems[0].items[1];
    editMenuItem.command = () => this.editCategory(item, index);
    deleteMenuItem.command = () => this.confirmFieldDeletion(item);
    menu.toggle(ev);
  }

  editCategory(category: FieldCategory, index: number): void {
    this.editing = true;
    this.indexOfCategoryToEdit = index;
    this.editCategoryForm.controls['title'].setValidators([
      Validators.required,
      categoryTitleDuplicationValidator(this.fieldCategoryService, category.title),
    ]);
    this.editCategoryForm.controls['title'].setValue(category.title);
    this.editCategoryForm.controls['slug'].setValue(_.camelCase(category.title));
  }

  deleteCategory(category: FieldCategory): void {
    // this.fieldCategories = this.fieldCategories.filter((fieldCategory) => fieldCategory !== category);
    this.fieldCategoryService.deleteCategory(category);
  }

  save(category: FieldCategory): void {
    this.editing = false;
    this.indexOfCategoryToEdit = null;
    category.title = this.editCategoryForm.controls['title'].value;
    category.slug = _.camelCase(this.editCategoryForm.controls['title'].value);
    this.fieldCategoryService.updateCategory(category);
  }

  createcategorySettingsMenu(): void {
    this.fieldItems = [
      {
        label: 'Category settings',
        items: [
          {
            label: 'Edit category name',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete category',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
  }

  drop(event: CdkDragDrop<FieldCategory[]>): void {
    this.moveCategory(event.previousIndex + 1, event.currentIndex + 1);
  }

  moveCategory(previousIndex: number, currentIndex: number): void {
    const element = this.fieldCategories[previousIndex];
    this.fieldCategories.splice(previousIndex, 1);
    this.fieldCategories.splice(currentIndex, 0, element);
    this.fieldCategories.forEach((category, index) => (category.position = index + 1));
    this.updateCategoriesPosition();
  }

  updateCategoriesPosition() {
    this.fieldCategoryService.updateCategories(this.fieldCategories);
    // this.fieldCategories.forEach((category: FieldCategory, index) => {
    //   category.position = index;
    //   this.fieldCategoryService.updateCategory(category);
    // });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  confirmFieldDeletion(item: FieldCategory): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this category? This action cannot be undone.',
      accept: () => {
        this.deleteCategory(item);
      },
    });
  }
}
