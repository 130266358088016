/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilderField,
  FormBuilderFieldTypeOption,
} from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-field-details-type-signature',
  templateUrl: './field-details-type-signature.component.html',
  styleUrls: ['./field-details-type-signature.component.scss'],
})
export class FieldDetailsTypeSignatureComponent {
  @Input() field: FormBuilderField;
  @Input() option: FormBuilderFieldTypeOption;
  @Input() isInEditMode: boolean = false;
  @Input() isInFieldBuilder: boolean = false;
  @Input() isInModal: boolean = false;
  @Output()
  deleteOption: EventEmitter<FormBuilderFieldTypeOption> = new EventEmitter<FormBuilderFieldTypeOption>();

  deleteOptionFromSelectedOptions(): void {
    this.deleteOption.emit(this.option);
  }
}
