<div class="card" fxLayout="row" fxLayoutAlign="space-between end">
  <div fxLayout="row">
    <iapplication2-change-advisor *ngIf="advisor" [currentAdvisor]="advisor"></iapplication2-change-advisor>
    <div fxLayout="column" fxLayoutAlign="center start" class="ml-3 pl-3 left-border">
      <p class="mb-0">
        <b>{{ 'applicationManagement.applicationPage.leadId' | translate }}:</b>
        {{ applicationsProcessService.currentApplication?.client?.id }}
      </p>
      <p class="mb-0">
        <b>{{ 'applicationManagement.applicationPage.status' | translate }}: </b>
        <iapplication2-product-status-tag
          style="display: inline-block"
          [status]="applicationsProcessService.currentApplication?.status"
          [scheduled]="applicationsProcessService.currentApplication?.scheduledAt"
        ></iapplication2-product-status-tag>
      </p>
      <p *ngIf="applicationsProcessService.currentApplication?.submittedOn">
        <b>{{ 'applicationManagement.applicationPage.submittedOn' | translate }}:</b>
        {{ applicationsProcessService.currentApplication?.submittedOn | date }}
      </p>
      <p *ngIf="applicationsProcessService.currentApplication?.scheduleDate">
        <b>{{ 'applicationManagement.applicationPage.scheduleDate' | translate }}:</b>
        {{ applicationsProcessService.currentApplication?.scheduleDate | date }}
      </p>
      <p *ngIf="applicationsProcessService.currentApplication?.date">
        <b>{{ 'applicationManagement.applicationPage.lastModified' | translate }}:</b>
        {{ applicationsProcessService.currentApplication?.date | date }}
      </p>
    </div>
  </div>
  <div>
    <p-button
      [hidden]="!isApplicationEditable"
      [label]="'applicationManagement.applicationPage.continueApplication' | translate"
      class="mb-2"
      [routerLink]="['/application', 'edit', applicationId]"
    ></p-button>
  </div>
</div>
<p-tabView>
  <p-tabPanel [header]="'applicationManagement.applicationPage.applicationInfo' | translate">
    <iapplication2-application-information *ngIf="productsAvailable; else emptyList"></iapplication2-application-information>
    <ng-template #emptyList class="overview-info unselectable">
      {{ 'viewApplicationPage.noProductsSelected' | translate }}
    </ng-template>
  </p-tabPanel>
  <p-tabPanel [header]="'applicationManagement.applicationPage.clientInfo' | translate">
    <iapplication2-client-information [clientForm]="clientForm" [isEditable]="false"></iapplication2-client-information>
  </p-tabPanel>
  <p-tabPanel
    *ngIf="
      applicationsProcessService.currentApplication?.status?.name === ApplicationStatusOptions.SCHEDULED ||
      applicationsProcessService.currentApplication?.scheduleDate
    "
    [header]="'applicationManagement.applicationPage.schedule' | translate"
  >
    <div *ngIf="!scheduleApplicationPremiumReceived" fxFlex fxLayout="column" fxLayoutAlign="center center">
      <span class="mb-3">{{ 'applicationManagement.applicationPage.premiumsLoading' | translate }}</span>
      <p-progressSpinner strokeWidth="4" class="app-loader-spinner"></p-progressSpinner>
    </div>
    <iapplication2-schedule-application-modal
      *ngIf="scheduleApplicationPremiumReceived"
      [premiums]="premiums"
      (scheduledDateSelected)="onScheduledDateSelected($event)"
    ></iapplication2-schedule-application-modal>
  </p-tabPanel>
  <p-tabPanel [header]="'applicationManagement.applicationPage.digitalSignatures' | translate">
    <iapplication2-application-digital-signatures
      *ngIf="digitalSignatures"
      (applicationStatusChanged)="onApplicationStatusChanged($event)"
    ></iapplication2-application-digital-signatures>
  </p-tabPanel>
</p-tabView>
<p-toast key="reschedule-application-message"></p-toast>
