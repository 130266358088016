/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdvisorsService {
  constructor(private http: HttpClient) {}

  getAdvisorList() {
    return of(null);
  }

  getAdvisorByAdvisorId(advisorId: number) {
    return of(null);
  }

  searchAdvisors(query: string) {
    return of(null);
  }
}
