import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'iapplication2-required',
  templateUrl: './required.component.html',
  styleUrls: ['./required.component.scss'],
})
export class RequiredComponent implements OnInit, OnChanges {
  @Input() value?: any;
  @Input() required?: boolean;
  @Input() MIBRequired?: boolean;
  @Input() rowRequired?: boolean;
  @Input() optionRequired?: boolean;
  @Input() disclosurePlayRequired?: boolean;

  hasValue = false;

  ngOnInit() {
    this.checkIfValueExists();
  }

  checkIfValueExists() {
    const onlySpacesRegexp = /^\s+$/;
    this.hasValue = this.value ? !onlySpacesRegexp.test(this.value) : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value) {
      this.checkIfValueExists();
    }
  }
}
