import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { APP_CONFIG } from '@iapplication2/app-config';
import { APP_TYPE, ENV, envType, Language, LocalStorageItems, Themes } from '@iapplication2/interfaces';
import {
  AuthService,
  DarkThemeService,
  MenuActionsService,
  ProgressSpinnerService,
  ResponsiveService,
  UtilsService,
} from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  @Input() isLoginPage: boolean;
  isLightTheme: boolean;

  isMenuStatic: boolean;

  isMobileMenuActive: boolean;

  items: MenuItem[];

  languages: Language[];

  languagesForm: FormGroup;
  globalSearchForm: FormGroup;

  appType = APP_TYPE;
  envType = envType;

  isEnvDevelop: boolean;

  unsubscribe: Subject<unknown> = new Subject();

  constructor(
    private utilsService: UtilsService,
    private darkThemeService: DarkThemeService,
    private responsiveService: ResponsiveService,
    private menuActionsService: MenuActionsService,
    private translateService: TranslateService,
    private authService: AuthService,
    public progressSpinnerService: ProgressSpinnerService,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit() {
    this.isLightTheme = JSON.parse(localStorage.getItem('isLightTheme'));

    this.menuActionsService.staticMenu.subscribe((isStatic) => {
      this.isMenuStatic = isStatic;
    });

    this.items = [
      // {
      //   label: 'Profile',
      //   icon: 'pi pi-fw pi-user',
      //   routerLink: '/profile',
      // },
      // {
      //   label: 'Log Out',
      //   icon: 'pi pi-fw pi-sign-out',
      //   command: () => this.logout(),
      // },
    ];
    this.darkThemeService.themeChanged.subscribe((theme) => {
      this.changeLightModeClasses(theme);
    });
    this.utilsService.getLanguages().subscribe((res) => {
      if (res) {
        this.languages = res as Language[];
      }
    });

    this.createLanguagesForm();
    this.createGlobalSearchForm();
    this.updateLanguageSelectorWhenLanguageChanges();
    this.blockLanguageSwitcherOnApplications();
  }

  checkIfEnvIsDevelop() {
    this.isEnvDevelop = this.appConfig.environmentType === envType.DEVELOP;
  }

  createLanguagesForm() {
    this.languagesForm = new FormGroup({
      language: new FormControl(null),
    });
    this.languagesForm.controls['language'].setValue(this.translateService.currentLang);
    this.blockOrUnblockLanguagesForm(this.router.url);
  }

  createGlobalSearchForm() {
    this.globalSearchForm = new FormGroup({
      searchQuery: new FormControl(null),
    });
  }

  languageChange() {
    const selectedLanguage = this.languagesForm.controls['language'].value;
    this.translateService.use(selectedLanguage);
    localStorage.setItem(LocalStorageItems.LANGUAGE, selectedLanguage);
  }

  updateLanguageSelectorWhenLanguageChanges() {
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
      this.languagesForm.controls['language'].setValue(event.lang);
    });
  }

  public getLightModeLayoutClasses() {
    return {
      'layout-topbar-light': this.isLightTheme,
      'layout-menu-light': this.isLightTheme,
      'layout-topbar-dark': !this.isLightTheme,
      'layout-menu-dark': !this.isLightTheme,
    };
  }

  public getLogoPathForCurrentLightMode() {
    return this.isLightTheme ? '/assets/layout/images/logo-full.svg' : '/assets/layout/images/logo-full.svg';
  }

  public onMenuButtonClick(event) {
    this.isMenuStatic = !this.isMenuStatic;

    if (this.responsiveService.isMobile()) {
      this.isMobileMenuActive = !this.isMobileMenuActive;

      this.menuActionsService.changedMenuState(this.isMenuStatic);
      this.menuActionsService.changedMobileMenuState(this.isMobileMenuActive);
      if (this.isMenuStatic) {
        this.responsiveService.blockBodyScroll();
      } else {
        this.responsiveService.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  public changeLightModeClasses(theme: string) {
    this.isLightTheme = theme === Themes.LIGHT;
  }

  blockLanguageSwitcherOnApplications() {
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.blockOrUnblockLanguagesForm(event.url);
      }
    });
  }

  blockOrUnblockLanguagesForm(url: string) {
    if (url.includes('/application/')) {
      this.languagesForm.controls['language'].disable();
    } else {
      this.languagesForm.controls['language'].enable();
    }
  }

  logout() {
    this.authService.logout();
  }

  searchWithProvidedQuery() {
    const query = this.globalSearchForm.controls['searchQuery'].value;
    this.globalSearchForm.controls['searchQuery'].setValue('');
    this.router.navigate(['/search'], { queryParams: { q: query } });
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe;
  }
}
