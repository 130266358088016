import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Country, Language, LanguagesEnum } from '@iapplication2/interfaces';
import { Province } from '@iapplication2/interfaces';
import { UtilsService } from '@iapplication2/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iapplication2-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<unknown> = new Subject();

  languages: Language[];

  selectedDrop: SelectItem;

  countries: Country[];

  provinces: Province[];

  selectedProvince: Province;

  selectedCountry: Country;

  displayPhoneVerificationModal: boolean = false;

  displayPasswordResetModal: boolean = false;

  constructor(private utilsService: UtilsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.getProvinces();
    this.getCountries();
    this.utilsService
      .getLanguages()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.languages = res as Language[];
        }
      });
    this.setOptionsOnLangChange();
  }

  setOptionsOnLangChange() {
    this.translateService.onLangChange.pipe().subscribe(() => {
      this.getProvinces();
      this.getCountries();
    });
  }

  getProvinces() {
    this.utilsService
      .getProvincesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.provinces = res?.map((province, index) => {
            const name = this.getNameKeyBySelectedLanguage();
            return {
              id: index + 1,
              name: province[name],
              abbreviation: province.prov_short,
            };
          });
        },
      });
  }

  getNameKeyBySelectedLanguage(): string {
    let name: string;
    switch (this.translateService.currentLang) {
      case LanguagesEnum.EN:
        name = 'name';
        break;
      default:
        name = `name_${this.translateService.currentLang}`;
    }
    return name;
  }

  getCountries() {
    this.utilsService
      .getCountriesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.countries = res?.data
            ?.map((country) => {
              const name = this.getNameKeyBySelectedLanguage();
              return {
                id: country.id,
                name: country[name],
                code: country.code,
              };
            })
            .sort((country1, country2) => {
              return country1.name.localeCompare(country2.name);
            });
        },
      });
  }

  showPhoneValidationDialog() {
    this.displayPhoneVerificationModal = true;
  }

  showPasswordResetDialog() {
    this.displayPasswordResetModal = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
