import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ = require('lodash');
import { isObservable } from 'rxjs';

@Pipe({
  name: 'translateBy',
  pure: false, // required to update the value when the promise is resolved
})
export class TranslateByPipe implements PipeTransform {
  value = '';
  lastKey: string;
  lastParams: any[];

  constructor(private translate: TranslateService, private _ref: ChangeDetectorRef) {}

  updateValue(key: string, interpolateParams?: unknown, translations?: any): void {
    const onTranslation = (res: string) => {
      this.value = res !== undefined ? res : key;
      this.lastKey = key;
      this._ref.markForCheck();
    };
    if (translations) {
      const res = this.translate.getParsedResult(translations, key, interpolateParams);
      if (isObservable(res.subscribe)) {
        res.subscribe(onTranslation);
      } else {
        onTranslation(res);
      }
    } else this.translate.get(key, interpolateParams).subscribe(onTranslation);
  }

  transform(query: string, lang: string, ...args: any[]): any {
    if (!query || !query.length) {
      return query;
    }

    // if we ask another time for the same key, return the last value
    if (_.isEqual(query, this.lastKey) && _.isEqual(args, this.lastParams)) {
      return this.value;
    }

    let interpolateParams: unknown;
    if (!!args[0] && args.length) {
      if (typeof args[0] === 'string' && args[0].length) {
        // we accept objects written in the template such as {n:1}, {'n':1}, {n:'v'}
        // which is why we might need to change it to real JSON objects such as {"n":1} or {"n":"v"}
        const validArgs: string = args[0].replace(/(')?([a-zA-Z0-9_]+)(')?(\s)?:/g, '"$2":').replace(/:(\s)?(')(.*?)(')/g, ':"$3"');
        try {
          interpolateParams = JSON.parse(validArgs);
        } catch (e) {
          throw new SyntaxError(`Wrong parameter in TranslatePipe. Expected a valid Object, received: ${args[0]}`);
        }
      } else if (typeof args[0] === 'object' && !Array.isArray(args[0])) {
        interpolateParams = args[0];
      }
    }

    // store the query, in case it changes
    this.lastKey = query;

    // store the params, in case they change
    this.lastParams = args;

    // set the value
    this.updateValue(
      query,
      interpolateParams,
      this.translate.translations[lang] // <<<<< This is the main requried change to return the translation for the provided lang.
    );

    return this.value;
  }
}
