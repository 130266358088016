import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from '@iapplication2/interfaces';
import { FormHelper } from '@iapplication2/superclass';
import { DefaultFormValidator, StringValidator } from '@iapplication2/validators';

@Component({
  selector: 'iapplication2-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
})
export class ClientInfoComponent extends FormHelper implements OnInit {
  clientForm: FormGroup;
  formSubmissionTriggered = false;
  @Input() client: Client;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.createClientForm();
    this.fillFormWithObjectValues(this.clientForm, this.client);
  }

  createClientForm() {
    this.clientForm = new FormGroup({
      firstName: new FormControl(null, DefaultFormValidator.firstName),
      middleName: new FormControl(null, [Validators.maxLength(50)]),
      lastName: new FormControl(null, DefaultFormValidator.lastName),
      language: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [
        StringValidator.requiredNoOnlySpaces(),
        Validators.required,
        Validators.email,
        Validators.maxLength(100),
      ]),
      birthDate: new FormControl(null, [Validators.required]),
      smokerStatus: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      countryOfBirth: new FormControl(null, [Validators.required]),
      address: new FormControl(null, DefaultFormValidator.address),
      province: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      postalCode: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      note: new FormControl(null, [Validators.maxLength(500)]),
    });
  }
}
