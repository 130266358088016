import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, DarkThemeService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { Themes } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  loginForm: FormGroup;
  formSubmissionTriggered = false;
  isLightTheme: boolean;
  loginErrorMessage: string;
  @ViewChild('passwordInput') passwordInput;

  unsubscribe: Subject<unknown> = new Subject();

  constructor(private authService: AuthService, private darkThemeService: DarkThemeService) {}

  ngOnInit(): void {
    this.createLoginForm();
    this.isLightTheme = JSON.parse(localStorage.getItem('isLightTheme'));
  }

  ngAfterViewInit(): void {
    this.passwordInput['cd'].detectChanges();
  }

  public getLogoPathForCurrentLightMode() {
    this.darkThemeService.themeChanged.subscribe((theme) => {
      this.isLightTheme = theme === Themes.LIGHT;
    });
    return this.isLightTheme ? '/assets/layout/images/logo-full.svg' : '/assets/layout/images/logo-full-black.svg';
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  submitLoginForm() {
    this.formSubmissionTriggered = true;
    if (this.loginForm.valid) {
      this.login();
    }
  }

  login() {
    const { email, password } = this.loginForm.getRawValue();
    this.authService.loginUser(email, password).subscribe({
      next: (res: any) => {
        this.authService.setToken(res.body.accessToken);
        this.authService.navigateAfterLogin();
        this.authService.getUserInformation();
        this.loginErrorMessage = null;
      },
      error: (e) => {
        this.loginErrorMessage = e.error.message;
      },
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
