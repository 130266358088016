import { Country, Province } from './address.model';
import { Language } from './language.model';

export interface User {
  id: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  language?: Language;
  email?: string;
  phoneNumber?: string;
  address?: string;
  country?: Country;
  province?: Province;
  postalCode?: string;
  code?: string;
  role?: Role;
}

export interface Role {
  id: number;
  name: string;
  type: string;
  createdAt?: string;
  updatedAt?: string;
}
