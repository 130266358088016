export interface StaticApplicationDisclosureAnswer {
  id?: number;
  disclosureType: string;
  answer: boolean;
  applicationId?: number;
}

export interface StaticApplicationDisclosure {
  disclosureType: string;
  condition: boolean;
  mp3File: string;
}
