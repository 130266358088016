import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProgressSpinnerService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent implements OnInit, OnDestroy {
  showProgressSpinner = false;

  unsubscribe: Subject<unknown> = new Subject();

  constructor(private progressSpinnerService: ProgressSpinnerService) {}

  ngOnInit() {
    this.createProgressSpinnerSubscription();
  }

  private createProgressSpinnerSubscription(): void {
    this.progressSpinnerService.showProgressSpinner.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (show: boolean) => (this.showProgressSpinner = show),
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
