/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { DarkModeSwitcherComponent } from './dark-mode-switcher/dark-mode-switcher.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { RouterModule } from '@angular/router';
import { TopbarComponent } from './topbar/topbar.component';
import { MenuModule } from 'primeng/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { UserProfileComponent } from './user-profile/user-profile.component';

import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { MenuComponent } from './menu/menu.component';
import { AppMenuitemComponent } from './menu/app.menuItem.component';
import { MenuService } from './menu/app.menu.service';
import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DividerModule } from 'primeng/divider';
import { CheckboxModule } from 'primeng/checkbox';

import { FlexLayoutModule } from '@angular/flex-layout';
import { DynamicTopbarInformationComponent } from './topbar/dynamic-topbar-information/dynamic-topbar-information.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FieldsetModule } from 'primeng/fieldset';
import { DynamicTopbarFormBuilderInformationComponent } from './topbar/dynamic-topbar-information/components/dynamic-topbar-form-builder-information/dynamic-topbar-form-builder-information.component';
import { DynamicTopbarFieldBuilderInformationComponent } from './topbar/dynamic-topbar-information/components/dynamic-topbar-field-builder-information/dynamic-topbar-field-builder-information.component';
import { FormBuilderUiModule } from './form-builder/form-builder-ui.module';
import { FieldSettingsModalModule } from '@iapplication2/interactive-form-builder';
import { DynamicTopbarFormDisplayInformationComponent } from './topbar/dynamic-topbar-information/components/dynamic-topbar-form-display-information/dynamic-topbar-form-display-information.component';
import { FieldCategoriesModule } from '../../../interactive-form-builder/src/lib/field-categories/field-categories.module';
import { FormFieldErrorModule } from './form-field-error/form-field-error.module';
import { RegisterComponent } from './auth/register/register.component';
import { MessagesModule } from 'primeng/messages';
import { PipesModule } from '@iapplication2/pipes';
import { ProgressSpinnerModule } from './progress-spinner/progress-spinner.module';
import { ProgressSpinnerModule as PrimeProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageModule } from 'primeng/message';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ToastModule } from 'primeng/toast';

import { SignatureComponent } from './signature/signature.component';

@NgModule({
  imports: [
    SignaturePadModule,
    CommonModule,
    AvatarModule,
    ButtonModule,
    DropdownModule,
    ToggleButtonModule,
    FormsModule,
    MenuModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    PasswordModule,
    InputMaskModule,
    InputTextModule,
    SelectButtonModule,
    RouterModule,
    SharedTranslateModule,
    DialogModule,
    RadioButtonModule,
    FlexLayoutModule,
    DividerModule,
    CheckboxModule,
    OverlayPanelModule,
    FieldsetModule,
    FormBuilderUiModule,
    FieldSettingsModalModule,
    FieldCategoriesModule,
    FormFieldErrorModule,
    MessagesModule,
    ProgressSpinnerModule,
    PrimeProgressSpinnerModule,
    PipesModule,
    MessageModule,
    ToastModule,
  ],
  exports: [
    ButtonModule,
    AvatarModule,
    DropdownModule,
    DarkModeSwitcherComponent,
    UserProfileComponent,
    SignatureComponent,
    ToggleButtonModule,
    FormsModule,
    TopbarComponent,
    MenuModule,
    RouterModule,
    ReactiveFormsModule,
    PasswordModule,
    InputMaskModule,
    InputTextModule,
    MenuComponent,
    FormFieldErrorModule,
    ProgressSpinnerModule,
  ],
  declarations: [
    DarkModeSwitcherComponent,
    LoginComponent,
    ForgotComponent,
    TopbarComponent,
    UserProfileComponent,
    SignatureComponent,
    MenuComponent,
    AppMenuitemComponent,
    PhoneVerificationComponent,
    ChangePasswordComponent,
    DynamicTopbarInformationComponent,
    DynamicTopbarFormBuilderInformationComponent,
    DynamicTopbarFieldBuilderInformationComponent,
    DynamicTopbarFormDisplayInformationComponent,
    RegisterComponent,
  ],
  providers: [MenuService],
})
export class UiModule {}
