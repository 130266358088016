import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { APP_CONFIG } from '@iapplication2/app-config';
import {
  ApplicationStatus,
  ENV,
  IApplicationDigitalSignature,
  MessagesModel,
  Severity,
  SignatureChanelTypeEnum,
  SignatureStatusEnum,
  SignatureTypeEnum,
} from '@iapplication2/interfaces';
import { ApplicationsProcessService, ESignatureService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil, distinctUntilChanged, debounceTime, share } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-application-digital-signatures',
  templateUrl: './application-digital-signatures.component.html',
  styleUrls: ['./application-digital-signatures.component.scss'],
})
export class ApplicationDigitalSignaturesComponent implements OnInit {
  digitalSignatures: IApplicationDigitalSignature[];
  digitalSignaturesForm = new FormGroup({});

  SignatureTypeEnum = SignatureTypeEnum;
  SignatureStatusEnum = SignatureStatusEnum;
  initialFormValue: any;
  initialApplicationStatus: ApplicationStatus;
  @Output() applicationStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  translatedResendSignatureMessages: MessagesModel = {
    success: {
      summary: '',
      detail: '',
    },
    error: {
      summary: '',
      detail: '',
    },
  };

  translatedUpdateSignatureMessages: MessagesModel = {
    success: {
      summary: '',
      detail: '',
    },
    error: {
      summary: '',
      detail: '',
    },
  };

  constructor(
    private eService: ESignatureService,
    private applicationsProcessService: ApplicationsProcessService,
    private messageService: MessageService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit(): void {
    this.loadSignaturesList();
    this.listenToLangChanges();
  }

  getTranslatedMessages() {
    this.translateService
      .get('applicationDigitalSignatures.messages')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((messages: any) => {
        this.translatedResendSignatureMessages.error.summary = messages.resendSignatureError;
        this.translatedResendSignatureMessages.success.summary = messages.resendSignatureSuccess;
        this.translatedUpdateSignatureMessages.success.summary = messages.updateSignatureSuccess;
        this, (this.translatedUpdateSignatureMessages.error.summary = messages.updateSignatureError);
      });
  }

  listenToLangChanges() {
    const combinedLangChanges$ = combineLatest([this.translateService.onDefaultLangChange, this.translateService.onLangChange]);
    combinedLangChanges$
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(() => {
          this.getTranslatedMessages();
        })
      )
      .subscribe({
        next: () => {
          this.getTranslatedMessages();
        },
      });
  }

  loadSignaturesList(): void {
    this.eService
      .getApplicationDigitalSignatures(this.applicationsProcessService.currentApplication.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((signatures: IApplicationDigitalSignature[]) => {
        this.setInitialApplicationStatus(signatures);
        this.setDigitalSignatures(signatures);
        this.initializeFormControls();
      });
  }

  setDigitalSignatures(signatures: IApplicationDigitalSignature[]) {
    this.digitalSignatures = signatures?.map((signature) => {
      signature.isSignatureChanged = false;
      return signature;
    });
  }
  setInitialApplicationStatus(signatures: IApplicationDigitalSignature[]) {
    this.initialApplicationStatus = signatures.find((signature) => !!signature.applicationStatus)?.applicationStatus;
  }

  checkForApplicationStatusChange(signatures: IApplicationDigitalSignature[]) {
    const applicationCurrentStatus = signatures.find((signature) => !!signature.applicationStatus)?.applicationStatus;
    if (applicationCurrentStatus.name !== this.initialApplicationStatus.name) {
      this.applicationStatusChanged.emit(true);
    }
  }

  initializeFormControls() {
    this.digitalSignatures?.forEach((signature: IApplicationDigitalSignature, index) => {
      this.digitalSignaturesForm.addControl(
        `signature-${index}`,
        new FormControl(
          {
            value: signature.method.includes(SignatureChanelTypeEnum.VOICESIGNATURE)
              ? SignatureTypeEnum.VOICESIGNATURE
              : signature.method.includes(SignatureChanelTypeEnum.SMS) || signature.method.includes(SignatureChanelTypeEnum.EMAIL)
              ? SignatureTypeEnum.ESIGNATURE
              : null,
            disabled: signature.status === SignatureStatusEnum.SIGNED,
          },
          [Validators.required]
        )
      );
      this.digitalSignaturesForm.addControl(
        `email-${index}`,
        new FormControl(
          {
            value: signature.method.includes(SignatureChanelTypeEnum.EMAIL),
            disabled:
              signature.status === SignatureStatusEnum.SIGNED ||
              signature.method.includes(SignatureChanelTypeEnum.VOICESIGNATURE) ||
              !signature.method,
          },
          []
        )
      );
      this.digitalSignaturesForm.addControl(
        `sms-${index}`,
        new FormControl(
          {
            value: signature.method.includes(SignatureChanelTypeEnum.SMS),
            disabled:
              signature.status === SignatureStatusEnum.SIGNED ||
              signature.method.includes(SignatureChanelTypeEnum.VOICESIGNATURE) ||
              !signature.method,
          },
          []
        )
      );
      this.digitalSignaturesForm.addControl(
        `phoneNumber-${index}`,
        new FormControl(
          {
            value: signature.phone || '',
            disabled: signature.status === SignatureStatusEnum.SIGNED || !signature.method.includes(SignatureChanelTypeEnum.SMS),
          },
          [Validators.required]
        )
      );
      this.digitalSignaturesForm.addControl(
        `emailValue-${index}`,
        new FormControl(
          {
            value: signature.email || '',
            disabled: signature.status === SignatureStatusEnum.SIGNED || !signature.method.includes(SignatureChanelTypeEnum.EMAIL),
          },
          [Validators.email]
        )
      );
      this.digitalSignaturesForm.addControl(
        `fullName-${index}`,
        new FormControl(
          { value: signature.fullName || '', disabled: signature.status === SignatureStatusEnum.SIGNED || !signature.method.length },
          [Validators.required]
        )
      );

      this.digitalSignaturesForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(300), takeUntil(this.unsubscribe)).subscribe(() => {
        this.checkForFormChanges(index);
      });
    });
  }

  repopulateFormRow(index: number) {
    this.eService
      .getApplicationDigitalSignatures(this.applicationsProcessService.currentApplication.id)
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), share(), debounceTime(300))
      .subscribe((signatures: IApplicationDigitalSignature[]) => {
        this.setDigitalSignatures(signatures);
        this.checkForApplicationStatusChange(signatures);
        const signature = this.digitalSignatures[index];
        this.getFormControl(`signature-${index}`).setValue(
          signature.method.includes(SignatureChanelTypeEnum.VOICESIGNATURE)
            ? SignatureTypeEnum.VOICESIGNATURE
            : signature.method.includes(SignatureChanelTypeEnum.SMS) || signature.method.includes(SignatureChanelTypeEnum.EMAIL)
            ? SignatureTypeEnum.ESIGNATURE
            : null
        );
        this.getFormControl(`email-${index}`).setValue(signature.method.includes(SignatureChanelTypeEnum.EMAIL));
        this.getFormControl(`sms-${index}`).setValue(signature.method.includes(SignatureChanelTypeEnum.SMS));
        this.getFormControl(`phoneNumber-${index}`).setValue(
          (signature.method.includes(SignatureChanelTypeEnum.SMS) && signature.phone) || ''
        );
        this.getFormControl(`emailValue-${index}`).setValue(
          (signature.method.includes(SignatureChanelTypeEnum.EMAIL) && signature.email) || ''
        );
        this.getFormControl(`fullName-${index}`).setValue(signature.fullName || '');
      });
  }

  checkForFormChanges(index) {
    if (this.digitalSignatures[index].status !== SignatureStatusEnum.SIGNED) {
      this.checkEmailFields(index);
      this.checkSmsFields(index);
      this.checkSignatureType(index);
    }
  }

  checkSignatureType(index) {
    const emailCheckboxControl = this.getFormControl(`email-${index}`);
    const smsCheckboxControl = this.getFormControl(`sms-${index}`);
    const fullNameInputControl = this.getFormControl(`fullName-${index}`);
    if (this.getFormControlValue(`signature-${index}`) === SignatureTypeEnum.VOICESIGNATURE) {
      this.disableAndResetFields(index);
    } else {
      this.enableFields([emailCheckboxControl, smsCheckboxControl, fullNameInputControl]);
    }
  }

  checkEmailFields(index: number) {
    const emailInputControl = this.getFormControl(`emailValue-${index}`);
    const emailCheckboxValue = !!this.getFormControlValue(`email-${index}`);

    if (emailCheckboxValue) {
      emailInputControl.enable({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      emailInputControl.disable({
        onlySelf: true,
        emitEvent: false,
      });
      emailInputControl.setValue('', { emitEvent: false });
      emailInputControl.setErrors(null, { emitEvent: false });
    }
  }
  checkSmsFields(index: number): void {
    const phoneInputControl = this.getFormControl(`phoneNumber-${index}`);
    const smsCheckboxValue = !!this.getFormControlValue(`sms-${index}`);

    if (smsCheckboxValue) {
      phoneInputControl.enable({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      phoneInputControl.disable({
        onlySelf: true,
        emitEvent: false,
      });
      phoneInputControl.setValue('', { emitEvent: false });
      phoneInputControl.setErrors(null, { emitEvent: false });
    }
  }

  disableAndResetFields(index: number) {
    const emailInputControl = this.getFormControl(`emailValue-${index}`);
    const emailCheckboxControl = this.getFormControl(`email-${index}`);
    const phoneInputControl = this.getFormControl(`phoneNumber-${index}`);
    const smsCheckboxControl = this.getFormControl(`sms-${index}`);
    const fullNameInputControl = this.getFormControl(`fullName-${index}`);

    emailInputControl.setValue('', { emitEvent: false });
    emailInputControl.disable({ onlySelf: true, emitEvent: false });
    emailInputControl.setErrors(null, { emitEvent: false });

    smsCheckboxControl.setValue(false, { emitEvent: false });
    smsCheckboxControl.disable({ onlySelf: true, emitEvent: false });

    emailCheckboxControl.setValue(false, { emitEvent: false });
    emailCheckboxControl.disable({ onlySelf: true, emitEvent: false });

    phoneInputControl.setValue('', { emitEvent: false });
    phoneInputControl.disable({ onlySelf: true, emitEvent: false });

    fullNameInputControl.disable({ onlySelf: true, emitEvent: false });
  }

  enableFields(fields: FormControl[]) {
    fields.forEach((field) => {
      field.enable({ onlySelf: true, emitEvent: false });
    });
  }

  getFormControl(controlName: string) {
    return this.digitalSignaturesForm?.controls[controlName] as FormControl;
  }
  getFormControlValue(controlName: string) {
    return this.getFormControl(controlName)?.value;
  }

  buildResendSignaturePayload(index: number, id: number) {
    this.getFormControlValue(`signature-${index}`);
    const signatureMethodVoice = this.getFormControlValue(`signature-${index}`) === SignatureTypeEnum.VOICESIGNATURE;
    const signatureMethodSMS = !!this.getFormControlValue(`sms-${index}`);
    const signatureMethodEmail = !!this.getFormControlValue(`email-${index}`);
    let signatureType = [];

    if (signatureMethodSMS) {
      signatureType.push(SignatureChanelTypeEnum.SMS);
    }

    if (signatureMethodEmail) {
      signatureType.push(SignatureChanelTypeEnum.EMAIL);
    }

    if (signatureMethodVoice) {
      signatureType = [SignatureChanelTypeEnum.VOICESIGNATURE];
    }

    return {
      signatureFor: this.digitalSignatures[index].signatureFor,
      type: signatureType,
      fullName: this.getFormControlValue(`fullName-${index}`),
      email: this.getFormControlValue(`emailValue-${index}`),
      phone: this.getFormControlValue(`phoneNumber-${index}`),
    };
  }

  buildUpdateSignaturePayload(index: number): any {
    if (this.digitalSignaturesForm.value[`signature-${index}`] === SignatureTypeEnum.VOICESIGNATURE) {
      return { type: ['voice_signature'] };
    } else {
      const payload = { fullName: this.digitalSignaturesForm.value[`fullName-${index}`] };
      if (this.getFormControlValue(`sms-${index}`)) {
        Object.assign(payload, { phone: this.digitalSignaturesForm.value[`phoneNumber-${index}`] || '' });
      }
      if (this.getFormControlValue(`email-${index}`)) {
        Object.assign(payload, { email: this.digitalSignaturesForm.value[`emailValue-${index}`] || '' });
      }

      const chanelType = [];
      if (this.getFormControlValue(`sms-${index}`)) {
        chanelType.push(SignatureChanelTypeEnum.SMS);
      }
      if (this.getFormControlValue(`email-${index}`)) {
        chanelType.push(SignatureChanelTypeEnum.EMAIL);
      }
      Object.assign(payload, { type: chanelType });

      return payload;
    }
  }

  signatureActionButtonClicked(index: number, id: number) {
    let payload;
    if (!this.digitalSignatures[index].isSignatureChanged || !this.digitalSignatures[index].id) {
      payload = this.buildResendSignaturePayload(index, id);
      this.eService
        .sendForESignature(payload)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.messageService.clear();
            this.messageService.add({
              key: 'signature-messages',
              severity: Severity.SUCCESS,
              summary: this.translatedResendSignatureMessages.success.summary,
              detail: '',
            });
            this.repopulateFormRow(index);
          },
          error: () => {
            this.messageService.clear();
            this.messageService.add({
              key: 'signature-messages',
              severity: Severity.ERROR,
              summary: this.translatedResendSignatureMessages.error.summary,
              detail: '',
            });
          },
        });
    } else {
      const updatePayload = this.buildUpdateSignaturePayload(index);
      payload = updatePayload;
      this.eService
        .updateSignatureData(payload, id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              key: 'signature-messages',
              severity: Severity.SUCCESS,
              summary: this.translatedUpdateSignatureMessages.success.summary,
              detail: '',
            });
            this.repopulateFormRow(index);
          },
          error: () => {
            this.messageService.add({
              key: 'signature-messages',
              severity: Severity.ERROR,
              summary: this.translatedUpdateSignatureMessages.error.summary,
              detail: '',
            });
          },
        });
    }
  }

  copyToClipboardSignatureLink(index) {
    const signatureLink = `${window.location.origin}/signature/${this.digitalSignatures[index].guid}`;
    navigator.clipboard.writeText(signatureLink);
  }
}
