import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationsProcessService, PaymentService, StaticFieldLinkService } from '@iapplication2/services';
import {
  DefaultFormValidator,
  validAccountNumber,
  validFinancialNumber,
  validMonth,
  validTransitNumber,
  validYear,
  validCVV,
  validAmexCVV,
  StringValidator,
} from '@iapplication2/validators';
import { Subject } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';
import { PaymentMethods, Product } from '@iapplication2/interfaces';
import { ActivatedRoute } from '@angular/router';

export enum PaymentType {
  CARD = 'Credit Card',
  PAD = 'PAD',
}
@Component({
  selector: 'iapplication2-application-payment-popup',
  templateUrl: './application-payment-popup.component.html',
  styleUrls: ['./application-payment-popup.component.scss'],
})
export class ApplicationPaymentPopupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() possiblePaymentMethods: string[];
  @Input() displayModal: boolean;
  @Input() selectedProducts: Product[];
  @Output() paymentFormFinished: EventEmitter<FormGroup> = new EventEmitter();
  @Output() paymentMethodChanged: EventEmitter<FormGroup> = new EventEmitter();

  unsubscribe: Subject<unknown> = new Subject();
  paymentTypeForm: FormGroup;
  paymentTypeEnum = PaymentType;
  totalMonthlyPremium = 0;
  applicationId: number;

  availablePaymentMethods: {
    PAD: boolean;
    creditCard: boolean;
  } = {
    PAD: false,
    creditCard: false,
  };
  formGroupType = FormGroup;

  constructor(
    private route: ActivatedRoute,
    private staticFieldLinkService: StaticFieldLinkService,
    private paymentService: PaymentService,
    public applicationsProcessService: ApplicationsProcessService
  ) {}

  ngOnInit(): void {
    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.createPaymentTypeForm();
    this.watchFormChanges();
    this.setAvailablePaymentMethods();
    this.fillPADFormWithStaticLinkData();
    this.watchPaymentFormDone();
    this.setTotalMonthlyPremium();
  }

  setAvailablePaymentMethods() {
    this.availablePaymentMethods.PAD =
      this.possiblePaymentMethods?.findIndex((paymentMethod: string) => paymentMethod === PaymentMethods.PAD) !== -1;
    this.availablePaymentMethods.creditCard =
      this.possiblePaymentMethods?.findIndex((paymentMethod: string) => paymentMethod === PaymentMethods.CREDIT_CARD) !== -1;
    if (this.displayModal) {
      this.setDefaultOption();
    }
  }
  setTotalMonthlyPremium() {
    this.selectedProducts?.forEach((product) => {
      if (Number(product.monthlyPremium)) {
        this.totalMonthlyPremium += Number(product.monthlyPremium);
      }
    });
  }

  setDefaultOption() {
    if (this.paymentTypeForm) {
      switch (true) {
        case !!this.availablePaymentMethods.PAD:
          this.paymentTypeForm?.get('type').setValue(PaymentType.PAD);
          break;
        case !!this.availablePaymentMethods.creditCard:
          this.paymentTypeForm?.get('type').setValue(PaymentType.CARD);
      }

      this.paymentMethodChanged.emit(this.paymentTypeForm);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.possiblePaymentMethods && changes.possiblePaymentMethods.currentValue) || changes.displayModal) {
      this.setAvailablePaymentMethods();
    }
  }

  createPaymentTypeForm() {
    this.paymentTypeForm = new FormGroup({
      type: new FormControl(),
      PADForm: new FormGroup({
        transitNumber: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, validTransitNumber()]),
        financialNumber: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, validFinancialNumber()]),
        accountNumber: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, validAccountNumber()]),
      }),
      cardForm: new FormGroup({
        holderFirstName: new FormControl(null, DefaultFormValidator.firstName),
        holderLastName: new FormControl(null, DefaultFormValidator.lastName),
        cardNumber: new FormControl(null, DefaultFormValidator.creditCard),
        cvvNumber: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, validCVV()]),
        expirationMonth: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, validMonth()]),
        expirationYear: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, validYear()]),
        holderAddress: new FormControl(null, DefaultFormValidator.address),
        holderZipCode: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      }),
    });
  }

  changeCVVLengthBasedOnCreditCardType() {
    const cardNumber = this.paymentTypeForm.get('cardForm').get('cardNumber').value;
    if (cardNumber) {
      const isAmex = cardNumber.startsWith('34') || cardNumber.startsWith('37');
      if (isAmex) {
        this.paymentTypeForm
          .get('cardForm')
          .get('cvvNumber')
          .setValidators([StringValidator.requiredNoOnlySpaces(), Validators.required, validAmexCVV()]);
      } else {
        this.paymentTypeForm
          .get('cardForm')
          .get('cvvNumber')
          .setValidators([StringValidator.requiredNoOnlySpaces(), Validators.required, validCVV()]);
      }
    }
    this.paymentTypeForm.get('cardForm').get('cvvNumber').updateValueAndValidity();
  }

  fillPADFormWithStaticLinkData() {
    const staticLinkDataForPayment = this.staticFieldLinkService.staticFieldLinkData['paymentInfo'];
    if (staticLinkDataForPayment) {
      Object.keys(staticLinkDataForPayment)?.forEach((key: string) => {
        const controlsInPADForm = Object.keys((this.paymentTypeForm.get('PADForm') as FormGroup).controls);
        if (controlsInPADForm.includes(key)) {
          (this.paymentTypeForm.get('PADForm') as FormGroup).controls[key].setValue(staticLinkDataForPayment[key]);
        }
      });
    }
  }

  setPayor() {
    this.paymentTypeForm
      .get('cardForm')
      .get('holderFirstName')
      .setValue(this.applicationsProcessService.currentApplication?.client?.firstName);
    this.paymentTypeForm
      .get('cardForm')
      .get('holderLastName')
      .setValue(this.applicationsProcessService.currentApplication?.client.lastName);
    this.paymentTypeForm.get('cardForm').get('holderAddress').setValue(this.applicationsProcessService.currentApplication?.client.address);
    this.paymentTypeForm
      .get('cardForm')
      .get('holderZipCode')
      .setValue(this.applicationsProcessService.currentApplication?.client.postalCode);
  }

  clearCreditCard() {
    Object.keys((this.paymentTypeForm.get('cardForm') as FormGroup).controls).forEach((key) => {
      (this.paymentTypeForm.get('cardForm') as FormGroup).controls[key].setValue(null);
    });
  }

  clearPAD() {
    Object.keys((this.paymentTypeForm.get('PADForm') as FormGroup).controls).forEach((key) => {
      (this.paymentTypeForm.get('PADForm') as FormGroup).controls[key].setValue(null);
    });
  }

  watchPaymentFormDone() {
    this.paymentService.paymentFormFinished.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: () => {
        this.paymentFormFinished.emit(this.paymentTypeForm);
      },
    });
  }

  watchFormChanges() {
    // this.paymentTypeForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe({
    //   next: () => {
    //     this.paymentFormChanged.emit(this.paymentTypeForm);
    //   },
    // });
    this.paymentTypeForm
      .get('type')
      .valueChanges.pipe(takeUntil(this.unsubscribe), pairwise())
      .subscribe({
        next: (value) => {
          switch (value[1]) {
            case PaymentType.CARD:
              if (value[0] !== PaymentType.CARD) {
                this.clearPAD();
                this.setPayor();
              }

              break;
            case PaymentType.PAD:
              if (value[0] !== PaymentType.PAD) {
                this.clearCreditCard();
              }
              break;
          }
          this.paymentMethodChanged.emit(this.paymentTypeForm);
        },
      });

    this.paymentTypeForm
      .get('cardForm')
      .get('cardNumber')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.changeCVVLengthBasedOnCreditCardType();
        },
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
