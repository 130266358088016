/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validMonth(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value) {
      const regEx = new RegExp('^(0?[1-9]|1[012])$');
      return regEx.test(control.value)
        ? null
        : {
            invalidMonth: true,
          };
    }
    return null;
  };
}

export function validYear(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value) {
      const regEx = new RegExp('^([2]{1}[2-9]|[3-9]{1}[0-9])$');
      return regEx.test(control.value)
        ? null
        : {
            yearPassed: true,
          };
    }
    return null;
  };
}

export function validTransitNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value) {
      const regEx = new RegExp('[0-9]{5}');
      return regEx.test(control.value)
        ? null
        : {
            invalidTransitNumber: true,
          };
    }
    return null;
  };
}

export function validFinancialNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value) {
      const regEx = new RegExp('[0-9]{3}');
      return regEx.test(control.value)
        ? null
        : {
            invalidFinancialNumber: true,
          };
    }
    return null;
  };
}

export function validAccountNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value) {
      const regEx = new RegExp('[0-9]{6,12}');
      return regEx.test(control.value)
        ? null
        : {
            invalidAccountNumber: true,
          };
    }
    return null;
  };
}

export function validAmexCVV(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value) {
      const regEx = new RegExp('[0-9]{4}');
      return regEx.test(control.value) && control.value.length === 4
        ? null
        : {
            invalidAmexCVV: true,
          };
    }
    return null;
  };
}

export function validCVV(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value) {
      const regEx = new RegExp('[0-9]{3}');
      return regEx.test(control.value) && control.value.length === 3
        ? null
        : {
            invalidCVV: true,
          };
    }
    return null;
  };
}
