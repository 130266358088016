import { Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CategorySlugs,
  CustomFormBuilderField,
  FieldCategory,
  FieldTable,
  FormBuilderField,
  FormBuilderFieldOptions,
  FormBuilderGroup,
  FormBuilderItem,
  ModalType,
  FormBuilderFieldTypeType,
} from '@iapplication2/interfaces';
import {
  DynamicTopbarInformationService,
  InteractiveFormBuilderService,
  FieldCategoryService,
  PredefinedFieldsService,
  ProgressSpinnerService,
} from '@iapplication2/services';
import { FormHelper } from '@iapplication2/superclass';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-field-builder',
  templateUrl: './field-builder.component.html',
  styleUrls: ['./field-builder.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class FieldBuilderComponent extends FormHelper implements OnInit, OnDestroy {
  sidebarAction = ModalType;
  searchForFieldValue: string;
  searchResultFields: any[];

  @ViewChild('editArea') editArea: ElementRef;

  formValidity = true;

  selectedField: CustomFormBuilderField;
  selectedGroup: FormBuilderGroup;
  selectedTable: FieldTable;
  selectedGroupLength: number;

  selectedItemCategory: FieldCategory;

  selectedColumnInPredefinedTable: FormBuilderItem;

  fieldDetailsForm: FormGroup = new FormGroup({
    fieldForm: new FormGroup({}),
    groupForm: new FormGroup({}),
  });

  fieldCategoryForm: FormGroup = new FormGroup({
    fieldCategory: new FormControl(null, [Validators.required]),
  });

  searchFormGroup: FormGroup = new FormGroup({
    searchInput: new FormControl({ value: '', disabled: true }),
  });

  showUnassignedList = false;

  numberOfFieldsInTempFieldGroupFields: number;

  fieldCategories: FieldCategory[];

  predefinedCustomFields: CustomFormBuilderField[] = [];
  predefinedFields: any;

  private unsubscribe = new Subject<void>();

  categorySlugEnum = CategorySlugs;

  canEditItem = false;
  canEditItemTooltip: string | undefined = undefined;

  isFieldDialogOpen = false;

  modalTypeEnum = ModalType;
  FormBuilderFieldTypeTypeEnum = FormBuilderFieldTypeType;

  settingsDialogOptions: ModalType;

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private dynamicTopbarInformationService: DynamicTopbarInformationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private fieldCategoryService: FieldCategoryService,
    private predefinedFieldsService: PredefinedFieldsService,
    private progressSpinnerService: ProgressSpinnerService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.watchSearchFormChanges();
    this.dynamicTopbarInformationService.displayDynamicInformation.next({
      displayInfo: true,
      displayComponent: 'fieldBuilder',
    });
    this.getCustomFieldCategories();
    this.interactiveFormBuilderService.sidebarListWasUpdated.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getCustomFieldCategories();
    });

    this.interactiveFormBuilderService.settingsModalOptionsWereSelected.pipe(takeUntil(this.unsubscribe)).subscribe((options) => {
      this.settingsDialogOptions = options;
    });

    this.getCategoryOfSelectedField();
    this.interactiveFormBuilderService.sidebarColumnWasSelected.pipe(takeUntil(this.unsubscribe)).subscribe((field) => {
      if (field) {
        this.selectedColumnInPredefinedTable = field;
        this.displayFieldDialog();
      }
    });
    this.interactiveFormBuilderService.fieldWasSelected.pipe(takeUntil(this.unsubscribe)).subscribe((field) => {
      if (field) {
        this.onItemSelected(field);
      }
    });
  }

  private getCategoryOfSelectedField() {
    this.interactiveFormBuilderService.categoryOfSelectedField
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((fieldCategory: FieldCategory) => {
        this.selectedItemCategory = fieldCategory;
        this.setCategoryBasedOnType(fieldCategory);
      });
  }

  private displayFieldDialog(): void {
    this.isFieldDialogOpen = true;
  }

  private watchSearchFormChanges() {
    this.searchFormGroup
      .get('searchInput')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => this.searchForFieldValueChanged())
      )
      .subscribe();
  }

  closeFieldDialog(): void {
    this.isFieldDialogOpen = false;
  }

  setCategoryBasedOnType(fieldCategory: FieldCategory) {
    switch (true) {
      case !!this.selectedField:
        this.selectedField.fieldCategory = fieldCategory;
        break;
      case !!this.selectedGroup:
        this.selectedGroup.groupOptions.category = fieldCategory;
        break;
      case !!this.selectedTable:
        this.selectedTable.category = fieldCategory;
    }
  }

  getPredefinedFields() {
    this.predefinedFieldsService.getPredefinedFieldsDebounced();
    this.predefinedFieldsService.$fetchedFields.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      if (res.length) {
        this.predefinedCustomFields = res;
        this.predefinedFields = this.fieldCategories.map((category) => {
          return {
            category: category,
            fields: this.getFieldsFromCategory(category),
          };
        });
        this.searchFormGroup.get('searchInput').enable();
      }
    });
  }

  getFieldsFromCategory(category: FieldCategory): FormBuilderField[] {
    const fieldsInCategory: FormBuilderField[] = [];
    this.predefinedCustomFields?.forEach((predefinedCustomField) => {
      if (predefinedCustomField.fieldCategory?.id && predefinedCustomField.fieldCategory?.id === category?.id) {
        fieldsInCategory.push(predefinedCustomField.field);
      }
    });
    return fieldsInCategory;
  }

  getCustomFieldCategories(): void {
    this.fieldCategoryService.getFieldCategories();
    this.fieldCategoryService.fatchedCategories.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      this.fieldCategories = res;
      this.getPredefinedFields();

      this.showUnassignedList = !!this.fieldCategories.filter((category) => category.slug === this.categorySlugEnum.UNASSIGNED).length;
    });
  }

  searchForFieldValueChanged(): void {
    this.searchResultFields = [];
    this.searchForFieldValue = this.searchFormGroup.get('searchInput').value;
    const searchTerm = this.searchForFieldValue.toUpperCase();
    this.predefinedFields?.forEach((predefinedField) => {
      const matchingFields = predefinedField.fields.filter((field) => {
        if (this.interactiveFormBuilderService.isFieldInTable(field)) {
          return field.table?.type?.name?.toUpperCase().includes(searchTerm);
        }
        if (this.interactiveFormBuilderService.isTypeGroup(field.fieldType)) {
          return field.groupOptions?.name?.toUpperCase().includes(searchTerm);
        }
        const customFieldLabel = field.options?.customFieldLabel?.toUpperCase();
        return customFieldLabel.includes(searchTerm);
      });

      if (matchingFields.length) {
        const matchingPredefinedField = { ...predefinedField, fields: matchingFields };
        this.searchResultFields.push(matchingPredefinedField);
      }
    });
  }

  displayDefaultCustomFields(): boolean {
    return this.searchForFieldValue ? this.searchForFieldValue.length <= 2 : true;
  }

  itemSelected(item: FormBuilderItem) {
    this.useSelectedItemAsPredefined(item);
  }
  private useSelectedItemAsPredefined(item: FormBuilderItem) {
    this.setPredefinedFieldIdFromItem(item);

    this.interactiveFormBuilderService.sidebarItemSelected(item);
    if (this.settingsDialogOptions !== ModalType.FORM) {
      this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.DRAW);
    }
  }

  setPredefinedFieldIdFromItem(item: FormBuilderItem) {
    switch (true) {
      case this.interactiveFormBuilderService.isItemTable(item):
        item?.fields?.forEach((fieldInTable: FormBuilderField) => {
          fieldInTable.predefinedFieldId = fieldInTable.id;
          if (fieldInTable.groupOptions?.id) {
            fieldInTable.groupOptions.predefinedGroupId = fieldInTable.groupOptions?.id;
          }
          if (fieldInTable.predefinedFieldTableId && fieldInTable?.table?.type) {
            fieldInTable.table.type.predefinedFieldTableId = fieldInTable.predefinedFieldTableId;
          }
        });
        item.predefinedTableId = item.id;
        break;
      case this.interactiveFormBuilderService.isItemGroup(item):
        item?.fields?.forEach((fieldInGroup: FormBuilderField) => {
          fieldInGroup.predefinedFieldId = fieldInGroup.id;
          fieldInGroup.groupOptions.predefinedGroupId = fieldInGroup.groupOptions.id;
        });
        item.groupOptions.predefinedGroupId = item.groupOptions.id;
        break;
      case this.interactiveFormBuilderService.isItemField(item):
        item.predefinedFieldId = item.id;
        break;
    }
  }

  onItemSelected(item: FormBuilderItem): void {
    this.setCanEditItem(false);
    if (item) {
      this.checkIfItemCanBeEdited(item);
      switch (true) {
        case !!this.interactiveFormBuilderService.isItemGroup(item):
          this.selectedField = undefined;
          this.selectedTable = undefined;
          this.selectedGroup = item as FormBuilderGroup;
          this.selectedGroupLength = this.selectedGroup?.fields?.length;
          break;
        case !!this.interactiveFormBuilderService.isItemField(item): {
          const customField: CustomFormBuilderField = {
            field: item as FormBuilderField,
            fieldCategory: this.selectedItemCategory,
          };
          this.selectedField = customField;
          this.selectedTable = undefined;
          this.selectedGroup = undefined;
          break;
        }
        case !!this.interactiveFormBuilderService.isItemTable(item):
          this.selectedGroup = undefined;
          this.selectedField = undefined;
          this.selectedTable = item as FieldTable;
      }
    }
  }

  scrollToEditArea(): void {
    this.editArea.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  private setCanEditItem(canEdit: boolean) {
    switch (canEdit) {
      case true:
        this.canEditItem = true;
        this.canEditItemTooltip = undefined;
        break;
      case false:
        this.canEditItem = false;
        this.canEditItemTooltip = 'Cannot delete this item, as it was already used in a form';
    }
  }

  private checkIfItemCanBeEdited(item: FormBuilderItem): void {
    this.predefinedFieldsService
      .checkIfAnyFieldIsCreatedFromPredefinedField(item)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (isUsed: boolean) => {
          this.setCanEditItem(!isUsed);
          this.scrollToEditArea();
        },
      });
  }

  optionsWereAddedToTheField(event: any): void {
    this.selectedField.field.fieldTypeOptions = event;
  }

  entriesWereAddedToTheField(event: any): void {
    this.selectedField.field.fieldOptionEntries = event;
  }

  saveFieldChanges(): void {
    this.selectedField.fieldCategory = this.fieldCategories.filter(
      (category: FieldCategory) => category.slug === this.fieldCategoryForm.controls['fieldCategory'].value
    )[0];

    this.selectedField.field.options = this.extractObjectFromFormValues<FormBuilderFieldOptions>(
      <FormGroup>this.fieldDetailsForm.controls['fieldForm'],
      this.selectedField.field.options
    );
    this.selectedField.field.options.customFieldName = _.camelCase(this.selectedField.field.options.customFieldLabel);

    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.predefinedFieldsService.updatePredefinedCustomField(this.selectedField);
  }

  saveTableChanges(): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.selectedTable.fields.forEach((field: FormBuilderField) => {
      field.table.type.name = this.selectedTable.name;
      field.table.type.columns = this.selectedTable.columns;

      const customField: CustomFormBuilderField = {
        field: field,
        fieldCategory: this.setCategoryObjectFromForm(this.fieldCategoryForm),
      };

      this.predefinedFieldsService.updatePredefinedCustomField(customField);
    });
  }

  setCategoryObjectFromForm(categoryForm: FormGroup): FieldCategory {
    return this.fieldCategories.find(
      (fieldCategory: FieldCategory) => fieldCategory?.slug && fieldCategory.slug === categoryForm.get('fieldCategory')?.value
    );
  }

  saveGroupChanges(): void {
    this.selectedGroup.groupOptions.category = this.fieldCategories.filter(
      (category: FieldCategory) => category.slug === this.fieldCategoryForm.controls['fieldCategory'].value
    )[0];

    this.addPredefinedFieldsToGroup();
    this.updatePredefinedGroup();
    this.updatePredefinedFieldsFromGroup();
    this.deletePredefinedFieldsFromGroup();
  }

  private addPredefinedFieldsToGroup() {
    this.selectedGroup?.fields?.forEach((field) => {
      if (!field.id) {
        const fieldCategoryForFieldsInGroup: FieldCategory = this.predefinedCustomFields.find(
          (predefinedField) => predefinedField.field == this.selectedGroup.fields[0]
        )?.fieldCategory;

        const customField: CustomFormBuilderField = {
          field: {
            ...field,
            fieldValidators: this.selectedGroup.fieldValidators,
          },
          fieldCategory: fieldCategoryForFieldsInGroup,
        };

        this.predefinedFieldsService.createNewPredefinedCustomField(customField).subscribe((result: CustomFormBuilderField) => {
          field.fieldOptionEntries = result.field.fieldOptionEntries;
          field.fieldTypeOptions = result.field.fieldTypeOptions;
          field.fieldValidators = result.field.fieldValidators;
          field.id = result.field.id;
        });
      }
    });
  }

  private updatePredefinedGroup() {
    const fieldsToBeUpdated = this.predefinedCustomFields.filter(
      (field) =>
        field.field.groupOptions?.id &&
        field.field.groupOptions?.id === this.selectedGroup.groupOptions.id &&
        this.selectedGroup.fields.some((item) => item.id === field.field.id)
    );

    if (fieldsToBeUpdated.length) {
      this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
      fieldsToBeUpdated.forEach((field) => {
        field.field.groupOptions = this.selectedGroup.groupOptions;
        field.fieldCategory = this.selectedGroup.groupOptions.category;
        field.field.fieldValidators = this.selectedGroup.fieldValidators;

        field.field.staticValueLink = this.selectedGroup.fields[0].staticValueLink;
        field.field.staticValueLinkId = this.selectedGroup.fields[0].staticValueLinkId;
        field.field.fieldTypeOptions = this.selectedGroup.fields[0].fieldTypeOptions;
        field.field.predefinedFieldDisclosureOption = this.selectedGroup.predefinedFieldDisclosureOption;
        this.predefinedFieldsService.updatePredefinedCustomField(field);
      });
    }
  }

  private updatePredefinedFieldsFromGroup() {
    const fieldsToBeUpdated = this.predefinedCustomFields.filter((field) => field.field.markedForUpdate);

    if (fieldsToBeUpdated.length) {
      this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
      fieldsToBeUpdated.forEach((field) => {
        delete field.field.markedForUpdate;
        this.predefinedFieldsService.updatePredefinedCustomField(field);
      });
    }
  }

  private deletePredefinedFieldsFromGroup() {
    const fieldsToBeDeleted = this.predefinedCustomFields.filter(
      (field) =>
        field.field.groupOptions?.id &&
        field.field.groupOptions.id === this.selectedGroup.groupOptions.id &&
        !this.selectedGroup.fields.some((item) => item.id === field.field.id)
    );

    if (fieldsToBeDeleted.length) {
      this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
      fieldsToBeDeleted.forEach((field) => {
        this.deleteField(field.field);
      });

      const filteredFields = [];
      this.predefinedCustomFields.forEach((field) => {
        if (fieldsToBeDeleted.some((item) => item.field.id !== field.field.id)) {
          filteredFields.push(field);
        }
      });
      this.predefinedCustomFields = filteredFields;
    }
  }

  deleteGroup(): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.selectedGroup.fields.forEach((field) => this.predefinedFieldsService.deleteCustomField(field));
    this.selectedGroup = undefined;
  }

  private deleteTable(): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.selectedTable.fields.forEach((field) => this.predefinedFieldsService.deleteCustomField(field));
    this.selectedTable = undefined;
  }

  formValidityChanged(event: any): void {
    this.formValidity = !event;
  }

  deleteField(field?: FormBuilderField): void {
    if (field) {
      this.predefinedFieldsService.deleteCustomField(field);
    } else {
      this.predefinedFieldsService.deleteCustomField(this.selectedField);
      this.selectedField = undefined;
    }
  }

  confirmFieldDeletion(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this field?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
        this.deleteField();
      },
    });
  }

  confirmGroupDeletion(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this group?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteGroup();
      },
    });
  }

  confirmTableDeletion(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this table?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteTable();
      },
    });
  }

  ngOnDestroy(): void {
    this.dynamicTopbarInformationService.displayDynamicInformation.next({
      displayInfo: false,
      displayComponent: null,
    });
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
