import { LanguagesEnum } from '@iapplication2/interfaces';

export const LanguagesConstant = [
  {
    langKey: LanguagesEnum.EN,
    langName: 'English',
  },
  {
    langKey: LanguagesEnum.FR,
    langName: 'French',
  },
];
