import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Product, Severity } from '@iapplication2/interfaces';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'iapplication2-quick-quote-page',
  templateUrl: './quick-quote-page.component.html',
  styleUrls: ['./quick-quote-page.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class QuickQuotePageComponent implements OnInit {
  quickQuoteForm: FormGroup;
  emailPDFForm: FormGroup;
  compareTriggered = false;
  loading = false;
  emailPDFFormSubmissionTriggered = false;
  emailPDFFormSent = false;
  selectedProducts: Product[] = [];
  quickQuoteId: number;

  constructor(private confirmationService: ConfirmationService, private messageService: MessageService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.createClientForm();
    this.createEmailPDFForm();
    this.getQuickQuoteId();
  }

  getQuickQuoteId() {
    this.route.params.subscribe((params) => {
      this.quickQuoteId = params['id'];
      if (this.quickQuoteId) {
        this.compare();
      }
    });
  }

  createClientForm() {
    this.quickQuoteForm = new FormGroup({
      effectiveDate: new FormControl(null),
      everest: new FormControl(false),
      birthDate: new FormControl(null, [Validators.required]),
      sex: new FormControl(null, [Validators.required]),
      smokerStatus: new FormControl(null, [Validators.required]),
      faceAmount: new FormControl(null, [Validators.required]),
      province: new FormControl(null, [Validators.required]),
      firstName: new FormControl(null),
      middleName: new FormControl(null),
      lastName: new FormControl(null),
    });
  }

  createEmailPDFForm() {
    this.emailPDFForm = new FormGroup({
      email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
    });
    console.log(this.emailPDFForm.controls['email']);
  }

  compare() {
    this.compareTriggered = true;
    this.loading = true;
    window.setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  productSelected(products: Product[]) {
    this.emailPDFFormSent = false;
    this.selectedProducts = products;
    this.selectedProducts.length ? this.emailPDFForm.controls['email'].enable() : this.emailPDFForm.controls['email'].disable();
    console.log(this.emailPDFForm);
  }

  emailPDF() {
    this.emailPDFFormSubmissionTriggered = true;
    console.log('email following products');
    console.log(this.selectedProducts);
    console.log('to email');
    console.log(this.emailPDFForm.controls['email'].value);
    this.showEmailSendingSuccessMessage();
    this.emailPDFFormSent = true;
  }

  confirmEmailSending(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to send this email?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.emailPDF();
      },
    });
  }

  showEmailSendingSuccessMessage() {
    this.messageService.add({
      key: 'emailPDFForm-message',
      severity: Severity.SUCCESS,
      summary: 'Success!',
      detail: 'Email was sent successfully',
    });
  }

  showEmailSendingErrorMessage() {
    this.messageService.add({
      key: 'emailPDFForm-message',
      severity: Severity.ERROR,
      summary: 'Error!',
      detail: 'There was an issue with the email.',
    });
  }

  startApplication() {
    console.log('start application with following products');
    console.log(this.selectedProducts);
  }
}
