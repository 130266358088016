<form [formGroup]="fieldCategoryForm" fxFlex fxLayout="column" class="mt-2">
  <div style="min-width: 50%" fxLayout="row">
    <input
      fxFlex
      id="categoryTitle"
      type="text"
      class="p-inputtext p-component mr-2"
      pInputText
      placeholder="Category title"
      formControlName="title"
    />
    <button
      class="max-width: max-content;"
      pButton
      pRipple
      label="Create category"
      (click)="createCategory()"
      [disabled]="fieldCategoryForm.get('title').errors?.duplicateCategoryTitle || !this.fieldCategoryForm.controls['title'].value"
    ></button>
  </div>
  <div>
    <p
      *ngIf="
        fieldCategoryForm.get('title').errors?.duplicateCategoryTitle &&
        !(fieldCategoryForm.controls['title'].value?.toUpperCase() === 'UNASSIGNED')
      "
    >
      This category title is already in use.
    </p>
    <p *ngIf="fieldCategoryForm.controls['title'].value?.toUpperCase() === 'UNASSIGNED'">This category title is reserved.</p>
  </div>
</form>
