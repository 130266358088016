<p-messages severity="error" *ngIf="displayInvalidFormMessage && !loading">
  <ng-template pTemplate>
    <div>{{ 'applicationProcess.step.applicationForms.errors.formNotValid' | translate }}</div>
    <p></p>
  </ng-template>
</p-messages>
<div class="flex flex-row align-items-center justify-content-between mt-5">
  <div class="flex flex-row align-items-center mb-4">
    <p class="mb-0 mr-2">{{ 'applicationProcess.step.applicationForms.selectedProduct' | translate }}:</p>
    <p-dropdown
      [options]="selectableForms"
      [(ngModel)]="currentlySelectedForm"
      optionLabel="productTitle"
      (ngModelChange)="onSelectedFormChange()"
    >
      <ng-template pTemplate="selectedItem">
        <div>{{ currentlySelectedForm?.productTitle | translate }}</div>
      </ng-template>
      <ng-template let-selectableForm pTemplate="item">
        <div>{{ selectableForm.productTitle | translate }}</div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="mb-3" fxFlex fxLayout="row" fxLayoutAlign="end center">
    <div>
      <iapplication2-progress-spinner></iapplication2-progress-spinner>
      <p-dialog
        [header]="'applicationProcess.step.applicationForms.payment.dialog.title' | translate"
        [(visible)]="displayModal"
        [modal]="true"
        [style]="{ width: '50vw', minWidth: '800px' }"
        [draggable]="false"
        [resizable]="false"
        class="payment-dialog"
        (onHide)="paymentDialogClosedNoSave()"
      >
        <div *ngIf="!recordingPaused" fxFlex fxLayout="column" fxLayoutAlign="center center">
          <span class="mb-3">{{ 'applicationProcess.step.applicationForms.payment.recordingPause' | translate }}</span>
          <p-progressSpinner strokeWidth="4" class="app-loader-spinner"></p-progressSpinner>
        </div>
        <iapplication2-application-payment-popup
          *ngIf="recordingPaused"
          [possiblePaymentMethods]="possiblePaymentMethods"
          [displayModal]="displayModal"
          [selectedProducts]="selectedProducts"
          (paymentFormFinished)="onPaymentFormFinished($event)"
          (paymentMethodChanged)="onPaymentMethodChanged($event)"
        ></iapplication2-application-payment-popup>
        <p-message *ngIf="paymentErrorMessage" severity="error" [text]="paymentErrorMessage"></p-message>
        <div *ngIf="recordingPaused">
          <div *ngIf="paymentForm?.controls['type'].value === paymentTypeEnum.CARD" fxFlex fxLayoutAlign="end center">
            <p-button
              icon="pi pi-check"
              [disabled]="paymentForm?.controls['cardForm']?.invalid"
              (click)="submitPayment()"
              label="Submit"
            ></p-button>
          </div>
          <div *ngIf="paymentForm?.controls['type'].value === paymentTypeEnum.PAD" fxFlex fxLayoutAlign="end center">
            <p-button (click)="donePAD()" [disabled]="paymentForm?.controls['PADForm']?.invalid"
                      label="Finish"></p-button>
          </div>
        </div>
        <button (click)="forceRecordingPause()"
                *ngIf="!recordingPaused && (this.appConfig.environmentType !== envType.PRODUCTION)"
                class="p-button-danger p-button-text force-pause-button"
                pButton>
          {{ 'applicationProcess.step.applicationForms.payment.recordingPauseForce' | translate }}
        </button>
      </p-dialog>
    </div>
  </div>
</div>

<div *ngIf="currentlySelectedForm?.sectionList?.length && !loading">
  <p-tabView #tabView [(activeIndex)]="tabViewIndex" class="product-section-tabs">
    <p-tabPanel *ngFor="let section of currentlySelectedForm.sectionList; let i = index" [selected]="i === 0">
      <ng-template pTemplate="header">
        <div
          class="p-3 m-0"
          fxFlex
          fxLayoutAlign="column"
          [pTooltip]="!!fieldIdsWithErrorInSection[section.id]?.length ? invalidFieldsTooltip : undefined"
          tooltipPosition="bottom"
          [showDelay]="500"
          [hidden]="isViewApplication"
        >
          <div>
            {{ (section?.lokaliseKey ? section?.lokaliseKey : 'missing_key') | translate: { Default: section?.title } }}
          </div>
          <p-badge
            *ngIf="fieldIdsWithErrorInSection[section.id]?.length && !isViewApplication"
            [value]="fieldIdsWithErrorInSection[section.id].length"
            severity="danger"
            size="small"
            class="flex align-items-center ml-2"
          ></p-badge>
        </div>
      </ng-template>
      <div *ngIf="section.type?.name === sectionTypesEnum.PAYMENT && !isViewApplication">
        <button
          pButton
          [label]="'applicationProcess.step.applicationForms.payment.button' | translate"
          (click)="openPaymentInformation()"
        ></button>
        <p-confirmPopup></p-confirmPopup>
      </div>
      <div *ngIf="section.formAreas?.length > 0">
        <iapplication2-form-display-area
          *ngFor="let area of section.formAreas"
          [area]="area"
          [hiddenFieldIds]="hiddenFields[currentlySelectedForm.productFormId]"
          [areaForm]="
            applicationFormGroup.controls[currentlySelectedForm.productFormId].get(section.id.toString()).get(area.id.toString())
              | cast: FormGroupType
          "
          [isPreviewMode]="false"
          [showStaticText]="true"
          class="p-2"
        ></iapplication2-form-display-area>
      </div>
      <div *ngIf="!section.formAreas || (section.formAreas?.length === 0 && !loading)">
        <p>{{ 'applicationProcess.step.sectionWithNoAreas' | translate }}</p>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
<p-progressBar *ngIf="loading" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
