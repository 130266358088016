import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV, FormBuilderFieldStaticFieldLink } from '@iapplication2/interfaces';
import { APP_CONFIG } from '@iapplication2/app-config';

@Injectable({
  providedIn: 'root',
})
export class InteractiveFormBuilderStaticFieldLinkService {
  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  getAllStaticFields(): Observable<FormBuilderFieldStaticFieldLink[]> {
    return this.http.get<FormBuilderFieldStaticFieldLink[]>('form-builder/static-value-link-options');
  }
}
