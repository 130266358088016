import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeeMoreComponent } from './see-more/see-more.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [SeeMoreComponent],
  imports: [CommonModule, ButtonModule],
  exports: [SeeMoreComponent],
})
export class SeeMoreModule {}
