import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Application, Client, Policy, Severity } from '@iapplication2/interfaces';
import { ClientManagementService } from '@iapplication2/services';
import { FormHelper } from '@iapplication2/superclass';
import { StringValidator } from '@iapplication2/validators';
import { MessageService } from 'primeng/api';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface TypeResponse {
  data: any;
}
@Component({
  selector: 'iapplication2-client-profile-page',
  templateUrl: './client-profile-page.component.html',
  styleUrls: ['./client-profile-page.component.scss'],
  providers: [MessageService],
})
export class ClientProfilePageComponent extends FormHelper implements OnInit, OnDestroy {
  private unsubscribe: Subject<unknown> = new Subject();
  client: Client;
  clientPolicies: Policy[];
  clientApplications: Application[];
  clientForm: FormGroup;
  formSubmissionTriggered = false;
  clientId: string;
  currentTabIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private clientManagementService: ClientManagementService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get('id');
    this.createClientForm();
    this.getClientById(this.clientId);
    this.getPoliciesByClientId(this.clientId);
    this.getApplicationsByClientId(this.clientId);
    const initialValue = this.clientForm.value;
    this.clientForm.valueChanges.subscribe((val) => {
      const hasChanged = Object.keys(initialValue).some((key) => this.clientForm.value[key] != initialValue[key]);
      console.log(hasChanged);
    });
  }

  createClientForm() {
    this.clientForm = new FormGroup({
      firstName: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      middleName: new FormControl(null, [Validators.maxLength(50)]),
      lastName: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      language: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [
        StringValidator.requiredNoOnlySpaces(),
        Validators.required,
        Validators.email,
        Validators.maxLength(100),
      ]),
      birthDate: new FormControl(null, [Validators.required]),
      smokerStatus: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      countryOfBirth: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      province: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      postalCode: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      note: new FormControl(null, [Validators.maxLength(500)]),
    });
  }

  onUpdateClient() {
    this.formSubmissionTriggered = true;

    this.client = this.extractObjectFromFormValues(this.clientForm, this.client);
    if (this.clientForm.status === 'VALID') {
      this.clientManagementService.updateClient(this.client);
      this.showSuccessMessage();
      this.formSubmissionTriggered = false;
      this.clientForm.markAsPristine();
    } else {
      this.showErrorMessage();
    }
  }

  showSuccessMessage() {
    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: 'Success',
      detail: 'The user was successfully updated',
    });
  }

  showErrorMessage() {
    this.messageService.add({
      severity: Severity.ERROR,
      summary: 'Error',
      detail: 'The form is invalid',
    });
  }

  handleTabChange(e) {
    this.currentTabIndex = e.index;
  }

  private getClientById(clientId: string) {
    this.clientManagementService
      .getClientById(clientId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: Client) => {
        this.client = res;
        if (res) {
          this.client.birthDate = res?.birthDate;
          this.fillFormWithObjectValues(this.clientForm, this.client);
        }
      });
  }

  private getPoliciesByClientId(clientId: string) {
    this.clientManagementService
      .getPoliciesByClientId(clientId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.clientPolicies = res.data;
      });
  }

  private getApplicationsByClientId(clientId: string) {
    this.clientManagementService
      .getApplicationsByClientId(clientId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: TypeResponse) => {
        this.clientApplications = res.data;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
