/* eslint-disable @typescript-eslint/no-inferrable-types */
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CanvasService,
  CustomObject,
  FieldManagementService,
  InteractiveFormBuilderService,
  ProgressSpinnerService,
} from '@iapplication2/services';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FieldTable,
  FormBuilderField,
  FormBuilderFieldGroupOptions,
  FormBuilderFieldOptions,
  FormBuilderFieldTypeType,
  FormBuilderGroup,
  ModalType,
} from '@iapplication2/interfaces';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@iapplication2/superclass';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'iapplication2-field-details',
  templateUrl: './field-details.component.html',
  styleUrls: ['./field-details.component.scss'],
  providers: [ConfirmationService],
})
export class FieldDetailsComponent extends FormHelper implements OnInit, OnDestroy, AfterViewChecked {
  private unsubscribe: Subject<void> = new Subject<void>();
  private selectionSubscription: Subject<void> = new Subject<void>();
  @Input() formId: number;

  selectedGroup: FormBuilderGroup;
  selectedTable: FieldTable;
  displayConditions: {
    showEditButton?: boolean;
    showAttachments?: boolean;
    showFieldDetails?: boolean;
    showTypeDetails?: boolean;
    showGroupDetails?: boolean;
    showTableDetails?: boolean;
    showEmptyState?: boolean;
    showFieldValidators?: boolean;
  } = {};
  selectedField: FormBuilderField;

  isInEditMode: boolean = false;
  isFieldLinkedToPredefinedField: boolean = false;

  fieldDetailsForm: FormGroup = new FormGroup({
    fieldForm: new FormGroup({}),
    groupForm: new FormGroup({}),
    tableForm: new FormGroup({}),
  });

  unmodifiedCanvasObject: CustomObject;

  FormBuilderFieldTypeTypeEnum = FormBuilderFieldTypeType;

  modalType: ModalType;

  constructor(
    private canvasService: CanvasService,
    private confirmationService: ConfirmationService,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private cdRef: ChangeDetectorRef,
    private fieldManagementService: FieldManagementService,
    private progressSpinnerService: ProgressSpinnerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createEditSubscriptions();
    this.createSelectionSubscriptions();
    this.doChecks();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  toggleEditMode(): void {
    this.isInEditMode = !this.isInEditMode;
  }

  saveChanges(): void {
    if (this.selectedField.groupOptions) {
      this.saveGroupChanges();
    } else if (this.selectedField.table) this.saveTableChanges();
    else this.saveFieldChanges();
    this.toggleEditMode();
  }

  saveFieldChanges() {
    this.selectedField.options = this.extractObjectFromFormValues<FormBuilderFieldOptions>(
      <FormGroup>this.fieldDetailsForm.controls['fieldForm'],
      this.selectedField.options
    );
    this.selectedField.options.customFieldName = _.camelCase(this.selectedField.options.customFieldLabel);
    this.fieldManagementService.updateField(this.selectedField).subscribe(() => {
      this.getAllFormFields(this.formId);
    });
  }

  saveGroupChanges(): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.addFieldsToGroup();
    this.updateGroup();
    this.updateFieldsFromGroup();
    this.deleteFieldsFromGroup();
  }

  private updateGroup() {
    this.selectedGroup?.fields?.forEach((field) => {
      field.groupOptions = this.selectedGroup.groupOptions;
      field.staticValueLink = this.selectedGroup.staticValueLink;
      field.staticValueLinkId = this.selectedGroup.staticValueLinkId;
      field.fieldTypeOptions = this.selectedGroup.fieldTypeOptions;
      field.fieldValidators = this.selectedGroup.fieldValidators;
      field.fieldDisclosureOption = this.selectedField?.fieldDisclosureOption;
      this.fieldManagementService
        .updateField(field)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.canvasService.getAllFormFieldsDebounced();
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        });
    });
  }

  private updateFieldsFromGroup() {
    const fieldsToBeUpdated = this.selectedGroup.fields.filter((field) => field.markedForUpdate);

    fieldsToBeUpdated.forEach((field) => {
      delete field.markedForUpdate;
      this.interactiveFormBuilderService.updateField(field);
      this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
    });
  }

  private deleteFieldsFromGroup() {
    const fieldsToBeDeleted = this.canvasService.listOfAllFormFields.filter(
      (field) =>
        field.groupOptions?.id &&
        field.groupOptions?.id === this.selectedGroup.fields[0].groupOptions.id &&
        !this.selectedGroup.fields.includes(field)
    );

    fieldsToBeDeleted.forEach((field: FormBuilderField) => {
      this.interactiveFormBuilderService.deleteField(field);
      this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
    });
  }

  private addFieldsToGroup() {
    const fieldsToBeAdded = this.selectedGroup.fields.filter(
      (field) =>
        field.groupOptions?.id &&
        field.groupOptions?.id === this.selectedGroup.fields[0].groupOptions.id &&
        !this.canvasService.listOfAllFormFields.includes(field)
    );

    fieldsToBeAdded.map((field) => (field.fieldValidators = this.selectedGroup.fieldValidators));

    if (fieldsToBeAdded.length) {
      this.canvasService.fieldsFromGroupSelected(fieldsToBeAdded);
      this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
      this.canvasService.userDrawing.next(true);
    }
  }

  private getAllFormFields(formId: number) {
    this.fieldManagementService
      .getAllFieldsByFormId(formId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FormBuilderField[]) => {
        this.canvasService.listOfAllFormFields = res;
      });
  }

  saveTableChanges(): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.interactiveFormBuilderService.updateTable(this.selectedTable);
    this.interactiveFormBuilderService.deleteColumns(this.selectedTable);
    this.toggleEditMode();
  }

  deleteTable(): void {
    const tableId = this.selectedField?.table?.type?.id;
    if (tableId) {
      this.interactiveFormBuilderService.deleteTable(tableId);
    }
  }

  confirmTableDeletion(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this table and all the fields within it?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteTable();
      },
    });
  }

  confirmFieldDeletion(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this field?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteField();
      },
    });
  }

  deleteField(): void {
    this.interactiveFormBuilderService.deleteField(this.selectedField);
    this.setToEmptyState();
  }

  confirmGroupDeletion(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this group and all the fields within it?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteGroup();
      },
    });
  }

  deleteGroup(): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.interactiveFormBuilderService.deleteGroup(this.selectedGroup);
    this.setToEmptyState();
  }

  createSelectionSubscriptions(): void {
    const stopConditions$ = merge(this.unsubscribe, this.selectionSubscription);
    this.canvasService.canvasObjectWasSelected.pipe(takeUntil(stopConditions$)).subscribe((object) => {
      this.clearSelectedObjects();

      if (object && object !== this.selectedField) {
        this.isInEditMode = false;
        this.isFieldLinkedToPredefinedField = !!object.predefinedFieldId;
      }

      this.selectedField = _.cloneDeep(object);

      if (object) {
        this.setGroupOptionsFromSelectedCanvasObject();
        this.setTableFromSelectedObjects();
        this.doChecks();
        this.modalType = ModalType.EDIT;
      } else {
        this.setToEmptyState();
        this.isInEditMode = false;
        this.modalType = null;
      }
    });
  }

  clearSelectedObjects() {
    this.selectedField = null;
    this.selectedGroup = null;
    this.selectedTable = null;
  }

  setToEmptyState(): void {
    this.displayConditions.showFieldDetails = false;
    this.displayConditions.showAttachments = false;
    this.displayConditions.showGroupDetails = false;
    this.displayConditions.showTypeDetails = false;
    this.displayConditions.showEditButton = false;
    this.displayConditions.showTableDetails = false;
    this.displayConditions.showFieldValidators = false;
    this.displayConditions.showEmptyState = true;
  }

  setGroupOptionsFromSelectedCanvasObject(): void {
    if (!this.selectedField.groupOptions) return;
    const groupOptions: FormBuilderFieldGroupOptions = {
      id: this.selectedField.groupOptions?.id,
      category: this.selectedField.groupOptions?.category,
      name: this.selectedField.groupOptions?.name,
      type: this.selectedField.fieldType,
    };
    const fields: FormBuilderField[] = [];
    fields.push(...this.canvasService.getFieldsByGroupId(this.selectedField.groupOptions.id));
    this.selectedGroup = {
      groupOptions: groupOptions,
      fields: fields,
      fieldValidators: this.selectedField.fieldValidators,
    };
  }

  setTableFromSelectedObjects(): void {
    if (this.selectedField && this.selectedField?.table?.type) {
      this.interactiveFormBuilderService.selectedTable.next(this.selectedField.table?.type);
      this.interactiveFormBuilderService.selectedTable.pipe(takeUntil(this.unsubscribe)).subscribe({
        next: (res: FieldTable) => {
          if (res) {
            this.selectedTable = res;
          }
        },
      });
      this.sortTableColumns();
    }
  }

  createEditSubscriptions(): void {
    this.canvasService.canvasFieldIsInEditMode.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.toggleEditMode();
    });
    this.canvasService.canvasGroupIsInEditMode.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.toggleEditMode();
    });
  }

  doChecks(): void {
    this.displayConditions = {
      showEditButton: this.showEditButton(),
      showFieldDetails: this.showFieldDetails(),
      showAttachments: this.showAttachments(),
      showGroupDetails: this.showGroupDetails(),
      showTypeDetails: this.showTypeDetails(),
      showTableDetails: this.showTableDetails(),
      showFieldValidators: this.showFieldValidators(),
      showEmptyState: this.showEmptyState(),
    };
  }

  private sortTableColumns() {
    this.selectedTable.columns.sort((a, b) => a.number - b.number);
  }

  showEditButton(): boolean {
    return this.showGroupDetails() || this.showTypeDetails() || this.showFieldDetails() || this.showTableDetails();
  }

  showFieldDetails(): boolean {
    return !!this.selectedField && !this.selectedField.table?.type;
  }

  showAttachments(): boolean {
    return !!this.selectedField && this.selectedField.fieldType?.type === FormBuilderFieldTypeType.IMAGE;
  }

  showTypeDetails(): boolean {
    return !!this.selectedField && !this.selectedField.table?.type;
  }

  showFieldValidators(): boolean {
    return !!this.selectedField && !this.selectedField.table?.type && !this.selectedField.groupOptions;
  }

  showGroupDetails(): boolean {
    return !!this.selectedField?.groupOptions?.id && !this.selectedField.table?.type;
  }

  showTableDetails(): boolean {
    return !!this.selectedField?.table;
  }

  showEmptyState(): boolean {
    return !this.showGroupDetails() && !this.showTypeDetails() && !this.showFieldDetails() && !this.showTableDetails();
  }

  getListOfGroupsFromSelectedFields(): number[] {
    const listOfGroups: number[] = [];
    return listOfGroups;
  }

  enableEditing() {
    this.toggleEditMode();
  }

  cancelEditing() {
    this.toggleEditMode();
    this.selectionSubscription.next(void 0);
    this.createSelectionSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
    this.selectionSubscription.complete();
  }
}
