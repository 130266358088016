<div class="fna-selection">
  <div fxLayout="column">
    <h5>{{ 'client_selection.fna.title' | translate }}</h5>
  </div>
  <div fxLayout="column" class="pr-3">
    <p-dropdown
      (onChange)="fnaSelectionChanged()"
      [options]="fnaOptions"
      optionLabel="label"
      inputId="fnaSelection"
      [(ngModel)]="fnaSelection"
    ></p-dropdown>
  </div>
  <div fxLayout="column" *ngIf="fnaSelection?.value === 2" class="pt-3 pb-1">
    <div *ngIf="fnaAttachments.length > 0" fxFlex fxLayout="column" class="pl-1">
      <div *ngFor="let attachedFile of fnaAttachments" fxFlex fxLayout="row" fxLayoutAlign="start center" class="mb-2">
        <div [style]="{ textOverflow: 'ellipsis' }" [pTooltip]="attachedFile.name">
          {{ attachedFile.name }}
        </div>
        <a [href]="attachedFile?.s3Link" target="_blank">
          <button
            pButton
            pRipple
            type="button"
            [label]="'client_selection.fna.fillUpload.buttons.download' | translate"
            class="p-button-secondary ml-2"
          ></button>
        </a>
        <button
          pButton
          pRipple
          type="button"
          [label]="'client_selection.fna.fillUpload.buttons.delete' | translate"
          class="p-button-secondary ml-2"
          (click)="deleteFile()"
        ></button>
      </div>
    </div>
    <div class="mb-3" *ngIf="fnaAttachments.length === 0">{{ 'client_selection.fna.fillUpload.noFilesAttached' | translate }}</div>
    <div fxFlex fxFill fxLayoutAlign="space-between center">
      <p-fileUpload
        #fileUpload
        mode="basic"
        name="files[]"
        accept="application/pdf"
        (uploadHandler)="onUpload($event, fileUpload)"
        [customUpload]="true"
        [auto]="true"
        [chooseLabel]="'client_selection.fna.fileUploadLabel' | translate"
      >
      </p-fileUpload>
    </div>
  </div>
  <form [formGroup]="form" fxLayout="column" *ngIf="fnaSelection?.value === 0" class="pt-4">
    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesMortgages">{{ 'client_selection.fna.fnaLiabilitiesMortgages' | translate }}</label>
      <p-inputNumber id="fnaLiabilitiesMortgages" formControlName="fnaLiabilitiesMortgages"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesMortgages'].touched && form.controls['fnaLiabilitiesMortgages'].dirty"
        [control]="form.controls['fnaLiabilitiesMortgages']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesLoans">{{ 'client_selection.fna.fnaLiabilitiesLoans' | translate }}</label>
      <p-inputNumber id="fnaLiabilitiesLoans" formControlName="fnaLiabilitiesLoans"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesLoans'].touched && form.controls['fnaLiabilitiesLoans'].dirty"
        [control]="form.controls['fnaLiabilitiesLoans']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesFinalExpenses">{{ 'client_selection.fna.fnaLiabilitiesFinalExpenses' | translate }}</label>
      <p-inputNumber id="fnaLiabilitiesFinalExpenses" formControlName="fnaLiabilitiesFinalExpenses"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesFinalExpenses'].touched && form.controls['fnaLiabilitiesFinalExpenses'].dirty"
        [control]="form.controls['fnaLiabilitiesFinalExpenses']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesEducationFund">{{ 'client_selection.fna.fnaLiabilitiesEducationFund' | translate }}</label>
      <p-inputNumber id="fnaLiabilitiesEducationFund" formControlName="fnaLiabilitiesEducationFund"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesEducationFund'].touched && form.controls['fnaLiabilitiesEducationFund'].dirty"
        [control]="form.controls['fnaLiabilitiesEducationFund']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesEmergencyFund">{{ 'client_selection.fna.fnaLiabilitiesEmergencyFund' | translate }}</label>
      <p-inputNumber id="fnaLiabilitiesEmergencyFund" formControlName="fnaLiabilitiesEmergencyFund"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesEmergencyFund'].touched && form.controls['fnaLiabilitiesEmergencyFund'].dirty"
        [control]="form.controls['fnaLiabilitiesEmergencyFund']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesInsuredOccupation">{{
        'client_selection.fna.fnaLiabilitiesInsuredOccupation' | translate
      }}</label>
      <input
        id="fnaLiabilitiesInsuredOccupation"
        type="text"
        class="p-inputtext"
        pInputText
        formControlName="fnaLiabilitiesInsuredOccupation"
      />
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesInsuredOccupation'].touched && form.controls['fnaLiabilitiesInsuredOccupation'].dirty"
        [control]="form.controls['fnaLiabilitiesInsuredOccupation']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesHouseholdMembersAge">{{
        'client_selection.fna.fnaLiabilitiesHouseholdMembersAge' | translate
      }}</label>
      <input
        id="fnaLiabilitiesHouseholdMembersAge"
        type="text"
        class="p-inputtext"
        pInputText
        formControlName="fnaLiabilitiesHouseholdMembersAge"
      />
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesHouseholdMembersAge'].touched && form.controls['fnaLiabilitiesHouseholdMembersAge'].dirty"
        [control]="form.controls['fnaLiabilitiesHouseholdMembersAge']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesAerobicHoursPerWeek">{{
        'client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek' | translate
      }}</label>
      <p-dropdown
        [options]="fnaLiabilitiesAerobicHoursOptions"
        id="fnaLiabilitiesAerobicHoursPerWeek"
        [placeholder]="'client_selection.fna.selectOptionLabel' | translate"
        formControlName="fnaLiabilitiesAerobicHoursPerWeek"
      ></p-dropdown>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesAerobicHoursPerWeek'].touched && form.controls['fnaLiabilitiesAerobicHoursPerWeek'].dirty"
        [control]="form.controls['fnaLiabilitiesAerobicHoursPerWeek']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesHouseholdAnnualIncome">{{
        'client_selection.fna.fnaLiabilitiesHouseholdAnnualIncome' | translate
      }}</label>
      <p-inputNumber id="fnaLiabilitiesHouseholdAnnualIncome" formControlName="fnaLiabilitiesHouseholdAnnualIncome"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesHouseholdAnnualIncome'].touched && form.controls['fnaLiabilitiesHouseholdAnnualIncome'].dirty"
        [control]="form.controls['fnaLiabilitiesHouseholdAnnualIncome']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesIndividualAnnualIncome">{{
        'client_selection.fna.fnaLiabilitiesIndividualAnnualIncome' | translate
      }}</label>
      <p-inputNumber id="fnaLiabilitiesIndividualAnnualIncome" formControlName="fnaLiabilitiesIndividualAnnualIncome"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesIndividualAnnualIncome'].touched && form.controls['fnaLiabilitiesIndividualAnnualIncome'].dirty"
        [control]="form.controls['fnaLiabilitiesIndividualAnnualIncome']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesPercentNeeded">{{ 'client_selection.fna.fnaLiabilitiesPercentNeeded' | translate }}</label>
      <p-inputNumber id="fnaLiabilitiesPercentNeeded" formControlName="fnaLiabilitiesPercentNeeded"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesPercentNeeded'].touched && form.controls['fnaLiabilitiesPercentNeeded'].dirty"
        [control]="form.controls['fnaLiabilitiesPercentNeeded']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaLiabilitiesYearsNeeded">{{ 'client_selection.fna.fnaLiabilitiesYearsNeeded' | translate }}</label>
      <p-inputNumber id="fnaLiabilitiesYearsNeeded" formControlName="fnaLiabilitiesYearsNeeded"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaLiabilitiesYearsNeeded'].touched && form.controls['fnaLiabilitiesYearsNeeded'].dirty"
        [control]="form.controls['fnaLiabilitiesYearsNeeded']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaAssetsCashSavings">{{ 'client_selection.fna.fnaAssetsCashSavings' | translate }}</label>
      <p-inputNumber id="fnaAssetsCashSavings" formControlName="fnaAssetsCashSavings"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaAssetsCashSavings'].touched && form.controls['fnaAssetsCashSavings'].dirty"
        [control]="form.controls['fnaAssetsCashSavings']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaAssetsCashBondsFunds">{{ 'client_selection.fna.fnaAssetsCashBondsFunds' | translate }}</label>
      <p-inputNumber id="fnaAssetsCashBondsFunds" formControlName="fnaAssetsCashBondsFunds"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaAssetsCashBondsFunds'].touched && form.controls['fnaAssetsCashBondsFunds'].dirty"
        [control]="form.controls['fnaAssetsCashBondsFunds']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaAssetsRealEstate">{{ 'client_selection.fna.fnaAssetsRealEstate' | translate }}</label>
      <p-inputNumber id="fnaAssetsRealEstate" formControlName="fnaAssetsRealEstate"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaAssetsRealEstate'].touched && form.controls['fnaAssetsRealEstate'].dirty"
        [control]="form.controls['fnaAssetsRealEstate']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaAssetsBusiness">{{ 'client_selection.fna.fnaAssetsBusiness' | translate }}</label>
      <p-inputNumber id="fnaAssetsBusiness" formControlName="fnaAssetsBusiness"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaAssetsBusiness'].touched && form.controls['fnaAssetsBusiness'].dirty"
        [control]="form.controls['fnaAssetsBusiness']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaAssetsTotalInsurance">{{ 'client_selection.fna.fnaAssetsTotalInsurance' | translate }}</label>
      <p-inputNumber id="fnaAssetsTotalInsurance" formControlName="fnaAssetsTotalInsurance"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaAssetsTotalInsurance'].touched && form.controls['fnaAssetsTotalInsurance'].dirty"
        [control]="form.controls['fnaAssetsTotalInsurance']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>

    <div fxLayout="column" class="py-2">
      <label class="pb-1" for="fnaAssetsDeathBenefits">{{ 'client_selection.fna.fnaAssetsDeathBenefits' | translate }}</label>
      <p-inputNumber id="fnaAssetsDeathBenefits" formControlName="fnaAssetsDeathBenefits"></p-inputNumber>
      <iapplication2-form-field-error
        *ngIf="form.controls['fnaAssetsDeathBenefits'].touched && form.controls['fnaAssetsDeathBenefits'].dirty"
        [control]="form.controls['fnaAssetsDeathBenefits']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>
  </form>
</div>
