import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'iapplication2-application-payment-popup-card',
  templateUrl: './application-payment-popup-card.component.html',
  styleUrls: ['./application-payment-popup-card.component.scss'],
})
export class ApplicationPaymentPopupCardComponent implements OnInit {
  @Input() paymentTypeForm;
  formGroupType = FormGroup;
  constructor() {}

  ngOnInit(): void {}
}
