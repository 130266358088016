<div class="client-profile">
  <div class="page-header flex align-items-center justify-content-between">
    <h1 class="page-header-title">
      {{ 'pages.newClient.title' | translate }}
    </h1>
  </div>
  <div>
    <form [formGroup]="clientForm" (submit)="submit()">
      <div class="col-12 lg:col-12 md:col-12">
        <div class="card grid">
          <div class="card-header col-12">
            <div class="card-header-title flex flex-row justify-content-start">
              <span class="card-header-title-counter flex align-items-center"> 1 </span>
              <h5>
                {{ 'pages.newClient.sections.clientInformation.title' | translate }}
              </h5>
            </div>
          </div>
          <div class="grid col-12">
            <div class="p-field flex flex-column col-12 lg:col-4 pr-2 lg:mb-0">
              <label for="firstName" class="flex">
                <div>
                  {{ 'pages.newClient.sections.clientInformation.fields.firstName.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.get('firstName'))" class="p-error ml-1">*</div>
              </label>
              <input
                id="firstName"
                type="text"
                class="p-inputtext p-component"
                pInputText
                [placeholder]="'pages.newClient.sections.clientInformation.fields.firstName.placeholder' | translate"
                formControlName="firstName"
              />
              <iapplication2-form-field-error
                *ngIf="clientForm.get('firstName').touched || formSubmissionTriggered"
                [control]="clientForm.get('firstName')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
            <div class="p-field flex flex-column col-12 lg:col-4 pr-2 lg:mb-0">
              <label for="lastName" class="flex">
                <div>
                  {{ 'pages.newClient.sections.clientInformation.fields.lastName.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.get('lastName'))" class="p-error ml-1">*</div>
              </label>
              <input
                id="lastName"
                type="text"
                class="p-inputtext p-component"
                pInputText
                [placeholder]="'pages.newClient.sections.clientInformation.fields.lastName.placeholder' | translate"
                formControlName="lastName"
              />
              <iapplication2-form-field-error
                *ngIf="clientForm.get('lastName').touched || formSubmissionTriggered"
                [control]="clientForm.get('lastName')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
          </div>
          <div class="grid col-12">
            <div
              *ngIf="(!isEditable && clientForm.get('middleName').value) || isEditable"
              class="p-field flex flex-column col-12 lg:col-4 pr-2 lg:mb-0"
            >
              <label for="middleName" class="flex">
                <div>
                  {{ 'pages.newClient.sections.clientInformation.fields.middleName.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.controls['middleName'])" class="p-error ml-1">*</div>
              </label>
              <input
                id="middleName"
                type="text"
                class="p-inputtext p-component"
                pInputText
                [placeholder]="'pages.newClient.sections.clientInformation.fields.middleName.placeholder' | translate"
                formControlName="middleName"
              />
              <iapplication2-form-field-error
                *ngIf="clientForm.get('middleName').touched || formSubmissionTriggered"
                [control]="clientForm.get('middleName')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
            <div class="p-field flex flex-column col-12 lg:col-4 pr-2 lg:mb-0">
              <label for="language" class="flex">
                <div>
                  {{ 'pages.newClient.sections.clientInformation.fields.language.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.get('language'))" class="p-error ml-1">*</div>
              </label>
              <input id="language" formControlName="language" pInputText class="p-inputtext p-component" />
              <iapplication2-form-field-error
                *ngIf="clientForm.get('language').touched || formSubmissionTriggered"
                [control]="clientForm.get('language')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
          </div>
          <div class="grid col-12">
            <div class="p-field flex flex-row col-12 mb-4 lg:col-4 pr-2 lg:mb-0 justify-content-start align-items-end">
              <div class="flex flex-column pr-2 width-100">
                <label for="birthDate" class="flex">
                  <div>
                    {{ 'pages.newClient.sections.clientInformation.fields.birthDate.label' | translate }}
                  </div>
                  <div *ngIf="hasRequiredField(clientForm.get('birthDate'))" class="p-error ml-1">*</div>
                </label>
                <p-calendar
                  class="flex flex-column"
                  id="birthDate"
                  formControlName="birthDate"
                  [showIcon]="true"
                  inputId="icon"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="1900:2021"
                  dateFormat="dd/mm/yy"
                  [placeholder]="'pages.newClient.sections.clientInformation.fields.birthDate.placeholder' | translate"
                  iapplication2DateMask
                ></p-calendar>
                <iapplication2-form-field-error
                  *ngIf="clientForm.get('birthDate').touched || formSubmissionTriggered"
                  [control]="clientForm.get('birthDate')"
                  style="bottom: -8px"
                ></iapplication2-form-field-error>
              </div>
            </div>

            <div class="p-field flex flex-row col-12 mb-4 lg:col-4 pr-2 lg:mb-0 justify-content-start align-items-end">
              <div class="flex flex-column pr-2 width-100">
                <label class="flex">
                  <div>
                    {{ 'pages.newClient.sections.clientInformation.fields.smokerStatus.label' | translate }}
                  </div>
                  <div *ngIf="hasRequiredField(clientForm.get('smokerStatus'))" class="p-error ml-1">*</div></label
                >
                <div class="flex">
                  <div *ngFor="let smokerStatus of smokerStatuses" class="p-field-checkbox mr-3 my-2">
                    <p-radioButton
                      [inputId]="smokerStatus"
                      name="smokerStatus"
                      [value]="smokerStatus"
                      formControlName="smokerStatus"
                    ></p-radioButton>
                    <label [for]="smokerStatus">{{ smokerStatus | titlecase }}</label>
                  </div>
                </div>
                <iapplication2-form-field-error
                  *ngIf="clientForm.get('smokerStatus').touched || formSubmissionTriggered"
                  [control]="clientForm.get('smokerStatus')"
                  style="bottom: -8px"
                ></iapplication2-form-field-error>
              </div>
            </div>
          </div>
          <div class="grid col-12">
            <div class="p-field flex flex-column col-12 mb-4 lg:col-4 pr-2 lg:mb-0">
              <label for="email" class="flex">
                <div>
                  {{ 'pages.newClient.sections.clientInformation.fields.email.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.get('email'))" class="p-error ml-1">*</div>
              </label>
              <input
                id="email"
                type="text"
                class="p-inputtext p-component"
                pInputText
                [placeholder]="'pages.newClient.sections.clientInformation.fields.email.placeholder' | translate"
                formControlName="email"
              />
              <iapplication2-form-field-error
                *ngIf="clientForm.get('email').touched || formSubmissionTriggered"
                [control]="clientForm.get('email')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
            <div class="p-field flex flex-row col-12 mb-4 lg:col-4 pr-2 lg:mb-0">
              <div class="flex flex-column pr-2 width-100">
                <label for="phoneNumber" class="flex mb-2">
                  <div>
                    {{ 'pages.newClient.sections.clientInformation.fields.phoneNumber.label' | translate }}
                  </div>
                  <div *ngIf="hasRequiredField(clientForm.get('phoneNumber'))" class="p-error ml-1">*</div>
                </label>
                <p-inputMask
                  id="phoneNumber"
                  class="flex flex-column"
                  type="text"
                  formControlName="phoneNumber"
                  [placeholder]="'pages.newClient.sections.clientInformation.fields.phoneNumber.placeholder' | translate"
                  mask="(999) 999-9999"
                ></p-inputMask>
                <iapplication2-form-field-error
                  *ngIf="clientForm.get('phoneNumber').touched || formSubmissionTriggered"
                  [control]="clientForm.get('phoneNumber')"
                  style="bottom: -8px"
                ></iapplication2-form-field-error>
              </div>
            </div>
          </div>
          <div *ngIf="(!isEditable && clientForm.get('countryOfBirth').value) || isEditable" class="grid col-12">
            <div class="grid col-12 lg:col-8">
              <div class="p-field flex flex-column col-12 lg:col-6 lg:mb-0">
                <label for="countryOfBirth" class="flex">
                  <div>
                    {{ 'pages.newClient.sections.clientInformation.fields.countryOfBirth.label' | translate }}
                  </div>
                  <div *ngIf="hasRequiredField(clientForm.get('countryOfBirth'))" class="p-error ml-1">*</div>
                </label>
                <p-dropdown
                  id="countryOfBirth"
                  [options]="countries"
                  formControlName="countryOfBirth"
                  optionLabel="name"
                  [filter]="true"
                  filterBy="name"
                  [showClear]="true"
                  [placeholder]="translatedPlaceholdersForDropdowns.countryOfBirth"
                >
                  <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center" *ngIf="clientForm?.controls['countryOfBirth']?.value">
                      <img
                        src="assets/demo/flags/flag_placeholder.png"
                        [class]="'flag flag-' + clientForm.controls['countryOfBirth'].value.code.toLowerCase()"
                      />
                      <div class="ml-2">
                        {{ clientForm.controls['countryOfBirth'].value.name }}
                      </div>
                    </div>
                  </ng-template>
                  <ng-template let-countryOfBirth pTemplate="item">
                    <div class="flex align-items-center">
                      <img src="assets/demo/flags/flag_placeholder.png" [class]="'flag flag-' + countryOfBirth.code.toLowerCase()" />
                      <div class="ml-2">{{ countryOfBirth.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <iapplication2-form-field-error
                  *ngIf="clientForm.get('countryOfBirth').touched || formSubmissionTriggered"
                  [control]="clientForm.get('countryOfBirth')"
                  style="bottom: -8px"
                ></iapplication2-form-field-error>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-12 md:col-12" *ngIf="showFullContent">
        <div class="card grid">
          <div class="card-header col-12">
            <div class="card-header-title flex flex-row justify-content-start">
              <span class="card-header-title-counter flex align-items-center"> 2 </span>
              <h5>
                {{ 'pages.newClient.sections.address.title' | translate }}
              </h5>
            </div>
          </div>
          <div class="grid col-12">
            <div class="p-field flex flex-column col-12 mb-4 lg:col-8 lg:mb-0">
              <label for="address" class="flex">
                <div>
                  {{ 'pages.newClient.sections.address.fields.address.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.get('address'))" class="p-error ml-1">*</div>
              </label>
              <input
                id="address"
                type="text"
                class="p-inputtext p-component"
                pInputText
                [placeholder]="'pages.newClient.sections.address.fields.address.placeholder' | translate"
                formControlName="address"
              />
              <iapplication2-form-field-error
                *ngIf="clientForm.get('address').touched || formSubmissionTriggered"
                [control]="clientForm.get('address')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
          </div>
          <div class="grid col-12 lg:col-8">
            <div class="p-field flex flex-column col-12 lg:col-6 lg:mb-0">
              <label for="province" class="flex">
                <div>
                  {{ 'pages.newClient.sections.address.fields.province.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.get('province'))" class="p-error ml-1">*</div>
              </label>
              <p-dropdown
                id="province"
                [options]="provinces"
                formControlName="province"
                optionLabel="name"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                [placeholder]="translatedPlaceholdersForDropdowns.province"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center" *ngIf="clientForm?.controls['province']?.value">
                    <div class="ml-2">
                      {{ clientForm?.controls['province']?.value.name }}
                    </div>
                  </div>
                </ng-template>
                <ng-template let-province pTemplate="item">
                  <div class="flex align-items-center">
                    <div class="ml-2">{{ province.name }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <iapplication2-form-field-error
                *ngIf="clientForm.get('province').touched || formSubmissionTriggered"
                [control]="clientForm.get('province')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
            <div class="p-field flex flex-column col-12 mb-4 lg:col-6 lg:mb-0">
              <label for="city" class="flex">
                <div>
                  {{ 'pages.newClient.sections.address.fields.city.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.controls['city'])" class="p-error ml-1">*</div></label
              >
              <input
                id="city"
                type="text"
                class="p-inputtext p-component"
                pInputText
                formControlName="city"
                [placeholder]="'pages.newClient.sections.address.fields.city.placeholder' | translate"
              />
              <iapplication2-form-field-error
                *ngIf="clientForm.get('city').touched || formSubmissionTriggered"
                [control]="clientForm.get('city')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
          </div>

          <div class="grid col-12 lg:col-8">
            <div class="p-field flex flex-column col-12 lg:col-6 lg:mb-0">
              <label for="postalCode" class="flex">
                <div>
                  {{ 'pages.newClient.sections.address.fields.postalCode.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.controls['postalCode'])" class="p-error ml-1">*</div>
              </label>
              <p-inputMask
                id="postalCode"
                class="flex flex-column"
                type="text"
                formControlName="postalCode"
                [placeholder]="'pages.newClient.sections.address.fields.postalCode.placeholder' | translate"
                mask="a9a 9a9"
              ></p-inputMask>
              <iapplication2-form-field-error
                *ngIf="clientForm.get('postalCode').touched || formSubmissionTriggered"
                [control]="clientForm.get('postalCode')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-12 md:col-12" *ngIf="showFullContent">
        <div class="card grid">
          <div class="card-header col-12">
            <div class="card-header-title flex flex-row justify-content-start">
              <span class="card-header-title-counter flex align-items-center"> 3 </span>
              <h5>{{ 'pages.newClient.sections.note.title' | translate }}</h5>
            </div>
          </div>
          <div class="grid col-12">
            <div class="p-field flex flex-column col-12 lg:col-8 lg:mb-0">
              <label class="flex">
                <div>
                  {{ 'pages.newClient.sections.note.fields.note.label' | translate }}
                </div>
                <div *ngIf="hasRequiredField(clientForm.controls['note'])" class="p-error ml-1">*</div>
              </label>
              <textarea
                [rows]="5"
                pInputTextarea
                [autoResize]="true"
                formControlName="note"
                [readOnly]="!isEditable"
                class="text-area"
              ></textarea>
              <iapplication2-form-field-error
                *ngIf="clientForm.get('note').touched || formSubmissionTriggered"
                [control]="clientForm.get('note')"
                style="bottom: -8px"
              ></iapplication2-form-field-error>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
