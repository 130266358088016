import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  params = new HttpParams();
  constructor(private http: HttpClient) {
    this.params = this.params.append('page', '1');
    this.params = this.params.append('take', '15');
    this.params = this.params.append('order', 'DESC');
  }

  getDashboardClients(): Observable<unknown> {
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('take', '15');
    params = params.append('order', 'DESC');

    return this.http.get('client/getAllFiltered', { params });
  }

  getRecentPolicies(): Observable<unknown> {
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('take', '5');
    params = params.append('order', 'DESC');

    return this.http.get('policy/getAll', { params });
  }

  getRecentDtc(): Observable<unknown> {
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('take', '15');
    params = params.append('order', 'DESC');

    return this.http.get('dtc-policy/getAllFiltered', { params });
  }

  getRecentApplications(): Observable<unknown> {
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('take', '15');
    params = params.append('order', 'DESC');

    return this.http.get('application/getAllFiltered', { params });
  }
}
