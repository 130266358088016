import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuActionsService {
  private _activeMenu: boolean;

  staticMenu: ReplaySubject<boolean> = new ReplaySubject(1);

  mobileMenuActive: ReplaySubject<boolean> = new ReplaySubject(1);

  // constructor() {}

  changedMenuState(isStatic: boolean) {
    localStorage.setItem('isMenuStatic', JSON.stringify(isStatic));
    this.staticMenu.next(isStatic);
  }

  changedMobileMenuState(isMobileMenuActive: boolean) {
    this.mobileMenuActive.next(isMobileMenuActive);
  }

  // set staticMenu(value: boolean) {
  //   this._staticMenu = value;
  //   if (value === true) {
  //     this._activeMenu = value;
  //   }
  // }

  get activeMenu(): boolean {
    return this._activeMenu;
  }

  set activeMenu(value: boolean) {
    this._activeMenu = value;
  }
}
