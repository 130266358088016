import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { ENV, FormListDisplay, Page } from '@iapplication2/interfaces';
import { ReplaySubject, Subject } from 'rxjs';
import { CanvasService } from '../canvas/canvas.service';

@Injectable({
  providedIn: 'root',
})
export class FormManagementService {
  currentFormId: ReplaySubject<number> = new ReplaySubject(1);
  currentPageId: ReplaySubject<number> = new ReplaySubject(1);
  currentSectionId: ReplaySubject<number> = new ReplaySubject(1);

  currentCarrierId: ReplaySubject<string> = new ReplaySubject(1);

  changedPage: Subject<Page> = new Subject();

  fetchedForm: ReplaySubject<FormListDisplay> = new ReplaySubject();

  private _form: FormListDisplay;

  public get form(): FormListDisplay {
    return this._form;
  }
  public set form(value: FormListDisplay) {
    this._form = value;
  }

  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient, private canvasService: CanvasService) {}

  getFormsByCarrierId(carrierId: string) {
    return this.http.get(`form-builder/forms/${carrierId}`);
  }

  getFormById(id: number) {
    this.http.get(`form-builder/form/${id}`).subscribe((res: FormListDisplay) => {
      if (res) {
        this.form = res;
        this.canvasService.currentCanvasForm = res;
        this.fetchedForm.next(res);
      }
    });
  }

  duplicateForm(id: number, reqBody) {
    return this.http.post(`form-builder/duplicate-form/${id}`, reqBody);
  }

  updatePdfForForm(id: number, reqBody) {
    return this.http.put(`form-builder/update-pdf-for-form/${id}`, reqBody);
  }

  addForm(form: FormListDisplay) {
    this.http.post<FormListDisplay>(`form-builder/forms`, form).subscribe();
  }

  deleteFormById(formId: number) {
    return this.http.delete(`form-builder/form/${formId}`);
  }
}
