<div
  class="card mb-2 p-custom-shadow-3 px-2 py-2 hoverBorder"
  cdkDrag
  fxLayout="column"
  (mouseenter)="highlightTabledOnCanvas()"
  (mouseleave)="unHighlightTabledOnCanvas()"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <span cdkDragHandle>
        <img [src]="isDarkTheme ? './assets/custom/icons/drag-icon-light.svg' : './assets/custom/icons/drag-icon.svg'" class="drag-icon" />
      </span>
      <form class="p-inputgroup ml-2" [formGroup]="tableLabel" *ngIf="isTableLabelInEditMode">
        <input class="p-inputtext p-component" pInputText formControlName="label" type="text" />
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-check"
          styleClass="p-button-warn"
          (click)="saveTableNameChange()"
          [disabled]="tableLabel.get('label').errors"
        ></button>
      </form>
      <p class="mb-1 ml-2" *ngIf="table.name && !isTableLabelInEditMode">
        {{ table.name }}
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="fieldType-chip table">Table</div>
      <button
        pButton
        pRipple
        icon="pi pi-cog"
        (click)="toggleTableMenu(table, groupMenu, $event)"
        class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
      ></button>
      <p-confirmDialog [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"> </p-confirmDialog>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" (click)="toggleExpandedContent($event)">
    <i class="pi pi-chevron-down expand-icon" *ngIf="!showExpandedContent"></i>
    <i class="pi pi-chevron-up expand-icon" *ngIf="showExpandedContent"></i>
    <p class="text-no-select">
      <small>Table - {{ table.columns.length }} columns inside (click to {{ showExpandedContent ? 'collapse' : 'expand' }})</small>
    </p>
  </div>
  <div *ngIf="showExpandedContent" fxLayout="column" fxFlex>
    <p-divider type="dashed"></p-divider>
    <div class="px-4 py-1" cdkDropList [cdkDropListData]="table.fields" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let column of table.columns" fxLayout="row" fxLayoutAlign="start center" class="mb-2 px-2 table-column-container">
        <div fxLayoutAlign="space-between center" fxLayout="row" fxFlex>
          <p class="mb-0" *ngIf="column.name && !column.isTableColumnInEdit">
            {{ column.name }}
          </p>
          <div fxLayoutAlign="end center">
            <div [class]="'fieldType-chip ' + column.type.type">
              {{ column.type.label }}
            </div>
            <button
              pButton
              pRipple
              icon="pi pi-cog"
              (click)="toggleColumnMenu(column, menu, $event)"
              class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <button pButton pRipple label="Save table column changes" (click)="saveTableColumnChanges()"></button>
  </div>
</div>
<p-menu #menu [popup]="true" appendTo="body" [model]="tableColumnsItems" styleClass="larger-p-menu"></p-menu>
<p-menu #groupMenu [popup]="true" appendTo="body" [model]="tableItems" styleClass="larger-p-menu"></p-menu>
