<div class="forgotPassword-body flex flex-column align-items-center justify-content-center">
  <div class="card">
    <div class="forgotPassword-panel flex flex-column align-items-center p-6">
      <a tabindex="0" id="logo-link" class="layout-topbar-logo">
        <img [src]="getLogoPathForCurrentLightMode()" alt="freya-layout" class="forgot-logo" />
      </a>

      <h5>Forgot your password?</h5>
      <p class="mt-1 mb-3">Input your email address below to reset your password.</p>
      <form [formGroup]="forgotPasswordForm" (submit)="submitForgotPassowrdForm()" class="flex flex-column align-items-center width-100">
        <div class="flex flex-column align-items-start mb-4 width-100">
          <input pInputText id="email" placeholder="Email" formControlName="email" class="p-inputtext-lg width-100" />
          <iapplication2-form-field-error
            *ngIf="forgotPasswordForm.get('email').touched || formSubmissionTriggered"
            [control]="forgotPasswordForm.get('email')"
          ></iapplication2-form-field-error>

          <input
            pInputText
            id="newPassword"
            type="password"
            placeholder="New password"
            formControlName="newPassword"
            class="p-inputtext-lg width-100 mt-3"
          />
          <iapplication2-form-field-error
            *ngIf="forgotPasswordForm.get('newPassword').touched || formSubmissionTriggered"
            [control]="forgotPasswordForm.get('newPassword')"
          ></iapplication2-form-field-error>

          <input
            pInputText
            id="newPasswordConfirmation"
            type="password"
            placeholder="Confirm new password"
            formControlName="newPasswordConfirm"
            class="p-inputtext-lg width-100 mt-3"
          />
          <iapplication2-form-field-error
            *ngIf="forgotPasswordForm.get('newPasswordConfirm').touched || formSubmissionTriggered"
            [control]="forgotPasswordForm.get('newPasswordConfirm')"
          ></iapplication2-form-field-error>
        </div>
        <p-messages severity="success" *ngIf="isPasswordResetSuccessfull">
          <ng-template pTemplate>
            <div>{{ 'login.resetPasswordSuccessMessage' | translate }}</div>
          </ng-template>
        </p-messages>
        <p-messages severity="error" *ngIf="isPasswordResetSuccessfull === false">
          <ng-template pTemplate>
            <div>{{ errorMessage | translate }}</div>
          </ng-template>
        </p-messages>
        <button pButton pRipple label="Reset my password" type="submit"></button>
      </form>

      <p class="mt-4">
        If you don't need to reset your password, you can
        <a [routerLink]="['/login']">login here</a>
      </p>
    </div>
  </div>
</div>
