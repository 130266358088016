<div class="p-col-12 p-px-0">
  <div class="flex flex-column card ApplicationManagement-table">
    <button
      pButton
      pRipple
      [label]="'pages.applications.buttons.newApplication' | translate"
      (click)="createApplication()"
      class="p-mb-3 w-max"
    ></button>

    <p-tabView class="mt-3" *ngIf="languages" (onChange)="loadApplicationsBasedOnLanguage()" [(activeIndex)]="tabIndex">
      <p-tabPanel *ngFor="let language of languages" [header]="language.name">
        <p-table
          #dt
          [columns]="selectedCols"
          frozenWidth="110px"
          [value]="applications"
          dataKey="id"
          styleClass="p-datatable-customers"
          [rowHover]="true"
          (onLazyLoad)="loadApplications($event)"
          [lazy]="true"
          [loading]="loading"
          [paginator]="true"
          [totalRecords]="totalRecords"
          [rows]="rows"
          [globalFilterFields]="['premium']"
          [scrollable]="true"
          [style]="{ width: '100%' }"
          [showCurrentPageReport]="true"
          [currentPageReportTemplate]="
            'applicationManagement.applicationsTable.currentPage'
              | translate: { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' }
          "
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
              <span *ngIf="isEnvDevelop" class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="filterGlobal($any($event.target).value)"
                  [placeholder]="'applicationManagement.applicationsTable.globalSearch' | translate"
                />
              </span>
              <p-multiSelect
                [options]="scrollableCols"
                [(ngModel)]="selectedCols"
                optionLabel="header"
                [selectedItemsLabel]="'applicationManagement.applicationsTable.selectedColumns' | translate: { number: '{0}' }"
                [style]="{ minWidth: '200px' }"
                (onChange)="selectedColsChanged()"
                [placeholder]="'applicationManagement.applicationsTable.chooseColumns' | translate"
              >
              </p-multiSelect>
            </div>
          </ng-template>
          <ng-template pTemplate="frozenheader" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th pFrozenColumn>{{ 'applicationManagement.applicationsTable.actions' | translate }}</th>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
                <p-columnFilter
                  [matchModeOptions]="matchModeOptions"
                  type="text"
                  [field]="col.field"
                  display="menu"
                  class="p-ml-auto"
                ></p-columnFilter>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-application let-editing="editing" let-ri="rowIndex" let-columns="columns">
            <tr class="p-selectable-row" #unfrozenRows [pEditableRow]="application">
              <td pFrozenColumn style="max-width: 79px">
                <div>
                  <p-button icon="pi pi-cog" class="p-button-secondary" (click)="menu.toggle($event)"></p-button>
                  <p-menu #menu [popup]="true" [model]="onClickMenu(application)" appendTo="body"></p-menu>
                </div>
              </td>
              <td *ngFor="let col of columns">
                <p-cellEditor>
                  <ng-template pTemplate="output">
                    <div
                      *ngIf="col.field === 'client'"
                      class="name-column"
                      [pTooltip]="application?.client?.firstName + ' ' + application?.client?.lastName"
                    >
                      <a [routerLink]="['/clients', 'client', application.client.id]">
                        {{ application?.client?.firstName }}
                        {{ application?.client?.lastName }}
                      </a>
                    </div>
                    <div *ngIf="col.field === 'age'">
                      {{ getAge(application.client.birthDate) }}
                    </div>
                    <div *ngIf="col.field === 'date'">
                      {{ application.date | date }}
                    </div>
                    <div *ngIf="col.field === 'time'">
                      {{ application.date | date: 'shortTime' }}
                    </div>
                    <div *ngIf="col.field === 'id'">{{ application.id }}</div>
                    <div *ngIf="col.field === 'products'">
                      <ul>
                        <li *ngFor="let product of application?.products">
                          <b>{{ product?.lokaliseKey | translate }}</b>
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="col.field === 'phone'">
                      {{ application.client.phoneNumber }}
                    </div>
                    <div *ngIf="col.field === 'premium'">
                      {{ application?.totalPremium }}
                    </div>
                    <div *ngIf="col.field === 'status'">
                      <iapplication2-product-status-tag
                        [status]="application.status"
                        [scheduled]="application.scheduleDate"
                      ></iapplication2-product-status-tag>
                    </div>
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">{{ 'applicationManagement.applicationsTable.notFound' | translate }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="paginatorleft">
            <div>
              <span>{{ 'pages.clients.table.rowsDisplay' | translate }}: </span>
              <p-dropdown styleClass="p-paginator-rpp-options" [options]="rowsPerPage" (onChange)="rowsChange($event)"></p-dropdown>
            </div>
          </ng-template>
          <ng-template pTemplate="paginatorright">
            <div>
              <span>{{ 'pages.clients.table.jumpToPage' | translate }}: </span>
              <p-dropdown
                styleClass="p-paginator-rpp-options"
                [filter]="true"
                filterBy="value"
                optionLabel="value"
                optionValue="value"
                [options]="totalPages"
                [(ngModel)]="page"
                (onChange)="setCurrentPage($event)"
                [placeholder]="'pages.clients.table.jumpToPagePlaceholder' | translate"
                [style]="{ width: '140px' }"
              ></p-dropdown>
            </div>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
