import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Client, Country, ENV, Language, Province, envType, LanguagesEnum, ClientFormEnum, TitleEnumEn } from '@iapplication2/interfaces';
import { UtilsService, DatesService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { APP_CONFIG } from '@iapplication2/app-config';
import { TranslateService } from '@ngx-translate/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GenderOptions } from 'apps/api-service/src/app/common/enums';

@Component({
  selector: 'iapplication2-application-client-information',
  templateUrl: './application-client-information.component.html',
  styleUrls: ['./application-client-information.component.scss'],
})
export class ApplicationClientInformationComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() selectedClient: Client;
  @Input() formSubmissionTriggered = false;

  titleOptions;
  genderOptions;
  provinces: Province[];
  languages: Language[];
  smokerStatuses: string[] = [];
  currentYear: number;
  realAge: number;
  ageNearest: number;
  maxDOB: Date;

  unsubscribe: Subject<unknown> = new Subject();

  envType = envType;

  constructor(
    private utilsService: UtilsService,
    private datesService: DatesService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit() {
    this.setInitialOptions();
    this.setOptionsOnLangChange();
    this.watchDobChange();
    this.watchFormChange();
  }

  setInitialOptions() {
    this.currentYear = new Date().getFullYear();
    this.getProvinces();
    this.smokerStatuses = this.utilsService.getSmokerStatuses();
    this.titleOptions = this.utilsService.getTitles();
    this.genderOptions = this.utilsService.getGenders();

    const today = new Date();
    this.maxDOB = new Date(today.getFullYear() - 17, today.getMonth(), today.getDate());

    this.utilsService
      .getLanguages()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.languages = res as Language[];
        }
      });
  }

  setOptionsOnLangChange() {
    this.translateService.onLangChange.pipe().subscribe(() => {
      this.smokerStatuses = this.utilsService.getSmokerStatuses();
      this.titleOptions = this.utilsService.getTitles();
      this.genderOptions = this.utilsService.getGenders();
      this.getProvinces();
    });
  }

  getProvinces() {
    this.utilsService
      .getProvincesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.provinces = res?.map((province, index) => {
            const name = this.getNameKeyBySelectedLanguage();
            return {
              id: index + 1,
              name: province[name],
              abbreviation: province.prov_short,
            };
          });
        },
      });
  }

  getNameKeyBySelectedLanguage(): string {
    let name: string;
    switch (this.translateService.currentLang) {
      case LanguagesEnum.EN:
        name = 'name';
        break;
      default:
        name = `name_${this.translateService.currentLang}`;
    }
    return name;
  }

  watchDobChange() {
    this.form
      .get('birthDate')
      .valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500))
      .subscribe({
        next: (res) => {
          if (res) {
            this.calculateAges(res);
          }
        },
      });
  }

  watchFormChange() {
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      const titleValue = this.form.controls[ClientFormEnum.TITLE].value;
      const genderValue = this.form.controls[ClientFormEnum.GENDER].value;

      if (
        titleValue === TitleEnumEn.MR && genderValue === GenderOptions.FEMALE ||
        titleValue === TitleEnumEn.MRS && genderValue === GenderOptions.MALE ||
        titleValue === TitleEnumEn.MS && genderValue === GenderOptions.MALE ||
        titleValue === TitleEnumEn.MISS && genderValue === GenderOptions.MALE
      ) {
        this.form.controls[ClientFormEnum.TITLE].setErrors({ 'invalidTitleAndGender': true });
        this.form.controls[ClientFormEnum.TITLE].markAsDirty();
        this.form.controls[ClientFormEnum.TITLE].markAllAsTouched();

        this.form.controls[ClientFormEnum.GENDER].setErrors({ 'invalidTitleAndGender': true });
        this.form.controls[ClientFormEnum.GENDER].markAsDirty();
        this.form.controls[ClientFormEnum.GENDER].markAllAsTouched();
      } else {
        this.form.controls[ClientFormEnum.TITLE].setErrors(null);
        this.form.controls[ClientFormEnum.GENDER].setErrors(null);
      }
    });
  }

  private calculateAges(birthDate: string) {
    this.realAge = this.datesService.calculateAge(birthDate);
    this.ageNearest = this.datesService.getAgeNearest(birthDate);
  }

  autofillForm(smokerSelection = false) {
    this.form.patchValue({
      title: 'Mr.',
      gender: 'Male',
      firstName: 'Test',
      middleName: 'Middle',
      lastName: 'Last',
      language: 'en',
      email: 'testemail@mail.com',
      birthDate: new Date('1993-11-11T00:00:00.000Z'),
      smokerStatus: smokerSelection ? 'no' : null,
      reflexSmokerStatus: null,
      phoneNumber: '(446) 554-4644',
      countryOfBirth: {} as Country,
      address: 'street 1',
      province: {
        id: 1,
        abbreviation: 'AB',
        name: 'Alberta',
      },
      city: 'City',
      postalCode: 'a8a8a8',
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
