import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdvisorCardComponent } from './advisor-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule, FlexLayoutModule],
  declarations: [AdvisorCardComponent],
  exports: [AdvisorCardComponent],
})
export class AdvisorCardModule {}
