<div class="registerPage-body flex flex-column align-items-center justify-content-center">
  <div class="card">
    <div class="registerPage-panel flex flex-column align-items-center p-6">
      <a tabindex="0" id="logo-link" class="layout-topbar-logo">
        <img [src]="getLogoPathForCurrentLightMode()" alt="freya-layout" class="register-logo" />
      </a>

      <h5>Register</h5>

      <form [formGroup]="registerForm" class="flex flex-column width-100" (submit)="submitRegisterForm()">
        <div class="flex flex-column align-items-start mb-3">
          <input pInputText id="email" placeholder="Email" formControlName="email" class="p-inputtext-lg width-100" />
          <iapplication2-form-field-error
            *ngIf="registerForm.get('email').touched || formSubmissionTriggered"
            [control]="registerForm.get('email')"
          ></iapplication2-form-field-error>
        </div>
        <div class="flex flex-column align-items-start mb-3">
          <input pInputText id="firstName" placeholder="First Name" formControlName="firstName" class="p-inputtext-lg width-100" />
          <iapplication2-form-field-error
            *ngIf="registerForm.get('firstName').touched || formSubmissionTriggered"
            [control]="registerForm.get('firstName')"
          ></iapplication2-form-field-error>
        </div>
        <div class="flex flex-column align-items-start mb-3">
          <input pInputText id="lastName" placeholder="Last Name" formControlName="lastName" class="p-inputtext-lg width-100" />
          <iapplication2-form-field-error
            *ngIf="registerForm.get('lastName').touched || formSubmissionTriggered"
            [control]="registerForm.get('lastName')"
          ></iapplication2-form-field-error>
        </div>
        <div class="mb-3 flex flex-column align-items-start">
          <p-password
            id="password"
            placeholder="Password"
            type="password"
            [toggleMask]="true"
            formControlName="password"
            (change)="onPasswordChange()"
            autocomplete="new-password"
            #passwordInput
            [strongRegex]="strongPasswordRegex"
            [mediumRegex]="mediumPasswordRegex"
            class="p-inputtext-lg"
          >
            <ng-template pTemplate="footer">
              <p-divider></p-divider>
              <p class="mt-2">Suggestions</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>Minimum 6 characters</li>
              </ul>
            </ng-template>
          </p-password>
          <iapplication2-form-field-error
            *ngIf="registerForm.get('password').touched || formSubmissionTriggered"
            [control]="registerForm.get('password')"
          ></iapplication2-form-field-error>
        </div>
        <div class="mb-3 flex flex-column align-items-start">
          <!-- <input
            type="password"
            pPassword
            id="passwordConfirmation"
            placeholder="Password"
            (change)="onPasswordChange()"
            formControlName="passwordConfirmation"
            class="p-inputtext-lg"
          /> -->
          <p-password
            #passwordConfirmationInput
            id="passwordConfirmation"
            placeholder="Password"
            type="password"
            (change)="onPasswordChange()"
            [toggleMask]="true"
            formControlName="passwordConfirmation"
            [strongRegex]="strongPasswordRegex"
            [mediumRegex]="mediumPasswordRegex"
            class="p-inputtext-lg"
          >
            <ng-template pTemplate="footer">
              <p-divider></p-divider>
              <p class="mt-2">Suggestions</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>Minimum 6 characters</li>
              </ul>
            </ng-template>
          </p-password>
          <iapplication2-form-field-error
            *ngIf="registerForm.get('passwordConfirmation').touched || formSubmissionTriggered"
            [control]="registerForm.get('passwordConfirmation')"
          ></iapplication2-form-field-error>
        </div>
        <small *ngFor="let error of backendErrors" class="p-error mb-3">
          {{ error }}
        </small>
        <button pButton pRipple label="Register" type="submit"></button>
      </form>
      <p class="mt-3">
        Already have an account?
        <a [routerLink]="['/login']">Login here</a>
      </p>
    </div>
  </div>
</div>
