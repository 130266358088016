import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldType, FieldTypeEnum, FormBuilderItem } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-display-condition-value-selection',
  templateUrl: './display-condition-value-selection.component.html',
  styleUrls: ['./display-condition-value-selection.component.scss'],
})
export class DisplayConditionValueSelectionComponent implements OnInit, OnChanges {
  @Input() conditionFormGroup: FormGroup;
  @Input() listOfFields: FormBuilderItem[];
  fieldType: FieldType;
  fieldTypeEnum = FieldTypeEnum;
  options: FormBuilderItem[];
  groupId: string;
  dropdownOptions: string[];

  FormControlType = FormControl;

  ngOnInit(): void {
    this.getFieldType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conditionFormGroup?.currentValue !== changes.conditionFormGroup?.previousValue) {
      this.getFieldType();
    }
  }

  private getFieldType(): void {
    this.options = [];
    this.groupId = null;
    this.fieldType = this.conditionFormGroup.get('conditionFieldId').value?.fieldType;
    if (
      this.fieldType?.id === this.fieldTypeEnum.radioGroup ||
      this.fieldType?.id === this.fieldTypeEnum.checkboxGroup ||
      this.fieldType?.id === this.fieldTypeEnum.disclosure
    ) {
      const groupId = this.conditionFormGroup.get('conditionFieldId').value.groupOptions?.id;
      if (groupId) {
        this.groupId = groupId.toString();
        this.options = this.listOfFields.filter((field) => field.groupOptions?.id === groupId);
      }
    }
    if (this.fieldType?.id === this.fieldTypeEnum.dropdown) {
      const field = this.conditionFormGroup.get('conditionFieldId').value;
      this.dropdownOptions =
        field.fieldOptionEntries.predefined.entries.length > 0
          ? field.fieldOptionEntries.predefined.entries
          : field.fieldOptionEntries.entries;
    }
  }
}
