/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormsService {
  constructor(private http: HttpClient) {}

  getFormSectionsList() {
    return of(null);
  }

  getFormSectionsById(formSectionId: number) {
    return of(null);
  }
}
