import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationType, FormBuilderFieldValidatorConfiguration } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-field-details-validator-configuration',
  templateUrl: './field-details-validator-configuration.component.html',
  styleUrls: ['./field-details-validator-configuration.component.scss'],
})
export class FieldDetailsValidatorConfigurationComponent implements OnInit {
  @Input() fieldValidatorConfiguration: FormBuilderFieldValidatorConfiguration;
  @Input() isInEditMode = false;

  validatorConfigurationTypeEnum = ConfigurationType;

  chipValue: string[];

  ngOnInit(): void {
    if (this.fieldValidatorConfiguration.type === ConfigurationType.CHIP) {
      this.chipValue = this.fieldValidatorConfiguration.value.toString().length
        ? this.fieldValidatorConfiguration.value.toString().split(',')
        : [];
    }
  }

  getInputMaxLength(): number {
    return this.fieldValidatorConfiguration?.selectionDisplayConfiguration?.maxFieldLength
      ? this.fieldValidatorConfiguration.selectionDisplayConfiguration.maxFieldLength
      : 4;
  }

  chipAdded(e: any) {
    this.fieldValidatorConfiguration.value = this.fieldValidatorConfiguration.value.toString().length
      ? this.fieldValidatorConfiguration.value + ',' + e.value
      : e.value;
  }

  chipRemoved() {
    this.fieldValidatorConfiguration.value = this.chipValue.join(',');
  }

  getInputMinValue(): number {
    return this.fieldValidatorConfiguration?.selectionDisplayConfiguration?.onlyPositive ? 0 : null;
  }
}
