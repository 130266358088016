import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ApplicationManagementPageComponent } from './application-management-page/application-management-page.component';
import { ApplicationManagementListComponent } from './application-management-list/application-management-list.component';
import { ApplicationsTableComponent } from './tables/applications-table/applications-table.component';
import { PoliciesTableComponent } from './tables/policies-table/policies-table.component';
import { DtcTableComponent } from './tables/dtc-table/dtc-table.component';
import { ApplicationDigitalSignaturesComponent } from './application-digital-signatures/application-digital-signatures.component';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { RequiredModule } from '@iapplication2/interface-design';

import { InputTextModule } from 'primeng/inputtext';
import { TagModule } from 'primeng/tag';
import { MenuModule } from 'primeng/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ApplicationPageComponent } from './application-page/application-page.component';
import { ApplicationNotesComponent } from './application-page/application-page-sections/application-notes/application-notes.component';
import { ApplicationInformationComponent } from './application-page/application-page-sections/application-information/application-information.component';
import { ClientInformationModule } from '@iapplication2/client-management';
import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { ChangeAdvisorModule, FormFieldErrorModule, ProductListDisplayModule, ProductStatusTagModule } from '@iapplication2/ui';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApplicationProcessModule } from '@iapplication2/application-process';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';

@NgModule({
  imports: [
    CommonModule,
    TabViewModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ProductListDisplayModule,
    ButtonModule,
    RadioButtonModule,
    CheckboxModule,
    InputMaskModule,
    RequiredModule,
    MultiSelectModule,
    InputTextModule,
    FormsModule,
    FormFieldErrorModule,
    ClientInformationModule,
    MenuModule,
    ChangeAdvisorModule,
    InputTextareaModule,
    ProductListDisplayModule,
    ProductStatusTagModule,
    TagModule,
    SharedTranslateModule,
    FlexLayoutModule,
    TableModule,
    ToastModule,
    RouterModule.forChild([
      {
        path: '',
        component: ApplicationManagementPageComponent,
      },
      {
        path: 'application/:id',
        component: ApplicationPageComponent,
      },
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ]),
    ProgressSpinnerModule,
    ApplicationProcessModule,
  ],
  declarations: [
    ApplicationManagementPageComponent,
    ApplicationManagementListComponent,
    ApplicationsTableComponent,
    PoliciesTableComponent,
    DtcTableComponent,
    ApplicationPageComponent,
    ApplicationNotesComponent,
    ApplicationInformationComponent,
    ApplicationDigitalSignaturesComponent,
  ],
})
export class ApplicationsManagementModule {}
