<div class="summary-container" fxFlex fxLayout="row">
  <div class="signature-section" fxFlex fxLayout="row">
    <p-card fxFlex="100">
      <iapplication2-application-signature-info></iapplication2-application-signature-info>
    </p-card>
  </div>
  <div fxFlex fxLayout="row" fxLayoutGap="20px">
    <p-card fxFlex="35">
      <iapplication2-summary-info
        *ngIf="summaryPageInfo"
        [summaryPageInfo]="summaryPageInfo"
        [upsellProducts]="selectedUpsellProducts"
      ></iapplication2-summary-info>
      <iapplication2-application-attachments
        *ngIf="applicationAttachments"
        [applicationAttachments]="applicationAttachments"
      ></iapplication2-application-attachments>
      <div>
        <div class="mt-5">
          <button
            pButton
            [style]="{ width: '100%' }"
            class="mb-1 p-button-secondary"
            [label]="'applicationProcess.step.summary.buttons.saveDraft' | translate"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            [style]="{ width: '100%' }"
            class="mb-1"
            [label]="'applicationProcess.step.summary.buttons.sendToCarrier' | translate"
            (click)="sendApplication()"
            [disabled]="disabledSubmitButtons || isApplicationSentForSignature"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            [style]="{ width: '100%' }"
            class="mb-1"
            [label]="'applicationProcess.step.summary.buttons.scheduleSubmission' | translate"
            (click)="openScheduleApplicationModal()"
            [disabled]="disabledSubmitButtons || isApplicationSentForSignature"
          ></button>
          <p-dialog
            [header]="'applicationProcess.step.summary.buttons.scheduleSubmission' | translate"
            [(visible)]="showScheduleApplicationModal"
            [modal]="true"
            [draggable]="false"
            [resizable]="false"
            [style]="{ width: '1040px' }"
            (onHide)="scheduleApplicationModalClosed()"
          >
            <div *ngIf="!scheduleApplicationPremiumReceived" fxFlex fxLayout="column" fxLayoutAlign="center center">
              <span class="mb-3"> {{ 'applicationProcess.step.summary.loadingPremiums' | translate }} </span>
              <p-progressSpinner strokeWidth="4" class="app-loader-spinner"></p-progressSpinner>
            </div>
            <iapplication2-schedule-application-modal
              *ngIf="scheduleApplicationPremiumReceived"
              (closeDialog)="onCloseScheduleApplicationModal()"
              [premiums]="premiums"
              (scheduledDateSelected)="onScheduledDateSelected($event)"
            ></iapplication2-schedule-application-modal>
          </p-dialog>
        </div>
      </div>
    </p-card>
    <p-card fxFlex="65">
      <p-tabView>
        <p-tabPanel [header]="'applicationProcess.step.summary.tabs.applicationPreview' | translate" [selected]="true">
          <iapplication2-pdf-viewer
            [applicationId]="applicationId"
            [productList]="summaryPageInfo.products"
            (loadingComplete)="enableSubmit($event)"
          ></iapplication2-pdf-viewer>
        </p-tabPanel>
        <!--      TODO: Upsell option temporary removed-->
        <!--      <p-tabPanel header="Product upsell">-->
        <!--        <iapplication2-products-information-->
        <!--          *ngIf="summaryPageInfo"-->
        <!--          [summaryInformation]="summaryPageInfo"-->
        <!--          (upsellProductsSelectionChanged)="updateSelectedUpsellProducts($event)"-->
        <!--        ></iapplication2-products-information>-->
        <!--      </p-tabPanel>-->
        <p-tabPanel *ngIf="isEnvDevelop" [header]="'applicationProcess.step.summary.tabs.clientInfo' | translate">
          <iapplication2-client-info [client]="summaryPageInfo?.client" *ngIf="summaryPageInfo"></iapplication2-client-info>
        </p-tabPanel>
        <p-tabPanel *ngIf="isEnvDevelop" [header]="'applicationProcess.step.summary.tabs.medicalQuestionnaire' | translate">
          <iapplication2-summary-health></iapplication2-summary-health>
        </p-tabPanel>
      </p-tabView>
    </p-card>
  </div>
</div>
