import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { Client, ENV } from '@iapplication2/interfaces';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientManagementService {
  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  getAllClientsFiltered(page?: number, limit?: string, sort?: string, order?: string, globalSearchValue?: string): Observable<any> {
    let params = new HttpParams();
    if (page) {
      params = params.append('page', page + '');
    }
    if (limit) {
      params = params.append('take', limit);
    } else {
      params = params.append('take', '10');
    }
    if (sort) {
      params = params.append('sort', sort);
    }
    if (order) {
      params = params.append('order', order);
    }
    if (globalSearchValue) {
      params = params.append('page', '0');
      params = params.append('keyword', globalSearchValue);
    }

    return this.http.get<any>('client' + '/getAllFiltered', { params });
  }

  getAllClients(): Observable<any> {
    return this.http.get<any>('client' + '/getAll');
  }

  createClient(client: Client) {
    return this.http.post('client', client);
  }

  updateClient(client: Client) {
    const url: string = 'client' + `/${client.id}`;
    this.http.put(url, client).subscribe();
  }

  getClientById(id: string) {
    return this.http.get('client' + `/${id}`);
  }

  //TODO pagination
  getPoliciesByClientId(id: string) {
    let params = new HttpParams();
    params = params.append('clientId', id);
    params = params.append('page', 1 + '');
    params = params.append('take', '10');
    params = params.append('sort', 'DESC');
    return this.http.get('client' + '/getAllPolicies', { params });
  }

  //TODO pagination
  getApplicationsByClientId(id: string) {
    let params = new HttpParams();
    params = params.append('clientId', id);
    params = params.append('page', 1 + '');
    params = params.append('take', '10');
    params = params.append('sort', 'DESC');
    return this.http.get('client' + '/getAllApplications', { params });
    return of(null);
  }
}
