<div fxLayout="row wrap">
  <div class="p-2">
    <h5 class="mb-0">{{ formSection?.title }}</h5>
  </div>
  <iapplication2-form-display-area
    *ngFor="let area of formSection?.formAreas"
    [fxFlex]="getFormAreaWidthByFormArea(area)"
    [area]="area"
    [areaForm]="getFormGroupByAreaId(area.id.toString())"
    [isPreviewMode]="isPreviewMode"
    [showStaticText]="true"
    class="p-2"
  ></iapplication2-form-display-area>
  <!-- <button
    pButton
    pRipple
    label="Log form data"
    type="button"
    (click)="logFormData()"
  ></button> -->
</div>
<div *ngIf="false">
  <!-- This is a workaround needed to display the dynamic form in production, while we manage to provide the actual fix, which will be provided only in V2 due to time constratints -->
  <iapplication2-freetext-field-display></iapplication2-freetext-field-display>
  <iapplication2-checkbox-field-display></iapplication2-checkbox-field-display>
  <iapplication2-checkbox-group-display></iapplication2-checkbox-group-display>
  <iapplication2-date-field-display></iapplication2-date-field-display>
  <iapplication2-disclaimer-field-display></iapplication2-disclaimer-field-display>
  <iapplication2-dropdown-field-display></iapplication2-dropdown-field-display>
  <iapplication2-image-field-display></iapplication2-image-field-display>
  <iapplication2-number-field-display></iapplication2-number-field-display>
  <iapplication2-pdf-field-display></iapplication2-pdf-field-display>
  <iapplication2-radio-group-display></iapplication2-radio-group-display>
  <iapplication2-signature-field-display></iapplication2-signature-field-display>
  <iapplication2-disclosure-group-display></iapplication2-disclosure-group-display>
</div>
