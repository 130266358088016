<div class="user-profile">
  <div class="page-header flex align-items-center justify-content-between">
    <h1 class="page-header-title">
      {{ 'userProfile.pageTitle' | translate }}
    </h1>
    <p-button [label]="'userProfile.saveChanges' | translate"></p-button>
  </div>
  <div>
    <div class="col-12 lg:col-12 md:col-12">
      <div class="card grid mb-3">
        <div class="card-header col-12">
          <div class="card-header-title flex flex-row justify-content-start">
            <span class="card-header-title-counter flex align-items-center"> 1 </span>
            <h5>
              {{ 'userProfile.personalInformation.sectionTitle' | translate }}
            </h5>
          </div>
        </div>
        <div class="grid col-12">
          <div class="p-field flex flex-column col-12 mb-2 lg:col-4 lg:mb-0">
            <label for="firstName">
              {{ 'userProfile.personalInformation.firstName' | translate }}
            </label>
            <input id="firstName" type="text" class="p-inputtext p-component" pInputText placeholder="First name" />
          </div>
          <div class="p-field flex flex-column col-12 mb-2 lg:col-4 lg:mb-0">
            <label for="lastName">
              {{ 'userProfile.personalInformation.lastName' | translate }}
            </label>
            <input id="lastName" type="text" class="p-inputtext p-component" pInputText placeholder="Last name" />
          </div>
        </div>
        <div class="grid col-12">
          <div class="p-field flex flex-column col-12 mb-2 lg:col-4 lg:mb-0">
            <label for="middleName">
              {{ 'userProfile.personalInformation.middleName' | translate }}
            </label>
            <input id="middleName" type="text" class="p-inputtext p-component" pInputText placeholder="Middle name" />
          </div>
          <div class="p-field flex flex-column col-12 mb-2 lg:col-4 lg:mb-0">
            <label for="language">
              {{ 'userProfile.personalInformation.language.label' | translate }}
            </label>
            <p-dropdown
              id="language"
              [options]="languages"
              optionLabel="name"
              optionValue="code"
              [placeholder]="'userProfile.personalInformation.language.placeholder' | translate"
              [(ngModel)]="selectedDrop"
              [showClear]="true"
            ></p-dropdown>
          </div>
        </div>
        <div class="grid col-12">
          <div class="p-field flex flex-column col-12 mb-4 lg:col-4 lg:mb-0">
            <label for="email">
              {{ 'userProfile.personalInformation.email' | translate }}
            </label>
            <input id="email" type="text" class="p-inputtext p-component" pInputText placeholder="email@example.ca" />
          </div>
          <div class="p-field flex flex-row col-12 mb-4 lg:col-4 lg:mb-0 justify-content-start align-items-end">
            <div class="flex flex-column pr-2 width-100">
              <label for="phoneNumber" class="mb-2">
                {{ 'userProfile.personalInformation.phoneNumber' | translate }}
              </label>
              <input id="phoneNumber" type="text" class="p-inputtext p-component" pInputText placeholder="Phone number" />
            </div>
            <p-dialog
              *ngIf="displayPhoneVerificationModal"
              [modal]="true"
              header="Verify your phone number"
              [(visible)]="displayPhoneVerificationModal"
              [style]="{ width: '40vw' }"
              showEffect="fade"
            >
              <iapplication2-phone-verification (closeModal)="displayPhoneVerificationModal = false"> </iapplication2-phone-verification>
            </p-dialog>
            <p-button class="sm:mt-4 lg:mt-2" (click)="showPhoneValidationDialog()"> Change </p-button>
          </div>
        </div>
        <div class="p-field flex flex-row col-12 mb-4 lg:col-4 lg:mb-0 justify-content-start align-items-end">
          <div class="flex flex-column pr-2 width-100">
            <label for="password" class="mb-2"> Password </label>
            <input id="password" type="text" class="p-inputtext p-component" pInputText placeholder="Password" />
          </div>
          <p-dialog
            *ngIf="displayPasswordResetModal"
            [modal]="true"
            header="Reset your password"
            [(visible)]="displayPasswordResetModal"
            [style]="{ width: '40vw' }"
            showEffect="fade"
          >
            <iapplication2-change-password></iapplication2-change-password>
          </p-dialog>
          <p-button class="sm:mt-4 lg:mt-2" (click)="showPasswordResetDialog()"> Change </p-button>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-12 md:col-12">
      <div class="card grid mb-3">
        <div class="card-header col-12">
          <div class="card-header-title flex flex-row justify-content-start">
            <span class="card-header-title-counter flex align-items-center"> 2 </span>
            <h5>
              {{ 'userProfile.address.sectionTitle' | translate }}
            </h5>
          </div>
        </div>
        <div class="grid col-12">
          <div class="p-field flex flex-column col-12 mb-4 lg:col-8 lg:mb-0">
            <label for="address">
              {{ 'userProfile.address.address' | translate }}
            </label>
            <input id="address" type="text" class="p-inputtext p-component" pInputText placeholder="Street" />
          </div>
        </div>
        <div class="grid col-12 lg:col-8">
          <div class="p-field flex flex-column col-12 mb-2 lg:col-4 lg:mb-0">
            <label for="country">
              {{ 'userProfile.address.country' | translate }}
            </label>
            <p-dropdown
              id="country"
              [options]="countries"
              [(ngModel)]="selectedCountry"
              optionLabel="name"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="Select a Country"
            >
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="selectedCountry">
                  <img src="assets/demo/flags/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" />
                  <div class="ml-2">{{ selectedCountry.name }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center">
                  <img src="assets/demo/flags/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" />
                  <div class="ml-2">{{ country.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="p-field flex flex-column col-12 mb-2 lg:col-5 lg:mb-0">
            <label for="province">
              {{ 'userProfile.address.province' | translate }}
            </label>
            <p-dropdown
              id="province"
              [options]="provinces"
              [(ngModel)]="selectedProvince"
              optionLabel="name"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
              placeholder="Select a province"
            >
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="selectedProvince">
                  <div class="ml-2">{{ selectedProvince.name }}</div>
                </div>
              </ng-template>
              <ng-template let-province pTemplate="item">
                <div class="flex align-items-center">
                  <div class="ml-2">{{ province.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="p-field flex flex-column col-12 mb-2 lg:col-3 lg:mb-0">
            <label for="zipCode">
              {{ 'userProfile.address.zipCode' | translate }}
            </label>
            <input id="zipCode" type="text" class="p-inputtext p-component" pInputText placeholder="Zip code" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-12 md:col-12">
      <div class="card grid">
        <div class="card-header col-12">
          <div class="card-header-title flex flex-row justify-content-start">
            <span class="card-header-title-counter flex align-items-center"> 3 </span>
            <h5>
              {{ 'userProfile.brokerInformation.sectionTitle' | translate }}
            </h5>
          </div>
        </div>
        <div class="grid col-12 lg:col-8">
          <div class="p-field flex flex-column col-12">
            <label for="brokerCode">
              {{ 'userProfile.brokerInformation.brokerCode' | translate }}
            </label>
            <input id="brokerCode" type="text" class="p-inputtext p-component" pInputText placeholder="Broker code" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
