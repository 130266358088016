import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Illustration, Product } from '@iapplication2/interfaces';
import { QuickQuoteManagementService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'iapplication2-quick-quote-products',
  templateUrl: './quick-quote-products.component.html',
  styleUrls: ['./quick-quote-products.component.scss'],
})
export class QuickQuoteProductsComponent implements OnInit, OnDestroy {
  products: Product[];
  selectedProducts: Product[] = [];
  @Output() productSelected: EventEmitter<Product[]> = new EventEmitter<Product[]>();
  unsubscribe: Subject<unknown> = new Subject();
  @Input() quickQuoteData: FormGroup;

  constructor(
    private quickQuoteManagementService: QuickQuoteManagementService
  ) {}

  ngOnInit(): void {
    this.quickQuoteData.value.faceAmount = +(this.quickQuoteData.value?.faceAmount?.toString().replace(/\./g,''));
    delete this.quickQuoteData.value.province.name;

    this.quickQuoteManagementService
      .getQuickQuoteProductList(this.quickQuoteData.value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: Product[]) => {
        this.products = res;
        this.products.map(product => {
          product.illustrations = this.getSortedIllustrations(product.illustrations);
        });
      });
  }

  onProductSelection(event: any) {
    this.selectedProducts.push(event.data);
    this.productSelected.emit(this.selectedProducts);
  }

  onProductUnselection(event: any) {
    this.selectedProducts = this.selectedProducts.filter((product) => product != event.data);
    this.productSelected.emit(this.selectedProducts);
  }

  private getSortedIllustrations(illustrations: Illustration[]): Illustration[] {
    const sortedIllustrations: Illustration[] = [];
    let illustrationToCompare: Illustration = illustrations[0];

    illustrations.forEach((illustration, index) => {
      if (illustration.monthlyRate !== illustrationToCompare.monthlyRate) {
        illustrationToCompare.age = illustrationToCompare.age + '-' + illustrations[index-1].age;
        sortedIllustrations.push(illustrationToCompare);
        illustrationToCompare = illustration;
      }

      // checks if this is the last illustration in the array
      if (!illustrations[index+1]) {
        illustrationToCompare.age = illustrationToCompare.age + '-' + illustration.age;
        sortedIllustrations.push(illustrationToCompare);
      }
    });
    return sortedIllustrations;
  }


  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
