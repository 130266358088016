export enum ConditionsTypeEnum {
  CONTAINS_STRING = 'contains_string',
  IS_NOT_EMPTY = 'is_not_empty',
  STARTS_WITH = 'starts_with',
  ENDS_WITH = 'ends_with',
  IS_EMPTY = 'is_empty',
  IS_SELECTED = 'is_selected',
  IS_NOT_SELECTED = 'is_not_selected',
  CONTAINS_CERTAIN_VALUE = 'contains_certain_value',
  DOES_NOT_CONTAIN_CERTAIN_VALUE = 'does_not_contain_certain_value',
  CERTAIN_VALUE_SELECTED = 'certain_value_selected',
  CERTAIN_VALUE_NOT_SELECTED = 'certain_value_not_selected',
  IS_EXACT_DATE = 'is_exact_date',
  IS_NOT_EXACT_DATE = 'is_not_exact_date',
  HIGHER_THAN = 'higher_than',
  IS_HIGHER_THAN = 'is_higher_than',
  HIGHER_OR_EQUAL_THAN = 'higher_or_equal_than',
  IS_HIGHER_OR_EQUAL_THAN = 'is_higher_or_equal_than',
  LOWER_THAN = 'lower_than',
  IS_LOWER_THAN = 'is_lower_than',
  LOWER_OR_EQUAL_THAN = 'lower_or_equal_than',
  IS_LOWER_OR_EQUAL_THAN = 'is_lower_or_equal_than',
  IS_EXACTLY = 'is_exactly',
  IS_NOT_EXACTLY = 'is_not_exactly',
  DISCLOSURE_READ = 'disclosure_read',
  DISCLOSURE_NOT_READ = 'disclosure_not_read',
  HAS_SIGNATURE = 'has_signature',
  DOES_NOT_HAVE_SIGNATURE = 'does_not_have_signature'
}
