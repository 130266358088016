<div *ngIf="!item.groupOptions && !item.columns">
  <div
    class="predefinedField card hoverBorder p-custom-shadow-3 mx-3 py-1 mb-3"
    fxLayout="row"
    fxFlex
    [title]="(item | cast: FormBuilderFieldType).options?.customFieldLabel"
  >
    <div class="my-2 ml-5">
      <h6 class="customFieldLabel mb-2">
        {{ (item | cast: FormBuilderFieldType).options?.customFieldLabel }}
      </h6>
      <div [class]="'fieldType-chip ' + (item | cast: FormBuilderFieldType).fieldType?.type">
        {{ (item | cast: FormBuilderFieldType).fieldType?.label }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="item.groupOptions && !item.table">
  <div
    class="predefinedField card hoverBorder p-custom-shadow-3 mx-3 py-1 mb-3"
    fxLayout="row"
    fxFlex
    [title]="(item | cast: FormBuilderGroupType).groupOptions.name"
  >
    <div class="my-2 ml-5">
      <h6 class="customFieldLabel mb-2">
        {{ (item | cast: FormBuilderGroupType).groupOptions.name }}
      </h6>
      <div [class]="'fieldType-chip ' + (item | cast: FormBuilderGroupType).groupOptions?.type?.type">
        {{ (item | cast: FormBuilderGroupType).groupOptions?.type?.label }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="item.columns">
  <div
    class="predefinedField card hoverBorder p-custom-shadow-3 mx-3 py-1 mb-3"
    fxLayout="row"
    fxFlex
    [title]="(item | cast: FormBuilderTableType).name"
  >
    <div class="my-2 ml-5">
      <h6 class="customFieldLabel mb-2">
        {{ (item | cast: FormBuilderTableType).name }}
      </h6>
      <div [class]="'fieldType-chip table'">
        Table
        <div></div>
      </div>
    </div>
  </div>
</div>
