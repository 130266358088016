<div
  *ngIf="!products?.length">{{ 'applicationProcess.step.summary.information.product.emptyUpsellList' | translate }}</div>
<div *ngIf="products?.length" class="card" fxFlex fxLayout="column">
  <div fxFlex fxLayout="row" fxLayoutAlign="end center" class="pb-3" *ngIf="products?.length">
    <p-badge [value]="products?.length.toString()" styleClass="custom-badge-severity" class="mr-2"></p-badge>
    <p [ngStyle]="{ color: '#ff7265' }"
       class="font-bold">{{ 'applicationProcess.step.summary.information.product.availableProducts' | translate }}</p>
  </div>
  <form [formGroup]="coverageForm">
    <p-table [value]="products" dataKey="name" responsiveLayout="scroll">
      <ng-template pTemplate="body" let-product let-expanded="expanded">
        <tr>
          <td style="width: 50px">
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="product"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></button>
          </td>
          <td fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
              <p-checkbox name="product" [binary]="true" class="pr-1"
                          (onChange)="addProduct($event, product?.productId)"></p-checkbox>
              <p class="mb-0 font-bold">
                {{ product.name }}
              </p>
            </div>
          </td>
          <td class="px-3 font-bold">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
              {{ product?.monthlyPremium }}${{ 'client_selection.per_month' | translate }}
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-product>
        <div fxLayout="row" class="p-3">
          <div fxLayout="column" class="mb-2">
            <label for="product_coverage">
              {{
                'applicationProcess.step.summary.information.product.coverageRange' | translate: {
                  min: product?.minCoverage, max: product?.maxCoverage }
              }}
            </label>
            <p-dropdown
              class="mb-3 mt-3"
              id="product_coverage"
              [options]="ratesSteps"
              [editable]="false"
              [formControlName]="'coverage-' + product.id"
              (onChange)="changedDropdown($event, product)"
              fxFlex
              fxFill
            ></p-dropdown>
            <p-inputNumber
              class="min-max"
              [formControlName]="'coverage-' + product.id"
              [showButtons]="true"
              buttonLayout="horizontal"
              inputId="horizontal"
              spinnerMode="horizontal"
              [max]="product?.max_coverage"
              [min]="product?.min_coverage"
              [step]="500"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              mode="currency"
              currency="CAD"
              (onInput)="onInputChange($event.value, product)"
            >
            </p-inputNumber>
          </div>

          <div fxFlex="5"></div>

          <div fxLayout="column" class="mb-3">
            <div class="p-field-checkbox">
              <p-checkbox [formControlName]="'everest-' + product.id" [binary]="true" inputId="everest"></p-checkbox>
              <label>{{ 'applicationProcess.step.summary.information.product.addEverest' | translate }}</label>
            </div>
          </div>

          <div fxFlex="5"></div>

          <div fxLayout="column" fxFlex="30" fxFill fxLayoutAlign="flex-end">
            <p class="mb-1">
              <b>{{ 'rates.monthlyPremium' | translate }} </b> ${{ product?.monthlyPremium }}
            </p>
            <p>
              <b>{{ 'rates.yearlyPremium' | translate }}</b> ${{ product?.yearlyPremium }}
            </p>
          </div>
        </div>
      </ng-template>
    </p-table>
  </form>
</div>
