import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'iapplication2-global-search-section-search',
  templateUrl: './global-search-section-search.component.html',
  styleUrls: ['./global-search-section-search.component.scss'],
})
export class GlobalSearchSectionSearchComponent implements OnInit {
  constructor(private router: Router) {}

  @Input() globalSearchForm;
  @Input() availableEntities;

  localSearchForm;

  ngOnInit() {
    /* The localSearchForm is needed so the highlighting of the searched term is only done when the search is performed.
     Updating the globalSearchForm directly would pass the value on every keystroke instead. */
    this.createLocalSearchForm();
    this.localSearchForm = _.cloneDeep(this.globalSearchForm);
  }

  createLocalSearchForm() {
    this.localSearchForm = new FormGroup({
      searchQuery: new FormControl(null),
      selectedEntities: new FormControl(null),
    });
  }

  searchWithProvidedQuery() {
    this.globalSearchForm.controls['searchQuery'].setValue(
      this.localSearchForm.controls['searchQuery'].value
    );
    const query = this.globalSearchForm.controls['searchQuery'].value;
    query
      ? this.router.navigate(['/search'], { queryParams: { q: query } })
      : this.router.navigate(['/search']);
  }

  multiSelectChanged() {
    this.globalSearchForm.controls['selectedEntities'].setValue(
      this.localSearchForm.controls['selectedEntities'].value
    );
  }
}
