<div class="py-3">
  <div *ngIf="selectedProducts.length" class="card">
    <h5>{{ 'applicationProcess.step.applicationForms.payment.products.title' | translate }}</h5>
    <p-table [value]="selectedProducts" [resizableColumns]="true" [autoLayout]="true" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'applicationProcess.step.applicationForms.payment.products.table.selectedProducts' | translate }}</th>
          <th>{{ 'applicationProcess.step.applicationForms.payment.products.table.coverage' | translate }}</th>
          <th>{{ 'applicationProcess.step.applicationForms.payment.products.table.monthlyPremium' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.name }}</td>
          <td>
            <span> {{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ product.coverage }} </span>
          </td>
          <td>
            <span> {{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ product?.monthlyPremium }} </span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr>
          <td>{{ 'applicationProcess.step.applicationForms.payment.products.table.total' | translate }}</td>
          <td></td>
          <td>
            <span> {{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ totalMonthlyPremium.toFixed(2) }} </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="py-2">
  <div *ngIf="paymentTypeForm.controls['type'].value; else noPaymentMethodAvailable">
    <form [formGroup]="paymentTypeForm">
      <p-radioButton
        [disabled]="!availablePaymentMethods.PAD"
        name="type"
        [value]="paymentTypeEnum.PAD"
        formControlName="type"
        label="PAD"
        class="mr-3"
      ></p-radioButton>
      <p-radioButton
        [disabled]="!availablePaymentMethods.creditCard"
        name="type"
        [value]="paymentTypeEnum.CARD"
        formControlName="type"
        label="Credit Card"
      ></p-radioButton>
    </form>
  </div>
</div>

<div class="py-3">
  <iapplication2-application-payment-popup-pad
    *ngIf="paymentTypeForm.controls['type'].value === paymentTypeEnum.PAD"
    [paymentTypeForm]="paymentTypeForm"
  ></iapplication2-application-payment-popup-pad>

  <iapplication2-application-payment-popup-card
    *ngIf="paymentTypeForm.controls['type'].value === paymentTypeEnum.CARD"
    [paymentTypeForm]="paymentTypeForm"
  ></iapplication2-application-payment-popup-card>

  <ng-template #noPaymentMethodAvailable>{{
    'applicationProcess.step.applicationForms.payment.noPaymentOptionsAvailable' | translate
  }}</ng-template>
</div>
