import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { register } from '../../../../registryComponent';
import { BaseFieldDataDirective } from '../../../../baseFieldData.directive';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@register('number')
@Component({
  selector: 'iapplication2-number-field-display',
  templateUrl: './number-field-display.component.html',
  styleUrls: ['./number-field-display.component.scss'],
})
export class NumberFieldDisplayComponent extends BaseFieldDataDirective implements OnInit, OnDestroy {
  localTabIndex: string;
  @ViewChild('numberInput') numberInput;
  private unsubscribe: Subject<void> = new Subject<void>();
  isViewApplication: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.localTabIndex = this.tabIndex ? this.tabIndex.toString() : null;
    this.getValueChanges();
  }

  getValueChanges() {
    this.formFieldControl.valueChanges.pipe(startWith(this.formFieldControl.value), pairwise(), takeUntil(this.unsubscribe)).subscribe({
      next: (data) => {
        if (data[1] === null || data[1] === '') {
          this.setValueToEmpty();
        }
      },
    });
  }

  onInput(event) {
    if (event.value !== null && event.value !== undefined) {
      this.formFieldControl.setValue(event.value, { emitEvent: true });
    } else {
      this.setValueToEmpty(true);
    }
  }

  private setValueToEmpty(shouldEmitEvent = false) {
    this.formFieldControl.setValue('', { emitEvent: shouldEmitEvent });
    this.numberInput.value = undefined;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
