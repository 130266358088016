import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, DarkThemeService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PasswordRegexConstants } from '@iapplication2/constants';
import { password } from '@iapplication2/validators';
import { Themes } from '@iapplication2/interfaces';
@Component({
  selector: 'iapplication2-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  registerForm: FormGroup;
  formSubmissionTriggered = false;
  isLightTheme: boolean;
  strongPasswordRegex = PasswordRegexConstants.STRONG_PASSWORD_REGEX;
  mediumPasswordRegex = PasswordRegexConstants.MEDIUM_PASSWORD_REGEX;
  backendErrors: string[] = [];

  unsubscribe: Subject<unknown> = new Subject();

  @ViewChild('passwordConfirmationInput') passwordConfirmationInput;
  @ViewChild('passwordInput') passwordInput;

  constructor(
    private authService: AuthService,
    private router: Router,
    private darkThemeService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.createRegisterForm();
    this.isLightTheme = JSON.parse(localStorage.getItem('isLightTheme'));
  }

  public getLogoPathForCurrentLightMode() {
    this.darkThemeService.themeChanged.subscribe((theme) => {
      this.isLightTheme = theme === Themes.LIGHT;
    });
    return this.isLightTheme ? '/assets/layout/images/logo-full.svg' : '/assets/layout/images/logo-full-black.svg';
  }

  ngAfterViewInit(): void {
    this.passwordConfirmationInput.input.nativeElement.setAttribute('placeholder', 'Password confirmation');
    this.passwordInput.input.nativeElement.setAttribute('placeholder', 'Password');
    this.passwordInput.input.nativeElement.setAttribute('autocomplete', 'new-password');
  }

  get password(): AbstractControl {
    return this.registerForm.controls['password'];
  }

  get confirm_password(): AbstractControl {
    return this.registerForm.controls['passwordConfirmation'];
  }

  createRegisterForm() {
    this.registerForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      password: new FormControl(null, [Validators.required, password()]),
      passwordConfirmation: new FormControl(null, [Validators.required]),
    });
  }

  submitRegisterForm() {
    this.formSubmissionTriggered = true;
    if (this.registerForm.valid) {
      this.register();
    }
  }

  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ missmatch: true });
    }
  }

  register() {
    this.backendErrors = [];
    const { email, firstName, lastName, password, passwordConfirmation } = this.registerForm.getRawValue();
    this.authService
      .registerUser(email, firstName, lastName, password, passwordConfirmation)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          this.router.navigate(['login']);
        },
        (e) => {
          this.backendErrors.push(e.error.message);
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
