/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  CategorySlugs,
  CustomFormBuilderField,
  FieldCategory,
  FieldColumn,
  FieldTable,
  FieldType,
  FieldTypeEnum,
  FormBuilderField,
  FormBuilderFieldGroupOptions,
  FormBuilderFieldTypeType,
  FormBuilderGroup,
  FormBuilderItem,
  ModalType,
} from '@iapplication2/interfaces';
import { FieldCategoryService, InteractiveFormBuilderService, PredefinedFieldsService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-fields-sidebar',
  templateUrl: './fields-sidebar.component.html',
  styleUrls: ['./fields-sidebar.component.scss'],
})
export class FieldsSidebarComponent implements OnInit, OnDestroy {
  fieldCategories: FieldCategory[];
  defaultItems: FieldType[];
  searchResultFields: any[];
  @Input() action: ModalType;
  @Input() showOnlyDefaultFieldTypes: boolean = false;
  @Output()
  itemSelected: EventEmitter<FormBuilderItem> = new EventEmitter<FormBuilderItem>();

  predefinedCustomFields: CustomFormBuilderField[] = [];
  predefinedFieldsByCategory: any;

  searchForFieldValue: string;

  showUnassignedList: boolean = false;

  categorySlugEnum = CategorySlugs;

  private unsubscribe = new Subject<void>();

  searchFormGroup: FormGroup = new FormGroup({
    searchInput: new FormControl({ value: '', disabled: true }),
  });
  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private fieldCategoryService: FieldCategoryService,
    private predefinedFieldsService: PredefinedFieldsService
  ) {}

  ngOnInit(): void {
    this.getCustomFieldCategories();

    this.getFieldTypes();
    this.watchSearchFormChanges(); // this.interactiveFormBuilderService.sidebarListWasUpdated.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
    //   this.showUnassignedList = this.interactiveFormBuilderService.getCustomFieldsByCategory('unassigned').length ? true : false;
    // });
  }

  watchSearchFormChanges() {
    this.searchFormGroup
      .get('searchInput')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => this.searchForFieldValueChanged())
      )
      .subscribe();
  }

  getFieldTypes() {
    this.interactiveFormBuilderService
      .getFieldTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FieldType[]) => {
        this.defaultItems = res;
        this.interactiveFormBuilderService.defaultItems.next(res);
        this.toggleVisibilityOfTableOption();
      });
  }

  getCustomFieldCategories(): void {
    this.fieldCategoryService.getFieldCategories();
    this.fieldCategoryService.fatchedCategories.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      this.fieldCategories = res;
      this.getPredefinedFields();

      this.showUnassignedList = !!this.fieldCategories.filter((category) => category.slug === this.categorySlugEnum.UNASSIGNED).length;
    });
  }

  getPredefinedFields() {
    this.predefinedFieldsService.getPredefinedFieldsDebounced();
    this.predefinedFieldsService.$fetchedFields.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      if (res) {
        this.predefinedCustomFields = res;
        this.predefinedFieldsByCategory = this.fieldCategories.map((category) => {
          return {
            category: category,
            fields: this.getFieldsFromCategory(category),
          };
        });
        this.searchFormGroup.get('searchInput').enable();
      }
    });
  }

  getFieldsFromCategory(category: FieldCategory): FormBuilderField[] {
    const fieldsInCategory: FormBuilderField[] = [];
    this.predefinedCustomFields?.forEach((predefinedCustomField) => {
      if (predefinedCustomField.fieldCategory?.id && predefinedCustomField.fieldCategory?.id === category?.id) {
        fieldsInCategory.push(predefinedCustomField.field);
      }
    });
    return fieldsInCategory;
  }

  toggleVisibilityOfTableOption(): void {
    if (this.action === ModalType.FORM) {
      this.defaultItems = this.defaultItems?.filter((item) => item.id !== FieldTypeEnum.table);
    } else {
      this.defaultItems = this.defaultItems?.filter((item) => item.canBeDrawn);
    }
  }

  searchForFieldValueChanged(): void {
    this.searchResultFields = [];
    this.searchForFieldValue = this.searchFormGroup.get('searchInput').value;
    const searchTerm = this.searchForFieldValue.toUpperCase();
    this.predefinedFieldsByCategory?.forEach((predefinedField) => {
      const matchingFields = predefinedField.fields.filter((field) => {
        if (this.interactiveFormBuilderService.isFieldInTable(field)) {
          return field.table?.type?.name?.toUpperCase().includes(searchTerm);
        }
        if (this.interactiveFormBuilderService.isTypeGroup(field.fieldType)) {
          return field.groupOptions?.name?.toUpperCase().includes(searchTerm);
        }
        const customFieldLabel = field.options?.customFieldLabel?.toUpperCase();
        return customFieldLabel.includes(searchTerm);
      });

      if (matchingFields.length) {
        const matchingPredefinedField = { ...predefinedField, fields: matchingFields };
        this.searchResultFields.push(matchingPredefinedField);
      }
    });
  }

  fieldSelected(type: FieldType): void {
    const item = this.craeteItemBasedOnSelectedFieldType(type);
    this.interactiveFormBuilderService.sidebarItemSelected(item);
    this.interactiveFormBuilderService.settingsModalOptionsSelected(this.action);
    this.itemSelected.emit(item);
  }

  craeteItemBasedOnSelectedFieldType(type: FieldType): FormBuilderItem {
    switch (true) {
      case this.interactiveFormBuilderService.isTypeGroup(type): {
        const groupOptions: FormBuilderFieldGroupOptions = {
          name: '',
          type: type,
        };
        const group: FormBuilderGroup = {
          groupOptions: groupOptions,
          fields: [],
        };
        return group;
      }
      case type.type === FormBuilderFieldTypeType.TABLE: {
        const columns: FieldColumn[] = [];
        const table: FieldTable = {
          name: '',
          columns: columns,
          numberOfRows: 0,
        };
        return table;
      }
      default: {
        const field: FormBuilderField = {
          fieldType: type,
          options: {
            customFieldLabel: '',
            customFieldName: '',
            visibleOnPdf: true,
          },
        };
        return field;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
