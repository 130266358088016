<div fxFlex fxLayout="column">
  <div class="pb-3">
    <h4 [ngStyle]="{ color: 'var(--primary-color)' }" class="mb-2">
      {{ 'applicationProcess.step.summary.information.clientInformation' | translate }}
    </h4>
    <p class="mb-1">
      {{ 'applicationProcess.step.summary.information.name' | translate }}: <span class="font-bold">{{ fullName }}</span>
    </p>
    <p class="mb-1">
      {{ 'applicationProcess.step.summary.information.dob' | translate }}:
      <span class="font-bold">
        {{ summaryPageInfo?.client?.birthDate | date }}
        ({{ 'client_selection.age.realAge' | translate: { realAge: realAge } }}) ({{
          'client_selection.age.nearestAge' | translate: { nearestAge: ageNearest }
        }})
      </span>
    </p>
    <p class="mb-1">
      {{ 'applicationProcess.step.summary.information.gender' | translate }}:
      <span class="font-bold">{{ summaryPageInfo?.client?.gender }}</span>
    </p>
    <p class="mb-1">
      {{ 'applicationProcess.step.summary.information.smokingStatus' | translate }}:
      <span class="font-bold">
        {{
          summaryPageInfo?.client?.smokerStatus?.toLowerCase() === 'yes'
            ? ('applicationProcess.step.summary.information.smoker' | translate)
            : ('applicationProcess.step.summary.information.nonSmoker' | translate)
        }}
      </span>
    </p>
    <p class="mb-1">
      {{ 'applicationProcess.step.summary.dates.scheduleDate.label' | translate }}:
      <span *ngIf="summaryPageInfo?.dates?.schedule; else scheduledDateNotApplicable" class="font-bold">
        {{ summaryPageInfo?.dates?.schedule | date }}
      </span>
      <ng-template #scheduledDateNotApplicable>
        <span class="font-bold">
          {{ 'applicationProcess.step.summary.information.notApplicable' | translate }}
        </span>
      </ng-template>
    </p>
  </div>
  <div class="pb-3">
    <h4 [ngStyle]="{ color: 'var(--primary-color)' }" class="mb-2">
      {{ 'applicationProcess.step.summary.information.product.information' | translate }}:
    </h4>
    <p-table [value]="summaryPageInfo?.products">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'applicationProcess.step.summary.information.product.name' | translate }}</th>
          <th>{{ 'applicationProcess.step.summary.information.product.coverage' | translate }}</th>
          <th>{{ 'applicationProcess.step.summary.information.product.monthlyPremium' | translate }}</th>
          <th *ngIf="applicationsProcessService.currentApplication?.billingFrequency === paymentFrequencyEnum.ANNUALY">
            {{ 'applicationProcess.step.summary.information.product.yearlyPremium' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product?.lokaliseKey | translate }}</td>
          <td>{{ summaryPageInfo?.currencySymbol }} {{ product?.coverage }}</td>
          <td>{{ summaryPageInfo?.currencySymbol }} {{ product?.monthlyPremium }}</td>
          <td *ngIf="applicationsProcessService.currentApplication?.billingFrequency === paymentFrequencyEnum.ANNUALY">
            {{ summaryPageInfo?.currencySymbol }} {{ product?.yearlyPremium }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="pb-3">
    <h4 [ngStyle]="{ color: 'var(--primary-color)' }" class="mb-2">
      {{ 'applicationProcess.step.summary.information.payment.title' | translate }}
    </h4>
    <p class="mb-1">
      {{ 'applicationProcess.step.summary.information.payment.mainMethod' | translate }}:
      <span class="font-bold" *ngIf="summaryPageInfo?.paymentMethod; else paymentMethodNotApplicable">
        {{
          summaryPageInfo?.paymentMethod?.toLowerCase() === PaymentMethods.PAD
            ? ('applicationProcess.step.summary.information.payment.pad' | translate)
            : ('applicationProcess.step.summary.information.payment.creditCard' | translate)
        }}
      </span>
      <ng-template #paymentMethodNotApplicable>
        <span class="font-bold">
          {{ 'applicationProcess.step.summary.information.notApplicable' | translate }}
        </span>
      </ng-template>
    </p>
    <p class="mb-1">
      {{ 'applicationProcess.step.summary.information.payment.secondaryMethod' | translate }}:
      <span class="font-bold">
        {{ 'applicationProcess.step.summary.information.notApplicable' | translate }}
      </span>
    </p>
    <p class="mb-1">
      {{ 'applicationProcess.step.summary.information.payment.withdrawalDate' | translate }}:
      <span *ngIf="parsedWithdrawalDate; else withdrawalDateNotApplicable" class="font-bold">
        {{ parsedWithdrawalDate }}
        {{ 'applicationProcess.step.summary.information.payment.dayOfMonth' | translate }}
      </span>
      <ng-template #withdrawalDateNotApplicable>
        <span class="font-bold">
          {{ 'applicationProcess.step.summary.information.notApplicable' | translate }}
        </span>
      </ng-template>
    </p>
    <p class="mb-0">
      {{ 'applicationProcess.step.summary.information.payment.totalMonthly' | translate }}:
      <span class="font-bold">
        {{ summaryPageInfo?.currencySymbol }}
        {{ totalMonthlyPremium | number: '1.2-2' }}
      </span>
    </p>
    <p *ngIf="applicationsProcessService.currentApplication?.billingFrequency === paymentFrequencyEnum.ANNUALY" class="mb-0">
      {{ 'applicationProcess.step.summary.information.payment.totalYearly' | translate }}:
      <span class="font-bold">
        {{ summaryPageInfo?.currencySymbol }}
        {{ totalYearlyPremium | number: '1.2-2' }}
      </span>
    </p>
  </div>
</div>
