import { Client } from '../client.model';
import { ApplicationStatus } from './application-status.model';
import { Currency } from './currency.model';
import { User } from '../user.model';
import { Language } from '../language.model';
import { ApplicationTypeList } from './application-type-list.enum';
import { ApplicationQuestion } from './application-question';
import { ApplicationFna } from './application-fna';
import { ApplicationProduct } from './applicationProduct.model';
import { PaymentMethods } from './payment.enum';
import { StaticApplicationDisclosureAnswer } from 'libs/interfaces/src/lib/advisor-application/static-application-disclosure.model';

export interface CardResponse {
  cardholderId: number;
  sslResult: number;
  success: number;
  token: string;
}

export interface ApplicationPayment extends CardResponse {
  value?: number;
  method?: PaymentMethods;
}

export interface Application {
  id: number;
  client: Client;
  products?: ApplicationProduct[];
  status: ApplicationStatus;
  premium: number;
  currency: Currency;
  advisorId: number;
  date: Date;
  notes: string;
  questions?: ApplicationQuestion[];
  fna?: ApplicationFna;

  assignedTo?: User;
  policyId?: number;
  broker?: User;
  language?: Language;
  startedAt?: Date;
  submittedOn?: Date;
  applicationType?: ApplicationTypeList;
  effectiveDate?: Date;
  scheduleDate?: Date;
  MIBStatus?: string;
  completedAt?: Date;
  scheduledAt?: Date;
  payment?: ApplicationPayment;
  staticApplicationDisclosureAnswers: StaticApplicationDisclosureAnswer[];
  [key: string]: any;
}
