import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  FormBuilderStaticText,
} from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-form-display-text',
  templateUrl: './form-display-text.component.html',
  styleUrls: ['./form-display-text.component.scss'],
})
export class FormDisplayTextComponent implements OnInit {
  @Input() formText: FormBuilderStaticText;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.formText.sanitized = this.sanitizer.bypassSecurityTrustHtml(this.formText.content);
  }
}
