export enum APP_TYPE {
  ADMIN,
  ADVISOR,
  NEW_BUSINESS,
}

export enum envType {
  DEVELOP = 'develop',
  STAGING = 'staging',
  PRODUCTION = 'production',
}
export interface ENV {
  production: boolean;
  appType: APP_TYPE;
  apiUrl: string;
  apiEndpoints?: any;
  environmentType: envType;
  translationsUrl: string;
}
