import { FieldCategory } from '@iapplication2/interfaces';
import { FieldType } from './field-type.model';

export interface FormBuilderFieldGroupOptions {
  id?: number;
  name: string;
  type?: FieldType;
  category?: FieldCategory;
  predefinedGroupId?: number;
  [key: string]: any;
}
