import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FieldColumn,
  FieldTable,
  FieldType,
  FormBuilderFieldGroupOptions,
  FormBuilderFieldTypeType,
  FormBuilderGroup,
  ModalType,
  Severity,
  Themes,
} from '@iapplication2/interfaces';
import { CanvasService, DarkThemeService, InteractiveFormBuilderService } from '@iapplication2/services';
import { tableColumnDuplicationValidator } from '@iapplication2/validators';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-field-details-table',
  templateUrl: './field-details-table.component.html',
  styleUrls: ['./field-details-table.component.scss'],
  providers: [MessageService],
})
export class FieldDetailsTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isInEditMode: boolean;
  @Input() isInCreation: boolean;
  @Input() isInFieldBuilder = false;
  @Input() table: FieldTable;
  @Input() mainForm: FormGroup;

  @Output() invalidForm?: EventEmitter<boolean> = new EventEmitter<boolean>();

  modalTypeEnum = ModalType;
  isDarkTheme: boolean;

  columnTypes = [
    {
      name: 'Free Text',
      value: 'freeText',
    },
    {
      name: 'Checkbox',
      value: 'checkbox',
    },
    {
      name: 'Dropdown',
      value: 'dropdown',
    },
    {
      name: 'Date',
      value: 'date',
    },
    {
      name: 'Number',
      value: 'number',
    },
    {
      name: 'Radio group',
      value: 'radioGroup',
    },
    {
      name: 'Checkbox group',
      value: 'checkboxGroup',
    },
  ];

  tableColumnsItems: MenuItem[];
  predefinedTableColumnItems: MenuItem[];
  newColumnForm: FormGroup;
  columnInEditMode: FormGroup;
  formValidity = true;
  selectedColumnForLinking: FieldColumn;
  isColumnLinkingDialogOpen = false;
  columnToLinkWith: FieldColumn = undefined;

  localFieldGroup: FormBuilderGroup = {} as FormBuilderGroup;

  defaultItems: FieldType[];

  newOptionForm: FormGroup;
  newOptions: string[] = [];

  FormBuilderFieldTypeTypeEnum = FormBuilderFieldTypeType;

  private unsubscribe = new Subject<void>();

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private canvasService: CanvasService,
    private darkThemeService: DarkThemeService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.darkThemeService.themeChanged.subscribe((value) => {
      this.isDarkTheme = value === Themes.DARK;
    });
    this.sortColumnsById();
    this.createMainForm();
    this.createColumnForm();
    this.createColumnInEditModeForm();
    this.createColumnSettingsMenu();
    this.toggleColumnDeletionPossibility();
    this.createGroupOptionForm();
    this.createDefaultItemsSubscription();

    this.createColumnInFieldBuilderSettingsMenu();
  }

  sortColumnsById() {
    this.table.columns.sort((a, b) => b.id - a.id);
  }

  createDefaultItemsSubscription() {
    this.interactiveFormBuilderService.defaultItems
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((defaultItems: FieldType[]) => (this.defaultItems = defaultItems));
  }

  createMainForm(): void {
    this.mainForm.controls['tableForm'] = new FormGroup({
      tableName: new FormControl(null, [Validators.required, tableColumnDuplicationValidator(undefined, this.table)]),
      numberOfRows: new FormControl(null, [Validators.required]),
    });

    this.addValueChangeListeners();
  }

  addValueChangeListeners() {
    this.mainForm.controls['tableForm']
      .get('tableName')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((newName) => {
        this.table.name = newName;
      });

    this.mainForm.controls['tableForm']
      .get('numberOfRows')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((numberOfRows) => {
        this.table.numberOfRows = numberOfRows;
      });
  }

  createColumnForm(): void {
    this.newColumnForm = new FormGroup({
      name: new FormControl(null, [Validators.required, tableColumnDuplicationValidator(undefined, this.table)]),
      columnType: new FormControl(null, [Validators.required]),
      groupName: new FormControl(null, [Validators.required]),
    });
  }

  createGroupOptionForm(): void {
    this.newOptionForm = new FormGroup({
      label: new FormControl(null, [Validators.required]),
    });
  }

  drop(ev: any): void {
    const previousColumnPositionInTable = ev.previousIndex;
    const newColumnPositionInTable = ev.currentIndex;
    this.canvasService.moveColumnInTable(this.table, previousColumnPositionInTable, newColumnPositionInTable);
  }

  createColumnInEditModeForm(): void {
    this.columnInEditMode = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      columnType: new FormControl(null, [Validators.required]),
    });
  }

  addColumn(): void {
    const newId: number = Math.floor(Math.random() * 9000000000) + 1000000000;
    const newColumnName: string = this.newColumnForm.controls['name'].value;
    const newColumnFieldType: FieldType = this.getTypeFromLabel(this.newColumnForm.controls['columnType'].value);
    const newColumn: FieldColumn = {
      id: newId,
      name: newColumnName,
      type: newColumnFieldType,
      number: this.table?.columns?.length ? this.table.columns.length : 0,
      linkedWith: undefined,
    };
    if (
      newColumnFieldType.type === FormBuilderFieldTypeType.CHECKBOXGROUP ||
      newColumnFieldType.type === FormBuilderFieldTypeType.RADIOGROUP
    ) {
      const groupOptions: FormBuilderFieldGroupOptions = {
        name: newColumnName,
        id: newId,
      };
      newColumn.fields = this.newOptions;
      newColumn.groupOptions = groupOptions;
    }
    this.table.columns.push(newColumn);
    this.newOptions = [];
    this.resetNewColumnForm();
    this.toggleColumnDeletionPossibility();
  }

  getTypeFromLabel(type: FormBuilderFieldTypeType) {
    return this.defaultItems.find((defaultItem) => defaultItem.type === type);
  }

  addOption(): void {
    this.newOptions.push(this.newOptionForm.controls['label'].value);
    this.newOptionForm = new FormGroup({
      label: new FormControl(null, [Validators.required]),
    });
  }

  resetNewColumnForm(): void {
    this.newColumnForm.controls['name'].setValue('');
    this.newColumnForm.controls['columnType'].setValue(undefined);
  }

  numberOfRowsInputValueChanges(): void {
    this.formValidity = !this.mainForm.get('numberOfRows').errors;
  }

  newColumnFormInputValueChanged(): void {
    this.formValidity = !(
      this.newColumnForm.get('name').errors?.duplicateFieldAndGroupOptionLabel || this.newColumnForm.get('name').errors?.required
    );
  }

  toggleFieldBuilderColumnMenu(column: FieldColumn, menu: any, ev: any): void {
    const editMenuItem = this.predefinedTableColumnItems[0].items[0];

    if (column?.type?.type === FormBuilderFieldTypeType.NUMBER || column?.type?.type === FormBuilderFieldTypeType.RADIOGROUP) {
      this.predefinedTableColumnItems[0].items[2] = {
        label: 'Link column with',
        icon: 'pi pi-link',
      };
      const linkMenuItem = this.predefinedTableColumnItems[0].items[2];

      // Unlinking columns should be handled separately, as this should also unlink the other field from.
      // Temporary workaround is to disable linking of columns where there is already one linked.
      linkMenuItem.disabled = !!this.table.columns.find((column) => column.linkedWith);
      linkMenuItem.command = () => this.linkColumnWith(column);
    } else {
      if (this.predefinedTableColumnItems[0].items[2]) {
        this.predefinedTableColumnItems[0].items.pop();
      }
    }

    editMenuItem.command = () => this.editPredefinedColumn(column);

    menu.toggle(ev);
  }

  toggleColumnMenu(column: FieldColumn, menu: any, ev: any): void {
    const editMenuItem = this.tableColumnsItems[0].items[0];
    const deleteMenuItem = this.tableColumnsItems[0].items[1];

    if (column?.type?.type === FormBuilderFieldTypeType.NUMBER || column?.type?.type === FormBuilderFieldTypeType.RADIOGROUP) {
      this.tableColumnsItems[0].items[2] = {
        label: 'Link column with',
        icon: 'pi pi-link',
      };
      const linkMenuItem = this.tableColumnsItems[0].items[2];

      // Unlinking columns should be handled separately, as this should also unlink the other field from.
      // Temporary workaround is to disable linking of columns where there is already one linked.
      linkMenuItem.disabled = !!this.table.columns.find((column) => column.linkedWith);
      linkMenuItem.command = () => this.linkColumnWith(column);
    } else {
      if (this.tableColumnsItems[0].items[2]) {
        this.tableColumnsItems[0].items.pop();
      }
    }

    // Deleting linked columns should be handled separately, as this should also unlink the other field from the deleted one.
    // Temporary workaround is to disable deletion of linked columns.
    if (column.linkedWith) {
      deleteMenuItem.disabled = true;
    }

    editMenuItem.command = () => this.editColumn(column);
    deleteMenuItem.command = () => this.deleteColumn(column);
    menu.toggle(ev);
  }

  linkColumnWith(column: FieldColumn): void {
    this.selectedColumnForLinking = column;
    this.isColumnLinkingDialogOpen = true;
  }

  setSelectedColumnsForLinking(): void {
    this.isColumnLinkingDialogOpen = false;
    const tempColumnToLinkWith = _.cloneDeep(this.columnToLinkWith[0]);
    const tempSelectedColumnForLinking = _.cloneDeep(this.selectedColumnForLinking);
    this.selectedColumnForLinking.linkedWith = tempColumnToLinkWith;
    this.columnToLinkWith[0].linkedWith = tempSelectedColumnForLinking;
    this.interactiveFormBuilderService.updateFieldsFromTableWithChangedTable(this.table);

    //TODO: REFACTOR
    // this.canvasService.updateFieldsInTable(this.table);
  }

  private editColumn(column: FieldColumn): void {
    this.interactiveFormBuilderService.sidebarColumnSelected(column, this.table.id);
    this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.EDIT);
  }

  private editPredefinedColumn(column: FieldColumn): void {
    this.interactiveFormBuilderService.sidebarPredefinedColumnSelected(column, this.table.id);
    this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.EDIT);
  }

  private deleteColumn(column: FieldColumn): void {
    if (!this.isInCreation) {
      this.table.columns = this.table.columns.filter((tableColumn) => tableColumn.id !== column.id);
      // this.fieldManagementService
      //   .deleteTableColumnByIdWithResponse(column.id)
      //   .pipe(takeUntil(this.unsubscribe))
      //   .subscribe(() => {
      //     fieldsToBeDeleted.forEach((field) => {
      //       this.canvasService.deleteObjectById(field.id);
      //     });
      //     this.table.columns = this.table.columns.filter((tableColumn) => tableColumn !== column);
      //     this.toggleColumnDeletionPossibility();
      //   });
      this.messageService.add({
        key: 'tableSuccessfullyDeleted',
        severity: Severity.SUCCESS,
        summary: 'Success!',
        detail: 'The table column was successfully deleted',
      });
    } else {
      this.table.columns = this.table.columns.filter((tableColumn) => tableColumn !== column);
      this.toggleColumnDeletionPossibility();
    }
  }

  toggleColumnDeletionPossibility(): void {
    const numberOfColumnsInTable: number = this.table?.columns.length;
    this.tableColumnsItems[0].items[1].disabled = numberOfColumnsInTable <= 2;
  }

  private createColumnSettingsMenu(): void {
    this.tableColumnsItems = [
      {
        label: 'Column options',
        items: [
          {
            label: 'Edit column',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete column',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
  }

  private createColumnInFieldBuilderSettingsMenu(): void {
    this.predefinedTableColumnItems = [
      {
        label: 'Predefined column options',
        items: [
          {
            label: 'Edit predefined column',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete column',
            icon: 'pi pi-trash',
            disabled: true,
            tooltip: 'You cannot delete a column from a predefined table',
          },
        ],
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isInEditMode?.currentValue != changes.isInEditMode?.previousValue) {
      if (!this.isInCreation && this.isInEditMode) {
        this.mainForm.controls['tableForm']?.get('tableName').setValue(this.table.name);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
