import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldAttachedImage } from '@iapplication2/interfaces';
import { BaseFieldDataDirective } from 'libs/dynamic-form-display/src/lib/baseFieldData.directive';
import { FieldManagementService } from 'libs/services/src/lib/field-management/field-management.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { register } from '../../../../registryComponent';

@register('image')
@Component({
  selector: 'iapplication2-image-field-display',
  templateUrl: './image-field-display.component.html',
  styleUrls: ['./image-field-display.component.scss'],
})
export class ImageFieldDisplayComponent extends BaseFieldDataDirective implements OnInit, OnDestroy {
  constructor(private fieldManagementService: FieldManagementService) {
    super();
  }

  fieldAttachment: FieldAttachedImage;

  private unsubscribe = new Subject<void>();

  ngOnInit(): void {
    this.getFieldAttachments();
  }

  private getFieldAttachments(): void {
    this.fieldManagementService
      .getFieldAttachments(this.field.id.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: FieldAttachedImage) => {
          if (res) {
            this.fieldAttachment = res;
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
