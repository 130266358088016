import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ApplicationProcessPageComponent } from './application-process-page/application-process-page.component';
import { StepsModule } from 'primeng/steps';
import { CardModule } from 'primeng/card';
import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ProgressBarModule } from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FieldsetModule } from 'primeng/fieldset';
import { ClientSelectionComponent } from './application-process-sections/client-selection/client-selection.component';
import { FnaSelectionComponent } from './application-process-sections/client-selection/fna-selection/fna-selection.component';
import { SummaryComponent } from './application-process-sections/summary/summary.component';
import { ApplicationFormComponent } from './application-process-sections/application-form/application-form.component';
import { AccordionModule } from 'primeng/accordion';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { InputNumberModule } from 'primeng/inputnumber';

import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { FileUploadModule } from 'primeng/fileupload';
import { BadgeModule } from 'primeng/badge';
import { PipesModule } from '@iapplication2/pipes';
import { DynamicFormDisplayModule } from '@iapplication2/dynamic-form-display';
import { SummaryInfoComponent } from './application-process-sections/summary/sections/summary-info/summary-info.component';
import { ClientInfoComponent } from './application-process-sections/summary/sections/client-info/client-info.component';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ClientInformationModule } from '@iapplication2/client-management';
import { PdfPreviewModule } from './application-process-sections/summary/sections/pdf-preview/pdf-preview.module';
import { SummaryHealthComponent } from './application-process-sections/summary/sections/summary-health/summary-health.component';
import { FormFieldErrorModule } from '@iapplication2/ui';
import { RippleModule } from 'primeng/ripple';
import { ScheduleApplicationModalComponent } from './application-process-sections/summary/sections/schedule-application-modal/schedule-application-modal.component';
import { ApplicationPaymentPopupComponent } from './application-process-sections/application-payment-popup/application-payment-popup.component';
import { DynamicQuestionnaireSectionComponent } from './application-process-sections/client-selection/dynamic-questionnaire-section/dynamic-questionnaire-section.component';
import { ProductQuestionnaireComponent } from './application-process-sections/client-selection/dynamic-questionnaire-section/product-questionnaire/product-questionnaire.component';
import { ApplicationAttachmentsComponent } from './application-process-sections/summary/sections/application-attachments/application-attachments.component';
import { ProductsInformationComponent } from './application-process-sections/summary/sections/products-information/products-information.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageService } from 'primeng/api';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PdfViewerComponent } from './application-process-sections/summary/sections/pdf-viewer/pdf-viewer.component';
import { ProductSelectionComponent } from './application-process-sections/client-selection/product-selection/product-selection.component';
import { ProductSelectionCardComponent } from './application-process-sections/client-selection/product-selection-card/product-selection-card.component';
import { ApplicationClientInformationComponent } from './application-process-sections/client-selection/application-client-information/application-client-information.component';
import { ProgressSpinnerModule } from '@iapplication2/ui';
import { MessageModule } from 'primeng/message';
import { RequiredModule } from '@iapplication2/interface-design';
import { ProgressSpinnerModule as PrimeProgressSpinnerModule } from 'primeng/progressspinner';
import { DirectivesModule } from '@iapplication2/directives';
import { DateMaskModule } from '@iapplication2/ui';
import { ToastModule } from 'primeng/toast';
import { SkeletonModule } from 'primeng/skeleton';
import { ApplicationPaymentPopupPadComponent } from './application-process-sections/application-payment-popup/application-payment-popup-pad/application-payment-popup-pad.component';
import { ApplicationPaymentPopupCardComponent } from './application-process-sections/application-payment-popup/application-payment-popup-card/application-payment-popup-card.component';
import { StaticApplicationDisclosuresComponent } from './application-process-sections/client-selection/static-application-disclosures/static-application-disclosures.component';
import { StaticDisclosureComponent } from './application-process-sections/client-selection/static-application-disclosures/static-disclosure/static-disclosure.component';
import { ApplicationSignatureComponent } from './application-process-sections/summary/sections/esignature-info/application-signature-info.component';

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin, interactionPlugin]);
@NgModule({
  imports: [
    CommonModule,
    StepsModule,
    CardModule,
    CalendarModule,
    FlexLayoutModule,
    TabViewModule,
    ConfirmPopupModule,
    ClientInformationModule,
    TagModule,
    PaginatorModule,
    PdfJsViewerModule,
    FileUploadModule,
    DropdownModule,
    ReactiveFormsModule,
    FullCalendarModule,
    CalendarModule,
    StepsModule,
    FormsModule,
    BadgeModule,
    TableModule,
    CheckboxModule,
    ButtonModule,
    PipesModule,
    RadioButtonModule,
    FieldsetModule,
    TabViewModule,
    DialogModule,
    ProgressBarModule,
    FormFieldErrorModule,
    ProgressSpinnerModule,
    DynamicFormDisplayModule,
    AccordionModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    InputMaskModule,
    FileUploadModule,
    PdfPreviewModule,
    SharedTranslateModule,
    MessageModule,
    RequiredModule,
    PrimeProgressSpinnerModule,
    RouterModule.forChild([
      {
        path: 'edit/:id',
        component: ApplicationProcessPageComponent,
      },
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ]),
    RippleModule,
    InputTextareaModule,
    DirectivesModule,
    DateMaskModule,
    ToastModule,
    SkeletonModule,
  ],
  declarations: [
    ApplicationProcessPageComponent,
    ClientSelectionComponent,
    FnaSelectionComponent,
    SummaryComponent,
    ApplicationFormComponent,
    ApplicationAttachmentsComponent,
    SummaryInfoComponent,
    ProductsInformationComponent,
    ClientInfoComponent,
    ScheduleApplicationModalComponent,
    ApplicationPaymentPopupComponent,
    SummaryHealthComponent,
    DynamicQuestionnaireSectionComponent,
    ProductQuestionnaireComponent,
    PdfViewerComponent,
    ProductSelectionComponent,
    ProductSelectionCardComponent,
    ApplicationClientInformationComponent,
    ApplicationPaymentPopupPadComponent,
    ApplicationPaymentPopupCardComponent,
    StaticApplicationDisclosuresComponent,
    StaticDisclosureComponent,
    ApplicationSignatureComponent,
  ],
  providers: [DatePipe, MessageService],
  exports: [ScheduleApplicationModalComponent, ApplicationFormComponent],
})
export class ApplicationProcessModule {}
