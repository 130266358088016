import { Component, OnInit } from '@angular/core';
import { register } from '../../../../registryComponent';
import { BaseGroupDataDirective } from '../../../../baseGroupData.directive';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderFieldTypeOption, ValidatorTypeEnum } from '@iapplication2/interfaces';
import { ApplicationsProcessService } from '@iapplication2/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@register('checkboxgroup')
@Component({
  selector: 'iapplication2-checkbox-group-display',
  templateUrl: './checkbox-group-display.component.html',
  styleUrls: ['./checkbox-group-display.component.scss'],
})
export class CheckboxGroupDisplayComponent extends BaseGroupDataDirective implements OnInit {
  unsubscribe: Subject<unknown> = new Subject();
  flexRow = false;
  tabIndex = null;
  isViewApplication: boolean;

  constructor(private applicationsProcessService: ApplicationsProcessService) {
    super();
  }

  ngOnInit() {
    this.checkViewApplicationDisplay();
    this.checkValidators();
    this.setBehaviourBasedOnFieldOptions();
    if (this.isReadonly) {
      this.tabIndex = -1;
    }
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  setBehaviourBasedOnFieldOptions() {
    switch (true) {
      case this.group.fields?.[0]?.fieldTypeOptions?.findIndex((option: FormBuilderFieldTypeOption) => option.id === 11) !== -1:
        this.flexRow = true;
    }
  }

  checkValidators() {
    const groupValidatorName = this.group?.fields?.[0]?.fieldValidators?.manualValidators?.[0]?.validatorKey;
    if (groupValidatorName && groupValidatorName === ValidatorTypeEnum.REQUIRED && !this.isViewApplication) {
      this.formControl.setValidators([Validators.required]);
    }
  }

  get formControl() {
    return <FormControl>this.parentFormGroup?.controls[this.group?.groupOptions?.id];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
