import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { ApplicationTypeComponent } from './application-type.component';

@NgModule({
  declarations: [ApplicationTypeComponent],
  imports: [CommonModule, TagModule],
  exports: [ApplicationTypeComponent],
})
export class ApplicationTypeModule {}
