<div fxFlex fxLayout="column">
  <div class="page-header flex align-items-center justify-content-between mt-3">
    <h1 class="page-header-title">{{ 'applicationManagement.notes' | translate }}</h1>
  </div>
  <div class="card" fxFlex fxLayout="column">
    <div fxLayout="row">
      <textarea fxFlex="50" pInputTextarea *ngIf="application?.notes" [(ngModel)]="application.notes" [rows]="5" readonly></textarea>
    </div>
  </div>
</div>
