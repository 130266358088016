import { Component, Input, OnDestroy } from '@angular/core';
import { Notification } from '@iapplication2/interfaces';
import { NotificationsService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnDestroy {
  @Input() notification: Notification;
  unsubscribe: Subject<unknown> = new Subject();

  constructor(private notificationsService: NotificationsService) {}

  readNotification() {
    if (!this.notification.isRead) {
      this.notificationsService.setNotificationAsRead(this.notification).pipe(takeUntil(this.unsubscribe)).subscribe();
    }
  }

  dismissNotification() {
    // this.notificationsService
    //   .deleteNotification(this.notification.id)
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
