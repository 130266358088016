<div *ngFor="let item of filesArray" class="mt-5">
  <h3 (click)="loadPdf(item)" ngClass="{{ item.product?.iappProduct?.productFormId === selectedProduct ? 'underline' : '' }}">
    <a>
      {{ item.product?.name }}
    </a>
    <a class="ml-2" [href]="item?.url" target="_blank">
      <button pButton pRipple type="button" [label]="'applicationProcess.step.summary.pdfPreview.downloadPDF' | translate" class="p-button-secondary"></button>
    </a>
  </h3>
</div>
<div style="height: 1200px">
  <p-skeleton width="100%" height="100%" *ngIf="loadingPdf"></p-skeleton>
  <ng2-pdfjs-viewer #pdfViewer></ng2-pdfjs-viewer>
</div>
