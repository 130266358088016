<img src="https://iapplication.ca/images/cheque.gif" class="mb-3" />
<form [formGroup]="paymentTypeForm.controls['PADForm'] | cast: formGroupType">
  <div fxLayout="row">
    <div fxLayout="column" class="pr-3 mb-2" fxFlex>
      <label class="pb-1" for="transitNumber">{{ 'applicationProcess.step.applicationForms.payment.PAD.transitNumber' | translate }}</label>
      <p-inputMask id="transitNumber" formControlName="transitNumber" placeholder="00000" mask="99999" [autoClear]="false"></p-inputMask>
      <iapplication2-form-field-error
        *ngIf="paymentTypeForm.controls['PADForm'].controls['transitNumber'].touched"
        [control]="paymentTypeForm.controls['PADForm'].controls['transitNumber']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>
    <div fxLayout="column" class="pr-3 mb-2" fxFlex>
      <label class="pb-1" for="financialNumber">{{
        'applicationProcess.step.applicationForms.payment.PAD.financialNumber' | translate
      }}</label>
      <p-inputMask id="financialNumber" formControlName="financialNumber" placeholder="000" mask="999" [autoClear]="false"></p-inputMask>
      <iapplication2-form-field-error
        *ngIf="paymentTypeForm.controls['PADForm'].controls['financialNumber'].touched"
        [control]="paymentTypeForm.controls['PADForm'].controls['financialNumber']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>
  </div>
  <div fxLayout="column" class="pr-3" fxFlex>
    <label class="pb-1" for="accountNumber">{{ 'applicationProcess.step.applicationForms.payment.PAD.accountNumber' | translate }}</label>
    <p-inputMask
      id="accountNumber"
      formControlName="accountNumber"
      placeholder="000000 / 000000000000"
      mask="999999?999999"
      [autoClear]="false"
      [style]="{ width: '100%' }"
    ></p-inputMask>
    <iapplication2-form-field-error
      *ngIf="paymentTypeForm.controls['PADForm'].controls['accountNumber'].touched"
      [control]="paymentTypeForm.controls['PADForm'].controls['accountNumber']"
      style="bottom: -8px"
    ></iapplication2-form-field-error>
  </div>
</form>
