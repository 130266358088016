import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ApplicationFormPdfResponseType, Product } from '@iapplication2/interfaces';
import { ApplicationsProcessService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  @Input() applicationId: number;
  @Input() productList;
  @Output() loadingComplete: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('pdfViewer') public pdfViewer;
  selectedLanguage;
  filesArray: { product: Product; file: any; url: any }[] = [];
  selectedProduct: number;
  loadingPdf = true;

  unsubscribe: Subject<unknown> = new Subject();

  constructor(private applicationsProcessService: ApplicationsProcessService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.applicationsProcessService.pdfGenerationCompleted.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe({
      next: (res: boolean) => {
        if (res) {
          this.getApplicationFormPdfs();
        }
      },
    });
  }

  getApplicationFormPdfs() {
    if (!this.filesArray?.length) {
      this.applicationsProcessService
        .getApplicationFormsPdf(this.applicationId, ApplicationFormPdfResponseType.ALL)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (res: any[]) => {
            // TODO: Refactor bellow to use the language on app/interface
            res.forEach((pdfDataForProductForm) => {
              const fileAlreadyExists = this.filesArray.find(
                (existingFile) => existingFile.product?.iappProduct?.productFormId === pdfDataForProductForm.en.productFormId
              );
              if (!fileAlreadyExists) {
                this.filesArray.push({
                  product: this.productList.find(
                    (product: Product) => product.iappProduct.productFormId === pdfDataForProductForm.en.productFormId
                  ),
                  file: pdfDataForProductForm.en.object,
                  url: pdfDataForProductForm.en.link,
                });
              }
              this.filesArray.sort((file1, file2) => file1?.product?.iappProduct?.position - file2?.product?.iappProduct?.positio);
            });

            this.loadPdf(this.filesArray[0]);
          },
          complete: () => {
            this.loadingComplete.next(true);
          }
        });
    }
  }

  loadPdf(item) {
    this.selectedProduct = item?.product?.iappProduct?.productFormId;
    if (this.selectedProduct) {
      this.loadingPdf = false;
      this.pdfViewer.pdfSrc = new Uint8Array(item.file?.Body?.data);
      this.pdfViewer.refresh();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
