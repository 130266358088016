/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { KeyboardEventsConstants } from '@iapplication2/constants';
import {
  ENV,
  FieldColumn,
  FieldTable,
  FieldType,
  FormBuilderField,
  FormBuilderFieldOptions,
  FormBuilderFieldTypeType,
  FormBuilderGroup,
  FormListDisplay,
  Page,
} from '@iapplication2/interfaces';
import { fabric } from 'fabric';
import { Rect } from 'fabric/fabric-impl';
import * as _ from 'lodash';
import { concat, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { FieldManagementService } from '../field-management/field-management.service';
import { ProgressSpinnerService } from '../progress-spinner/progress-spinner.service';

export interface origin {
  origX: number;
  origY: number;
}

interface size {
  width: number;
  height: number;
}

export class CustomRect extends fabric.Rect {
  // customProps: FormBuilderField;
  id: number;
}

export class CustomObject extends fabric.Object {
  customProps: FormBuilderField;
  id: number;
  controls;
}

export interface CustomFieldAndRect {
  rect: CustomObject;
  field: FormBuilderField;
}

@Injectable({
  providedIn: 'root',
})
export class CanvasService {
  private readonly size: size = {
    width: 820,
    height: 1061,
  };
  private readonly constantRectangleSizeForClickOnlyObjects: number = 12;
  public canvasInDrawingMode: boolean = false;
  selectedCustomFieldForDrawing: FormBuilderField;
  currentlyDrawnRectangle: fabric.Rect;
  private isMousePressed: boolean = false;
  origin: origin;
  private activeCanvasObject: CustomObject;
  private activeCanvasObjects: CustomObject[];
  _clipboard: CustomObject;
  private _isDrawingFromWizard: boolean = false;
  public get isDrawingFromWizard(): boolean {
    return this._isDrawingFromWizard;
  }
  public set isDrawingFromWizard(value: boolean) {
    this._isDrawingFromWizard = value;
  }

  canvas: fabric.Canvas;
  canvasObjectWasSelected: ReplaySubject<FormBuilderField> = new ReplaySubject<FormBuilderField>(1);
  canvasFieldIsInEditMode: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  canvasGroupIsInEditMode: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  userDrawing: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  currentlyDrawingFieldFromGroup: Subject<FormBuilderField> = new Subject<FormBuilderField>();
  currentlyDrawingFieldFromTable: Subject<FormBuilderField> = new Subject<FormBuilderField>();

  fieldsToDrawFromGroup: FormBuilderField[] = [];
  tableToDraw: FieldTable;
  rowsToDrawFromTable: number;
  currentlyDrawingFieldNumber: number;
  currentlyDrawingFieldFromTableGroupNumber: number;

  fieldsQueuedForDeletionFromGroupOnSave: FormBuilderField[] = [];
  numberOfFieldsInTheQueueForDeletionFromGroupOnSave: ReplaySubject<number> = new ReplaySubject<number>(1);
  updatedField: Subject<FormBuilderField> = new Subject<FormBuilderField>();
  informWizardOfFinishedDrawing: Subject<boolean> = new Subject<boolean>();

  formId: number;

  private _listOfAllFormFields: FormBuilderField[] = [];
  public get listOfAllFormFields(): FormBuilderField[] {
    return this._listOfAllFormFields;
  }
  public set listOfAllFormFields(value: FormBuilderField[]) {
    this._listOfAllFormFields = value;
  }

  private _currentPage: Page;
  public get currentPage(): Page {
    return this._currentPage;
  }
  public set currentPage(value: Page) {
    this._currentPage = value;
  }

  _currentCanvasForm: FormListDisplay;

  public get currentCanvasForm(): FormListDisplay {
    return this._currentCanvasForm;
  }
  public set currentCanvasForm(value: FormListDisplay) {
    this._currentCanvasForm = value;
  }

  saveCanvasDataDebounced = _.debounce(() => this.saveCanvasData(), 500);
  getAllFormFieldsDebounced = _.debounce(() => this.getAllFormFields(this.formId), 500);

  fieldsToBeCreatedFromTable: FormBuilderField[] = [];
  rectsAndFieldsToBeCreatedFromTable: CustomFieldAndRect[] = [];

  fieldsToBeCreatedFromDisclosure: FormBuilderField[] = [];
  rectsAndFieldsToBeCreatedFromDisclosure: CustomFieldAndRect[] = [];

  constructor(
    @Inject(APP_CONFIG) private appConfig: ENV,
    private http: HttpClient,
    private fieldManagementService: FieldManagementService,
    private progressSpinnerService: ProgressSpinnerService
  ) {}

  getFieldById(fieldId: number): FormBuilderField {
    this.setFormId(this.listOfAllFormFields?.[0]?.formId);
    let field = this.listOfAllFormFields.find((field) => field.id === fieldId);
    if (!field) {
      (this.getAllFormFields(this.formId, false) as Observable<FormBuilderField[]>).subscribe((res: FormBuilderField[]) => {
        this.listOfAllFormFields = res;
        field = this.listOfAllFormFields.find((field) => field.id === fieldId);
        // TODO: Decide what to do when the selected field in the canvas does not exist in the DB
      });
    }
    return field;
  }

  setFormId(formId: number) {
    if (!this.formId) {
      this.formId = formId;
    }
  }

  getFieldsByGroupId(groupId: number): FormBuilderField[] {
    return this.listOfAllFormFields.filter((field) => field.groupOptions?.id === groupId);
  }

  getFieldsFromColumnFromTable(columnId: number, tableId: number): FormBuilderField[] {
    return this.listOfAllFormFields.filter(
      (field) => tableId && field.table?.type?.id === tableId && columnId && field.table?.position?.column?.id === columnId
    );
  }

  getFieldsFromTable(tableId: number): FormBuilderField[] {
    return this.listOfAllFormFields.filter((field) => tableId && field.table?.type?.id === tableId);
  }

  fieldSelected(field: FormBuilderField) {
    this.canvas?.discardActiveObject();
    this.canvasInDrawingMode = true;
    this.selectedCustomFieldForDrawing = field;
    this.makeAllObjectsUnselectable();
  }

  fieldsFromGroupSelected(fields: FormBuilderField[]) {
    this.fieldsToDrawFromGroup = fields;
    this.currentlyDrawingFieldNumber = 0;
    this.drawFieldsFromSelectedGroup();
  }

  fieldsFromGroupInTableSelected(fields: FormBuilderField[]) {
    this.fieldsToDrawFromGroup = fields;
    this.currentlyDrawingFieldFromTableGroupNumber = 0;
    this.drawFieldsFromSelectedGroupFromTable();
  }

  drawFieldsFromSelectedGroupFromTable() {
    this.canvas?.discardActiveObject();
    this.canvasInDrawingMode = true;
    this.makeAllObjectsUnselectable();
    this.selectedCustomFieldForDrawing = this.fieldsToDrawFromGroup[this.currentlyDrawingFieldFromTableGroupNumber];

    this.currentlyDrawingFieldFromGroup.next(this.selectedCustomFieldForDrawing);
    this.currentlyDrawingFieldFromTableGroupNumber++;
    if (this.fieldsToDrawFromGroup?.length === this.currentlyDrawingFieldFromTableGroupNumber) {
      this.currentlyDrawingFieldNumber++;
    }
  }

  private setActiveFieldToSelectedObject() {
    this.activeCanvasObject = this.canvas.getActiveObject() as CustomObject;
    if (this.activeCanvasObject) {
      this.activeCanvasObject.customProps = this.getFieldById(this.activeCanvasObject.id);
      this.canvasObjectWasSelected.next(this.activeCanvasObject.customProps);
    }
  }

  newFieldFromGroupAdded(field: FormBuilderField) {
    this.fieldsToDrawFromGroup = [];
    this.fieldsToDrawFromGroup.push(field);
    this.currentlyDrawingFieldNumber = 0;
    this.drawFieldsFromSelectedGroup();
  }

  drawFieldsFromSelectedGroup() {
    this.canvas?.discardActiveObject();
    this.canvasInDrawingMode = true;
    this.makeAllObjectsUnselectable();
    this.selectedCustomFieldForDrawing = this.fieldsToDrawFromGroup[this.currentlyDrawingFieldNumber];
    this.currentlyDrawingFieldFromGroup.next(this.selectedCustomFieldForDrawing);
    this.currentlyDrawingFieldNumber++;
  }

  drawTable(table: FieldTable) {
    this.currentlyDrawingFieldNumber = 0;
    this.tableToDraw = table;
    this.rowsToDrawFromTable = table.numberOfRows;
    this.drawFieldsFromColumnInTable();
  }

  createFieldToBeDrawnBasedOnType(): FormBuilderField | FormBuilderField[] {
    if (this.tableToDraw?.fields && this.tableToDraw?.fields[this.currentlyDrawingFieldNumber]) {
      return this.tableToDraw.fields[this.currentlyDrawingFieldNumber];
    } else {
      const newFieldType: FieldType =
        this.tableToDraw.columns[Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable)].type;
      if (newFieldType.type === FormBuilderFieldTypeType.CHECKBOXGROUP || newFieldType.type === FormBuilderFieldTypeType.RADIOGROUP) {
        return this.createFieldsInGroupToBeDrawn();
      } else {
        return this.createFieldToBeDrawn();
      }
    }
  }

  createFieldToBeDrawn(): FormBuilderField {
    const fieldName: string = this.tableToDraw.columns[Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable)].name;
    const newFieldType: FieldType = this.tableToDraw.columns[Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable)].type;
    const fieldRow: number = this.currentlyDrawingFieldNumber % this.rowsToDrawFromTable;
    const column = {
      number: Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable),
      id: this.tableToDraw.columns[Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable)].id,
    };
    return this.createFieldForTableWithType(fieldName, newFieldType, this.tableToDraw, fieldRow, column);
  }

  createFieldsInGroupToBeDrawn(): FormBuilderField[] {
    const groupToBeDrawn = this.tableToDraw.columns[Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable)];

    groupToBeDrawn.groupOptions.type = groupToBeDrawn.type;

    const newGroupId: number = Math.floor(Math.random() * 9000000000) + 1000000000;
    groupToBeDrawn.groupOptions.id = newGroupId;
    const fields: FormBuilderField[] = [];

    for (let i = 0; i < groupToBeDrawn.fields.length; i++) {
      const fieldName: string = this.tableToDraw.columns[Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable)].fields[i];
      const newFieldType: FieldType =
        this.tableToDraw.columns[Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable)].type;
      const fieldRow: number = this.currentlyDrawingFieldNumber % this.rowsToDrawFromTable;
      const column = {
        number: Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable),
        id: this.tableToDraw.columns[Math.floor(this.currentlyDrawingFieldNumber / this.rowsToDrawFromTable)].id,
      };

      const newField = this.createFieldForTableWithType(fieldName, newFieldType, this.tableToDraw, fieldRow, column);
      newField.groupOptions = _.cloneDeep(groupToBeDrawn.groupOptions);
      fields.push(newField);
    }

    return fields;
  }

  drawFieldsFromColumnInTable() {
    this.canvas?.discardActiveObject();
    this.canvasInDrawingMode = true;
    this.makeAllObjectsUnselectable();
    const field: FormBuilderField | FormBuilderField[] = this.createFieldToBeDrawnBasedOnType();

    if (field.length) {
      this.fieldsFromGroupInTableSelected(field as FormBuilderField[]);
    } else {
      this.selectedCustomFieldForDrawing = field as FormBuilderField;

      this.currentlyDrawingFieldFromTable.next(this.selectedCustomFieldForDrawing);
      this.currentlyDrawingFieldNumber++;
    }
  }

  createFieldForTableWithType(fieldName: string, type: FieldType, table: FieldTable, fieldRow: number, column: any): FormBuilderField {
    const fieldOptions: FormBuilderFieldOptions = {
      customFieldName: _.camelCase(fieldName),
      customFieldLabel: fieldName,
      visibleOnPdf: true,
    };
    const newField: FormBuilderField = {
      options: fieldOptions,
      fieldValidators: {
        predefinedValidators: [],
        manualValidators: [],
      },
      fieldType: type,
      table: {
        type: table,
        position: {
          row: fieldRow,
          column: {
            number: column.number,
            id: column.id,
          },
        },
      },
    };
    return newField;
  }

  makeAllObjectsUnselectable() {
    this.canvas?.forEachObject((object) => {
      object.selectable = false;
    });
  }

  makeAllObjectsSelectable() {
    this.canvas?.forEachObject((object) => {
      object.selectable = true;
    });
  }

  // TODO: Merge higlighting calls into one

  hoveredOverFormBuilderField(field: FormBuilderField) {
    if (this.canvas && field.id) {
      this.canvas.forEachObject((obj) => {
        if ((obj as CustomObject).id === field.id) {
          const newColor = field.fieldType.rgbaColorForRectangle
            .substring(0, field.fieldType.rgbaColorForRectangle.length - 4)
            .concat('1)');
          obj.set('fill', newColor);
          this.canvas.requestRenderAll();
        }
      });
    }
  }

  leftHoverOverFormBuilderField(field: FormBuilderField) {
    if (this.canvas) {
      this.canvas.forEachObject((obj) => {
        if ((obj as CustomObject).id === field.id) {
          obj.set('fill', field.fieldType.rgbaColorForRectangle);
          this.canvas.requestRenderAll();
        }
      });
    }
  }

  hoveredOverFormBuilderGroup(group: FormBuilderGroup) {
    if (this.canvas && group.fields?.length) {
      this.canvas.forEachObject((obj: CustomObject) => {
        if (group.fields.some((groupField) => groupField.id === obj.id)) {
          const newColor = group.groupOptions.type.rgbaColorForRectangle
            .substring(0, group.groupOptions.type.rgbaColorForRectangle.length - 4)
            .concat('1)');
          obj.set('fill', newColor);
          this.canvas.requestRenderAll();
        }
      });
    }
  }

  leftHoverOverFormBuilderGroup(group: FormBuilderGroup) {
    if (this.canvas) {
      this.canvas.forEachObject((obj: CustomObject) => {
        if (group.fields.some((groupField) => groupField.id === obj.id)) {
          obj.set('fill', group.groupOptions.type.rgbaColorForRectangle);
          this.canvas.requestRenderAll();
        }
      });
    }
  }

  hoveredOverFormBuilderTable(table: FieldTable) {
    const fieldsInTable: FormBuilderField[] = this.getFieldsFromTable(table.id);
    if (this.canvas && table.columns?.length) {
      this.canvas.forEachObject((obj: CustomObject) => {
        fieldsInTable.find((tableField: FormBuilderField) => {
          if (tableField.id === obj.id) {
            const newColor = tableField.fieldType.rgbaColorForRectangle
              .substring(0, tableField.fieldType.rgbaColorForRectangle.length - 4)
              .concat('1)');
            obj.set('fill', newColor);
            this.canvas.requestRenderAll();
          }
        });
      });
    }
  }

  leftHoverOverFormBuilderTable(table: FieldTable) {
    const fieldsInTable: FormBuilderField[] = this.getFieldsFromTable(table.id);
    if (this.canvas && table.columns?.length) {
      this.canvas.forEachObject((obj: CustomObject) => {
        fieldsInTable.find((tableField: FormBuilderField) => {
          if (tableField.id === obj.id) {
            obj.set('fill', tableField.fieldType.rgbaColorForRectangle);
            this.canvas.requestRenderAll();
          }
        });
      });
    }
  }

  clickedOnFormBuilderField(field: FormBuilderField) {
    if (this.canvas) {
      this.canvas.forEachObject((obj) => {
        if ((obj as CustomObject).id === field.id) {
          this.canvas.setActiveObject(obj);
          this.canvas.requestRenderAll();
        }
      });
    }
  }

  deselectSelectedCanvasField() {
    this.canvas?.discardActiveObject();
    this.canvas?.requestRenderAll();
  }

  deleteSelectedObject() {
    this.canvas.remove(this.canvas.getActiveObject());
    this.saveCanvasDataDebounced();
    this.canvas?.requestRenderAll();
  }

  deleteObjectById(objectId: number) {
    this.canvas.remove(this.canvas.getObjects().filter((object) => (object as CustomObject).id === objectId)[0]);
    this.getAllFormFieldsDebounced();
    this.saveCanvasDataDebounced();
    this.canvas?.requestRenderAll();
  }

  public updateFieldInListOfFields(field: FormBuilderField): void {
    const fieldInList = this.listOfAllFormFields.find((fieldInList) => fieldInList.id === field.id);

    Object.keys(field).forEach((key) => {
      fieldInList[key] = field[key];
    });
  }

  public updateFieldsInColumnByIdWithNewFieldValues(fieldId: number, field: FormBuilderField) {
    const fieldInList = this.listOfAllFormFields.find((fieldInList) => fieldInList.id === fieldId);

    Object.keys(field).forEach((key) => {
      if (key !== 'id' && key !== 'table') {
        fieldInList[key] = field[key];
      }
    });
  }

  public updateFieldsTableInListOfFields(tableType: FieldTable) {
    if (tableType?.id) {
      this.listOfAllFormFields.forEach((fieldFromTableInList) => {
        if (tableType?.id && fieldFromTableInList.table?.type?.id === tableType?.id) {
          fieldFromTableInList.table.type = tableType;
        }
      });
    }
  }

  buildInitialCanvas(canvas: fabric.Canvas, htmlCanvas, canvasImage: string): fabric.Canvas {
    canvas = new fabric.Canvas(htmlCanvas.nativeElement, {
      hoverCursor: 'pointer',
      selectionBorderColor: 'blue',
      uniformScaling: false,
    });
    canvas = this.setCanvasSize(canvas);
    canvas = this.setCanvasSelection(canvas, true);
    canvas = this.setCanvasImage(canvas, canvasImage);
    this.setInitialCanvasObjectsProperties();
    return canvas;
  }

  onCanvasSelectionCreated() {
    this.canvas.renderAll();

    switch (true) {
      case this.canvas.getActiveObjects().length === 1:
        this.setActiveFieldToSelectedObject();
        break;
      case this.canvas.getActiveObjects().length > 1:
        this.canvas.setActiveObject((this.canvas.getActiveObjects() as CustomObject[])[0]);
        this.setActiveFieldToSelectedObject();
        break;
    }
  }

  onCanvasSelectionUpdated() {
    this.canvas.renderAll();
    if (this.canvas.getActiveObject()) {
      this.setActiveFieldToSelectedObject();
    }
    this.canvasFieldIsInEditMode.next(undefined);
    this.canvasGroupIsInEditMode.next(undefined);
  }

  onCanvasSelectionCleared() {
    this.activeCanvasObject = undefined;
    this.canvasObjectWasSelected.next(undefined);
    this.canvasFieldIsInEditMode.next(undefined);
    this.canvasGroupIsInEditMode.next(undefined);
  }

  objectModified() {
    this.saveCanvasDataDebounced();
  }

  onCanvasMouseDown(canvas: fabric.Canvas, o: fabric.IEvent) {
    this.canvas = canvas;
    if (this.canvasInDrawingMode) {
      this.isMousePressed = true;
      canvas = this.setCanvasSelection(canvas, false);
      this.origin = this.getOriginFromPointer(o);
      this.drawInitialRectangle();
    }
  }

  onCanvasMouseMove(canvas: fabric.Canvas, o: fabric.IEvent) {
    this.canvas = canvas;

    if (this.canvasInDrawingMode && this.selectedCustomFieldForDrawing?.fieldType?.dragToDraw && this.isMousePressed) {
      this.drawRectangleWhileMouseIsMoving(o);
      canvas.renderAll();
    }
  }

  keepObjectInCanvas() {
    const activeObject = this.canvas.getActiveObject();

    if (activeObject?.get('width') > activeObject?.canvas?.height || activeObject?.get('width') > activeObject?.canvas?.width) {
      return;
    }
    activeObject.setCoords();

    if (activeObject.getBoundingRect().top < 0 || activeObject.getBoundingRect().left < 0) {
      activeObject.top = Math.max(activeObject.top, activeObject.top - activeObject.getBoundingRect().top);
      activeObject.left = Math.max(activeObject.left, activeObject.left - activeObject.getBoundingRect().left);
    }

    if (
      activeObject.getBoundingRect().top + activeObject.getBoundingRect().height > activeObject.canvas.height ||
      activeObject.getBoundingRect().left + activeObject.getBoundingRect().width > activeObject.canvas.width
    ) {
      activeObject.top = Math.min(
        activeObject.top,
        activeObject.canvas.height - activeObject.getBoundingRect().height + activeObject.top - activeObject.getBoundingRect().top
      );
      activeObject.left = Math.min(
        activeObject.left,
        activeObject.canvas.width - activeObject.getBoundingRect().width + activeObject.left - activeObject.getBoundingRect().left
      );
    }
  }

  onCanvasMouseUp(canvas: fabric.Canvas) {
    this.canvas = canvas;
    if (this.canvasInDrawingMode) {
      this.isMousePressed = false;
      if (this.isRectangleTooSmall()) {
        this.drawDefaultSizedRectangle(this.currentlyDrawnRectangle, canvas);
      }
      this.isDrawingFromWizard = false;
      canvas = this.setCanvasSelection(canvas, true);
      this.selectedCustomFieldForDrawing = undefined;

      // Check to see if currently drawing a field directly from a group, directly from a table, or from a table from a group

      const conditionToDrawFieldsFromAGroup =
        this.fieldsToDrawFromGroup &&
        !this.tableToDraw?.columns &&
        this.fieldsToDrawFromGroup?.length > this.currentlyDrawingFieldNumber &&
        this.fieldsToDrawFromGroup.every((field) => field.fieldType.type !== FormBuilderFieldTypeType.DISCLOSURE);
      const conditionsToDrawFromATable =
        this.tableToDraw?.fields?.length > this.currentlyDrawingFieldNumber ||
        (this.tableToDraw?.columns && this.tableToDraw?.columns?.length * this.rowsToDrawFromTable > this.currentlyDrawingFieldNumber);

      const conditionsToDrawFieldsFromAGroupFromATable =
        this.fieldsToDrawFromGroup && this.fieldsToDrawFromGroup?.length > this.currentlyDrawingFieldFromTableGroupNumber;

      const conditionsToDrawFromADisclosureGroup =
        this.fieldsToDrawFromGroup?.length > this.currentlyDrawingFieldNumber &&
        this.fieldsToDrawFromGroup.every((field) => field.fieldType.type === FormBuilderFieldTypeType.DISCLOSURE);

      // Drawing fields from a selected group
      if (conditionToDrawFieldsFromAGroup) {
        this.drawFieldsFromSelectedGroup();
      }

      // Drawing drom a table
      if (conditionsToDrawFromATable) {
        // Drawing fields from a group in a table
        if (conditionsToDrawFieldsFromAGroupFromATable) {
          this.drawFieldsFromSelectedGroupFromTable();
        } else {
          // Drawing fields from a table
          this.drawFieldsFromColumnInTable();
        }
      }
      if (!conditionToDrawFieldsFromAGroup && !conditionsToDrawFromATable && !this.rectsAndFieldsToBeCreatedFromDisclosure.length) {
        this.endDrawing();

        if (this.rectsAndFieldsToBeCreatedFromTable.length) {
          this.createFieldsInTable();
        }
      }

      if (conditionsToDrawFromADisclosureGroup) {
        this.drawFieldsFromSelectedGroup();
      }

      if (!conditionsToDrawFromADisclosureGroup && this.rectsAndFieldsToBeCreatedFromDisclosure.length) {
        this.endDrawing();

        this.createFieldsInDisclosure();
      }

      this.informWizardOfFinishedDrawing.next(true);
    }

    this.makeAllObjectsSelectable();
  }

  private endDrawing() {
    this.canvasInDrawingMode = false;
    this.userDrawing.next(false);
    this.currentlyDrawingFieldFromGroup.next(null);
    this.currentlyDrawingFieldFromTable.next(null);
    this.tableToDraw = undefined;
    this.fieldsToDrawFromGroup = [];
  }

  private createFieldsInTable() {
    delete this.rectsAndFieldsToBeCreatedFromTable[0].field?.table?.type?.id;

    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.fieldManagementService
      .createField(this.rectsAndFieldsToBeCreatedFromTable[0].field)
      .pipe(
        tap((res: FormBuilderField) => {
          this.rectsAndFieldsToBeCreatedFromTable[0].field.id = res.id;
          this.rectsAndFieldsToBeCreatedFromTable[0].field.table.type.id = res.table.type.id;
          this.rectsAndFieldsToBeCreatedFromTable[0].rect.id = res.id;
        }),
        concatMap((res: FormBuilderField) => {
          const obs$ = [];

          this.rectsAndFieldsToBeCreatedFromTable.forEach((item, index) => {
            if (index > 0) {
              item.field.table.type.id = res.table.type.id;
              // item.field.index = index;
              obs$.push(this.fieldManagementService.createField(item.field));
            }
          });

          return of(obs$);
        })
      )
      .subscribe({
        next: (result: []) => {
          const processedObservable = concat(...result);

          processedObservable.subscribe({
            next: (res: FormBuilderField) => {
              const indexOfFieldRelatedToRes = this.rectsAndFieldsToBeCreatedFromTable.findIndex((rectAndField) => {
                return (
                  rectAndField.field.table.position.column.number === res.table.position.column.number &&
                  rectAndField.field.table.position.row === res.table.position.row &&
                  rectAndField.field.options.customFieldLabel === res.options.customFieldLabel &&
                  !rectAndField.field.id
                );
              });

              this.rectsAndFieldsToBeCreatedFromTable[indexOfFieldRelatedToRes].field.id = res.id;
              this.rectsAndFieldsToBeCreatedFromTable[indexOfFieldRelatedToRes].rect.id = res.id;
            },
            complete: () => {
              const fieldAndRectWithNoIdExists = this.rectsAndFieldsToBeCreatedFromTable.find(
                (rectAndField) => !rectAndField.field.id || !rectAndField.rect.id
              );

              if (fieldAndRectWithNoIdExists) {
                this.deleteObjectsCreatedFromFailedTableCreation(this.rectsAndFieldsToBeCreatedFromTable);
              } else {
                this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
                this.rectsAndFieldsToBeCreatedFromTable.forEach((rectAndField) => {
                  this.updateCanvasObjectIdBasedOnOwnMatrixCacheKey(rectAndField.rect.ownMatrixCache.key, rectAndField.rect.id);
                });
                this.setFormId(this.rectsAndFieldsToBeCreatedFromTable[0].field.formId);
                this.getAllFormFieldsDebounced();
                this.saveCanvasDataDebounced();
                this.rectsAndFieldsToBeCreatedFromTable = [];
              }
            },
            error: (e) => {
              if (e) {
                this.deleteObjectsCreatedFromFailedTableCreation(this.rectsAndFieldsToBeCreatedFromTable);
              }
            },
          });
        },
        error: (e) => {
          if (e) {
            this.deleteObjectsCreatedFromFailedTableCreation(this.rectsAndFieldsToBeCreatedFromTable);
          }
        },
      });
  }

  createFieldsInDisclosure() {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.fieldManagementService
      .createField(this.rectsAndFieldsToBeCreatedFromDisclosure[0].field)
      .pipe(
        tap((res: FormBuilderField) => {
          this.rectsAndFieldsToBeCreatedFromDisclosure[0].field.id = res.id;
          this.rectsAndFieldsToBeCreatedFromDisclosure[0].rect.id = res.id;
          this.rectsAndFieldsToBeCreatedFromDisclosure[0].field.fieldDisclosureOption = res.fieldDisclosureOption;
        }),
        concatMap((res: FormBuilderField) => {
          const obs$ = [];

          this.rectsAndFieldsToBeCreatedFromDisclosure.forEach((item, index) => {
            if (index > 0) {
              item.field.fieldDisclosureOption = res.fieldDisclosureOption;
              obs$.push(this.fieldManagementService.createField(item.field));
            }
          });

          return of(obs$);
        })
      )
      .subscribe({
        next: (result: []) => {
          const processedObservable = concat(...result);

          processedObservable.subscribe({
            next: (res: FormBuilderField) => {
              const indexOfFieldRelatedToRes = this.rectsAndFieldsToBeCreatedFromDisclosure.findIndex((rectAndField) => {
                return rectAndField.field.options.customFieldLabel === res.options.customFieldLabel && !rectAndField.field.id;
              });

              this.rectsAndFieldsToBeCreatedFromDisclosure[indexOfFieldRelatedToRes].field.id = res.id;
              this.rectsAndFieldsToBeCreatedFromDisclosure[indexOfFieldRelatedToRes].rect.id = res.id;
            },
            complete: () => {
              const fieldAndRectWithNoIdExists = this.rectsAndFieldsToBeCreatedFromDisclosure.find(
                (rectAndField) => !rectAndField.field.id || !rectAndField.rect.id
              );

              if (fieldAndRectWithNoIdExists) {
                this.deleteObjectsCreatedFromFailedDisclosureCreation(this.rectsAndFieldsToBeCreatedFromDisclosure);
              } else {
                this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
                this.rectsAndFieldsToBeCreatedFromDisclosure.forEach((rectAndField) => {
                  this.updateCanvasObjectIdBasedOnOwnMatrixCacheKey(rectAndField.rect.ownMatrixCache.key, rectAndField.rect.id);
                });
                this.setFormId(this.rectsAndFieldsToBeCreatedFromDisclosure[0].field.formId);
                this.getAllFormFieldsDebounced();
                this.saveCanvasDataDebounced();
                this.rectsAndFieldsToBeCreatedFromDisclosure = [];
              }
            },
            error: (e) => {
              if (e) {
                this.deleteObjectsCreatedFromFailedDisclosureCreation(this.rectsAndFieldsToBeCreatedFromDisclosure);
              }
            },
          });
        },
        error: (e) => {
          if (e) {
            this.deleteObjectsCreatedFromFailedDisclosureCreation(this.rectsAndFieldsToBeCreatedFromDisclosure);
          }
        },
      });
  }

  private deleteObjectsCreatedFromFailedTableCreation(objects: CustomFieldAndRect[]): void {
    const objectsInCanvas = this.canvas.getObjects();

    objects.forEach((object) => {
      const objectToBeRemoved = objectsInCanvas.find(
        (objectInCanvas: CustomObject) =>
          object.rect.ownMatrixCache?.key && objectInCanvas.ownMatrixCache?.key === object.rect.ownMatrixCache?.key
      );
      this.canvas.remove(objectToBeRemoved);
    });

    this.fieldManagementService.deleteTableById(this.rectsAndFieldsToBeCreatedFromTable[0].field.table.type.id).subscribe({
      complete: () => this.progressSpinnerService.toggleProgressSpinnerDebounced(true),
      error: () => this.progressSpinnerService.toggleProgressSpinnerDebounced(false),
    });
    this.rectsAndFieldsToBeCreatedFromTable = [];

    throw {
      message: 'Duplicated field found',
      value: 'There are at least two fields or group options with the same name.\n No fields from this table were saved.',
    };
  }

  private deleteObjectsCreatedFromFailedDisclosureCreation(objects: CustomFieldAndRect[]): void {
    const objectsInCanvas = this.canvas.getObjects();

    objects.forEach((object) => {
      const objectToBeRemoved = objectsInCanvas.find(
        (objectInCanvas: CustomObject) =>
          object.rect.ownMatrixCache?.key && objectInCanvas.ownMatrixCache?.key === object.rect.ownMatrixCache?.key
      );
      this.canvas.remove(objectToBeRemoved);
    });

    this.rectsAndFieldsToBeCreatedFromDisclosure.forEach((item) => {
      this.fieldManagementService.deleteFieldById(item.field.id);
    });

    this.rectsAndFieldsToBeCreatedFromDisclosure = [];

    throw {
      message: 'Duplicated field found',
      value: 'There are at least two fields or group options with the same name.\n No fields from this disclosure were saved.',
    };
  }

  updateCanvasObjectIdBasedOnOwnMatrixCacheKey(key: string, id: number): void {
    const objectsInCanvas = this.canvas.getObjects();
    objectsInCanvas.forEach((object) => {
      if (key && object.ownMatrixCache && object.ownMatrixCache?.key === key) {
        (object as CustomObject).id = id;
      }
    });
  }

  handleKeyboardDownEvents(event: KeyboardEvent) {
    const arrowKeysIncrement = event.shiftKey ? 10 : 1;
    if (this.canvas?.getActiveObject()) {
      this.activeCanvasObject = this.canvas.getActiveObject() as CustomObject;
      if (event.key === KeyboardEventsConstants.UP_ARROW) {
        event.preventDefault();
        this.moveUpSelected(arrowKeysIncrement);
        this.saveCanvasDataDebounced();
      }
      if (event.key === KeyboardEventsConstants.DOWN_ARROW) {
        event.preventDefault();
        this.moveDownSelected(arrowKeysIncrement);
        this.saveCanvasDataDebounced();
      }
      if (event.key === KeyboardEventsConstants.LEFT_ARROW) {
        event.preventDefault();
        this.moveLeftSelected(arrowKeysIncrement);
        this.saveCanvasDataDebounced();
      }
      if (event.key === KeyboardEventsConstants.RIGHT_ARROW) {
        event.preventDefault();
        this.moveRightSelected(arrowKeysIncrement);
        this.saveCanvasDataDebounced();
      }
    }
    this.keepObjectInCanvas();
  }

  saveCanvasToJSON(): void {
    const json = JSON.stringify(this.canvas.toJSON(['id']));

    localStorage.setItem('Kanvas', json);
  }

  loadCanvasFromJSON() {
    const CANVAS = localStorage.getItem('Kanvas');
    this.canvas.loadFromJSON(CANVAS, () => {
      this.canvas.renderAll();
    });
  }

  setCanvas(canvas: fabric.Canvas, canvasAsJson: string, canvasImage: string) {
    this.canvas = canvas;
    canvas = this.setCanvasImage(canvas, canvasImage);
    this.canvas.loadFromJSON(canvasAsJson, () => {
      this.canvas.renderAll();
    });
  }

  public deleteFieldFromCanvas(field: FormBuilderField) {
    this.canvas.getObjects().forEach((object) => {
      if ((object as CustomObject).id === field.id) {
        this.canvas.remove(object);
        this.saveCanvasData();
      }
    });
    this.canvas.requestRenderAll();
  }

  drawDefaultSizedRectangle(ref: fabric.Rect, canvas: fabric.Canvas) {
    ref.set({ width: 100, height: 30, scaleX: 1, scaleY: 1, originY: 'center', originX: 'center' });

    this.setObjectAndRenderCanvas(ref);
    canvas.renderAll();
    canvas.setActiveObject(ref);
    canvas.requestRenderAll();
    canvas.setActiveObject(this.currentlyDrawnRectangle);
  }

  private isRectangleTooSmall(): boolean {
    return this.currentlyDrawnRectangle.width < 5 && this.currentlyDrawnRectangle.height < 5;
  }

  drawInitialRectangle() {
    this.currentlyDrawnRectangle = new fabric.Rect({
      left: this.origin.origX,
      top: this.origin.origY,
      originX: this.selectedCustomFieldForDrawing.fieldType.dragToDraw ? 'left' : 'center',
      originY: this.selectedCustomFieldForDrawing.fieldType.dragToDraw ? 'top' : 'center',
      width: this.selectedCustomFieldForDrawing.fieldType.dragToDraw ? 0 : this.constantRectangleSizeForClickOnlyObjects,
      height: this.selectedCustomFieldForDrawing.fieldType.dragToDraw ? 0 : this.constantRectangleSizeForClickOnlyObjects,
      fill: this.selectedCustomFieldForDrawing.fieldType.rgbaColorForRectangle,
    });

    this.selectedCustomFieldForDrawing.id = undefined;
    this.selectedCustomFieldForDrawing.formId = this.currentCanvasForm?.id ? this.currentCanvasForm.id : null;
    this.selectedCustomFieldForDrawing.formAreaPosition = null;

    if (this.selectedCustomFieldForDrawing.options.customFieldLabel)
      this.selectedCustomFieldForDrawing.options.customFieldName = _.camelCase(this.selectedCustomFieldForDrawing.options.customFieldLabel);

    this.canvas.add(this.currentlyDrawnRectangle);
    const ref: any = this.currentlyDrawnRectangle;
    if (!this.selectedCustomFieldForDrawing.table) {
      if (this.selectedCustomFieldForDrawing.groupOptions?.type?.type === FormBuilderFieldTypeType.DISCLOSURE) {
        this.fieldsToBeCreatedFromDisclosure.push(this.selectedCustomFieldForDrawing);
        this.rectsAndFieldsToBeCreatedFromDisclosure.push({
          rect: this.currentlyDrawnRectangle as CustomObject,
          field: this.selectedCustomFieldForDrawing,
        });
      } else {
        this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
        this.fieldManagementService.createField(this.selectedCustomFieldForDrawing).subscribe({
          next: (res: FormBuilderField) => {
            ref.id = res.id;

            this.setFormId(res.formId);
            this.getAllFormFieldsDebounced();
            this.saveCanvasDataDebounced();
            this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
          },
          error: () => {
            this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
            this.stopUserFromDrawing();
            this.deleteFieldFromCanvas(ref);
          },
        });
      }
    } else {
      this.fieldsToBeCreatedFromTable.push(this.selectedCustomFieldForDrawing);
      this.rectsAndFieldsToBeCreatedFromTable.push({
        rect: this.currentlyDrawnRectangle as CustomObject,
        field: this.selectedCustomFieldForDrawing,
      });
    }
  }

  stopUserFromDrawing() {
    this.canvas.setCursor('default');
    this.isMousePressed = false;
    this.canvasInDrawingMode = false;
    this.userDrawing.next(false);
    this.selectedCustomFieldForDrawing = null;
    this.currentlyDrawingFieldFromGroup.next(null);
    this.currentlyDrawingFieldFromTable.next(null);
    this.tableToDraw = undefined;
    this.fieldsToDrawFromGroup = [];
    this.rectsAndFieldsToBeCreatedFromTable = [];
  }

  moveColumnInTable(table: FieldTable, previousColumnPositionInTable: number, newColumnPositionInTable: number) {
    const element = table.columns[previousColumnPositionInTable];
    table.columns.splice(previousColumnPositionInTable, 1);
    table.columns.splice(newColumnPositionInTable, 0, element);
    table.columns.forEach((column: FieldColumn, index: number) => {
      column.number = index;
    });
  }

  private setObjectAndRenderCanvas(object: Rect): void {
    this.canvas.setActiveObject(object);
    this.canvas.requestRenderAll();
  }

  private setCanvasImage(canvas: fabric.Canvas, canvasImage: string): fabric.Canvas {
    canvas.setBackgroundImage(canvasImage, canvas.renderAll.bind(canvas), {
      scaleX: 1,
      scaleY: 1,
    });
    return canvas;
  }

  private drawRectangleWhileMouseIsMoving(o: fabric.IEvent) {
    const pointer = this.canvas.getPointer(o.e);

    if (this.origin.origX > pointer.x) {
      this.currentlyDrawnRectangle.set({ left: Math.abs(pointer.x) });
    }
    if (this.origin.origY > pointer.y) {
      this.currentlyDrawnRectangle.set({ top: Math.abs(pointer.y) });
    }

    this.currentlyDrawnRectangle.set({
      width: Math.abs(this.origin.origX - pointer.x),
    });
    this.currentlyDrawnRectangle.set({
      height: Math.abs(this.origin.origY - pointer.y),
    });
  }

  private setCanvasSize(canvas: fabric.Canvas): fabric.Canvas {
    canvas.setWidth(this.size.width);
    canvas.setHeight(this.size.height);
    return canvas;
  }

  private setCanvasSelection(canvas: fabric.Canvas, selection: boolean): fabric.Canvas {
    canvas.selection = selection;
    return canvas;
  }

  private getOriginFromPointer(o: fabric.IEvent): origin {
    const pointer = this.canvas.getPointer(o.e);
    return {
      origX: pointer.x,
      origY: pointer.y,
    };
  }

  private setInitialCanvasObjectsProperties() {
    fabric.Object.prototype.set({
      borderColor: '#5297ff',
      cornerColor: '#5297ff',
      cornerStyle: 'rect',
      angle: 0,
      padding: 10,
      transparentCorners: false,
      cornerSize: 10,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const controls = fabric.Object.prototype.controls;
    controls.mtr.visible = false;
    controls.mt.visible = false;
    controls.mr.visible = false;
    controls.mb.visible = false;
    controls.ml.visible = false;
  }

  private moveUpSelected(increment: number) {
    this.activeCanvasObject.top -= increment;
    this.canvas.requestRenderAll();
  }

  private moveDownSelected(increment: number) {
    this.activeCanvasObject.top += increment;
    this.canvas.requestRenderAll();
  }

  private moveLeftSelected(increment: number) {
    this.activeCanvasObject.left -= increment;
    this.canvas.requestRenderAll();
  }

  private moveRightSelected(increment: number) {
    this.activeCanvasObject.left += increment;
    this.canvas.requestRenderAll();
  }

  public getAllFormFields(formId, withSubscribe: boolean = true): Subscription | Observable<FormBuilderField[]> {
    if (formId) {
      switch (withSubscribe) {
        case true:
          return this.fieldManagementService.getAllFieldsByFormId(formId).subscribe((res: FormBuilderField[]) => {
            this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
            this.listOfAllFormFields = res;

            this.setActiveFieldToSelectedObject();
          });
        case false:
          return this.fieldManagementService.getAllFieldsByFormId(formId) as Observable<FormBuilderField[]>;
      }
    }
  }

  public getAllProductFormFields(productForm, withSubscribe: boolean = true): Subscription | Observable<FormBuilderField[]> {
    if (productForm) {
      switch (withSubscribe) {
        case true:
          return this.fieldManagementService.getAllFieldsByProductFormId(productForm).subscribe((res: FormBuilderField[]) => {
            this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
            this.listOfAllFormFields = res;

            this.setActiveFieldToSelectedObject();
          });
        case false:
          return this.fieldManagementService.getAllFieldsByProductFormId(productForm) as Observable<FormBuilderField[]>;
      }
    }
  }

  private saveCanvasData() {
    if (this.currentPage) {
      this.currentPage.canvasData = JSON.stringify(this.canvas.toJSON(['id']));
      this.http.put(`form-builder/page/${this.currentPage.id}`, this.currentPage).subscribe({
        complete: () => {
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        },
      });
    }
  }
}
