/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';

interface Question {
  id: string;
  order: string;
  title: string;
  response?: string;
  questions?: Question[];
}

@Component({
  selector: 'iapplication2-summary-health',
  templateUrl: './summary-health.component.html',
  styleUrls: ['./summary-health.component.scss'],
})
export class SummaryHealthComponent implements OnInit {
  questions: Question[];

  ngOnInit(): void {
    this.initQuestions();
  }

  initQuestions() {
    this.questions = [
      {
        id: '1',
        order: '1',
        title: 'Have you ever sought advice or received any treatment for, or had any known indication of:',
        questions: [
          {
            id: '1a',
            order: 'a)',
            title:
              'Stroke (including transient ischemic attack), heart attack, coronary artery disease, severe valvular heart disease e.g. aortic stenosis, or any type of cardiac surgery?',
            response: 'true',
          },
          {
            id: '1b',
            order: 'b)',
            title: 'Cancer, tumour or malignancy.',
            response: 'true',
          },
          {
            id: '1c',
            order: 'c)',
            title: 'Advanced ophthalmic disease',
            response: 'true',
          },
          {
            id: '1d',
            order: 'd)',
            title: 'Multiple sclerosis or paralysis',
            response: 'true',
          },
          {
            id: '1e',
            order: 'e)',
            title:
              'Any chronic or progressive disease or disorder of the kidney, lung, liver, pancreas or bone marrow that may lead to the failure of the organ or thatmay require transplantation',
            response: 'true',
          },
          {
            id: '1f',
            order: 'f)',
            title: 'AIDS, HIV, chronic or unexplained infections',
            response: 'true',
          },
        ],
      },
      {
        id: '2',
        order: '2',
        title: 'In the last 5 years, have you been diagnosed or had any known indication of a medical problem involving:',
        questions: [
          {
            id: '2a',
            order: 'a)',
            title:
              'Untreated or uncontrolled high blood pressure, angina, heart murmur associated with known cardiac disease, or an abnormal ECG associated with the potential for or evidence of, a cardiac event',
            response: 'true',
          },
          {
            id: '2b',
            order: 'b)',
            title: 'Diabetes, digestive or intestinal disorder, excluding functional disorders e.g. Irritable Bowel Syndrome',
            response: 'true',
          },
          {
            id: '2c',
            order: 'c)',
            title: 'Hospitalized due to a medical problem with respect to severe respiratory disorder?',
            response: 'true',
          },
          {
            id: '2d',
            order: 'd)',
            title: 'Used habit forming drugs, or received treatment or medical advice due to the use of drugs or alcohol?',
            response: 'true',
          },
        ],
      },
      {
        id: '3',
        order: '3',
        title: 'Have you ever been declined for life insurance or offered coverage only at higher than standard rates?',
      },
      {
        id: '4',
        order: '4',
        title: 'Have you ever sought advice or received treatment for, or had any known indication of:',
        questions: [
          {
            id: '4a',
            order: 'a)',
            title: 'Advanced loss of hearing?',
            response: 'true',
          },
          {
            id: '4b',
            order: 'b)',
            title: 'Alzheimer’s disease, Parkinson’s disease, motor neuron disease or other neuro-degenerative disorders?',
            response: 'true',
          },
          {
            id: '4c',
            order: 'c)',
            title: 'Any psychiatric disorder, mental deterioration or loss of intellectual ability?',
            response: 'true',
          },
          {
            id: '4d',
            order: 'd)',
            title:
              'Gout, arthritis, scleroderma, muscular dystrophy, ataxia, systemic lupus erythematosus, transverse myelitis, myasthenia gravis, post-polio syndrome,sarcoidosis or cystic fibrosis?',
            response: 'true',
          },
          {
            id: '4e',
            order: 'e)',
            title: 'Amputation due to disease?',
            response: 'true',
          },
        ],
      },
      {
        id: '5',
        order: '5',
        title: 'Do you currently:',
        questions: [
          {
            id: '5a',
            order: 'a)',
            title:
              'Use or require the use of any mechanical or medical devices such as: a wheelchair, walker, multi-prong cane, crutches, hospital bed, dialysis, oxygen, motorized cart or stair lift?',
            response: 'true',
          },
          {
            id: '5b',
            order: 'b)',
            title:
              'Need help, assistance or supervision in doing any of the following: bathing, eating, dressing, toileting, walking, transferring, or maintaining continence?',
            response: 'true',
          },
          {
            id: '5c',
            order: 'c)',
            title:
              'Need help, assistance or supervision in performing two or more of the following everyday activities: taking medication, doing housework, laundry, shopping or meal preparation?',
            response: 'true',
          },
        ],
      },
      {
        id: '6',
        order: '6',
        title: 'Does your height and weight fall outside the chart noted below?',
      },
    ];
  }
}
