import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentPayload } from '@iapplication2/interfaces';
import { Observable, of, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  paymentFormFinished: Subject<void> = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  createToken(payload: PaymentPayload, applicationId: number) {
    const url = `application/payment/${applicationId}`;

    return this.http.post(url, payload, {
      observe: 'response',
    });
  }

  pauseRecording(): Observable<any> {
    const payload = {
      email: this.authService.user.email,
      action: 'pause',
    };

    if (payload.email) {
      return this.http.put('application/dialer/recording', payload, {
        observe: 'response',
      });
    }

    return of(null);
  }

  resumeRecording(): Observable<any> {
    const payload = {
      email: this.authService.user.email,
      action: 'resume',
    };

    if (payload.email) {
      return this.http.put('application/dialer/recording', payload, {
        observe: 'response',
      });
    }

    return of(null);
  }
}
