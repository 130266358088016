/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from '@iapplication2/app-config';
import { ENV, envType } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-application-management-list',
  templateUrl: './application-management-list.component.html',
  styleUrls: ['./application-management-list.component.scss'],
})
export class ApplicationManagementListComponent implements OnInit {
  activeIndex: number = 0;

  isEnvDevelop: boolean;

  constructor(private router: Router, private route: ActivatedRoute, @Inject(APP_CONFIG) public appConfig: ENV) {}

  ngOnInit(): void {
    this.navigateToProvidedTab();
  }

  checkIfEnvIsDevelop() {
    this.isEnvDevelop = this.appConfig.environmentType === envType.DEVELOP;
  }

  navigateToProvidedTab() {
    const providedTabQueryParam = this.route.snapshot.queryParamMap.get('tab');
    switch (providedTabQueryParam) {
      case 'applications':
        this.activeIndex = 0;
        break;
      case 'policies':
        this.activeIndex = 1;
        break;
      case 'DTCpolicies':
        this.activeIndex = 2;
        break;
      default:
        this.activeIndex = 0;
    }
  }

  tabChanged(event: any) {
    this.changeRouterBasedOnTabIndex(event.index);
  }

  changeRouterBasedOnTabIndex(index: number) {
    let param: string;
    switch (index) {
      case 0:
        param = 'applications';
        break;
      case 1:
        param = 'policies';
        break;
      case 2:
        param = 'DTCpolicies';
        break;
      default:
        param = 'applications';
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: param,
      },
      queryParamsHandling: 'merge',
    });
  }
}
