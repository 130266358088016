import { Component, Input } from '@angular/core';
import { User } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  @Input() user: User;
  profileImageLink: string;
}
