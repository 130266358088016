/* eslint-disable max-len */
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldCategory } from '@iapplication2/interfaces';
import { FieldCategoryService } from '@iapplication2/services';
import { categoryTitleDuplicationValidator } from '@iapplication2/validators';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-field-categories-creator',
  templateUrl: './field-categories-creator.component.html',
  styleUrls: ['./field-categories-creator.component.scss'],
})
export class FieldCategoriesCreatorComponent implements OnInit, OnDestroy {
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();
  fieldCategories: FieldCategory[];

  newCategory: FieldCategory = {} as FieldCategory;

  fieldCategoryForm: FormGroup = new FormGroup({
    title: new FormControl(null, [Validators.required, categoryTitleDuplicationValidator(this.fieldCategoryService, '')]),
    slug: new FormControl(null, [Validators.required]),
  });

  private unsubscribe = new Subject<void>();

  constructor(private fieldCategoryService: FieldCategoryService) {}

  ngOnInit() {
    this.getFetchedCategories();
  }

  getFetchedCategories() {
    this.fieldCategoryService.fatchedCategories
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FieldCategory[]) => (this.fieldCategories = res));
  }

  createCategory() {
    this.newCategory.title = this.fieldCategoryForm.controls['title'].value;
    this.newCategory.slug = _.camelCase(this.fieldCategoryForm.controls['title'].value);
    this.newCategory.position = Math.max(...this.fieldCategories.map((category: FieldCategory) => category.position)) + 1;
    this.newCategory.isDefault = false;
    this.fieldCategoryService.createFieldCategory(this.newCategory);

    this.closeDialog.emit(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
