<div class="layout-topbar">
  <div class="layout-topbar-wrapper">
    <div class="layout-topbar-left" *ngIf="!isLoginPage">
      <a tabindex="0" class="menu-button" (click)="onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
      </a>
      <a tabindex="0" id="logo-link" class="layout-topbar-logo">
        <img [src]="getLogoPathForCurrentLightMode()" alt="freya-layout" />
      </a>
    </div>

    <iapplication2-menu *ngIf="!isLoginPage"></iapplication2-menu>

    <div class="layout-topbar-right">
      <div fxFlex fxLayoutAlign="center center" fxLayout="row">
        <p-progressSpinner
          *ngIf="progressSpinnerService.showGlobalLoadingSpinner"
          strokeWidth="6"
          class="app-loader-spinner"
        ></p-progressSpinner>
        <div>
          <iapplication2-dynamic-topbar-information></iapplication2-dynamic-topbar-information>
        </div>
        <ul class="layout-topbar-actions">
          <li *ngIf="isEnvDevelop && appConfig.appType === appType.ADVISOR && !isLoginPage" class="pr-2">
            <form class="p-inputgroup" [formGroup]="globalSearchForm" (submit)="searchWithProvidedQuery()">
              <input
                class="p-inputtext p-component"
                pInputText
                type="text"
                formControlName="searchQuery"
                placeholder="{{ 'pages.globalSearch.searchField.placeholder' | translate }}"
              />
              <button
                type="submit"
                pButton
                pRipple
                icon="pi pi-search"
                styleClass="p-button-warn"
                [disabled]="!globalSearchForm.controls['searchQuery'].value"
              ></button>
            </form>
          </li>
          <li class="topbar-item mr-2">
            <iapplication2-dark-mode-switcher> </iapplication2-dark-mode-switcher>
          </li>
          <li [formGroup]="languagesForm" class="mr-2">
            <div
              *ngIf="this.appConfig.environmentType !== envType.PRODUCTION"
              [pTooltip]="languagesForm.controls['language'].disabled ? ('general.interfaceLanguage.disabledTooltiop' | translate) : ''"
            >
              <p-dropdown
                [options]="languages"
                optionLabel="name"
                optionValue="code"
                formControlName="language"
                (onChange)="languageChange()"
              ></p-dropdown>
            </div>
          </li>
          <li *ngIf="!isLoginPage" #profile class="topbar-item user-profile p-1 cursor-pointer" style="position: static">
            <div>
              <a>
                <i class="pi pi-sign-out" style="font-size: 18px" (click)="logout()"></i>
                <!--            <img src="assets/layout/images/avatar-profilemenu.png" alt="freya-layout" (click)="menu.toggle($event)" />-->
              </a>
              <p-menu #menu [popup]="true" [model]="items"></p-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
