import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormSection } from '@iapplication2/interfaces';
import { DynamicTopbarInformationService, InteractiveFormBuilderService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-dynamic-topbar-form-display-information',
  templateUrl: './dynamic-topbar-form-display-information.component.html',
  styleUrls: ['./dynamic-topbar-form-display-information.component.scss'],
})
export class DynamicTopbarFormDisplayInformationComponent implements OnInit, OnDestroy {
  formSectionsList: FormSection[];
  sectionId: number;
  unsubscribe: Subject<void> = new Subject();

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private dynamicTopbarInformationService: DynamicTopbarInformationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.formSectionsList = this.interactiveFormBuilderService.formSectionsList;
    this.dynamicTopbarInformationService.currentSectionIdForFormDisplay.pipe(takeUntil(this.unsubscribe)).subscribe((sectionId) => {
      this.sectionId = sectionId;
    });
  }

  changedSection() {
    this.router.navigate(['formDisplay/form/fsdfsd/section/' + this.sectionId]);
  }

  moveToNextSection() {
    const sectionIndex = this.formSectionsList.findIndex((section) => section.id === this.sectionId);
    this.router.navigate(['formDisplay/form/fsdfsd/section/' + this.formSectionsList[sectionIndex + 1].id]);
  }

  moveToPreviousSection() {
    const sectionIndex = this.formSectionsList.findIndex((section) => section.id === this.sectionId);
    this.router.navigate(['formDisplay/form/fsdfsd/section/' + this.formSectionsList[sectionIndex - 1].id]);
  }

  checkIfIsFirstSection() {
    return this.formSectionsList[0].id === this.sectionId;
  }

  checkIfIsLastSection() {
    return this.formSectionsList[this.formSectionsList.length - 1].id === this.sectionId;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
