import { Pipe, PipeTransform } from '@angular/core';
import {
  FieldTable,
  FormBuilderField,
  FormBuilderFieldTypeType,
  FormBuilderGroup,
  FormBuilderItem,
  FormBuilderItemEnum,
} from '@iapplication2/interfaces';

@Pipe({
  name: 'itemTypeCheck',
})
export class ItemTypeCheckPipe implements PipeTransform {
  transform(item: FormBuilderItem, type: FormBuilderItemEnum): boolean {
    switch (type) {
      case FormBuilderItemEnum.GROUP:
        return !!(item as FormBuilderGroup).groupOptions;
      case FormBuilderItemEnum.TABLE:
        return !!(item as FieldTable).columns;
      case FormBuilderItemEnum.TEXT:
        return ((item as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.STATICTEXT);
      case FormBuilderItemEnum.FIELD:
        return (
          !(item as FormBuilderGroup).groupOptions &&
          !(item as FieldTable).columns &&
          !((item as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.STATICTEXT)
        );
      default:
        return false;
    }
  }
}
