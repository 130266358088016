import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FieldCategory,
  FieldColumn,
  FieldTable,
  FormBuilderField,
  FormBuilderFieldGroupOptions,
  FormBuilderGroup,
  FormBuilderItem,
  ModalType,
} from '@iapplication2/interfaces';
import { InteractiveFormBuilderService } from '@iapplication2/services';
import _ = require('lodash');
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-fields-sidebar-category',
  templateUrl: './fields-sidebar-category.component.html',
  styleUrls: ['./fields-sidebar-category.component.scss'],
  providers: [ConfirmationService],
})
export class FieldsSidebarCategoryComponent implements OnInit, OnDestroy {
  @Input() fieldsSidebarCategory: FieldCategory;
  @Input() predefinedFields: FormBuilderField[];
  @Input() showTemplateChoiceDialog = false;
  // customFormBuilderFields: CustomFormBuilderField[];
  formBuilderItems: FormBuilderItem[] = [];
  formBuilderGroups: FormBuilderGroup[] = [];
  formBuilderTables: FieldTable[] = [];
  settingsDialogOptions: ModalType;

  private unsubscribe = new Subject<void>();

  constructor(private interactiveFormBuilderService: InteractiveFormBuilderService, private confirmationService: ConfirmationService) {}

  ngOnInit(): void {
    this.createFormBuilderItems();
    this.interactiveFormBuilderService.settingsModalOptionsWereSelected.pipe(takeUntil(this.unsubscribe)).subscribe((options) => {
      this.settingsDialogOptions = options;
    });
    this.interactiveFormBuilderService.sidebarListWasUpdated.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.createFormBuilderItems();
    });
  }

  createFormBuilderItems() {
    this.formBuilderItems = [];
    this.formBuilderGroups = [];
    this.formBuilderTables = [];
    this.predefinedFields.filter((field: FormBuilderField) => {
      switch (true) {
        case this.interactiveFormBuilderService.isItemFieldFromTable(field) &&
          !this.interactiveFormBuilderService.isItemGroupFromTable(field):
          this.addFieldToTableItem(field);
          break;
        case this.interactiveFormBuilderService.isItemGroup(field):
          this.addFieldToGroupItem(field);
          break;
        default:
          this.formBuilderItems.push(field);
      }
    });

    this.formBuilderGroups.forEach((group: FormBuilderGroup) => {
      this.formBuilderItems.push(group);
    });

    this.formBuilderTables.forEach((table: FieldTable) => {
      table.fields.forEach((fieldInTable: FormBuilderField) => (fieldInTable.table.type.columns = _.cloneDeep(table.columns)));
      this.formBuilderItems.push(table);
    });
  }

  addFieldToTableItem(field: FormBuilderField) {
    const index = this.formBuilderTables.findIndex((table) => field.table?.type?.id && table.id === field.table?.type?.id);
    if (index === -1) {
      const table: FieldTable = field.table.type;

      table.fields = [_.cloneDeep(field)];
      this.formBuilderTables.push(field.table.type);
      this.addGroupOptionsToColumnInTable(field, table);
    } else {
      this.formBuilderTables[index].fields.push(_.cloneDeep(field));

      this.addGroupOptionsToColumnInTable(field, this.formBuilderTables[index]);
    }
  }

  addGroupOptionsToColumnInTable(field: FormBuilderField, table: FieldTable) {
    if (field.groupOptions) {
      field.groupOptions.id = parseInt(field.groupOptions.id.toString());
      table.columns.forEach((column: FieldColumn) => {
        if (
          column.id === field.table.position.column.id &&
          !column.fields?.find((fieldInColumn: string) => fieldInColumn === field.options?.customFieldLabel)
        ) {
          if (column.fields) {
            column.fields.push(field.options?.customFieldLabel);
          } else {
            column.fields = [field.options?.customFieldLabel];
          }
          column.fieldValidators = field.fieldValidators;
          column.groupOptions = field.groupOptions;
        }
      });
    }
  }

  addFieldToGroupItem(field: FormBuilderField) {
    const index = this.formBuilderGroups.findIndex((group) => group.groupOptions?.id == field.groupOptions?.id);
    if (index === -1) {
      const groupOptions: FormBuilderFieldGroupOptions = {
        id: field.groupOptions.id,
        name: field.groupOptions.name,
        type: field.fieldType,
        category: field.groupOptions.category,
      };
      const fields: FormBuilderField[] = [field];
      const group: FormBuilderGroup = {
        groupOptions: groupOptions,
        fields: fields,
        fieldValidators: fields[0].fieldValidators,
        predefinedFieldDisclosureOption: fields[0]?.predefinedFieldDisclosureOption,
      };
      this.formBuilderGroups.push(group);
    } else {
      this.formBuilderGroups[index].fields.push(field);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  itemSelected(item: FormBuilderItem) {
    if (this.showTemplateChoiceDialog) {
      this.showPredefinedOrTemplateDialog(item);
    } else {
      this.useSelectedItemAsPredefined(item);
    }
  }

  private showPredefinedOrTemplateDialog(item: FormBuilderItem) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Do you want to link this field with all other predefined fields of this type, or use it just as a template.',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Link with predefined',
      rejectLabel: 'Use just as template',
      accept: () => {
        this.useSelectedItemAsPredefined(item);
      },
      reject: () => {
        this.useSelectedItemAsTemplate(item);
      },
    });
  }

  private useSelectedItemAsPredefined(item: FormBuilderItem) {
    this.setPredefinedFieldIdFromItem(item);

    this.interactiveFormBuilderService.sidebarItemSelected(item, this.fieldsSidebarCategory);
    if (this.settingsDialogOptions !== ModalType.FORM) {
      this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.DRAW);
    }
  }

  private useSelectedItemAsTemplate(item: FormBuilderItem) {
    this.resetPredefinedFIeldIdFromItem(item);
    this.interactiveFormBuilderService.sidebarItemSelected(item, this.fieldsSidebarCategory);
    if (this.settingsDialogOptions !== ModalType.FORM) {
      this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.DRAW);
    }
  }

  setPredefinedFieldIdFromItem(item: FormBuilderItem) {
    switch (true) {
      case this.interactiveFormBuilderService.isItemTable(item):
        item.fields?.forEach((fieldInTable: FormBuilderField) => {
          fieldInTable.predefinedFieldId = fieldInTable.id;
          if (fieldInTable.groupOptions?.id) {
            fieldInTable.groupOptions.predefinedGroupId = fieldInTable.groupOptions?.id;
          }
          if (fieldInTable.predefinedFieldTableId && fieldInTable?.table?.type) {
            fieldInTable.table.type.predefinedFieldTableId = fieldInTable.predefinedFieldTableId;
          }
        });
        item.predefinedTableId = item.id;
        break;
      case this.interactiveFormBuilderService.isItemGroup(item):
        item.fields.forEach((fieldInGroup: FormBuilderField) => {
          fieldInGroup.predefinedFieldId = fieldInGroup.id;
          fieldInGroup.groupOptions.predefinedGroupId = fieldInGroup.groupOptions.id;
        });
        item.groupOptions.predefinedGroupId = item.groupOptions.id;
        break;
      case this.interactiveFormBuilderService.isItemField(item):
        item.predefinedFieldId = item.id;
        break;
    }
  }

  resetPredefinedFIeldIdFromItem(item: FormBuilderItem) {
    switch (true) {
      case this.interactiveFormBuilderService.isItemTable(item):
        item.fields?.forEach((fieldInTable: FormBuilderField) => {
          fieldInTable.predefinedFieldId = null;
          if (fieldInTable.groupOptions?.id) {
            fieldInTable.groupOptions.predefinedGroupId = null;
          }
          if (fieldInTable.predefinedFieldTableId && fieldInTable?.table?.type) {
            fieldInTable.table.type.predefinedFieldTableId = null;
          }
        });
        item.predefinedTableId = null;
        break;
      case this.interactiveFormBuilderService.isItemGroup(item):
        item.fields.forEach((fieldInGroup: FormBuilderField) => {
          fieldInGroup.predefinedFieldId = null;
          fieldInGroup.groupOptions.predefinedGroupId = null;
        });
        item.groupOptions.predefinedGroupId = null;
        break;
      case this.interactiveFormBuilderService.isItemField(item):
        item.predefinedFieldId = null;
        break;
    }
  }
}
