<div class="pt-3" fxFlex>
  <div *ngIf="!!'IAPP2CA-862' === false" fxLayout="column" class="pb-3">
    <p-card class="pb-3">
      <h5>{{ 'client_selection.select_client' | translate }}</h5>
      <span class="p-input-icon-left">
        <p-dropdown
          [options]="clients"
          [(ngModel)]="selectedClient"
          [filter]="true"
          filterBy="firstName,lastName, email"
          [showClear]="true"
          [placeholder]="'client_selection.select_client' | translate"
          (input)="(clients)"
          dropdownIcon="pi pi-search "
          (onChange)="fillForm()"
        >
          <ng-template pTemplate="selectedItem">
            <div class="client-item client-item-value" *ngIf="selectedClient">
              <div>{{ selectedClient?.firstName }} {{ selectedClient?.lastName }}</div>
            </div>
          </ng-template>
          <ng-template let-client pTemplate="item">
            <div class="client-item">
              <div>{{ client.firstName }} {{ client.lastName }}</div>
              <i class=""></i>
            </div>
          </ng-template>
        </p-dropdown>
      </span>
    </p-card>
  </div>
  <div fxLayout="row" class="pt-3">
    <div fxFlex fxLayout="column" [style]="{ alignSelf: 'flex-start' }">
      <iapplication2-application-client-information
        [form]="clientForm"
        [selectedClient]="selectedClient"
        [formSubmissionTriggered]="formSubmissionTriggered"
      ></iapplication2-application-client-information>
      <iapplication2-static-application-disclosures
        [displayHumaniaDisclosure]="isAnyHumaniaProductSelected"
        (changedStaticApplicationDisclosureAnswer)="onChangedStaticApplicationDisclosureAnswer()"
      ></iapplication2-static-application-disclosures>

      <iapplication2-dynamic-questionnaire-section
        [applicationId]="applicationId"
        [selectedProducts]="selectedProducts"
        (medicalQuestionnairesAreValid)="onMedicalQuestionnairesAreValidChanged($event)"
      ></iapplication2-dynamic-questionnaire-section>
    </div>
    <div fxFlex fxLayout="column">
      <iapplication2-product-selection
        [form]="clientForm"
        (selectionChanged)="onProductSelectionChanged($event)"
        (isAnyHumaniaProductSelectedChanged)="onIsAnyHumaniaProductSelectedChanged($event)"
        [loadedProducts]="loadedProducts"
      ></iapplication2-product-selection>
      <p-card class="pt-3">
        <iapplication2-fna-selection
          [form]="fnaForm"
          [fileKey]="fileKey"
          [applicationId]="applicationId"
          [fnaOptions]="fnaOptions"
          [fnaSelection]="fnaSelection"
          [fnaLiabilitiesAerobicHoursOptions]="fnaLiabilitiesAerobicHoursOptions"
          (fnaFormIsValid)="onFnaFormIsValid($event)"
        ></iapplication2-fna-selection>
      </p-card>
      <p-card class="pt-3">
        <form [formGroup]="clientForm">
          <p-fieldset legend="{{ 'client_selection.note' | translate }}" [toggleable]="true">
            <span class="p-float-label">
              <textarea id="textarea" rows="6" style="resize: none" cols="30" formControlName="note" pInputTextarea fxFlex></textarea>
            </span>
            <div *ngIf="clientForm.controls['note'].errors" style="color: red">
              <div>{{ 'client_selection.noteMaxLengthError' | translate }}</div>
            </div>
          </p-fieldset>
        </form>
      </p-card>
    </div>
  </div>
</div>
