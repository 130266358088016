import { FieldTable } from '@iapplication2/interfaces';
import { FormBuilderField } from './form-builder-field.model';
import { FormBuilderGroup } from './form-builder-group.model';
import { FormBuilderStaticText } from './form-builder-static-text.model';
import { ConditionsTypeEnum } from './advisor-application/conditions-type.enum';

export type FormBuilderItem = FormBuilderField | FormBuilderGroup | FormBuilderStaticText | FieldTable;

export enum FormBuilderItemEnum {
  FIELD = 'field',
  GROUP = 'group',
  TABLE = 'table',
  TEXT = 'text',
}

export interface Condition {
  conditionFieldId: number,
  conditionType: {
    acceptsValue: boolean,
    fieldTypeId: number,
    id: number,
    type: ConditionsTypeEnum
  },
  fieldId: number,
  id: number,
  value: string
  [key: string]: any;
}
