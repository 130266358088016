export interface ApplicationFna {
    id?: number;
    fnaLiabilitiesMortgages?: number;
    fnaLiabilitiesLoans?: number;
    fnaLiabilitiesFinalExpenses?: number;
    fnaLiabilitiesEducationFund?: number;
    fnaLiabilitiesEmergencyFund?: number;

    fnaLiabilitiesIndividualAnnualIncome?: number;
    fnaLiabilitiesPercentNeeded?: number;
    fnaLiabilitiesYearsNeeded?: number;

    fnaLiabilitiesInsuredOccupation?: string;
    fnaLiabilitiesHouseholdAnnualIncome?: number;
    fnaLiabilitiesHouseholdMembersAge?: string;
    fnaLiabilitiesAerobicHoursPerWeek?: string;

    fnaAssetsCashSavings?: number;
    fnaAssetsCashBondsFunds?: number;
    fnaAssetsRealEstate?: number;
    fnaAssetsBusiness?: number;
    fnaAssetsTotalInsurance?: number;
    fnaAssetsDeathBenefits?: number;
}
