<p-accordionTab [selected]="false" class="w-full">
  <p-header fxFlex fxFill>
    <div fxLayout="row" fxFlex fxFill>
      <div fxLayoutAlign="start">
        <p-checkbox
          name="product"
          [binary]="true"
          [(ngModel)]="ratesProduct.iappProduct.selected"
          class="pr-1"
          (onChange)="productSelectionChanged($event)"
        ></p-checkbox>
        {{
          (ratesProduct.iappProduct.lokaliseKey ? ratesProduct.iappProduct.lokaliseKey : 'missing_key')
            | translate: { Default: ratesProduct.iappProduct.name }
        }}
      </div>
      <div fxLayoutAlign="end" fxFlex *ngIf="gender && (smokerStatus || reflexSmokerStatus !== null) && !loadingRate">
        {{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ monthlyPremium }}
        {{ 'client_selection.per_month' | translate }}
      </div>
      <div fxLayoutAlign="end" fxFlex *ngIf="loadingRate && gender && smokerStatus">
        {{ 'applicationProcess.step.productSelection.loading' | translate }}
      </div>
      <div fxLayoutAlign="end" fxFlex *ngIf="!gender || (!smokerStatus && !ratesProduct?.isHumania)">
        <i class="pi pi-exclamation-triangle"></i>
      </div>
    </div>
  </p-header>
  <form [formGroup]="coverageForm">
    <div fxFlex fxFill>
      <div fxLayout="row" fxFlex fxFill>
        <div fxLayout="column" fxFlex="40" fxFill fxLayoutAlign="start">
          <label for="product_coverage" *ngIf="ratesProduct.min_coverage !== ratesProduct.max_coverage">{{
            'applicationProcess.step.productSelection.coverage.coverageRange'
              | translate: { minCoverage: ratesProduct.min_coverage, maxCoverage: ratesProduct.max_coverage }
          }}</label>
          <label for="product_coverage" *ngIf="ratesProduct.min_coverage === ratesProduct.max_coverage">{{
            'applicationProcess.step.productSelection.coverage.coverageFixed' | translate: { coverage: ratesProduct.max_coverage }
          }}</label>
          <p-dropdown
            *ngIf="ratesProduct.min_coverage !== ratesProduct.max_coverage && ratesSteps"
            fxFlex
            fxFill
            [options]="ratesSteps"
            [editable]="false"
            id="product_coverage"
            formControlName="coverageDropdown"
            (onChange)="changedDropdown($event)"
            class="mb-3 mt-3"
          ></p-dropdown>
          <p-inputNumber
            class="mt-2"
            [showButtons]="ratesProduct.min_coverage !== ratesProduct.max_coverage"
            buttonLayout="horizontal"
            inputId="horizontal"
            spinnerMode="horizontal"
            [max]="ratesProduct?.max_coverage"
            [min]="ratesProduct?.min_coverage"
            [step]="500"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            mode="currency"
            currency="CAD"
            formControlName="coverage"
            [iapplication2MultipleOfNumber]="coverageForm.controls['coverage']"
          >
          </p-inputNumber>
        </div>
        <div fxLayout="column" fxFlex fxLayoutAlign="space-between end">
          <div>
            <div fxLayout="column" *ngIf="province && postalCode && isEverestAllowed && ratesProduct.iappProduct.selected">
              <div class="p-field-checkbox">
                <p-checkbox
                  [formControl]="coverageForm.controls['everest'] | cast: FormControlType"
                  [binary]="true"
                  inputId="everest"
                ></p-checkbox>
                <label class="ml-2">{{ 'applicationProcess.step.productSelection.everest' | translate }}</label>
              </div>
            </div>
            <div *ngIf="!province || !postalCode">
              <p class="mb-3">{{ 'applicationProcess.step.productSelection.everestCondition' | translate }}</p>
            </div>
            <div *ngIf="!isEverestAllowed && province && postalCode; else productSelectionNeeded">
              <p class="mb-3">{{ 'applicationProcess.step.productSelection.everestNotAllowed' | translate }}</p>
            </div>
            <ng-template #productSelectionNeeded>
              <div *ngIf="!ratesProduct.iappProduct.selected">
                {{ 'applicationProcess.step.productSelection.productSelectionNeeded' | translate }}
              </div>
            </ng-template>
          </div>
          <div fxLayoutAlign="space-between end">
            <div fxLayout="column" *ngIf="gender && smokerStatus">
              <p>
                <b>{{ 'rates.monthlyPremium' | translate }}</b> ${{ monthlyPremium }}
              </p>
              <p>
                <b>{{ 'rates.yearlyPremium' | translate }}</b> ${{ yearlyPremium }}
              </p>
            </div>
            <div *ngIf="!gender || (!smokerStatus && reflexSmokerStatus === null)" class="pl-5">
              <p>{{ 'applicationProcess.step.productSelection.priceDisplayCondition' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-accordionTab>
