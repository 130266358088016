<div class="pt-3 pr-3" *ngIf="displayStaticApplicationDisclosures">
  <p-card>
    <div *ngFor="let staticApplicationDisclosure of staticApplicationDisclosures">
      <iapplication2-static-disclosure
        *ngIf="staticApplicationDisclosure.condition"
        [staticApplicationDisclosure]="staticApplicationDisclosure"
        (changedStaticApplicationDisclosureAnswer)="onChangedStaticApplicationDisclosureAnswer()"
      >
      </iapplication2-static-disclosure>
    </div>
  </p-card>
</div>
