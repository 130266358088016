import { Validators } from '@angular/forms';
import { StringValidator } from './string.validator';

export class DefaultFormValidator {
  static firstName = [
    StringValidator.requiredNoOnlySpaces(),
    Validators.required,
    StringValidator.firstNamePart(),
    Validators.maxLength(50),
  ];
  static lastName = [StringValidator.requiredNoOnlySpaces(), Validators.required, StringValidator.namePart(), Validators.maxLength(35)];
  static address = [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(255)];
  static creditCard = [StringValidator.requiredNoOnlySpaces(), Validators.required, StringValidator.creditCard()];
}
