import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { APP_CONFIG } from '@iapplication2/app-config';
import { ENV, User } from '@iapplication2/interfaces';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient, private router: Router) {}

  user: User;
  user$: ReplaySubject<User> = new ReplaySubject(1);
  authStatusChanged: Subject<boolean> = new Subject();
  afterLoginRedirectUrl: string;

  loginUser(email: string, password: string) {
    return this.http.post(
      `auth/login`,
      {
        email,
        password,
      },
      { observe: 'response' }
    );
  }

  resetPassword(email: string, password: string, passwordConfirmation: string) {
    return this.http.put(`auth/forgot`, {
      email,
      password,
      passwordConfirmation,
    });
  }

  registerUser(email: string, firstName: string, lastName: string, password: string, passwordConfirmation: string) {
    return this.http.post(`auth/register`, {
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation,
    });
  }

  setToken(token: string) {
    localStorage.setItem('Authorization', token);
    this.authStatusChanged.next(true);
  }

  navigateAfterLogin() {
    if (this.afterLoginRedirectUrl) {
      this.router.navigate([this.afterLoginRedirectUrl]);
    } else {
      this.router.navigate(['']);
    }
  }

  getToken(): string {
    return localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');
  }

  logout() {
    localStorage.removeItem('Authorization');
    sessionStorage.removeItem('Authorization');
    this.authStatusChanged.next(false);
    this.router.navigate(['login']);
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization') ? true : false;
  }

  getUserInformation() {
    return this.http.get(`auth/me`).subscribe({
      next: (res: User) => {
        if (res) {
          this.user = res;
          this.user$.next(res);
        }
      },
    });
  }
}
