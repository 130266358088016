import { Component, OnDestroy, OnInit } from '@angular/core';
import { Notification } from '@iapplication2/interfaces';
import { NotificationsService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-dashboard-notifications',
  templateUrl: './dashboard-notifications.component.html',
  styleUrls: ['./dashboard-notifications.component.scss'],
})
export class DashboardNotificationsComponent implements OnInit, OnDestroy {
  recentNotifications: Notification[];
  unsubscribe: Subject<unknown> = new Subject();
  todayNotifications: Notification[] = [];
  yesterdayNotifications: Notification[] = [];
  olderNotifications: Notification[] = [];

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications() {
    this.notificationsService
      .getRecentNotifications()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: Notification[]) => {
        this.recentNotifications = res;
        this.recentNotifications?.forEach((notification) => {
          if (this.isToday(new Date(notification.timestamp))) {
            this.todayNotifications.push(notification);
          } else if (this.isYesterday(new Date(notification.timestamp))) {
            this.yesterdayNotifications.push(notification);
          } else {
            this.olderNotifications.push(notification);
          }
        });
      });
  }

  isToday(someDate: Date) {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() && someDate.getMonth() == today.getMonth() && someDate.getFullYear() == today.getFullYear()
    );
  }

  isYesterday(someDate: Date) {
    const today = new Date();
    const yesterday = new Date(new Date().setDate(today.getDate() - 1));
    return (
      someDate.getDate() == yesterday.getDate() &&
      someDate.getMonth() == yesterday.getMonth() &&
      someDate.getFullYear() == yesterday.getFullYear()
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
