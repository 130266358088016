import { Product } from '../product.model';

export interface RatesProductAdvisor {
  id: number;
  name: string;
  code: number;
  name_fr: string;
  min_age?: number;
  max_age?: number;
  min_premium?: number;
  min_coverage?: number;
  max_coverage?: number;
  owner: string;
  age_type: string;
  coverages?: any;
  iappProduct?: Product;
  coverage?: number;
  hasEverest: boolean;
  risk?: number;
  isHumania?: boolean;
  [key: string]: any;
}
