<div
  class="px-3 p-2 m-2"
  [ngClass]="isDarkTheme ? 'form-builder-sub-section-area-dark' : 'form-builder-sub-section-area'"
  fxFlex="100"
  fxLayout="column"
>
  <div fxLayout="row" fxLayoutAlign="space-between start" class="py-2">
    <div fxLayout="row" fxLayoutAlign="center center">
      <b class="sub-section-title pl-2">Unassigned fields</b>
    </div>
  </div>
  <div
    cdkDropList
    id="unassigned-fields"
    [cdkDropListData]="unassignedItems"
    [cdkDropListConnectedTo]="connectedTo"
    (cdkDropListDropped)="drop($event)"
    class="py-3 px-2 unassigned-fields-max-height-container"
  >
    <div *ngFor="let areaItem of unassignedItems">
      <iapplication2-form-builder-item-display
        [formBuilderItem]="areaItem"
        [selectedCanvasObject]="selectedCanvasObject"
        [selectedFormField]="selectedFormField"
        (deletedFieldEvent)="deletedField($event)"
      ></iapplication2-form-builder-item-display>
      <!-- <iapplication2-form-builder-item-display
        *ngIf="!areaItem.table?.type && !fieldIsGroupAndPartOfTable(areaItem) && !areaItem.groupOptions?.id"
        [formBuilderItem]="areaItem"
        [selectedCanvasObject]="selectedCanvasObject"
        [selectedFormField]="selectedFormField"
        (deletedFieldEvent)="deletedField($event)"
      >
      </iapplication2-form-builder-item-display>
      <iapplication2-form-builder-item-display
        *ngIf="!areaItem.table?.type && !fieldIsGroupAndPartOfTable(areaItem) && areaItem.id === areaItem.groupOptions?.group?.fields[0].id"
        [formBuilderItem]="areaItem"
        [selectedCanvasObject]="selectedCanvasObject"
        [selectedFormField]="selectedFormField"
        (deletedFieldEvent)="deletedField($event)"
      >
      </iapplication2-form-builder-item-display>
      <iapplication2-form-builder-item-display
        *ngIf="
          areaItem.table?.type &&
          areaItem.table?.position.row === 0 &&
          areaItem.table?.position.column.number === 0 &&
          !fieldIsGroupAndPartOfTable(areaItem)
        "
        [formBuilderItem]="areaItem.table?.type"
        [selectedCanvasObject]="selectedCanvasObject"
        [selectedFormField]="selectedFormField"
        (deletedFieldEvent)="deletedField($event)"
      >
      </iapplication2-form-builder-item-display>
      <iapplication2-form-builder-item-display
        *ngIf="
          !(areaItem.table?.type && areaItem.table?.position.row === 0 && areaItem.table?.position.column.number === 0) &&
          fieldIsGroupAndPartOfTable(areaItem)
        "
        [formBuilderItem]="areaItem.fields[0].table?.type"
        [selectedCanvasObject]="selectedCanvasObject"
        [selectedFormField]="selectedFormField"
        (deletedFieldEvent)="deletedField($event)"
      >
      </iapplication2-form-builder-item-display>
      <div
        *ngIf="areaItem.table?.type && (areaItem.table?.position.row !== 0 || areaItem.table?.position.column.number !== 0)"
        cdkDrag
      ></div> -->
    </div>
    <!-- <div *ngFor="let table of tables">
      <iapplication2-form-builder-item-display
        [formBuilderItem]="table"
        [selectedCanvasObject]="selectedCanvasObject"
        [selectedFormField]="selectedFormField"
      >
      </iapplication2-form-builder-item-display>
    </div> -->
    <div *ngIf="unassignedFields.length === 0">Good job! No unassigned fields left</div>
  </div>
</div>
