<div *ngIf="!hasValue" style="margin-top: 4px" class="ml-1 flex ai-center">
  <div *ngIf="required" [hideDelay]="100" [pTooltip]="'errorMessages.required' | translate" [showDelay]="200">
    <p-badge severity="danger" size="x-small" class="flex align-items-center mr-1"></p-badge>
  </div>
  <div *ngIf="disclosurePlayRequired" [hideDelay]="100" [pTooltip]="'errorMessages.disclosurePlayRequired' | translate"
       [showDelay]="200">
    <p-badge severity="danger" size="x-small" class="flex align-items-center mr-1"></p-badge>
  </div>
  <div *ngIf="MIBRequired" [hideDelay]="100" [pTooltip]="'errorMessages.MIBRequired' | translate"
       [showDelay]="200">
    <p-badge severity="danger" size="x-small" class="flex align-items-center mr-1"></p-badge>
  </div>
  <div *ngIf="optionRequired" [hideDelay]="100" [pTooltip]="'errorMessages.optionRequired' | translate"
       [showDelay]="200">
    <p-badge severity="danger" size="x-small" class="flex align-items-center mr-1"></p-badge>
  </div>
  <div
    class="flex ai-center"
    *ngIf="rowRequired"
    [pTooltip]="'errorMessages.rowRequired' | translate"
    [showDelay]="200"
    [hideDelay]="100"
  >
    <p-badge severity="help" size="x-small" class="flex align-items-center mr-1"></p-badge>
  </div>
</div>
