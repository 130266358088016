import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CustomFormBuilderField,
  FieldColumn,
  FieldTable,
  FieldTableType,
  FormBuilderField,
  FormBuilderFieldGroupOptions,
  FormBuilderFieldOptions,
  FormBuilderFieldType,
  FormBuilderFieldTypeType,
  FormBuilderGroup,
  FormBuilderGroupType,
  FormBuilderItem,
  FormBuilderItemEnum,
  ModalType,
} from '@iapplication2/interfaces';
import {
  CanvasService,
  FieldCategoryService,
  FieldManagementService,
  InteractiveFormBuilderService,
  PredefinedFieldsService,
  ProgressSpinnerService,
} from '@iapplication2/services';
import { FormHelper } from '@iapplication2/superclass';

import * as _ from 'lodash';
import { concat, of, Subject } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-field-settings-modal',
  templateUrl: './field-settings-modal.component.html',
  styleUrls: ['./field-settings-modal.component.scss'],
})
export class FieldSettingsModalComponent extends FormHelper implements OnInit, OnDestroy {
  @Input() selectedFormBuilderItem: FormBuilderItem;
  // Modal type draw displays the draw button and modal type edit displays a save button
  @Input() modalType: ModalType;
  @Input() isInFieldBuilder?: boolean = false;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

  formValidity = true;

  localSelectedFormBuilderItem: FormBuilderItem;

  modalTypeEnum = ModalType;

  isInEditMode = true;

  groupCanBeCreated = true;
  numberOfFieldsInTempFieldGroupFields = 0;

  fieldDetailsForm: FormGroup = new FormGroup({
    fieldForm: new FormGroup({}),
    groupForm: new FormGroup({}),
    tableForm: new FormGroup({}),
  });

  fieldCategoryForm: FormGroup = new FormGroup({
    fieldCategory: new FormControl(null, [Validators.required]),
  });

  FormBuilderFieldTypeTypeEnum = FormBuilderFieldTypeType;
  FormBuilderItemEnum = FormBuilderItemEnum;

  FormBuilderFieldType = FormBuilderFieldType;
  FormBuilderGroupType = FormBuilderGroupType;
  FormBuilderTableType = FieldTableType;

  private unsubscribe = new Subject<void>();

  constructor(
    private canvasService: CanvasService,
    public interactiveFormBuilderService: InteractiveFormBuilderService,
    private fieldCategoryService: FieldCategoryService,
    private predefinedFieldsService: PredefinedFieldsService,
    private fieldManagementService: FieldManagementService,
    private progressSpinnerService: ProgressSpinnerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setEditMode();
    if (
      this.interactiveFormBuilderService.isFormBuilderItemField(this.selectedFormBuilderItem) ||
      this.interactiveFormBuilderService.isFormBuilderItemTable(this.selectedFormBuilderItem)
    ) {
      this.localSelectedFormBuilderItem = _.cloneDeep(this.selectedFormBuilderItem);
    } else {
      this.localSelectedFormBuilderItem = this.selectedFormBuilderItem;
    }
  }

  setEditMode() {
    switch (true) {
      case this.interactiveFormBuilderService.isItemField(this.selectedFormBuilderItem):
        this.isInEditMode = !this.selectedFormBuilderItem?.predefinedFieldId;
        break;
      case this.interactiveFormBuilderService.isItemGroup(this.selectedFormBuilderItem):
        this.isInEditMode = !this.selectedFormBuilderItem.groupOptions?.predefinedGroupId;
        break;
      case this.interactiveFormBuilderService.isItemTable(this.selectedFormBuilderItem):
        this.isInEditMode = !this.selectedFormBuilderItem.predefinedTableId;
        break;
      default:
        this.isInEditMode = true;
    }
  }

  startDrawingWithSelectedField(): void {
    if ((this.localSelectedFormBuilderItem as FormBuilderField).predefinedFieldDisclosureOption) {
      (this.localSelectedFormBuilderItem as FormBuilderField).fieldDisclosureOption.predefinedDisclosureOptionId = (
        this.localSelectedFormBuilderItem as FormBuilderField
      )?.predefinedFieldDisclosureOption?.id;
      delete (this.localSelectedFormBuilderItem as FormBuilderField).predefinedFieldDisclosureOption;
    }

    const disclosureOption = (this.localSelectedFormBuilderItem as FormBuilderField).fieldDisclosureOption;

    if (disclosureOption) {
      disclosureOption.warningText = disclosureOption.warningText ? disclosureOption.warningText : '';
      disclosureOption.disclosureFieldTypeId = disclosureOption.disclosureFieldTypeId ? disclosureOption.disclosureFieldTypeId : 1;
    }

    this.canvasService.fieldSelected(this.localSelectedFormBuilderItem as FormBuilderField);
    this.canvasService.userDrawing.next(true);
    this.closeDialog.emit(true);
  }

  startDrawingFieldsInThisGroup(): void {
    this.prepareGroupForDrawing();
    this.canvasService.userDrawing.next(true);
    this.closeDialog.emit(true);
  }

  prepareGroupForDrawing() {
    const clonedSelectedFormBuilderItem: FormBuilderItem = _.cloneDeep(this.selectedFormBuilderItem);
    const predefinedGroupId: number = this.selectedFormBuilderItem.groupOptions.id;
    const newGroupId: number = Math.floor(Math.random() * 9000000000) + 1000000000;
    clonedSelectedFormBuilderItem.groupOptions.id = newGroupId;
    clonedSelectedFormBuilderItem.groupOptions = this.extractObjectFromFormValues<FormBuilderFieldGroupOptions>(
      <FormGroup>this.fieldDetailsForm.controls['groupForm'],
      clonedSelectedFormBuilderItem.groupOptions
    );
    clonedSelectedFormBuilderItem.fields.forEach((field) => {
      field.groupOptions.id = clonedSelectedFormBuilderItem.groupOptions.id;
      field.groupOptions.name = clonedSelectedFormBuilderItem.groupOptions.name;
      field.groupOptions.predefinedGroupId = predefinedGroupId;
      field.staticValueLink = clonedSelectedFormBuilderItem.staticValueLink;
      field.staticValueLinkId = clonedSelectedFormBuilderItem.staticValueLinkId;
      field.fieldTypeOptions = clonedSelectedFormBuilderItem.fieldTypeOptions;
      field.fieldValidators = clonedSelectedFormBuilderItem.fieldValidators;
      field.fieldDisclosureOption = clonedSelectedFormBuilderItem.fieldDisclosureOption;

      if (clonedSelectedFormBuilderItem.predefinedFieldDisclosureOption && !this.isInFieldBuilder) {
        field.fieldDisclosureOption.predefinedDisclosureOptionId = clonedSelectedFormBuilderItem.predefinedFieldDisclosureOption?.id;
        delete (this.localSelectedFormBuilderItem as FormBuilderField).predefinedFieldDisclosureOption;
      }
    });

    this.canvasService.fieldsFromGroupSelected(clonedSelectedFormBuilderItem.fields);
  }

  startDrawingFieldsInThisTable(): void {
    this.localSelectedFormBuilderItem.name = this.localSelectedFormBuilderItem.name
      ? this.localSelectedFormBuilderItem.name
      : this.fieldDetailsForm.controls['tableForm'].get('tableName').value;
    this.localSelectedFormBuilderItem.numberOfRows = this.localSelectedFormBuilderItem.numberOfRows
      ? this.localSelectedFormBuilderItem.numberOfRows
      : this.fieldDetailsForm.controls['tableForm'].get('numberOfRows').value;

    const tablePreparedForDrawing: FieldTable = _.cloneDeep(this.localSelectedFormBuilderItem as FieldTable);

    if (tablePreparedForDrawing.fields) {
      this.preparePredefinedTableForDrawing(tablePreparedForDrawing);
    }

    this.canvasService.drawTable(tablePreparedForDrawing);
    (this.localSelectedFormBuilderItem as FieldTable) = undefined;
    this.canvasService.userDrawing.next(true);
    this.closeDialog.emit(true);
  }

  private preparePredefinedTableForDrawing(table: FieldTable): void {
    this.prepareFieldsInTableForDrawing(table);
    this.prepareColumnsForDrawing(table);
    this.preparePredefinedFieldInGroupsFromTableForDrawing(table);
  }

  private prepareColumnsForDrawing(table: FieldTable): void {
    const columnsInTable = [];
    table.columns.forEach((columnInTable: FieldColumn) => {
      columnsInTable[columnInTable.id]
        ? columnsInTable[columnInTable.id].push(columnInTable)
        : (columnsInTable[columnInTable.id] = [columnInTable]);
    });

    Object.keys(columnsInTable).forEach((key) => {
      const newColumnId: number = Math.floor(Math.random() * 9000000000) + 1000000000;

      columnsInTable[key].forEach((columnInTable: FieldColumn) => {
        columnInTable.id = newColumnId;
      });

      table.fields.forEach((fieldInTable: FormBuilderField) => {
        fieldInTable.table.type.columns = table.columns;
        if (fieldInTable.table.position.column.id.toString() === key) {
          fieldInTable.table.position.column.id = newColumnId;
        }
      });
    });

    Object.keys(columnsInTable).forEach((key) => {
      columnsInTable[key].forEach((columnInTable: FieldColumn) => {
        if (columnInTable.linkedWith?.id) {
          columnInTable.linkedWith.id = columnsInTable[columnInTable.linkedWith?.id][0].id;
        }
      });
    });
  }

  private prepareFieldsInTableForDrawing(table: FieldTable): void {
    table.fields.forEach((fieldInTable: FormBuilderField) => {
      delete fieldInTable.id;
    });
  }

  private preparePredefinedFieldInGroupsFromTableForDrawing(table: FieldTable): void {
    const groupsInTable = [];
    table.fields.forEach((fieldInTable: FormBuilderField) => {
      if (fieldInTable.groupOptions?.id) {
        groupsInTable[fieldInTable.groupOptions.id]
          ? groupsInTable[fieldInTable.groupOptions.id].push(fieldInTable)
          : (groupsInTable[fieldInTable.groupOptions.id] = [fieldInTable]);
      }
    });

    Object.keys(groupsInTable).forEach((key) => {
      const newGroupId: number = Math.floor(Math.random() * 9000000000) + 1000000000;

      groupsInTable[key].forEach((fieldInGroup: FormBuilderField) => {
        fieldInGroup.groupOptions.id = newGroupId;
      });

      table.columns.forEach((columnInTable: FieldColumn) => {
        if (columnInTable.groupOptions?.id.toString() === key) {
          columnInTable.groupOptions.id = newGroupId;
        }
      });
    });
  }

  addField(): void {
    (this.localSelectedFormBuilderItem as FormBuilderField).options.visibleOnPdf = false;

    delete this.localSelectedFormBuilderItem.id;
    this.localSelectedFormBuilderItem.formId = this.canvasService.currentCanvasForm.id;

    if ((this.localSelectedFormBuilderItem as FormBuilderField).fieldDisclosureOption) {
      (this.localSelectedFormBuilderItem as FormBuilderField).fieldDisclosureOption.disclosureFieldTypeId = 1;
      (this.localSelectedFormBuilderItem as FormBuilderField).fieldDisclosureOption.warningText = '';
    }

    this.closeDialog.emit(true);
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.fieldManagementService.createField(this.localSelectedFormBuilderItem as FormBuilderField).subscribe((res: FormBuilderField) => {
      this.interactiveFormBuilderService.$formAreasUpdated.next(res.formAreaId);
      this.localSelectedFormBuilderItem.id = res.id;
      this.localSelectedFormBuilderItem.options.id = res.options.id;

      if (this.localSelectedFormBuilderItem.fileToBeAttached) {
        this.attachFileToField();
      }
    });
  }

  addGroup(): void {
    const newGroupName: string = this.fieldDetailsForm.controls.groupForm.value.name;
    const newGroupId: number = Math.floor(Math.random() * 9000000000) + 1000000000;
    this.selectedFormBuilderItem.groupOptions = {
      id: newGroupId,
      name: newGroupName,
      type: this.selectedFormBuilderItem.groupOptions.type,
    };
    this.selectedFormBuilderItem.visibleOnPdf = false;
    this.selectedFormBuilderItem.fields.forEach((field) => {
      field.groupOptions.name = this.selectedFormBuilderItem.groupOptions.name;
      field.groupOptions.id = newGroupId;
      field.groupOptions.visibleOnPdf = false;
      field.options.visibleOnPdf = false;
      field.fieldValidators = this.selectedFormBuilderItem.fieldValidators;
      field.staticValueLink = this.selectedFormBuilderItem.staticValueLink;
      field.staticValueLinkId = this.selectedFormBuilderItem.staticValueLinkId;
      field.fieldTypeOptions = this.selectedFormBuilderItem.fieldTypeOptions;
      field.formId = this.canvasService.currentCanvasForm.id;
      field.fieldDisclosureOption = this.selectedFormBuilderItem.fieldDisclosureOption;
    });
    this.createFieldsInGroup();

    this.closeDialog.emit(true);
  }

  createFieldsInGroup() {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.fieldManagementService
      .createField(this.selectedFormBuilderItem.fields[0])
      .pipe(
        tap((res: FormBuilderField) => {
          this.selectedFormBuilderItem.fields[0].id = res.id;
          this.selectedFormBuilderItem.fields[0].options.id = res.options.id;
          this.selectedFormBuilderItem.fields[0].fieldDisclosureOption = res.fieldDisclosureOption;
        }),
        concatMap(() => {
          const obs$ = [];
          this.selectedFormBuilderItem.fields.forEach((field: FormBuilderField, index) => {
            field.fieldDisclosureOption = this.selectedFormBuilderItem.fields[0].fieldDisclosureOption;
            if (index > 0) {
              obs$.push(this.fieldManagementService.createField(field));
            }
          });
          return of(obs$);
        })
      )
      .subscribe({
        next: (res: []) => {
          const processedObservable = concat(...res);

          processedObservable.subscribe({
            complete: () => {
              this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
              this.interactiveFormBuilderService.requestAllFields.next(true);
              this.interactiveFormBuilderService.$formAreasUpdated.next(this.selectedFormBuilderItem.fields[0].formAreaId);
            },
            error: () => {
              this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
              this.interactiveFormBuilderService.requestAllFields.next(true);
            },
          });
        },
        error: () => {
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
          this.interactiveFormBuilderService.requestAllFields.next(true);
        },
      });
  }

  formValidityChanged(event: boolean): void {
    this.formValidity = !event;
  }

  attachFileToField(): void {
    this.fieldManagementService
      .addFieldAttachment(this.localSelectedFormBuilderItem.id, this.localSelectedFormBuilderItem.fileToBeAttached)
      .subscribe(() => {
        delete this.localSelectedFormBuilderItem.fileToBeAttached;
      });
  }

  localSelectedFormBuilderItemWasModified(event: FormBuilderItem): void {
    this.selectedFormBuilderItem = event;
  }

  private createPredefinedFieldsInGroup(): CustomFormBuilderField[] {
    const newCustomFieldsFromGroup: CustomFormBuilderField[] = [];
    this.localSelectedFormBuilderItem.fields.forEach((field: FormBuilderField) => {
      if ((this.localSelectedFormBuilderItem as FormBuilderGroup).staticValueLink) {
        field.staticValueLink = (this.localSelectedFormBuilderItem as FormBuilderGroup).staticValueLink;
      }

      if ((this.localSelectedFormBuilderItem as FormBuilderGroup).staticValueLinkId) {
        field.staticValueLinkId = (this.localSelectedFormBuilderItem as FormBuilderGroup).staticValueLinkId;
      }

      if ((this.localSelectedFormBuilderItem as FormBuilderGroup).fieldValidators) {
        field.fieldValidators = (this.localSelectedFormBuilderItem as FormBuilderGroup).fieldValidators;
      }

      if ((this.localSelectedFormBuilderItem as FormBuilderGroup).fieldTypeOptions) {
        field.fieldTypeOptions = (this.localSelectedFormBuilderItem as FormBuilderGroup).fieldTypeOptions;
      }

      if ((this.localSelectedFormBuilderItem as FormBuilderGroup).predefinedFieldDisclosureOption) {
        field.predefinedFieldDisclosureOption = (this.localSelectedFormBuilderItem as FormBuilderField).predefinedFieldDisclosureOption;
      }

      const newCustomFieldFromGroup: CustomFormBuilderField = {
        field: _.cloneDeep(field),
        fieldCategory: this.fieldCategoryService.getCategoryFromSlug(
          this.fieldCategoryForm.controls['fieldCategory'].value ? this.fieldCategoryForm.controls['fieldCategory'].value : 'unassigned'
        ),
      };
      newCustomFieldsFromGroup.push(newCustomFieldFromGroup);
    });
    return newCustomFieldsFromGroup;
  }

  private createPredefinedFieldsInTable(): CustomFormBuilderField[] {
    const customFieldsInTable: CustomFormBuilderField[] = [];
    const numberOfRowsInTable = this.fieldDetailsForm.controls['tableForm'].get('numberOfRows').value;

    this.localSelectedFormBuilderItem.numberOfRows = numberOfRowsInTable;

    this.localSelectedFormBuilderItem.columns.forEach((column: FieldColumn) => {
      const fieldColumn = {
        number: column.number,
        id: column.id,
      };

      for (let fieldRow = 0; fieldRow < numberOfRowsInTable; fieldRow++) {
        switch (true) {
          case this.interactiveFormBuilderService.isTypeGroup(column.type): {
            const newGroupId: number = Math.floor(Math.random() * 9000000000) + 1000000000;

            column.fields.forEach((fieldName: string) => {
              const fieldToAdd: FormBuilderField = this.canvasService.createFieldForTableWithType(
                fieldName,
                column.type,
                this.localSelectedFormBuilderItem as FieldTable,
                fieldRow,
                fieldColumn
              );
              fieldToAdd.groupOptions = {
                name: column.name,
                type: column.type,
                id: newGroupId,
              };
              const customField: CustomFormBuilderField = {
                field: fieldToAdd,
                fieldCategory: this.fieldCategoryService.getCategoryFromSlug(
                  this.fieldCategoryForm.controls['fieldCategory'].value
                    ? this.fieldCategoryForm.controls['fieldCategory'].value
                    : 'unassigned'
                ),
              };
              customFieldsInTable.push(customField);
            });
            break;
          }
          default: {
            const fieldToAdd: FormBuilderField = this.canvasService.createFieldForTableWithType(
              column.name,
              column.type,
              this.localSelectedFormBuilderItem as FieldTable,
              fieldRow,
              fieldColumn
            );
            const customField: CustomFormBuilderField = {
              field: fieldToAdd,
              fieldCategory: this.fieldCategoryService.getCategoryFromSlug(
                this.fieldCategoryForm.controls['fieldCategory'].value
                  ? this.fieldCategoryForm.controls['fieldCategory'].value
                  : 'unassigned'
              ),
            };
            customFieldsInTable.push(customField);
          }
        }
      }
    });
    return customFieldsInTable;
  }

  createCustomField(): void {
    switch (true) {
      case this.interactiveFormBuilderService.isItemGroup(this.localSelectedFormBuilderItem): {
        const predefinedFieldsInGroup: CustomFormBuilderField[] = this.createPredefinedFieldsInGroup();
        this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
        this.predefinedFieldsService.createPredefinedCustomFieldFromGroup(predefinedFieldsInGroup);
        break;
      }
      case this.interactiveFormBuilderService.isItemField(this.localSelectedFormBuilderItem): {
        this.predefinedFieldsService.createPredefinedCustomField(
          this.createCustomFieldBasedOnSelectedValues(this.fieldDetailsForm.controls.fieldForm.value.customFieldLabel)
        );
        break;
      }
      case this.interactiveFormBuilderService.isItemTable(this.localSelectedFormBuilderItem): {
        const predefinedFieldsInTable: CustomFormBuilderField[] = this.createPredefinedFieldsInTable();
        this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
        this.predefinedFieldsService.createPredefinedCustomFieldFromTable(predefinedFieldsInTable);
      }
    }

    this.closeDialog.emit(true);
  }

  createCustomFieldBasedOnSelectedValues(fieldLabel: string): CustomFormBuilderField {
    const category = this.fieldCategoryService.getCategoryFromSlug(
      this.fieldCategoryForm.controls['fieldCategory'].value ? this.fieldCategoryForm.controls['fieldCategory'].value : 'unassigned'
    );
    const newCustomField: CustomFormBuilderField = {} as CustomFormBuilderField;
    newCustomField.field = {} as FormBuilderField;

    newCustomField.field.options = {
      customFieldName: _.camelCase(fieldLabel),
      customFieldLabel: fieldLabel,
      fieldDetails: '',
      visibleOnPdf: true,
    };
    newCustomField.field.fieldType = (this.localSelectedFormBuilderItem as FormBuilderField).fieldType;

    if ((this.localSelectedFormBuilderItem as FormBuilderField).fieldTypeOptions?.length)
      newCustomField.field.fieldTypeOptions = (this.localSelectedFormBuilderItem as FormBuilderField).fieldTypeOptions;
    if ((this.localSelectedFormBuilderItem as FormBuilderField).fieldOptionEntries)
      newCustomField.field.fieldOptionEntries = (this.localSelectedFormBuilderItem as FormBuilderField).fieldOptionEntries;
    if (
      (this.localSelectedFormBuilderItem as FormBuilderField).fieldValidators?.predefinedValidators ||
      (this.localSelectedFormBuilderItem as FormBuilderField).fieldValidators?.manualValidators.length
    )
      newCustomField.field.fieldValidators = (this.localSelectedFormBuilderItem as FormBuilderField).fieldValidators;

    if ((this.localSelectedFormBuilderItem as FormBuilderField).staticValueLink) {
      newCustomField.field.staticValueLink = (this.localSelectedFormBuilderItem as FormBuilderField).staticValueLink;
    }

    if ((this.localSelectedFormBuilderItem as FormBuilderField).staticValueLinkId) {
      newCustomField.field.staticValueLinkId = (this.localSelectedFormBuilderItem as FormBuilderField).staticValueLinkId;
    }

    if ((this.localSelectedFormBuilderItem as FormBuilderField).predefinedFieldDisclosureOption) {
      (this.localSelectedFormBuilderItem as FormBuilderField).predefinedFieldDisclosureOption.disclosureFieldTypeId = 1;
      (this.localSelectedFormBuilderItem as FormBuilderField).predefinedFieldDisclosureOption.warningText = '';
      newCustomField.field.predefinedFieldDisclosureOption = (
        this.localSelectedFormBuilderItem as FormBuilderField
      ).predefinedFieldDisclosureOption;
    }

    if ((this.localSelectedFormBuilderItem as FormBuilderField).fieldDisclosureOption) {
      (this.localSelectedFormBuilderItem as FormBuilderField).fieldDisclosureOption.disclosureFieldTypeId = 1;
      (this.localSelectedFormBuilderItem as FormBuilderField).fieldDisclosureOption.warningText = '';
    }

    newCustomField.fieldCategory = category;

    return newCustomField;
  }

  optionsWereAddedToTheField(event: any): void {
    (this.localSelectedFormBuilderItem as FormBuilderField).fieldTypeOptions = event;
  }

  entriesWereAddedToTheField(event: any): void {
    (this.localSelectedFormBuilderItem as FormBuilderField).fieldOptionEntries = event;
  }

  private saveFieldInTableChanges(field: FormBuilderField): void {
    const fieldsFromTable = this.canvasService.getFieldsFromTable(field.table.type.id);

    const columnIndex = field.table.type.columns.findIndex((column: FieldColumn) => column.id === field.table?.position?.column?.id);
    fieldsFromTable.forEach((fieldFromTable: FormBuilderField) => {
      if (fieldFromTable.table?.position.column.id === field.table.position.column.id) {
        fieldFromTable.fieldValidators = field.fieldValidators;
        fieldFromTable.options.customFieldLabel = field.options.customFieldLabel;
        fieldFromTable.options.customFieldName = field.options.customFieldName;
        fieldFromTable.options.fieldDetails = field.options.fieldDetails;
        fieldFromTable.staticValueLink = field.staticValueLink;
        fieldFromTable.staticValueLinkId = field.staticValueLinkId;
        fieldFromTable.fieldTypeOptions = field.fieldTypeOptions;
        fieldFromTable.fieldOptionEntries = field.fieldOptionEntries;
      }

      fieldFromTable.table.type.columns[columnIndex].name = field.options.customFieldLabel;
      this.canvasService.updateFieldInListOfFields(fieldFromTable);
    });
  }

  private saveGroupChanges(group: FormBuilderGroup) {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);

    group.fields.forEach((field: FormBuilderField) => {
      field.fieldValidators = group.fieldValidators;
      field.groupOptions.name = group.groupOptions.name;
      field.staticValueLink = group.staticValueLink;
      field.staticValueLinkId = group.staticValueLinkId;
      field.fieldTypeOptions = group.fieldTypeOptions;
      field.fieldDisclosureOption = group.fieldDisclosureOption;

      this.canvasService.updateFieldInListOfFields(field);

      this.fieldManagementService.updateField(field).subscribe(() => {
        this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        this.canvasService.getAllFormFieldsDebounced();
      });
    });

    this.interactiveFormBuilderService.$formAreasUpdated.next(group.fields[0].formAreaId);
  }

  private saveGroupInTableChanges(group: FormBuilderGroup) {
    const fieldsFromTable = this.canvasService.getFieldsFromTable(group.fields[0].table.type.id);
    const fieldsFromGroupsInSameColumn = fieldsFromTable.filter(
      (fieldFromTable: FormBuilderField) => fieldFromTable.table.position.column.id === group.fields[0].table.position.column.id
    );

    fieldsFromGroupsInSameColumn.forEach((fieldInGroupFromTable: FormBuilderField) => {
      fieldInGroupFromTable.fieldValidators = group.fieldValidators;
      fieldInGroupFromTable.groupOptions.name = group.groupOptions.name;
      fieldInGroupFromTable.staticValueLink = group.staticValueLink;
      fieldInGroupFromTable.staticValueLinkId = group.staticValueLinkId;
      fieldInGroupFromTable.fieldTypeOptions = group.fieldTypeOptions;
      fieldInGroupFromTable.fieldOptionEntries = group.fieldOptionEntries;
    });

    const columnIndex = fieldsFromGroupsInSameColumn[0].table.type.columns.findIndex(
      (column: FieldColumn) => column.id === fieldsFromGroupsInSameColumn[0].table?.position?.column?.id
    );
    fieldsFromTable.forEach((fieldFromTable: FormBuilderField) => {
      fieldFromTable.table.type.columns[columnIndex].name = group.groupOptions.name;
      this.canvasService.updateFieldInListOfFields(fieldFromTable);
    });
    this.interactiveFormBuilderService.selectedTable.next(fieldsFromGroupsInSameColumn[0].table.type);
  }

  saveChanges(element: FormBuilderItem): void {
    switch (true) {
      case !!(element as FormBuilderField).table?.type ||
        !!(element.fields?.length && (element as FormBuilderGroup)?.fields[0]?.table?.type): {
        if (!(element as FormBuilderField).groupOptions) {
          this.saveFieldInTableChanges(element as FormBuilderField);
        } else {
          this.saveGroupInTableChanges(element as FormBuilderGroup);
        }
        break;
      }
      case !!this.interactiveFormBuilderService.isFormBuilderItemGroup(element):
        this.saveGroupChanges(element as FormBuilderGroup);
        break;
      case !this.interactiveFormBuilderService.isFormBuilderItemGroup(element) && !element.table?.type:
        (element as FormBuilderField).options = this.extractObjectFromFormValues<FormBuilderFieldOptions>(
          <FormGroup>this.fieldDetailsForm.controls['fieldForm'],
          (element as FormBuilderField).options
        );

        if (this.isInFieldBuilder) {
          // this.interactiveFormBuilderService.updateFieldOptions(element as FormBuilderField);
        }
        if ((element as FormBuilderField).id) {
          this.interactiveFormBuilderService.updateField(element as FormBuilderField);
        }

        this.updateFilesOnField();
        break;
    }
    this.closeDialog.emit(true);
  }

  saveColumnChanges(element: FormBuilderItem): void {
    switch (true) {
      // FIELDS
      case !element.fields: {
        const predefinedCustomFieldsInSameTable = this.predefinedFieldsService.predefinedFields.filter(
          (predefinedCustomField: CustomFormBuilderField) =>
            predefinedCustomField.field.table?.type?.id && predefinedCustomField.field.table?.type?.id === element.table?.type?.id
        );

        this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
        predefinedCustomFieldsInSameTable.forEach((predefinedCustomField: CustomFormBuilderField) => {
          const fieldsAreInSameColumn = predefinedCustomField.field.table?.position?.column?.id === element.table?.position?.column?.id;
          if (fieldsAreInSameColumn) {
            const fieldId = predefinedCustomField.field.id;
            const fieldOptions = _.cloneDeep(predefinedCustomField.field.options);
            const tablePosition = _.cloneDeep(predefinedCustomField.field.table?.position);

            predefinedCustomField.field = element as FormBuilderField;
            predefinedCustomField.field.id = fieldId;
            predefinedCustomField.field.options = fieldOptions;
            predefinedCustomField.field.table.position = tablePosition;
          }

          this.predefinedFieldsService.updatePredefinedCustomField(predefinedCustomField);
        });
        break;
      }

      // GROUPS
      case !!element.fields: {
        const predefinedCustomFieldsInSameTable = this.predefinedFieldsService.predefinedFields.filter(
          (predefinedCustomField: CustomFormBuilderField) =>
            predefinedCustomField.field.table?.type?.id && predefinedCustomField.field.table?.type?.id === element.fields[0].table?.type?.id
        );

        this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
        predefinedCustomFieldsInSameTable.forEach((predefinedCustomField: CustomFormBuilderField) => {
          const fieldsAreInSameColumn =
            predefinedCustomField.field.table?.position?.column?.id === element.fields[0].table?.position?.column?.id;
          if (fieldsAreInSameColumn) {
            predefinedCustomField.field.staticValueLink = element.staticValueLink;
            predefinedCustomField.field.staticValueLinkId = element.staticValueLinkId;
            predefinedCustomField.field.fieldValidators = element.fieldValidators;
          }

          this.predefinedFieldsService.updatePredefinedCustomField(predefinedCustomField);
        });
        break;
      }
    }
    this.closeDialog.emit(true);
  }

  updateFilesOnField(): void {
    if (this.localSelectedFormBuilderItem.fileToBeDeleted) {
      this.deleteFileFromField(this.localSelectedFormBuilderItem).subscribe(() => {
        if (this.localSelectedFormBuilderItem.fileToBeAttached) {
          this.attachFileToField();
        }
      });
    } else {
      if (this.localSelectedFormBuilderItem.fileToBeAttached) {
        this.attachFileToField();
      }
    }
  }

  deleteFileFromField(element: FormBuilderItem) {
    return this.fieldManagementService.deleteFieldAttachments(element.id);
  }

  updateTableColumnName(table: FieldTable, field: FormBuilderField, newName: string): void {
    table.columns[field.table.position.column.number].name = newName;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
    this.interactiveFormBuilderService.fieldWasSelected.next(null);
  }
}
