import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ENV } from '@iapplication2/interfaces';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  exports: [TranslateModule],
  providers: [{ provide: TranslateStore }],
})
export class SharedTranslateModule {}

export function createTranslateLoader(handler: HttpBackend, config: ENV) {
  const http = new HttpClient(handler);
  const urlPrefix = config?.translationsUrl ? config.translationsUrl : './assets/i18n/ui/';
  const dynamicFiles: ITranslationResource[] = [
    { prefix: urlPrefix, suffix: '/fields.json' },
    { prefix: urlPrefix, suffix: '/forms.json' },
    { prefix: urlPrefix, suffix: '/products.json' },
    { prefix: urlPrefix, suffix: '/applications.json' },
  ];
  return new MultiTranslateHttpLoader(http, [{ prefix: urlPrefix, suffix: '/static.json' }, ...dynamicFiles]);
}
export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      return params.interpolateParams['Default'] || params.key;
    }
    return params.key;
  }
}
