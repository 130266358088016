import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'iapplication2-client-management-page',
  templateUrl: './client-management-page.component.html',
  styleUrls: ['./client-management-page.component.scss'],
})
export class ClientManagementPageComponent {
  constructor(private router: Router) {}

  createClient() {
    const newClientRoute: string[] = ['/clients/new'];
    this.router.navigate(newClientRoute);
  }
}
