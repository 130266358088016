import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArea, FormAreaWidthOptions, Themes } from '@iapplication2/interfaces';
import { DarkThemeService, InteractiveFormBuilderService } from '@iapplication2/services';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'iapplication2-form-builder-area-header',
  templateUrl: './form-builder-area-header.component.html',
  styleUrls: ['./form-builder-area-header.component.scss'],
})
export class FormBuilderAreaHeaderComponent implements OnInit, OnDestroy {
  @Input() formBuilderArea: FormArea;
  initialFormBuilderArea: FormArea;
  @Output() deleteFormArea = new EventEmitter<FormArea>();
  items: MenuItem[];
  unsubscribe: Subject<unknown> = new Subject();
  isDarkTheme: boolean;

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private darkThemeService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.darkThemeService.themeChanged.subscribe(value => {
      this.isDarkTheme = value === Themes.DARK;
    });
    this.createFormBuilderAreaMenu();
    this.initialFormBuilderArea = _.cloneDeep(this.formBuilderArea);
  }

  private createFormBuilderAreaMenu(): void {
    this.items = [
      {
        label: 'Area options',
        items: [
          {
            label: 'Make area full width',
            icon: 'pi pi-window-maximize',
          },
          {
            label: 'Make area half width',
            icon: 'pi pi-window-minimize',
          },
          {
            label: 'Delete area',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
  }

  onDeactivateInplace() {
    if (this.formBuilderArea !== this.initialFormBuilderArea) {
      const areas = [];
      areas.push(this.formBuilderArea);
      this.interactiveFormBuilderService
        .updateSectionAreas(areas)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: FormArea[]) => {
          this.initialFormBuilderArea = res[0];
        });
    }
  }

  toggleMenu(item: FormArea, menu: any, ev: any): void {
    const makeFullWidthMenuItem = this.items[0].items[0];
    const makeHalfWidthMenuItem = this.items[0].items[1];
    const deleteMenuItem = this.items[0].items[2];
    makeFullWidthMenuItem.disabled = item.width === 'full' ? true : false;
    makeHalfWidthMenuItem.disabled = item.width === 'half' ? true : false;
    makeFullWidthMenuItem.command = () => this.changeAreaWidth(item, FormAreaWidthOptions.FULL);
    makeHalfWidthMenuItem.command = () => this.changeAreaWidth(item, FormAreaWidthOptions.HALF);
    deleteMenuItem.command = () => this.deleteArea(item);
    menu.toggle(ev);
  }

  changeAreaWidth(formArea: FormArea, width: FormAreaWidthOptions): void {
    formArea.width = width;
    const areas = [];
    areas.push(formArea);
    this.interactiveFormBuilderService.updateSectionAreas(areas).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  deleteArea(formArea: FormArea): void {
    this.deleteFormArea.emit(formArea);
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
