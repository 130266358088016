import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ConditionType, FieldDisplayCondition, FieldType, FormBuilderField, FormBuilderItem } from '@iapplication2/interfaces';
import { ConditionalDisplayService, InteractiveFormBuilderService } from '@iapplication2/services';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'iapplication2-display-conditions-modal',
  templateUrl: './display-conditions-modal.component.html',
  styleUrls: ['./display-conditions-modal.component.scss'],
})
export class DisplayConditionsModalComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<unknown> = new Subject();
  item: FormBuilderItem;
  conditionsForm: FormArray = new FormArray([]);
  listOfFields: FormBuilderItem[];
  originalListOfFields: FormBuilderItem[];
  formGroupType = FormGroup;
  formId: number;
  conditionTypeObject: {
    [key: number]: ConditionType[];
  } = {};
  conditions: FieldDisplayCondition[] = [];
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

  loadingExistingConditions = true;

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private conditionalDisplayService: ConditionalDisplayService
  ) {}

  ngOnInit(): void {
    const observables = [
      this.interactiveFormBuilderService.selectedItemForDisplayConditions,
      this.interactiveFormBuilderService.selectedFormId,
    ];
    forkJoin(observables)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([selectedItem, formId]: [FormBuilderItem, number]) => {
        this.item = selectedItem;
        this.formId = formId;
        if (this.formId) {
          this.getAllFieldsByFormId(this.formId);
        }
      });
  }

  getAllFieldsByFormId(formId: number) {
    this.conditionalDisplayService
      .getAllFieldsByFormId(formId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FormBuilderItem[]) => {
        this.listOfFields = res;
        this.originalListOfFields = res;
        let listOfGroups = this.listOfFields.filter((item) => item.groupOptions);
        const listOfFields = this.listOfFields.filter((item) => !item.groupOptions);
        listOfGroups = _.uniqBy(listOfGroups, function (e) {
          return e.groupOptions?.id;
        });
        this.listOfFields = listOfFields.concat(listOfGroups);

        this.setExistingConditions(this.item);
        this.loadingExistingConditions = false;
      });
  }

  changedFieldSelection(condition) {
    const fieldType: FieldType = condition.get('conditionFieldId').value?.fieldType;
    condition.get('conditionType').setValue(null);
    condition.get('value').setValue(null);
    if (fieldType?.id && !Object.keys(this.conditionTypeObject).includes(fieldType?.id.toString())) {
      this.conditionalDisplayService
        .getConditionTypesByFieldTypeId(fieldType?.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: ConditionType[]) => {
          res.map((conditionType) => (conditionType.displayLabel = this.getDisplayLabelForCondition(conditionType)));
          this.conditionTypeObject[fieldType.id.toString()] = res;
        });
    }
  }

  addCondition() {
    const group: FormGroup = new FormGroup({});
    group.addControl('conditionFieldId', new FormControl(null));
    group.addControl('value', new FormControl(''));
    group.addControl('conditionType', new FormControl(''));
    this.conditionsForm.controls.push(group);
  }

  removeCondition(index) {
    this.conditionsForm.controls.splice(index, 1);
  }

  private setExistingConditions(item: FormBuilderItem): void {
    const conditions = item.conditions ? item.conditions : item.fields[0].conditions ? item.fields[0].conditions : null;

    if (conditions) {
      conditions.forEach((condition: FieldDisplayCondition, index) => {
        condition.conditionType.displayLabel = this.getDisplayLabelForCondition(condition.conditionType);
        this.addCondition();
        const field = this.listOfFields.filter((field: FormBuilderField) => {
          if (field.groupOptions?.id) {
            return field.groupOptions.id.toString() === condition.conditionFieldId.toString();
          }
          if (field.id) {
            return field.id.toString() === condition.conditionFieldId.toString();
          }
          return false;
        })[0];

        this.conditionsForm.controls[index].get('conditionFieldId').setValue(field);

        this.setConditionTypeForExistingCondition(this.conditionsForm.controls[index], condition.conditionType);

        this.conditionsForm.controls[index].get('value').setValue(condition.value);
      });
    }
  }

  private setConditionTypeForExistingCondition(condition: AbstractControl, existingConditionType: ConditionType): void {
    const fieldType: FieldType = condition.get('conditionFieldId').value?.fieldType;

    if (fieldType?.id && !Object.keys(this.conditionTypeObject).includes(fieldType?.id.toString())) {
      this.conditionalDisplayService
        .getConditionTypesByFieldTypeId(fieldType?.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: ConditionType[]) => {
          res.map((conditionType) => (conditionType.displayLabel = this.getDisplayLabelForCondition(conditionType)));
          this.conditionTypeObject[fieldType?.id.toString()] = res;

          condition.get('conditionType').setValue(existingConditionType);
        });
    } else {
      condition.get('conditionType').setValue(existingConditionType);
    }
  }

  getDisplayLabelForCondition(conditionType: ConditionType): string {
    return conditionType.type.replace(/_/g, ' ').replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  }

  saveConditions() {
    this.conditions = [];

    this.conditionsForm.controls.forEach((control) => {
      const selectedConditionField = control.get('conditionFieldId').value;
      if (selectedConditionField) {
        const conditionType: ConditionType = {
          id: control.get('conditionType').value.id,
        };
        this.conditions.push({
          conditionFieldId: selectedConditionField.groupOptions?.id
            ? parseInt(selectedConditionField.groupOptions?.id)
            : parseInt(selectedConditionField?.id),
          conditionType: conditionType,
          value: control.get('value').value?.toString(),
        });
      }
    });

    switch (true) {
      case this.interactiveFormBuilderService.isItemField(this.item) || this.interactiveFormBuilderService.isItemStaticText(this.item):
        this.conditionalDisplayService.saveConditions(this.item.id, this.conditions).subscribe();
        break;
      case this.interactiveFormBuilderService.isItemGroup(this.item):
        this.item.fields?.forEach((field) => {
          this.conditionalDisplayService.saveConditions(field.id, this.conditions).subscribe();
        });
        break;
    }
    this.closeDialog.emit(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
