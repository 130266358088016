/* eslint-disable @typescript-eslint/no-inferrable-types */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormSection, SectionType, Severity, Themes } from '@iapplication2/interfaces';
import { DarkThemeService, InteractiveFormBuilderService } from '@iapplication2/services';
import * as _ from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-form-sections-manager',
  templateUrl: './form-sections-manager.component.html',
  styleUrls: ['./form-sections-manager.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class FormSectionsManagerComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() listOfSections: FormSection[];
  @Input() formId: number;
  @ViewChild('cdkDroplistElement') cdkDroplistElement: ElementRef;
  formSectionsList: FormSection[];
  initialFormSectionsList: FormSection[];
  displayRevertButton: boolean = false;
  deletedSections: FormSection[] = [];
  unsubscribe: Subject<unknown> = new Subject();
  sectionTypes: SectionType[] = [];
  isDarkTheme: boolean;
  scrollableAreaHeight = 0;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private darkThemeService: DarkThemeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.darkThemeService.themeChanged.subscribe((value) => {
      this.isDarkTheme = value === Themes.DARK;
    });
    this.listOfSections.forEach((section: FormSection) => {
      section.hasSectionType = !!section.type.id;
    });
    this.interactiveFormBuilderService
      .getFormSectionsTypeList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: SectionType[]) => {
        this.sectionTypes = res;
      });
    this.formSectionsList = _.cloneDeep(this.listOfSections);
    this.initialFormSectionsList = _.cloneDeep(this.listOfSections);
    this.interactiveFormBuilderService.saveSectionManagement.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.formSectionsList.some((section) => section.title === '' || section.title === null || section.title === undefined)) {
        this.messageService.clear();
        this.messageService.add({
          severity: Severity.ERROR,
          detail: 'Please make sure all sections have a title!',
        });
      } else {
        this.formSectionsList.forEach((section, index) => {
          section.sectionFormPosition = index + 1;
        });
        this.interactiveFormBuilderService.modifiedSectionList.next(this.formSectionsList);
        if (this.deletedSections.length > 0) {
          this.interactiveFormBuilderService.deletedSectionList.next(this.deletedSections);
        }
      }
    });
  }

  ngAfterViewChecked(): void {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // These are all needed as the cdkScroll requires a height specified in pixels
    this.scrollableAreaHeight =
      this.cdkDroplistElement.nativeElement.scrollHeight > 700 ? 700 : this.cdkDroplistElement.nativeElement.scrollHeight;

    this.cdr.detectChanges();
  }

  drop(ev: CdkDragDrop<string>): void {
    moveItemInArray(this.formSectionsList, ev.previousIndex, ev.currentIndex);
  }

  changedSectionTypeCheckbox(event, formSection: FormSection) {
    if (event.checked === false) {
      formSection.type = null;
    }
  }

  addNewSection() {
    this.formSectionsList.push({
      //There is a bug where the formId shows up as string even though it is defined as a number
      formId: parseInt(this.formId.toString()),
      title: '',
      id: null,
    });
  }

  removeSection(index, ev) {
    if (this.formSectionsList.length > 1) {
      this.confirmationService.confirm({
        target: ev.target,
        message: 'Deleting a section is irreversible. Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deletedSections.push(this.formSectionsList[index]);
          this.formSectionsList.splice(index, 1);
          this.displayRevertButton = true;
        },
      });
    } else {
      this.messageService.clear();
      this.messageService.add({
        severity: Severity.ERROR,
        detail: 'You can not have a form that has no sections!',
      });
    }
  }

  restoreItems() {
    this.formSectionsList = _.cloneDeep(this.initialFormSectionsList);
    this.displayRevertButton = false;
    this.messageService.clear();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
