import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@iapplication2/app-config';
import {
  Application,
  ApplicationStatusOptions,
  ENV,
  envType,
  Language,
  TableCol,
  TablesList,
  UserSettings,
} from '@iapplication2/interfaces';
import { ApplicationsManagementService, ApplicationsProcessService, UserSettingsService, UtilsService } from '@iapplication2/services';
import { LazyLoadEvent, MenuItem, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iapplication2-applications-table',
  templateUrl: './applications-table.component.html',
  styleUrls: ['./applications-table.component.scss'],
})
export class ApplicationsTableComponent implements OnInit, OnDestroy {
  @ViewChildren('unfrozenRows') unfrozenRows;
  @ViewChildren('frozenRows') frozenRows;
  @ViewChild('dt') table: Table;
  @ViewChild('activePanel') activePanel;

  private unsubscribe: Subject<unknown> = new Subject();

  applications: Application[];
  userSettings: UserSettings;
  scrollableCols: TableCol[];
  selectedCols: TableCol[];
  page = 0;
  rows = 10;
  previousTableEvent: any;
  totalRecords: number;
  loading = true;
  matchModeOptions: SelectItem[];
  items: MenuItem[][] = [];
  isEnvDevelop: boolean;
  rowsPerPage: string[] = ['10', '20', '50', '100'];
  totalPages = [];
  languages: Language[];
  tabIndex: number;

  constructor(
    public utilsService: UtilsService,
    private router: Router,
    private applicationManagementService: ApplicationsManagementService,
    private userSettingsService: UserSettingsService,
    private applicationsProcessService: ApplicationsProcessService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit(): void {
    this.scrollableCols = [
      {
        field: 'id',
        header: 'Application Id',
        width: 200,
      },
      {
        field: 'client',
        header: 'Client',
        width: 175,
      },
      {
        field: 'age',
        header: 'Age',
        width: 125,
      },
      {
        field: 'date',
        header: 'Date',
        width: 175,
      },
      {
        field: 'time',
        header: 'Time',
        width: 175,
      },
      {
        field: 'products',
        header: 'Products',
        width: 275,
      },
      {
        field: 'phone',
        header: 'Phone',
        width: 150,
      },
      {
        field: 'premium',
        header: 'Premium',
        width: 200,
      },
      {
        field: 'status',
        header: 'Status',
        width: 200,
      },
    ];

    this.selectedCols = this.scrollableCols;
    this.getUserTableColumns();
    this.getLanguages();
  }

  private getLanguages() {
    this.utilsService
      .getLanguages()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: Language[]) => {
        if (res) {
          this.languages = res;
          this.tabIndex = res.findIndex((lang) => lang.code === this.translateService.currentLang);
          this.changeApplicationsListOnLanguageChange();
        }
      });
  }

  changeApplicationsListOnLanguageChange() {
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.tabIndex = this.languages.findIndex((lang) => lang.code === res.lang);
      this.loadApplicationsBasedOnLanguage();
    });
  }

  loadApplicationsBasedOnLanguage() {
    this.translateService.use(this.languages[this.tabIndex].code);
    this.loadApplications();
  }

  checkIfEnvIsDevelop() {
    this.isEnvDevelop = this.appConfig.environmentType === envType.DEVELOP;
  }

  rowsChange(event) {
    if (event.value) {
      this.loadApplications(this.previousTableEvent, undefined, event.value);
    }
  }

  setCurrentPage(event) {
    if (event.value) {
      const first = event.value * Number(this.table.rows) - Number(this.table.rows);
      this.loadApplications(undefined, undefined, undefined, Math.round(first));
    }
  }

  loadApplications(event?: LazyLoadEvent, globalSearchValue?: string, rowsNumber?: string, firstItemOnPage?: number) {
    let rows: string;
    if (event) {
      this.previousTableEvent = event;
    }

    if (rowsNumber) {
      rows = rowsNumber;
      this.table.rows = Number(rowsNumber);
      this.previousTableEvent = { ...this.previousTableEvent, rows: rowsNumber };
    } else {
      rows = this.previousTableEvent?.rows?.toString();
    }

    if (firstItemOnPage !== undefined) {
      this.table.first = firstItemOnPage;
      this.table.firstChange.emit(this.table.first);
      this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
      this.previousTableEvent = { ...this.previousTableEvent, first: firstItemOnPage };
    }

    this.page = this.previousTableEvent.first / this.previousTableEvent.rows + 1;
    const sortField: string = this.previousTableEvent.sortField;
    const sortOder: string = this.previousTableEvent.sortOrder === -1 ? 'ASC' : 'DESC';
    this.loading = true;

    this.applicationManagementService
      .getApplications(this.page, rows, sortField, sortOder, globalSearchValue, this.languages[this.tabIndex].id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.items = [];
        this.applications = res.data as Application[];
        this.applications.forEach((application) => {
          application.totalPremium = this.getPremium(application);
        })

        this.totalRecords = res.count;
        this.loading = false;
        window.setTimeout(() => {
          this.utilsService.syncronizeRowHeightForDataTable(this.frozenRows, this.unfrozenRows);
        }, 0);
        this.getPages();
      });
  }

  getPages() {
    const pagesTotal = Math.ceil(this.totalRecords / this.previousTableEvent.rows + 1);
    this.totalPages = [];
    if (pagesTotal) {
      const pages = [...Array(pagesTotal).keys()];
      pages.forEach((page) => {
        this.totalPages.push({
          value: page,
        });
      });
    }
  }

  saveApplication(application) {
    this.applicationsProcessService.updateApplication(application.id, application).subscribe();
  }

  createApplication() {
    this.applicationsProcessService
      .createNewApplicationId()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((id: { applicationId: number }) => {
        this.router.navigate(['/application/edit/' + id.applicationId]);
      });
  }

  navigateToEdit(routerLink) {
    const editApplicationRoute: string[] = [routerLink];
    this.router.navigate(editApplicationRoute);
  }

  filterGlobal(value: string) {
    this.loadApplications(null, value);
  }

  getAge(date: string): string {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }

  onClickMenu(application) {
    const contextId = application.id;
    if (this.items[contextId]) {
      return this.items[contextId];
    }
    const items = [
      {
        label: 'Application options',
        items: [
          {
            label: 'Edit application',
            icon: 'pi pi-pencil',
            command: () => {
              this.navigateToEdit(`/application/edit/${contextId}`);
            },
          },
          {
            label: 'View application',
            icon: 'pi pi-eye',
            routerLink: `application/${contextId}`,
          }
        ],
      },
    ];

    /* removes "edit" option if the status of the application is other than Active */
    if (application?.status?.name && application?.status?.name !== ApplicationStatusOptions.ACTIVE) {
      items[0].items.splice(0, 1);
    }

    this.items[contextId] = items;
    return this.items[contextId];
  }

  toggleMenu(application: Application, menu, ev) {
    ev.preventDefault();
    // this.items[0].items[0].routerLink = `${application.id}`;
    menu.toggle(ev);
  }

  getUserTableColumns() {
    this.userSettingsService
      .getUserSeetingsByUserId(1234)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.userSettings = res[0];
          const tableColsForUser = this.userSettings.selectedTableColumns.filter((table) => table.name === TablesList.APPLICATIONS)[0];
          if (tableColsForUser) {
            this.selectedCols = tableColsForUser.columns;
          }
        }
      });
  }

  selectedColsChanged() {
    this.updateUserSettingsWithSelectedTableCols();
  }

  updateUserSettingsWithSelectedTableCols() {
    const tableFromUserSettings = this.userSettings.selectedTableColumns.filter(
      (selectedTable) => selectedTable.name === TablesList.APPLICATIONS
    )[0];
    if (tableFromUserSettings) {
      tableFromUserSettings.columns = this.selectedCols;
    } else {
      this.userSettings.selectedTableColumns.push({
        name: 'applications',
        columns: this.selectedCols,
      });
    }
    this.userSettingsService.updateUserSettingsByUserId(this.userSettings);
  }

  getPremium(application) {
    let premium = 0;

    application?.products?.forEach((product) => {
      premium = premium + Number(product?.monthlyPremium);
    });

    return application.currency?.symbol + ' ' + premium?.toFixed(2) + '/m';
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
