export enum ValidatorTypeEnum {
  REQUIRED = 'required',
  DISCLOSURE_REQUIRED_PLAY = 'disclosureRequiredPlay',
  NAME = 'name',
  MIN_LENGTH = 'hasMinLength',
  MAX_LENGTH = 'hasMaxLength',
  LENGTH_BETWEEN = 'hasLengthBetween',
  FIXED_LENGTH = 'hasFixedLength',
  EMAIL = 'email',
  DOES_NOT_CONTAIN_VALUE = 'doesNotContainValue',
  CONTAINS_VALUE = 'containsValue',
}
