import { FormBuilderFieldTypeType } from './form-builder-field-type-option.model';

export interface FieldType {
  id?: number;
  type: FormBuilderFieldTypeType;
  label: FieldTypeLabelOptions;
  dragToDraw: boolean;
  rgbaColorForRectangle: string;
  canBeDrawn: boolean;
}

export enum FieldTypeLabelOptions {
  FREE_TEXT = 'Free Text',
  CHECKBOX = 'Checkbox',
  CHECKBOX_GROUP = 'Checkbox group',
  RADIO_GROUP = 'Radio group',
  DROPDOWN = 'Dropdown',
  SIGNATURE = 'Signature',
  DATE = 'Date',
  NUMBER = 'Number',
  TABLE = 'Table',
  STATIC_TEXT = 'Static text',
  DISCLOSURE = 'Disclosure Question',
  DISCLAIMER = 'Disclaimer Text',
}
