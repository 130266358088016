<div fxLayout="column">
  <div class="p-2" fxFlex fxLayout="column" fxLayoutAlign="start start">
    <div fxLayout="row" [style]="'width: 100%'">
      <button
        pButton
        pRipple
        class="p-button-secondary"
        label="Add new area"
        (click)="addFormAreaMenu.toggle($event)"
        [style]="'flex: 1'"
      ></button>
      <button
        pButton
        pRipple
        class="p-button-secondary ml-2"
        label="Add new item"
        (click)="openAddNewItemOverlayPannel($event)"
        [style]="'flex: 1'"
      ></button>
    </div>
    <p-overlayPanel #overlayPanel [dismissable]="false" [showCloseIcon]="true" styleClass="overlayPanel-fieldSelection fieldSidebar" #op>
      <ng-template pTemplate>
        <div class="overlayPanel-container">
          <iapplication2-fields-sidebar
            [action]="sidebarAction.FORM"
            *ngIf="!selectedField"
            [showOnlyDefaultFieldTypes]="false"
            (itemSelected)="itemSelected($event)"
          >
          </iapplication2-fields-sidebar>
        </div>
      </ng-template>
    </p-overlayPanel>
    <p-overlayPanel
      #overlayPanel
      [dismissable]="false"
      [showCloseIcon]="true"
      styleClass="overlayPanel-fieldSelection"
      #staticText
      appendTo="body"
    >
    </p-overlayPanel>
    <p-menu #addFormAreaMenu [popup]="true" appendTo="body" [model]="addFormAreaItems"></p-menu>
    <div class="mt-3" fxLayout="row" fxLayoutAlign="start center">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">Section</span>
        <p-dropdown
          [options]="formSectionsList"
          optionLabel="title"
          [(ngModel)]="selectedFormSection"
          placeholder="Chose a section"
          (onChange)="changedSection()"
        ></p-dropdown>
        <button pButton label="Manage sections" class="p-button-secondary" (click)="openSectionManagerModal()"></button>
      </div>
    </div>
  </div>
  <p-dialog
    header="Section manager"
    [(visible)]="displaySectionManager"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ width: '50vw' }"
    class="sectionManager-dialog"
  >
    <iapplication2-form-sections-manager [listOfSections]="formSectionsList" [formId]="formId"></iapplication2-form-sections-manager>
    <ng-template pTemplate="footer">
      <button pButton label="Save changes" (click)="saveSectionManagement()"></button>
    </ng-template>
  </p-dialog>
</div>
