import {
  Directive,
  HostListener,
  Input,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

export const keyboardKey = {
  COMMA: ',',
  DOT: '.',
  ZERO: '0'
}

@Directive({
  selector: '[iapplication2AmountFormat]',
})
export class AmountFormatDirective {
  private control: AbstractControl;

  @Input() set iapplication2AmountFormat(control: AbstractControl) {
    this.control = control;
  }

  @HostListener('input', ['$event'])
  onInputEvent(event: InputEvent) {
    this.checkIfAllowed(event);
  }

  private checkIfAllowed(event: InputEvent) {
    const amount: string = this.control?.value;
    const lastCharacter = amount?.substr(amount.length - 1);

    /* The amount can't start with dot, comma or 0 */
    if (amount === keyboardKey.COMMA || amount === keyboardKey.DOT || amount === keyboardKey.ZERO) {
      this.control?.setValue('');
      return;
    }

    /* The user is allowed to type only one comma as decimal delimitation */
    if (
      amount &&
      amount?.length &&
      lastCharacter === keyboardKey.COMMA &&
      amount.split(',').length > 2
    ) {
      this.control?.setValue(this.control?.value.replace(/.$/, ''));
      return;
    }

    /* The user is allowed to type only two numbers after the comma */
    if (
      // If there is a comma in the input, and there are already 2 digits after it
      amount.indexOf(',') > -1 &&
      amount.split(',')[1]?.length > 2 &&
      // And the user types after the comma and wants to add another digit (would be the 3rd one)
      amount.indexOf(',') < (event.target as any)?.selectionStart &&
      // This extra check makes sure the user wants to type in a 3rd digit and he is not just replacing
      // one of the 2 digits after the comma
      (event.target as any)?.selectionStart === (event.target as any)?.selectionEnd
    ) {
      this.control?.setValue(this.control?.value.replace(/.$/, ''));
      return;
    }

    /* Allowed only comma and digits since the dots are automatically appended */
    if (
      lastCharacter &&
      !(
        lastCharacter === keyboardKey.COMMA ||
        (this.isNumber(lastCharacter) && +lastCharacter >= 0 && +lastCharacter <= 9)
      )
    ) {
      this.control?.setValue(this.control?.value.replace(/.$/, ''));
      return;
    }
  }

  isNumber(value: string | number): boolean {
    return value !== null && value !== '' && !isNaN(Number(value.toString()));
  }
}
