export interface AttachedFile {
  id: number;
  fieldId?: number;
  fileName: string;
  applicationId?: number;
  s3Link?: string;
  fileType?: string;
}

export interface FieldAttachedImage {
  fileName: string;
  s3Key: string;
  id?: number;
  fieldId?: string;
}
