import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Advisor, PaymentFrequencyEnum, PaymentMethods, Product, SummaryPageInfo } from '@iapplication2/interfaces';
import { AdvisorsService, ApplicationsProcessService, DatesService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-summary-info',
  templateUrl: './summary-info.component.html',
  styleUrls: ['./summary-info.component.scss'],
})
export class SummaryInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() summaryPageInfo: SummaryPageInfo;
  @Input() upsellProducts: Product[];

  paymentFrequencyEnum = PaymentFrequencyEnum;
  advisor: Advisor;
  displayModal: boolean;
  totalMonthlyPremium = 0;
  totalYearlyPremium = 0;
  unsubscribe: Subject<any> = new Subject();
  parsedWithdrawalDate: string;
  realAge: number;
  ageNearest: number;
  PaymentMethods = PaymentMethods;

  constructor(
    private advisorsService: AdvisorsService,
    private datesService: DatesService,
    public applicationsProcessService: ApplicationsProcessService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.summaryPageInfo && changes.summaryPageInfo.currentValue) {
      this.summaryPageInfo = changes.summaryPageInfo.currentValue;
      this.calculateAges(this.summaryPageInfo?.client?.birthDate);

      // TODO: upsell option temporary removed
      // if (changes?.upsellProducts?.currentValue) {
      //   this.upsellProducts = changes.upsellProducts.currentValue;
      // }

      this.calculateTotalPremiums();
    }
  }

  ngOnInit(): void {
    this.parseWithdrawalDate();
    this.getAdvisorById();

    if (this.summaryPageInfo) this.calculateTotalPremiums();
  }

  private calculateAges(birthDate: string) {
    this.realAge = this.datesService.calculateAge(birthDate);
    this.ageNearest = this.datesService.getAgeNearest(birthDate);
  }

  private parseWithdrawalDate() {
    const withdrawalDate = this.summaryPageInfo.dates.withdrawal;
    if (withdrawalDate) {
      this.parsedWithdrawalDate = this.datesService.getDateOrdinalSuffix(withdrawalDate);
    }
  }

  showModalDialog() {
    this.displayModal = true;
  }

  getAdvisorById() {
    if (this.summaryPageInfo?.advisorId) {
      this.advisorsService
        .getAdvisorByAdvisorId(this.summaryPageInfo?.advisorId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.advisor = res as Advisor;
        });
    }
  }

  calculateTotalPremiums() {
    this.totalMonthlyPremium = 0;
    this.totalYearlyPremium = 0;
    this.summaryPageInfo?.products?.forEach((product) => {
      if (Number(product.monthlyPremium)) {
        this.totalMonthlyPremium += Number(product.monthlyPremium);
      }

      if (Number(product.yearlyPremium)) {
        this.totalYearlyPremium += Number(product.yearlyPremium);
      }
    });

    // TODO: upsell option temporary removed
    // this.upsellProducts?.forEach((product) => {
    //   if (Number(product.monthlyPremium)) {
    //     this.totalMonthlyPremium += Number(product.monthlyPremium);
    //   }
    //
    //   if (Number(product.yearlyPremium)) {
    //     this.totalYearlyPremium += Number(product.yearlyPremium);
    //   }
    // });
  }

  get fullName() {
    return `${this.summaryPageInfo?.client?.title} ${this.summaryPageInfo?.client?.firstName}
    ${this.summaryPageInfo?.client?.middleName ? this.summaryPageInfo?.client?.middleName : ''}
    ${this.summaryPageInfo?.client?.lastName}`;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
