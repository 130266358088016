import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderField, FormBuilderFieldValidator, FormBuilderGroup } from '@iapplication2/interfaces';
import { CanvasService, InteractiveFormBuilderService, InteractiveFormBuilderTypeOptionsService } from '@iapplication2/services';
import * as _ from 'lodash';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminValidatorTypes, FieldValidators } from '@iapplication2/interfaces';
@Component({
  selector: 'iapplication2-field-details-validator',
  templateUrl: './field-details-validator.component.html',
  styleUrls: ['./field-details-validator.component.scss'],
  providers: [ConfirmationService],
})
export class FieldDetailsValidatorComponent implements OnInit, OnDestroy {
  @Input() isInEditMode = false;
  @Input() isInModal = false;
  @Input() isInFieldBuilder = false;
  @Input() item: FormBuilderField | FormBuilderGroup;

  possibleManualValidators: FormBuilderFieldValidator[] = [];
  possiblePredefinedValidators: FormBuilderFieldValidator[] = [];
  possibleManualValidatorNodes: TreeNode[] = [];
  possiblePredefinedValidatorNodes: TreeNode[] = [];
  possibleManualValidatorsInitial: FormBuilderFieldValidator[] = [];
  possiblePredefinedValidatorsInitial: FormBuilderFieldValidator[] = [];
  selectedValidatorToAdd;
  showAddValidatorSection = false;
  editingValidator = false;
  validatorToEdit: FormBuilderFieldValidator;
  validatorToEditOriginal: FormBuilderFieldValidator;
  editingValidatorType: AdminValidatorTypes;
  areValidatorsAvailable = true;

  ValidatorTypeEnum = AdminValidatorTypes;

  validatorsList: TreeNode[] = [
    {
      label: 'Manual validators',
      data: AdminValidatorTypes.MANUAL,
      selectable: false,
      children: this.possibleManualValidatorNodes,
    },
    {
      label: 'Predefined validators',
      data: AdminValidatorTypes.PREDEFINED,
      selectable: false,
      children: this.possiblePredefinedValidatorNodes,
    },
  ];

  displaySection: boolean;

  private unsubscribe = new Subject<void>();

  constructor(
    private interactiveFormBuilderTypeOptionsService: InteractiveFormBuilderTypeOptionsService,
    private confirmationService: ConfirmationService,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private canvasService: CanvasService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getPossibleValidatorsList();
  }

  addSelectedValidator() {
    if (!this.item.fieldValidators) this.item.fieldValidators = {} as FieldValidators;
    if (!this.item.fieldValidators?.manualValidators) this.item.fieldValidators.manualValidators = [];
    if (!this.item.fieldValidators?.predefinedValidators) this.item.fieldValidators.predefinedValidators = [];
    if (this.selectedValidatorToAdd.parent.data === AdminValidatorTypes.MANUAL) {
      this.item.fieldValidators.manualValidators.push(this.selectedValidatorToAdd.data);
      this.prepareManualValidatorsNodes(this.possibleManualValidators);
    } else if (this.selectedValidatorToAdd.parent.data === AdminValidatorTypes.PREDEFINED) {
      this.item.fieldValidators.predefinedValidators.push(this.selectedValidatorToAdd.data);
      this.preparePredefinedValidatorNodes(this.possiblePredefinedValidators);
    }
    this.selectedValidatorToAdd = null;
  }

  editValidator(validator: FormBuilderFieldValidator, type: AdminValidatorTypes) {
    this.editingValidator = true;
    this.validatorToEdit = validator;
    this.validatorToEditOriginal = _.cloneDeep(validator);
    this.editingValidatorType = type;
  }

  deleteValidator(event, validatorToDelete: FormBuilderFieldValidator, type: AdminValidatorTypes) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete this validator?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (type === AdminValidatorTypes.MANUAL) {
          this.item.fieldValidators.manualValidators = this.item.fieldValidators.manualValidators.filter(
            (validator) => validator.id !== validatorToDelete.id
          );
          this.prepareManualValidatorsNodes(this.possibleManualValidatorsInitial);
        }
        if (type === AdminValidatorTypes.PREDEFINED) {
          this.item.fieldValidators.predefinedValidators = this.item.fieldValidators.predefinedValidators.filter(
            (validator) => validator.id !== validatorToDelete.id
          );
          this.preparePredefinedValidatorNodes(this.possiblePredefinedValidatorsInitial);
        }
      },
    });
  }

  saveEditedValidator() {
    this.editingValidator = false;
    this.validatorToEdit = null;
    this.editingValidatorType = null;
    this.validatorToEditOriginal = null;
  }

  cancelEditValidator() {
    this.editingValidator = false;
    if (this.editingValidatorType === AdminValidatorTypes.MANUAL) {
      const item = this.item.fieldValidators.manualValidators.find((validator) => validator.id === this.validatorToEdit.id);
      if (item) {
        this.item.fieldValidators.manualValidators.find((validator) => validator.id === this.validatorToEdit.id).configuration =
          this.validatorToEditOriginal.configuration;
      }
    }
    if (this.editingValidatorType === AdminValidatorTypes.PREDEFINED) {
      const item = this.item.fieldValidators.predefinedValidators.find((validator) => validator.id === this.validatorToEdit.id);
      if (item) {
        this.item.fieldValidators.predefinedValidators.find((validator) => validator.id === this.validatorToEdit.id).configuration =
          this.validatorToEditOriginal.configuration;
      }
    }
    this.validatorToEdit = null;
    this.editingValidatorType = null;
    this.validatorToEditOriginal = null;
  }

  cancelAddValidator() {
    this.showAddValidatorSection = false;
    this.selectedValidatorToAdd = null;
  }

  private getPossibleValidatorsList() {
    const type = this.item?.groupOptions ? this.item.groupOptions?.type?.type : this.item.fieldType?.type;
    this.interactiveFormBuilderTypeOptionsService
      .getPossibleManualValidatorsByType(type)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.possibleManualValidatorsInitial = _.cloneDeep(res);
        this.prepareManualValidatorsNodes(res);
        this.isSectionToBeDisplayed();
      });
    this.interactiveFormBuilderTypeOptionsService
      .getPossiblePredefinedValidatorsByType(type)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.possiblePredefinedValidatorsInitial = _.cloneDeep(res);
        this.preparePredefinedValidatorNodes(res);
        this.isSectionToBeDisplayed();
      });
  }

  private prepareManualValidatorsNodes(data: FormBuilderFieldValidator[]) {
    this.possibleManualValidators = this.item.fieldValidators?.manualValidators
      ? this.filterOutExistingValidators(data, this.item.fieldValidators.manualValidators)
      : data;
    this.possibleManualValidatorNodes = this.possibleManualValidators.map((validator) => {
      const node: TreeNode = {
        label: validator.label,
        data: validator,
      };
      return node;
    });
    this.validatorsList.find((node) => node.data === AdminValidatorTypes.MANUAL).children = this.possibleManualValidatorNodes;
    this.checkAvailableValidators();
  }

  private preparePredefinedValidatorNodes(data: FormBuilderFieldValidator[]) {
    this.possiblePredefinedValidators = this.item.fieldValidators?.predefinedValidators
      ? this.filterOutExistingValidators(data, this.item.fieldValidators.predefinedValidators)
      : data;
    this.possiblePredefinedValidatorNodes = this.possiblePredefinedValidators.map((validator) => {
      const node: TreeNode = {
        label: validator.label,
        data: validator,
      };
      return node;
    });
    this.validatorsList.find((node) => node.data === AdminValidatorTypes.PREDEFINED).children = this.possiblePredefinedValidatorNodes;
    this.checkAvailableValidators();
  }

  checkAvailableValidators() {
    this.areValidatorsAvailable = !!(this.possibleManualValidatorNodes?.length || this.possiblePredefinedValidatorNodes?.length);
    if (!this.areValidatorsAvailable) {
      this.showAddValidatorSection = this.areValidatorsAvailable;
    }
  }

  private filterOutExistingValidators(
    initialValidatorList: FormBuilderFieldValidator[],
    existingValidators: FormBuilderFieldValidator[]
  ): FormBuilderFieldValidator[] {
    return initialValidatorList.filter((validator) => !existingValidators.find((element) => element.id === validator.id));
  }

  isSectionToBeDisplayed(): void {
    this.displaySection =
      !!this.possibleManualValidators.length ||
      !!this.possiblePredefinedValidators.length ||
      this.item.fieldValidators?.manualValidators?.length ||
      this.item.fieldValidators?.predefinedValidators?.length;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
