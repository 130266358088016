export interface FieldDisplayCondition {
  id?: number;
  fieldId?: number;
  conditionType?: ConditionType | null;
  conditionFieldId: number;
  value: unknown;
}

export interface ConditionType {
  id: number;
  type?: string;
  fieldTypeId?: number;
  acceptsValue?: boolean;
  displayLabel?: string;
}
