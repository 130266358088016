import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountInputFormat'
})
export class AmountInputFormatPipe implements PipeTransform {
  transform(value: string): string | number {
    if (value) {
      if (value.toString().slice(-1) === ',') {
        return value;
      } else {
        return this.format(value);
      }
    } else {
      return value;
    }
  }

  private format(value): string {
    const DOT = '.';
    const COMMA = ',';
    const REGEX = new RegExp('[^,\\d]', 'g');
    // separates the integer and decimals
    const separatedNumber: string[] = value
      .toString()
      .replace(REGEX, '')
      .toString()
      .split(COMMA);
    // first pair of digits
    const rest = separatedNumber[0].length % 3;
    // thousand pairs
    const thousands: string[] | null = separatedNumber[0].substr(rest).match(/\d{3}/g);
    let result: string = separatedNumber[0].substr(0, rest);
    // append the thousand pairs
    if (thousands) {
      result +=(rest ? DOT : '') + thousands.join(DOT);
    }
    // can be used to append the decimals if needed
    // if (separatedNumber[1]) {
    //   result += COMMA + separatedNumber[1];
    // }
    return result;
  }

}

