import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientManagementListComponent } from './client-management/client-management-list.component';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RouterModule } from '@angular/router';
import { InputMaskModule } from 'primeng/inputmask';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TagModule } from 'primeng/tag';
import { TabViewModule } from 'primeng/tabview';
import { MenuModule } from 'primeng/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  AdvisorCardModule,
  ChangeAdvisorModule,
  ProductListDisplayModule,
  ProductStatusTagModule
} from '@iapplication2/ui';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { ClientManagementPageComponent } from './client-management-page/client-management-page.component';
import { ClientNewComponent } from './client-new/client-new.component';
import { FormFieldErrorModule } from '@iapplication2/ui';
import { ClientProfilePageComponent } from './client-profile-page/client-profile-page.component';
import { ClientPoliciesComponent } from './client-page-sections/client-policies/client-policies.component';
import { ClientApplicationsComponent } from './client-page-sections/client-applications/client-applications.component';
import { ClientActionsComponent } from './client-page-sections/client-actions/client-actions.component';
import { ClientInformationModule } from './client-page-sections/client-information/client-information.module';
import { SharedTranslateModule } from '@iapplication2/shared-translate';

@NgModule({
  imports: [
    CommonModule,
    SliderModule,
    TabViewModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    ProgressBarModule,
    CheckboxModule,
    FormsModule,
    ChangeAdvisorModule,
    ReactiveFormsModule,
    InputTextModule,
    MenuModule,
    AutoCompleteModule,
    DialogModule,
    ClientInformationModule,
    CalendarModule,
    ToastModule,
    ButtonModule,
    InputTextareaModule,
    OverlayPanelModule,
    ProductListDisplayModule,
    InputMaskModule,
    TagModule,
    FlexLayoutModule,
    AdvisorCardModule,
    RadioButtonModule,
    FormFieldErrorModule,
    ProductStatusTagModule,
    SharedTranslateModule,
    RouterModule.forChild([
      {
        path: '',
        component: ClientManagementPageComponent,
      },
      {
        path: 'new',
        component: ClientNewComponent,
      },
      {
        path: 'client/:id',
        component: ClientProfilePageComponent,
      },
    ]),
  ],
  exports: [ClientManagementListComponent],
  declarations: [
    ClientManagementListComponent,
    ClientManagementPageComponent,
    ClientNewComponent,
    ClientProfilePageComponent,
    ClientPoliciesComponent,
    ClientApplicationsComponent,
    ClientActionsComponent,
  ],
})
export class ClientManagementModule {}
