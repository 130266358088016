<div
  *ngIf="fieldCategories.length"
  cdkDropList
  id="field-categories"
  [cdkDropListData]="fieldCategories"
  (cdkDropListDropped)="drop($event)"
>
  <div
    *ngIf="fieldCategories[0]?.slug === categorySlugEnum.UNASSIGNED"
    class="card slightGrayout p-custom-shadow-3 group-options-field my-2 px-3 py-2"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    pTooltip="This category cannot be modified"
    style="min-height: 47px"
    pDraggableDisabled
    pDroppableDisabled
  >
    <div>
      {{ fieldCategories[0].title }}
    </div>
  </div>
  <p-confirmDialog [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"> </p-confirmDialog>
  <div *ngFor="let category of fieldCategories; let i = index">
    <div>
      <div
        *ngIf="category?.slug !== categorySlugEnum.UNASSIGNED"
        class="card p-custom-shadow-3 group-options-field my-2 px-3 py-2"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        cdkDrag
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <span cdkDragHandle class="mt-1 mr-3">
            <img
              [src]="isDarkTheme ? './assets/custom/icons/drag-icon-light.svg' : './assets/custom/icons/drag-icon.svg'"
              class="drag-icon"
            />
          </span>
          <div *ngIf="i !== indexOfCategoryToEdit">
            {{ category?.title }}
          </div>
          <form [formGroup]="editCategoryForm">
            <div *ngIf="i === indexOfCategoryToEdit">
              <input
                id="categoryTitle"
                type="text"
                class="p-inputtext p-component"
                pInputText
                placeholder="title"
                formControlName="title"
              />
              <p
                *ngIf="
                  editCategoryForm.get('title').errors?.duplicateCategoryTitle &&
                  !(editCategoryForm.controls['title'].value?.toLowerCase() === categorySlugEnum.UNASSIGNED)
                "
              >
                This category title is already in use.
              </p>
              <p *ngIf="editCategoryForm.controls['title'].value?.toLowerCase() === categorySlugEnum.UNASSIGNED">
                This category title is reserved.
              </p>
            </div>
          </form>
        </div>
        <button
          *ngIf="i !== indexOfCategoryToEdit"
          pButton
          pRipple
          icon="pi pi-cog"
          (click)="toggleMenu(category, fieldMenu, $event, i)"
          class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
        ></button>
        <button
          *ngIf="editing && i === indexOfCategoryToEdit"
          pButton
          pRipple
          type="button"
          icon="pi pi-check"
          [disabled]="
            editCategoryForm.get('title').errors?.duplicateCategoryTitle ||
            editCategoryForm.controls['title'].value?.toLowerCase() === categorySlugEnum.UNASSIGNED
          "
          (click)="save(category)"
          class="p-button-rounded p-button-text p-button-success mr-2"
        ></button>
      </div>
    </div>
  </div>
</div>
<p-menu #fieldMenu [popup]="true" appendTo="body" [model]="fieldItems"></p-menu>

<p-toast key="changesSavedSuccessfully"></p-toast>
