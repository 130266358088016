import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldErrorComponent } from './form-field-error.component';
import { SharedTranslateModule } from '@iapplication2/shared-translate';

@NgModule({
  imports: [
    CommonModule,
    SharedTranslateModule
  ],
  declarations: [FormFieldErrorComponent],
  exports: [FormFieldErrorComponent],
})
export class FormFieldErrorModule {}
