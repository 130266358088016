import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { APP_TYPE, ENV, envType } from '@iapplication2/interfaces';
import { MenuActionsService } from '@iapplication2/services';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'iapplication2-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  isLightTheme: boolean;
  isMenuActive: boolean;

  isMenuStatic: boolean;

  model: MenuItem[];

  isEnvDevelop: boolean;

  constructor(private menuActionsService: MenuActionsService, @Inject(APP_CONFIG) private appConfig: ENV) {}

  ngOnInit() {
    this.isLightTheme = JSON.parse(localStorage.getItem('isLightTheme'));
    this.isMenuActive = this.menuActionsService.activeMenu;

    if (localStorage.getItem('isMenuStatic')) {
      this.isMenuStatic = JSON.parse(localStorage.getItem('isMenuStatic'));
      this.changeMenuState();
    }

    if (this.appConfig.appType === APP_TYPE.ADMIN) {
      this.model = this.setAdminMenu();
    } else if (this.appConfig.appType === APP_TYPE.ADVISOR) {
      this.model = this.setAdvisorMenu();
    } else if (this.appConfig.appType === APP_TYPE.NEW_BUSINESS) {
      this.model = this.seNewBusinessMenu();
    }
  }

  checkIfEnvIsDevelop() {
    this.isEnvDevelop = this.appConfig.environmentType === envType.DEVELOP;
  }

  setAdminMenu(): MenuItem[] {
    return [
      {
        label: 'Carriers management',
        icon: 'pi pi-briefcase',
        routerLink: ['carriers'],
        routerLinkActiveOptions: {
          exact: false,
        },
      },
      {
        label: 'Field builder',
        icon: 'pi pi-palette',
        routerLink: ['/formBuilder/fieldBuilder'],
      },
    ];
  }

  setAdvisorMenu(): MenuItem[] {
    const dashboard = {
      label: 'Dashboard',
      icon: 'pi pi-home',
      routerLink: ['/'],
    };
    const quickQuotes: MenuItem = {
      label: 'Quick quotes',
      icon: 'pi pi-comment',
      url: 'https://quote-term.iapplication.ca/',
      target: '_blank',
    };
    const myClients: MenuItem = {
      label: 'My clients',
      icon: 'pi pi-users',
      routerLink: ['/clients'],
    };
    const myApplications: MenuItem = {
      label: 'My applications',
      icon: 'pi pi-briefcase',
      routerLink: ['/applications'],
    };
    switch (true) {
      case this.isEnvDevelop:
        return [dashboard, quickQuotes, myClients, myApplications];
      default:
        return [dashboard, myClients, myApplications, quickQuotes];
    }
  }

  seNewBusinessMenu(): MenuItem[] {
    return [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        routerLink: ['/'],
      },
    ];
  }

  onSidebarMouseOver(event) {
    if (!this.isMenuStatic) {
      this.isMenuActive = true;
      this.menuActionsService.activeMenu = true;
    }
  }

  onSidebarMouseLeave(event) {
    if (!this.isMenuStatic) {
      this.isMenuActive = false;
      this.menuActionsService.activeMenu = false;
    }
  }

  onToggleMenu(event) {
    this.isMenuStatic = !this.isMenuStatic;
    this.changeMenuState();
    event.preventDefault();
  }

  public getLogoPathForCurrentLightMode() {
    return this.isLightTheme ? '/assets/layout/images/logo-svg.svg' : '/assets/layout/images/logo-svg.svg';
  }

  private changeMenuState() {
    this.menuActionsService.changedMenuState(this.isMenuStatic);
    if (this.isMenuStatic) {
      this.isMenuActive = true;
      this.menuActionsService.activeMenu = true;
    }
  }
}
