export interface Message {
  summary: string;
  detail: string;
}

export class MessagesModel {
  success?: Message;
  error?: Message;
  warn?: Message;
  info?: Message;
  custom?: Message;
}
