import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '@iapplication2/interfaces';
import { DatesService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-result-section-clients',
  templateUrl: './result-section-clients.component.html',
  styleUrls: ['./result-section-clients.component.scss'],
})
export class ResultSectionClientsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() clients: Client[];
  @Input() loading = true;
  @Input() searchedString: string;
  cols: unknown[];
  numberOfRows = 10;
  items: MenuItem[];

  private unsubscribe = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private datesService: DatesService
  ) {}

  ngOnInit(): void {
    this.setClientsDetails();
    this.items = [
      {
        label: '',
        items: [
          {
            label: '',
            icon: 'pi pi-eye',
          },
        ],
      },
    ];
    this.getTranslationsForActionButton();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForActionButton();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clients) {
      this.setClientsDetails();
    }
  }

  setClientsDetails() {
    this.clients.forEach((client) => {
      client.age = this.datesService.calculateAge(client.birthDate);
      this.getFullName(client);
    });
  }

  getFullName(client: Client) {
    const firstName = client?.firstName?.replace(/ /g, '&nbsp;');
    const middleName = client?.middleName?.replace(/ /g, '&nbsp;');
    const lastName = client?.lastName?.replace(/ /g, '&nbsp;');

    client.fullName = `${firstName}&nbsp;${client?.middleName ? middleName + '&nbsp;': ''}${lastName}`;
    client.fullNameTooltip = `${client?.firstName} ${client?.middleName ? client?.middleName + ' ': ''}${client?.lastName}`;
  }

  navigateToClient(client: Client) {
    this.router.navigate(['client/' + client.id], {
      relativeTo: this.route,
    });
  }

  toggleMenu(client: Client, menu, ev) {
    ev.preventDefault();
    menu.toggle(ev);
    this.items[0].items[0].routerLink = `clients/client/${client.id}`;
  }

  private getTranslationsForActionButton() {
    this.translateService.get('pages.dashboard.sections.recentClients.tabs.recentClients.table.button').subscribe((res: any) => {
      this.items[0].label = res.title;
      this.items[0].items[0].label = res.options?.view;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
