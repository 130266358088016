export interface QuestionTemplateList {
    questions: QuestionTemplate[];
}

export interface QuestionTemplate {
    id: number;
    order: number;
    title: string;
    response?: string;
    questions?: QuestionTemplate[]
}

export interface QuestionTemplateIntern {
    id: number;
    order: number;
    parentId: number;
    relatedParentId: number;
    title: string;
    questions?: QuestionTemplate[]
}