<div fxLayout="row" fxFlex>
  <div fxFlex="45">
    <div class="fieldsSidebar-container card" fxFlex fxLayout="column">
      <form [formGroup]="searchFormGroup">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <input id="searchInput" formControlName="searchInput" type="text" class="p-inputtext" placeholder="Search for a field" fxFlex />
        </span>
      </form>
      <p-divider></p-divider>
      <div *ngIf="searchForFieldValue?.length <= 1; else displaySearchResults">
        <div *ngFor="let predefinedField of predefinedFields" class="p-mb-3">
          <div *ngIf="predefinedField?.category?.slug === categorySlugEnum.UNASSIGNED && showUnassignedList">
            <p-fieldset legend="Unassigned" [toggleable]="true">
              <iapplication2-fields-sidebar-category
                [predefinedFields]="predefinedField.fields"
                [fieldsSidebarCategory]="predefinedField?.category"
              >
              </iapplication2-fields-sidebar-category>
            </p-fieldset>
          </div>
          <div *ngIf="predefinedField?.category?.slug !== categorySlugEnum.UNASSIGNED">
            <p-fieldset [legend]="predefinedField?.category?.title" [toggleable]="true">
              <iapplication2-fields-sidebar-category
                [predefinedFields]="predefinedField.fields"
                [fieldsSidebarCategory]="predefinedField?.category"
              >
              </iapplication2-fields-sidebar-category>
            </p-fieldset>
          </div>
        </div>
      </div>
      <ng-template #displaySearchResults>
        <div *ngIf="searchResultFields?.length; else emptyState" fxLayout="row wrap" class="fieldsSidebarCategory">
          <div *ngFor="let predefinedField of searchResultFields" class="p-mb-3">
            <div *ngIf="predefinedField?.category?.slug === categorySlugEnum.UNASSIGNED && showUnassignedList">
              <p-fieldset legend="Unassigned" [toggleable]="true">
                <iapplication2-fields-sidebar-category
                  [predefinedFields]="predefinedField.fields"
                  [fieldsSidebarCategory]="predefinedField?.category"
                >
                </iapplication2-fields-sidebar-category>
              </p-fieldset>
            </div>
            <div *ngIf="predefinedField?.category?.slug !== categorySlugEnum.UNASSIGNED">
              <p-fieldset [legend]="predefinedField?.category?.title" [toggleable]="true">
                <iapplication2-fields-sidebar-category
                  [predefinedFields]="predefinedField.fields"
                  [fieldsSidebarCategory]="predefinedField?.category"
                >
                </iapplication2-fields-sidebar-category>
              </p-fieldset>
            </div>
          </div>
        </div>

        <ng-template #emptyState> There are no custom fields that match your search. </ng-template>
      </ng-template>
    </div>
  </div>
  <div fxFlex="35" class="ml-6 details card" [ngClass]="emptyState ? '' : 'details-container'" #editArea>
    <div class="mb-4" *ngIf="selectedField">
      <button
        pButton
        *ngIf="canEditItem"
        label="Save field changes"
        (click)="saveFieldChanges()"
        class="mr-2"
        [disabled]="!formValidity"
      ></button>
      <div [pTooltip]="canEditItemTooltip" tooltipPosition="left" style="display: initial">
        <button
          pButton
          pRipple
          type="button"
          label="Delete field"
          class="p-button-danger p-button-text"
          (click)="confirmFieldDeletion($event)"
          [disabled]="!canEditItem"
        ></button>
      </div>
      <p-confirmPopup></p-confirmPopup>
      <p-divider></p-divider>
    </div>
    <div class="mb-4" *ngIf="selectedGroup">
      <button
        pButton
        *ngIf="canEditItem"
        label="Save group changes"
        (click)="saveGroupChanges()"
        class="mr-2"
        [disabled]="!formValidity"
      ></button>

      <div [pTooltip]="canEditItemTooltip" tooltipPosition="left" style="display: initial">
        <button
          pButton
          pRipple
          type="button"
          label="Delete group"
          class="p-button-danger p-button-text"
          (click)="confirmGroupDeletion($event)"
          [disabled]="!canEditItem"
        ></button>
      </div>
      <p-confirmPopup></p-confirmPopup>
      <p-divider></p-divider>
    </div>
    <div class="mb-4" *ngIf="selectedTable">
      <button
        pButton
        *ngIf="canEditItem"
        label="Save table changes"
        (click)="saveTableChanges()"
        class="mr-2"
        [disabled]="!formValidity"
      ></button>

      <div [pTooltip]="canEditItemTooltip" tooltipPosition="left" style="display: initial">
        <button
          pButton
          pRipple
          type="button"
          label="Delete table"
          class="p-button-danger p-button-text"
          (click)="confirmTableDeletion($event)"
          [disabled]="!canEditItem"
        ></button>
      </div>
      <p-confirmPopup></p-confirmPopup>
      <p-divider></p-divider>
    </div>
    <iapplication2-field-details-field
      *ngIf="selectedField"
      [isInEditMode]="canEditItem"
      [field]="selectedField.field"
      [mainForm]="fieldDetailsForm"
      (invalidForm)="formValidityChanged($event)"
    >
    </iapplication2-field-details-field>
    <iapplication2-field-details-category
      *ngIf="selectedField || selectedGroup || selectedTable"
      class="details-field-content-property my-2"
      fxLayout="row"
      [fieldCategory]="selectedItemCategory"
      [isInEditMode]="canEditItem"
      [form]="fieldCategoryForm"
    ></iapplication2-field-details-category>
    <iapplication2-field-details-type
      *ngIf="selectedField || selectedGroup"
      class="details-type-content-property my-2"
      [isInFieldBuilder]="true"
      [isInEditMode]="canEditItem"
      [selectedField]="selectedGroup ? selectedGroup.fields[0] : selectedField.field"
      (options)="optionsWereAddedToTheField($event)"
      (entries)="entriesWereAddedToTheField($event)"
    >
    </iapplication2-field-details-type>

    <iapplication2-field-details-group
      *ngIf="selectedGroup"
      [isInEditMode]="canEditItem"
      [fieldGroup]="selectedGroup"
      [isInFieldBuilder]="true"
      [mainForm]="fieldDetailsForm"
    >
    </iapplication2-field-details-group>
    <iapplication2-field-disclosure-details
      *ngIf="selectedField?.field.fieldType?.type === FormBuilderFieldTypeTypeEnum.DISCLAIMER"
      [item]="selectedField.field"
      [isInFieldBuilder]="true"
      [isInEditMode]="canEditItem"
    ></iapplication2-field-disclosure-details>
    <iapplication2-field-disclosure-details
      *ngIf="selectedGroup?.groupOptions?.type?.type === FormBuilderFieldTypeTypeEnum.DISCLOSURE"
      [item]="selectedGroup"
      [isInFieldBuilder]="true"
      [isInEditMode]="canEditItem"
    ></iapplication2-field-disclosure-details>
    <iapplication2-field-details-validator
      *ngIf="selectedField"
      [isInEditMode]="canEditItem"
      [isInFieldBuilder]="true"
      [item]="selectedField?.field"
    ></iapplication2-field-details-validator>
    <iapplication2-field-details-validator
      *ngIf="selectedGroup"
      [isInEditMode]="canEditItem"
      [isInFieldBuilder]="true"
      [item]="selectedGroup"
    ></iapplication2-field-details-validator>
    <iapplication2-field-details-table
      *ngIf="selectedTable"
      [isInEditMode]="canEditItem"
      [isInCreation]="false"
      [isInFieldBuilder]="true"
      [table]="selectedTable"
      [mainForm]="fieldDetailsForm"
    ></iapplication2-field-details-table>
    <iapplication2-field-details-static-field-link
      *ngIf="selectedField || selectedGroup"
      [isInEditMode]="canEditItem"
      [selectedField]="selectedField?.field ? selectedField?.field : selectedGroup?.fields[0]"
    ></iapplication2-field-details-static-field-link>
    <div *ngIf="!selectedField && !selectedGroup && !selectedTable; then emptyState"></div>
    <ng-template #emptyState>
      <div class="details-emptyState" fxLayout="row" fxLayoutAlign="end center">
        <h6>Select one or more fields to see their details.</h6>
        <img src="./assets/custom/icons/large-details.svg" />
      </div>
    </ng-template>
  </div>
</div>
<p-toast key="changesSavedSuccessfully"></p-toast>

<p-dialog
  *ngIf="isFieldDialogOpen"
  [modal]="true"
  [(visible)]="isFieldDialogOpen"
  [style]="{ width: '40vw', minWidth: '600px' }"
  showEffect="fade"
>
  <iapplication2-field-settings-modal
    [selectedFormBuilderItem]="selectedColumnInPredefinedTable"
    [modalType]="modalTypeEnum.EDIT_PREDEFINED"
    (closeDialog)="closeFieldDialog()"
  >
  </iapplication2-field-settings-modal>
</p-dialog>
