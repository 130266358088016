import { Component, OnDestroy, OnInit } from '@angular/core';
import { AttachedFile } from '@iapplication2/interfaces';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BaseFieldDataDirective } from 'libs/dynamic-form-display/src/lib/baseFieldData.directive';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FieldManagementService } from 'libs/services/src/lib/field-management/field-management.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { register } from '../../../../registryComponent';
import { ApplicationsProcessService, ApplicationSummaryService } from '@iapplication2/services';
import { ActivatedRoute } from '@angular/router';

@register('pdf')
@Component({
  selector: 'iapplication2-pdf-field-display',
  templateUrl: './pdf-field-display.component.html',
  styleUrls: ['./pdf-field-display.component.scss'],
})
export class PdfFieldDisplayComponent extends BaseFieldDataDirective implements OnInit, OnDestroy {
  fieldAttachments: AttachedFile[] = [];
  applicationId: number;
  isViewApplication: boolean;

  private unsubscribe = new Subject<void>();

  constructor(
    private fieldManagementService: FieldManagementService,
    private applicationSummaryService: ApplicationSummaryService,
    private applicationsProcessService: ApplicationsProcessService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkViewApplicationDisplay();
    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.getFieldAttachments();
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  private getFieldAttachments(): void {
    this.applicationSummaryService
      .getApplicationAttachments(this.applicationId.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((list: AttachedFile[]) => {
        if (list) {
          this.fieldAttachments = list.filter((file) => file.fieldId === this.field.id);
        }
      });
  }

  onAddAttachments(event, form) {
    const uploadedFile = event.files[0];
    form.clear();

    this.applicationSummaryService
      .createAttachmentWithFieldIdByApplicationId(this.applicationId, this.field.id, uploadedFile)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((file: AttachedFile) => {
        this.fieldAttachments.push(file);

        this.setFormControlValue(file.id);
      });
  }

  setFormControlValue(newFileId: number) {
    const existingValue: string = this.formFieldControl.value;
    if (existingValue) {
      this.formFieldControl.setValue(existingValue.concat(',', newFileId.toString()));
    } else {
      this.formFieldControl.setValue(newFileId.toString());
    }
  }

  removeFromFormControlValue(fileIdToRemove: number) {
    if (this.formFieldControl.value) {
      let existingValues = (this.formFieldControl.value as string).split(',');
      existingValues = existingValues.filter((existingValue: string) => existingValue !== fileIdToRemove.toString());

      this.formFieldControl.setValue(existingValues.length ? existingValues.join(',') : null);
    }
  }

  deleteFile(attachedFile: AttachedFile) {
    this.applicationSummaryService
      .deleteApplicationAttachmentsById(attachedFile.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.fieldAttachments = this.fieldAttachments.filter((file) => file.id !== attachedFile.id);
        this.removeFromFormControlValue(attachedFile.id);
      });
    this.getFieldAttachments();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
