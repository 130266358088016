export interface ReflexDataModel {
  address: string;
  application_id: string;
  city: string;
  coverage: number;
  dob: string;
  email: string;
  first_name: string;
  gender: string;
  language: string;
  last_name: string;
  middle_name: string;
  phone: string;
  postal_code: string;
  province: string;
  extension: string;
  serverPayload?: any,
  risk: string;
  term: string;
  env: string;
  isCallCenter: number;
  smoker: string;
  smokerFE: string;
  product_version: string;
  product_code: string;
  force_GI: boolean;
  approved: boolean;
  products: string;
  add_everest: string;
  showGP?: boolean
  hasUsedVoice: boolean;
  last_question?: Question;
}

interface Question {
  answer?: any;
  question?: string;
}
