/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FieldTable } from '@iapplication2/interfaces';

export function tableColumnDuplicationValidator(
  currentValue: string,
  table: FieldTable
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const found = table.columns.find(
        (column) => column.name === control.value
      );
      if (control.value != currentValue && found) {
        return {
          duplicateColumnName: true,
        };
      }
    }
  };
}
