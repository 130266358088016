import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLengthCheck',
  pure: true,
})
export class TextLengthCheckPipe implements PipeTransform {
  transform(value: string): string {
    if ( value && value?.length > 20) {
      return value.slice(0, 20) + '..';
    } else {
      return value;
    }
  }
}
