import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBuilderField } from '@iapplication2/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getTypeFor } from '../../registryComponent';
import { ApplicationsProcessService, InteractiveFormBuilderValidatorsService } from '@iapplication2/services';

@Component({
  selector: 'iapplication2-form-display-field',
  templateUrl: './form-display-field.component.html',
  styleUrls: ['./form-display-field.component.scss'],
})
export class FormDisplayFieldComponent implements AfterViewInit, OnDestroy {
  @Input() formField: FormBuilderField;
  @ViewChild('fieldContainer', { read: ViewContainerRef }) container;
  @Input() formFieldControl: FormControl;
  @Input() parentFormGroup: FormGroup;
  @Input() isPreviewMode: boolean;
  @Input() hideLabel: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private componentRef: ComponentRef<any>;

  isViewApplication: boolean;
  showFieldAsRequired: boolean;
  showFieldAsMIBRequired: boolean;
  fieldHasRequiredValidator: boolean;
  fieldHasMIBRequiredValidator: boolean;
  unsubscribe: Subject<unknown> = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
    private validatorsService: InteractiveFormBuilderValidatorsService,
    private applicationsProcessService: ApplicationsProcessService
  ) {}

  private static getComponentByName(componentName: string) {
    return getTypeFor(componentName);
  }

  ngAfterViewInit(): void {
    if (this.formField) {
      this.checkViewApplicationDisplay();
      this.getRequiredFieldsFromFieldValidators();
      this.setInitialRequiredBasedOnFieldValuesInFormGroup();
      this.dynamicallyCreateComponent();
      this.checkRequiredWhenFieldValueChangesInFormGroup();
    }
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  private dynamicallyCreateComponent() {
    const componentClass = FormDisplayFieldComponent.getComponentByName(this.formField.fieldType.type);
    if (componentClass) {
      this.componentRef = this.container.createComponent(componentClass);
      this.componentRef.instance.field = this.formField;
      if (!this.formField?.table && !this.isViewApplication)
        this.formFieldControl?.setValidators(this.validatorsService.getValidators(this.formField));
      this.componentRef.instance.formFieldControl = this.formFieldControl;
      this.componentRef.instance.parentFormGroup = this.parentFormGroup;
      this.componentRef.instance.isPreviewMode = this.isPreviewMode;
      this.componentRef.instance.hideLabel = this.hideLabel;

      /* The IDs 25 and 26 are for the fields: country of birth and province/state of birth  */
      this.componentRef.instance.isReadonly = this.isViewApplication
        ? this.isViewApplication
        : this.formField?.staticValueLink?.id === 25 || this.formField?.staticValueLink?.id === 26
        ? false
        : !!this.formField.staticValueLink?.fieldKey;

      this.componentRef.instance.tabIndex = this.formField.staticValueLink?.fieldKey ? -1 : null;
      this.cdr.detectChanges();
    }
  }

  getRequiredFieldsFromFieldValidators() {
    this.fieldHasRequiredValidator = !!this.formField?.fieldValidators.manualValidators.filter(
      (validator) => validator.label === 'Required'
    ).length;
    this.fieldHasMIBRequiredValidator = !!this.formField?.fieldValidators.manualValidators.filter(
      (validator) => validator.label === 'MIB Required'
    ).length;
  }

  setInitialRequiredBasedOnFieldValuesInFormGroup() {
    if (this.parentFormGroup.controls[this.formField.id].value) {
      if (this.fieldHasRequiredValidator) {
        this.showFieldAsRequired = false;
      }
      if (this.fieldHasMIBRequiredValidator) {
        this.showFieldAsMIBRequired = false;
      }
    } else {
      if (!this.isViewApplication) {
        if (this.fieldHasRequiredValidator) {
          this.showFieldAsRequired = true;
        }
        if (this.fieldHasMIBRequiredValidator) {
          this.showFieldAsMIBRequired = true;
        }
      }
    }
  }

  checkRequiredWhenFieldValueChangesInFormGroup() {
    this.parentFormGroup.controls[this.formField.id].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      if (data) {
        if (this.fieldHasRequiredValidator) {
          this.componentRef.instance.showFieldAsRequired = false;
        }
        if (this.fieldHasMIBRequiredValidator) {
          this.componentRef.instance.showFieldAsMIBRequired = false;
        }
      } else {
        if (!this.isViewApplication) {
          if (this.fieldHasRequiredValidator) {
            this.componentRef.instance.showFieldAsRequired = true;
          }
          if (this.fieldHasMIBRequiredValidator) {
            this.componentRef.instance.showFieldAsMIBRequired = true;
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
