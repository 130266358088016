<div class="flex flex-column">
  <span class="my-2"> Enter your current password and set a new one. </span>
  <form [formGroup]="passwordChangeForm" (submit)="changePassword()">
    <div class="mb-4">
      <div class="p-field flex flex-column col-12 mb-2 lg:col-8 lg:mb-0 pl-0">
        <label for="currentPassword">Current password</label>
        <p-password
          formControlName="newPassword"
          placeholder="New password"
          [feedback]="true"
          [toggleMask]="true"
          appendTo="body"
        ></p-password>
      </div>
      <div class="p-field flex flex-column col-12 mb-2 lg:col-8 lg:mb-0 pl-0">
        <label for="newPassword">New password</label>
        <p-password formControlName="newPassword" placeholder="New password" [feedback]="true" [toggleMask]="true">
          <ng-template pTemplate="header">
            <h6>Pick a new password</h6>
          </ng-template>
          <ng-template pTemplate="footer">
            <p-divider></p-divider>
            <p class="mt-2">Suggestions</p>
            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
              <li>At least one lowercase</li>
              <li>At least one uppercase</li>
              <li>At least one numeric</li>
              <li>Minimum 8 characters</li>
            </ul>
          </ng-template>
        </p-password>
      </div>
    </div>
    <button pButton label="Change password" type="submit"></button>
  </form>
</div>
