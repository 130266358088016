<div *ngIf="status">
  <div class="productStatus">
    <div *ngIf="status?.name === applicationStatusListEnum.ACTIVE">
      <p-tag [value]="status.lokaliseKey | translate | uppercase" severity="info"></p-tag>
    </div>
    <div *ngIf="status?.name === applicationStatusListEnum.SCHEDULED">
      <p-tag [value]="status.lokaliseKey | translate | uppercase" severity="warning"></p-tag>
    </div>
    <div *ngIf="status?.name === applicationStatusListEnum.SUBMITTED">
      <p-tag [value]="status.lokaliseKey | translate | uppercase" severity="success"></p-tag>
    </div>
    <div *ngIf="status?.name === applicationStatusListEnum.EXPIRED">
      <p-tag [value]="status.lokaliseKey | translate | uppercase" severity="danger"></p-tag>
    </div>
    <div *ngIf="status?.name === applicationStatusListEnum.AWAITING_SIGNATURES">
      <p-tag [value]="status.lokaliseKey | translate | uppercase" severity="warning"></p-tag>
      <p-tag class=“ml-2” *ngIf="!!scheduled" [value]="applicationStatusListEnum.SCHEDULED.toString().toUpperCase()" severity="info"></p-tag>
    </div>
    <div *ngIf="status?.name === applicationStatusListEnum.FINALIZED">
      <p-tag [value]="status.lokaliseKey | translate | uppercase" severity="warning"></p-tag>
    </div>
  </div>
  <div *ngIf="statuses">
    <div class="productStatus" *ngFor="let singleStatus of statuses">
      <div *ngIf="singleStatus?.name === applicationStatusListEnum.ACTIVE">
        <p-tag [value]="singleStatus.lokaliseKey | translate | uppercase" severity="info"></p-tag>
      </div>
      <div *ngIf="singleStatus?.name === applicationStatusListEnum.SCHEDULED">
        <p-tag [value]="singleStatus.lokaliseKey | translate | uppercase" severity="warning"></p-tag>
      </div>
      <div *ngIf="singleStatus?.name === applicationStatusListEnum.SUBMITTED">
        <p-tag [value]="singleStatus.lokaliseKey | translate | uppercase" severity="success"></p-tag>
      </div>
      <div *ngIf="singleStatus?.name === applicationStatusListEnum.EXPIRED">
        <p-tag [value]="singleStatus.lokaliseKey | translate | uppercase" severity="danger"></p-tag>
      </div>
    </div>
  </div>
</div>
