/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input } from '@angular/core';
import {
  FormBuilderField,
  FormBuilderFieldTypeOption,
} from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-field-details-type-number',
  templateUrl: './field-details-type-number.component.html',
  styleUrls: ['./field-details-type-number.component.scss'],
})
export class FieldDetailsTypeNumberComponent {
  @Input() field: FormBuilderField;
  @Input() option: FormBuilderFieldTypeOption;
  @Input() isInEditMode: boolean = false;
  @Input() isInFieldBuilder: boolean = false;
  @Input() isInModal: boolean = false;
}
