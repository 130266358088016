<h4>{{ staticApplicationDisclosure?.disclosureType | titlecase }}</h4>
<p>{{ disclosureText }}</p>
<div
  class="mb-2 mt-2 flex flex-row w-max"
  [pTooltip]="
    !disclosureSuccessfullyPlayed
      ? ('applicationProcess.staticApplicationDisclosures.humania.tooltipMessage.disclosureNotPlayed' | translate)
      : ''
  "
>
  <div class="pl-2 flex flex-row" [formGroup]="staticApplicationDisclosureAnswerForm">
    <p-radioButton formControlName="answer" [value]="true" class="mr-1"></p-radioButton> Yes
  </div>
  <div class="pl-2 flex flex-row" [formGroup]="staticApplicationDisclosureAnswerForm">
    <p-radioButton formControlName="answer" [value]="false" class="mr-1"></p-radioButton> No
  </div>
</div>
<p-messages severity="warn" *ngIf="showWarningText">
  <ng-template pTemplate>
    <div class="ml-2">{{ disclosureError }}</div>
  </ng-template>
</p-messages>
<div>
  <span
    tooltipPosition="bottom"
    [pTooltip]="
      disclosuresService.isAnyDisclosureDisclaimerPlaying
        ? ('applicationProcess.step.applicationForms.displayItem.disclosureDisclaimerAlreadyPlaying' | translate)
        : ''
    "
  >
    <button
      pButton
      (click)="startPlayingDisclosure()"
      [loading]="isDisclosurePlaying"
      [disabled]="disclosuresService.isAnyDisclosureDisclaimerPlaying"
      [label]="
        isDisclosurePlaying
          ? ('applicationProcess.step.applicationForms.displayItem.disclosure.playingDisclosure' | translate)
          : ('applicationProcess.step.applicationForms.displayItem.disclosure.playDisclosure' | translate)
      "
    ></button>
  </span>
  <button
    class="p-button-danger ml-2"
    *ngIf="isDisclosurePlaying"
    pButton
    (click)="stopPlayingDisclosure()"
    [label]="'applicationProcess.step.applicationForms.displayItem.disclosure.stopDisclosure' | translate"
  ></button>
</div>
<p-toast key="audio-disclosure-message"></p-toast>
