import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationsProcessService, DarkThemeService } from '@iapplication2/services';
import { Themes } from '@iapplication2/interfaces';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-dark-mode-switcher',
  templateUrl: './dark-mode-switcher.component.html',
  styleUrls: ['./dark-mode-switcher.component.scss'],
})
export class DarkModeSwitcherComponent implements OnInit, OnDestroy {
  isLightTheme: boolean;

  unsubscribe: Subject<unknown> = new Subject();

  constructor(private darkThemeService: DarkThemeService, private applicationsProcessService: ApplicationsProcessService) {}

  ngOnInit(): void {
    this.saveThemeIfReflexStarted();
    this.getIsLightTheme();
    this.changedButton();
  }

  saveThemeIfReflexStarted() {
    this.applicationsProcessService.isReflexPage.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      if (value) {
        this.darkThemeService.themeChanged.pipe(take(1)).subscribe((theme: Themes) => {
          this.darkThemeService.savedTheme = theme;
        });
      }
    });
  }

  changedButton() {
    localStorage.setItem('isLightTheme', JSON.stringify(this.isLightTheme));
    this.switchTheme(this.isLightTheme);
  }

  switchTheme(isLightTheme: boolean) {
    isLightTheme ? this.switchThemeToLight() : this.switchThemeToDark();
  }

  switchThemeToLight() {
    this.changeColorScheme(Themes.LIGHT);
    this.darkThemeService.changeTheme(Themes.LIGHT);
  }

  switchThemeToDark() {
    this.changeColorScheme(Themes.DARK);
    this.darkThemeService.changeTheme(Themes.DARK);
  }

  changeColorScheme(scheme) {
    this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
    this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);

    // this.app.darkMode = scheme;
    // this.app.topbarTheme = scheme;
    // this.app.menuTheme = scheme;
  }

  changeStyleSheetsColor(id, value, from) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');

    if (from === 1) {
      // which function invoked this function - change scheme
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      // which function invoked this function - change color
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  getIsLightTheme() {
    if (this.darkThemeService.savedTheme) {
      this.isLightTheme = this.darkThemeService.savedTheme === Themes.LIGHT;
    } else {
      this.isLightTheme = localStorage.getItem('isLightTheme') ? JSON.parse(localStorage.getItem('isLightTheme')) : true;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
