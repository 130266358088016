import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'iapplication2-application-information',
  templateUrl: './application-information.component.html',
  styleUrls: ['./application-information.component.scss'],
})
export class ApplicationInformationComponent implements OnInit{
  applicationId: number;

  constructor(private route: ActivatedRoute){}

  ngOnInit () {
    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
  }
}
