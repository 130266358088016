<small *ngIf="control.errors?.required && control.dirty; else otherErrors" class="p-error">
  {{ 'validations.required' | translate }}
</small>
<ng-template #otherErrors>
  <small *ngIf="control.errors?.radioGroupRequired && control.dirty" class="p-error">
    {{ 'validations.radioGroupRequired' | translate }}
  </small>
  <small *ngIf="control.errors?.disclosureRequiredPlay && control.dirty" class="p-error">
    {{ 'validations.disclosureRequiredPlay' | translate }}
  </small>
  <small *ngIf="control.errors?.email && control.dirty" class="p-error">
    {{ 'validations.invalidEmail' | translate }}
  </small>
  <small *ngIf="control.errors?.minlength && control.dirty" class="p-error">
    {{ 'validations.minLength.requiredLength' | translate: { requiredLength: control.errors?.minlength.requiredLength } }}
  </small>
  <small *ngIf="control.errors?.maxlength && control.dirty" class="p-error">
    <!-- Only {{ control.errors?.maxlength.requiredLength }} characters are allowed. -->
    {{ 'validations.maxLength.requiredLength' | translate: { requiredLength: control.errors?.maxlength.requiredLength } }}
  </small>
  <small *ngIf="control.errors?.invalidPassword && control.dirty" class="p-error">
    {{ 'validations.invalidPassword' | translate }}
  </small>
  <small *ngIf="control.errors?.missmatch && control.dirty" class="p-error">
    {{ 'validations.passwordMissmatch' | translate }}
  </small>
  <small *ngIf="control.errors?.invalidMonth && control.dirty" class="p-error">
    {{ 'validations.invalidMonth' | translate }}
  </small>
  <small *ngIf="control.errors?.invalidCard && control.dirty" class="p-error">
    {{ 'validations.invalidCard' | translate }}
  </small>
  <small *ngIf="control.errors?.invalidFirstName && control.dirty" class="p-error">
    {{ 'validations.invalidFirstName' | translate }}
  </small>
  <small *ngIf="control.errors?.invalidLastName && control.dirty" class="p-error">
    {{ 'validations.invalidLastName' | translate }}
  </small>
  <small *ngIf="control.errors?.yearPassed && control.dirty" class="p-error">
    {{ 'validations.yearPassed' | translate }}
  </small>
  <small *ngIf="control.errors?.invalidTransitNumber && control.dirty" class="p-error">
    {{ 'validations.invalidTransitNumber' | translate }}
  </small>
  <small *ngIf="control.errors?.invalidFinancialNumber && control.dirty" class="p-error">
    {{ 'validations.invalidFinancialNumber' | translate }}
  </small>
  <small *ngIf="control.errors?.invalidAccountNumber && control.dirty" class="p-error">
    {{ 'validations.invalidAccountNumber' | translate }}
  </small>

  <small *ngIf="control.errors?.invalidCVV && control.dirty" class="p-error">
    {{ 'validations.invalidCVV' | translate }}
  </small>

  <small *ngIf="control.errors?.invalidAmexCVV && control.dirty" class="p-error">
    {{ 'validations.InvalidAmexCVV' | translate }}
  </small>

  <small *ngIf="control.errors?.transitNumber && control.dirty"
         class="p-error"> {{ 'validations.transitNumber' | translate }} </small>
  <small *ngIf="control.errors?.financialNumber && control.dirty"
         class="p-error"> {{ 'validations.financialNumber' | translate }} </small>
  <small *ngIf="control.errors?.accountNumber && control.dirty"
         class="p-error"> {{ 'validations.accountNumber' | translate }} </small>
  <small *ngIf="control.errors?.invalidTitleAndGender && control.dirty" class="p-error">
    {{ 'validations.invalidTitleAndGender' | translate }}
  </small>

  <!--  Custom validators errors -->
  <small *ngIf="control.errors?.containsValue && control.dirty" class="p-error">
    {{ 'errorMessages.containsValue' | translate: {values: control.errors?.containsValue} }}
  </small>
  <small *ngIf="control.errors?.doesNotContainValue && control.dirty" class="p-error">
    {{ 'errorMessages.doesNotContainValue' | translate: {values: control.errors?.doesNotContainValue} }}
  </small>
  <small *ngIf="control.errors?.hasFixedLength && control.dirty" class="p-error">
    {{
    'errorMessages.hasFixedLength'
      | translate
      : {
        options: control.errors?.hasFixedLength?.slice(0, -1)?.join(', '),
        lastOption: control.errors?.hasFixedLength?.slice(-1)[0]
      }
    }}
  </small>
  <small *ngIf="control.errors?.hasLengthBetween && control.dirty" class="p-error">
    {{
    'errorMessages.hasLengthBetween'
      | translate
      : {
        min: control.errors?.hasLengthBetween?.min,
        max: control.errors?.hasLengthBetween?.max
      }
    }}
  </small>
  <small *ngIf="control.errors?.nameFormat && control.dirty" class="p-error">
    {{ 'errorMessages.nameFormat' | translate }}
  </small>
  <small *ngIf="control.errors?.postalCodeFormat && control.dirty" class="p-error">
    {{ 'errorMessages.postalCodeFormat' | translate }}
  </small>
</ng-template>
