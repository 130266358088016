<div clsas="mt-4">
  <div class="details-field-header flex justify-content-between">
    <h4 class="details-field-header-title">Field details</h4>
  </div>
  <form [formGroup]="mainForm">
    <div formGroupName="fieldForm">
      <div class="details-field-content-property my-2" fxLayout="row">
        <div class="details-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">Field label:</div>
        <div class="details-field-content-property-content" fxLayoutAlign="start center">
          <div *ngIf="!isInEditMode || field.groupOptions?.id || field.predefinedFieldTableId">
            {{
              (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
                | translate: { Default: field?.options?.customFieldLabel }
            }}
          </div>
          <div *ngIf="isInEditMode && !field.groupOptions?.id && !field.predefinedFieldTableId">
            <input
              style="width: 100%"
              type="text"
              class="p-inputtext p-component"
              pInputText
              placeholder="Field label"
              formControlName="customFieldLabel"
              (input)="inputValueChanged()"
            />
            <p *ngIf="mainForm.controls.fieldForm.get('customFieldLabel')?.errors?.duplicateFieldLabel">This name is already in use</p>
          </div>
          <div *ngIf="isInEditMode && !isInCreation && field.groupOptions?.id">
            <i
              *ngIf="!field.table?.type"
              class="pi pi-exclamation-circle ml-2"
              pTooltip="This field is part of a group and can only have the name changed through the group settings."
              style="color: #a3c4f5; font-size: 0.9rem"
            ></i>
          </div>
        </div>
      </div>
      <div class="details-field-content-property my-2" fxLayout="row" *ngIf="field?.fieldType?.type !== 'radioGroup'">
        <div class="details-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">Field type:</div>
        <div class="details-field-content-property-content fieldType-chip" [ngClass]="field?.fieldType?.type">
          {{ field?.fieldType?.label }}
        </div>
      </div>
    </div>
  </form>
</div>
