import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { User } from '@iapplication2/interfaces';
import { UsersService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-change-new-business-user',
  templateUrl: './change-new-business-user.component.html',
  styleUrls: ['./change-new-business-user.component.scss'],
})
export class ChangeNewBusinessUserComponent implements OnDestroy {
  @Input() currentNewBusinessUser: User;
  newBusinessUserList: User[] = [];
  filteredNewBusinessUsersList: User[] = [];
  selectedNewBusinessUser: User;
  @ViewChild('op') overlayPanel;
  @Output() newBusinessUserChanged: EventEmitter<User> = new EventEmitter();

  private unsubscribe: Subject<unknown> = new Subject();

  constructor(private usersService: UsersService) {}

  onChangeNewBusinessUserOverlayShow() {
    if (this.newBusinessUserList.length === 0) {
      this.usersService
        .getUserList()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.newBusinessUserList = res as User[];
        });
    }
  }

  filterNewBusinessUsers(event) {
    this.usersService
      .searchUsers(event.query)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.filteredNewBusinessUsersList = res as User[];
      });
  }

  clearedInput() {
    this.filteredNewBusinessUsersList = this.newBusinessUserList;
  }

  cancelOverlay() {
    this.overlayPanel.hide();
  }

  changeNewBusinessUser() {
    this.currentNewBusinessUser = this.selectedNewBusinessUser;
    this.newBusinessUserChanged.emit(this.currentNewBusinessUser);
    this.cancelOverlay();
  }

  assignToMe() {
    // TODO implement assign to me functionality
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
