import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilderFieldTypeOption } from '@iapplication2/interfaces';
import { Subject } from 'rxjs';
import { BaseFieldDataDirective } from '../../../../baseFieldData.directive';
import { register } from '../../../../registryComponent';

@register('checkbox')
@Component({
  selector: 'iapplication2-checkbox-field-display',
  templateUrl: './checkbox-field-display.component.html',
  styleUrls: ['./checkbox-field-display.component.scss'],
})
export class CheckboxFieldDisplayComponent extends BaseFieldDataDirective implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('checkboxInput') checkboxInput;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setBehaviourBasedOnFieldOptions();
  }

  setBehaviourBasedOnFieldOptions() {
    switch (true) {
      case this.field.fieldTypeOptions.findIndex((option: FormBuilderFieldTypeOption) => option.id === 8) !== -1:
        if (this.formFieldControl.value === null || this.formFieldControl.value === undefined) {
          this.formFieldControl.setValue(true);
        }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
