import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { GenderEnum, RatesProductAdvisor } from '@iapplication2/interfaces';
import { ApplicationsProcessService, DatesService } from '@iapplication2/services';
import _ = require('lodash');
import { Subject } from 'rxjs';
import { debounceTime, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Decimal } from 'decimal.js';

@Component({
  selector: 'iapplication2-product-selection-card',
  templateUrl: './product-selection-card.component.html',
  styleUrls: ['./product-selection-card.component.scss'],
})
export class ProductSelectionCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ratesProduct: RatesProductAdvisor;
  @Input() form: FormGroup;
  @Input() isEverestAllowed;
  @Input() everestMultiProductObject;
  @Input() isAnyProductSelected: boolean;
  @Input() maxHumaniaCoverage: number;
  @Input() currentHumaniaCoverage: number;
  @Output() everestValueUpdated: EventEmitter<number> = new EventEmitter();
  @Output() resetEverestValue: EventEmitter<number> = new EventEmitter();
  @Output() changedProductSelection: EventEmitter<unknown> = new EventEmitter();
  unsubscribe: Subject<unknown> = new Subject();

  ratesSteps = [];
  coverageForm: FormGroup;
  monthlyPremium: string;
  yearlyPremium: string;
  everestPremium: string;
  gender: string;
  smokerStatus: string;
  reflexSmokerStatus: boolean;
  postalCode: string;
  province: string;
  loadingRate = true;
  FormControlType = FormControl;
  lastRateStepAdded: number;
  oldCoverageValue: number;
  productVersion: number;

  getProductRatesDebounced = _.debounce(() => this.getProductRates(), 500);

  constructor(public applicationsProcessService: ApplicationsProcessService, private datesService: DatesService) {}

  ngOnInit(): void {
    this.generateRatesSteps();
    this.createInitialForm();
    this.trackFormChanges();
    this.getInitialFormValues();
    this.getProductRatesDebounced();
    this.loadSelectedProductData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.everestMultiProductObject?.currentValue) {
      this.everestFormControl?.setValue(this.everestMultiProductObject[this.ratesProduct.iappProduct.id], { emitEvent: false });
      this.ratesProduct.iappProduct.hasEverestAddon =
        this.everestFormControl?.value !== undefined ? this.everestFormControl?.value : this.ratesProduct.iappProduct.hasEverestAddon;
      this.getProductRatesDebounced();
    }
    if (changes.isEverestAllowed && !changes.isEverestAllowed.isFirstChange()) {
      if (!this.isEverestAllowed) {
        this.everestFormControl?.setValue(false);
      }
    }
  }

  inputChanged(value) {
    if (value) {
      const roundedValue = Math.round(value / 500) * 500;

      if (this.lastRateStepAdded) {
        const index = this.ratesSteps.findIndex((step) => step === this.lastRateStepAdded);
        if (index > -1) {
          this.ratesSteps.splice(index, 1);
        }
      }

      this.addNewRateStep(roundedValue);

      if (this.ratesProduct?.isHumania) {
        let newCoverageAfterChange = roundedValue + this.currentHumaniaCoverage - this.oldCoverageValue;

        /* If the product was not previously selected, then the old coverage value should not be considered,
         * as it was not added before to the total humania coverage */
        if (!this.ratesProduct?.iappProduct?.selected) {
          newCoverageAfterChange = newCoverageAfterChange + this.oldCoverageValue;
        }

        const newValueToSet =
          newCoverageAfterChange > this.maxHumaniaCoverage
            ? roundedValue - (newCoverageAfterChange - this.maxHumaniaCoverage)
            : roundedValue;

        if (newCoverageAfterChange > this.maxHumaniaCoverage) {
          this.addNewRateStep(newValueToSet);
        }

        this.setCoverageAndGetProductRates(newValueToSet);
      } else {
        this.setCoverageAndGetProductRates(roundedValue);
      }
    }
  }

  private setCoverageAndGetProductRates(coverageToSet: number) {
    if (coverageToSet < 0) {
      coverageToSet = this.ratesProduct.min_coverage;
    }
    this.coverageFormControl.setValue(coverageToSet, { emitEvent: false });
    this.ratesProduct.iappProduct.coverage = coverageToSet;
    this.coverageDropdownFormControl.setValue(coverageToSet);
    this.oldCoverageValue = coverageToSet;

    this.getProductRatesDebounced();
  }

  private addNewRateStep(value) {
    if (!this.ratesSteps.includes(value) && value >= this.ratesProduct?.min_coverage && value <= this.ratesProduct?.max_coverage) {
      this.ratesSteps.push(value);
      this.lastRateStepAdded = value;
      this.ratesSteps.sort((ratesStep1, ratesStep2) => ratesStep1 - ratesStep2);
    }
  }

  private generateRatesSteps() {
    const list = [];
    const step = 5000;
    let stepToAdd = this.ratesProduct.max_coverage > 10000 ? 10000 : 1000;

    if (this.ratesProduct?.min_coverage % step === 0) list.push(this.ratesProduct?.min_coverage);

    while (stepToAdd > this.ratesProduct.min_coverage && stepToAdd < this.ratesProduct.max_coverage) {
      list.push(stepToAdd);
      stepToAdd += step;
    }

    if (this.ratesProduct?.min_coverage !== this.ratesProduct.max_coverage && this.ratesProduct.max_coverage % step === 0) {
      list.push(this.ratesProduct.max_coverage);
    }

    if (!list.includes(this.ratesProduct?.coverage)) {
      if (this.ratesProduct?.coverage) {
        list.push(this.ratesProduct?.coverage);
      }
    }

    this.ratesSteps = list.filter((ratesStep) => ratesStep % step === 0 || ratesStep === this.ratesProduct?.coverage);

    this.ratesSteps.sort((ratesStep1, ratesStep2) => ratesStep1 - ratesStep2);
  }

  private createInitialForm() {
    this.coverageForm = new FormGroup({
      coverage: new FormControl(null),
      coverageDropdown: new FormControl(null),
      everest: new FormControl(null),
    });
    this.coverageFormControl?.setValue(
      this.ratesProduct.coverage || this.ratesProduct.iappProduct.coverage || this.ratesProduct.min_coverage || null
    );
    this.coverageDropdownFormControl?.setValue(this.coverageFormControl?.value || this.ratesSteps[0]);
    this.everestFormControl.setValue(this.everestMultiProductObject[this.ratesProduct.iappProduct.id]);
    if (this.ratesProduct.min_coverage === this.ratesProduct.max_coverage) {
      this.coverageForm.get('coverage').disable();
    }
  }

  private getInitialFormValues() {
    this.gender = this.genderStatusFormControl?.value;
    this.smokerStatus = this.smokerStatusFormControl?.value;
    this.reflexSmokerStatus = this.reflexSmokerStatusFormControl?.value;
    this.province = this.provinceFormControl?.value?.abbreviation;
    this.postalCode = this.postalCodeFormControl?.value;
  }

  private getProductRates() {
    if (this.gender && (this.smokerStatus !== null || this.reflexSmokerStatus !== null) && this.coverageFormControl?.value) {
      const body = {
        dob: this.datesService.convertDayMonthYearToYearMonthDay(this.birthDateStatusFormControl?.value),
        smoker: this.getSmokerBasedOnProductType(),
        gender: this.genderStatusFormControl?.value === GenderEnum.Male ? 'm' : 'f',
        benefit_amount: this.coverageFormControl?.value,
        product_code: this.ratesProduct?.code,
        add_everest: this.getEverestValue() ? this.getEverestValue() : false,
        province: this.provinceFormControl?.value?.abbreviation,
        product_risk: this.ratesProduct?.risk,
        product_term: this.ratesProduct.term,
      };

      if (!body.province) {
        delete body.add_everest;
      }

      this.loadingRate = true;
      this.applicationsProcessService
        .getProductRates(this.ratesProduct.iappProduct.productType, body)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: any) => {
          this.monthlyPremium = this.getMonthlyRate(res);
          this.yearlyPremium = new Decimal(Number(this.monthlyPremium) * 12).toFixed(2);
          this.everestPremium = this.getEverestPrimum(res).toString();
          this.productVersion = this.getProductVersion(res);

          this.ratesProduct = {
            ...this.ratesProduct,
            monthlyPremium: this.monthlyPremium,
            yearlyPremium: this.yearlyPremium,
            everestPremium: this.everestPremium,
            productVersion: this.productVersion,
          };
          this.loadingRate = false;
          this.productSelectionChanged();
        });
    }
  }

  private getSmokerBasedOnProductType(): 'y' | 'n' {
    if (this.ratesProduct?.isHumania) {
      return this.reflexSmokerStatusFormControl?.value === true ? 'y' : 'n';
    } else {
      return this.smokerStatusFormControl?.value === 'no' ? 'n' : 'y';
    }
  }

  private getEverestValue(): boolean {
    if (!this.isAnyProductSelected) {
      return true;
    }
    return this.isEverestAllowed && this.province && this.postalCode ? this.everestFormControl?.value : false;
  }

  private loadSelectedProductData() {
    if (this.ratesProduct.iappProduct.selected || this.ratesProduct?.selected) {
      this.coverageFormControl?.setValue(
        this.ratesProduct.coverage || this.ratesProduct.iappProduct.coverage || this.ratesProduct.min_coverage
      );
      this.everestFormControl?.setValue(this.ratesProduct.hasEverest);
      this.inputChanged(this.coverageFormControl?.value);
    }
  }

  changedDropdown(event) {
    this.oldCoverageValue = this.coverageFormControl?.value;
    const newCoverageAfterChange = this.ratesProduct?.isHumania
      ? event.value + this.currentHumaniaCoverage - this.oldCoverageValue
      : event.value;

    let finalCoverage =
      this.ratesProduct?.isHumania && newCoverageAfterChange > this.maxHumaniaCoverage
        ? event.value - (newCoverageAfterChange - this.maxHumaniaCoverage)
        : event.value;

    if (finalCoverage < 0) {
      finalCoverage = this.ratesProduct.min_coverage;
    }
    this.coverageFormControl.setValue(finalCoverage);
    this.coverageDropdownFormControl.setValue(finalCoverage);
    this.getProductRatesDebounced();
  }

  productSelectionChanged(event?) {
    event?.originalEvent.preventDefault();
    event?.originalEvent.stopPropagation();

    this.ratesProduct = {
      ...this.ratesProduct,
      monthlyPremium: this.monthlyPremium,
      yearlyPremium: this.yearlyPremium,
      everestPremium: this.everestPremium,
      productVersion: this.productVersion,
      coverage: this.coverageFormControl.value || this.ratesProduct.min_coverage,
      selected: !!event?.checked || this.ratesProduct?.iappProduct?.selected || this.ratesProduct?.selected,
    };

    if (
      this.ratesProduct.iappProduct.selected &&
      this.isEverestAllowed &&
      Object.values(this.everestMultiProductObject).every((value) => value === false) &&
      event
    ) {
      this.everestFormControl.setValue(true);
    }
    if (!this.ratesProduct.iappProduct.selected && event) {
      this.everestFormControl.setValue(false);
    }
    this.changedProductSelection.emit(this.ratesProduct);
  }

  private getMonthlyRate(response: any): string {
    const key = Object.keys(response.data.rate)[0];
    const premium = response.data.rate[key]?.premium;
    if (premium.toString().slice(premium.toString().length - 5) === '99999') {
      return new Decimal(new Decimal(premium).toFixed(3)).toFixed(2);
    } else {
      return new Decimal(premium).toFixed(2);
    }
  }

  private getEverestPrimum(response: any): number {
    const key = Object.keys(response.data.rate)[0];
    const rateObject = response.data.rate[key];
    return rateObject?.everest_premium + rateObject.everest_tax;
  }

  private getProductVersion(response: any): number {
    return response.data.product?.version;
  }

  private trackFormChanges() {
    this.genderStatusFormControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.gender = this.genderStatusFormControl?.value;
      this.getProductRatesDebounced();
    });
    this.smokerStatusFormControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.smokerStatus = this.smokerStatusFormControl?.value;
      this.getProductRatesDebounced();
    });
    this.postalCodeFormControl.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(() => {
      this.postalCode = this.postalCodeFormControl?.value;
      if (!this.postalCode) {
        this.everestFormControl.setValue(false);
      }
    });
    this.provinceFormControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.province = this.provinceFormControl?.value?.abbreviation;
      if (!this.province) {
        this.everestFormControl.setValue(false);
      }
      this.getProductRatesDebounced();
    });
    this.everestFormControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.everestFormControl.value === true) {
        this.everestValueUpdated.emit(this.ratesProduct.iappProduct.id);
      } else {
        this.resetEverestValue.emit(this.ratesProduct.iappProduct.id);
        this.getProductRatesDebounced();
      }
    });
    const initialValue = this.coverageFormControl.value;
    this.coverageFormControl.valueChanges
      .pipe(startWith(initialValue), pairwise(), takeUntil(this.unsubscribe), debounceTime(500))
      .subscribe((data) => {
        this.ratesProduct.iappProduct.coverage = data[1];
        this.productSelectionChanged();
        this.inputChanged(data[1]);
      });
  }

  get coverageFormControl(): AbstractControl {
    return this.coverageForm?.get('coverage');
  }

  get coverageDropdownFormControl(): AbstractControl {
    return this.coverageForm?.get('coverageDropdown');
  }

  get everestFormControl(): AbstractControl {
    return this.coverageForm?.get('everest');
  }

  get provinceFormControl(): AbstractControl {
    return this.form.get('province');
  }

  get postalCodeFormControl(): AbstractControl {
    return this.form.get('postalCode');
  }

  get genderStatusFormControl(): AbstractControl {
    return this.form.get('gender');
  }

  get smokerStatusFormControl(): AbstractControl {
    return this.form.get('smokerStatus');
  }

  get birthDateStatusFormControl(): AbstractControl {
    return this.form.get('birthDate');
  }

  get reflexSmokerStatusFormControl(): AbstractControl {
    return this.form.get('reflexSmokerStatus');
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
