import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FormArea,
  FormBuilderField,
  FormSection,
  Product,
  SectionTypes,
  ApplicationFormData,
  FormBuilderItem,
} from '@iapplication2/interfaces';
import { InteractiveFormBuilderService, ApplicationsProcessService } from '@iapplication2/services';
import _ = require('lodash');
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-dynamic-questionnaire-section',
  templateUrl: './dynamic-questionnaire-section.component.html',
  styleUrls: ['./dynamic-questionnaire-section.component.scss'],
})
export class DynamicQuestionnaireSectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedProducts: Product[] = [];
  @Output() medicalQuestionnairesAreValid: EventEmitter<boolean> = new EventEmitter();
  changeTriggered = 0;
  @Input() applicationId: number;
  listOfItems: FormBuilderItem[] = [];

  dynamicQuestionnaireForm: FormGroup = new FormGroup({});

  unsubscribe: Subject<unknown> = new Subject();

  formDataObject;

  FormGroupType = FormGroup;

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private applicationsProcessService: ApplicationsProcessService
  ) {}

  ngOnInit(): void {
    this.selectedProducts?.forEach((product: Product) => this.clearHiddenFields(product));
    this.applicationsProcessService
      .getFormData(this.applicationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: { [key: string]: ApplicationFormData[] }) => {
          this.formDataObject = res;
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProducts?.previousValue?.length !== changes.selectedProducts?.currentValue?.length) {
      this.onMedicalQuestionnaireChanged();
      this.selectedProducts.forEach((product: Product) => {
        this.clearHiddenFields(product);
        if (!product.questionnaireSections) {
          this.getQuestionnaireSections(product);
        }
      });
    }
  }

  clearHiddenFields(product: Product): void {
    if (!product.hiddenFields) {
      product.hiddenFields = [];
    }
    if (product.coverage <= product.maxCoverageNoQuestionnaire) {
      product.hiddenFields = [];
    }
  }

  onMedicalQuestionnaireChanged() {
    let foundAtLeatOneHidden = false;

    let atLeastOneProductQuestionnaire = false;
    this.selectedProducts.forEach((product: Product) => {
      if (product.questionnaireSections && product.coverage > product.maxCoverageNoQuestionnaire) {
        atLeastOneProductQuestionnaire = true;
      }
    });

    if (atLeastOneProductQuestionnaire) {
      this.selectedProducts.forEach((product: Product) => {
        product.questionnaireSections?.[0].formAreas?.forEach((formArea: FormArea) => {
          formArea.fields?.forEach((field: FormBuilderField) => {
            if (field.conditions && product.hiddenFields.includes(field.id)) {
              foundAtLeatOneHidden = true;
            }
          });
        });
      });

      this.medicalQuestionnairesAreValid.emit(!foundAtLeatOneHidden);
    } else {
      this.medicalQuestionnairesAreValid.emit(true);
    }
  }

  private getQuestionnaireSections(product: Product) {
    this.interactiveFormBuilderService
      .getFormSectionsListByProductFormId(product.productFormId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FormSection[]) => {
        const questionnaireSections: FormSection[] = res.filter((section: FormSection) => section.type.name === SectionTypes.QUESTIONNAIRE);
        if (questionnaireSections.length) {
          this.dynamicQuestionnaireForm.addControl(product.productFormId.toString(), new FormGroup({}));
          product.questionnaireSections = questionnaireSections;

          product.questionnaireSections.forEach((section) => {
            (this.dynamicQuestionnaireForm.get(product.productFormId.toString()) as FormGroup).addControl(
              section.id.toString(),
              new FormGroup({})
            );
            this.interactiveFormBuilderService
              .getFormAreasBySectionId(section.id)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((res: FormArea) => {
                section.formAreas = res;

                this.sortAreasInSection(section);

                section.formAreas.forEach((area) => {
                  (this.dynamicQuestionnaireForm.get(product.productFormId.toString()).get(section.id.toString()) as FormGroup).addControl(
                    area.id.toString(),
                    new FormGroup({})
                  );
                  area.fields.forEach((field) => {
                    this.listOfItems.push(field);
                    this.addFieldToListOfAllFields(field, section.id, area.id, product.productFormId);
                    if (this.interactiveFormBuilderService.isFormBuilderItemField(field)) {
                      (
                        this.dynamicQuestionnaireForm
                          .get(product.productFormId.toString())
                          .get(section.id.toString())
                          .get(area.id.toString()) as FormGroup
                      ).addControl(field.id.toString(), new FormControl(null));
                    } else if (this.interactiveFormBuilderService.isFormBuilderItemGroup(field)) {
                      (
                        this.dynamicQuestionnaireForm
                          .get(product.productFormId.toString())
                          .get(section.id.toString())
                          .get(area.id.toString()) as FormGroup
                      ).addControl(field.groupOptions.id.toString(), new FormControl(null, [Validators.required]));
                    }
                  });
                });

                this.changeTriggered++;
              });
          });
        }
      });
  }

  private sortAreasInSection(section: FormSection): void {
    if (section?.formAreas?.length) {
      section.formAreas = section.formAreas.sort((area1: FormArea, area2: FormArea) => area1.position - area2.position);
    }
  }

  addFieldToListOfAllFields(field: FormBuilderField, sectionId: string, formAreaId: string, productFormId: number) {
    field.productFormId = productFormId;
    field.sectionId = parseInt(sectionId);

    this.applicationsProcessService.allFieldsInAllForms.push(field);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
