export interface FormBuilderFieldValidatorConfiguration {
  id: number;
  type: ConfigurationType;
  possibleValues?: string[];
  value: string | number;
  selectionDisplayConfiguration?: {
    maxFieldLength?: number;
    onlyPositive?: boolean;
  };
}

export enum ConfigurationType {
  INPUT = 'input',
  DROPDOWN = 'dropdown',
  CHECKBOX = 'checkbox',
  NUMBERINPUT = 'numberInput',
  CHIP = 'chip',
}
