import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Application, Client, DtcPolicy, Entities, Policy, QuickQuote } from '@iapplication2/interfaces';
import { ApplicationsManagementService, ClientManagementService, QuickQuoteManagementService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-global-search-page',
  templateUrl: './global-search-page.component.html',
  styleUrls: ['./global-search-page.component.scss'],
})
export class GlobalSearchPageComponent implements OnInit, OnDestroy {
  clients: Client[] = [];
  loading: boolean;

  applications: Application[];
  loadingApplications: boolean;

  policies: Policy[];
  loadingPolicies: boolean;

  DTCApplications: DtcPolicy[];
  loadingDTCApplications: boolean;

  quickQuotes: QuickQuote[];
  loadingQuickQuotes: boolean;

  globalSearchForm: FormGroup;

  availableEntities: any;

  entitiesEnum = Entities;

  displayConditions: {
    showPoliciesSection: boolean;
    showApplicationsSection: boolean;
    showDTCApplicationsSection: boolean;
    showQuotesSection: boolean;
    showClientsSection: boolean;
  } = {
    showPoliciesSection: true,
    showApplicationsSection: true,
    showDTCApplicationsSection: true,
    showQuotesSection: true,
    showClientsSection: true,
  };

  constructor(
    private clientManagementService: ClientManagementService,
    private applicationsManagementService: ApplicationsManagementService,
    private quickQuoteManagementService: QuickQuoteManagementService,
    private route: ActivatedRoute
  ) {}

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  ngOnInit(): void {
    this.createGlobalSearchForm();
    this.globalSearchForm.controls['selectedEntities'].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.setDisplayConditions();
    });
    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
      this.globalSearchForm.controls['searchQuery'].setValue(params['q']);
      this.getEntities();
    });
  }

  setDisplayConditions() {
    this.setDisplayConditionsToFalse();
    this.globalSearchForm.controls['selectedEntities'].value.forEach((entity) => {
      if (entity.field === this.entitiesEnum.APPLICATIONS) {
        this.displayConditions.showApplicationsSection = true;
      }
      if (entity.field === this.entitiesEnum.POLICIES) {
        this.displayConditions.showPoliciesSection = true;
      }
      if (entity.field === this.entitiesEnum.DTC) {
        this.displayConditions.showDTCApplicationsSection = true;
      }
      if (entity.field === this.entitiesEnum.QUOTES) {
        this.displayConditions.showQuotesSection = true;
      }
      if (entity.field === this.entitiesEnum.CLIENTS) {
        this.displayConditions.showClientsSection = true;
      }
    });
  }

  setDisplayConditionsToFalse() {
    Object.keys(this.displayConditions).forEach((condition) => (this.displayConditions[condition] = false));
  }

  createGlobalSearchForm() {
    this.globalSearchForm = new FormGroup({
      searchQuery: new FormControl(null),
      selectedEntities: new FormControl(null),
    });
    this.setEntities();
  }

  setEntities() {
    this.availableEntities = [
      {
        field: 'Policies',
        header: 'Policies',
      },
      {
        field: 'Applications',
        header: 'Applications',
      },
      {
        field: 'DTC Applications',
        header: 'DTC Applications',
      },
      {
        field: 'Quotes',
        header: 'Quotes',
      },
      {
        field: 'Clients',
        header: 'Clients',
      },
    ];
    this.globalSearchForm.controls['selectedEntities'].setValue(this.availableEntities);
  }

  getEntities() {
    if (this.displayConditions.showClientsSection) {
      this.getClients();
    }

    if (this.displayConditions.showApplicationsSection) {
      this.getApplications();
    }

    if (this.displayConditions.showPoliciesSection) {
      this.getPolicies();
    }

    if (this.displayConditions.showDTCApplicationsSection) {
      this.getDtcPolicies();
    }

    if (this.displayConditions.showQuotesSection) {
      this.getQuickQuotes();
    }
  }

  // TODO: Send null/undefined for "limit" instead of hardcoded number, and have BE return all if it's not specified

  getClients() {
    this.loading = true;
    this.clientManagementService
      .getAllClientsFiltered(1, '100', undefined, 'DESC', this.globalSearchForm.controls['searchQuery'].value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.clients = res.data;
        this.loading = false;
      });
  }

  getApplications() {
    this.loadingApplications = true;
    this.applicationsManagementService
      .getApplications(1, '100', undefined, 'DESC', this.globalSearchForm.controls['searchQuery'].value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        console.log(res);
        this.applications = res.data as Application[];
        this.loadingApplications = false;
      });
  }

  getPolicies() {
    this.loadingPolicies = true;
    this.applicationsManagementService
      .getPolicies(1, '5', undefined, 'DESC', this.globalSearchForm.controls['searchQuery'].value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.policies = res.data as Policy[];

        this.loadingPolicies = false;
      });
  }

  getDtcPolicies() {
    this.loadingDTCApplications = true;
    this.applicationsManagementService
      .getDtcApplications(1, '100', undefined, 'DESC', this.globalSearchForm.controls['searchQuery'].value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.DTCApplications = res.data as DtcPolicy[];
        this.loadingDTCApplications = false;
      });
  }

  getQuickQuotes() {
    this.loadingQuickQuotes = true;
    this.quickQuoteManagementService
      .getQuickQuotes(1, '100', undefined, 'DESC', this.globalSearchForm.controls['searchQuery'].value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.quickQuotes = res.body as QuickQuote[];
        this.loadingQuickQuotes = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
