import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FieldDetailsOptions,
  FormBuilderField,
  FormBuilderFieldTypeType,
  FormBuilderGroup,
  FormBuilderItem,
  ModalType,
  Themes,
} from '@iapplication2/interfaces';
import { CanvasService, DarkThemeService, InteractiveFormBuilderService, ProgressSpinnerService } from '@iapplication2/services';
import _ = require('lodash');
import { MenuItem } from 'primeng/api';
import { TooltipOptions } from 'primeng/tooltip';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-form-builder-group-display',
  templateUrl: './form-builder-group-display.component.html',
  styleUrls: ['./form-builder-group-display.component.scss'],
})
export class FormBuilderGroupDisplayComponent implements OnInit, OnDestroy {
  @Input() formBuilderGroup: FormBuilderGroup;
  showExpandedContent = false;
  groupItems: MenuItem[];
  isGroupLabelInEditMode = false;
  isDarkTheme: boolean;

  FieldDetailsOptionsEnum = FieldDetailsOptions;
  FormBuilderFieldTypeTypeEnum = FormBuilderFieldTypeType;

  requiredAndDefault = FieldDetailsOptions.MARKED_AS_REQUIRED + ' ' + FieldDetailsOptions.MARKED_AS_DEFAULT;
  defaultAndRequired = FieldDetailsOptions.MARKED_AS_DEFAULT + ' ' + FieldDetailsOptions.MARKED_AS_REQUIRED;

  unsubscribe: Subject<unknown> = new Subject();

  constructor(
    private canvasService: CanvasService,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private darkThemeService: DarkThemeService,
    private progressSpinnerService: ProgressSpinnerService
  ) {}

  ngOnInit(): void {
    this.watchThemeChange();
    this.createGroupSettingsMenu();
  }

  watchThemeChange() {
    this.darkThemeService.themeChanged.subscribe((value) => {
      this.isDarkTheme = value === Themes.DARK;
    });
  }

  public highlightGroupOnCanvas(): void {
    this.canvasService.hoveredOverFormBuilderGroup(this.formBuilderGroup);
  }

  public unHighlightGroupOnCanvas(): void {
    this.canvasService.leftHoverOverFormBuilderGroup(this.formBuilderGroup);
  }

  public toggleExpandedContent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.showExpandedContent = !this.showExpandedContent;
  }

  public drop(event: CdkDragDrop<FormBuilderField[]>): void {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  public toggleGroupMenu(item: FormBuilderGroup, menu: any, ev: any): void {
    this.setEditOrViewMenuOption(item);

    const editMenuItem = this.groupItems[0].items[0];
    const deleteMenuItem = this.groupItems[0].items[1];
    const displayConditionsMenuItem = this.groupItems[0].items[2];
    editMenuItem.command = () => this.editGroup();
    deleteMenuItem.command = () => this.deleteGroup(item);
    displayConditionsMenuItem.command = () => this.openDisplayConditionsDialog(item);
    menu.toggle(ev);
  }

  private editGroup(): void {
    this.interactiveFormBuilderService.sidebarItemSelected(this.formBuilderGroup);
    this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.EDIT);
  }

  private openDisplayConditionsDialog(item: FormBuilderItem) {
    this.interactiveFormBuilderService.showDisplayConditionsDialog.next(void 0);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions = new ReplaySubject(1);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions.next(item);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions.complete();
  }

  private deleteGroup(group: FormBuilderGroup): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.interactiveFormBuilderService.deleteGroup(group);
  }

  private createGroupSettingsMenu(): void {
    this.groupItems = [
      {
        label: 'Group settings',
        items: [
          {
            label: 'Edit group',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete group',
            icon: 'pi pi-trash',
          },
          {
            label: 'Set display conditions',
            icon: 'pi pi-sitemap',
          },
        ],
      },
    ];
  }

  setEditOrViewMenuOption(item: FormBuilderGroup) {
    if (this.groupItems && this.groupItems[0]?.items[0]) {
      if (item?.groupOptions?.predefinedGroupId) {
        this.groupItems[0].items[0].disabled = true;
        const tooltipOptions: TooltipOptions = {
          tooltipLabel: 'A group created from a predefined field cannot be modified,',
          appendTo: 'body',
          tooltipPosition: 'left',
          showDelay: 300,
          hideDelay: 300,
        };
        this.groupItems[0].items[0].tooltipOptions = tooltipOptions;
      } else {
        this.groupItems[0].items[0].disabled = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
