import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientInformationComponent } from './client-information.component';
import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { FormFieldErrorModule, DateMaskModule } from '@iapplication2/ui';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ClientInformationComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    InputMaskModule,
    InputTextareaModule,
    CalendarModule,
    RadioButtonModule,
    FormFieldErrorModule,
    SharedTranslateModule,
    DateMaskModule,
  ],
  exports: [ClientInformationComponent],
})
export class ClientInformationModule {}
