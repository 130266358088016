<div fxLayout="column" class="mb-3" [formGroup]="parentFormGroup">
  <div class="flex">
    <iapplication2-required
      *ngIf="!isViewApplication"
      [required]="formFieldControl.errors?.required && !field.table"
      [value]="formFieldControl.value"
    ></iapplication2-required>
    <label *ngIf="!hideLabel">{{
      (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key') | translate: { Default: field?.options?.customFieldLabel }
    }}</label>
  </div>
  <div class="flex flex-row">
    <div class="flex flex-column">
      <textarea
        *ngIf="textArea"
        pInputTextarea
        [rows]="6"
        [cols]="50"
        type="text"
        class="mt-1"
        [formControlName]="field.id"
        [placeholder]="
          (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
            | translate: { Default: field?.options?.customFieldLabel }
        "
        [readonly]="isReadonly"
        [class.isReadonlyInput]="isReadonly"
        [tabindex]="tabIndex"
        [iapplication2OnlyNumbers]="onlyNumbers"
      ></textarea>
      <input
        *ngIf="!textArea && !isPhoneNumber && !postalCode"
        type="text"
        pInputText
        class="mt-1"
        [formControlName]="field.id"
        [placeholder]="
          (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
            | translate: { Default: field?.options?.customFieldLabel }
        "
        [readonly]="isReadonly"
        [class.isReadonlyInput]="isReadonly"
        [tabindex]="tabIndex"
        [iapplication2OnlyNumbers]="onlyNumbers"
      />
      <p-inputMask
        *ngIf="!textArea && !isPhoneNumber && postalCode"
        class="mt-1"
        type="text"
        [formControlName]="field.id"
        [placeholder]="
          (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
            | translate: { Default: field?.options?.customFieldLabel }
        "
        [readonly]="isReadonly"
        [class.isReadonlyInput]="isReadonly"
        [tabindex]="localTabIndex"
        mask="a9a 9a9"
      ></p-inputMask>
      <p-inputMask
        *ngIf="!textArea && isPhoneNumber && !postalCode"
        class="mt-1"
        type="text"
        [formControlName]="field.id"
        [placeholder]="
          (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
            | translate: { Default: field?.options?.customFieldLabel }
        "
        [readonly]="isReadonly"
        [class.isReadonlyInput]="isReadonly"
        [tabindex]="localTabIndex"
        mask="(999) 999-9999"
      ></p-inputMask>
    </div>
    <iapplication2-required
      class="flex ai-center ml-2"
      style="min-width: 20px"
      [rowRequired]="field?.requiredByTableRow"
      *ngIf="!isViewApplication"
    ></iapplication2-required>
  </div>
</div>
