import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { SharedModule } from '@iapplication2/shared';
import { environment } from '../environments/environment';

import { APP_CONFIG } from '@iapplication2/app-config';
import { AppRoutingModule } from './app-routing.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend, HTTP_INTERCEPTORS } from '@angular/common/http';
import { createTranslateLoader, MissingTranslationHelper } from '@iapplication2/shared-translate';
import {
  AppLanguageHeaderInterceptor,
  BaseUrlInterceptor,
  PendingRequestInterceptor,
  TokenInterceptor,
  UnauthorizedInterceptor,
} from '@iapplication2/interceptors';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend, APP_CONFIG],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper },
    }),
  ],
  providers: [
    { provide: APP_CONFIG, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendingRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppLanguageHeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
