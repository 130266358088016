export enum GenderOptions {
    MALE = 'Male',
    FEMALE = 'Female'
}

export enum GenderDialerOptions {
    MALE = 'm',
    FEMALE = 'f'
}
export enum ReturnType {
    OBJECT = 'object',
    LINK = 'link',
    ALL = 'all',
  }

  export enum ProductTypeOptions {
    HUMANIA = 'humania',
    CHUBB = 'chubb',
    REFLEXIVE = 'reflexive',
    EVOLUTION = 'evolution',
    JUVENILE = 'juvenile',
    IVARI = 'ivari',
    RIDER = 'rider',
  }

  export enum SmokerDialerOptions {
    YES = 'y',
    NO = 'n'
}

export enum SmokerOptions {
    YES = 'yes',
    NO = 'no'
}
export enum UserRoleType {
    ADVISOR = 'advisor',
    TEAM_LEADER = 'team_leader',
  }

export enum Iapp1Ids {
  PRIMARY_SALUTATION = 'primary-salutation',
  PRIMARY_FIRSTNAME = 'primary-first-name',
  PRIMARY_MIDDLENAME = 'primary-middle-name',
  PRIMARY_LASTNAME = 'primary-last-name',
  PRIMARY_DOB = 'primary-dob',
  PRIMARY_GENDER = 'primary-gender',
  PRIMARY_SMOKER = 'primary-smoker',
  PRIMARY_EMAIL = 'primary-email',
  PRIMARY_MOBILE = 'primary-mobile',
  PRIMARY_PHONE = 'primary-phone',
  PRIMARY_PHONEALT = 'primary-phonealt',
  PRIMARY_ADDRESS = 'primary-address',
  PRIMARY_APARTMENT = 'primary-apartment',
  PRIMARY_CITY = 'primary-city',
  PRIMARY_PROVINCE = 'primary-province',
  PRIMARY_POSTAL = 'primary-postal',
  PRIMARY_COUNTRY_BIRTH = 'primary-country-birth',
  PRIMARY_PROVINCE_BIRTH = 'primary-province-birth',
  PRIMARY_RESIDENCY = 'primary-residency',
  PRIMARY_RESIDENCY_OTHER_STATUS = 'primary-residency-other-status',
  PRIMARY_RESIDENCY_YEARS = 'primary-residency-years',
  PRIMARY_RESIDENCY_MONTHS = 'primary-residency-months',
  PRIMARY_RESIDENCY_OTHER = 'primary-residency-other',
  PRIMARY_IDENTIFICATION_DOCUMENT = 'primary-identification-document',
  PRIMARY_IDENTIFICATION_NUMBER = 'primary-identification-number',
  PRIMARY_IDENTIFICATION_PROVINCE = 'primary-identification-province',
  PRIMARY_IDENTIFICATION_EXPIRE = 'primary-identification-expire',
  PRIMARY_OCCUPATION = 'primary-occupation',
  PRIMARY_BUSINESS = 'primary-business',
  IS_OWNER = 'is-owner',
  IS_OWNER_CORPORATE = 'is-owner-corporate',
  OWNER_SIGNATURE_FROM_INSURED = 'owner-signature-from-insured',
  OWNER_FIRST_NAME = 'owner-first-name',
  OWNER_MIDDLE_NAME = 'owner-middle-name',
  OWNER_LAST_NAME = 'owner-last-name',
  OWNER_RELATIONSHIP = 'owner-relationship',
  OWNER_PHONE_MOBILE = 'owner-phone-mobile',
  OWNER_PHONE_HOME = 'owner-phone-home',
  OWNER_PHONE_WORK = 'owner-phone-work',
  OWNER_ADDRESS = 'owner-address',
  OWNER_APARTMENT = 'owner-apartment',
  OWNER_CITY = 'owner-city',
  OWNER_PROVINCE = 'owner-province',
  OWNER_POSTAL = 'owner-postal',
  OWNER_IDENTIFICATION_DOCUMENT = 'owner-identification-document',
  OWNER_IDENTIFICATION_NUMBER = 'owner-identification-number',
  OWNER_IDENTIFICATION_PROVINCE = 'owner-identification-province',
  OWNER_IDENTIFICATION_EXPIRE = 'owner-identification-expire',
  OWNER_EMAIL = 'owner-email',
  OWNER_OCCUPATION = 'owner-occupation',
  OWNER_BUSINESS = 'owner-business',
  OWNER_DOB = 'owner-dob',
  IS_PAYOR = 'is-payor',
  PAYOR_TYPE = 'payor-type',
  PAYOR_NAME = 'payor-name',
  PAYOR_FIRST_NAME = 'payor-first-name',
  PAYOR_LAST_NAME = 'payor-last-name',
  PAYOR_MIDDLE_NAME = 'payor-middle-name',
  PAYOR_LEGAL_NAME = 'payor-legal-name',
  PAYOR_RELATIONSHIP = 'payor-relationship',
  PAYOR_DOB = 'payor-dob',
  PAYOR_MOBILE_PHONE = 'payor-mobile-phone',
  PAYOR_HOME_PHONE = 'payor-home-phone',
  PAYOR_WORK_PHONE = 'payor-work-phone',
  PAYOR_ADDRESS = 'payor-address',
  PAYOR_APARTMENT = 'payor-apartment',
  PAYOR_CITY = 'payor-city',
  PAYOR_EMAIL= 'payor-email',
  PAYOR_PROVINCE = 'payor-province',
  PAYOR_POSTAL_CODE = 'payor-postal-code',
  PAYOR_IDENTIFICATION_DOCUMENT = 'payor-identification-document',
  PAYOR_IDENTIFICATION_NUMBER = 'payor-identification-number',
  PAYOR_IDENTIFICATION_PROVINCE = 'payor-identification-province',
  PAYOR_IDENTIFICATION_EXPIRE = 'payor-identification-expire',
  PAYOR_OCCUPATION = 'payor-occupation',
  PAYOR_BUSINESS = 'payor-business',
  BENEFICIARY_FULL_NAME = 'beneficiary-full-name',
  BENEFICIARY_FIRST_NAME = 'beneficiary-first-name',
  BENEFICIARY_MIDDLE_NAME = 'beneficiary-middle-name',
  BENEFICIARY_LAST_NAME = 'beneficiary-last-name',
  BENEFICIARY_RELATIONSHIP = 'beneficiary-relationship',
  BENEFICIARY_DOB = 'beneficiary-dob',
  BENEFICIARY_SHARES = 'beneficiary-shares',
  BENEFICIARY_REVOCABLE = 'beneficiary-revocable',
  BENEFICIARY_PRIMARY = 'beneficiary-primary',
  HAS_TRUSTEE = 'has_trustee',
  TRUSTEE_FIRST_NAME = 'trustee-first-name',
  TRUSTEE_NAME = 'trustee-name',
  TRUSTEE_LAST_NAME = 'trustee-last-name',
  TRUSTEE_MIDDLE_NAME = 'trustee-middle-name',
  TRUSTEE_RELATIONSHIP = 'trustee-relationship',
  TRUSTEE_CONTACT_INFORMATION = 'trustee-contact-information',
  ADVISOR_FULL_NAME = 'advisor-full-name',
  ADVISOR_FIRST_NAME = 'advisor-first-name',
  ADVISOR_LAST_NAME = 'advisor-last-name',
  ADVISOR_MIDDLE_NAME = 'advisor-middle-name',
  ADVISOR_CODE = 'advisor-code',
  ADVISOR_SPLIT = 'advisor-split',
  ADVISOR_PHONE_NUMBER = 'advisor-phone-number',
  ADVISOR_EMAIL = 'advisor-email',
  IS_ADVISOR_RELATED = 'is-advisor-related',
  ADVISOR_RELATIONSHIP = 'advisor-relationship',
  ADVISOR2_FULL_NAME = 'advisor-full-name',
  ADVISOR2_FIRST_NAME = 'advisor2-first-name',
  ADVISOR2_LAST_NAME = 'advisor2-last-name',
  ADVISOR2_MIDDLE_NAME = 'advisor2-middle-name',
  ADVISOR2_CODE = 'advisor2-code',
  ADVISOR2_SPLIT = 'advisor2-split',
  ADVISOR2_PHONE_NUMBER = 'advisor-phone-number',
  ADVISOR2_EMAIL = 'advisor-email',
  BILLING_FREQUENCY = 'billing-frequency',
  PAYMENT_METHOD = 'payment-method',
  TRANSIT_NUMBER = 'transit_number',
  FINANCIAL_NUMBER = 'financial_number',
  ACCOUNT_NUMBER = 'account_number',
  WITHDRAWAL_DATE = 'withdrawal-date',
  IAPP1_ID = 'iapp1_id',
  IS_NF2F = 'is-nf2f',
  APPLICATION_RECEIVED_DATE = 'application_received_date',
  APPLICATION_STARTED_AT = 'application-started-at',
  MONTHLY_PREMIUM = 'monthly-premium',
  MONTHLY_YEARLY = 'monthly-yearly',
  REPLACING_POLICY = 'replacing-policy',
  REPLACING_HUMANIA = 'replacing-humania',
  TOTAL_REPLACE_INFORCE = 'total-replace-inforce',
  TOTAL_REPLACE_POLICY = 'total-replace-policy',
  EMAIL_DELIVERY = 'email-delivery',
  SEND_POLICY_EMAIL_TO = 'send-policy-email-to',
  MARKETING_CONSENT = 'marketing-consent',
  SIGNED_AT_CITY = 'signed-at-city',
  SIGNED_AT_PROVINCE = 'signed-at-province',
  SIGNED_AT_DATE = 'signed-at-date',
  SPECIAL_INSTRUCTIONS = 'special-instructions',
  PRIMARY_EMAIL_MIB = 'primary-email-mib',
  PRIMARY_EMAIL_MIB_DATE = 'primary-email-mib_date',
  SIGNATURE_ADVISOR1 = 'signature-advisor1',
  OWNER_LEGAL_NAME = 'owner-legal-name',
  SIGNATURE_PAYOR = 'signature-payor',
  SIGNATURE_PAYOR_EMAIL = 'payor-email',
  SIGNATURE_PAYOR_LEGAL_NAME = 'payor-legal-name',
  SIGNATURE_INSURED = 'signature-insured',
  SIGNATURE_INSURED_EMAIL = 'insured-email',
  SIGNATURE_OWNER = 'signature-owner',
  SIGNATURE_OWNER_EMAIL = 'owner-email',
  EXISTING_INSURANCE_PDF = 'existing-insurance-pdf',
  LIRD_1 = 'lird-1',
  LIRD_2 = 'lird-2',
  LIRD_3 = 'lird-3',
  LIRD_4 = 'lird-4',
  LIRD_5 = 'lird-5',
  LIRD_6 = 'lird-6',
  LIRD_7 = 'lird-7',
  LIRD_8 = 'lird-8',
  LIRD_9 = 'lird-9',
  LIRDMETHOD = 'lird-method',
  HUMANIA_TITLE = 'humania-title',
  OWNER_IS_INSURED='owner-is-insured',
  PAYOR_IS_INSURED='payor-is-insured',
  IS_BANCK_ACCOUNT_HOLDER='is-bank-account-holder',
  COMPLETED_IN_PERSON = 'complete-in-person',
  RECORDING_METHOD = 'recording-method',
  IDENTIFY_VERIFIED= 'identify-verified',
  LANGUAGE_UNDERSTANDING = 'language-understanding',
  DETAILD_UNDERSTANDING = 'details-understanding',
  MIB_RECEIVED_EMAIL = 'mib-received-email'
}

export enum SignatureFor {
  INSURED = 'insured',
  OWNER = 'owner',
  PAYOR = 'payor'
}

export enum SignatureStatus {
  NOT_SENT = 'not_sent',
  PENDING = "pending",
  SIGNED = "signed",
  EXPIRED = "expired",
}

export enum SignatureMethod {
  EMAIL = 'email',
  SMS = 'sms',
  VOICE = 'voice_signature'
}

export enum TrullyOptions {
  YES = 'Yes',
  NO = 'No',
  no = 'no'
}

export enum PayorTypes {
  OWNER = 'Owner',
  INSURED = 'Insured',
  OTHER = 'Other'
}
