import { TableCol } from './table-column.model';

export interface UserSettings {
  id: number;
  userId: number;
  isMenuStatic: boolean;
  isMenuActive: boolean;
  isLightTheme: boolean;
  selectedTableColumns: [
    {
      name: string;
      columns: TableCol[];
    }
  ];
}
