import { SafeHtml } from '@angular/platform-browser';
import { FieldType } from './field-type.model';
import { FormBuilderFieldOptions } from './form-builder-field-options.model';

export interface FormBuilderStaticText {
  id?: number;
  content?: string;
  fieldType: FieldType;
  options: FormBuilderFieldOptions;
  [key: string]: any;
}

export class FormBuilderStaticTextType implements FormBuilderStaticText {
  id?: number;
  content?: string;
  fieldType: FieldType;
  options: FormBuilderFieldOptions;
  [key: string]: any;
}
