<div class="mt-4" *ngIf="displaySection">
  <div class="details-type-header flex justify-content-between">
    <h4 class="details-type-header-title">Validator details</h4>
  </div>
  <div *ngIf="displaySection; else noPossibleValidators" class="details-field-content-property">
    <div class="details-field-content-property-label pr-3">
      <p *ngIf="!item?.fieldValidators?.manualValidators?.length && !item?.fieldValidators?.predefinedValidators?.length">
        This field has no validators currently
      </p>
      <div *ngIf="item?.fieldValidators?.manualValidators.length" fxLayout="column">
        <p class="mb-1"><b>Manual validators</b></p>
        <div *ngFor="let manualValidator of item.fieldValidators.manualValidators" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <button
            *ngIf="isInEditMode"
            pButton
            pRipple
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-sm p-button-text p-button-danger"
            (click)="deleteValidator($event, manualValidator, ValidatorTypeEnum.MANUAL)"
          ></button>
          <p-confirmPopup></p-confirmPopup>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-sm p-button-text"
            *ngIf="manualValidator.isConfigurable && isInEditMode"
            (click)="editValidator(manualValidator, ValidatorTypeEnum.MANUAL)"
          ></button>
          <p class="ml-2">{{ manualValidator.label }}</p>
        </div>
      </div>
      <div *ngIf="item?.fieldValidators?.predefinedValidators?.length" fxLayout="column">
        <p class="mb-1"><b>Predefined validators</b></p>
        <div
          *ngFor="let predefinedValidator of item.fieldValidators.predefinedValidators"
          fxFlex
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <button
            *ngIf="isInEditMode"
            pButton
            pRipple
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-sm p-button-text p-button-danger"
            (click)="deleteValidator($event, predefinedValidator, ValidatorTypeEnum.PREDEFINED)"
          ></button>
          <p-confirmPopup></p-confirmPopup>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-sm p-button-text"
            *ngIf="predefinedValidator.isConfigurable && isInEditMode"
            (click)="editValidator(predefinedValidator, ValidatorTypeEnum.PREDEFINED)"
          ></button>
          <p class="ml-2">{{ predefinedValidator.label }}</p>
        </div>
      </div>
    </div>
    <button
      pButton
      label="Add a new validator"
      (click)="showAddValidatorSection = true"
      *ngIf="!showAddValidatorSection && !editingValidator && isInEditMode && areValidatorsAvailable"
      class="mt-3 p-button-secondary"
    ></button>
    <div *ngIf="showAddValidatorSection" class="mt-2">
      <h5>
        Add a new validator
        <i
          class="pi pi-exclamation-circle mr-2"
          pTooltip=" Select a validator from the list below. The validator details and configurations will be showed under the list. When ready, click the Add validator button to add the validator to this field"
          style="color: #a3c4f5; font-size: 0.9rem"
        ></i>
      </h5>
      <p-tree [value]="validatorsList" selectionMode="single" [(selection)]="selectedValidatorToAdd"></p-tree>
      <button
        *ngIf="!selectedValidatorToAdd"
        pButton
        label="Exit add validator"
        (click)="cancelAddValidator()"
        class="mt-3 p-button-secondary"
      ></button>
      <div *ngIf="selectedValidatorToAdd" class="mt-3">
        <h5>{{ selectedValidatorToAdd?.data?.label }}</h5>
        <p *ngIf="selectedValidatorToAdd?.data?.info" class="mb-1">
          <b>Validator description:</b> {{ selectedValidatorToAdd?.data?.info }}
        </p>
        <div *ngIf="selectedValidatorToAdd?.data?.isConfigurable">
          <div fxLayout="row" fxLayoutAlign="start center">
            <p class="mr-3 mb-0"><b>Configuration:</b></p>
            <iapplication2-field-details-validator-configuration
              *ngFor="let validatorConfiguration of selectedValidatorToAdd?.data?.configuration"
              [isInEditMode]="isInEditMode"
              [fieldValidatorConfiguration]="validatorConfiguration"
            ></iapplication2-field-details-validator-configuration>
          </div>
        </div>
        <div fxLayout="row" class="mt-3">
          <button pButton label="Add validator" (click)="addSelectedValidator()" class="p-button-secondary"></button>
          <button pButton label="Cancel" class="p-button-outlined p-button-danger ml-2" (click)="cancelAddValidator()"></button>
        </div>
      </div>
    </div>
    <div *ngIf="editingValidator" class="mt-3">
      <h5>{{ validatorToEdit?.label }}</h5>
      <p *ngIf="validatorToEdit?.info" class="mb-1"><b>Validator description:</b> {{ validatorToEdit?.info }}</p>
      <div fxLayout="row" fxLayoutAlign="start center">
        <p class="mr-3 mb-0"><b>Configuration:</b></p>
        <iapplication2-field-details-validator-configuration
          *ngFor="let validatorConfiguration of validatorToEdit?.configuration"
          [isInEditMode]="isInEditMode"
          [fieldValidatorConfiguration]="validatorConfiguration"
        ></iapplication2-field-details-validator-configuration>
      </div>
      <div fxLayout="row" class="mt-3">
        <button pButton label="Save changes" (click)="saveEditedValidator()" class="p-button-secondary"></button>
        <button pButton label="Cancel editing" class="p-button-outlined p-button-danger ml-2" (click)="cancelEditValidator()"></button>
      </div>
    </div>
  </div>
  <ng-template #noPossibleValidators class="details-field-content-property">
    There are no validators that can be added to this type of field
  </ng-template>
</div>
