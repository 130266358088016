<div fxFlex fxLayout="row" class="py-3">
  <div [fxFlex]="displayPdfsToChange ? 70 : 100" fxLayoutAlign="center center" fxLayout="column">
    <!-- <p-paginator
      [first]="currentPage"
      [rows]="1"
      [totalRecords]="selectedProductPdf.pages.length"
      (onPageChange)="onPageChange($event)"
      [showJumpToPageDropdown]="true"
      [showPageLinks]="false"
    ></p-paginator> -->
    <p class="font-bold mb-4">{{ selectedProductPdf.name }}</p>
    <p-paginator
      [first]="currentPage"
      [rows]="1"
      class="mb-2"
      [totalRecords]="selectedProductPdf.pages.length"
      (onPageChange)="onPageChange($event)"
    ></p-paginator>
    <img [src]="selectedProductPdf.pages[currentPage].imageUrl" class="pdf-preview-container p-shadow-5" />
    <p-paginator
      [first]="currentPage"
      class="mt-2"
      [rows]="1"
      [totalRecords]="selectedProductPdf.pages.length"
      (onPageChange)="onPageChange($event)"
    ></p-paginator>
    <button pButton [label]="'applicationProcess.step.summary.pdfPreview.download' | translate" class="mt-3"></button>
  </div>
  <div fxFlex="30" fxLayout="column" class="thumbnail-container" *ngIf="displayPdfsToChange">
    <div *ngFor="let pdf of thumbnailProductPdf" fxFlex fxLayout="column" fxLayoutAlign="center center" class="mb-5">
      <p class="font-bold mb-2">{{ pdf.name }}</p>
      <img [src]="pdf.pages[0].imageUrl" class="pdf-thumbnail-container mb-3 cursor-pointer p-shadow-5" (click)="changeSelectedPdf(pdf)" />
      <button pButton [label]="'applicationProcess.step.summary.pdfPreview.download' | translate"></button>
    </div>
  </div>
  <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <p>{{ productPdfs.length }} PDF file<span *ngIf="productPdfs.length > 1">s</span> available</p>
    <p-dropdown [options]="productPdfs" [(ngModel)]="selectedProductPdf" optionLabel="name" (onChange)="changedProductPdf()"></p-dropdown>
  </div>
  <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="mt-4">
    <p-paginator
      [first]="currentPage"
      [rows]="1"
      [totalRecords]="selectedProductPdf.pages.length"
      (onPageChange)="onPageChange($event)"
      [showJumpToPageDropdown]="true"
      [showPageLinks]="false"
    ></p-paginator>
    <img [src]="selectedProductPdf.pages[currentPage].imageUrl" class="pdf-preview-container" />
    <button pButton label="Download"></button>
  </div> -->
</div>
