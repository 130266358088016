<div class="mt-4" fxFlex fxLayout="column">
  <div class="details-type-header flex justify-content-between">
    <h4 class="details-type-header-title">
      <span *ngIf="isDisclosure">Disclosure</span> <span *ngIf="!isDisclosure">Disclaimer</span> details
    </h4>
  </div>
  <div [formGroup]="disclosureInformationForm" fxFlex fxLayout="column" *ngIf="isInEditMode">
    <div fxFlex fxLayout="row" class="mb-3">
      <p-dropdown
        *ngIf="mp3FileNames?.length"
        [options]="mp3FileNames"
        appendTo="body"
        formControlName="mp3FileName"
        placeholder="Select an mp3 file"
        class="mr-3"
      ></p-dropdown>
      <p-dropdown
        *ngIf="isDisclosure"
        [options]="disclosureTypeOptions"
        optionLabel="name"
        optionValue="value"
        appendTo="body"
        formControlName="disclosureType"
        placeholder="Select a disclosure options type"
      ></p-dropdown>
    </div>
    <div>
      <p-editor
        formControlName="disclosureText"
        [style]="{ height: '320px' }"
        class="mt-1 mb-3"
        [placeholder]="isDisclosure ? 'Disclosure text' : ' Disclaimer text'"
      >
        <ng-template pTemplate="header">
          <select class="ql-header"></select>
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button type="button" class="ql-underline" aria-label="Underline"></button>
        </ng-template>
      </p-editor>
      <div *ngIf="isDisclosure">
        <p class="mb-1 mt-3">
          <em
            >In order for the warning text to be displayed, you must set the disclosure as required (by adding the required validator). In
            case an option is marked as the required disclosure option, the waring text will also be displayed when any other option is
            selected.
          </em>
        </p>
        <textarea
          pInputTextarea
          [rows]="4"
          [cols]="60"
          type="text"
          class="mt-1"
          formControlName="warningText"
          placeholder="Warning text"
        ></textarea>
      </div>
    </div>
  </div>
  <div *ngIf="!isInEditMode && disclosureDetails">
    <div *ngIf="disclosureDetails.disclosureText" class="mb-3">
      <label
        ><b>{{ isDisclosure ? 'Disclosure text' : ' Disclaimer text' }}</b></label
      >
      <iapplication2-see-more [text]="disclosureDetails?.disclosureText" [length]="100"></iapplication2-see-more>
    </div>
    <div *ngIf="disclosureDetails.warningText" class="mb-3">
      <label><b>Warning text</b></label>
      <p>{{ disclosureDetails.warningText }}</p>
    </div>
    <div *ngIf="disclosureDetails.mp3FileName" class="mb-3">
      <label><b>Mp3 File</b></label>
      <p>{{ disclosureDetails.mp3FileName }}</p>
    </div>
    <div
      class="mb-3"
      *ngIf="
        (disclosureDetails?.disclosureFieldTypeId && disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.checkboxGroup) ||
        disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.radioGroup
      "
    >
      <label><b>Disclosure type</b></label>
      <p>
        {{
          disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.checkboxGroup
            ? 'Checkbox'
            : disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.radioGroup
            ? 'Radio'
            : ''
        }}
      </p>
    </div>
  </div>
</div>
