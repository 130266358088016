<div class="flex flex-column">
  <p-card fxFlex class="pr-3">
    <form [formGroup]="form" class="application-client-information">
      <div class="pb-3">
        <div fxFill fxLayout="row" fxFlex fxLayoutAlign="start center">
          <h5 class="pb-2 app-info-fsize">{{ 'client_selection.application_information' | translate }}</h5>
        </div>
      </div>
      <div class="pb-3" fxLayout="row" fxFill>
        <div fxLayout="column" class="pr-3 w-3">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="title">{{ 'client_selection.title' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['title'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <p-dropdown
            [options]="titleOptions"
            optionLabel="label"
            optionValue="value"
            id="title"
            formControlName="title"
            [placeholder]="'client_selection.selectTitleLabel' | translate"
          ></p-dropdown>
          <iapplication2-form-field-error
            *ngIf="form.controls['title'].touched || formSubmissionTriggered"
            [control]="form.controls['title']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div fxLayout="column" class="pr-3 w-3">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="gender">{{ 'client_selection.gender' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['gender'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <p-dropdown
            [options]="genderOptions"
            optionLabel="label"
            optionValue="value"
            id="gender"
            formControlName="gender"
            [placeholder]="'client_selection.selectGenderLabel' | translate"
          ></p-dropdown>
          <iapplication2-form-field-error
            *ngIf="form.controls['gender'].touched || formSubmissionTriggered"
            [control]="form.controls['gender']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div fxLayout="column" class="pr-3 w-3">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="birthDate">{{ 'client_selection.date_of_birth' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['birthDate'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <p-calendar
            class="flex flex-column"
            id="birthDate"
            formControlName="birthDate"
            [showIcon]="true"
            inputId="icon"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'1900:' + currentYear"
            [maxDate]="maxDOB"
            dateFormat="dd/mm/yy"
            dataType="string"
            [placeholder]="'pages.newClient.sections.clientInformation.fields.birthDate.placeholder' | translate"
            iapplication2DateMask
          ></p-calendar>
          <iapplication2-form-field-error
            *ngIf="form.controls['birthDate'].touched || formSubmissionTriggered"
            [control]="form.controls['birthDate']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div class="move-down" fxFlex fxLayout="column" fxLayoutAlign="end start" *ngIf="realAge && ageNearest">
          <p class="mb-0 age-text">{{ 'client_selection.age.realAge' | translate: { realAge: realAge } }}</p>
          <p class="mb-0 age-text">{{ 'client_selection.age.nearestAge' | translate: { nearestAge: ageNearest } }}</p>
        </div>
      </div>
      <div class="pb-3" fxLayout="row">
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="firstName">{{ 'client_selection.first_name' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['firstName'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <input
            id="firstName"
            type="text"
            class="p-inputtext capitalize-text"
            pInputText
            formControlName="firstName"
            [iapplication2TitleFormat]="form.controls['firstName']"
          />
          <iapplication2-form-field-error
            *ngIf="form.controls['firstName'].touched || formSubmissionTriggered"
            [control]="form.controls['firstName']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="middleName">{{ 'client_selection.middle_name' | translate }}</label>
          </div>
          <input
            id="middleName"
            type="text"
            class="p-inputtext capitalize-text"
            pInputText
            formControlName="middleName"
            [iapplication2TitleFormat]="form.controls['middleName']"
          />
          <iapplication2-form-field-error
            *ngIf="form.controls['middleName'].touched || formSubmissionTriggered"
            [control]="form.controls['middleName']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="lastName">{{ 'client_selection.last_name' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['lastName'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <input
            id="lastName"
            type="text"
            class="p-inputtext capitalize-text"
            pInputText
            formControlName="lastName"
            [iapplication2TitleFormat]="form.controls['lastName']"
          />
          <iapplication2-form-field-error
            *ngIf="form.controls['lastName'].touched || formSubmissionTriggered"
            [control]="form.controls['lastName']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
      <div class="pb-3" fxLayout="row">
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="address">{{ 'client_selection.address' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['address'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <input
            id="address"
            type="text"
            class="p-inputtext capitalize-text"
            pInputText
            formControlName="address"
            [iapplication2TitleFormat]="form.controls['address']"
          />
          <iapplication2-form-field-error
            *ngIf="form.controls['address'].touched || formSubmissionTriggered"
            [control]="form.controls['address']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="city">{{ 'client_selection.city' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['city'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <input
            id="city"
            type="text"
            class="p-inputtext capitalize-text"
            pInputText
            formControlName="city"
            [iapplication2TitleFormat]="form.controls['city']"
          />
          <iapplication2-form-field-error
            *ngIf="form.controls['city'].touched || formSubmissionTriggered"
            [control]="form.controls['city']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="province">{{ 'client_selection.province' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['province'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <p-dropdown
            id="province"
            [options]="provinces"
            formControlName="province"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            [placeholder]="'client_selection.selectProvinceLabel' | translate"
          >
          </p-dropdown>
          <iapplication2-form-field-error
            *ngIf="form.controls['province'].touched || formSubmissionTriggered"
            [control]="form.controls['province']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
      <div class="pb-3" fxLayout="row">
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="postalCode">{{ 'client_selection.postal_code' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['postalCode'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <p-inputMask
            id="postalCode"
            class="flex flex-column"
            type="text"
            formControlName="postalCode"
            [placeholder]="'client_selection.postal_code' | translate"
            mask="a9a 9a9"
            [autoClear]="false"
          ></p-inputMask>
          <iapplication2-form-field-error
            *ngIf="form.controls['postalCode'].touched || formSubmissionTriggered"
            [control]="form.controls['postalCode']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="email">{{ 'client_selection.email' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['email'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <input
            id="email"
            type="text"
            class="p-inputtext p-component"
            pInputText
            [placeholder]="'client_selection.email' | translate"
            formControlName="email"
          />
          <iapplication2-form-field-error
            *ngIf="form.controls['email'].touched || formSubmissionTriggered"
            [control]="form.controls['email']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
        <div fxLayout="column" class="pr-3 w-4">
          <div fxFlex fxLayout="row">
            <label class="pb-1" for="phoneNumber">{{ 'client_selection.phone_number' | translate }}</label>
            <iapplication2-required
              [value]="form.controls['phoneNumber'].value"
              class="flex ai-center"
              style="min-width: 20px"
              [required]="true"
            ></iapplication2-required>
          </div>
          <p-inputMask
            id="phoneNumber"
            class="flex flex-column"
            type="text"
            formControlName="phoneNumber"
            [placeholder]="'client_selection.phone_number' | translate"
            mask="(999) 999-9999"
          ></p-inputMask>
          <iapplication2-form-field-error
            *ngIf="form.controls['phoneNumber'].touched || formSubmissionTriggered"
            [control]="form.controls['phoneNumber']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
      <div class="pt-3">
        <div>
          <div fxFlex fxLayout="column">
            <div fxFlex fxLayout="column">
              <label
                ><b>{{ 'client_selection.smokingStatus.questionText1' | translate }}</b></label
              >
              <label class="pb-3">
                {{ 'client_selection.smokingStatus.questionText2' | translate }}
              </label>
            </div>
            <div class="p-field flex flex-row">
              <div fxFlex fxLayout="row" class="smoker-status-container">
                <span class="py-2">{{ 'client_selection.smokingStatus.questionLabel' | translate }}</span>
                <iapplication2-required
                  [value]="form.controls['smokerStatus'].value"
                  class="flex ai-center"
                  style="min-width: 20px"
                  [required]="true"
                ></iapplication2-required>
              </div>
              <div class="p-field flex flex-row align-items-center mb-0 ml-3">
                <p-radioButton class="py-1 pr-2" name="smokerStatus" value="yes" formControlName="smokerStatus" inputId="yesSmoker">
                </p-radioButton>
                <label class="mb-0"> {{ 'client_selection.smokingStatus.yesAnswerLabel' | translate }} </label>
              </div>
              <div class="p-field flex flex-row align-items-center mb-0 ml-3">
                <p-radioButton
                  class="py-1 pr-2"
                  name="smokerStatus"
                  value="no"
                  formControlName="smokerStatus"
                  inputId="noSmoker"
                ></p-radioButton>
                <label class="mb-0"> {{ 'client_selection.smokingStatus.noAnswerLabel' | translate }} </label>
              </div>
            </div>
          </div>

          <iapplication2-form-field-error
            *ngIf="form.controls['smokerStatus'].touched || formSubmissionTriggered"
            [control]="form.controls['smokerStatus']"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
    </form>
  </p-card>
  <div class="mt-3">
    <button
      pButton
      label="Fill form with data - dev only"
      *ngIf="this.appConfig.environmentType === envType.DEVELOP"
      (click)="autofillForm(true)"
    ></button>
    <button
      class="ml-3"
      pButton
      label="Fill form with data - dev only (without chubb smoker)"
      *ngIf="this.appConfig.environmentType === envType.DEVELOP"
      (click)="autofillForm()"
    ></button>
  </div>
</div>
