import { Component, Input } from '@angular/core';
import { ApplicationTypeList } from 'libs/interfaces/src/lib/advisor-application/application-type-list.enum';

@Component({
  selector: 'iapplication2-application-type',
  templateUrl: './application-type.component.html',
  styleUrls: ['./application-type.component.scss'],
})
export class ApplicationTypeComponent {
  @Input() type: ApplicationTypeList;
  applicationTypeListEnum = ApplicationTypeList;
}
