<div fxLayout="column" class="mb-3" [formGroup]="parentFormGroup">
  <div class="flex">
    <iapplication2-required *ngIf="!isViewApplication" [required]="formFieldControl.errors?.required && !field.table"></iapplication2-required>
    <label *ngIf="!hideLabel">{{
      (field?.options?.lokaliseKey ? field?.options.lokaliseKey : 'missing_key') | translate: { Default: field?.options?.customFieldLabel }
    }}</label>
  </div>
  <div class="mt-1 flex flex-row">
    <p-dropdown
      [options]="options"
      optionLabel="value"
      optionValue="value"
      [placeholder]="
        'Select a ' +
        (
          (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
          | translate: { Default: field?.options?.customFieldLabel }
        )?.toLowerCase()
      "
      [formControlName]="field.id"
      [filter]="isSearchable"
      filterBy="value"
      [showClear]="true"
      [readonly]="isReadonly"
      [class.isReadonlyInput]="isReadonly"
      [tabindex]="tabIndex"
      (onClear)="setEmptyValueOnControl()"
      #dropdown
    >
      <iapplication2-required
        *ngIf="!isViewApplication"
        class="flex ai-center"
        style="min-width: 20px"
        [rowRequired]="field?.requiredByTableRow"
      ></iapplication2-required>
    </p-dropdown>
  </div>
</div>
