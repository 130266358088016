/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { ENV, FormBuilderField } from '@iapplication2/interfaces';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FieldManagementService {
  groupIdOfCurrentlyDrawingGroup: ReplaySubject<number> = new ReplaySubject();
  tableIdOfCurrentlyDrawingTable: Subject<number> = new Subject();
  creationFailedForTableWithId: Subject<number> = new Subject();
  creationFailedForTableWithoutId: Subject<boolean | null> = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  createField(field: FormBuilderField) {
    delete field.options.id;

    return this.http.post('form-builder/fields', field);
  }

  getAllFieldsByFormId(formId: number) {
    console.log('getAllFieldsByFormId called');

    return this.http.get(`form-builder/fields/${formId}`);
  }

  getAllFieldsByProductFormId(productFormId: number) {
    return this.http.get(`form-builder/fields/productForm/${productFormId}`);
  }

  updateField(field: FormBuilderField) {
    return this.http.put(`form-builder/fields/${field.id}`, field);
  }

  updateFields(fields: FormBuilderField[]) {
    if (fields.length) {
      this.http.put(`form-builder/fields/${fields[0].id}`, fields[0]).subscribe((res) => {
        if (res) {
          fields = fields.splice(1, fields.length);
          this.updateFields(fields);
        }
      });
    }
  }

  deleteFieldById(id: number) {
    this.http.delete(`form-builder/fields/${id}`).subscribe();
  }

  deleteFieldByIdWithResponse(id: number) {
    return this.http.delete(`form-builder/fields/${id}`);
  }

  deleteTableColumnByIdWithResponse(id: number) {
    return this.http.delete(`form-builder/field-column/${id}`);
  }

  deleteTableById(id: number) {
    return this.http.delete(`form-builder/field-table/${id}`);
  }

  deleteGroupOptionById(id: number) {
    return this.http.delete(`form-builder/group-option/${id}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addFieldAttachment(id: number, file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file, file?.name);
    return this.http.post(`form-builder/attachments/${id}`, formData);
  }

  getFieldAttachments(id: string) {
    return this.http.get('form-builder/attachments/' + id);
  }

  deleteFieldAttachments(attachmentId: string | number) {
    return this.http.delete('form-builder/attachments/' + attachmentId);
  }
}
