<div class="col-12 px-0">
  <div class="card quickQuotesManagement-table">
    <p-table
      #dt
      [columns]="selectedCols"
      frozenWidth="75px"
      [value]="quickQuotes"
      dataKey="id"
      styleClass="p-datatable-customers"
      [rowHover]="true"
      (onLazyLoad)="loadQuickQuotes($event)"
      [lazy]="true"
      [loading]="loading"
      [paginator]="true"
      [totalRecords]="totalRecords"
      [rows]="rows"
      [rowsPerPageOptions]="rowsPerPage"
      [globalFilterFields]="['premium']"
      [scrollable]="true"
      [style]="{ width: '100%' }"
    >
      <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between table-header">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input class="p-inputtext" pInputText type="text" (input)="filterGlobal($any($event.target).value)" placeholder="Search" />
          </span>
          <p-multiSelect
            [options]="scrollableCols"
            [(ngModel)]="selectedCols"
            optionLabel="header"
            selectedItemsLabel="{0} columns selected"
            [style]="{ minWidth: '200px' }"
            (onChange)="selectedColsChanged()"
            placeholder="Choose Columns"
          >
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="frozenheader" let-columns>
        <tr>
          <th pFrozenColumn>Actions</th>
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th pFrozenColumn>Actions</th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter
              [matchModeOptions]="matchModeOptions"
              type="text"
              [field]="col.field"
              display="menu"
              class="ml-auto"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-quickQuote let-editing="editing" let-ri="rowIndex" let-columns="columns">
        <tr class="p-selectable-row" #unfrozenRows [pEditableRow]="quickQuote" style="height: 70px">
          <td pFrozenColumn style="max-width: 79px">
            <div>
              <p-button icon="pi pi-cog" class="p-button-secondary ml-2" (click)="toggleMenu(quickQuote, menu, $event)"></p-button>
              <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
            </div>
          </td>

          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="output">
                <div *ngIf="col.field === 'id'">{{ quickQuote.id }}</div>
                <div *ngIf="col.field === 'firstName'">
                  {{ quickQuote.firstName }}
                </div>
                <div *ngIf="col.field === 'middleName'">
                  {{ quickQuote.middleName }}
                </div>
                <div *ngIf="col.field === 'lastName'">
                  {{ quickQuote.lastName }}
                </div>
                <div *ngIf="col.field === 'effectiveDate'">
                  {{ quickQuote.effectiveDate | date }}
                </div>
                <div *ngIf="col.field === 'everest'">
                  {{ quickQuote.everest ? 'Yes' : 'No' }}
                </div>
                <div *ngIf="col.field === 'birthDate'">
                  {{ quickQuote.birthDate | date }}
                </div>
                <div *ngIf="col.field === 'sex'">
                  {{ quickQuote.sex }}
                </div>
                <div *ngIf="col.field === 'smokerStatus'">
                  {{ quickQuote.smokerStatus }}
                </div>
                <div *ngIf="col.field === 'faceAmount'">
                  {{ quickQuote.faceAmount }}
                </div>
                <div *ngIf="col.field === 'province'">
                  {{ quickQuote.province?.name }}
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No quick-quotes found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-toast> </p-toast>
