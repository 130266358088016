export interface FormBuilderFieldTypeOption {
  id: number;
  label: string;
  info: string;
  predefined: boolean;
  type: FormBuilderFieldTypeType;
}

export enum FormBuilderFieldTypeType {
  FREETEXT = 'freeText',
  CHECKBOX = 'checkbox',
  CHECKBOXGROUP = 'checkboxGroup',
  RADIOGROUP = 'radioGroup',
  DROPDOWN = 'dropdown',
  SIGNATURE = 'signature',
  DATE = 'date',
  NUMBER = 'number',
  TABLE = 'table',
  STATICTEXT = 'staticText',
  DISCLOSURE = 'disclosure',
  IMAGE = 'image',
  PDF = 'pdf',
  DISCLAIMER = 'disclaimer',
}

export enum FormBuilderFieldTypeIdEnum {
  freeText = '1',
  checkbox = '2',
  checkboxGroup = '3',
  radioGroup = '4',
  dropdown = '5',
  signature = '6',
  date = '7',
  number = '8',
  table = '9',
  staticText = '10',
  disclosure = '11',
  image = '12',
  pdf = '13',
  disclaimer = '14',
}

export enum FieldTypeEnum {
  freeText = 1,
  checkbox = 2,
  checkboxGroup = 3,
  radioGroup = 4,
  dropdown = 5,
  signature = 6,
  date = 7,
  number = 8,
  table = 9,
  staticText = 10,
  disclosure = 11,
  image = 12,
  pdf = 13,
  disclaimer = 14,
}
