<div fxLayout="column" class="mb-3" [formGroup]="parentFormGroup">
  <div class="flex">
    <iapplication2-required *ngIf="!isViewApplication" [required]="formFieldControl.errors?.required && !field.table"></iapplication2-required>
    <label *ngIf="!hideLabel">{{
      (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key') | translate: { Default: field?.options?.customFieldLabel }
    }}</label>
  </div>
  <div class="flex flex-row">
    <p-inputNumber
      #numberInput
      class="mt-1"
      [formControlName]="field.id"
      [placeholder]="
        (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
          | translate: { Default: field?.options?.customFieldLabel }
      "
      [useGrouping]="false"
      [readonly]="isReadonly"
      [class.isReadonlyInput]="isReadonly"
      [tabindex]="localTabIndex"
      (onInput)="onInput($event)"
    ></p-inputNumber>
    <iapplication2-required
      *ngIf="!isViewApplication"
      class="flex ai-center"
      style="min-width: 20px"
      [rowRequired]="field?.requiredByTableRow"
    ></iapplication2-required>
  </div>
</div>
