<div [class.playing-disclaimer]="isDisclaimerPlaying" class="p-3 mb-2 disclosure-disclaimer-background">
  <div fxLayout="row">
    <iapplication2-required [required]="formFieldControl.errors?.required"></iapplication2-required>
    <div>
      <h5>{{ field.options.customFieldLabel }}</h5>
    </div>
  </div>

  <div fxLayout="column">
    <iapplication2-see-more [text]="disclosureDetails?.disclosureText" [length]="250"></iapplication2-see-more>
    <div fxLayout="row">
      <span
        tooltipPosition="bottom"
        [pTooltip]="
          disclosuresService.isAnyDisclosureDisclaimerPlaying
            ? ('applicationProcess.step.applicationForms.displayItem.disclosureDisclaimerAlreadyPlaying' | translate)
            : ''
        "
      >
        <button
          *ngIf="!isViewApplication"
          pButton
          (click)="toggleDisclaimerPlaying()"
          [disabled]="disclosuresService.isAnyDisclosureDisclaimerPlaying"
          [label]="
            isDisclaimerPlaying
              ? ('applicationProcess.step.applicationForms.displayItem.disclaimer.playingDisclaimer' | translate)
              : ('applicationProcess.step.applicationForms.displayItem.disclaimer.playDisclaimer' | translate)
          "
          [loading]="isDisclaimerPlaying"
        ></button>
      </span>

      <button
        class="p-button-danger ml-3"
        (click)="toggleDisclaimerPlaying()"
        pButton
        *ngIf="isDisclaimerPlaying && !isViewApplication"
        [label]="'applicationProcess.step.applicationForms.displayItem.disclaimer.stopDisclaimer' | translate"
      ></button>
    </div>
  </div>
</div>
