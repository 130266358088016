import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, startWith, take, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { ApplicationsProcessService, AuthService, CustomValidatorsService, StaticFieldLinkService } from '@iapplication2/services';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Application,
  ApplicationProduct,
  ApplicationStatusOptions,
  ClientFormEnum,
  FormGroupStatus,
  MessagesModel,
  Product,
  Severity,
  User,
} from '@iapplication2/interfaces';
import { ProductsResponse } from '../application-process-sections/client-selection/product-selection/product-selection.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import _ = require('lodash');
import { StringValidator } from '@iapplication2/validators';

@Component({
  selector: 'iapplication2-application-process-page',
  templateUrl: './application-process-page.component.html',
  styleUrls: ['./application-process-page.component.scss'],
  providers: [MessageService],
})
export class ApplicationProcessPageComponent implements OnInit, OnDestroy, AfterViewChecked {
  applicationProcess: MenuItem[];
  applicationId: number;
  currentStep = 0;
  formSubmissionTriggered: boolean;
  fnaForm: FormGroup;
  formIsLoading: boolean;
  saveOngoing = false;

  canGoToNextStep = false;
  canGoToNextStepTooltip = '';

  medicalQuestionnairesAreValid = false;
  selectedProductsHavePremium = false;
  fnaFormIsValid = false;
  staticDisclaimerValid = false;
  selectedProducts: Product[] = [];
  isAnyHumaniaProductSelected = false;

  translatedClientFields: unknown = {};

  translatedPlaceholdersForDropdowns: {
    productSelection: string;
    applicationForms: string;
    summary: string;
  } = {
    productSelection: '',
    applicationForms: '',
    summary: '',
  };

  // TODO: Refactor bellow to not require local initialization of object with empty values
  translatedSaveMessages: MessagesModel = {
    success: {
      summary: '',
      detail: '',
    },
    error: {
      summary: '',
      detail: '',
    },
  };
  translatedContinueTooltips: {
    medicalQuestionnaire: string;
    clientFormValidity: {
      general: string;
      specific: string;
    };
    selectedProductsHavePremium: string;
    fnaFormValidity: string;
    noProductSelected: string;
    saveOngoing: string;
    staticDisclaimerValid: string;
    waitingForChanges: string;
    humaniaCoverageOverMax: string;
  } = {
    medicalQuestionnaire: '',
    clientFormValidity: {
      general: '',
      specific: '',
    },
    selectedProductsHavePremium: '',
    fnaFormValidity: '',
    noProductSelected: '',
    saveOngoing: '',
    staticDisclaimerValid: '',
    waitingForChanges: '',
    humaniaCoverageOverMax: '',
  };

  clientForm: FormGroup;
  translationSubscriptionInitialized = false;

  displayApplicationWarning = false;
  currentApplicationStatus: any;

  humaniaCoverageOverMax = false;

  waitingForChanges = false;
  finishApplicationTriggered = false;

  saveDebounced = _.debounce((stopSaving: boolean = false) => {
    if (!stopSaving && !this.finishApplicationTriggered) {
      this.save();
    }
  }, 2000);
  getApplicationDebounced = _.debounce(() => this.getApplication(), 500);

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private translate: TranslateService,
    private applicationsProcessService: ApplicationsProcessService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private staticFieldLinkService: StaticFieldLinkService,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private customValidatorsService: CustomValidatorsService
  ) {}

  ngOnInit(): void {
    this.resetInvolvedServices();
    this.getApplicationDebounced();
    this.setAdvisorStaticLinkData();

    this.formSubmissionTriggered = false;

    this.createFnaForm();
    this.createClientForm();
    this.createCanGoToSummarySubscription();

    this.listenToLangChanges();

    this.setApplicationProcessForSteps();

    this.createFormChangesSubscription();
    this.watchTotalHumaniaCoverage();
    this.staticFieldLinkService.getMappableStaticFieldLinkItems();
  }

  setApplicationProcessForSteps() {
    this.applicationProcess = [
      {
        label: this.translatedPlaceholdersForDropdowns.productSelection,
      },
      {
        label: this.translatedPlaceholdersForDropdowns.applicationForms,
      },
      { label: this.translatedPlaceholdersForDropdowns.summary },
    ];
  }

  listenToLangChanges() {
    const combinedLangChanges$ = combineLatest([this.translate.onDefaultLangChange, this.translate.onLangChange]);
    combinedLangChanges$
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(() => {
          this.setTranslatedStrings();
        })
      )
      .subscribe({
        next: () => {
          this.setTranslatedStrings();
        },
      });
  }

  setTranslatedStrings() {
    this.getTranslationsForDropdownPlaceholders();
    this.getTranslationsForClientFields();
    this.getTranslationsForSaveMessages();
    this.getTranslatedContinueTooltips();
  }

  onWaitingForChanges(waiting: boolean) {
    this.waitingForChanges = waiting;
  }

  goToViewApplication() {
    this.router.navigate(['/applications/application/' + this.applicationId]);
  }

  setAdvisorStaticLinkData() {
    this.authService.user$.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: User) => {
        if (res) {
          this.staticFieldLinkService.setAdvisorDataToStaticFields(res);
        }
      },
    });
  }

  onFormLoadingStateChanged(isLoading: boolean) {
    this.formIsLoading = isLoading;
  }

  saveApplication() {
    if (this.applicationsProcessService.currentApplication?.status?.name === ApplicationStatusOptions.ACTIVE) {
      if (this.currentStep === 1) {
        this.applicationsProcessService.saveFormCalledFromStep2.next(true);
      } else {
        this.applicationsProcessService.saveApplication.next(true);
      }
    }
  }

  onGetApplicationTriggered(trigger: boolean) {
    if (trigger) {
      this.getApplicationDebounced();
    }
  }

  private getApplication() {
    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.applicationsProcessService.getApplicationById(this.applicationId).subscribe((res: any) => {
      if (!res) return;
      if (res.status?.name === ApplicationStatusOptions.ACTIVE) {
        this.applicationsProcessService.setCurrentApplication(res);
        this.currentApplicationStatus = this.applicationsProcessService.currentApplication?.status;

        /* if status is not Active, redirect to view application */
        if (
          this.applicationsProcessService.currentApplication.status?.name &&
          this.applicationsProcessService.currentApplication.status?.name !== ApplicationStatusOptions.ACTIVE
        ) {
          this.displayApplicationWarning = true;
        }
        this.checkUserRights(this.applicationsProcessService.currentApplication);
      } else {
        this.router.navigate(['/applications/application/' + this.applicationId]);
      }
    });
  }

  checkUserRights(application: Application) {
    this.authService.user$.pipe(take(1), takeUntil(this.unsubscribe)).subscribe({
      next: (res) => {
        if (res) {
          const userCanEditApplication = this.checkIfUserCanEditApplication(application.advisorId, res);

          if (userCanEditApplication) {
            if (application?.client?.birthDate) {
              this.getProductForClient(application);
            }
          } else {
            this.router.navigate(['']);
          }
        }
      },
    });
  }

  getProductForClient(application: Application) {
    this.applicationsProcessService
      .getProductsByClientDOB(application?.client?.birthDate?.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((productsResponse: ProductsResponse) => {
        const availableProducts = [];

        productsResponse.productsEvolution
          .filter((product) => product.iappProduct)
          .forEach((product) => {
            availableProducts.push(product);
          });
        productsResponse.products
          .filter((product) => product.iappProduct)
          .forEach((product) => {
            availableProducts.push(product);
          });

        this.applicationsProcessService.availableProducts = availableProducts;
      });
  }

  private checkIfUserCanEditApplication(applicationAdvisorId: number, user: User): boolean {
    const noAdvisorOnApplication = !applicationAdvisorId;
    const userIsAdvisorOnApplication = user?.id === applicationAdvisorId;
    const userIsTeamlead = user?.role?.id === 2;
    return noAdvisorOnApplication || userIsAdvisorOnApplication || userIsTeamlead;
  }

  private createFnaForm(): void {
    this.fnaForm = new FormGroup({
      fnaLiabilitiesMortgages: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesLoans: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesFinalExpenses: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesEducationFund: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesEmergencyFund: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesInsuredOccupation: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesHouseholdMembersAge: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesAerobicHoursPerWeek: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesHouseholdAnnualIncome: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesIndividualAnnualIncome: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesPercentNeeded: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaLiabilitiesYearsNeeded: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaAssetsCashSavings: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaAssetsCashBondsFunds: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaAssetsRealEstate: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaAssetsBusiness: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaAssetsTotalInsurance: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      fnaAssetsDeathBenefits: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
    });
  }

  createClientForm() {
    this.clientForm = new FormGroup({});

    const titleControl = new FormControl(null, [Validators.required]);
    this.clientForm.addControl(ClientFormEnum.TITLE, titleControl);

    const genderControl = new FormControl(null, [Validators.required]);
    this.clientForm.addControl(ClientFormEnum.GENDER, genderControl);

    const firstNameControl = new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]);
    this.clientForm.addControl(ClientFormEnum.FIRST_NAME, firstNameControl);

    const middleNameControl = new FormControl(null, [Validators.maxLength(50)]);
    this.clientForm.addControl(ClientFormEnum.MIDDLE_NAME, middleNameControl);

    const lastNameControl = new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]);
    this.clientForm.addControl(ClientFormEnum.LAST_NAME, lastNameControl);

    const languageControl = new FormControl(null, [Validators.required]);
    this.clientForm.addControl(ClientFormEnum.LANGUAGE, languageControl);

    const emailControl = new FormControl(null, [
      Validators.email,
      Validators.maxLength(100),
      StringValidator.requiredNoOnlySpaces(),
      Validators.required,
    ]);
    this.clientForm.addControl(ClientFormEnum.EMAIL, emailControl);

    const birthDateControl = new FormControl('', [Validators.required]);
    this.clientForm.addControl(ClientFormEnum.BIRTH_DATE, birthDateControl);

    const smokerStatusControl = new FormControl(null, [Validators.required]);
    this.clientForm.addControl(ClientFormEnum.SMOKER_STATUS, smokerStatusControl);

    const phoneNumberControl = new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]);
    this.clientForm.addControl(ClientFormEnum.PHONE_NUMBER, phoneNumberControl);

    const countryOfBirthControl = new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]);
    this.clientForm.addControl(ClientFormEnum.COUNTRY_OF_BIRTH, countryOfBirthControl);

    const addressControl = new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]);
    this.clientForm.addControl(ClientFormEnum.ADDRESS, addressControl);

    const provinceControl = new FormControl(null, [Validators.required]);
    this.clientForm.addControl(ClientFormEnum.PROVINCE, provinceControl);

    const cityControl = new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]);
    this.clientForm.addControl(ClientFormEnum.CITY, cityControl);

    const postalCodeControl = new FormControl(null, [Validators.required, this.customValidatorsService.postalCodeFormat()]);
    this.clientForm.addControl(ClientFormEnum.POSTAL_CODE, postalCodeControl);

    const noteControl = new FormControl(null, [Validators.maxLength(500)]);
    this.clientForm.addControl(ClientFormEnum.NOTE, noteControl);

    const reflexSmokerStatusControl = new FormControl(null);
    this.clientForm.addControl(ClientFormEnum.REFLEX_SMOKER_STATUS, reflexSmokerStatusControl);
  }

  getTranslatedContinueTooltips() {
    this.translate
      .get('applicationProcess.step.productSelection.continueTooltip')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.translatedContinueTooltips.clientFormValidity.general = res.clientFormValidity?.general;
        this.translatedContinueTooltips.clientFormValidity.specific = res.clientFormValidity?.specific;
        this.translatedContinueTooltips.medicalQuestionnaire = res.medicalQuestionnaire;
        this.translatedContinueTooltips.selectedProductsHavePremium = res.selectedProductsHavePremium;
        this.translatedContinueTooltips.fnaFormValidity = res.fnaFormValidity;
        this.translatedContinueTooltips.noProductSelected = res.noProductSelected;
        this.translatedContinueTooltips.saveOngoing = res.saveOngoing;
        this.translatedContinueTooltips.waitingForChanges = res.waitingForChanges;
        this.translatedContinueTooltips.staticDisclaimerValid = res.staticDisclaimerValid;
        this.translatedContinueTooltips.humaniaCoverageOverMax = res.humaniaCoverageOverMax;
      });
  }

  getTranslationsForClientFields() {
    this.translate
      .get('client_selection')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.translationSubscriptionInitialized = true;
        this.translatedClientFields = res;
        this.checkCanGoToNextStep();
      });
  }

  getTranslationsForDropdownPlaceholders() {
    this.translate
      .get('applicationProcess.step')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.translatedPlaceholdersForDropdowns.productSelection =
          res.productSelection?.title || this.translatedPlaceholdersForDropdowns.productSelection;
        this.translatedPlaceholdersForDropdowns.applicationForms =
          res.applicationForms?.title || this.translatedPlaceholdersForDropdowns.applicationForms;
        this.translatedPlaceholdersForDropdowns.summary = res.summary?.title || this.translatedPlaceholdersForDropdowns.summary;
        this.setApplicationProcessForSteps();
      });
  }

  getTranslationsForSaveMessages() {
    this.translate
      .get('applicationProcess.saveMessages')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.translatedSaveMessages.success.summary = res.success?.summary;
        this.translatedSaveMessages.success.detail = res.success?.detail;
        this.translatedSaveMessages.error.summary = res.error?.summary;
        this.translatedSaveMessages.error.detail = res.error?.detail;
      });
  }

  onStaticApplicationDisclosureAnswerChanged() {
    const staticApplicationDisclosureAnswers = this.applicationsProcessService.currentApplication.staticApplicationDisclosureAnswers;
    this.staticDisclaimerValid = this.isAnyHumaniaProductSelected
      ? staticApplicationDisclosureAnswers.every((answer) => answer.answer === true) && staticApplicationDisclosureAnswers?.length > 0
      : true;
    this.saveApplicationDataAutosave();
  }

  onMedicalQuestionnairesAreValidChanged(medicalQuestionnairesAreValid) {
    this.medicalQuestionnairesAreValid = medicalQuestionnairesAreValid;
    this.saveApplicationDataAutosave();
    this.checkCanGoToNextStep();
  }

  onProductSelectionChanged(selectedProducts: Product[]) {
    this.selectedProducts = selectedProducts;
    this.checkIfAllProductsHavePremiumSet(selectedProducts);
    this.saveApplicationDataAutosave();
    this.checkCanGoToNextStep();
  }

  checkIfAllProductsHavePremiumSet(selectedProducts: Product[]) {
    const productWithNoPremium = selectedProducts.find(
      (selectedProduct: Product) => !selectedProduct.monthlyPremium || !selectedProduct.yearlyPremium
    );
    this.selectedProductsHavePremium = !productWithNoPremium;
  }

  onFnaFormIsValid(fnaFormIsValid) {
    if (!this.translationSubscriptionInitialized) {
      this.getTranslationsForClientFields();
    }
    this.fnaFormIsValid = fnaFormIsValid;
    this.saveApplicationDataAutosave();
    this.checkCanGoToNextStep();
  }

  private createFormChangesSubscription(): void {
    this.clientForm.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(() => {
      this.saveApplicationDataAutosave();
      this.checkCanGoToNextStep();
    });
  }

  private createCanGoToSummarySubscription() {
    this.applicationsProcessService.formValidCanMoveToSummary.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (formValid: boolean) => {
        if (formValid) {
          this.nextStep(true);
        }
      },
    });
  }

  private checkCanGoToNextStep() {
    const formValidForHumaniaProductsNoSmoker = Object.keys(this.clientForm.controls)
      .filter((controlKey) => controlKey !== ClientFormEnum.SMOKER_STATUS)
      .every((controlKey) => this.clientForm.controls[controlKey].valid);
    this.canGoToNextStep =
      (this.clientForm.valid || formValidForHumaniaProductsNoSmoker) &&
      this.medicalQuestionnairesAreValid &&
      this.selectedProductsHavePremium &&
      this.fnaFormIsValid &&
      this.staticDisclaimerValid &&
      !!this.selectedProducts.length &&
      !this.saveOngoing &&
      !this.humaniaCoverageOverMax;

    const canGoToNextStepTooltip = {
      medicalQuestionnaire: null,
      clientFormValidity: null,
      selectedProductsHavePremium: null,
      fnaFormValidity: null,
      noProductSelected: null,
      saveOngoing: null,
      staticDisclaimerValid: null,
      waitingForChanges: null,
      humaniaCoverageOverMax: null,
    };

    switch (this.canGoToNextStep) {
      case true:
        this.canGoToNextStepTooltip = null;
        break;
      case false:
        switch (this.clientForm.valid) {
          case true:
            canGoToNextStepTooltip.clientFormValidity = null;
            break;
          case false: {
            const generalTooltip = this.translatedContinueTooltips.clientFormValidity.general;
            const invalidFields: string[] = [];
            Object.keys(this.clientForm.controls).forEach((control) => {
              if (this.clientForm.controls[control].status === FormGroupStatus.INVALID) {
                const translatedValue = this.translatedClientFields[_.snakeCase(control)];
                if (translatedValue) {
                  invalidFields.push(translatedValue);
                }
              }
            });

            if (invalidFields.length) {
              canGoToNextStepTooltip.clientFormValidity = `${
                this.translatedContinueTooltips.clientFormValidity.specific
              }${invalidFields.join(', ')}`;
            } else {
              canGoToNextStepTooltip.clientFormValidity = generalTooltip;
            }
            break;
          }
        }
        switch (this.medicalQuestionnairesAreValid) {
          case true:
            canGoToNextStepTooltip.medicalQuestionnaire = null;
            break;
          case false:
            canGoToNextStepTooltip.medicalQuestionnaire = this.translatedContinueTooltips.medicalQuestionnaire;
            break;
        }
        switch (this.fnaFormIsValid) {
          case true:
            canGoToNextStepTooltip.fnaFormValidity = null;
            break;
          case false:
            canGoToNextStepTooltip.fnaFormValidity = this.translatedContinueTooltips.fnaFormValidity;
            break;
        }
        switch (!!this.selectedProducts.length) {
          case true:
            switch (this.selectedProductsHavePremium) {
              case true:
                canGoToNextStepTooltip.selectedProductsHavePremium = null;
                break;
              case false:
                canGoToNextStepTooltip.selectedProductsHavePremium = this.translatedContinueTooltips.selectedProductsHavePremium;
                break;
            }
            break;
          case false:
            canGoToNextStepTooltip.noProductSelected = this.translatedContinueTooltips.noProductSelected;
            break;
        }
        switch (this.saveOngoing) {
          case true:
            canGoToNextStepTooltip.saveOngoing = this.translatedContinueTooltips.saveOngoing;
            break;
          case false:
            canGoToNextStepTooltip.saveOngoing = null;
            break;
        }
        switch (this.waitingForChanges) {
          case true:
            canGoToNextStepTooltip.waitingForChanges = this.translatedContinueTooltips.waitingForChanges;
            break;
          case false:
            canGoToNextStepTooltip.waitingForChanges = null;
            break;
        }
        switch (!this.staticDisclaimerValid) {
          case true:
            canGoToNextStepTooltip.staticDisclaimerValid = this.translatedContinueTooltips.staticDisclaimerValid;
            break;
          case false:
            canGoToNextStepTooltip.staticDisclaimerValid = null;
            break;
        }
        switch (this.humaniaCoverageOverMax) {
          case true:
            canGoToNextStepTooltip.humaniaCoverageOverMax = this.translatedContinueTooltips.humaniaCoverageOverMax;
            break;
          case false:
            canGoToNextStepTooltip.humaniaCoverageOverMax = null;
            break;
        }
    }

    const tooltips: string[] = [];
    Object.keys(canGoToNextStepTooltip).forEach((key) => {
      if (canGoToNextStepTooltip[key]) {
        tooltips.push(canGoToNextStepTooltip[key]);
      }
    });

    this.canGoToNextStepTooltip = _.capitalize(tooltips.join(' and '));
  }

  onIsAnyHumaniaProductSelected(event) {
    this.isAnyHumaniaProductSelected = event;
    this.onStaticApplicationDisclosureAnswerChanged();
  }

  onFinishApplicationTriggered(onFinishApplicationTriggered: boolean) {
    this.finishApplicationTriggered = onFinishApplicationTriggered;
  }

  getWidthByStep() {
    return this.currentStep === 0 ? 100 : this.currentStep === 1 ? 60 : 50;
  }

  previousStep() {
    this.getApplicationDebounced();
    if (this.currentStep > 0) {
      this.applicationsProcessService.pdfGenerationCompleted.next(null);
      this.applicationsProcessService.applicationFormDataArray = [];
      this.currentStep--;
      this.applicationsProcessService.onMovedToSummary.next(false);
    }
  }

  nextStep(skip = false) {
    if (this.currentStep === 0) {
      this.formSubmissionTriggered = true;
    }
    if (this.currentStep === 1 && !skip) {
      this.applicationsProcessService.onMovedToSummary.next(true);
      return;
    }
    if (this.currentStep < 4) {
      this.currentStep++;
    }
    this.saveOnGoingToNextStep();
  }

  ngAfterViewChecked() {
    // ↓ Fix for IAPP2CA-771:
    this.cdr.detectChanges();
  }

  resetInvolvedServices() {
    this.applicationsProcessService.reset();
    this.staticFieldLinkService.reset();
  }

  ngOnDestroy() {
    this.saveDebounced(true);
    this.resetInvolvedServices();
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  filterApplicationData(): any {
    if (this.applicationId && this.applicationsProcessService.currentApplication) {
      const applicationToUpdate: any = { ...this.applicationsProcessService.currentApplication };

      delete applicationToUpdate.id;
      applicationToUpdate.policyId = null;

      applicationToUpdate.client = this.clientForm.getRawValue();
      applicationToUpdate.client.advisorId = this.applicationsProcessService.currentApplication?.advisorId
        ? this.applicationsProcessService.currentApplication?.advisorId
        : this.applicationsProcessService.currentApplication?.client?.advisorId;
      applicationToUpdate.client.id = this.applicationsProcessService.currentApplication?.client?.id;
      applicationToUpdate.client.applicationClientId = this.applicationsProcessService.currentApplication?.client?.applicationClientId;

      applicationToUpdate.client.gender = this.clientForm.value['gender'];
      applicationToUpdate.client.title = this.clientForm.value['title'];

      applicationToUpdate.status = this.applicationsProcessService.currentApplication?.status;
      delete applicationToUpdate.status?.status;
      delete applicationToUpdate.status?.displayedInDropdown;
      applicationToUpdate.products = [];
      if (this.selectedProducts) {
        this.selectedProducts.forEach((selectedProduct: Product) => {
          let item: ApplicationProduct;

          const savedProduct = this.applicationsProcessService.currentApplication?.products?.find(
            (applicationProduct: ApplicationProduct) => applicationProduct.productId === selectedProduct.id
          );

          const unmodifiableProductProperties = {
            productId: selectedProduct.id,
            name: selectedProduct.name,
            selected: 1,
            minCoverage: 0,
            maxCoverage: 0,
            requiresQuestionnaire: 0,
            productStatusId: 1,
            risk: selectedProduct.risk,
          };

          const modifiableProductProperties = {
            payment: {
              value: selectedProduct?.payment?.value || savedProduct?.payment?.value || null,
              method: selectedProduct?.payment?.method || savedProduct?.payment?.method || null,
            },
            coverage: selectedProduct.coverage,
            monthlyPremium: selectedProduct.monthlyPremium,
            yearlyPremium: selectedProduct.yearlyPremium,
            withEverest: selectedProduct.hasEverestAddon,
            everestPremium: selectedProduct.everestPremium,
            productVersion: selectedProduct.productVersion,
          };

          if (savedProduct) {
            item = {
              ...savedProduct,
              ...modifiableProductProperties,
            };
          } else {
            item = {
              ...unmodifiableProductProperties,
              ...modifiableProductProperties,
            };
          }
          applicationToUpdate.products.push(item);
        });
      }

      applicationToUpdate.premium = 2;
      applicationToUpdate.currency = {
        id: 2,
        name: 'DOLLAR',
        symbol: '$',
      };
      applicationToUpdate.notes = this.clientForm.get('note').value || '';
      applicationToUpdate.policyId = this.applicationsProcessService.currentApplication?.policyId;

      applicationToUpdate.advisorId = applicationToUpdate.advisorId ? applicationToUpdate.advisorId : this.authService.user.id;
      delete applicationToUpdate.fna;

      return applicationToUpdate;
    }
  }

  saveApplicationDataAutosave() {
    this.saveOngoing = true;
    this.saveDebounced();
  }

  save() {
    const filteredApplication = this.filterApplicationData();

    if (this.applicationId && this.applicationsProcessService.currentApplication?.status?.name === ApplicationStatusOptions.ACTIVE) {
      this.applicationsProcessService.updateApplication(this.applicationId, filteredApplication)?.subscribe({
        next: (res: Application) => {
          this.saveOngoing = false;
          this.checkCanGoToNextStep();
          this.showApplicationSavedSuccessMessage();
          this.applicationsProcessService.setCurrentApplication(res, true);
        },
        error: () => {
          this.saveOngoing = false;
          this.checkCanGoToNextStep();
          this.showApplicationSavedErrorMessage();
        },
      });
    }
  }

  saveOnGoingToNextStep() {
    const application = this.filterApplicationData();

    if (this.applicationId) {
      this.applicationsProcessService.updateApplication(this.applicationId, application).subscribe({
        next: () => {
          this.saveOngoing = false;
          this.getApplicationDebounced();
          this.checkCanGoToNextStep();
          this.showApplicationSavedSuccessMessage();
        },
        error: () => {
          this.saveOngoing = false;
          this.checkCanGoToNextStep();
          this.showApplicationSavedErrorMessage();
        },
      });
    }
  }

  showApplicationSavedSuccessMessage() {
    this.messageService.clear();
    this.messageService.add({
      key: 'save-application-message',
      severity: Severity.SUCCESS,
      summary: this.translatedSaveMessages.success.summary,
      detail: this.translatedSaveMessages.success.detail,
    });
  }

  showApplicationSavedErrorMessage() {
    this.messageService.clear();
    this.messageService.add({
      key: 'save-application-message',
      severity: Severity.ERROR,
      summary: this.translatedSaveMessages.error.summary,
      detail: this.translatedSaveMessages.error.detail,
    });
  }

  private watchTotalHumaniaCoverage() {
    this.applicationsProcessService.isHumaniaCoverageOverMax
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isHumaniaCoverageOverMax: boolean) => {
        this.humaniaCoverageOverMax = isHumaniaCoverageOverMax;
        this.checkCanGoToNextStep();
      });
  }
}
