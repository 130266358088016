import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Currency } from '@iapplication2/interfaces';
import { ApplicationsProcessService } from '@iapplication2/services';

@Component({
  selector: 'iapplication2-product-list-display',
  templateUrl: './product-list-display.component.html',
  styleUrls: ['./product-list-display.component.scss'],
})
export class ProductListDisplayComponent implements OnInit, OnChanges {
  @Input() currency: Currency;
  @Input() displayInTable = true;

  totalMonthlyPremium = 0;
  totalYearlyPremium = 0;
  columnContent: string;

  constructor(public applicationsProcessService: ApplicationsProcessService) {}

  ngOnChanges(): void {
    this.calculateTotalPremiums();
  }

  ngOnInit() {
    this.calculateTotalPremiums();
    this.applicationsProcessService.applicationChanged.subscribe({
      next: () => {
        this.calculateTotalPremiums();
      },
    });

    if (this.applicationsProcessService.currentApplication?.products) {
      this.columnContent = this.getColumnContent(this.applicationsProcessService.currentApplication?.products);
    }
  }

  private calculateTotalPremiums() {
    this.totalMonthlyPremium = 0;
    this.totalYearlyPremium = 0;
    this.applicationsProcessService.currentApplication?.products?.forEach((product) => {
      if (Number(product.monthlyPremium)) {
        this.totalMonthlyPremium += Number(product.monthlyPremium);
      }

      if (Number(product.yearlyPremium)) {
        this.totalYearlyPremium += Number(product.yearlyPremium);
      }
    });
  }

  getColumnContent(products): string {
    let result: string;

    products?.forEach((product) => {
      if (product?.addons?.length) {
        let productAddons: string = product?.name ? product?.name + ': ' : '';
        product?.addons?.forEach((addon, index) => {
          productAddons = productAddons + addon?.name + (index < product?.addons?.length - 1 ? ', ' : '\n');
        });

        result = (result || '') + productAddons;
      }
    });

    if (result) return result;
  }
}
