import { Injectable } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Iapp1Ids } from '../../../../../apps/api-service/src/app/common/enums';
import { ClientFormEnum, FormBuilderFieldStaticFieldLink, StaticFieldLinkType, User } from '@iapplication2/interfaces';
import { InteractiveFormBuilderStaticFieldLinkService } from '../interactive-form-builder-static-field-link/interactive-form-builder-static-field-link.service';
import _ = require('lodash');

@Injectable({
  providedIn: 'root',
})
export class StaticFieldLinkService {
  private mappableStaticFieldLinkItems: FormBuilderFieldStaticFieldLink[] = [];

  staticFieldLinkData = {};

  constructor(private interactiveFormBuilderStaticFieldLinkService: InteractiveFormBuilderStaticFieldLinkService) {}

  getMappableStaticFieldLinkItems() {
    this.interactiveFormBuilderStaticFieldLinkService.getAllStaticFields().subscribe({
      next: (res: FormBuilderFieldStaticFieldLink[]) => {
        this.mappableStaticFieldLinkItems = res.filter((staticLink) => !!staticLink.fieldKey);
      },
    });
  }

  getMappableStaticFieldLinkItemsByType(type: StaticFieldLinkType): FormBuilderFieldStaticFieldLink[] {
    return this.mappableStaticFieldLinkItems.filter((staticLink) => staticLink.type === type);
  }

  setStaticFieldLinkDataByTypeAndKey(type: StaticFieldLinkType, iapp1Id: string, value: any, formId: string = null) {
    iapp1Id = _.camelCase(iapp1Id);
    if (!value || !iapp1Id) return;

    const camelCaseType = _.camelCase(type);
    if (!this.staticFieldLinkData[camelCaseType]) {
      this.staticFieldLinkData[camelCaseType] = {};
    }

    if (formId && this.staticFieldLinkData[camelCaseType][iapp1Id]) {
      const formValue: { [key: string]: any } = this.getRawValue(value, formId) as { [key: string]: any };
      this.staticFieldLinkData[camelCaseType][iapp1Id] = {
        ...this.staticFieldLinkData[camelCaseType][iapp1Id],
        ...formValue,
      };
    } else {
      this.staticFieldLinkData[camelCaseType][iapp1Id] = this.getRawValue(value, formId);
    }
    this.mapFullNames(type, iapp1Id);
  }

  mapFullNames(type: StaticFieldLinkType, key: string) {
    const camelCaseType = _.camelCase(type);
    const uppercaseKey = key.toUpperCase();
    const nameOptions = ['FIRSTNAME', 'LASTNAME', 'MIDDLENAME'];
    const option = nameOptions.find((option) => uppercaseKey.includes(option));
    if (option) {
      let person = uppercaseKey.replace(option, '');
      person = person.toLowerCase();
      const fullNameKey = (person === '' ? 'insured' : person) + 'FullName';
      const firstName = this.staticFieldLinkData[camelCaseType][person + 'firstName'];
      const lastName = this.staticFieldLinkData[camelCaseType][person + 'lastName'];
      const middleName = this.staticFieldLinkData[camelCaseType][person + 'middleName'];
      const value = `${firstName ? firstName + ' ' : ''}${middleName ? middleName + ' ' : ''}${lastName ? lastName : ''}`;
      if (value) {
        this.setStaticFieldLinkDataByTypeAndKey(type, fullNameKey, value);
      }
    }
  }

  getStaticFieldLinkDataByTypeAndKey(type: string, key: string): string {
    const camelCaseType = _.camelCase(type);
    const iapp1IdByClientFormKey = this.getClientFormKeyByIapp1Id(key);

    return this.staticFieldLinkData[camelCaseType]?.[iapp1IdByClientFormKey];
  }

  private getRawValue(value: any, formId: string): string | { [key: string]: any } {
    switch (true) {
      case !!formId:
        return {
          [formId]: this.getRawValue(value, null),
        };
      case !!value?.name:
        return value?.name;

      default:
        return value;
    }
  }
  public getIapp1IdByClientFormKey(clientFormKey: string): string {
    let returnValue: string;

    switch (true) {
      case clientFormKey === ClientFormEnum.ADDRESS:
        returnValue = Iapp1Ids.PRIMARY_ADDRESS;
        break;

      case clientFormKey === ClientFormEnum.BIRTH_DATE:
        returnValue = Iapp1Ids.PRIMARY_DOB;
        break;

      case clientFormKey === ClientFormEnum.CITY:
        returnValue = Iapp1Ids.PRIMARY_CITY;
        break;

      case clientFormKey === ClientFormEnum.COUNTRY_OF_BIRTH:
        returnValue = Iapp1Ids.PRIMARY_COUNTRY_BIRTH;
        break;

      case clientFormKey === ClientFormEnum.EMAIL:
        returnValue = Iapp1Ids.PRIMARY_EMAIL;
        break;

      case clientFormKey === ClientFormEnum.FIRST_NAME:
        returnValue = Iapp1Ids.PRIMARY_FIRSTNAME;
        break;

      case clientFormKey === ClientFormEnum.GENDER:
        returnValue = Iapp1Ids.PRIMARY_GENDER;
        break;

      case clientFormKey === ClientFormEnum.LAST_NAME:
        returnValue = Iapp1Ids.PRIMARY_LASTNAME;
        break;

      case clientFormKey === ClientFormEnum.MIDDLE_NAME:
        returnValue = Iapp1Ids.PRIMARY_MIDDLENAME;
        break;

      case clientFormKey === ClientFormEnum.PHONE_NUMBER:
        returnValue = Iapp1Ids.PRIMARY_MOBILE;
        break;

      case clientFormKey === ClientFormEnum.POSTAL_CODE:
        returnValue = Iapp1Ids.PRIMARY_POSTAL;
        break;

      case clientFormKey === ClientFormEnum.PROVINCE:
        returnValue = Iapp1Ids.PRIMARY_PROVINCE;
        break;

      case clientFormKey === ClientFormEnum.SMOKER_STATUS:
        returnValue = Iapp1Ids.PRIMARY_SMOKER;
        break;

      case clientFormKey === ClientFormEnum.TITLE:
        returnValue = Iapp1Ids.PRIMARY_SALUTATION;
        break;

      case clientFormKey === ClientFormEnum.HUMANIA_TITLE:
        returnValue = Iapp1Ids.HUMANIA_TITLE;
        break;
    }

    return _.camelCase(returnValue);
  }

  public getClientFormKeyByIapp1Id(clientFormKey: string): string {
    let returnValue: string;

    switch (true) {
      case clientFormKey === Iapp1Ids.PRIMARY_ADDRESS:
        returnValue = ClientFormEnum.ADDRESS;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_DOB:
        returnValue = ClientFormEnum.BIRTH_DATE;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_CITY:
        returnValue = ClientFormEnum.CITY;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_COUNTRY_BIRTH:
        returnValue = ClientFormEnum.COUNTRY_OF_BIRTH;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_EMAIL:
        returnValue = ClientFormEnum.EMAIL;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_FIRSTNAME:
        returnValue = ClientFormEnum.FIRST_NAME;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_GENDER:
        returnValue = ClientFormEnum.GENDER;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_LASTNAME:
        returnValue = ClientFormEnum.LAST_NAME;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_MIDDLENAME:
        returnValue = ClientFormEnum.MIDDLE_NAME;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_MOBILE:
        returnValue = ClientFormEnum.PHONE_NUMBER;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_POSTAL:
        returnValue = ClientFormEnum.POSTAL_CODE;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_PROVINCE:
        returnValue = ClientFormEnum.PROVINCE;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_SMOKER:
        returnValue = ClientFormEnum.SMOKER_STATUS;
        break;

      case clientFormKey === Iapp1Ids.PRIMARY_SALUTATION:
        returnValue = ClientFormEnum.TITLE;
        break;

      case clientFormKey === Iapp1Ids.HUMANIA_TITLE:
        returnValue = ClientFormEnum.HUMANIA_TITLE;
        break;
      default:
        returnValue = clientFormKey;
    }

    return _.camelCase(returnValue);
  }

  public getReflexKeyByIapp1Id(iapp1Id: string): string {
    let returnValue: string;

    switch (true) {
      case iapp1Id === Iapp1Ids.PRIMARY_COUNTRY_BIRTH:
        returnValue = 'countryOfBirth';
        break;
      case iapp1Id === Iapp1Ids.PRIMARY_PROVINCE_BIRTH:
        returnValue = 'primaryProvinceBirth';
        break;
      case iapp1Id === Iapp1Ids.PRIMARY_RESIDENCY:
        returnValue = 'residencyStatus';
        break;
      case iapp1Id === Iapp1Ids.PRIMARY_RESIDENCY_OTHER_STATUS:
        returnValue = 'residencyStatusOther';
        break;
      default:
        returnValue = iapp1Id;
    }

    return _.camelCase(returnValue);
  }

  setAdvisorDataToStaticFields(user: User) {
    this.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.ADVISORS, Iapp1Ids.ADVISOR_FIRST_NAME, user.firstName);
    this.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.ADVISORS, Iapp1Ids.ADVISOR_LAST_NAME, user.lastName);
    this.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.ADVISORS, Iapp1Ids.ADVISOR_MIDDLE_NAME, user.middleName);
    this.setStaticFieldLinkDataByTypeAndKey(
      StaticFieldLinkType.ADVISORS,
      Iapp1Ids.ADVISOR_FULL_NAME,
      `${user.firstName} ${user.middleName ? `${user.middleName} ` : ''}${user.lastName}`
    );
    this.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.ADVISORS, Iapp1Ids.ADVISOR_PHONE_NUMBER, user.phoneNumber);
    this.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.ADVISORS, Iapp1Ids.ADVISOR_EMAIL, user.email);
    this.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.ADVISORS, Iapp1Ids.ADVISOR_CODE, user.code);
    this.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.ADVISORS, Iapp1Ids.ADVISOR_SPLIT, '100');
  }

  reset() {
    this.mappableStaticFieldLinkItems = [];
    this.staticFieldLinkData = {};
  }
}
