<div class="card">
  <h5>{{ 'applicationDigitalSignatures.applicationDigitalSignatures' | translate }}</h5>
  <div *ngIf="digitalSignatures" class="flex">
    <form class="flex-auto" [formGroup]="digitalSignaturesForm">
      <p-table [value]="digitalSignatures" responsiveLayout="stack" [breakpoint]="'768px'">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>{{ 'applicationDigitalSignatures.signatureFor' | translate }}</th>
            <th>{{ 'applicationDigitalSignatures.signatureStatus' | translate }}</th>
            <th>{{ 'applicationDigitalSignatures.lastModified' | translate }}</th>
            <th>{{ 'applicationDigitalSignatures.signatureType' | translate }}</th>
            <th>{{ 'applicationDigitalSignatures.method' | translate }}</th>
            <th>{{ 'applicationDigitalSignatures.signerInfo' | translate }}</th>
            <th>{{ 'applicationDigitalSignatures.expiryDate' | translate }}</th>
            <th>{{ 'applicationDigitalSignatures.actions' | translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-signature let-columns="columns">
          <tr ngClass="{{ signature.status === SignatureStatusEnum.SIGNED ? 'disabled-events' : '' }}">
            <td>
              <span class="p-column-title">{{ 'applicationDigitalSignatures.signatureFor' | translate }}</span
              ><b>{{ 'applicationDigitalSignatures.' + signature.signatureFor | translate }}</b>
            </td>
            <td>
              <span class="p-column-title">{{ 'applicationDigitalSignatures.signatureStatus' | translate }}</span
              ><b>
                {{
                  signature?.signedAt
                    ? ('applicationDigitalSignatures.signedAt' | translate: { date: (signature.signedAt | date: 'dd/MM/yyyy') })
                    : ('applicationDigitalSignatures.' + signature.status | translate)
                }}
              </b>
            </td>
            <td>
              <span class="p-column-title">{{ 'applicationDigitalSignatures.lastModified' | translate }}</span
              ><b>{{ (signature.updatedAt ? signature.updatedAt : signature.createdAt) | date: 'dd/MM/yyyy' }}</b>
            </td>
            <td>
              <span class="p-column-title">{{ 'applicationDigitalSignatures.signatureType' | translate }}</span>
              <div>
                <p-radioButton
                  [value]="SignatureTypeEnum.VOICESIGNATURE"
                  [formControlName]="'signature-' + digitalSignatures.indexOf(signature)"
                  label="{{ 'applicationDigitalSignatures.voiceSignature' | translate }}"
                  class="mr-3 mb-2"
                ></p-radioButton>
                <p-radioButton
                  [value]="SignatureTypeEnum.ESIGNATURE"
                  [formControlName]="'signature-' + digitalSignatures.indexOf(signature)"
                  label="{{ 'applicationDigitalSignatures.eSignature' | translate }}"
                ></p-radioButton>
              </div>
            </td>
            <td>
              <span class="p-column-title">{{ 'applicationDigitalSignatures.method' | translate }}</span>
              <div class="flex flex-column">
                <div class="mb-2">
                  <p-checkbox
                    [binary]="true"
                    [formControlName]="'email-' + digitalSignatures.indexOf(signature)"
                    [inputId]="'inputid'"
                  ></p-checkbox>
                  <label [for]="'inputid'"> {{ 'applicationDigitalSignatures.signatureChanelEmail' | translate }} </label>
                </div>

                <div class="mb-2">
                  <p-checkbox
                    [binary]="true"
                    [formControlName]="'sms-' + digitalSignatures.indexOf(signature)"
                    [inputId]="'inptID'"
                  ></p-checkbox>
                  <label [for]="'inptID'"> {{ 'applicationDigitalSignatures.signatureChanelSms' | translate }} </label>
                </div>
              </div>
            </td>
            <td>
              <span class="p-column-title">{{ 'applicationDigitalSignatures.signerInfo' | translate }}</span>
              <div class="flex flex-column">
                <div fxLayout="column" class="pr-3 mb-2">
                  <div fxFlex fxLayout="row">
                    <label class="pb-1" for="fullName">{{ 'client_selection.first_name' | translate }}</label>
                    <iapplication2-required
                      [value]="digitalSignaturesForm.controls['fullName-' + digitalSignatures.indexOf(signature)].value"
                      class="flex ai-center"
                      style="min-width: 20px"
                      [required]="true"
                    ></iapplication2-required>
                  </div>
                  <input
                    id="fullName"
                    type="text"
                    class="p-inputtext capitalize-text"
                    pInputText
                    [placeholder]="'applicationDigitalSignatures.fullName' | translate"
                    [formControlName]="'fullName-' + digitalSignatures.indexOf(signature)"
                  />
                  <iapplication2-form-field-error
                    *ngIf="digitalSignaturesForm.controls['fullName-' + digitalSignatures.indexOf(signature)].invalid"
                    [control]="digitalSignaturesForm.controls['fullName-' + digitalSignatures.indexOf(signature)]"
                    style="bottom: -8px"
                  ></iapplication2-form-field-error>
                </div>
                <div fxLayout="column" class="pr-3 mb-2">
                  <div fxFlex fxLayout="row">
                    <label class="pb-1" for="email">{{ 'applicationDigitalSignatures.signatureChanelEmail' | translate }}</label>
                    <iapplication2-required
                      [value]="digitalSignaturesForm.controls['emailValue-' + digitalSignatures.indexOf(signature)].value"
                      class="flex ai-center"
                      style="min-width: 20px"
                      [required]="true"
                    ></iapplication2-required>
                  </div>
                  <input
                    id="email"
                    type="email"
                    class="p-inputtext p-component"
                    pInputText
                    [placeholder]="'applicationDigitalSignatures.signatureChanelEmail' | translate"
                    [formControlName]="'emailValue-' + digitalSignatures.indexOf(signature)"
                  />
                  <small
                    *ngIf="digitalSignaturesForm.controls['emailValue-' + digitalSignatures.indexOf(signature)].invalid"
                    class="p-error"
                  >
                    {{ 'validations.invalidEmail' | translate }}
                  </small>
                </div>
                <div fxLayout="column" class="pr-3">
                  <div fxFlex fxLayout="row">
                    <label class="pb-1" for="phoneNumber">{{ 'applicationDigitalSignatures.phoneNumber' | translate }}</label>
                    <iapplication2-required
                      [value]="digitalSignaturesForm.controls['phoneNumber-' + digitalSignatures.indexOf(signature)].value"
                      class="flex ai-center"
                      style="min-width: 20px"
                      [required]="true"
                    ></iapplication2-required>
                  </div>
                  <p-inputMask
                    id="phoneNumber"
                    class="flex flex-column"
                    type="text"
                    [formControlName]="'phoneNumber-' + digitalSignatures.indexOf(signature)"
                    [placeholder]="'applicationDigitalSignatures.phoneNumber' | translate"
                    mask="(999) 999-9999"
                  ></p-inputMask>
                  <iapplication2-form-field-error
                    *ngIf="digitalSignaturesForm.controls['phoneNumber-' + digitalSignatures.indexOf(signature)].touched"
                    [control]="digitalSignaturesForm.controls['phoneNumber-' + digitalSignatures.indexOf(signature)]"
                    style="bottom: -8px"
                  ></iapplication2-form-field-error>
                </div>
              </div>
            </td>
            <td>
              <span class="p-column-title">{{ 'applicationDigitalSignatures.expiryDate' | translate }}</span
              ><b>{{ signature.expiryDate | date: 'dd/MM/yyyy' }}</b>
            </td>
            <td>
              <span class="p-column-title">{{ 'applicationDigitalSignatures.actions' | translate }}</span>
              <div class="flex flex-column">
                <p-button
                  class="mb-2"
                  [disabled]="
                    digitalSignaturesForm.controls['signature-' + digitalSignatures.indexOf(signature)].value !==
                      SignatureTypeEnum.VOICESIGNATURE &&
                    (digitalSignaturesForm?.controls['emailValue-' + digitalSignatures.indexOf(signature)].invalid ||
                      digitalSignaturesForm?.controls['phoneNumber-' + digitalSignatures.indexOf(signature)].invalid ||
                      digitalSignaturesForm?.controls['fullName-' + digitalSignatures.indexOf(signature)].invalid ||
                      (!digitalSignaturesForm?.controls['email-' + digitalSignatures.indexOf(signature)].value &&
                        !digitalSignaturesForm?.controls['sms-' + digitalSignatures.indexOf(signature)].value) ||
                      (digitalSignaturesForm?.controls['email-' + digitalSignatures.indexOf(signature)].value &&
                        !digitalSignaturesForm?.controls['emailValue-' + digitalSignatures.indexOf(signature)].value) ||
                      (digitalSignaturesForm?.controls['sms-' + digitalSignatures.indexOf(signature)].value &&
                        !digitalSignaturesForm?.controls['phoneNumber-' + digitalSignatures.indexOf(signature)].value))
                  "
                  (click)="signatureActionButtonClicked(digitalSignatures.indexOf(signature), signature.id)"
                  >{{
                  (
                    signature.isSignatureChanged ||
                    (digitalSignaturesForm.controls['signature-' + digitalSignatures.indexOf(signature)]?.value === SignatureTypeEnum.VOICESIGNATURE)
                  )
                    ? ('applicationDigitalSignatures.saveSignature' | translate)
                    : ('applicationDigitalSignatures.resendSignature' | translate)
                  }}</p-button
                >
                <p-button
                  *ngIf="
                    digitalSignaturesForm.controls['signature-' + digitalSignatures.indexOf(signature)]?.value ===
                      SignatureTypeEnum.ESIGNATURE && signature.guid
                  "
                  (click)="copyToClipboardSignatureLink(digitalSignatures.indexOf(signature))"
                  >{{ 'applicationDigitalSignatures.copySignatureLink' | translate }}</p-button
                >
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </form>
  </div>
  <p-toast key="signature-messages"></p-toast>
</div>
