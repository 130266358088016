import { ResultSectionApplicationsComponent } from './result-section-applications/result-section-applications.component';
import { ResultSectionPoliciesComponent } from './result-section-policies/result-section-policies.component';
import { ResultSectionDtcComponent } from './result-section-dtc/result-section-dtc.component';
import { ResultSectionQuotesComponent } from './result-section-quotes/result-section-quotes.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '@iapplication2/shared-translate';
import { ResultSectionClientsComponent } from './result-section-clients/result-section-clients.component';
import { HighlightModule } from '../../../ui/src/lib/highlight/highlight.module';
import { ProductStatusTagModule } from '@iapplication2/ui';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    TabViewModule,
    SkeletonModule,
    MenuModule,
    FlexLayoutModule,
    HighlightModule,
    TagModule,
    ProductStatusTagModule,
    TranslateModule.forChild({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    ResultSectionApplicationsComponent,
    ResultSectionPoliciesComponent,
    ResultSectionDtcComponent,
    ResultSectionQuotesComponent,
    ResultSectionClientsComponent,
  ],
  declarations: [
    ResultSectionApplicationsComponent,
    ResultSectionPoliciesComponent,
    ResultSectionDtcComponent,
    ResultSectionQuotesComponent,
    ResultSectionClientsComponent,
  ],
})
export class ResultSectionsModule {}
