<div fxLayout="row wrap" fxLayoutAlign="space-between space-between" class="fieldsSidebarCategory">
  <iapplication2-custom-field-card
    [item]="formBuilderItem"
    fxFlex="50"
    fxFlex.lt-lg="100"
    *ngFor="let formBuilderItem of formBuilderItems"
    (click)="itemSelected(formBuilderItem)"
    action="draw"
  ></iapplication2-custom-field-card>
</div>
<p-confirmPopup></p-confirmPopup>
