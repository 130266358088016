<div
  class="mt-4"
  *ngIf="
    possiblePredefinedEntries?.length ||
    selectedField?.fieldOptionEntries?.entries?.length ||
    availablePossibleOptions?.length ||
    selectedField?.fieldTypeOptions
  "
>
  <div class="details-type-header flex justify-content-between">
    <h4 class="details-type-header-title">Type details</h4>
  </div>
  <div class="details-type-content-property mb-2">
    <div fxLayout="column">
      <div fxLayout="row" *ngIf="possiblePredefinedEntries?.length || selectedField?.fieldOptionEntries?.entries?.length">
        <div class="details-field-content-property-label pr-3 mt-2 text-right" fxLayoutAlign="end start">Entries source:</div>
        <div>
          <p-selectButton
            [options]="entrySourceTypes"
            [(ngModel)]="selectedEntrySourceType"
            optionLabel="label"
            optionValue="value"
            (onChange)="changedEntriesSource()"
          >
          </p-selectButton>
          <div *ngIf="selectedEntrySourceType === 'manual'">
            <div *ngIf="selectedField?.fieldOptionEntries?.entries?.length <= 0">
              <div class="mt-3">
                <em>There are no entries added currently.</em>
              </div>
            </div>
            <div class="my-2">
              <div *ngFor="let manualEntry of selectedField?.fieldOptionEntries?.entries" fxLayoutAlign="start center">
                <button
                  *ngIf="isInEditMode"
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-times"
                  (click)="deleteManualEntry(manualEntry)"
                  class="p-button-rounded p-button-sm p-button-text p-button-danger"
                ></button>
                <div class="my-2 ml-1 mr-3">
                  {{ manualEntry.value }}
                </div>
              </div>
              <!-- <div *ngFor="let manualEntry of tempManualEntries" fxLayoutAlign="start center">
                <button
                  *ngIf="isInEditMode"
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-times"
                  (click)="deleteTempManualEntry(manualEntry)"
                  class="p-button-rounded p-button-sm p-button-text p-button-danger"
                ></button>
                <div class="my-2 ml-1 mr-3">
                  {{ manualEntry.value }}
                </div>
              </div> -->
            </div>
            <div fxLayout="column" *ngIf="isInEditMode">
              <input class="p-inputtext p-component" pInputText placeholder="" [(ngModel)]="inputEntry" type="text" label="Add new entry" />
              <button class="p-button p-button-text my-2" (click)="addEntry()">Add new entry</button>
            </div>
          </div>
          <div *ngIf="selectedEntrySourceType === 'predefined'">
            <div fxLayout="column">
              <div *ngIf="selectedField?.fieldOptionEntries?.predefined">
                <div class="details-field-content-property-content mt-3" fxLayoutAlign="start center">
                  <button
                    *ngIf="isInEditMode"
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-times"
                    (click)="deletePredefinedEntry()"
                    class="p-button-rounded p-button-sm p-button-text p-button-danger"
                  ></button>
                  <div class="my-2 ml-1 mr-3">
                    {{ selectedField?.fieldOptionEntries?.predefined.label }}
                  </div>
                  <i
                    class="pi pi-exclamation-circle mr-2"
                    [pTooltip]="selectedField?.fieldOptionEntries?.predefined.info"
                    style="color: #a3c4f5; font-size: 0.9rem"
                  ></i>
                </div>
                <div *ngIf="isInEditMode" class="my-3">
                  <em>Only one predefined entry can be set to a field.</em>
                </div>
              </div>
            </div>
            <div *ngIf="!selectedField?.fieldOptionEntries?.predefined">
              <div class="mt-3">
                <em>No predefined fields are currently selected.</em>
              </div>
              <div *ngIf="isInEditMode">
                <p-overlayPanel [dismissable]="true" [showCloseIcon]="true" appendTo="body" #entriesSource>
                  <ng-template pTemplate>
                    <div
                      *ngFor="let predefinedEntry of possiblePredefinedEntries"
                      class="my-2 hover--pointer"
                      (click)="selectPredefinedEntry(predefinedEntry)"
                    >
                      <div fxLayoutAlign="start center">
                        <div class="my-2 ml-1 mr-3">
                          {{ predefinedEntry.label }}
                        </div>
                        <i
                          class="pi pi-exclamation-circle mr-2"
                          [pTooltip]="predefinedEntry.info"
                          style="color: #a3c4f5; font-size: 0.9rem"
                        ></i>
                      </div>
                    </div>
                  </ng-template>
                </p-overlayPanel>
                <button
                  pButton
                  pRipple
                  class="p-button p-button-text my-3"
                  label="Select from predefined entries"
                  (click)="entriesSource.toggle($event)"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" *ngIf="availablePossibleOptions?.length || selectedField?.fieldTypeOptions?.length">
        <div class="details-field-content-property-label pr-3 mt-2 text-right" fxLayoutAlign="end start">Options:</div>
        <div>
          <div>
            <div *ngIf="selectedField?.fieldTypeOptions?.length <= 0">
              <div class="mt-2">
                <em>There are no options added currently.</em>
              </div>
            </div>
            <div fxLayout="column">
              <div *ngIf="selectedField?.fieldTypeOptions?.length > 0">
                <div
                  *ngFor="let option of selectedField?.fieldTypeOptions"
                  class="details-field-content-property-content"
                  fxLayoutAlign="start center"
                >
                  <iapplication2-field-details-type-option
                    [field]="selectedField"
                    [isInEditMode]="isInEditMode"
                    [isInFieldBuilder]="isInFieldBuilder"
                    [isInModal]="isInModal"
                    [option]="option"
                    (optionDeleted)="onOptionDeletion($event)"
                  ></iapplication2-field-details-type-option>
                </div>
              </div>
              <div *ngIf="isInEditMode && availablePossibleOptions?.length; else noOptionsRemaining" class="mt-2">
                <p-overlayPanel [dismissable]="true" [showCloseIcon]="true" styleClass="" #options appendTo="body">
                  <ng-template pTemplate>
                    <div
                      *ngFor="let option of availablePossibleOptions"
                      class="my-2 hover--pointer"
                      (click)="addOptionToSelectedOptions(option)"
                    >
                      <iapplication2-field-details-type-option
                        [field]="selectedField"
                        [isInEditMode]="false"
                        [isInFieldBuilder]="isInFieldBuilder"
                        [isInModal]="isInModal"
                        [option]="option"
                      ></iapplication2-field-details-type-option>
                    </div>
                  </ng-template>
                </p-overlayPanel>
                <button pButton pRipple class="p-button p-button-text" label="Add a new option" (click)="options.toggle($event)"></button>
              </div>
              <ng-template #noOptionsRemaining>
                <div *ngIf="isInEditMode">
                  <em>All available options were added to the field.</em>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
