/* eslint-disable @typescript-eslint/no-inferrable-types */
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilderItem, FormListDisplay, ModalType, Page } from '@iapplication2/interfaces';
import {
  CanvasService,
  DynamicTopbarInformationService,
  FormManagementService,
  InteractiveFormBuilderService,
} from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-builder-container-page',
  templateUrl: './builder-container-page.component.html',
  styleUrls: ['./builder-container-page.component.scss'],
})
export class BuilderContainerPageComponent implements OnInit, OnDestroy, AfterViewInit {
  isFieldDialogOpen: boolean = false;
  isDisplayConditionsDialogOpen: boolean = false;
  pageViewState: 'draw' | 'form' | 'preview';
  selectedField: FormBuilderItem;
  settingsDialogOptions: ModalType;
  pageId: number;
  formId: number;
  form: FormListDisplay;
  currentPage: Page;
  private unsubscribe = new Subject<void>();
  state: 'draw' | 'form' | 'preview' = 'draw';

  sidebarAction = ModalType;

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private dynamicTopbarInformationService: DynamicTopbarInformationService,
    private formManagementService: FormManagementService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private canvasService: CanvasService
  ) {}

  ngOnInit(): void {
    this.interactiveFormBuilderService.fieldWasSelected.pipe(takeUntil(this.unsubscribe)).subscribe((field) => {
      if (field) {
        this.selectedField = field;
        this.displayFieldDialog();
      }
    });
    this.interactiveFormBuilderService.showDisplayConditionsDialog.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.showDisplayConditionsDialog();
    });
    this.interactiveFormBuilderService.settingsModalOptionsWereSelected.pipe(takeUntil(this.unsubscribe)).subscribe((options) => {
      this.settingsDialogOptions = options;
    });
    this.interactiveFormBuilderService.fromBuilderPageViewStateChanged.pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
      this.state = state;
      if (state !== 'preview') {
        this.router.navigate(['../' + state], { relativeTo: this.route });
      } else {
        this.router.navigate(['../' + state + '/form/' + this.formId], {
          relativeTo: this.route,
        });
      }
    });
    this.dynamicTopbarInformationService.displayDynamicInformation.next({
      displayInfo: true,
      displayComponent: 'formBuilder',
    });
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params: Params) => {
      this.formId = params['formId'];
      this.pageId = params['pageId'];
      this.formManagementService.currentFormId.next(this.formId);
      this.canvasService.setFormId(this.formId);
      this.formManagementService.currentPageId.next(this.pageId);
      this.formManagementService.getFormById(this.formId);
      this.formManagementService.fetchedForm.pipe(takeUntil(this.unsubscribe)).subscribe((res: FormListDisplay) => {
        this.form = res;
        this.currentPage = this.form.pages.filter((page) => page.id == this.pageId)[0];
        this.canvasService.currentPage = this.currentPage;
      });
    });
  }

  ngAfterViewInit(): void {
    this.pageViewState = <'draw' | 'form'>this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
    this.interactiveFormBuilderService.fromBuilderPageViewState.next(this.pageViewState);
    this.cdr.detectChanges();
  }

  onNavigateToAnotherPage(event): void {
    this.router.navigate(['../../' + event + '/' + this.state], {
      relativeTo: this.route,
    });
  }

  closeFieldDialog(): void {
    this.isFieldDialogOpen = false;
  }

  private displayFieldDialog(): void {
    this.isFieldDialogOpen = true;
  }

  private showDisplayConditionsDialog(): void {
    this.isDisplayConditionsDialogOpen = true;
  }

  closeDisplayConditionsDialog(): void {
    this.isDisplayConditionsDialogOpen = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
    this.dynamicTopbarInformationService.displayDynamicInformation.next({
      displayInfo: false,
      displayComponent: null,
    });
  }
}
