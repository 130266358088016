<div class="quick-quote">
  <div class="page-header flex align-items-center justify-content-between">
    <h1 class="page-header-title">
      {{ 'pages.quickQuotes.singlePage.title' | translate }}
    </h1>
    <div class="flex">
      <form *ngIf="!emailPDFFormSent" class="p-inputgroup" style="width: auto; position: relative" [formGroup]="emailPDFForm">
        <iapplication2-form-field-error
          style="position: absolute; top: -20px"
          *ngIf="emailPDFForm.get('email').touched || emailPDFFormSubmissionTriggered"
          [control]="emailPDFForm.get('email')"
        ></iapplication2-form-field-error>
        <div class="flex">
          <input
            type="text"
            pInputText
            placeholder="Email address"
            class="p-inputtext p-component"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            formControlName="email"
          />
          <button
            type="button"
            pButton
            pRipple
            label="Email PDF"
            [disabled]="!selectedProducts.length || !emailPDFForm.get('email').valid"
            pTooltip="Email PDF application to provided email address"
            style="border-top-left-radius: 0; border-bottom-left-radius: 0"
            [showDelay]="300"
            tooltipPosition="bottom"
            (click)="confirmEmailSending($event)"
          ></button>
          <p-confirmPopup appendTo="body"></p-confirmPopup>
        </div>
      </form>
      <div *ngIf="emailPDFFormSent" class="flex align-items-center mr-2">Email sent ✓</div>
      <button
        class="ml-2"
        type="button"
        pButton
        pRipple
        label="Start application"
        pTooltip="Start application with selected products"
        [showDelay]="300"
        tooltipPosition="bottom"
        [disabled]="!selectedProducts.length"
        (click)="startApplication()"
      ></button>
    </div>
  </div>
  <div class="grid">
    <div class="col-4">
      <iapplication2-quick-quote-form
        [quickQuoteForm]="quickQuoteForm"
        [quickQuoteId]="quickQuoteId"
        (formSubmisionTriggered)="compare()"
      ></iapplication2-quick-quote-form>
    </div>
    <div class="col-8">
      <div class="empty-state" *ngIf="!compareTriggered && !loading">
        <div class="empty-state-text">
          <strong>{{ 'pages.quickQuotes.singlePage.productsTable.emptyStateMessage' | translate }}</strong>
        </div>
        <img class="empty-state-icon" src="/assets/custom/icons/product-result-empty-state.svg" />
      </div>
      <div *ngIf="compareTriggered && loading" class="card">
        <div class="custom-skeleton p-4">
          <ul class="m-0 p-0">
            <li class="my-0">
              <div class="flex">
                <p-skeleton class="mt-2 mr-3" shape="circle" size="1rem"></p-skeleton>
                <p-skeleton class="ml-3" size="2rem"></p-skeleton>
                <div class="ml-5 mr-6 mt-2" style="flex: 1">
                  <p-skeleton height="1rem" width="90%"></p-skeleton>
                </div>
                <div class="ml-6 mr-6" style="flex: 1">
                  <p-skeleton height="2rem" width="80%"></p-skeleton>
                </div>
                <div class="pl-6 ml-6 mr-6 mt-2" style="flex: 1">
                  <p-skeleton height="1rem" width="40%"></p-skeleton>
                </div>
                <div class="mr-4" style="flex: 1">
                  <p-skeleton class="flex justify-content-end" height="2rem" width="50%"></p-skeleton>
                </div>
                <p-skeleton class="ml-4" size="2rem"></p-skeleton>
                <p-skeleton class="ml-5" size="2rem"></p-skeleton>
              </div>
            </li>
            <li class="mt-6">
              <div class="flex">
                <p-skeleton class="mt-2 mr-3" shape="circle" size="1rem"></p-skeleton>
                <p-skeleton class="ml-3" size="2rem"></p-skeleton>
                <div class="ml-5 mr-6 mt-2" style="flex: 1">
                  <p-skeleton height="1rem" width="90%"></p-skeleton>
                </div>
                <div class="ml-6 mr-6" style="flex: 1">
                  <p-skeleton height="2rem" width="80%"></p-skeleton>
                </div>
                <div class="pl-6 ml-6 mr-6 mt-2" style="flex: 1">
                  <p-skeleton height="1rem" width="40%"></p-skeleton>
                </div>
                <div class="mr-4" style="flex: 1">
                  <p-skeleton class="flex justify-content-end" height="2rem" width="50%"></p-skeleton>
                </div>
                <p-skeleton class="ml-4" size="2rem"></p-skeleton>
                <p-skeleton class="ml-5" size="2rem"></p-skeleton>
              </div>
            </li>
            <li class="mt-6">
              <div class="flex">
                <p-skeleton class="mt-2 mr-3" shape="circle" size="1rem"></p-skeleton>
                <p-skeleton class="ml-3" size="2rem"></p-skeleton>
                <div class="ml-5 mr-6 mt-2" style="flex: 1">
                  <p-skeleton height="1rem" width="90%"></p-skeleton>
                </div>
                <div class="ml-6 mr-6" style="flex: 1">
                  <p-skeleton height="2rem" width="80%"></p-skeleton>
                </div>
                <div class="pl-6 ml-6 mr-6 mt-2" style="flex: 1">
                  <p-skeleton height="1rem" width="40%"></p-skeleton>
                </div>
                <div class="mr-4" style="flex: 1">
                  <p-skeleton class="flex justify-content-end" height="2rem" width="50%"></p-skeleton>
                </div>
                <p-skeleton class="ml-4" size="2rem"></p-skeleton>
                <p-skeleton class="ml-5" size="2rem"></p-skeleton>
              </div>
            </li>
            <li class="mt-6 mb-2">
              <div class="flex">
                <p-skeleton class="mt-2 mr-3" shape="circle" size="1rem"></p-skeleton>
                <p-skeleton class="ml-3" size="2rem"></p-skeleton>
                <div class="ml-5 mr-6 mt-2" style="flex: 1">
                  <p-skeleton height="1rem" width="90%"></p-skeleton>
                </div>
                <div class="ml-6 mr-6" style="flex: 1">
                  <p-skeleton height="2rem" width="80%"></p-skeleton>
                </div>
                <div class="pl-6 ml-6 mr-6 mt-2" style="flex: 1">
                  <p-skeleton height="1rem" width="40%"></p-skeleton>
                </div>
                <div class="mr-4" style="flex: 1">
                  <p-skeleton class="flex justify-content-end" height="2rem" width="50%"></p-skeleton>
                </div>
                <p-skeleton class="ml-4" size="2rem"></p-skeleton>
                <p-skeleton class="ml-5" size="2rem"></p-skeleton>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <iapplication2-quick-quote-products
        *ngIf="compareTriggered && !loading"
        (productSelected)="productSelected($event)"
        [quickQuoteData]="quickQuoteForm"
      ></iapplication2-quick-quote-products>
    </div>
  </div>
</div>
<p-toast key="emailPDFForm-message"></p-toast>
