/* eslint-disable @typescript-eslint/no-inferrable-types */
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilderField, FormSection, FormArea, FormAreaWidthOptions, FormAreaId } from '@iapplication2/interfaces';
import { CanvasService, InteractiveFormBuilderService, ProgressSpinnerService } from '@iapplication2/services';
import { MenuItem } from 'primeng/api';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { UnassignedFieldsAreaComponent } from './form-builder-area/unassigned-fields-area/unassigned-fields-area.component';

@Component({
  selector: 'iapplication2-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
})
export class FormBuilderComponent implements OnInit, OnDestroy {
  formAreas: FormArea[] = [];

  formSectionsList: FormSection[];

  selectedFormSection: FormSection;

  items: MenuItem[];

  showWidthOptions: boolean = false;

  connectedTo = [];

  unassignedFields: FormBuilderField[];

  currentlySelectedCanvasField: FormBuilderField;

  unsubscribe: Subject<void> = new Subject();

  selectedFormField: FormBuilderField;

  sortableJsOptions = {};

  @Input() formId: number;

  @ViewChild('unassignedFieldsArea') unassignedFieldsArea: UnassignedFieldsAreaComponent;

  getFieldsFromSectiomDebounced = _.debounce(
    (section: number | FormAreaId.UNASSIGNED | null | undefined) => this.getFieldsFromSectiom(section),
    500
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private canvasService: CanvasService,
    private progressSpinnerService: ProgressSpinnerService
  ) {
    this.sortableJsOptions = {
      onUpdate: () => {
        this.formAreas.forEach((area, index) => (area.position = index + 1));
        this.interactiveFormBuilderService.updateSectionAreas(this.formAreas).pipe(takeUntil(this.unsubscribe)).subscribe();
      },
      handle: '.sub-section-handle',
    };
  }

  ngOnInit(): void {
    this.checkIfFormAreasUpdated();
    this.getFormSectionsList();
    this.createLinkBetweenTheUnassignedFieldsLists();
    this.setCanvasObjectOnSelection();
    this.getListOfUnassignedFieldsByFormId();
  }

  private getFormSectionsList() {
    this.interactiveFormBuilderService
      .getFormSectionsListByFormId(this.formId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FormSection[]) => {
        this.formSectionsList = res;
        this.formSectionsList.sort((a, b) => a.sectionFormPosition - b.sectionFormPosition);
        this.interactiveFormBuilderService.selectedFormId = new ReplaySubject(1);
        this.interactiveFormBuilderService.selectedFormId.next(this.formId);
        this.interactiveFormBuilderService.selectedFormId.complete();
      });
  }

  private createLinkBetweenTheUnassignedFieldsLists() {
    this.unassignedFields = this.interactiveFormBuilderService._unassignedFields;
  }

  private setCanvasObjectOnSelection() {
    this.canvasService.canvasObjectWasSelected.pipe(takeUntil(this.unsubscribe)).subscribe((object) => {
      this.findSelectedCanvasFieldInFormFields(object);
    });
  }

  private checkIfFormAreasUpdated() {
    this.interactiveFormBuilderService.$formAreasUpdated
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: number | FormAreaId.UNASSIGNED | null | undefined) => {
        this.canvasService.getAllFormFieldsDebounced();
        this.getFieldsFromSectiomDebounced(res);
      });
  }

  private getFieldsFromSectiom(id: number | FormAreaId.UNASSIGNED | null | undefined) {
    switch (true) {
      case id === FormAreaId.UNASSIGNED:
        this.getListOfUnassignedFieldsByFormId();
        break;
      case id === null:
        this.getListOfUnassignedFieldsByFormId();
        break;
      case id === undefined:
        this.getListOfUnassignedFieldsByFormId();
        break;
      default:
        this.loadFormAreasBySection(this.selectedFormSection);
        break;
    }
  }

  private getListOfUnassignedFieldsByFormId() {
    this.interactiveFormBuilderService
      .getUnassignedFieldsByFormId(this.formId.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FormBuilderField[]) => {
        this.unassignedFields = res;
        this.interactiveFormBuilderService._unassignedFields = this.unassignedFields;
      });
  }

  public loadFormAreasBySection(section: FormSection): void {
    this.selectedFormSection = section;
    this.interactiveFormBuilderService
      .getFormAreasBySectionId(section.id.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FormArea[]) => {
        this.formAreas = res;
        this.prepareFormAreas(true);
      });
    this.connectedTo.push(FormAreaId.UNASSIGNED);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  private prepareFormAreas(isInitialLoad: boolean) {
    this.formAreas.sort((a, b) => a.position - b.position);
    this.formAreas.forEach((formArea) => {
      if (isInitialLoad) {
        this.connectedTo.push(formArea.id.toString());
      }
      this.prepareFormArea(formArea);
    });
  }

  private prepareFormArea(area: FormArea) {
    area.fields?.sort((a, b) => a.formAreaPosition - b.formAreaPosition);
  }

  drop(event): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    if (event?.previousContainer === event?.container) {
      this.moveInSameArea(event);
    } else {
      this.moveInAnotherArea(event);
    }
  }

  private moveInSameArea(event) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    const areaId = event.container.id === FormAreaId.UNASSIGNED ? null : parseInt(event.container.id);
    const listOfFields = this.interactiveFormBuilderService.convertListOfItemsToListOfFields(event.container.data);
    listOfFields.forEach((field, index) => {
      field.formAreaPosition = index;
      field.formAreaId = areaId;
    });
    this.interactiveFormBuilderService
      .updateListOfFields(listOfFields)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        complete: () => {
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
          this.interactiveFormBuilderService.$formAreasUpdated.next(event.previousContainer.id);
        },
      });
  }

  private moveInAnotherArea(event) {
    transferArrayItem(event?.previousContainer?.data, event?.container?.data, event?.previousIndex, event?.currentIndex);

    const listOfFieldsFromPreviousContainer = this.interactiveFormBuilderService.convertListOfItemsToListOfFields(
      event.previousContainer?.data
    );
    const listOfFieldsFromCurrent = this.interactiveFormBuilderService.convertListOfItemsToListOfFields(event.container?.data);

    listOfFieldsFromPreviousContainer.forEach((field, index) => {
      field.formAreaPosition = index;
      const areaId = parseInt(event.previousContainer.id);
      field.formAreaId = areaId === undefined ? null : areaId;
    });
    listOfFieldsFromCurrent.forEach((field, index) => {
      field.formAreaPosition = index;
      const areaId = parseInt(event.container.id);
      field.formAreaId = areaId === undefined ? null : areaId;
    });

    const listOfFields = [...listOfFieldsFromPreviousContainer, ...listOfFieldsFromCurrent];
    this.interactiveFormBuilderService
      .updateListOfFields(listOfFields)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        complete: () => {
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
          this.interactiveFormBuilderService.$formAreasUpdated.next(event.previousContainer.id);
          this.interactiveFormBuilderService.$formAreasUpdated.next(event.container.id);
        },
      });
  }

  addArea(width: FormAreaWidthOptions): void {
    const id = this.generateIdForNewformArea(1);
    const area = {
      id: id,
      title: '',
      width: width,
      position: this.formAreas?.length + 1,
      formSectionId: this.selectedFormSection.id,
      fields: [],
    };
    this.formAreas.push(area);
    const saveAreas = [];
    saveAreas.push(_.cloneDeep(area));
    saveAreas[0].id = null;
    this.interactiveFormBuilderService
      .createAreas(saveAreas)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: FormArea[]) => {
        const newArea = this.formAreas.find((area) => area.id === id);
        newArea.id = data?.[0]?.id;
        this.showWidthOptions = false;
        this.connectedTo.push(newArea.id.toString());
        this.cdr.detectChanges();
      });
  }

  public deleteArea(formArea: FormArea): void {
    this.interactiveFormBuilderService
      .deleteArea(formArea.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.loadFormAreasBySection(this.selectedFormSection);
      });
  }

  private generateIdForNewformArea(increment: number): number {
    return this.formAreas ? this.formAreas?.length + increment : 1;
  }

  private findSelectedCanvasFieldInFormFields(selectedCanvasField: FormBuilderField): void {
    if (selectedCanvasField) {
      const fieldIdFromObject = selectedCanvasField?.id;
      let fieldArray = [];
      this.formAreas?.forEach((formArea) => {
        fieldArray = fieldArray.concat(formArea.fields);
      });
      fieldArray = fieldArray.concat(this.unassignedFields);
      const selectedField = fieldArray.filter((field: FormBuilderField) => field.id === fieldIdFromObject);
      this.currentlySelectedCanvasField = selectedField[0];
      this.selectedFormField = selectedField[0];
    } else {
      this.currentlySelectedCanvasField = undefined;
      this.selectedFormField = undefined;
    }
  }
}
