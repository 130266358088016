<p-card fxFlex class="pt-3 pr-3">
  <h5>{{ 'client_selection.questionnaire' | translate }}</h5>
  <div *ngFor="let question of questions" class="pt-3">
    <div>
      <b>{{ question.order }} {{ question.title }}</b>
    </div>
    <div *ngFor="let q of question.questions" fxLayout="row" class="pt-1">
      <div>{{ q.order }} {{ q.title }}</div>
      <div fxLayoutAlign="end" fxFlex class="pl-1">
        <div class="p-field-radiobutton">
          <p-radioButton name="groupname" value="true" [(ngModel)]="q.response" [disabled]="true" inputId="{{ q.id }}"></p-radioButton>
          <label for="{{ q.id }}" class="pr-2">{{ 'applicationProcess.yes' | translate }}</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton name="groupname" value="false" [(ngModel)]="q.response" [disabled]="true" inputId="{{ q.id }}"></p-radioButton>
          <label for="{{ q.id }}">{{ 'applicationProcess.no' | translate }}</label>
        </div>
      </div>
    </div>
    <div *ngIf="!question.questions">
      <div fxLayoutAlign="end" fxFlex>
        <div class="p-field-radiobutton">
          <p-radioButton
            name="groupname"
            value="true"
            [(ngModel)]="question.response"
            [disabled]="true"
            inputId="{{ question.id }}"
          ></p-radioButton>
          <label for="{{ question.id }}" class="pr-2">{{ 'applicationProcess.yes' | translate }}</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            name="groupname"
            value="false"
            [(ngModel)]="question.response"
            [disabled]="true"
            inputId="{{ question.id }}"
          ></p-radioButton>
          <label for="{{ question.id }}">{{ 'applicationProcess.no' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
</p-card>
