export interface ReflexPayload {
    application_id: number;
    product_id: number;
    status?: ReflexHistoryStatusOptions;
    decision: ReflexDecision;
    term: number;
    risk: number;
    smoker: string;
    smokerFE: string;
    coverage: number;
    questions: any; //To be discuss
    serverPayload: any; //To be discuss
    maxCoverage: number;
    forcedStop: boolean;
    last_question: ReflexQuestion;
    product_code: number;
    product_version: number;
}

export interface ReflexDecision {
    status: string;
    reason: string;
}

export interface ReflexQuestion {
    answer: string;
    question: string;
}

export interface ReflexServerPayload {
    id?: number;
    serverPayload: any;
}

export enum ReflexHistoryStatusOptions {
    CREATED = 'Created',
    UPDATED = 'Updated',
    DELETED = 'Deleted'
}