<div *ngIf="todayNotifications.length > 0" class="mb-3">
  <h5 class="mb-1">Today</h5>
  <iapplication2-notification-card *ngFor="let notification of todayNotifications" [notification]="notification">
  </iapplication2-notification-card>
</div>
<div *ngIf="yesterdayNotifications.length > 0" class="mb-3">
  <h5 class="mb-1">Yesterday</h5>
  <iapplication2-notification-card *ngFor="let notification of yesterdayNotifications" [notification]="notification">
  </iapplication2-notification-card>
</div>
<div *ngIf="olderNotifications.length > 0" class="mb-3">
  <h5 class="mb-1">Older</h5>
  <iapplication2-notification-card *ngFor="let notification of olderNotifications" [notification]="notification">
  </iapplication2-notification-card>
</div>
<a [routerLink]="['/notifications']">See all notifications <i class="pi pi-arrow-right" style="font-size: 0.8rem"></i></a>
