<div class="mt-4">
  <div class="details-type-header flex justify-content-between">
    <h4 class="details-type-header-title">
      {{ 'admin.interactiveFormBuilder.fieldDetails.fieldDetailsStaticFieldLink.header.title' | translate }}
    </h4>
  </div>
  <div class="details-type-content-property mb-2" fxLayout="row">
    <div class="details-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">
      {{ 'admin.interactiveFormBuilder.fieldDetails.fieldDetailsStaticFieldLink.content.properties.staticFieldLink.label' | translate }}:
    </div>
    <div class="details-field-content-property-content" fxLayoutAlign="start center">
      <p-treeSelect
        *ngIf="isInEditMode; else notInEditMode"
        optionLabel="name"
        id="staticFieldsToLinkWithSelection"
        [(ngModel)]="selectedNode"
        [options]="staticFieldsTreeSelectObjects"
        [placeholder]="
          'admin.interactiveFormBuilder.fieldDetails.fieldDetailsStaticFieldLink.content.properties.staticFieldLink.treeSelect.placeholder'
            | translate
        "
        appendTo="body"
        (onNodeSelect)="nodeSelected()"
        selectionMode="single"
        [filter]="true"
        filterBy="label"
        [filterPlaceholder]="
          'admin.interactiveFormBuilder.fieldDetails.fieldDetailsStaticFieldLink.content.properties.staticFieldLink.treeSelect.filterPlaceholder'
            | translate
        "
        [resetFilterOnHide]="true"
        [showClear]="true"
        (onClear)="selectedNodeCleared()"
      >
      </p-treeSelect>
      <ng-template #notInEditMode>
        {{
          selectedField?.staticValueLink?.id
            ? selectedField?.staticValueLink.name
            : ('admin.interactiveFormBuilder.fieldDetails.fieldDetailsStaticFieldLink.content.properties.staticFieldLink.empty' | translate)
        }}
      </ng-template>
    </div>
  </div>
</div>
