import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  Advisor,
  Application,
  ApplicationProduct,
  ApplicationStatusOptions,
  IApplicationDigitalSignature,
  MessagesModel,
  Premium,
  Severity,
  SmokerStatus,
} from '@iapplication2/interfaces';
import {
  AdvisorsService,
  ApplicationsManagementService,
  ApplicationsProcessService,
  ApplicationSummaryService,
  ESignatureService,
  DatesService,
} from '@iapplication2/services';
import { FormHelper } from '@iapplication2/superclass';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ = require('lodash');
import { StringValidator } from '@iapplication2/validators';
import * as moment from 'moment';
import Decimal from 'decimal.js';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iapplication2-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss'],
})
export class ApplicationPageComponent extends FormHelper implements OnInit, OnDestroy {
  applicationId: number;
  previousApplication: Application;
  clientForm: FormGroup;
  formSubmissionTriggered = false;
  advisor: Advisor;
  isApplicationEditable = false;
  ratesForSelectedProducts: any[];
  digitalSignatures: IApplicationDigitalSignature[];

  ApplicationStatusOptions = ApplicationStatusOptions;
  scheduleApplicationPremiumReceived = false;
  premiums: Premium[];
  productsAvailable = false;
  unsubscribe: Subject<unknown> = new Subject();

  translatedSaveMessages: MessagesModel = {
    success: {
      summary: '',
      detail: '',
    },
    error: {
      summary: '',
      detail: '',
    },
  };

  constructor(
    public eSignatureService: ESignatureService,
    private route: ActivatedRoute,
    private applicationsManagementService: ApplicationsManagementService,
    private advisorsService: AdvisorsService,
    private applicationSummaryService: ApplicationSummaryService,
    public applicationsProcessService: ApplicationsProcessService,
    private datesService: DatesService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.createClientForm();
    this.getApplication();
    this.setTranslatedStrings();
  }

  getApplication() {
    this.applicationsManagementService
      .getApplicationById(this.applicationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.applicationsProcessService.setCurrentApplication(res as Application);
        this.productsAvailable = !!(res as Application)?.products;
        this.scheduleApplication();
        this.setApplicationEditable();
        this.loadDigitalSignatures(this.applicationId);
        this.loadCurrentAdvisor(this.applicationsProcessService.currentApplication.advisorId);
        this.fillClientForm();
      });
  }

  onApplicationStatusChanged(applicationStatusChangedEvent: boolean) {
    if (applicationStatusChangedEvent) {
      this.getApplication();
    }
  }

  setApplicationEditable() {
    this.isApplicationEditable = this.applicationsProcessService.currentApplication?.status?.name === ApplicationStatusOptions.ACTIVE;
  }

  fillClientForm() {
    this.clientForm.patchValue({
      firstName: this.applicationsProcessService.currentApplication?.client?.firstName,
      middleName: this.applicationsProcessService.currentApplication?.client?.middleName,
      lastName: this.applicationsProcessService.currentApplication?.client?.lastName,
      email: this.applicationsProcessService.currentApplication?.client?.email,
      phoneNumber: this.applicationsProcessService.currentApplication?.client?.phoneNumber,
      countryOfBirth: this.applicationsProcessService.currentApplication?.client?.countryOfBirth,
      address: this.applicationsProcessService.currentApplication?.client?.address,
      province: this.applicationsProcessService.currentApplication?.client?.province,
      city: this.applicationsProcessService.currentApplication?.client?.city,
      postalCode: this.applicationsProcessService.currentApplication?.client?.postalCode,
      note: this.applicationsProcessService.currentApplication?.client?.note,
      language: this.applicationsProcessService.currentApplication?.language.name,
      smokerStatus:
        this.applicationsProcessService.currentApplication?.client.smokerStatus === 'no'
          ? SmokerStatus.NON_SMOKER
          : this.applicationsProcessService.currentApplication?.client.smokerStatus === 'yes'
          ? SmokerStatus.SMOKER
          : null,
      birthDate: new Date(this.applicationsProcessService.currentApplication?.client.birthDate),
    });
  }

  loadCurrentAdvisor(advisorId: number) {
    this.advisorsService
      .getAdvisorByAdvisorId(advisorId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.advisor = res as Advisor;
      });
  }

  loadDigitalSignatures(applicationId: number) {
    this.eSignatureService
      .getApplicationDigitalSignatures(applicationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((signatures: IApplicationDigitalSignature[]) => {
        this.digitalSignatures = signatures;
      });
  }

  createClientForm() {
    this.clientForm = new FormGroup({
      firstName: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      middleName: new FormControl(null, [Validators.maxLength(50)]),
      lastName: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      language: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [
        StringValidator.requiredNoOnlySpaces(),
        Validators.required,
        Validators.email,
        Validators.maxLength(100),
      ]),
      birthDate: new FormControl(null, [Validators.required]),
      smokerStatus: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      countryOfBirth: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      province: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      postalCode: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      note: new FormControl(null, [Validators.maxLength(500)]),
    });
  }

  scheduleApplication() {
    this.applicationSummaryService.getPremiumsForScheduledApplication(this.applicationId).subscribe({
      next: (res: Premium[]) => {
        if (res) {
          this.ratesForSelectedProducts = res;
          this.getTotalPremiums(res);
        }
      },
      error: () => {
        this.scheduleApplicationPremiumReceived = false;
      },
    });
  }

  getTotalPremiums(premiums) {
    if (premiums.length) {
      const premiumAges = _.cloneDeep(premiums.filter((premium) => premium.premiumAges?.length)[0]?.premiumAges);
      if (premiumAges) {
        this.scheduleApplicationPremiumReceived = true;
        premiumAges.forEach((premiumAge) => {
          premiumAge.premium = 0;
        });
        premiums.forEach((premium) => {
          premium.premiumAges.forEach((premiumAge, index) => {
            premiumAges[index].premium = premiumAges[index].premium + premiumAge.premium;
          });
        });
        this.premiums = premiumAges;
      }
    }
  }

  onScheduledDateSelected(scheduledDate: Date) {
    this.previousApplication = _.cloneDeep(this.applicationsProcessService.currentApplication);
    const scheduledDay = moment(scheduledDate);
    this.ratesForSelectedProducts.forEach((product) => {
      product.premiumAges.forEach((premiumAge) => {
        if (moment(premiumAge.date).isSame(scheduledDay, 'day')) {
          const updatedProducts = this.applicationsProcessService.currentApplication.products.map((serviceProduct) => {
            if (serviceProduct.id === product.productId) {
              let roundedPremium;
              if (premiumAge.premium.toString().slice(premiumAge.premium.toString().length - 5) === '99999') {
                roundedPremium = new Decimal(new Decimal(premiumAge.premium).toFixed(3)).toFixed(2);
              } else {
                roundedPremium = new Decimal(premiumAge.premium).toFixed(2);
              }
              serviceProduct.monthlyPremium = roundedPremium;
              serviceProduct.yearlyPremium = (roundedPremium * 12).toFixed(2);
            }
            return serviceProduct;
          });
          const applicationToUpdate = _.cloneDeep(this.applicationsProcessService.currentApplication);
          applicationToUpdate.products = updatedProducts;
          this.applicationsProcessService.setCurrentApplication(applicationToUpdate);
        }
      });
    });

    const currentApplicationToUpdate = _.cloneDeep(this.applicationsProcessService.currentApplication);
    currentApplicationToUpdate.scheduleDate = scheduledDate;
    // ↓ Fallback in case the effective date is not set in BE -- should be ignored in BE in the happyflow
    currentApplicationToUpdate.effectiveDate = scheduledDate;
    this.applicationsProcessService.setCurrentApplication(currentApplicationToUpdate);

    this.rescheduleApplication();
  }

  rescheduleApplication() {
    const scheduledDate: string = this.datesService.removeTimeFromDate(this.applicationsProcessService.currentApplication.scheduleDate);
    const products: Pick<
      ApplicationProduct,
      | ApplicationProduct['id']
      | ApplicationProduct['productId']
      | ApplicationProduct['monthlyPremium']
      | ApplicationProduct['yearlyPremium']
    >[] = this.applicationsProcessService.currentApplication.products.map((product: ApplicationProduct) => {
      return {
        productId: product.productId,
        id: product.id,
        monthlyPremium: product.monthlyPremium,
        yearlyPremium: product.yearlyPremium,
      };
    });
    this.applicationsProcessService
      .rescheduleApplication(this.applicationId, scheduledDate, products)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.showApplicationSavedSuccessMessage();
          this.setDatesOnApplicationAfterReschedule(res.application);
        },
        error: () => {
          this.applicationsProcessService.setCurrentApplication(this.previousApplication);
          this.previousApplication = null;
          this.showApplicationSavedErrorMessage();
        },
      });
  }

  setDatesOnApplicationAfterReschedule(application: any) {
    if (application) {
      const applicationToUpdate = this.applicationsProcessService.currentApplication;
      applicationToUpdate.date = application.date;
      applicationToUpdate.scheduleDate = application.scheduleDate;
      applicationToUpdate.effectiveDate = application.effectiveDate;
      this.applicationsProcessService.setCurrentApplication(applicationToUpdate);
    }
  }

  showApplicationSavedSuccessMessage() {
    this.messageService.clear();
    this.messageService.add({
      key: 'reschedule-application-message',
      severity: Severity.SUCCESS,
      summary: this.translatedSaveMessages.success?.summary,
      detail: this.translatedSaveMessages.success?.detail,
    });
  }

  showApplicationSavedErrorMessage() {
    this.messageService.clear();
    this.messageService.add({
      key: 'reschedule-application-message',
      severity: Severity.ERROR,
      summary: this.translatedSaveMessages.error?.summary,
      detail: this.translatedSaveMessages.error?.detail,
    });
  }

  setTranslatedStrings() {
    this.getTranslationsForSaveMessages();
  }

  getTranslationsForSaveMessages() {
    this.translate
      .get('applicationProcess.rescheduleApplicationMessages')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.translatedSaveMessages.success.summary = res.success?.summary;
        this.translatedSaveMessages.success.detail = res.success?.detail;
        this.translatedSaveMessages.error.summary = res.error?.summary;
        this.translatedSaveMessages.error.detail = res.error?.detail;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
