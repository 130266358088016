<div [formGroup]="parentFormGroup" class="mb-3" fxLayout="column">
  <div class="flex">
    <iapplication2-required [required]="formFieldControl.errors?.required && !field?.table"></iapplication2-required>
    <label *ngIf="!hideLabel">{{
      (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key') | translate: { Default: field?.options?.customFieldLabel }
    }}</label>
  </div>
  <div class="mt-1 flex flex-row">
    <p-calendar
      #calendar
      [formControlName]="field?.id"
      [placeholder]="
        (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
          | translate: { Default: field?.options?.customFieldLabel }
      "
      [showIcon]="true"
      [monthNavigator]="true"
      [yearNavigator]="true"
      dateFormat="dd/mm/yy"
      [yearRange]="'1900:' + currentYear"
      [readonly]="isReadonly"
      [class.isReadonlyInput]="isReadonly"
      [tabindex]="tabIndex"
      [minDate]="minimumDate"
      [maxDate]="maximumDate"
      iapplication2DateMask
      dataType="string"
    ></p-calendar>
    <iapplication2-required
      class="flex ai-center"
      style="min-width: 20px"
      [rowRequired]="field?.requiredByTableRow"
    ></iapplication2-required>
  </div>
</div>
