/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @angular-eslint/no-output-rename */
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[iapplication2ClickOutside]',
})
export class ClickOutsideDirective {
  @Input() includeCanvasInClickedOutsideList: boolean = true;
  @Input() elementData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target']) onMouseEnter(
    targetElement
  ) {
    const clickedOnCanvas = (targetElement as HTMLElement).classList.contains(
      'upper-canvas'
    );
    const clickedInside = this._elementRef.nativeElement.contains(
      targetElement
    );
    if (
      !clickedInside &&
      (!clickedOnCanvas ||
        (clickedOnCanvas && this.includeCanvasInClickedOutsideList))
    ) {
      this.clickOutside.emit(this.elementData);
    }
  }
}
