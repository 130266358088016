import { Directive, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBuilderField } from '@iapplication2/interfaces';

@Directive()
export abstract class BaseFieldDataDirective {
  @Input() field: FormBuilderField;
  @Input() formFieldControl: FormControl;
  @Input() parentFormGroup: FormGroup;
  @Input() isPreviewMode: boolean;
  @Input() hideLabel?: boolean;
  @Input() requiredByTableRow?: boolean;
  @Input() isReadonly: boolean;
  @Input() tabIndex: number;
}
