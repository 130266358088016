import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { register } from '../../../../registryComponent';
import { BaseFieldDataDirective } from '../../../../baseFieldData.directive';
import { ApplicationsProcessService, UtilsService } from '@iapplication2/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesEnum } from '@iapplication2/interfaces';

@register('dropdown')
@Component({
  selector: 'iapplication2-dropdown-field-display',
  templateUrl: './dropdown-field-display.component.html',
  styleUrls: ['./dropdown-field-display.component.scss'],
})
export class DropdownFieldDisplayComponent extends BaseFieldDataDirective implements OnInit, OnDestroy {
  @ViewChild('dropdown') dropdown;
  selectedLanguage: string;
  options: any;
  isSearchable: boolean;
  private unsubscribe = new Subject<void>();
  isViewApplication: boolean;

  constructor(
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private applicationsProcessService: ApplicationsProcessService
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkViewApplicationDisplay();
    this.selectedLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res) => {
        this.selectedLanguage = res.lang;
        this.getOptions();
      },
    });
    this.getOptions();
    this.isSearchable = this.field.fieldTypeOptions.some((option) => option.id === 2);
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  getOptions() {
    if (this.field.fieldOptionEntries.predefined.id) {
      this.setOptionsByPredefinedOptionId(this.field.fieldOptionEntries.predefined.id);
    } else {
      this.options = this.field?.fieldOptionEntries?.entries.map((entry) => {
        return {
          value: entry.value,
        };
      });
      if (Number(this.options?.[0]?.value)) {
        this.options = this.options.sort((a, b) => Number(a.value) - Number(b.value));
      }
    }
  }

  setOptionsByPredefinedOptionId(id: number) {
    switch (true) {
      case id === 1:
        this.setCountriesByPredefinedOptionId();
        break;
      case id === 2 || id === 4:
        this.setProvincesByPredefinedOptionId(id);
        break;
      case id === 5 || id === 6:
        this.setStatesByPredefinedOptionId(id);
        break;
    }
  }

  setTeritoryByLanguage(listOfTeritories: any) {
    const existingValue = this.formFieldControl.value;
    const foundTeritory = listOfTeritories.find((teritory: any) => {
      let foundTeritory: any;

      Object.keys(teritory).forEach((key) => {
        if (key.includes('name') && teritory[key] === existingValue) {
          foundTeritory = teritory;
        }
      });
      return !!foundTeritory;
    });

    if (foundTeritory) {
      const name = this.getNameKeyBySelectedLanguage();
      const newValue = foundTeritory[name];

      if (newValue) {
        this.formFieldControl.setValue(newValue);
      }
    }
  }

  getNameKeyBySelectedLanguage(): string {
    let name: string;
    switch (this.selectedLanguage) {
      case LanguagesEnum.EN:
        name = 'name';
        break;
      default:
        name = `name_${this.selectedLanguage}`;
    }
    return name;
  }

  setCountriesByPredefinedOptionId() {
    this.utilsService
      .getCountriesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.options = res?.data?.map((country) => {
            const name = this.getNameKeyBySelectedLanguage();
            return {
              value: country[name],
            };
          });
          this.options.sort((country1, country2) => country1.value.localeCompare(country2.value));

          this.setTeritoryByLanguage(res?.data);
        },
      });
  }

  setProvincesByPredefinedOptionId(id: number) {
    this.utilsService
      .getProvincesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          switch (true) {
            case id === 2:
              this.options = res?.map((province) => {
                const name = this.getNameKeyBySelectedLanguage();
                return {
                  value: province[name],
                };
              });
              break;
            case id === 4:
              this.options = res?.map((province) => {
                return {
                  value: province.prov_short,
                };
              });
          }
          this.setTeritoryByLanguage(res);
        },
      });
  }

  setStatesByPredefinedOptionId(id: number) {
    this.utilsService
      .getStatesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          switch (true) {
            case id === 5:
              this.options = res?.data?.map((state) => {
                const name = this.getNameKeyBySelectedLanguage();
                return {
                  value: state[name],
                };
              });
              break;
            case id === 6:
              this.options = res?.data?.map((state) => {
                return {
                  value: state.code,
                };
              });
          }
          this.setTeritoryByLanguage(res?.data);
        },
      });
  }

  setEmptyValueOnControl() {
    this.parentFormGroup.controls[this.field.id].setValue('', { emitEvent: false });
    this.dropdown.value = null;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
