import { Component, ElementRef, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessagesModel, Severity } from '@iapplication2/interfaces';
import { ESignatureService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad';
import { MessageService } from 'primeng/api';
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  providers: [MessageService],
})
export class SignatureComponent implements OnInit, AfterViewInit {
  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  signatureImg: string;
  signatureGuid: string;
  signatureInfo: any;
  showSuccessPage = false;

  @ViewChild('container') container: ElementRef;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  alreadySubmittedBEMessage = 'Application is already signed';
  expiredApplicationBEMessage = 'The signature expired';
  isSignatureAlreadySubmitted = false;
  isApplicationExpired = false;

  signaturePadOptions = {
    minWidth: 2,
    canvasWidth: 700,
    canvasHeight: 300,
  };

  translatedSaveSignatureMessages: MessagesModel = {
    error: {
      summary: '',
      detail: '',
    },
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private signatureService: ESignatureService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getEsignatureInfo();
    this.listenToLangChanges();
  }

  ngAfterViewInit() {
    this.signaturePad.set('canvasWidth', this.container.nativeElement.offsetWidth);
    this.signaturePad.clear();
  }

  getEsignatureInfo() {
    this.signatureGuid = this.activatedRoute.snapshot.paramMap.get('guid');
    this.signatureService
      .getESignatureInfo(this.signatureGuid)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (signatureInfo: any) => {
          this.signatureInfo = signatureInfo;
        },
        error: (signatureError: any) => {
          this.handleErrorMessages(signatureError);
        },
      });
  }

  getTranslatedMessages() {
    this.translateService
      .get('applicationDigitalSignatures.messages')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((messages: any) => {
        this.translatedSaveSignatureMessages.error.summary = messages.saveSignaturePadError;
      });
  }

  listenToLangChanges() {
    const combinedLangChanges$ = combineLatest([this.translateService.onDefaultLangChange, this.translateService.onLangChange]);
    combinedLangChanges$
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(() => {
          this.getTranslatedMessages();
        })
      )
      .subscribe({
        next: () => {
          this.getTranslatedMessages();
        },
      });
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL('image/svg+xml');
    this.signatureImg = base64Data;
    this.signatureService.updateESignature(base64Data, this.signatureGuid).subscribe({
      next: () => {
        this.showSuccessPage = true;
      },
      error: (signatureError) => {
        this.handleErrorMessages(signatureError);
        this.messageService.add({
          key: 'signature-pad-messages',
          severity: Severity.ERROR,
          summary: this.translatedSaveSignatureMessages.error.summary,
          detail: '',
        });
      },
    });
  }

  handleErrorMessages(error: any) {
    if (error.error.message === this.alreadySubmittedBEMessage) {
      this.isSignatureAlreadySubmitted = true;
    }
    if (error.error.message === this.expiredApplicationBEMessage) {
      this.isApplicationExpired = true;
    }
  }
}
