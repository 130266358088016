/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { DtcPolicy, TableCol, TablesList } from '@iapplication2/interfaces';
import { ApplicationsManagementService, UtilsService } from '@iapplication2/services';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserSettings } from 'libs/interfaces/src/lib/user-settings.model';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserSettingsService } from 'libs/services/src/lib/user-settings/user-settings.service';
import { LazyLoadEvent, MenuItem, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-dtc-table',
  templateUrl: './dtc-table.component.html',
  styleUrls: ['./dtc-table.component.scss'],
})
export class DtcTableComponent implements OnInit, OnDestroy {
  dtcApplications: DtcPolicy[];
  userSettings: UserSettings;
  private unsubscribe: Subject<unknown> = new Subject();

  scrollableCols: TableCol[];

  selectedCols: TableCol[];

  @ViewChildren('unfrozenRows') unfrozenRows;
  @ViewChildren('frozenRows') frozenRows;

  page: number = 0;

  rows: number = 5;

  previousTableEvent: any;
  totalRecords: number;

  loading: boolean = true;
  matchModeOptions: SelectItem[];
  items: MenuItem[];

  rowsPerPage: string[] = ['5', '10', '20', '50'];

  constructor(
    private applicationManagementService: ApplicationsManagementService,
    private utilsService: UtilsService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'DTC Policy options',
        items: [
          {
            label: 'Option 1',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Option 2',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];

    this.scrollableCols = [
      {
        field: 'id',
        header: 'Policy Id',
        width: 150,
      },
      {
        field: 'leadId',
        header: 'Lead Id',
        width: 150,
      },
      {
        field: 'client',
        header: 'Client',
        width: 175,
      },
      {
        field: 'paymentMethod',
        header: 'Payment Method',
        width: 175,
      },
      {
        field: 'products',
        header: 'Products',
        width: 200,
      },
      {
        field: 'premium',
        header: 'Premium',
        width: 175,
      },
    ];

    this.selectedCols = this.scrollableCols;
    this.getUserTableColumns();
  }

  // syncronizeRowHeight() {
  //   this.frozenRows._results.forEach((result, index) => {
  //     const frozenHeight = result.nativeElement.offsetHeight;
  //     const unfrozenHeight = this.unfrozenRows._results[index].nativeElement
  //       .offsetHeight;
  //     result.nativeElement.setAttribute(
  //       'height',
  //       frozenHeight < unfrozenHeight ? unfrozenHeight : frozenHeight
  //     );
  //   });
  // }

  loadApplications(event?: LazyLoadEvent, globalSearchValue?: string) {
    if (event) {
      this.previousTableEvent = event;
    }

    this.page = this.previousTableEvent.first / this.previousTableEvent.rows + 1;
    const rows = this.previousTableEvent?.rows.toString();
    const sortField: string = this.previousTableEvent.sortField;
    const sortOder: string = this.previousTableEvent.sortOrder === -1 ? 'ASC' : 'DESC';

    this.loading = true;
    this.applicationManagementService
      .getDtcApplications(this.page, rows, sortField, sortOder, globalSearchValue)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.dtcApplications = res.data as DtcPolicy[];
        this.totalRecords = res.count;
        this.loading = false;
        window.setTimeout(() => {
          this.utilsService.syncronizeRowHeightForDataTable(this.frozenRows, this.unfrozenRows);
        }, 0);
      });
  }

  navigateToEdit(a) {
    console.log('..');
  }

  filterGlobal(value: string) {
    this.loadApplications(null, value);
  }

  getAge(date: string): string {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }

  toggleMenu(dtcPolicy: DtcPolicy, menu, ev) {
    ev.preventDefault();
    menu.toggle(ev);
  }

  getUserTableColumns() {
    this.userSettingsService
      .getUserSeetingsByUserId(1234)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          console.log(res[0]);
          this.userSettings = res[0];
          const tableColsForUser = this.userSettings.selectedTableColumns.filter((table) => table.name === TablesList.DTCAPPLICATIONS)[0];
          if (tableColsForUser) {
            this.selectedCols = tableColsForUser.columns;
          }
        }
      });
  }

  selectedColsChanged() {
    this.updateUserSettingsWithSelectedTableCols();
  }

  updateUserSettingsWithSelectedTableCols() {
    const tableFromUserSettings = this.userSettings.selectedTableColumns.filter(
      (selectedTable) => selectedTable.name === TablesList.DTCAPPLICATIONS
    )[0];
    if (tableFromUserSettings) {
      tableFromUserSettings.columns = this.selectedCols;
    } else {
      this.userSettings.selectedTableColumns.push({
        name: 'DTCapplications',
        columns: this.selectedCols,
      });
    }
    this.userSettingsService.updateUserSettingsByUserId(this.userSettings);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
