import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomValidatorsService {
  radioGroupRequired(groupId: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasValue = control.value !== null && control.value !== undefined && control.value !== '' && control.value !== groupId;

      return hasValue ? null : { radioGroupRequired: true };
    };
  }

  disclosureRequired(groupId: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasValue =
        control.value !== null &&
        control.value !== undefined &&
        control.value !== '' &&
        control.value !== groupId &&
        !(typeof control.value === 'object' && control.value.length === 1 && control.value[0].toString() === groupId);

      return hasValue ? null : { disclosureRequired: true };
    };
  }

  disclosureRequiredPlay(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasValue = control.value !== null && control.value !== undefined && control.value !== '';

      return hasValue ? null : { disclosureRequiredPlay: true };
    };
  }

  specificOptionSelected(optionId: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let isValid;
      if (Array.isArray(control.value)) {
        isValid = control.value.length && control.value.includes(parseInt(optionId));
      } else {
        isValid = control.value?.toString() === optionId;
      }

      return isValid ? null : { specificOptionNotSelected: true };
    };
  }

  containsValue(values: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let valueIsPresent = true;
      let valuesString = '';

      values.forEach((value, index) => {
        if (value)
          if (!control?.value?.includes(value)) {
            valueIsPresent = false;
          } else {
            if (values[index + 1]) {
              valuesString = valuesString + value + ', ';
            } else {
              valuesString = valuesString + value + '.';
            }
          }
      });

      return valueIsPresent ? null : { containsValue: valuesString };
    };
  }

  doesNotContainValue(values: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let valueIsPresent = false;
      let valuesString = '';

      values.forEach((value, index) => {
        if (value) {
          if (control?.value?.includes(value)) {
            valueIsPresent = true;
          } else {
            if (values[index + 1]) {
              valuesString = valuesString + value + ', ';
            } else {
              valuesString = valuesString + value + '.';
            }
          }
        }
      });

      return valueIsPresent ? { doesNotContainValue: valuesString } : null;
    };
  }

  hasFixedLength(values: number[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let correctLength = false;
      const options: string[] = [];

      values.forEach((value) => {
        if (control?.value?.length === value) {
          correctLength = true;
        } else {
          options.push(value?.toString());
        }
      });

      return correctLength ? null : { hasFixedLength: options };
    };
  }

  hasLengthBetween(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control?.value?.length >= min && control?.value?.length <= max) {
        return null;
      } else {
        return { hasLengthBetween: { min, max } };
      }
    };
  }

  nameFormat(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const REGEX = new RegExp(/^[a-z '"-]+$/i);
      if (!REGEX.test(control?.value)) {
        return { nameFormat: true };
      } else {
        return null;
      }
    };
  }

  postalCodeFormat(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const REGEX = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
      if (!REGEX.test(control?.value?.toString())) {
        return { postalCodeFormat: true };
      } else {
        return null;
      }
    };
  }

  disclosurePlayed(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasValue = control.value !== null && control.value !== undefined && control.value !== '';

      console.log(hasValue);

      return hasValue ? null : { disclosurePlayed: true };
    };
  }
}
