import { Component, OnDestroy, OnInit } from '@angular/core';
import { register } from '../../../../registryComponent';
import { BaseFieldDataDirective } from '../../../../baseFieldData.directive';
import { FormBuilderFieldTypeOption } from '@iapplication2/interfaces';
import { TitleCasePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApplicationsProcessService } from '@iapplication2/services';

@register('freetext')
@Component({
  selector: 'iapplication2-freetext-field-display',
  templateUrl: './freetext-field-display.component.html',
  styleUrls: ['./freetext-field-display.component.scss'],
})
export class FreetextFieldDisplayComponent extends BaseFieldDataDirective implements OnInit, OnDestroy {
  textArea = false;
  isPhoneNumber = false;
  onlyNumbers = false;
  titleCase = false;
  postalCode = false;
  localTabIndex: string;
  isViewApplication: boolean;

  unsubscribe: Subject<void> = new Subject();

  constructor(private titleCasePipe: TitleCasePipe, private applicationsProcessService: ApplicationsProcessService) {
    super();
  }

  ngOnInit(): void {
    this.checkViewApplicationDisplay();
    this.setBehaviorBasedOnFieldOptions();
    if (this.tabIndex) {
      this.localTabIndex = this.tabIndex.toString();
    }
    if (this.titleCase) {
      this.formFieldControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe({
        next: () => {
          this.formFieldControl.setValue(this.titleCasePipe.transform(this.formFieldControl.value), { emitEvent: false });
        },
      });
    }
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  setBehaviorBasedOnFieldOptions() {
    switch (true) {
      case this.field.fieldTypeOptions.findIndex((option: FormBuilderFieldTypeOption) => option.id === 13) !== -1:
        this.textArea = true;
        break;
      case this.field.fieldTypeOptions.findIndex((option: FormBuilderFieldTypeOption) => option.id === 15) !== -1:
        this.isPhoneNumber = true;
        break;
      case this.field.fieldTypeOptions.findIndex((option: FormBuilderFieldTypeOption) => option.id === 26) !== -1:
        this.formFieldControl.disable();
        break;
      case this.field.fieldTypeOptions.findIndex((option: FormBuilderFieldTypeOption) => option.id === 27) !== -1:
        this.onlyNumbers = true;
        break;
      case this.field.fieldTypeOptions.findIndex((option: FormBuilderFieldTypeOption) => option.id === 28) !== -1:
        this.postalCode = true;
        break;
      case this.field.fieldTypeOptions.findIndex((option: FormBuilderFieldTypeOption) => option.id === 29) !== -1:
        this.titleCase = true;
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
