<iapplication2-client-actions
  *ngIf="client"
  (updateClientEmitter)="onUpdateClient()"
  [client]="client"
  class="mb-0"
  [currentTabIndex]="currentTabIndex"
  [clientForm]="clientForm"
></iapplication2-client-actions>
<p-tabView (onChange)="handleTabChange($event)">
  <p-tabPanel [header]="'pages.clients.headers.clientInfo' | translate">
    <iapplication2-client-information
      [clientForm]="clientForm"
      [formSubmissionTriggered]="formSubmissionTriggered"
    ></iapplication2-client-information>
  </p-tabPanel>
  <p-tabPanel [header]="'pages.clients.headers.clientApplicationsAndPolicies' | translate">
    <iapplication2-client-policies *ngIf="clientPolicies" [clientPolicies]="clientPolicies"></iapplication2-client-policies>
    <iapplication2-client-applications
      *ngIf="clientApplications"
      [clientApplications]="clientApplications"
    ></iapplication2-client-applications>
  </p-tabPanel>
</p-tabView>

<p-toast> </p-toast>
