<div class="fieldsSidebar-container card" fxFlex fxLayout="column">
  <form [formGroup]="searchFormGroup">
    <span class="p-input-icon-right" *ngIf="!showOnlyDefaultFieldTypes">
      <i class="pi pi-search"></i>
      <input type="text" class="p-inputtext" id="searchInput" placeholder="Search for a field" formControlName="searchInput" fxFlex />
    </span>
  </form>
  <p-divider *ngIf="!showOnlyDefaultFieldTypes"></p-divider>
  <div *ngIf="searchForFieldValue?.length <= 2; else displaySearchResults">
    <p-fieldset legend="Field types" [toggleable]="true" class="mb-3">
      <iapplication2-field-type-card
        [defaultItem]="defaultItem"
        *ngFor="let defaultItem of defaultItems"
        fxFlex="50"
        fxFlex.lt-lg="100"
        (click)="fieldSelected(defaultItem)"
        action="draw"
      >
      </iapplication2-field-type-card>
    </p-fieldset>
    <div *ngFor="let predefinedFieldsInCategory of predefinedFieldsByCategory" class="mb-3 test">
      <div *ngIf="!showOnlyDefaultFieldTypes">
        <div *ngIf="predefinedFieldsInCategory.category?.slug === categorySlugEnum.UNASSIGNED && showUnassignedList">
          <p-fieldset legend="Unassigned" [toggleable]="true" class="mb-3">
            <iapplication2-fields-sidebar-category
              [fieldsSidebarCategory]="predefinedFieldsInCategory.category"
              [predefinedFields]="predefinedFieldsInCategory.fields"
              [showTemplateChoiceDialog]="true"
              style="width: 100%"
            >
            </iapplication2-fields-sidebar-category>
          </p-fieldset>
        </div>
        <div *ngIf="predefinedFieldsInCategory.category?.slug !== categorySlugEnum.UNASSIGNED">
          <p-fieldset [legend]="predefinedFieldsInCategory.category?.title" [toggleable]="true" class="mb-3">
            <iapplication2-fields-sidebar-category
              [fieldsSidebarCategory]="predefinedFieldsInCategory.category"
              [predefinedFields]="predefinedFieldsInCategory.fields"
              [showTemplateChoiceDialog]="true"
              style="width: 100%"
            >
            </iapplication2-fields-sidebar-category>
          </p-fieldset>
        </div>
      </div>
    </div>
  </div>
  <ng-template #displaySearchResults>
    <div *ngIf="searchResultFields.length; else emptyState">
      <div *ngFor="let predefinedFieldsInCategory of searchResultFields" class="mb-3 test">
        <div *ngIf="!showOnlyDefaultFieldTypes">
          <div *ngIf="predefinedFieldsInCategory.category?.slug === categorySlugEnum.UNASSIGNED && showUnassignedList">
            <p-fieldset legend="Unassigned" [toggleable]="true" class="mb-3">
              <iapplication2-fields-sidebar-category
                [fieldsSidebarCategory]="predefinedFieldsInCategory.category"
                [predefinedFields]="predefinedFieldsInCategory.fields"
                [showTemplateChoiceDialog]="true"
                style="width: 100%"
              >
              </iapplication2-fields-sidebar-category>
            </p-fieldset>
          </div>
          <div *ngIf="predefinedFieldsInCategory.category?.slug !== categorySlugEnum.UNASSIGNED">
            <p-fieldset [legend]="predefinedFieldsInCategory.category?.title" [toggleable]="true" class="mb-3">
              <iapplication2-fields-sidebar-category
                [fieldsSidebarCategory]="predefinedFieldsInCategory.category"
                [predefinedFields]="predefinedFieldsInCategory.fields"
                [showTemplateChoiceDialog]="true"
                style="width: 100%"
              >
              </iapplication2-fields-sidebar-category>
            </p-fieldset>
          </div>
        </div>
      </div>
    </div>
    <ng-template #emptyState> There are no custom fields that match your search. </ng-template>
  </ng-template>
</div>
