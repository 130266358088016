<div>
  <button pButton pRipple class="p-button ml-2" label="Add new item" (click)="op.toggle($event)"></button>
  <p-overlayPanel [dismissable]="true" [showCloseIcon]="true" styleClass="overlayPanel-fieldSelection fieldSidebar" #op>
    <ng-template pTemplate>
      <div class="fieldType-container mt-3">
        <iapplication2-field-type-card
          [defaultItem]="defaultItem"
          *ngFor="let defaultItem of defaultItems"
          fxFlex="50"
          fxFlex.lt-lg="100"
          (click)="fieldSelected(defaultItem)"
        >
        </iapplication2-field-type-card>
      </div>
    </ng-template>
  </p-overlayPanel>
  <p-dialog
    *ngIf="isFieldDialogOpen"
    [modal]="true"
    [(visible)]="isFieldDialogOpen"
    [style]="{ width: '40vw', minWidth: '600px' }"
    showEffect="fade"
  >
    <iapplication2-field-settings-modal
      [isInFieldBuilder]="true"
      [selectedFormBuilderItem]="selectedItem"
      [modalType]="settingsDialogOptions"
      (closeDialog)="closeFieldDialog()"
    >
    </iapplication2-field-settings-modal>
  </p-dialog>
  <button
    class="ml-2"
    type="button"
    pRipple
    pButton
    pRipple
    icon="pi pi-bars"
    label="Category manager"
    (click)="categoryManagerMenu.toggle($event)"
  ></button>
  <p-menu #categoryManagerMenu [popup]="true" [model]="categoryManagerOptions"></p-menu>
  <p-dialog
    *ngIf="isCategoryCreatorOpen"
    [modal]="true"
    [(visible)]="isCategoryCreatorOpen"
    [style]="{ width: '40vw', minWidth: '600px' }"
    showEffect="fade"
  >
    <iapplication2-field-categories-creator (closeDialog)="closeCategoryCreatorDialog()"></iapplication2-field-categories-creator>
  </p-dialog>
  <p-dialog
    *ngIf="isCategoryManagerOpen"
    [modal]="true"
    [(visible)]="isCategoryManagerOpen"
    [style]="{ width: '40vw', minWidth: '600px' }"
    showEffect="fade"
  >
    <iapplication2-field-categories></iapplication2-field-categories>
  </p-dialog>
</div>
