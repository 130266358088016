import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'iapplication2-application-payment-popup-pad',
  templateUrl: './application-payment-popup-pad.component.html',
  styleUrls: ['./application-payment-popup-pad.component.scss'],
})
export class ApplicationPaymentPopupPadComponent {
  @Input() paymentTypeForm;
  formGroupType = FormGroup;
}
