/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PredefinedFieldsService } from '../../../services/src/index';

export function fieldLabelDuplicationValidator(service: PredefinedFieldsService, currentValue: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      if (service.checkIfFieldWithProvidedNameExists(control.value, currentValue)) {
        return {
          duplicateFieldLabel: true,
        };
      }
    }
  };
}
