import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilderField, FormBuilderItem, FormListDisplay, ModalType, Page, FormBuilderFieldType } from '@iapplication2/interfaces';
import { CanvasService, FormManagementService, InteractiveFormBuilderService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'iapplication2-dynamic-topbar-form-builder-information',
  templateUrl: './dynamic-topbar-form-builder-information.component.html',
  styleUrls: ['./dynamic-topbar-form-builder-information.component.scss'],
})
export class DynamicTopbarFormBuilderInformationComponent implements OnInit, OnDestroy, OnChanges {
  unsubscribe: Subject<void> = new Subject();

  pageViewState: 'draw' | 'form' | 'preview';
  selectedFieldForDrawing: FormBuilderItem;
  userDrawing: boolean = false;
  page: Page;
  pagesOfThisForm: Page[];
  selectedPage: Page;
  pageId: number;
  formId: number;

  pageViewStateOptions: unknown[] = [
    {
      label: 'Draw',
      value: 'draw',
    },
    {
      label: 'Form',
      value: 'form',
    },
    {
      label: 'Preview',
      value: 'preview',
      disabled: true,
    },
  ];

  FormBuilderFieldType = FormBuilderFieldType;

  constructor(
    private canvasService: CanvasService,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private formManagementService: FormManagementService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createCurrentFormIdSubscription();
    this.createCurrentPageIdSubscription();
    this.interactiveFormBuilderService.fromBuilderPageViewState.pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
      this.pageViewState = state;
    });
    this.canvasService.userDrawing.pipe(takeUntil(this.unsubscribe)).subscribe((userDrawing) => {
      this.userDrawing = userDrawing;
    });
    this.interactiveFormBuilderService.fieldWasSelected.pipe(takeUntil(this.unsubscribe)).subscribe((selectedField) => {
      if (selectedField) {
        this.selectedFieldForDrawing = selectedField;
      }
    });
    this.canvasService.currentlyDrawingFieldFromGroup.pipe(takeUntil(this.unsubscribe)).subscribe((field) => {
      this.selectedFieldForDrawing = field;
    });
    this.canvasService.currentlyDrawingFieldFromTable.pipe(takeUntil(this.unsubscribe)).subscribe((field) => {
      this.selectedFieldForDrawing = field;
    });
  }

  createCurrentFormIdSubscription() {
    this.formManagementService.currentFormId.pipe(takeUntil(this.unsubscribe)).subscribe((currentFormId: number) => {
      this.formId = currentFormId;
      this.formManagementService.getFormById(this.formId);
    });
  }

  createCurrentPageIdSubscription() {
    this.formManagementService.currentPageId.pipe(takeUntil(this.unsubscribe)).subscribe((currentPageId: number) => {
      this.pageId = currentPageId;
      this.formManagementService.fetchedForm.pipe(takeUntil(this.unsubscribe)).subscribe((res: FormListDisplay) => {
        if (res.pages.length) {
          this.pagesOfThisForm = res.pages;
          this.selectedPage = this.pagesOfThisForm.filter((page) => page.id == this.pageId)[0];
          this.cdr.detectChanges();
        }
      });
    });
  }

  changedPage() {
    if (this.selectedPage) {
      this.formManagementService.changedPage.next(this.selectedPage);
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pageId) {
      this.selectedPage = _.cloneDeep(this.page);
    }
  }

  chagnedSelectButtonValue() {
    this.interactiveFormBuilderService.fromBuilderPageViewStateChanged.next(this.pageViewState);
    if (this.pageViewState === ModalType.FORM) {
      this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.FORM);
    } else {
      this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.DRAW);
    }
  }

  getItemAsField(item: FormBuilderItem): FormBuilderField {
    return item as FormBuilderField;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
