<p-card>
  <div *ngIf="shouldProductsBeDisplayed">
    <button
      (click)="startReflex()"
      pButton
      [label]="'client_selection.buttons.sureTerm' | translate"
      [style]="{ width: '100%' }"
      class="mb-1"
    ></button>
  </div>
  <div *ngIf="!shouldProductsBeDisplayed || (shouldProductsBeDisplayed && !isSmokerValid)" class="mb-0">
    <p>
      <b>{{ 'client_selection.showProductConditionsText' | translate }}</b>
    </p>
    <ul>
      <li *ngIf="!isGenderValid" class="mb-0">{{ 'client_selection.gender' | translate }}</li>
      <li *ngIf="!isDobValid" class="mb-0">{{ 'client_selection.birthDate' | translate }}</li>
      <li *ngIf="!isProvinceValid" class="mb-0">{{ 'client_selection.province' | translate }}</li>
      <li *ngIf="!isPostalCodeValid" class="mb-0">{{ 'client_selection.postal_code' | translate }}</li>
      <li *ngIf="!isSmokerValid" class="mb-0">{{ 'client_selection.smokingStatus.questionLabel' | translate }}</li>
    </ul>
  </div>
  <div *ngIf="shouldProductsBeDisplayed && productResponse">
    <p-accordion>
      <div class="flex flex-column">
        <iapplication2-product-selection-card
          *ngFor="let product of productList"
          [ratesProduct]="product"
          [form]="form"
          (changedProductSelection)="onSelectionChanged($event)"
          [isEverestAllowed]="isEverestAllowed"
          [everestMultiProductObject]="everestMultiProductObject"
          (everestValueUpdated)="onEverestValueUpdated($event)"
          (resetEverestValue)="onResetEverestValue($event)"
          [isAnyProductSelected]="isAnyProductSelected"
          [maxHumaniaCoverage]="humaniaTotalMaxCoverage"
          [currentHumaniaCoverage]="humaniaProductsCoverage"
          class="flex"
          [style]="{ order: product.iappProduct?.position }"
        ></iapplication2-product-selection-card>
      </div>
    </p-accordion>
  </div>
  <h4 class="mt-4 mb-0" fxLayout="row" fxLayoutAlign="space-between center">
    {{ 'client_selection.information.totalPremium' | translate }}
    <span>
      {{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ totalPremium | number: '1.2-2' }}
      {{ 'client_selection.per_month' | translate }}
    </span>
  </h4>
  <h5 class="mt-2 mb-0" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="humaniaProductsCoverage > 0">
    {{ 'client_selection.information.humaniaProductsCoverage' | translate }}
    <span
      >{{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ humaniaProductsCoverage | number: '1.2-2' }} /
      {{ applicationsProcessService.currentApplication?.currency?.symbol }} {{ humaniaTotalMaxCoverage | number: '1.2-2' }}</span
    >
  </h5>
  <div *ngIf="productList.length && isEverestSelectedOnAnyProduct" class="pt-4">
    <p>{{ 'applicationProcess.step.productSelection.everestText' | translate }}</p>
  </div>
</p-card>
