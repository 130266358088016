import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { ENV } from '@iapplication2/interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private appConfig: ENV) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiReq;
    if (request.url.includes('http')) {
      apiReq = request.clone({ url: request.url });
    } else {
      apiReq = request.clone({ url: `${this.appConfig.apiUrl}/${request.url}` });
    }
    return next.handle(apiReq);
  }
}
