<div
  class="fieldType-card card hoverBorder p-custom-shadow-3 mx-3 py-3 mb-3 justify-content-center"
  fxLayout="column"
  fxLayoutAlign="center center"
  [title]="defaultItem.label"
>
  <div [class]="'fieldType-chip ' + defaultItem.type">
    {{ defaultItem.label }}
  </div>
</div>
