import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignatureRecipientTypeEnum, SignatureChanelTypeEnum } from '@iapplication2/interfaces';
import { BehaviorSubject } from 'rxjs';
import { ApplicationsProcessService } from '../application-process/application-process.service';
@Injectable({
  providedIn: 'root',
})
export class ESignatureService {
  payorSignatureSubject = new BehaviorSubject('' as SignatureRecipientTypeEnum | '');
  ownerSignatureSubject = new BehaviorSubject('' as SignatureRecipientTypeEnum | '');
  isAppSignatureSubmittedSubject = new BehaviorSubject(false);
  applicationId = this.applicationsProcessService?.currentApplication?.id || null;

  constructor(private http: HttpClient, private applicationsProcessService: ApplicationsProcessService) {
    this.applicationsProcessService.applicationChanged.subscribe((application) => {
      if (this.applicationId) {
        if (this.applicationId !== application?.id) {
          this.ownerSignatureSubject.next('');
          this.payorSignatureSubject.next('');
        }
      }
      this.applicationId = application?.id || null;
    });
  }

  sendForESignature(payload: any) {
    Object.assign(payload, { applicationId: this.applicationId });
    return this.http.post(`application/signature/${this.applicationId}`, payload);
  }

  getESignatureInfo(guid: string) {
    return this.http.get(`application/signature/${guid}`);
  }

  getApplicationDigitalSignatures(applicationId: number) {
    return this.http.get(`application/signatures/${applicationId}`);
  }

  updateESignature(payload: string, guid: string) {
    return this.http.put(`application/signature/${guid}`, { signature: payload });
  }

  updateSignatureData(payload: any, id: number) {
    return this.http.put(`application/signature/update/${id}`, payload);
  }
}
