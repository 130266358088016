import { Client } from '../client.model';
import { Product } from '../product.model';
import { AttachedFile } from './attached-file.model';

export interface SummaryPageInfo {
  applicationAttachments?: AttachedFile[];
  productPdfs?: ProductPdf[];
  advisorId?: number;
  products: Product[];
  upsellProducts?: Product[];
  client?: Client;
  ignoreSignatures?: boolean;
  dontEmailPolicy?: boolean;
  paymentMethod?: string;
  dates?: {
    effective: Date;
    schedule: Date;
    withdrawal: string;
  };
  currencySymbol?: string;
}

export interface ShortPage {
  id: number;
  imageUrl: string;
}

export interface ProductPdf {
  id: number;
  name: string;
  pages: ShortPage[];
}
