<div fxLayout="row" class="mb-3" [formGroup]="parentFormGroup">
  <div class="flex">
    <iapplication2-required [required]="formFieldControl.errors?.required && !field.table"></iapplication2-required>
    <iapplication2-required
      class="flex ai-center"
      style="min-width: 20px"
      [rowRequired]="field?.requiredByTableRow"
    ></iapplication2-required>
    <label *ngIf="!hideLabel" [class.displayReadonlyValue]="isReadonly">{{ field?.options.customFieldLabel }}</label>
  </div>
  <div class="flex flex-column" [class.displayReadonlyValue]="isReadonly">
    <p-checkbox
      #checkboxInput
      class="mr-2"
      [formControlName]="field?.id"
      [binary]="true"
      [class.isReadOnlyRadioButton]="isReadonly"
      [tabindex]="tabIndex"
      trueValue="x"
      falseValue=""
    ></p-checkbox>
  </div>
</div>
