<form [formGroup]="paymentTypeForm.controls['cardForm'] | cast: formGroupType" fxLayout="column">
  <div class="flex flex-column">
    <div class="grid">
      <div class="col-3 pr-3 mb-2">
        <label class="pb-1" for="holderFirstName">{{
          'applicationProcess.step.applicationForms.payment.card.holderFirstName' | translate
        }}</label>
        <input id="holderFirstName" formControlName="holderFirstName" pInputText [style]="{ width: '100%' }" />
        <iapplication2-form-field-error
          *ngIf="paymentTypeForm.controls['cardForm'].controls['holderFirstName'].touched"
          [control]="paymentTypeForm.controls['cardForm'].controls['holderFirstName']"
          style="bottom: -8px"
        ></iapplication2-form-field-error>
      </div>
      <div class="col-3 pr-3 mb-2">
        <label class="pb-1" for="holderLastName">{{
          'applicationProcess.step.applicationForms.payment.card.holderLastName' | translate
        }}</label>
        <input id="holderLastName" formControlName="holderLastName" pInputText [style]="{ width: '100%' }" />
        <iapplication2-form-field-error
          *ngIf="paymentTypeForm.controls['cardForm'].controls['holderLastName'].touched"
          [control]="paymentTypeForm.controls['cardForm'].controls['holderLastName']"
          style="bottom: -8px"
        ></iapplication2-form-field-error>
      </div>
      <div class="col-4 pr-3 mb-2">
        <label class="pb-1" for="holderAddress">{{
          'applicationProcess.step.applicationForms.payment.card.holderAddress' | translate
        }}</label>
        <input id="holderAddress" formControlName="holderAddress" pInputText [style]="{ width: '100%' }" />
        <iapplication2-form-field-error
          *ngIf="paymentTypeForm.controls['cardForm'].controls['holderAddress'].touched"
          [control]="paymentTypeForm.controls['cardForm'].controls['holderAddress']"
          style="bottom: -8px"
        ></iapplication2-form-field-error>
      </div>
      <div class="col-2 pr-3 mb-2">
        <label class="pb-1" for="holderZipCode">{{
          'applicationProcess.step.applicationForms.payment.card.holderZipCode' | translate
        }}</label>
        <input id="holderZipCode" formControlName="holderZipCode" pInputText [style]="{ width: '100%' }" />
        <iapplication2-form-field-error
          *ngIf="paymentTypeForm.controls['cardForm'].controls['holderZipCode'].touched"
          [control]="paymentTypeForm.controls['cardForm'].controls['holderZipCode']"
          style="bottom: -8px"
        ></iapplication2-form-field-error>
      </div>
    </div>
    <div class="grid">
      <div class="col-4 pr-3 mb-2">
        <label class="pb-1" for="cardNumber">{{ 'applicationProcess.step.applicationForms.payment.card.cardNumber' | translate }}</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-credit-card"></i></span>
          <input id="cardNumber" formControlName="cardNumber" pInputText />
        </div>
        <iapplication2-form-field-error
          *ngIf="paymentTypeForm.controls['cardForm'].controls['cardNumber'].touched"
          [control]="paymentTypeForm.controls['cardForm'].controls['cardNumber']"
          style="bottom: -8px"
        ></iapplication2-form-field-error>
      </div>
      <div class="col-2 pr-3 mb-2">
        <label class="pb-1" for="cvvNumber">{{ 'applicationProcess.step.applicationForms.payment.card.CVV' | translate }}</label>
        <input id="cvvNumber" formControlName="cvvNumber" pInputText [style]="{ width: '100%' }" />
        <iapplication2-form-field-error
          *ngIf="paymentTypeForm.controls['cardForm'].controls['cvvNumber'].touched"
          [control]="paymentTypeForm.controls['cardForm'].controls['cvvNumber']"
          style="bottom: -8px"
        ></iapplication2-form-field-error>
      </div>
      <div class="col-3 pr-3 mb-2">
        <label class="pb-1" for="expirationMonth">{{
          'applicationProcess.step.applicationForms.payment.card.expirationMonth' | translate
        }}</label>
        <p-inputMask
          id="expirationMonth"
          formControlName="expirationMonth"
          placeholder="00"
          mask="99"
          [autoClear]="false"
          [style]="{ width: '100%' }"
        ></p-inputMask>
        <iapplication2-form-field-error
          *ngIf="paymentTypeForm.controls['cardForm'].controls['expirationMonth'].touched"
          [control]="paymentTypeForm.controls['cardForm'].controls['expirationMonth']"
          style="bottom: -8px"
        ></iapplication2-form-field-error>
      </div>
      <div class="col-3 pr-3 mb-2">
        <label class="pb-1" for="expirationYear">{{
          'applicationProcess.step.applicationForms.payment.card.expirationYear' | translate
        }}</label>
        <p-inputMask
          id="expirationYear"
          formControlName="expirationYear"
          placeholder="00"
          mask="99"
          [autoClear]="false"
          [style]="{ width: '100%' }"
        ></p-inputMask>
        <iapplication2-form-field-error
          *ngIf="paymentTypeForm.controls['cardForm'].controls['expirationYear'].touched"
          [control]="paymentTypeForm.controls['cardForm'].controls['expirationYear']"
          style="bottom: -8px"
        ></iapplication2-form-field-error>
      </div>
    </div>
  </div>
  <div *ngIf="false" fxLayout="row">
    <div fxLayout="column" class="pr-3 mb-2" fxFlex>
      <label class="pb-1" for="holderAddress">{{
        'applicationProcess.step.applicationForms.payment.card.holderAddress' | translate
      }}</label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="pi pi-map-marker"></i></span>
        <input id="holderAddress" formControlName="holderAddress" pInputText />
      </div>
      <iapplication2-form-field-error
        *ngIf="paymentTypeForm.controls['cardForm'].controls['holderAddress'].touched"
        [control]="paymentTypeForm.controls['cardForm'].controls['holderAddress']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>
    <div fxLayout="column" class="pr-3 mb-2">
      <label class="pb-1" for="holderZipCode">{{
        'applicationProcess.step.applicationForms.payment.card.holderZipCode' | translate
      }}</label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="pi pi-map-marker"></i></span>
        <input id="holderZipCode" formControlName="holderZipCode" pInputText />
      </div>
      <iapplication2-form-field-error
        *ngIf="paymentTypeForm.controls['cardForm'].controls['holderZipCode'].touched"
        [control]="paymentTypeForm.controls['cardForm'].controls['holderZipCode']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>
  </div>
  <div *ngIf="false" fxLayout="row">
    <div fxLayout="column" class="pr-3 mb-2" fxFlex>
      <label class="pb-1" for="holderFirstName">{{
        'applicationProcess.step.applicationForms.payment.card.holderFirstName' | translate
      }}</label>
      <input id="holderFirstName" formControlName="holderFirstName" pInputText />
      <iapplication2-form-field-error
        *ngIf="paymentTypeForm.controls['cardForm'].controls['holderFirstName'].touched"
        [control]="paymentTypeForm.controls['cardForm'].controls['holderFirstName']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>
    <div fxLayout="column" class="pr-3 mb-2" fxFlex>
      <label class="pb-1" for="holderLastName">{{
        'applicationProcess.step.applicationForms.payment.card.holderLastName' | translate
      }}</label>
      <input id="holderLastName" formControlName="holderLastName" pInputText />
      <iapplication2-form-field-error
        *ngIf="paymentTypeForm.controls['cardForm'].controls['holderLastName'].touched"
        [control]="paymentTypeForm.controls['cardForm'].controls['holderLastName']"
        style="bottom: -8px"
      ></iapplication2-form-field-error>
    </div>
  </div>
</form>
