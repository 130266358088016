import { Component, Input, OnInit } from '@angular/core';
import { FormBuilderItem } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-field-details-static-text',
  templateUrl: './field-details-static-text.component.html',
  styleUrls: ['./field-details-static-text.component.scss'],
})
export class FieldDetailsStaticTextComponent implements OnInit {
  @Input() staticTextField: FormBuilderItem;

  ngOnInit(): void {
    this.staticTextField.content = '';
  }
}
