export const STATIC_FIELD_LINK_ALTERNATE_VALUES = {
  smokerStatus: {
    YES: ['SMOKER', 'YES', 'OUI'],
    NO: ['NONSMOKER', 'NO', 'NON'],
  },
  gender: {
    MALE: ['MALE', 'HOMME'],
    FEMALE: ['FEMALE', 'FEMME'],
  },
  title: {
    MR: ['MR', 'M'],
    MRS: ['MRS', 'MME'],
    MS: ['MS'],
    MISS: ['MISS', 'MLLE'],
    REV: ['REV', 'LE RÉVÉREND PÈRE/LA RÉVÉRENDE MÈRE/SOEUR'],
    DR: ['DR', 'DR/DRE'],
  },
  paymentMethod: {
    PAC: ['PAC', 'PAD'],
    creditCard: ['CREDITCARD', 'CARTEDECREDIT', 'CARTEDECRÉDIT', 'CARTEDECRDIT', 'CC', 'CARD'],
  },
};
