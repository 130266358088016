import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FieldAttachedImage, FormBuilderField } from '@iapplication2/interfaces';
import { FieldManagementService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-field-details-attachment',
  templateUrl: './field-details-attachment.component.html',
  styleUrls: ['./field-details-attachment.component.scss'],
})
export class FieldDetailsAttachmentComponent implements OnInit, OnDestroy {
  @Input() selectedField: FormBuilderField;
  @Input() isInEditMode: boolean;
  fieldAttachment: FieldAttachedImage;

  private unsubscribe = new Subject<void>();

  constructor(private fieldManagementService: FieldManagementService) {}

  ngOnInit(): void {
    if (this.selectedField.id) {
      this.getFieldAttachments();
    }
  }

  private getFieldAttachments(): void {
    this.fieldManagementService
      .getFieldAttachments(this.selectedField.id.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: FieldAttachedImage) => {
          if (res) {
            this.fieldAttachment = res;
          }
        },
      });
  }

  addFieldAttachment(event: any) {
    const uploadedFile = event.files[0];
    this.fieldAttachment = {
      fileName: uploadedFile.name,
      s3Key: uploadedFile.objectURL,
    };
    this.selectedField.fileToBeAttached = uploadedFile;
  }

  deleteFieldAttachment() {
    if (this.fieldAttachment?.id) {
      this.selectedField.fileToBeDeleted = true;
    } else {
      delete this.selectedField.fileToBeAttached;
    }

    this.fieldAttachment = null;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
