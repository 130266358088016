import { FormBuilderFieldOptions } from './form-builder-field-options.model';
import { FieldType } from './field-type.model';
import { FormBuilderFieldGroupOptions } from './form-builder-field-group-options.model';
import { FormBuilderFieldTypeOption } from './form-builder-field-type-option.model';
import { FormBuilderFieldTypeEntries } from './form-builder-field-type-entries.model';
import { FormBuilderFieldValidator } from './form-builder-field-validator.model';
import { FieldTable, FieldTablePosition } from './field-type-table.model';
import { FormBuilderFieldStaticFieldLink } from './form-builder-field-static-link.model';
import { FieldDisclosureDetails } from './field-disclosure-details.model';
export interface FormBuilderField {
  id?: number;
  formId?: number;
  formAreaId?: number;
  formAreaPosition?: number;
  sectionId?: number;
  options: FormBuilderFieldOptions;
  groupOptions?: FormBuilderFieldGroupOptions;
  fieldTypeOptions?: FormBuilderFieldTypeOption[];
  fieldOptionEntries?: FormBuilderFieldTypeEntries;
  fieldValidators?: FieldValidators;
  fieldType: FieldType;
  table?: {
    type: FieldTable;
    position: FieldTablePosition;
  };
  predefinedFieldId?: number;
  productFormId?: number;
  content?: string;
  staticValueLinkId?: number;
  staticValueLink?: FormBuilderFieldStaticFieldLink;
  fieldDisclosureOption?: FieldDisclosureDetails;
  predefinedFieldDisclosureOption?: FieldDisclosureDetails;
  [key: string]: any;
}

export interface FieldValidators {
  predefinedValidators: FormBuilderFieldValidator[];
  manualValidators: FormBuilderFieldValidator[];
}

export class FormBuilderFieldType implements FormBuilderField {
  id?: number;
  formId?: number;
  formAreaId?: number;
  formAreaPosition?: number;
  options: FormBuilderFieldOptions;
  groupOptions?: FormBuilderFieldGroupOptions;
  fieldTypeOptions?: FormBuilderFieldTypeOption[];
  fieldOptionEntries?: FormBuilderFieldTypeEntries;
  fieldValidators?: FieldValidators;
  fieldType: FieldType;
  table?: {
    type: FieldTable;
    position: FieldTablePosition;
  };
  predefinedFieldId?: number;
  content?: string;
  [key: string]: any;
}
