<div class="card">
  <p-tabView>
    <p-tabPanel *ngIf="isEnvDevelop" [header]="'pages.dashboard.sections.recentPolicies.tabs.recentPolicies.title' | translate">
      <iapplication2-result-section-policies
        [loading]="loadingPolicies"
        [policies]="recentPolicies"
      ></iapplication2-result-section-policies>
    </p-tabPanel>
    <p-tabPanel [header]="'pages.dashboard.sections.recentPolicies.tabs.recentApplications.title' | translate">
      <ng-template pTemplate="content">
        <iapplication2-result-section-applications
          [loading]="loadingApplications"
          [applications]="recentApplications"
        ></iapplication2-result-section-applications>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel *ngIf="isEnvDevelop" [header]="'pages.dashboard.sections.recentPolicies.tabs.recentDTCApplications.title' | translate">
      <ng-template pTemplate="content">
        <iapplication2-result-section-dtc
          [loading]="loadingDtcApplications"
          [dtcApplications]="recentDtcApplications"
        ></iapplication2-result-section-dtc>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
