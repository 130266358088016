import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CastPipe } from './cast/cast.pipe';
import { AmountInputFormatPipe } from './amount-input-format/amount-input-format.pipe';
import { ItemTypeCheckPipe } from './item-type-check/item-type-check.pipe';
import { FieldTypeCheckPipe } from './field-type-check/field-type-check.pipe';
import { ConvertFormAreaWidthPipe } from './convert-form-area-width/convert-form-area-width.pipe';
import { TextLengthCheckPipe } from './text-length-check/text-length-check.pipe';
import { TranslateByPipe } from './translate-by/translate-by.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CastPipe,
    AmountInputFormatPipe,
    ItemTypeCheckPipe,
    FieldTypeCheckPipe,
    ConvertFormAreaWidthPipe,
    TextLengthCheckPipe,
    TranslateByPipe,
  ],
  exports: [
    CastPipe,
    AmountInputFormatPipe,
    ItemTypeCheckPipe,
    FieldTypeCheckPipe,
    ConvertFormAreaWidthPipe,
    TextLengthCheckPipe,
    TranslateByPipe,
  ],
})
export class PipesModule {}
