import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { APP_CONFIG } from '@iapplication2/app-config';
import { SmokerStatus, Sex, ENV, LanguagesEnum, TitleEnumFr, Title, Gender, SexEn, SexFr } from '@iapplication2/interfaces';
import { ApplicationTypeList, TitleEnumEn } from '@iapplication2/interfaces';
import { TranslateService } from '@ngx-translate/core';
import _ = require('lodash');
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
//TODO integration apeluri din BE
export class UtilsService {
  countries;
  provinces;
  languages;
  states;
  currentLanguage: string;
  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient, private translateService: TranslateService) {}

  getLanguages() {
    const url = `languages`;

    if (this.languages) {
      return of(this.languages);
    } else {
      return this.http.get(url).pipe(
        tap((res: any) => {
          this.languages = res;
        })
      );
    }
  }

  getTitles() {
    let titles: Title[];
    switch (this.translateService.currentLang) {
      case LanguagesEnum.EN:
        titles = [
          { label: TitleEnumEn.MR, value: TitleEnumEn.MR },
          { label: TitleEnumEn.MRS, value: TitleEnumEn.MRS },
          { label: TitleEnumEn.MS, value: TitleEnumEn.MS },
          { label: TitleEnumEn.MISS, value: TitleEnumEn.MISS },
          { label: TitleEnumEn.REV, value: TitleEnumEn.REV },
          { label: TitleEnumEn.DR, value: TitleEnumEn.DR },
        ];
        break;
      case LanguagesEnum.FR:
        titles = [
          { label: TitleEnumFr.M, value: TitleEnumEn.MR },
          { label: TitleEnumFr.MME, value: TitleEnumEn.MRS },
          { label: TitleEnumFr.MLLE, value: TitleEnumEn.MISS },
          { label: TitleEnumFr.DR, value: TitleEnumEn.DR },
          { label: TitleEnumFr.REV, value: TitleEnumEn.REV },
        ];
        break;
    }
    return titles;
  }

  getGenders() {
    let genders: Gender[];

    switch (this.translateService.currentLang) {
      case LanguagesEnum.EN:
        genders = [
          { label: SexEn.MALE, value: SexEn.MALE },
          { label: SexEn.FEMALE, value: SexEn.FEMALE },
        ];
        break;

      case LanguagesEnum.FR:
        genders = [
          { label: SexFr.MALE, value: SexEn.MALE },
          { label: SexFr.FEMALE, value: SexEn.FEMALE },
        ];
        break;
    }
    return genders;
  }

  getCountriesFromApi() {
    const countriesAPIEndpoint = 'application/isi/countries';

    if (this.countries) {
      return of(this.countries);
    } else {
      return this.http.get(countriesAPIEndpoint).pipe(
        tap((res: any) => {
          this.countries = res;
        })
      );
    }
  }

  getProvincesFromApi() {
    const provincesAPIEndpoint = 'application/isi/provinces';

    if (this.provinces) {
      return of(this.provinces);
    } else {
      return this.http.get(provincesAPIEndpoint).pipe(
        tap((res: any) => {
          this.provinces = res;
        })
      );
    }
  }

  getStatesFromApi() {
    const statesAPIEndpoint = 'application/isi/states';

    if (this.states) {
      return of(this.states);
    } else {
      return this.http.get(statesAPIEndpoint).pipe(
        tap((res: any) => {
          this.states = res;
        })
      );
    }
  }

  syncronizeRowHeightForDataTable(frozenRows, unfrozenRows) {
    frozenRows._results.forEach((result, index) => {
      const frozenHeight = result.nativeElement.offsetHeight;
      const unfrozenHeight = unfrozenRows._results[index].nativeElement.offsetHeight;
      result.nativeElement.setAttribute('height', frozenHeight < unfrozenHeight ? unfrozenHeight : frozenHeight);
    });
  }

  getSmokerStatuses() {
    return Object.values(SmokerStatus);
  }

  getSex() {
    return Object.values(Sex);
  }

  hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  getApplicationTypes(): ApplicationTypeList[] {
    const applicationTypeList = ApplicationTypeList;
    return [applicationTypeList.ELECTRONIC, applicationTypeList.PAPER];
  }

  removeSpecialCharactersFromString(stringToRemoveFrom: string): string {
    return stringToRemoveFrom.replace(/[^\w ]/, '');
  }
}
