/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PasswordRegexConstants } from '@iapplication2/constants';

export function password(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value) {
      const regEx = new RegExp(PasswordRegexConstants.STRONG_PASSWORD_REGEX);
      console.log(regEx.test(control.value));
      return regEx.test(control.value)
        ? null
        : {
            invalidPassword: true,
          };
      // const lowerCaseLetter = [...control?.value].find(
      //   (character) => character !== character.toUpperCase()
      // )
      //   ? true
      //   : false;
      // const upperCaseLetter = [...control?.value].find(
      //   (character) =>
      //     character === character.toUpperCase() &&
      //     character !== character.toLowerCase()
      // )
      //   ? true
      //   : false;
      // const number = /\d/g.test(control.value);
      // const length = control.value.length >= 8 ? true : false;
      // return lowerCaseLetter && upperCaseLetter && number && length
      //   ? null
      //   : {
      //       invalidPassword: true,
      //     };
    }
    return null;
  };
}
