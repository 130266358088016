/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilderField, FormBuilderFieldOptions } from '@iapplication2/interfaces';
import { PredefinedFieldsService } from '@iapplication2/services';
import { FormHelper } from '@iapplication2/superclass';
import { fieldLabelDuplicationValidator } from '@iapplication2/validators';
import _ = require('lodash');

@Component({
  selector: 'iapplication2-field-details-field',
  templateUrl: './field-details-field.component.html',
  styleUrls: ['./field-details-field.component.scss'],
})
export class FieldDetailsFieldComponent extends FormHelper implements OnInit, OnChanges {
  @Input() isInCreation: boolean = false;
  @Input() isInEditMode: boolean = false;
  @Input() field: FormBuilderField;
  @Input() mainForm: FormGroup;

  @Output() invalidForm?: EventEmitter<boolean> = new EventEmitter<boolean>();

  categoryForm: FormGroup;

  constructor(private predefinedFieldsService: PredefinedFieldsService) {
    super();
  }

  ngOnInit(): void {
    this.mainForm.controls['fieldForm'] = new FormGroup({
      customFieldLabel: new FormControl(null, [
        Validators.required,
        fieldLabelDuplicationValidator(this.predefinedFieldsService, this.field?.options?.customFieldLabel),
      ]),
    });
    this.mainForm.controls['fieldForm'] = this.fillFormWithObjectValues<FormBuilderFieldOptions>(
      <FormGroup>this.mainForm.controls['fieldForm'],
      this.field?.options
    );
  }

  inputValueChanged(): void {
    if (this.mainForm.controls.fieldForm.get('customFieldLabel').errors?.duplicateFieldLabel) {
      this.invalidForm.emit(true);
    } else {
      this.field.options.customFieldLabel = this.mainForm.controls.fieldForm.get('customFieldLabel').value;
      this.field.options.customFieldName = this.field.options.customFieldLabel ? _.camelCase(this.field.options.customFieldLabel) : '';
      this.invalidForm.emit(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.field?.isFirstChange() && changes.field?.currentValue != changes.field?.previousValue) {
      this.mainForm.controls.fieldForm
        .get('customFieldLabel')
        .setValidators([
          Validators.required,
          fieldLabelDuplicationValidator(this.predefinedFieldsService, this.field?.options?.customFieldLabel),
        ]);
      this.mainForm.controls['fieldForm'] = this.fillFormWithObjectValues<FormBuilderFieldOptions>(
        <FormGroup>this.mainForm.controls['fieldForm'],
        this.field?.options
      );
    }
  }
}
