import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseGroupDataDirective } from '../../../../baseGroupData.directive';
import { register } from '../../../../registryComponent';
import { FormControl } from '@angular/forms';
import { FieldDetailsOptions, FormBuilderField, FormBuilderFieldTypeOption, ValidatorTypeEnum } from '@iapplication2/interfaces';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApplicationsProcessService, CustomValidatorsService } from '@iapplication2/services';

@register('radiogroup')
@Component({
  selector: 'iapplication2-radio-group-display',
  templateUrl: './radio-group-display.component.html',
  styleUrls: ['./radio-group-display.component.scss'],
})
export class RadioGroupDisplayComponent extends BaseGroupDataDirective implements OnInit, OnDestroy {
  formControlName: string;
  requiredOptionId: number;
  flexRow = false;
  showWarningText = false;
  tabIndex = null;
  isGroupRequired = false;
  isViewApplication: boolean;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private customValidatorsService: CustomValidatorsService,
    private applicationsProcessService: ApplicationsProcessService
  ) {
    super();
  }

  ngOnInit() {
    this.formControlName = this.group.groupOptions.id?.toString();
    this.requiredOptionId = this.findRequiredOption();
    this.checkValidators();
    this.setDefaultValue();
    this.setBehaviorBasedOnFieldOptions();
    if (this.isReadonly) {
      this.tabIndex = -1;
    }
    this.watchFormControlChanges();
    this.checkViewApplicationDisplay();
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  private watchFormControlChanges() {
    const initialValue = this.formControl.value ? parseInt(this.formControl.value) : null;
    this.formControl.valueChanges.pipe(startWith(initialValue), pairwise(), takeUntil(this.unsubscribe)).subscribe({
      next: (data) => {
        this.shouldShowWarningText();
        if (data[0] === data[1] && data[0] !== null && !this.isReadonly) {
          this.formControl.setValue(this.group.groupOptions.id);
        }
      },
    });
  }

  private findRequiredOption() {
    const requiredOption = this.group.fields.find((fieldInGroup) => {
      return fieldInGroup.options?.fieldDetails?.includes(FieldDetailsOptions.MARKED_AS_REQUIRED);
    });
    if (requiredOption) {
      requiredOption.isRequiredOption = true;
      return requiredOption.id;
    }
    return null;
  }

  private setDefaultValue() {
    this.group?.fields?.forEach((fieldInGroup: FormBuilderField) => {
      if (fieldInGroup.options?.fieldDetails?.includes(FieldDetailsOptions.MARKED_AS_DEFAULT) && this.formControl.value === null) {
        this.formControl.setValue(fieldInGroup.id);
        this.formControl.updateValueAndValidity();
      }
    });
  }

  setBehaviorBasedOnFieldOptions() {
    switch (true) {
      case this.group.fields?.[0]?.fieldTypeOptions?.findIndex((option: FormBuilderFieldTypeOption) => option.id === 12) !== -1:
        this.flexRow = true;
    }
  }

  checkIfGroupHasRequiredValidator(): boolean {
    let hasRequiredValidator = false;
    this.group.fields[0]?.fieldValidators?.manualValidators?.forEach((manualValidator) => {
      if (manualValidator.validatorKey === ValidatorTypeEnum.REQUIRED) {
        hasRequiredValidator = true;
      }
    });

    return hasRequiredValidator;
  }

  checkValidators() {
    if (this.checkIfGroupHasRequiredValidator()) {
      this.formControl.setValidators([this.customValidatorsService.radioGroupRequired(this.group?.groupOptions?.id?.toString())]);
      if (this.requiredOptionId) {
        this.formControl.addValidators([this.customValidatorsService.specificOptionSelected(this.requiredOptionId.toString())]);
      }
      this.formControl.updateValueAndValidity();
      this.isGroupRequired = true;
      this.shouldShowWarningText();
    } else {
      this.isGroupRequired = false;
      this.shouldShowWarningText();
    }
  }

  private shouldShowWarningText() {
    if (this.isGroupRequired) {
      this.showWarningText = !this.formControl.valid;
    }
  }

  get formControl() {
    return <FormControl>this.parentFormGroup?.controls[this.group?.groupOptions?.id];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
