<div fxLayout="column">
  <div fxLayout="row" fxFlex>
    <div fxFlex="25" *ngIf="pageViewState === 'draw'">
      <iapplication2-fields-sidebar [action]="sidebarAction.DRAW"></iapplication2-fields-sidebar>
    </div>
    <div fxFlex>
      <iapplication2-pdf-drawing
        *ngIf="currentPage"
        [page]="currentPage"
        (navigateToAnotherPage)="onNavigateToAnotherPage($event)"
      ></iapplication2-pdf-drawing>
    </div>
    <div fxFlex *ngIf="pageViewState === 'draw'">
      <iapplication2-field-details [formId]="formId"></iapplication2-field-details>
    </div>
    <div fxFlex="50" *ngIf="pageViewState === 'form'">
      <iapplication2-form-builder [formId]="formId"></iapplication2-form-builder>
    </div>
  </div>
</div>
<p-dialog
  *ngIf="isFieldDialogOpen"
  [modal]="true"
  [(visible)]="isFieldDialogOpen"
  [style]="{ width: '40vw', minWidth: '600px' }"
  showEffect="fade"
>
  <iapplication2-field-settings-modal
    [selectedFormBuilderItem]="selectedField"
    [modalType]="settingsDialogOptions"
    (closeDialog)="closeFieldDialog()"
  >
  </iapplication2-field-settings-modal>
</p-dialog>
<p-dialog
  *ngIf="isDisplayConditionsDialogOpen"
  [modal]="true"
  [(visible)]="isDisplayConditionsDialogOpen"
  [style]="{ width: '60vw' }"
  showEffect="fade"
>
  <iapplication2-display-conditions-modal (closeDialog)="closeDisplayConditionsDialog()"></iapplication2-display-conditions-modal>
</p-dialog>
