/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FieldCategoryService } from '@iapplication2/services';

export function categoryTitleDuplicationValidator(service: FieldCategoryService, currentValue: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      if (service.checkIfCategoryWithProvidedTitleExists(control.value, currentValue)) {
        return {
          duplicateCategoryTitle: true,
        };
      }
    }
  };
}
