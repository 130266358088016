import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerComponent } from './progress-spinner.component';
import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule as PrimeProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [CommonModule, DialogModule, SharedTranslateModule, PrimeProgressSpinnerModule],
  declarations: [ProgressSpinnerComponent],
  exports: [ProgressSpinnerComponent],
})
export class ProgressSpinnerModule {}
