import { Component, Input, OnInit } from '@angular/core';
import { Application } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-client-applications',
  templateUrl: './client-applications.component.html',
  styleUrls: ['./client-applications.component.scss'],
})
export class ClientApplicationsComponent implements OnInit {
  @Input() clientApplications: Application[];

  items = [];

  ngOnInit(): void {
    this.items = [
      {
        label: 'Applications options',
        items: [
          {
            label: 'Option 1',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Option 2',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
  }

  toggleMenu(application: Application, menu, ev) {
    ev.preventDefault();
    menu.toggle(ev);
  }
}
