import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { AmountFormatDirective } from './amount-format/amount-format.directive';
import { TitleFormatDirective } from './title-format/title-format.directive';
import { MultipleOfNumberDirective } from './multiple-of-number/title-format.directive';
import { OnlyNumbersDirective } from './only-numbers/only-numbers.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ClickOutsideDirective, AmountFormatDirective, TitleFormatDirective, MultipleOfNumberDirective, OnlyNumbersDirective],
  exports: [ClickOutsideDirective, AmountFormatDirective, TitleFormatDirective, MultipleOfNumberDirective, OnlyNumbersDirective],
})
export class DirectivesModule {}
