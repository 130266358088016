import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ApplicationProduct,
  FormBuilderFieldStaticFieldLink,
  LanguagesEnum,
  Province,
  RatesProductAdvisor,
  ReflexDataModel,
  ReflexStaticLinkQuestionEnum,
  StaticFieldLinkType,
} from '@iapplication2/interfaces';
import { ApplicationsProcessService, DatesService, DisclosuresService, StaticFieldLinkService } from '@iapplication2/services';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import Reflex from '@isi/reflex';
import { ActivatedRoute } from '@angular/router';
import _ = require('lodash');
import { TranslateService } from '@ngx-translate/core';

export interface ProductsResponse {
  products: RatesProductAdvisor[];
  displayProducts: RatesProductAdvisor[];
  productsEvolution: RatesProductAdvisor[];
}

@Component({
  selector: 'iapplication2-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
})
export class ProductSelectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup;
  @Input() loadedProducts: ApplicationProduct[];
  @Output() selectionChanged: EventEmitter<unknown> = new EventEmitter();
  @Output() isAnyHumaniaProductSelectedChanged: EventEmitter<boolean> = new EventEmitter();
  unsubscribe: Subject<unknown> = new Subject();
  dob: string;
  productResponse: ProductsResponse;
  evolutionProducts: RatesProductAdvisor[] = [];
  filteredEvolutionProducts: RatesProductAdvisor[] = [];
  applicationId: number;
  productList: RatesProductAdvisor[] = [];
  postalCode: string;
  province: Province;
  isEverestAllowed = false;
  everestMultiProductObject = {};
  isEverestSelectedOnAnyProduct = false;
  isAnyProductSelected = false;
  shouldProductsBeDisplayed = false;
  isProvinceValid = false;
  isDobValid = false;
  isSmokerValid = false;
  isSmokerStatusFirstSelected = false;
  isGenderValid = false;
  isPostalCodeValid = false;
  totalPremium = 0;
  dobChanged = true;
  smokerChanged = true;
  provinceChanged = true;
  postalCodeChanged = true;
  humaniaProductsCoverage = 0;
  humaniaTotalMaxCoverage = 0;
  isOnReflexDataSave = false;
  isAudioPlaying = false;
  isAnyHumaniaProductSelected = false;

  getProductsDebounced = _.debounce((dob: string) => this.getProductData(dob), 500);
  getEverestInfoDebounced = _.debounce(() => this.getEverestInfo(), 500);

  constructor(
    public applicationsProcessService: ApplicationsProcessService,
    private route: ActivatedRoute,
    private disclosuresService: DisclosuresService,
    private datesService: DatesService,
    private staticFieldLinkService: StaticFieldLinkService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.watchFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedProducts?.currentValue !== changes.loadedProducts?.previousValue) {
      this.selectLoadedProducts();
    }
  }

  calculateTotal() {
    this.totalPremium = 0;
    this.humaniaProductsCoverage = 0;
    this.productResponse?.displayProducts?.forEach((product) => {
      if (product?.iappProduct?.selected) {
        this.totalPremium += Number(product?.iappProduct?.monthlyPremium);
      }
    });

    this.productList.forEach((product) => {
      if (product?.iappProduct?.selected && product.isHumania) {
        this.humaniaProductsCoverage += Number(product.iappProduct.coverage);
      }
    });

    this.humaniaTotalMaxCoverage = this.productList.find((product) => product.isHumania)?.max_coverage;

    this.applicationsProcessService.isHumaniaCoverageOverMax.next(this.humaniaProductsCoverage > this.humaniaTotalMaxCoverage);
  }

  private checkIfProductsShouldBeDisplayed() {
    this.isProvinceValid = this.form.get('province').valid;
    this.isDobValid = this.form.get('birthDate').valid;
    this.isSmokerStatusFirstSelected = this.form.get('smokerStatus').valid !== this.isSmokerValid;
    this.isSmokerValid = this.form.get('smokerStatus').valid;
    this.isGenderValid = this.form.get('gender').valid;
    this.isPostalCodeValid = this.form.get('postalCode').valid;
    this.shouldProductsBeDisplayed = this.isDobValid && this.isGenderValid && this.isPostalCodeValid && this.isProvinceValid;

    if (this.shouldProductsBeDisplayed && this.dobChanged) {
      this.getProductsDebounced(this.dob);
    }

    if (this.shouldProductsBeDisplayed && this.smokerChanged) {
      this.getProductsDebounced(this.dob);
    }

    if (this.shouldProductsBeDisplayed && this.provinceChanged) {
      this.getEverestInfoDebounced();
      this.provinceChanged = false;
    }

    if (this.shouldProductsBeDisplayed && this.postalCodeChanged) {
      this.getEverestInfoDebounced();
      this.postalCodeChanged = false;
    }
  }

  private selectLoadedProducts() {
    if (this.loadedProducts && this.productResponse?.displayProducts) {
      this.productResponse?.displayProducts.forEach((product) => {
        const prod = this.loadedProducts.find((loadedProduct) => loadedProduct.productId === product.iappProduct.id);
        if (prod) {
          product.iappProduct.selected = true;
          product.coverage = prod.coverage;
          product.hasEverest = prod.withEverest;
        }
      });
      this.onSelectionChanged();
    }
  }

  startReflex() {
    if (this.shouldProductsBeDisplayed) {
      const reflex = new BehaviorSubject<ReflexDataModel>({} as ReflexDataModel);
      const audioApi = new BehaviorSubject<any>({} as any);
      const isReflexPage = new BehaviorSubject<boolean>(true);
      const data: ReflexDataModel | any = {
        address: this.form.value?.address,
        application_id: this.applicationId?.toString(),
        city: this.form.value?.city,
        coverage: null,
        dob: this.datesService.convertDayMonthYearToYearMonthDay(this.form.value?.birthDate),
        email: this.form.value?.email,
        first_name: this.form.value?.firstName,
        gender: this.form.value?.gender === 'Male' ? 'm' : this.form.value?.gender === 'Female' ? 'f' : undefined,
        language: this.translate.currentLang || LanguagesEnum.EN,
        last_name: this.form.value?.lastName,
        middle_name: this.form.value?.middleName,
        phone: this.form.value?.phoneNumber,
        postal_code: this.form.value?.postalCode,
        province: this.form.value.province?.abbreviation,
        extension: '904',
        serverPayload: undefined,
        risk: '',
        term: '',
        env: 'local',
        isCallCenter: 2,
        smoker: this.form.value?.smokerStatus === 'no' ? '0' : this.form.value.smokerStatus === 'yes' ? '1' : undefined,
        smokerFE: this.form.value?.reflexSmokerStatus === false ? '0' : this.form.value.reflexSmokerStatus === true ? '1' : undefined,
        product_version: '',
        product_code: '',
        force_GI: false,
        approved: false,
        products: '',
        add_everest: '1',
        showGP: undefined,
        hasUsedVoice: false,
      };

      // Checks if the application has reflex data saved
      this.applicationsProcessService
        .getReflexData(this.applicationId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (savedReflex: any) => {
            if (savedReflex) {
              if (savedReflex.last_question.answer) {
                savedReflex.last_question.answer = {
                  id: savedReflex.last_question.answer,
                  label: savedReflex.last_question.answer.toLowerCase(),
                };
              }
              data.serverPayload = savedReflex?.serverPayload || undefined;
              Reflex.mount(data);
            }
          },
          () => {
            Reflex.mount(data);
          }
        );

      Reflex.init({
        updateReflex(payload: any): void {
          reflex.next(payload);
        },
        exitReflex(payload: any): void {
          isReflexPage.next(false);
          reflex.next(payload);
        },
        callAudioApi(payload): void {
          audioApi.next(payload);
        },
      });

      isReflexPage.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
        this.applicationsProcessService.setReflexPage(value);
      });

      // Saves reflex data
      reflex.pipe(takeUntil(this.unsubscribe)).subscribe((reflexData) => {
        if (reflexData) {
          reflexData.application_id = this.applicationId?.toString();
          if (reflexData?.last_question?.answer.id) {
            reflexData.last_question = {
              answer: reflexData?.last_question.answer.id,
              question: reflexData?.last_question.question,
            };
          }
          this.applicationsProcessService
            .createOrUpdateReflexData(reflexData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
              next: () => {
                // TODO: IAPP2CA-1603 - removed functionality
                // this.prepareAndMapReflexStaticFieldLinkData(reflexData);
                if (reflexData.approved) {
                  this.isOnReflexDataSave = true;
                  if (reflexData.smoker !== null) {
                    this.form.get('reflexSmokerStatus').setValue(reflexData.smoker === '1');
                  }
                }
              },
            });
        }
      });
      audioApi.pipe(takeUntil(this.unsubscribe)).subscribe((payload) => {
        if (payload) {
          this.callAudioApi(payload);
        }
      });
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  callAudioApi(payload: any) {
    if (!this.isAudioPlaying) {
      this.disclosuresService.callJenieAudioApi(payload?.file, payload?.action).subscribe({
        next: () => {
          this.isAudioPlaying = !this.isAudioPlaying;
        },
      });
    } else {
      this.disclosuresService.callJenieAudioApi(payload?.file, payload?.action).subscribe({
        next: () => {
          this.isAudioPlaying = !this.isAudioPlaying;
        }
      });
    }
  }

  // TODO: IAPP2CA-1603 - removed functionality
  // prepareAndMapReflexStaticFieldLinkData(reflexData) {
  //   const reflexAnswersData = this.prepareReflexStaticFieldLinkData(reflexData);
  //   if (reflexAnswersData) {
  //     this.mapFormDataToStaticFieldLinks(reflexAnswersData);
  //   }
  // }

  // TODO: IAPP2CA-1603 - removed functionality
  // private prepareReflexStaticFieldLinkData(reflexData) {
  //   if (reflexData && reflexData.questions) {
  //     const birthLocationData = reflexData.questions[ReflexStaticLinkQuestionEnum.BIRTH_INFO]?.answer?.split(',');
  //     if (birthLocationData) {
  //       const locationDataContainStateOrProvince = birthLocationData.length === 2;
  //       const countryOfBirth = locationDataContainStateOrProvince ? birthLocationData[1]?.trim() : birthLocationData[0]?.trim();
  //       const provinceOrStateOfBirth = locationDataContainStateOrProvince ? birthLocationData[0].trim() : '';
  //       //The above code is needed because Reflex returns the country and province/state in the same field.
  //       //If a country other than Canada or the US is selected, the province/state part of the answer is not returned, so we need to check for that.
  //       const questionAnswers = {
  //         countryOfBirth: countryOfBirth,
  //         primaryProvinceBirth: provinceOrStateOfBirth,
  //         // The below values can't be mapped at this point due to answer inconsistency between reflex and the PDF
  //         // residencyStatus: reflexData.questions[ReflexStaticLinkQuestionEnum.RESIDENCY_STATUS]?.answer,
  //         // residencyStatusOther: reflexData.questions[ReflexStaticLinkQuestionEnum.RESIDENCY_STATUS]?.answer,
  //       };
  //       return questionAnswers;
  //     }
  //   }
  //   return null;
  // }

  // TODO: IAPP2CA-1603 - removed functionality
  // private mapFormDataToStaticFieldLinks(reflexAnswersData) {
  //   const mappableStaticFieldLinkItems: FormBuilderFieldStaticFieldLink[] =
  //     this.staticFieldLinkService.getMappableStaticFieldLinkItemsByType(StaticFieldLinkType.INSURED_PERSON);
  //
  //   mappableStaticFieldLinkItems.forEach((staticFieldLink: FormBuilderFieldStaticFieldLink) => {
  //     if (staticFieldLink.fieldKey) {
  //       const reflexDataKeyByIapp1Id = this.staticFieldLinkService.getReflexKeyByIapp1Id(staticFieldLink.iapp1Id);
  //       const answer = reflexAnswersData[reflexDataKeyByIapp1Id];
  //
  //       this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.INSURED_PERSON, reflexDataKeyByIapp1Id, answer);
  //     }
  //   });
  // }

  onSelectionChanged (product?) {
    if (product && this.productResponse?.displayProducts) {
      const displayProductIndex = this.productResponse.displayProducts.findIndex(
        (displayProduct) => displayProduct.id === product.id && displayProduct.iappProduct.id === product.iappProduct.id
      );

      if (this.productResponse.displayProducts[displayProductIndex]) {
        this.productResponse.displayProducts[displayProductIndex] = {
          ...this.productResponse.displayProducts[displayProductIndex],
          iappProduct: {
            ...this.productResponse.displayProducts[displayProductIndex].iappProduct,
            monthlyPremium: product.monthlyPremium,
            yearlyPremium: product.yearlyPremium,
            everestPremium: product.everestPremium,
            productVersion: product.productVersion,
            selected: !!product.iappProduct?.selected,
            coverage: product?.coverage,
            hasEverestAddon: product?.iappProduct.hasEverestAddon,
          },
        };
      }
    }
    this.isAnyProductSelected = this.productResponse?.displayProducts?.some((product) => product.iappProduct.selected);
    this.selectionChanged.emit(this.productResponse?.displayProducts);
    this.isAnyHumaniaProductSelected = this.productList?.some((product) => product.iappProduct.selected && product.isHumania);
    this.isAnyHumaniaProductSelectedChanged.emit(this.isAnyHumaniaProductSelected);
    this.calculateTotal();
  }

  private watchFormChanges() {
    this.form
      .get('birthDate')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.dob = res;
        this.dobChanged = true;
        this.checkIfProductsShouldBeDisplayed();
      });
    this.form
      .get('province')
      .valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe({
        next: () => {
          this.provinceChanged = true;
          this.checkIfProductsShouldBeDisplayed();
        },
      });
    this.form
      .get('postalCode')
      .valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), debounceTime(500))
      .subscribe({
        next: () => {
          this.postalCodeChanged = true;
          this.checkIfProductsShouldBeDisplayed();
        },
      });
    this.form
      .get('gender')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.checkIfProductsShouldBeDisplayed();
        },
      });
    this.form
      .get('smokerStatus')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.smokerChanged = true;
          this.checkIfProductsShouldBeDisplayed();
        },
      });
    this.form
      .get('reflexSmokerStatus')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.smokerChanged = true;
          this.checkIfProductsShouldBeDisplayed();
        },
      });
  }

  private getProductData(birthDate: string) {
    if (birthDate) {
      this.applicationsProcessService
        .getProductsByClientDOB(birthDate)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((result: ProductsResponse) => {
          if (this.dobChanged) {
            this.productResponse = result;
            this.dobChanged = false;
          }
          if (this.smokerChanged) {
            this.smokerChanged = false;
          }
          this.evolutionProducts = result?.productsEvolution;
          if (this.isOnReflexDataSave) {
            this.displayProducts(true);
            this.isOnReflexDataSave = false;
          } else {
            this.displayProducts();
          }
        });
    }
  }

  displayProducts(isReflexSave = false) {
    if (this.productResponse) {
      const previousProductList = this.productResponse.displayProducts || this.productList;
      this.productList = [];
      this.productResponse.displayProducts = [];
      this.productResponse.productsEvolution
        .filter((product) => product.iappProduct)
        .forEach((product) => {
          this.productResponse.displayProducts.push(product);
        });
      if (this.isSmokerValid) {
        this.productResponse.products
          .filter((product) => product.iappProduct)
          .forEach((product) => {
            this.productResponse.displayProducts.push(product);
          });
        if (!this.productList.length) {
          this.productList = this.productResponse.products.filter((product) => product.iappProduct);
        }

        if (this.isSmokerStatusFirstSelected) {
          this.productList = [...this.productList, ...this.productResponse.products.filter((product) => product.iappProduct)];
        }

        this.productList = _.uniqBy(this.productList, 'id');

        this.productList.sort(
          (product1: RatesProductAdvisor, product2: RatesProductAdvisor) => product1.iappProduct.position - product2.iappProduct.position
        );
        this.productList.forEach((product) => {
          this.everestMultiProductObject[product.iappProduct.id] = false;
        });

        if (previousProductList && !this.dobChanged) {
          previousProductList.forEach((product) => {
            const index = this.productList.findIndex((obj) => obj.id === product.id);
            if (index !== -1) {
              this.productList[index] = { ...this.productList[index], ...product };
            }
          });
        }
      }

      this.selectLoadedProducts();
      this.displayEvolutionProducts(isReflexSave);
    }
  }

  getEverestInfo() {
    this.province = this.form.get('province').value as Province;
    this.postalCode = this.form.get('postalCode').value;

    if (this.province && this.postalCode) {
      this.applicationsProcessService
        .getEverestInfo({ province: this.province.abbreviation, postal_code: this.postalCode })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: any) => {
          this.isEverestAllowed = !!res.data.length;
        });
    }
  }

  onEverestValueUpdated(event) {
    const newEverestMultiProductObject = { ...this.everestMultiProductObject };
    Object.keys(newEverestMultiProductObject).forEach((key) => {
      if (key) {
        newEverestMultiProductObject[key] = false;
      }
    });
    if (event) {
      newEverestMultiProductObject[event] = true;
    }

    this.everestMultiProductObject = { ...newEverestMultiProductObject };
    this.isEverestSelectedOnAnyProduct = Object.values(this.everestMultiProductObject).some((value) => value === true);
  }

  displayEvolutionProducts(isReflexSave: boolean) {
    this.applicationsProcessService
      .getReflexData(this.applicationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((savedReflex: any) => {
        if (savedReflex.approved) {
          // TODO: IAPP2CA-1603 - removed functionality
          // this.prepareAndMapReflexStaticFieldLinkData(savedReflex);
          this.filterEvolutionProducts(savedReflex?.products, isReflexSave);
        }
      });
  }

  onResetEverestValue(event) {
    if (this.everestMultiProductObject[event] === true) {
      const newEverestMultiProductObject = { ...this.everestMultiProductObject };
      Object.keys(newEverestMultiProductObject).forEach((key) => {
        newEverestMultiProductObject[key] = false;
      });
      this.everestMultiProductObject = { ...newEverestMultiProductObject };
      this.isEverestSelectedOnAnyProduct = Object.values(this.everestMultiProductObject).some((value) => value === true);
    }
  }

  private filterEvolutionProducts(resultedProducts: any, isOnReflexDataSave = false): void {
    if (resultedProducts) {
      const filteredProducts = [];
      this.evolutionProducts?.forEach((product) => {
        resultedProducts?.forEach((item) => {
          if (item.product_id === product.code) {
            product = {
              ...product,
              coverage: this.getProductCoverageForReflexiveProducts(product, item, isOnReflexDataSave),
              hasEverest: item.hasEverest,
            };
            if (product.iappProduct) {
              if (isOnReflexDataSave && item.coverage > 0) {
                product.iappProduct.selected = true;
              }
              filteredProducts.push(product);
            }
          }
        });
      });

      if (this.filteredEvolutionProducts && this.isSmokerValid) {
        filteredProducts.forEach((filteredProduct) => {
          const productToCompare = this.filteredEvolutionProducts.find((product) => product.id === filteredProduct.id);

          if (productToCompare) {
            filteredProduct.iappProduct.selected = productToCompare?.iappProduct?.selected;
            filteredProduct.iappProduct.coverage = productToCompare?.iappProduct?.coverage;
            filteredProduct.iappProduct.hasEverestAddon = productToCompare?.iappProduct?.hasEverestAddon;
          }
        });
      }

      if (filteredProducts?.length && isOnReflexDataSave) {
        this.filteredEvolutionProducts = [...filteredProducts];
      } else {
        this.filteredEvolutionProducts = [...this.filteredEvolutionProducts, ...filteredProducts];
      }

      this.filteredEvolutionProducts.forEach((product) => {
        const compareProduct = filteredProducts.find((item) => item.id === product.id);
        if (compareProduct) {
          product = compareProduct;
        }
      });

      /* remove duplicates */
      this.filteredEvolutionProducts = _.uniqBy(this.filteredEvolutionProducts, 'id');

      this.filteredEvolutionProducts.forEach((product) => {
        this.everestMultiProductObject[product.iappProduct.id] = false;
        product.isHumania = true;
      });
      if (isOnReflexDataSave) {
        const everestReflexProduct = this.filteredEvolutionProducts.find((product) => product.hasEverest);
        if (Object.values(this.everestMultiProductObject).every((value) => value === false)) {
          if (everestReflexProduct?.iappProduct?.id) {
            this.everestMultiProductObject[everestReflexProduct?.iappProduct?.id] = true;
          }
        }
      } else {
        this.loadedProducts?.forEach((loadedProduct) => {
          const evolutionProduct = this.filteredEvolutionProducts.find((product) => product.iappProduct.id === loadedProduct.productId);
          if (evolutionProduct) {
            evolutionProduct.hasEverest = loadedProduct.withEverest;
            evolutionProduct.withEverest = loadedProduct.withEverest;
            evolutionProduct.hasEverestAddon = loadedProduct.withEverest;
          }
        });
        const everestReflexProduct = this.filteredEvolutionProducts.find((product) => product.withEverest);
        if (Object.values(this.everestMultiProductObject).every((value) => value === false)) {
          if (everestReflexProduct?.iappProduct?.id) {
            this.everestMultiProductObject[everestReflexProduct?.iappProduct?.id] = true;
          }
        }
      }
      if (this.filteredEvolutionProducts) {
        const filteredEvolutionProductsAlreadyInTheList = this.filteredEvolutionProducts.filter((filteredEvolutionProduct) =>
          this.productList.find((product) => product.iappProduct.id === filteredEvolutionProduct.iappProduct.id)
        );
        this.productList = _.uniqBy(this.productList, 'id');

        // remove all products from productList that are already in the list
        this.productList = this.productList.filter(
          (product) =>
            !filteredEvolutionProductsAlreadyInTheList.find(
              (filteredEvolutionProduct) => filteredEvolutionProduct.iappProduct.id === product.iappProduct.id
            )
        );
        this.productList = [...this.productList, ...this.filteredEvolutionProducts];
      }
    }
  }

  getProductCoverageForReflexiveProducts(product: RatesProductAdvisor, item: any, isOnReflexDataSave): number {
    if (!product.iappProduct?.selected && !isOnReflexDataSave) {
      return product.min_coverage;
    }
    if (product.coverage) {
      return product.coverage;
    }
    return item.coverage === 0 ? product.min_coverage : item.coverage;
  }

  private unloadReflex() {
    const oldReflexRoot = document.getElementById('reflex-root');
    oldReflexRoot?.remove();
    const body = document.getElementsByTagName('body')[0];
    const newReflexRoot = document.createElement('div');
    newReflexRoot.setAttribute('id', 'reflex-root');
    body.appendChild(newReflexRoot);
  }

  ngOnDestroy(): void {
    this.unloadReflex();
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
