import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { ENV, QuickQuote } from '@iapplication2/interfaces';

@Injectable({
  providedIn: 'root',
})
export class QuickQuoteManagementService {
  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  getQuickQuotes(page?: number, limit?: string, sort?: string, order?: string, globalSearchValue?: string) {
    const url = 'quotes/query-data?';
    let pageKeyValue = '';
    let sortKeyValye = '';
    let orderKeyValye = '';
    let limitKeyValye = '&take=5';
    let globalSearchValueKeyValue = '';
    if (page) {
      pageKeyValue = `page=${page}`;
    }
    if (limit) {
      limitKeyValye = `&take=${limit}`;
    }
    if (sort) {
      sortKeyValye = `&sort=${sort}`;
    }
    if (order) {
      orderKeyValye = `&order=${order}`;
    }
    if (globalSearchValue) {
      pageKeyValue = `page=0`;
      globalSearchValueKeyValue = `&q=${globalSearchValue}`;
    }

    return this.http.get(url + pageKeyValue + limitKeyValye + sortKeyValye + orderKeyValye + globalSearchValueKeyValue, {
      observe: 'response',
    });
  }

  createQuickQuote(quickQuote: QuickQuote) {
    const url = 'quotes/query-data';
    return this.http.post(url, quickQuote);
  }

  updateQuickQuote(quickQuote: QuickQuote) {
    const url = 'quotes/query-data/';
    return this.http.put(`${url}${quickQuote.id}`, quickQuote);
  }

  getQuickQuoteById(id: number) {
    const url = 'quotes/query-data/';
    return this.http.get(`${url}${id}`);
  }

  deleteQuickQuoteById(id: number) {
    const url = 'quotes/query-data/';
    return this.http.delete(`${url}${id}`);
  }

  getQuickQuoteProductList(quickQuote: QuickQuote) {
    const url = 'quotes';
    return this.http.post(url, quickQuote);
  }
}
