<div class="layout-wrapper layout-sidebar p-input-filled" [ngClass]="getLayoutClasses()">
  <iapplication2-topbar *ngIf="!isReflexPage" [isLoginPage]="!isAuthenticated"></iapplication2-topbar>
  <div class="layout-main">
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="layout-mask modal-in"></div>
</div>
