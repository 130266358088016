import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ApplicationsProcessService, AuthService, DarkThemeService, MenuActionsService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';
import { LanguagesEnum, LocalStorageItems, Themes } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isMenuStatic: boolean;

  isMobileActive: boolean;

  isLightTheme: boolean;

  isAuthenticated = false;

  isReflexPage = false;

  unsubscribe: Subject<unknown> = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private menuActionsService: MenuActionsService,
    private darkThemeService: DarkThemeService,
    private authService: AuthService,
    private applicationsProcessService: ApplicationsProcessService
  ) {
    this.setInterfaceLanguage();
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  ngOnInit() {
    const headEl = this.document.getElementsByTagName('head')[0];
    const newLinkEl = this.document.createElement('link');
    newLinkEl.rel = 'stylesheet';
    this.applicationsProcessService
      .getIsReflexPage()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: boolean) => {
        this.isReflexPage = value;
        if (value) {
          newLinkEl.href = './assets/custom/css/reflex.css';
          headEl?.appendChild(newLinkEl);
        } else {
          if (newLinkEl.href && headEl?.contains(newLinkEl)) headEl?.removeChild(newLinkEl);
        }
      });

    if (this.authService.isAuthenticated()) {
      this.authService.getUserInformation();
    }

    this.menuActionsService.staticMenu.pipe(takeUntil(this.unsubscribe)).subscribe((isStatic) => {
      this.isMenuStatic = isStatic;
    });

    this.menuActionsService.staticMenu.pipe(takeUntil(this.unsubscribe)).subscribe((isActive) => {
      this.isMobileActive = isActive;
    });

    this.isLightTheme = JSON.parse(localStorage.getItem('isLightTheme'));

    this.darkThemeService.themeChanged.pipe(takeUntil(this.unsubscribe)).subscribe((theme) => {
      this.changeLightModeClasses(theme);
    });

    this.authService.authStatusChanged.pipe(takeUntil(this.unsubscribe)).subscribe((status) => {
      this.isAuthenticated = status;
    });

    this.listenToLanguageChange();
    this.setInterfaceLanguageBasedOnApplicationLanguage();
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => this.config.setTranslation(res));
  }

  getLayoutClasses() {
    return {
      'layout-static': this.isMenuStatic,
      'layout-mobile-active': this.isMobileActive,
      'layout-topbar-light': this.isLightTheme,
      'layout-menu-light': this.isLightTheme,
      'layout-topbar-dark': !this.isLightTheme,
      'layout-menu-dark': !this.isLightTheme,
    };
  }

  setInterfaceLanguage() {
    this.translateService.addLangs([LanguagesEnum.FR, LanguagesEnum.EN]);
    const localStorageLanguage = localStorage.getItem(LocalStorageItems.LANGUAGE);

    if (localStorageLanguage) {
      this.translateService.use(localStorageLanguage);
    } else {
      this.translateService.use(LanguagesEnum.EN);
      localStorage.setItem(LocalStorageItems.LANGUAGE, LanguagesEnum.EN);
    }
  }

  listenToLanguageChange() {
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res) => {
        this.translate(res.lang);
      },
    });
  }

  setInterfaceLanguageBasedOnApplicationLanguage() {
    this.applicationsProcessService.applicationChanged.pipe(takeUntil(this.unsubscribe)).subscribe((application) => {
      if (application?.language?.code && application.language.code !== this.translateService.currentLang) {
        this.translateService.use(application.language.code);
        localStorage.setItem(LocalStorageItems.LANGUAGE, application.language.code);
      }
    });
  }

  public changeLightModeClasses(theme: string) {
    this.isLightTheme = theme === Themes.LIGHT;
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
