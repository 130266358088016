import { FormBuilderField } from './form-builder-field.model';

export interface FormArea {
  id: number;
  formSectionId?: number;
  width: FormAreaWidthOptions;
  title: string;
  lokaliseKey?: string;
  position?: number;
  fields?: FormBuilderField[];
  [key: string]: any;
}

export enum FormAreaWidthOptions {
  FULL = 'full',
  HALF = 'half',
}

export enum FormAreaId {
  UNASSIGNED = 'unassigned-fields',
}
