import { ApplicationStatus } from './application-status.model';

export enum SignatureTypeEnum {
  VOICESIGNATURE = 'voiceSignature',
  ESIGNATURE = 'eSignature',
}
export enum SignatureRecipientTypeEnum {
  INSURED = 'insured',
  PAYOR = 'payor',
  OWNER = 'owner',
  ADVISOR = 'advisor',
}

export enum SignaturePayorTypeEnum {
  OWNER = 'owner',
  INSURED = 'insured',
  OTHER = 'other',
}

export enum SignatureChanelTypeEnum {
  EMAIL = 'email',
  SMS = 'sms',
  VOICESIGNATURE = 'voice_signature',
}

export enum SignatureStaticLinkRecipientTypeEnum {
  PAYOR = 'signature-payor',
  OWNER = 'signature-owner',
}

export enum SignatureStatusEnum {
  PENDING = 'pending',
  SIGNED = 'signed',
  EXPIRED = 'expired',
}

export interface IApplicationDigitalSignature {
  id: number;
  signatureFor: SignatureRecipientTypeEnum;
  phone?: string;
  email?: string;
  fullName?: string;
  emailErrors?: boolean;
  phoneErrors?: boolean;
  chanelTypeErrors?: boolean;
  createdAt: Date;
  emailSent?: Date;
  guid?: string;
  expiryDate?: Date;
  method: SignatureChanelTypeEnum[];
  resendedAt?: Date;
  signedAt?: Date;
  smsSent?: Date;
  status: SignatureStatusEnum;
  applicationStatus?: ApplicationStatus;
  updatedAt?: Date;
  isSignatureChanged?: boolean;
  isSubmitDisabled?: boolean;
}

export interface ISignaturePayload {
  method: SignatureChanelTypeEnum[];
  signatureFor: SignatureRecipientTypeEnum;
}
