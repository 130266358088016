<div class="flex">
  <form class="flex" [formGroup]="localSearchForm" (submit)="searchWithProvidedQuery()">
    <div class="p-inputgroup mr-2">
      <input
        class="p-inputtext p-component"
        pInputText
        style="max-width: 200px"
        type="text"
        placeholder="{{ 'pages.globalSearch.searchField.placeholder' | translate }}"
        [value]="localSearchForm.controls['searchQuery'].value ? localSearchForm.controls['searchQuery'].value : ''"
        formControlName="searchQuery"
      />
      <button
        type="submit"
        pButton
        pRipple
        icon="pi pi-search"
        styleClass="p-button-warn"
        [disabled]="!localSearchForm.controls['searchQuery'].value"
      ></button>
    </div>
    <p-multiSelect
      [options]="availableEntities"
      formControlName="selectedEntities"
      optionLabel="header"
      [selectedItemsLabel]="'pages.globalSearch.searchField.selectedEntities' | translate: { number: '{0}' }"
      [style]="{ minWidth: '200px' }"
      (onChange)="multiSelectChanged()"
    >
    </p-multiSelect>
  </form>
</div>
