/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Notification } from '@iapplication2/interfaces';
import { NotificationsService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  allNotifications: Notification[];
  unsubscribe: Subject<unknown> = new Subject();
  page: number = 0;
  resultsPerPage: number = 20;

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.getNotifications();
    window.setInterval(() => {
      this.getNotifications();
    }, 10000);
  }

  getNotifications() {
    this.notificationsService
      .getPaginatedNotifications(this.page, this.resultsPerPage)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: Notification[]) => {
        this.allNotifications = res;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
