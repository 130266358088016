export interface Gender {
  label: SexEn | SexFr;
  value: SexEn;
}

export enum Sex {
  MALE = 'male',
  FEMALE = 'female',
}

export enum SexEn {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum SexFr {
  MALE = 'Masculin',
  FEMALE = 'Féminin',
}
