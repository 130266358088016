export abstract class KeyboardEventsConstants {
  static readonly DELETE_KEY = 'Delete';
  static readonly UP_ARROW = 'ArrowUp';
  static readonly DOWN_ARROW = 'ArrowDown';
  static readonly LEFT_ARROW = 'ArrowLeft';
  static readonly RIGHT_ARROW = 'ArrowRight';
  static readonly C_KEY = 'c' || 'C';
  static readonly C_CODE = 'KeyC';
  static readonly V_KEY = 'v' || 'V';
  static readonly V_CODE = 'KeyV';
}
