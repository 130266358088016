export interface FormBuilderFieldOptions {
  id?: number;
  customFieldName: string;
  customFieldLabel: string;
  lokaliseKey?: string;
  fieldDetails?: string;
  visibleOnPdf: boolean;
}

export enum FieldDetailsOptions {
  MARKED_AS_DEFAULT = 'markedAsDefault',
  MARKED_AS_REQUIRED = 'markedAsRequired',
}
