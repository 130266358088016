<div class="fieldDetails">
  <iapplication2-field-details-field
    *ngIf="
      (localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.FIELD) &&
      !(localSelectedFormBuilderItem | cast: FormBuilderFieldType | fieldTypeCheck: FormBuilderFieldTypeTypeEnum.TABLE)
    "
    [isInCreation]="true"
    [isInEditMode]="isInEditMode"
    [field]="localSelectedFormBuilderItem | cast: FormBuilderFieldType"
    [mainForm]="fieldDetailsForm"
    (invalidForm)="formValidityChanged($event)"
  >
  </iapplication2-field-details-field>
  <iapplication2-field-details-attachment
    *ngIf="localSelectedFormBuilderItem | cast: FormBuilderFieldType | fieldTypeCheck: FormBuilderFieldTypeTypeEnum.IMAGE"
    [isInEditMode]="isInEditMode"
    [selectedField]="localSelectedFormBuilderItem | cast: FormBuilderFieldType"
  >
  </iapplication2-field-details-attachment>
  <iapplication2-field-details-category
    *ngIf="modalType === modalTypeEnum.CREATE"
    [isInEditMode]="isInEditMode"
    [form]="fieldCategoryForm"
  ></iapplication2-field-details-category>
  <iapplication2-field-details-group
    *ngIf="localSelectedFormBuilderItem | cast: FormBuilderGroupType | itemTypeCheck: FormBuilderItemEnum.GROUP"
    [isInFieldBuilder]="isInFieldBuilder"
    [isInEditMode]="isInEditMode"
    [modalType]="modalType"
    [fieldGroup]="localSelectedFormBuilderItem | cast: FormBuilderGroupType"
    [mainForm]="fieldDetailsForm"
    [isInModal]="true"
    (modifiedGroup)="localSelectedFormBuilderItemWasModified($event)"
  >
  </iapplication2-field-details-group>
  <iapplication2-field-details-type
    *ngIf="!(localSelectedFormBuilderItem | cast: FormBuilderFieldType | fieldTypeCheck: FormBuilderFieldTypeTypeEnum.TABLE)"
    [isInFieldBuilder]="isInFieldBuilder"
    [isInEditMode]="isInEditMode"
    [isInModal]="true"
    [selectedField]="localSelectedFormBuilderItem | cast: FormBuilderFieldType"
    (options)="optionsWereAddedToTheField($event)"
    (entries)="entriesWereAddedToTheField($event)"
  >
  </iapplication2-field-details-type>
  <iapplication2-field-disclosure-details
    *ngIf="
      localSelectedFormBuilderItem?.groupOptions?.type?.type === FormBuilderFieldTypeTypeEnum.DISCLOSURE ||
      localSelectedFormBuilderItem?.fieldType?.type === FormBuilderFieldTypeTypeEnum.DISCLAIMER
    "
    [item]="localSelectedFormBuilderItem"
    [isInFieldBuilder]="isInFieldBuilder"
    [isInEditMode]="isInEditMode"
  ></iapplication2-field-disclosure-details>
  <iapplication2-field-details-validator
    *ngIf="selectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.GROUP"
    [isInEditMode]="isInEditMode"
    [isInModal]="true"
    [item]="localSelectedFormBuilderItem | cast: FormBuilderGroupType"
  ></iapplication2-field-details-validator>
  <iapplication2-field-details-validator
    *ngIf="selectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.FIELD"
    [isInEditMode]="isInEditMode"
    [isInModal]="true"
    [item]="localSelectedFormBuilderItem | cast: FormBuilderFieldType"
  ></iapplication2-field-details-validator>
  <iapplication2-field-details-table
    *ngIf="localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.TABLE"
    [isInEditMode]="isInEditMode"
    [isInCreation]="true"
    [table]="localSelectedFormBuilderItem | cast: FormBuilderTableType"
    [mainForm]="fieldDetailsForm"
    (invalidForm)="formValidityChanged($event)"
  ></iapplication2-field-details-table>
  <iapplication2-field-details-static-text
    *ngIf="selectedFormBuilderItem | cast: FormBuilderFieldType | fieldTypeCheck: FormBuilderFieldTypeTypeEnum.STATICTEXT"
    [staticTextField]="localSelectedFormBuilderItem"
  >
  </iapplication2-field-details-static-text>
  <iapplication2-field-details-static-field-link
    *ngIf="!(selectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.TABLE)"
    [isInEditMode]="isInEditMode"
    [selectedField]="localSelectedFormBuilderItem | cast: FormBuilderFieldType"
  ></iapplication2-field-details-static-field-link>
  <div class="fieldDetails-footer mt-4">
    <div *ngIf="isInFieldBuilder && modalType === modalTypeEnum.CREATE">
      <button
        pButton
        pRipple
        label="Create predefined field"
        (click)="createCustomField()"
        *ngIf="localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.FIELD"
        [disabled]="!formValidity"
      ></button>
      <button
        pButton
        pRipple
        label="Create predefined table"
        (click)="createCustomField()"
        *ngIf="localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.TABLE"
        [disabled]="!formValidity || localSelectedFormBuilderItem.columns?.length < 2"
      ></button>
      <button
        pButton
        pRipple
        label="Create predefined group"
        (click)="createCustomField()"
        *ngIf="localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.GROUP"
        [disabled]="!formValidity || selectedFormBuilderItem.fields?.length === 0"
      ></button>
    </div>
    <div *ngIf="!isInFieldBuilder && modalType === modalTypeEnum.DRAW">
      <button
        pButton
        pRipple
        label="Start drawing this field"
        (click)="startDrawingWithSelectedField()"
        *ngIf="localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.FIELD"
      ></button>
      <button
        pButton
        pRipple
        label="Start drawing fields in this group"
        (click)="startDrawingFieldsInThisGroup()"
        *ngIf="localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.GROUP"
        [disabled]="selectedFormBuilderItem?.fields?.length === 0"
      ></button>
      <button
        pButton
        pRipple
        label="Start drawing fields in this table"
        (click)="startDrawingFieldsInThisTable()"
        *ngIf="localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.TABLE"
        [disabled]="
          (localSelectedFormBuilderItem | cast: FormBuilderTableType).columns?.length < 2 ||
          !(localSelectedFormBuilderItem | cast: FormBuilderTableType).name ||
          !(localSelectedFormBuilderItem | cast: FormBuilderTableType).numberOfRows
        "
      ></button>
    </div>
    <div *ngIf="!isInFieldBuilder && modalType === modalTypeEnum.FORM">
      <button
        pButton
        pRipple
        label="Add field"
        (click)="addField()"
        *ngIf="
          !(localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.TABLE) &&
          !(localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.GROUP) &&
          !(localSelectedFormBuilderItem | cast: FormBuilderFieldType | fieldTypeCheck: FormBuilderFieldTypeTypeEnum.STATICTEXT)
        "
      ></button>
      <button
        pButton
        pRipple
        label="Add static text"
        (click)="addField()"
        *ngIf="
          !(localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.TABLE) &&
          !(localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.GROUP) &&
          (localSelectedFormBuilderItem | cast: FormBuilderFieldType | fieldTypeCheck: FormBuilderFieldTypeTypeEnum.STATICTEXT)
        "
      ></button>
      <button
        pButton
        pRipple
        label="Add group"
        (click)="addGroup()"
        *ngIf="localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.GROUP"
      ></button>
    </div>
    <div *ngIf="isInEditMode && modalType === modalTypeEnum.EDIT">
      <button
        pButton
        pRipple
        label="Save changes"
        (click)="saveChanges(localSelectedFormBuilderItem)"
        *ngIf="!(localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.TABLE)"
      ></button>
    </div>
    <div *ngIf="isInEditMode && modalType === modalTypeEnum.EDIT_PREDEFINED">
      <button
        pButton
        pRipple
        label="Save column changes"
        (click)="saveColumnChanges(localSelectedFormBuilderItem)"
        *ngIf="!(localSelectedFormBuilderItem | itemTypeCheck: FormBuilderItemEnum.TABLE)"
      ></button>
    </div>
  </div>
</div>
