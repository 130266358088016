<div>
  <p-table [value]="policies" [lazy]="true" [scrollable]="true" [loading]="loading" scrollHeight="200px" [autoLayout]="true" #customtable>
    <ng-template pTemplate="header">
      <tr>
        <th class="med-col">
          {{ 'pages.dashboard.sections.recentPolicies.tabs.recentPolicies.table.columns.client' | translate }}
        </th>
        <th class="med-col">
          {{ 'pages.dashboard.sections.recentPolicies.tabs.recentPolicies.table.columns.premium' | translate }}
        </th>
        <th>
          {{ 'pages.dashboard.sections.recentPolicies.tabs.recentPolicies.table.columns.products' | translate }}
        </th>
        <th class="small-col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-policy>
      <tr>
        <td class="med-col" [highlight]="searchedString" [innerHTML]="policy.client.firstName + ' ' + policy.client.lastName"></td>
        <td
          class="med-col"
          [highlight]="searchedString"
          [innerHTML]="policy.currency.symbol + policy.premium + policy.currency.name + '/m'"
        ></td>
        <td>
          <ul>
            <li *ngFor="let product of policy.products">
              <b [highlight]="searchedString" [innerHTML]="product.name"> </b>
              <span *ngIf="product.addons.length > 0">
                (<span *ngFor="let addon of product.addons; let i = index" class="addon-text">
                  <span
                    *ngIf="i < product.addons.length - 1"
                    [highlight]="searchedString"
                    [innerHTML]="addon.name + '<span>,</span>'"
                  ></span>
                  <span *ngIf="i >= product.addons.length - 1" [highlight]="searchedString" [innerHTML]="addon.name"></span> </span
                >)
              </span>
            </li>
          </ul>
        </td>
        <td class="small-col">
          <p-button icon="pi pi-cog" class="p-button-secondary" (click)="toggleMenu(policy, menu, $event)"></p-button>
          <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="pt-3 pl-2">
    <a [routerLink]="['/applications']" [queryParams]="{ tab: 'policies' }"
      >{{ 'pages.dashboard.sections.recentPolicies.tabs.recentPolicies.seeAll' | translate }}
      <i class="pi pi-arrow-right" style="font-size: 0.8rem"></i
    ></a>
  </div>
</div>
