import { Component, Input, OnInit } from '@angular/core';
import { AttachedFile, Severity } from '@iapplication2/interfaces';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApplicationSummaryService } from '@iapplication2/services';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'iapplication2-application-attachments',
  templateUrl: './application-attachments.component.html',
  styleUrls: ['./application-attachments.component.scss'],
})
export class ApplicationAttachmentsComponent implements OnInit {
  @Input() applicationAttachments: AttachedFile[];

  private unsubscribe: Subject<unknown> = new Subject<unknown>();
  applicationId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private applicationSummaryService: ApplicationSummaryService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
  }

  onAddAttachments(event, form) {
    if (this.applicationAttachments?.length <= 5) {
      const uploadedFile = event.files[0];
      form.clear();

      this.applicationSummaryService
        .createAttachmentByApplicationId(this.applicationId, uploadedFile)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (file: AttachedFile) => {
            this.applicationAttachments.push(file);
          },
          complete: () => {
            this.messageService.add({ severity: Severity.INFO, summary: 'File uploaded', detail: '' });
          },
          error: () => {
            this.messageService.add({ severity: Severity.ERROR, summary: 'File upload failed', detail: '' });
          },
       });
    }
  }

  deleteFile(attachedFile: AttachedFile) {
    this.applicationSummaryService
      .deleteApplicationAttachmentsById(attachedFile.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.applicationAttachments = this.applicationAttachments.filter((file) => file.id !== attachedFile.id);
      });
  }
}
