import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { GenderEnum, Product, SummaryPageInfo } from '@iapplication2/interfaces';
import { takeUntil } from 'rxjs/operators';
import { ApplicationsProcessService } from '@iapplication2/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-products-information',
  templateUrl: './products-information.component.html',
  styleUrls: ['./products-information.component.scss'],
})
export class ProductsInformationComponent implements OnChanges {
  @Input() summaryInformation: SummaryPageInfo;
  @Output() upsellProductsSelectionChanged: EventEmitter<unknown> = new EventEmitter();

  unsubscribe: Subject<unknown> = new Subject();
  products: Product[] = [];
  coverageForm: FormGroup = new FormGroup({});
  ratesSteps = [1000, 2000, 4600, 5000, 10000, 15000, 25000];
  monthlyPremium: string;
  yearlyPremium: string;

  constructor(private applicationsProcessService: ApplicationsProcessService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.summaryInformation && changes.summaryInformation.currentValue) {
      this.summaryInformation = changes.summaryInformation.currentValue;
      this.products = this.summaryInformation.upsellProducts;

      if (this.products) {
        this.products.sort((a, b) => a.id - b.id);
        this.products?.forEach((product) => {
          if (product.iappProduct) {
            product.selected = false;
            this.getProductRates(product);
          }
        });
        this.initializeFormControls();
      }
    }
  }

  initializeFormControls() {
    this.products?.forEach((product) => {
      this.getProductRates(product);
      this.coverageForm.addControl(`coverage-${product.id}`, new FormControl(product?.coverage || 5000));
      this.coverageForm.addControl(`everest-${product.id}`, new FormControl(product?.withEverest || false));
      this.onInputChange(product?.coverage, product);
    });
  }

  changedDropdown(event, product) {
    this.getProductCoverage(product)?.setValue(event.value);
    this.getProductRates(product);
  }

  addProduct(event, productId) {
    this.products?.forEach((product) => {
      if (product.productId === productId) {
        product.selected = event.checked;
        this.upsellProductsSelectionChanged.emit(product);
      }
    });
  }

  onInputChange(value, product: Product) {
    if (value && product) {
      if (!this.ratesSteps.includes(value)) {
        this.ratesSteps.push(value);
      }
      this.ratesSteps.sort((a, b) => a - b);
      this.getProductCoverage(product)?.setValue(value);
      this.getProductRates(product);
    }
  }

  private getProductRates(product: Product) {
    const body = {
      dob: this.summaryInformation?.client?.birthDate,
      smoker: this.summaryInformation?.client?.smokerStatus === 'no' ? 'n' : 'y',
      gender: this.summaryInformation?.client?.gender === GenderEnum.Male ? 'm' : 'f',
      benefit_amount: this.getProductCoverage(product)?.value || product?.coverage || 5000,
      add_everest: this.getEverestValueForProduct(product)?.value || product.withEverest || false,
      province: this.summaryInformation?.client?.province?.abbreviation,
      product_code: product?.code,
    };

    if (product?.iappProduct?.productType && body) {
      this.applicationsProcessService
        .getProductRates(product?.iappProduct?.productType, body)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: any) => {
          const premium = this.getMonthlyRate(res);

          this.products?.forEach((item) => {
            if (item.id === product.id) {
              item.monthlyPremium = Number(premium?.toFixed(2));
              item.yearlyPremium = Number((parseInt(premium?.toFixed(2)) * 12)?.toFixed(2));
            }
          });
        });
    }
  }

  private getMonthlyRate(response: any): number {
    const key = Object.keys(response.data.rate)[0];
    return response.data.rate[key]?.premium;
  }

  getProductCoverage(product): AbstractControl {
    return this.coverageForm.get('coverage-' + product.id);
  }

  getEverestValueForProduct(product): AbstractControl {
    return this.coverageForm.get('everest-' + product.id);
  }
}
