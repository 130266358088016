import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  FieldTable,
  FormBuilderField,
  FormBuilderFieldTypeOption,
  FormBuilderGroup,
  FormBuilderItem,
  FormBuilderStaticText,
} from '@iapplication2/interfaces';
import { ApplicationsProcessService, InteractiveFormBuilderService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-form-display-item',
  templateUrl: './form-display-item.component.html',
  styleUrls: ['./form-display-item.component.scss'],
})
export class FormDisplayItemComponent implements OnInit, OnDestroy {
  @Input() formItem: FormBuilderItem;
  @Input() formFieldControl: FormControl | FormArray;
  @Input() parentFormGroup: FormGroup;
  @Input() isPreviewMode: boolean;
  @Input() showStaticText: boolean;
  isViewApplication: boolean;
  checkType = {
    isGroup: false,
    isField: false,
    isText: false,
    isTable: false,
  };
  isHidden = false;

  unsubscribe: Subject<unknown> = new Subject();

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private applicationsProcessService: ApplicationsProcessService
  ) {}

  ngOnInit() {
    this.checkViewApplicationDisplay();
    this.checkType = {
      isGroup: this.isGroup(),
      isField: this.isField(),
      isText: this.isText(),
      isTable: this.isTable(),
    };

    this.formFieldControl?.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe({
      next: () => {
        this.interactiveFormBuilderService.itemValueUpdated.next(this.formItem);
      },
    });
    if (this.checkType.isTable) {
      this.subscribeToTableItemsValueChanges();
    }
    this.setBehaviorBasedOnItemOptions();
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.isViewApplication = value;
    });
  }

  setBehaviorBasedOnItemOptions() {
    this.checkIfItemShouldBeHidden();
  }

  checkIfItemShouldBeHidden() {
    // List of field type option ids for "Hide from field" for all existing fields and groups
    const hiddenFieldTypeIds = [16, 17, 18, 19, 20, 21, 22, 23];

    switch (true) {
      case !!this.checkType.isField:
        this.isHidden =
          this.formItem.fieldTypeOptions?.findIndex((option: FormBuilderFieldTypeOption) => hiddenFieldTypeIds.includes(option.id)) !== -1;
        break;
      case !!this.checkType.isGroup:
        this.isHidden =
          this.formItem.fields?.[0]?.fieldTypeOptions?.findIndex((option: FormBuilderFieldTypeOption) =>
            hiddenFieldTypeIds.includes(option.id)
          ) !== -1;
    }
  }

  isGroup() {
    return this.interactiveFormBuilderService.isFormBuilderItemGroup(this.formItem);
  }

  isField() {
    return this.interactiveFormBuilderService.isFormBuilderItemField(this.formItem) && !this.formItem.table;
  }

  isTable() {
    return this.formItem.table;
  }

  isText() {
    return this.interactiveFormBuilderService.isFormBuilderItemText(this.formItem);
  }

  asFormBuilderField(val): FormBuilderField {
    return val;
  }

  asFormBuilderGroup(val): FormBuilderGroup {
    return val;
  }

  asFormBuilderStaticText(val): FormBuilderStaticText {
    return val;
  }

  asFormBuilderTable(val): FieldTable {
    return val;
  }

  getFormFieldControl() {
    return <FormControl>this.formFieldControl;
  }

  private subscribeToTableItemsValueChanges() {
    const items: FormBuilderItem[] = this.formItem.items;
    items.forEach((item) => {
      const id = this.interactiveFormBuilderService.isFormBuilderItemField(item)
        ? item.id
        : this.interactiveFormBuilderService.isFormBuilderItemGroup(item)
        ? item.groupOptions.id
        : null;
      if (id) {
        this.parentFormGroup
          .get(id.toString())
          ?.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500))
          .subscribe({
            next: () => {
              this.interactiveFormBuilderService.itemValueUpdated.next(item);
            },
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
