import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatesService {
  fromLocalToServer(date: Date | string) {
    if (typeof date === 'object') {
      return date.toUTCString().substring(0, 25);
    }
  }

  removeTimeFromDate(date: Date | string): string {
    switch (typeof date) {
      case 'string':
        return date.substring(0, 10);
      case 'object':
        return date.toISOString().substring(0, 10);
      default:
        return '';
    }
  }

  today(): Date {
    return this.getNewDateInTheFutureFromNow(0);
  }

  getNewDateInTheFutureFromNow(daysInTheFuture: number): Date {
    const now: Date = new Date(this.fromLocalToUTC(new Date()).setHours(0, 0, 0, 0));

    return new Date(now.setDate(now.getDate() + daysInTheFuture));
  }

  fromLocalToUTC(date: Date) {
    return new Date(date.toUTCString().substring(0, 25));
  }

  formatForFullCalendar(date: Date): string {
    return date.toISOString().replace(/T.*$/, '');
  }

  addMonths(date: Date, monthsInTheFuture: number) {
    const dateFromDate = date.getDate();
    date.setMonth(date.getMonth() + +monthsInTheFuture);
    if (date.getDate() != dateFromDate) {
      date.setDate(0);
    }
    return date;
  }

  convertBirthDateToAge(birthdate: string): string {
    const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    return age.toString();
  }

  getAgeNearest(birthday: string) {
    // The nearest age increases starting with x years 6 months 1 day

    const now = new Date(this.fromLocalToUTC(new Date()).setHours(0, 0, 0, 0));
    const dob = new Date(new Date(this.fromLocalToUTC(new Date(this.convertDayMonthYearToYearMonthDay(birthday))).setHours(0, 0, 0, 0)));

    const yearNow = now.getFullYear();
    const monthNow = now.getMonth() + 1;
    const dateNow = now.getDate();
    const yearDob = dob.getFullYear();
    const monthDob = dob.getMonth() + 1;
    const dateDob = dob.getDate();

    let yearDiff = yearNow - yearDob;
    const monthDiff = monthNow - monthDob;
    const dateDiff = dateNow - dateDob;

    if (monthDiff < 0) {
      yearDiff -= 1;
    }

    if (
      (monthDiff > 6 && monthDiff < 0) ||
      (monthDiff === 6 && dateDiff <= 0) ||
      (monthDiff > -6 && monthDiff < 0) ||
      (monthDiff === -6 && dateDiff > 0)
    ) {
      yearDiff += 1;
    }

    return yearDiff;
  }

  calculateAge(birthday: string) {
    // The real age increases starting with the day following the birthday

    const now = new Date(this.fromLocalToUTC(new Date()).setHours(0, 0, 0, 0));
    const dob = new Date(new Date(this.fromLocalToUTC(new Date(this.convertDayMonthYearToYearMonthDay(birthday))).setHours(0, 0, 0, 0)));

    const yearNow = now.getFullYear();
    const monthNow = now.getMonth() + 1;
    const dateNow = now.getDate();
    const yearDob = dob.getFullYear();
    const monthDob = dob.getMonth() + 1;
    const dateDob = dob.getDate();

    let yearDiff = yearNow - yearDob;
    const monthDiff = monthNow - monthDob;
    const dateDiff = dateNow - dateDob;

    if (monthDiff < 0 || (monthDiff === 0 && dateDiff < 0)) {
      yearDiff--;
    }

    return yearDiff;
  }

  getDateOrdinalSuffix(value) {
    let numberValue: number;
    if (typeof value === 'string') {
      numberValue = parseInt(value.replace(/\D/g, ''));
    }
    if (numberValue > 3 && numberValue < 21) return `${value}th`;
    switch (numberValue % 10) {
      case 1:
        return `${value}st`;
      case 2:
        return `${value}nd`;
      case 3:
        return `${value}rd`;
      default:
        return `${value}th`;
    }
  }

  convertDayMonthYearToYearMonthDay(date: string | Date) {
    if (date) {
      const dateAsString: string = this.getDateAsString(date);
      const regexToTestForAlreadyCorrectFormat = /(\d{4})-(\d{2})-(\d{2})/;
      if (regexToTestForAlreadyCorrectFormat.test(dateAsString.toString())) {
        return dateAsString;
      } else {
        const withSlash = dateAsString.split('/').reverse().join('-');
        const withMinus = dateAsString.split('-').reverse().join('-');
        return withSlash === dateAsString ? withMinus : withSlash;
      }
    }
  }

  converYearMonthDayToDayMonthYear(date: string | Date) {
    if (date) {
      const dateAsString: string = this.getDateAsString(date);
      const regexToTestForAlreadyCorrectFormat = /(\d{2})\/(\d{2})\/(\d{4})/;
      if (regexToTestForAlreadyCorrectFormat.test(dateAsString.toString())) {
        return dateAsString;
      } else {
        const withSlash = dateAsString.split('/').reverse().join('/');
        const withMinus = dateAsString.split('-').reverse().join('/');
        return withSlash === dateAsString ? withMinus : withSlash;
      }
    }
  }

  getDateAsString(date: string | Date): string {
    let dateAsString: string;
    if (typeof date === 'string') {
      dateAsString = date;
    }
    if (typeof date === 'object' && date instanceof Date) {
      dateAsString = date.toISOString().substring(0, 10);
    }

    return dateAsString;
  }
}
