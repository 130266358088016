<div class="summary-esignature-info">
  <form [formGroup]="signatureForm">
    <p-table [autoLayout]="true" [breakpoint]="'500px'" [resizableColumns]="true"
             [value]="signatureTableData" responsiveLayout="stack">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ 'applicationProcess.step.summary.signature.signatureFor' | translate }}</th>
          <th>{{ 'applicationProcess.step.summary.signature.signatureType' | translate }}</th>
          <th>{{ 'applicationProcess.step.summary.signature.sendSignatureLinkBy' | translate }}</th>
          <th>{{ 'applicationDigitalSignatures.signerInfo' | translate }}</th>

          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-recipient let-columns="columns">
        <tr>
          <td>
            <span class="p-column-title">{{ 'applicationProcess.step.summary.signature.signatureFor' | translate }}</span
            ><b>{{ 'applicationDigitalSignatures.' + recipient.recipientType | translate }}</b>
          </td>
          <td>
            <span class="p-column-title">{{ 'applicationProcess.step.summary.signature.signatureType' | translate }}</span>
            <div>
              <p-radioButton
                [value]="SignatureTypeEnum.VOICESIGNATURE"
                [formControlName]="'signature-' + recipient.recipientType"
                label="{{ 'applicationProcess.step.summary.signature.signatureTypeVoice' | translate }}"
                class="mr-3"
              ></p-radioButton>
              <p-radioButton
                [value]="SignatureTypeEnum.ESIGNATURE"
                [formControlName]="'signature-' + recipient.recipientType"
                label="{{ 'applicationProcess.step.summary.signature.signatureTypeElectronic' | translate }}"
              ></p-radioButton>
            </div>
          </td>
          <td>
            <span class="p-column-title">{{ 'applicationProcess.step.summary.signature.sendSignatureLinkBy' | translate }}</span>
            <div class="flex flex-column">
              <div class="mb-2">
                <p-checkbox [binary]="true" [formControlName]="'email-' + recipient.recipientType" [inputId]="'inputid'"></p-checkbox>
                <label [for]="'inputid'"> {{ 'applicationProcess.step.summary.signature.signatureChanelEmail' | translate }} </label>
              </div>

              <div class="mb-2">
                <p-checkbox [binary]="true" [formControlName]="'sms-' + recipient.recipientType" [inputId]="'inptID'"></p-checkbox>
                <label [for]="'inptID'"> {{ 'applicationProcess.step.summary.signature.signatureChanelSms' | translate }} </label>
              </div>
            </div>
          </td>
          <td>
            <span class="p-column-title">{{ 'applicationDigitalSignatures.signerInfo' | translate }}</span>
            <div class="flex flex-column">
              <div fxLayout="column" class="pr-3 mb-2">
                <div fxFlex fxLayout="row">
                  <label class="pb-1" for="fullName">{{ 'client_selection.first_name' | translate }}</label>
                  <iapplication2-required
                    [value]="signatureForm.controls['fullName-' + recipient.recipientType].value"
                    class="flex ai-center"
                    style="min-width: 20px"
                    [required]="true"
                  ></iapplication2-required>
                </div>
                <input
                  id="fullName"
                  type="text"
                  class="p-inputtext capitalize-text"
                  pInputText
                  [placeholder]="'applicationDigitalSignatures.fullName' | translate"
                  [formControlName]="'fullName-' + recipient.recipientType"
                />
                <iapplication2-form-field-error
                  *ngIf="signatureForm.controls['fullName-' + recipient.recipientType].invalid"
                  [control]="signatureForm.controls['fullName-' + recipient.recipientType]"
                  style="bottom: -8px"
                ></iapplication2-form-field-error>
              </div>
              <div fxLayout="column" class="pr-3 mb-2">
                <div fxFlex fxLayout="row">
                  <label class="pb-1" for="email">{{ 'applicationDigitalSignatures.signatureChanelEmail' | translate }}</label>
                  <iapplication2-required
                    [value]="signatureForm.controls['emailValue-' + recipient.recipientType].value"
                    class="flex ai-center"
                    style="min-width: 20px"
                    [required]="true"
                  ></iapplication2-required>
                </div>
                <input
                  id="email"
                  type="text"
                  class="p-inputtext p-component"
                  pInputText
                  [placeholder]="'applicationDigitalSignatures.signatureChanelEmail' | translate"
                  [formControlName]="'emailValue-' + recipient.recipientType"
                />
                <small *ngIf="signatureForm.controls['emailValue-' + recipient.recipientType].invalid" class="p-error">
                  {{ 'validations.invalidEmail' | translate }}
                </small>
              </div>

              <div fxLayout="column" class="pr-3">
                <div fxFlex fxLayout="row">
                  <label class="pb-1" for="phoneNumber">{{ 'applicationDigitalSignatures.phoneNumber' | translate }}</label>
                  <iapplication2-required
                    [value]="signatureForm.controls['phoneNumber-' + recipient.recipientType].value"
                    class="flex ai-center"
                    style="min-width: 20px"
                    [required]="true"
                  ></iapplication2-required>
                </div>
                <p-inputMask
                  id="phoneNumber"
                  class="flex flex-column"
                  type="text"
                  [formControlName]="'phoneNumber-' + recipient.recipientType"
                  [placeholder]="'applicationDigitalSignatures.phoneNumber' | translate"
                  mask="(999) 999-9999"
                ></p-inputMask>
                <iapplication2-form-field-error
                  *ngIf="signatureForm.controls['phoneNumber-' + recipient.recipientType].touched"
                  [control]="signatureForm.controls['phoneNumber-' + recipient.recipientType]"
                  style="bottom: -8px"
                ></iapplication2-form-field-error>
              </div>
            </div>
          </td>
          <td>
            <span class="p-column-title">.</span>
            <div class="flex flex-column">
              <p-button
                class="mb-2"
                [ngClass]="{
                  'disabled-area':
                    !signatureForm?.controls['signature-' + recipient.recipientType].value || recipient.isSubmitButtonDisabled
                }"
                [disabled]="
                  signatureForm?.controls['signature-' + recipient.recipientType].value === SignatureTypeEnum.VOICESIGNATURE ||
                  signatureForm?.controls['emailValue-' + recipient.recipientType].invalid ||
                  signatureForm?.controls['phoneNumber-' + recipient.recipientType].invalid ||
                  signatureForm?.controls['fullName-' + recipient.recipientType].invalid ||
                  (!signatureForm?.controls['email-' + recipient.recipientType].value &&
                    !signatureForm?.controls['sms-' + recipient.recipientType].value) ||
                  (signatureForm?.controls['email-' + recipient.recipientType].value &&
                    !signatureForm?.controls['emailValue-' + recipient.recipientType].value) ||
                  (signatureForm?.controls['sms-' + recipient.recipientType].value &&
                    !signatureForm?.controls['phoneNumber-' + recipient.recipientType].value)
                "
                (click)="sendForSignature(recipient.recipientType)"
                >{{
                  'applicationProcess.step.summary.signature.signatureSendButton' | translate: { recipientType: recipient.recipientType }
                }}</p-button
              >
              <p-button *ngIf="recipient.showCopySignatureLink" (click)="copyToClipboardSignatureLink(recipient.recipientType)">{{
                'applicationDigitalSignatures.copySignatureLink' | translate
              }}</p-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>
  <p-toast key="summary-signature-messages"></p-toast>
</div>
