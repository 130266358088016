<div
  class="card mb-2 p-custom-shadow-3 px-2 py-2 hoverBorder"
  cdkDrag
  fxLayout="row"
  fxLayoutAlign="space-between center"
  (mouseenter)="highlightFieldOnCanvas()"
  [ngClass]="{ selected: formBuilderField === selectedCanvasObject }"
  (mouseleave)="unHighlightFieldOnCanvas()"
  iapplication2ClickOutside
  [includeCanvasInClickedOutsideList]="false"
  [elementData]="formBuilderField"
>
  <div fxLayout="row" fxLayoutAlign="center center">
    <span cdkDragHandle *ngIf="!formBuilderField?.displaySelectRadio">
      <img [src]="isDarkTheme ? './assets/custom/icons/drag-icon-light.svg' : './assets/custom/icons/drag-icon.svg'" class="drag-icon" />
    </span>
    <div *ngIf="formBuilderField.displaySelectRadio">
      <p-checkbox [(ngModel)]="formBuilderField.selected" [binary]="true"></p-checkbox>
    </div>
    <p class="mb-1 ml-2" *ngIf="formBuilderField.options?.customFieldLabel; else emptyTitle">
      {{ formBuilderField.options?.customFieldLabel }}
    </p>
    <ng-template #emptyTitle>
      <p class="mb-1 ml-2 no-group-title">No label set for this field</p>
    </ng-template>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div [class]="'fieldType-chip ' + formBuilderField.fieldType.type">
      {{ formBuilderField.fieldType.label }}
    </div>
    <i
      *ngIf="!formBuilderField.options?.visibleOnPdf"
      class="ml-3 mr-1 pi pi-eye-slash"
      style="color: #856404"
      pTooltip="This field will not be visible on PDF"
      tooltipPosition="bottom"
    ></i>
    <button
      pButton
      pRipple
      icon="pi pi-cog"
      (click)="toggleMenu(formBuilderField, menu, $event)"
      class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
    ></button>
  </div>
</div>

<p-menu #menu [popup]="true" [model]="items"></p-menu>
