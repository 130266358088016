import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Application, ApplicationStatusOptions } from '@iapplication2/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-result-section-applications',
  templateUrl: './result-section-applications.component.html',
  styleUrls: ['./result-section-applications.component.scss'],
})
export class ResultSectionApplicationsComponent implements OnInit, OnDestroy {
  @Input() applications: Application[];
  @Input() loading = true;
  @Input() searchedString: string;
  items: MenuItem[][] = [];
  menuLabel: string;
  editOptionLabel: string;
  viewOptionLabel: string;

  isEnvDevelop: boolean;

  private unsubscribe = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.setApplicationsDetails();
    this.items = [];
    this.getTranslationsForActionButton();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForActionButton();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.applications) {
      this.setApplicationsDetails();
    }
  }

  setApplicationsDetails() {
    this.applications?.forEach((application) => {
      this.getFullName(application);
      this.getPremium(application);
    });
  }

  getFullName(application: Application) {
    const firstName = application?.client?.firstName?.replace(/ /g, '&nbsp;');
    const middleName = application?.client?.middleName?.replace(/ /g, '&nbsp;');
    const lastName = application?.client?.lastName?.replace(/ /g, '&nbsp;');

    application.fullName = `${firstName}&nbsp;${application.client?.middleName ? middleName + '&nbsp;': ''}${lastName}`;
    application.fullNameTooltip = `${application?.client?.firstName} ${application.client?.middleName ?
      application.client?.middleName + ' ': ''}${application?.client?.lastName}`;
  }

  getPremium(application) {
    let premium = 0;

    application?.products?.forEach((product) => {
      premium = premium + Number(product?.monthlyPremium);
    });

    application.premium =  application.currency?.symbol + ' ' + premium?.toFixed(2) + '/m';
  }

  onClickMenu(application) {
    const contextId = application.id;
    if (this.items[contextId]) {
      return this.items[contextId];
    }
    const items = [
      {
        label: this.menuLabel,
        items: [
          {
            label: this.editOptionLabel,
            icon: 'pi pi-pencil',
            routerLink: `/application/edit/${application.id}`
          },
          {
            label: this.viewOptionLabel,
            icon: 'pi pi-eye',
            routerLink: `/applications/application/${contextId}`,
          }
        ],
      },
    ];

    /* removes "edit" option if the status of the application is other than Active */
    if (application?.status?.name && application?.status?.name !== ApplicationStatusOptions.ACTIVE) {
      items[0].items.splice(0, 1);
    }

    this.items[contextId] = items;
    return this.items[contextId];
  }

  toggleMenu(application: Application, menu, ev) {
    ev.preventDefault();
    menu.toggle(ev);
  }

  private getTranslationsForActionButton() {
    this.translateService
      .get('pages.dashboard.sections.recentPolicies.tabs.recentApplications.table.button')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.menuLabel = res.title;
        this.editOptionLabel = res.options?.editApplication;
        this.viewOptionLabel = res.options?.editApplicationDetails;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
