import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '@iapplication2/interfaces';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  getRecentNotifications() {
    return of(null);
    // return this.http.get(`notifications?_limit=5`);
  }

  getPaginatedNotifications(page: number, resultsPerPage: number) {
    return of(null);
    // return this.http.get(`notifications?_
    // page=${page}&_limit=${resultsPerPage}&_sort=timestamp&_order=desc`);
  }

  setNotificationAsRead(notification: Notification) {
    notification.isRead = true;
    return of(null);
    // return this.http.put(`notifications/${notification.id}`, notification);
  }

  createSimpleNotification(text: string, userId: number) {
    return of(null);
    // const notification: Notification = {
    //   userId,
    //   text,
    //   timestamp: new Date(),
    //   isRead: false,
    //   hasLink: false,
    // };
    // return this.http.post('notifications', notification);
  }

  createLinkNotification(text: string, userId: number, link: string) {
    return of(null);
    // const notification: Notification = {
    //   userId,
    //   text,
    //   timestamp: new Date(),
    //   isRead: false,
    //   hasLink: true,
    //   link: link || '',
    // };
    // return this.http.post('notifications', notification);
  }

  deleteNotification(id: number) {
    return of(null);
    // return this.http.delete(`notifications/${id}`);
  }
}
