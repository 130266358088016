<div class="attachment-section mb-3">
  <div fxFlex fxLayout="column">
    <div class="mb-2" fxFlex fxLayout="row" fxLayoutAlign="start center">
      <iapplication2-required *ngIf="!isViewApplication" [required]="formFieldControl.errors?.required"></iapplication2-required>
      {{
        (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
          | translate: { Default: field?.options?.customFieldLabel }
      }}
    </div>
    <div *ngIf="fieldAttachments?.length > 0" fxFlex fxLayout="column" class="pl-1">
      <div *ngFor="let attachedFile of fieldAttachments" fxFlex fxLayout="row" fxLayoutAlign="start center" class="mb-2">
        {{ attachedFile?.fileName?.length > 20 ? (attachedFile?.fileName | slice: 0:20) + '..' : attachedFile?.fileName }}
        <a href="{{ attachedFile?.s3Link }}">
          <button pButton pRipple type="button" label="Download" class="p-button-secondary ml-2"></button>
        </a>
        <button pButton pRipple type="button" label="Delete" class="p-button-secondary ml-2" (click)="deleteFile(attachedFile)"></button>
      </div>
    </div>
    <div *ngIf="!fieldAttachments?.length">No attachments yet.</div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="pl-1 mt-3">
      <p class="mb-0">Add File:</p>
      <p-fileUpload
        class="ml-2 custom-upload-button"
        #logoUploadForm
        name="files[]"
        mode="basic"
        accept="application/pdf"
        [customUpload]="true"
        [auto]="true"
        (uploadHandler)="onAddAttachments($event, logoUploadForm)"
        chooseLabel="Upload"
      >
      </p-fileUpload>
    </div>
  </div>
</div>
