import { Inject, Injectable } from '@angular/core';
import {
  FormArea,
  FormBuilderField,
  FormBuilderStaticText,
  FormSection,
  FormBuilderItem,
  FormBuilderGroup,
  FormBuilderFieldGroupOptions,
  FieldTable,
  FieldType,
  FieldColumn,
  FieldCategory,
  ENV,
  FieldTypeEnum,
  CustomFormBuilderField,
  FormAreaId,
} from '@iapplication2/interfaces';
import { concat, merge, of, ReplaySubject, Subject } from 'rxjs';
import { CanvasService } from '../canvas/canvas.service';
import { ModalType } from '@iapplication2/interfaces';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@iapplication2/app-config';
import { FieldManagementService } from '../field-management/field-management.service';
import { tap } from 'rxjs/operators';
import { PredefinedFieldsService } from '../predefined-fields/predefined-fields.service';
import { ProgressSpinnerService } from '../progress-spinner/progress-spinner.service';

@Injectable({
  providedIn: 'root',
})
export class InteractiveFormBuilderService {
  selectedItem: FormBuilderItem;
  selectedItemFromColumn: FormBuilderItem;
  selectedTable: ReplaySubject<FieldTable> = new ReplaySubject<FieldTable>(1);
  settingsModalOptions: ModalType;

  fieldWasSelected: Subject<FormBuilderItem> = new Subject<FormBuilderItem>();
  sidebarColumnWasSelected: Subject<FormBuilderItem> = new Subject<FormBuilderItem>();
  categoryOfSelectedField: Subject<FieldCategory> = new Subject<FieldCategory>();
  settingsModalOptionsWereSelected: ReplaySubject<ModalType> = new ReplaySubject(1);
  fromBuilderPageViewState: ReplaySubject<'draw' | 'form' | 'preview'> = new ReplaySubject<'draw' | 'form' | 'preview'>(1);

  fromBuilderPageViewStateChanged: ReplaySubject<'draw' | 'form' | 'preview'> = new ReplaySubject<'draw' | 'form' | 'preview'>(1);

  unsubscribe: Subject<void>;

  sidebarListWasUpdated: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  defaultItems: ReplaySubject<FieldType[]> = new ReplaySubject<FieldType[]>(1);

  updatedField: Subject<FormBuilderField> = new Subject<FormBuilderField>();

  saveSectionManagement: Subject<unknown> = new Subject();
  modifiedSectionList: Subject<FormSection[]> = new Subject();
  deletedSectionList: Subject<FormSection[]> = new Subject();

  showDisplayConditionsDialog: Subject<unknown> = new Subject();
  selectedItemForDisplayConditions: ReplaySubject<FormBuilderItem> = new ReplaySubject(1);
  selectedFormId: ReplaySubject<number> = new ReplaySubject(1);

  requestAllFields: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  $formAreasUpdated: ReplaySubject<number | FormAreaId.UNASSIGNED | null | undefined> = new ReplaySubject<
    number | FormAreaId.UNASSIGNED | null | undefined
  >(1);

  _formAreasForCurrentSection: FormArea[] = [];

  _unassignedFields: FormBuilderField[] = [];

  itemValueUpdated: ReplaySubject<FormBuilderItem> = new ReplaySubject(1);

  constructor(
    @Inject(APP_CONFIG) private appConfig: ENV,
    private canvasService: CanvasService,
    private http: HttpClient,
    private fieldManagementService: FieldManagementService,
    private predefinedFieldsService: PredefinedFieldsService,
    private progressSpinnerService: ProgressSpinnerService
  ) {}

  getFormSectionsListByFormId(id: number) {
    return this.http.get(`form-builder/formSections/${id}`);
  }

  getFormSectionsListByProductFormId(productFormId: number) {
    return this.http.get(`form-builder/productFormSections/${productFormId}`);
  }

  getFormSectionsTypeList() {
    return this.http.get('form-builder/form-section-types');
  }

  convertListOfFieldsToListOfItems(fields: FormBuilderField[]): FormBuilderItem[] {
    const items: FormBuilderItem[] = [];
    fields.forEach((field) => {
      if (field.groupOptions?.id && !field.table) {
        if (!items.some((item) => item.groupOptions?.id === field?.groupOptions.id)) {
          const group: FormBuilderGroup = {
            groupOptions: field.groupOptions,
            fields: fields.filter((fieldInGroup) => fieldInGroup.groupOptions?.id === field.groupOptions?.id),
          };

          if (group.fields[0].predefinedFieldId && !group.groupOptions.predefinedGroupId) {
            group.groupOptions.predefinedGroupId = group.groupOptions.id;
          }
          group.fieldValidators = group.fields[0].fieldValidators;
          group.staticValueLink = group.fields[0].staticValueLink;
          group.staticValueLinkId = group.fields[0].staticValueLinkId;
          group.fieldTypeOptions = group.fields[0].fieldTypeOptions;
          group.fieldDisclosureOption = group.fields[0].fieldDisclosureOption;
          items.push(group);
        }
      } else if (field.table?.type?.id) {
        const fieldsInTable = items.filter((item: FormBuilderItem) => item.id === field.table?.type?.id);
        if (!fieldsInTable.length) {
          const table: FieldTable = {
            id: field.table?.type?.id,
            name: field.table?.type.name,
            numberOfRows: field.table?.type.numberOfRows,
            columns: field.table?.type?.columns,
          };

          if (fieldsInTable[0]?.predefinedFieldId) {
            table.predefinedTableId = table.id;
          }

          items.push(table);
        }
      } else {
        items.push(field);
      }
    });
    return items;
  }

  convertListOfItemsToListOfFields(items: FormBuilderItem[]): FormBuilderField[] {
    const fields: FormBuilderField[] = [];
    items.forEach((item) => {
      if (item.groupOptions) {
        fields.push(...item.fields);
      } else if (item.columns) {
        fields.push(...this.canvasService.listOfAllFormFields.filter((field) => field.table?.type?.id === item.id));
      } else {
        fields.push(item as FormBuilderField);
      }
    });
    return fields;
  }

  updateFieldsFromTableWithChangedTable(table: FieldTable) {
    const fieldsFromTable = this.findFieldsFromTable(table.id);
    fieldsFromTable.forEach((field) => (field.table.type = table));
  }

  findFieldsFromTable(tableId: number): FormBuilderField[] {
    const foundFields: FormBuilderField[] = [];
    this._unassignedFields.forEach((field) => {
      if (field.table?.type.id === tableId) {
        foundFields.push(field as FormBuilderField);
      }
    });
    this._formAreasForCurrentSection.forEach((area) => {
      area.fields.forEach((field) => {
        if (field.table?.type.id === tableId) {
          foundFields.push(field as FormBuilderField);
        }
      });
    });
    return foundFields;
  }

  sidebarItemSelected(item: FormBuilderItem, category?: FieldCategory) {
    this.selectedItem = item;
    this.fieldWasSelected.next(this.selectedItem);
    if (category) {
      this.categoryOfSelectedField.next(category);
    }
  }

  public sidebarColumnSelected(column: FieldColumn, tableId: number) {
    const genericItemInColumn = this.canvasService.getFieldsFromColumnFromTable(column.id, tableId)[0];

    if (genericItemInColumn?.groupOptions) {
      const groupOptions: FormBuilderFieldGroupOptions = {
        id: genericItemInColumn.groupOptions?.id,
        name: genericItemInColumn.groupOptions?.name,
        type: genericItemInColumn.fieldType,
      };
      const fields: FormBuilderField[] = [];
      fields.push(...this.canvasService.getFieldsByGroupId(genericItemInColumn.groupOptions.id));
      this.selectedItem = {
        groupOptions: groupOptions,
        fields: fields,
      };
    } else {
      this.selectedItem = genericItemInColumn;
    }

    this.fieldWasSelected.next(this.selectedItem);
  }

  public settingsModalOptionsSelected(modalType: ModalType) {
    this.settingsModalOptions = modalType;
    this.settingsModalOptionsWereSelected.next(this.settingsModalOptions);
  }

  public getFormAreasBySectionId(id: string) {
    return this.http.get(`form-builder/sectionAreas/${id}`);
  }

  public updateSectionAreas(areas: FormArea[]) {
    return this.http.put(`form-builder/sectionArea`, areas);
  }

  public isFormBuilderItemGroup(fieldItem: FormBuilderItem): fieldItem is FormBuilderGroup {
    return !!(fieldItem as FormBuilderGroup)?.groupOptions?.id;
  }

  public isFormBuilderItemTable(fieldItem: FormBuilderItem): fieldItem is FieldTable {
    return (fieldItem as FormBuilderGroup)?.columns != undefined;
  }

  public isTypeGroup(type: FieldType) {
    return type.id === FieldTypeEnum.checkboxGroup || type.id === FieldTypeEnum.radioGroup || type.id === FieldTypeEnum.disclosure;
  }

  public isTypeTable(type: FieldType) {
    return type.id === FieldTypeEnum.table;
  }

  public isFieldInTable(field: FormBuilderField) {
    return field?.table?.type;
  }

  public isFormBuilderItemField(fieldItem: FormBuilderItem): fieldItem is FormBuilderField {
    return (
      (fieldItem as FormBuilderField)?.options != undefined &&
      !(fieldItem as FormBuilderField)?.content &&
      !(fieldItem as FormBuilderField).groupOptions
    );
  }

  public isFormBuilderItemText(fieldItem: FormBuilderItem): fieldItem is FormBuilderStaticText {
    return (fieldItem as FormBuilderStaticText)?.content != undefined;
  }

  public updateSidebarList(): void {
    this.sidebarListWasUpdated.next(true);
  }

  public createSections(sections: FormSection[]) {
    return this.http.post(`form-builder/formSection/`, sections);
  }

  public updateSections(sections: FormSection[]) {
    return this.http.post(`form-builder/formSection/`, sections);
  }

  public deleteSectionById(sectionId: number) {
    return this.http.delete(`form-builder/formSection/${sectionId}`);
  }

  public createAreas(areas: FormArea[]) {
    return this.http.post(`form-builder/sectionArea`, areas);
  }

  public getUnassignedFieldsByFormId(formId: string) {
    return this.http.get(`form-builder/unassigned-fields/${formId}`);
  }

  public updateListOfFields(list: FormBuilderItem[]) {
    return this.http.put(`form-builder/list-of-fields`, list);
  }

  public deleteArea(areaId: number) {
    return this.http.delete(`form-builder/sectionArea/${areaId}`);
  }

  public getFieldTypes() {
    return this.http.get('form-builder/field-types');
  }

  // SAVING ANY FIELD CHANGES TO THE DB FROM ANYWHERE IN THE APP:

  public getListOfFieldsFromTableByIdFromListOfFields(tableId: number): FormBuilderField[] {
    return this.canvasService.listOfAllFormFields.filter((field) => field.table?.type?.id === tableId);
  }

  public updateTableColumnsPosition(tableToUpdate: FieldTable, fieldsInTable: FormBuilderField[]) {
    fieldsInTable?.forEach((fieldInTable) => {
      fieldInTable.table.position.column.number = tableToUpdate.columns.find(
        (column) => column.id === fieldInTable.table.position.column.id
      )?.number;
      fieldInTable.table.type.columns = tableToUpdate.columns;
    });
  }

  public updateTable(tableToUpdate: FieldTable) {
    const newTableName = tableToUpdate.name;

    const fieldsInTable: FormBuilderField[] = this.getListOfFieldsFromTableByIdFromListOfFields(tableToUpdate.id);
    this.updateTableColumnsPosition(tableToUpdate, fieldsInTable);

    fieldsInTable?.forEach((field: FormBuilderField) => {
      field.table.type.name = newTableName;
      this.fieldManagementService.updateField(field).subscribe(() => {
        this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        this.$formAreasUpdated.next(field.formAreaId);
        this.canvasService.getAllFormFieldsDebounced();
      });
    });
  }

  public updateFieldsInColumn(tableToUpdate: FieldTable) {
    const fieldsInTable: FormBuilderField[] = this.getListOfFieldsFromTableByIdFromListOfFields(tableToUpdate.id);
    const fieldsWithChanges = fieldsInTable.filter((field: FormBuilderField) => field.table.position.row === 0);

    fieldsInTable.forEach((fieldInColumnInTable) => {
      if (fieldInColumnInTable.table.position.row !== 0 && !fieldInColumnInTable.groupOptions?.type) {
        const fieldToUpdateWith = fieldsWithChanges.filter(
          (fieldWithChanges: FormBuilderField) =>
            fieldWithChanges.table.position.column.id === fieldInColumnInTable.table.position.column.id
        )[0];
        this.canvasService.updateFieldsInColumnByIdWithNewFieldValues(fieldInColumnInTable.id, fieldToUpdateWith);
      }
    });

    tableToUpdate = fieldsInTable[0].table.type;

    fieldsInTable.forEach((field) => {
      this.fieldManagementService.updateField(field).subscribe(() => {
        this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        this.$formAreasUpdated.next(field.formAreaId);
        this.canvasService.getAllFormFieldsDebounced();
      });
    });
  }

  public deleteColumns(tableToUpdate: FieldTable) {
    const fieldsInColumnsToBeDeleted = this.canvasService.listOfAllFormFields.filter((field) => {
      const index = tableToUpdate.columns.findIndex((column) => column.id === field.table?.position.column.id);

      return field.table?.type.id && field.table.type?.id === tableToUpdate.id && index === -1;
    });

    const columnsToBeDeleted = _.uniqBy(fieldsInColumnsToBeDeleted, (field) => {
      return field.table.position.column.id;
    }).map((field) => field.table.position.column);

    columnsToBeDeleted.forEach((columnToBeDeleted) => {
      this.fieldManagementService.deleteTableColumnByIdWithResponse(columnToBeDeleted.id).subscribe(() => {
        this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        this.$formAreasUpdated.next(fieldsInColumnsToBeDeleted[0].formAreaId);
        fieldsInColumnsToBeDeleted.forEach((field) => {
          if (field.table.position.column.id === columnToBeDeleted.id) {
            this.canvasService.deleteObjectById(field.id);
          }
        });
      });
    });
  }

  public deleteTable(tableId: number) {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    const fieldsToBeDeletedFromCanvas: FormBuilderField[] = this.canvasService.listOfAllFormFields.filter(
      (field: FormBuilderField) => field.table?.type?.id === tableId
    );
    return this.fieldManagementService.deleteTableById(tableId).subscribe({
      complete: () => {
        this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        this.$formAreasUpdated.next(fieldsToBeDeletedFromCanvas[0].formAreaId);
        fieldsToBeDeletedFromCanvas.forEach((field: FormBuilderField) => this.canvasService.deleteObjectById(field.id));
      },
    });
  }

  public deleteGroup(group: FormBuilderGroup): void {
    const groupId = group.groupOptions.id;
    const fieldsToBeDeleted = this.canvasService.listOfAllFormFields.filter((field) => field.groupOptions?.id === groupId);

    const obs$ = [];
    const fieldsToBeDeletedFromCanvas: FormBuilderField[] = [];
    fieldsToBeDeleted.forEach((field) => {
      obs$.push(
        this.fieldManagementService.deleteFieldByIdWithResponse(field.id).pipe(
          tap(() => {
            const deleteFromCanvas = group.fields[0].options?.visibleOnPdf !== false;
            if (deleteFromCanvas) {
              fieldsToBeDeletedFromCanvas.push(field);
            }
          })
        )
      );
    });

    merge(of(obs$)).subscribe({
      next: (res) => {
        const processedObservable = concat(...res);

        processedObservable.subscribe({
          complete: () => {
            this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
            fieldsToBeDeletedFromCanvas.forEach((field: FormBuilderField) => this.canvasService.deleteObjectById(field.id));
            this.$formAreasUpdated.next(group.formAreaId);
          },
        });
      },
    });
  }
  public deleteField(field: FormBuilderField): void {
    this.fieldManagementService.deleteFieldByIdWithResponse(field.id).subscribe({
      complete: () => {
        const deleteFromCanvas = field.options?.visibleOnPdf !== false;

        this.$formAreasUpdated.next(field.formAreaId);
        if (deleteFromCanvas) {
          this.canvasService.deleteFieldFromCanvas(field);
        }
      },
    });
  }

  public updateField(field: FormBuilderField) {
    this.fieldManagementService.updateField(field).subscribe(() => {
      this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
      const updateCanvas = field.options?.visibleOnPdf !== false;
      this.$formAreasUpdated.next(field.formAreaId);
      if (updateCanvas) {
        this.canvasService.getAllFormFields(field.formId, false);
      }
    });
  }

  public isItemStaticText(item: FormBuilderItem): boolean {
    return !!item?.content;
  }

  public isItemGroup(item: FormBuilderItem): boolean {
    return !!item?.groupOptions && !item?.table?.type;
  }

  public isItemTable(item: FormBuilderItem): boolean {
    return !!item?.columns?.length;
  }

  public isItemField(item: FormBuilderItem): boolean {
    return !item?.groupOptions?.id && !item?.columns && !item?.fields && !item?.content && !item?.table?.type;
  }

  public isItemFieldFromTable(item: FormBuilderItem): boolean {
    return item?.table?.type && !this.isItemGroupFromTable(item);
  }

  public isItemGroupFromTable(item: FormBuilderItem): boolean {
    return !!item?.fields?.find((option) => option?.table?.type);
  }

  private getFieldsFromColumnFromPredefinedTable(columnId: number, tableId: number): FormBuilderField[] {
    return this.predefinedFieldsService.predefinedFields
      .filter(
        (field: CustomFormBuilderField) =>
          tableId && field.field.table?.type?.id === tableId && columnId && field.field.table?.position?.column?.id === columnId
      )
      .map((field: CustomFormBuilderField) => field.field);
  }

  private getPredefinedFieldsByGroupId(groupId: number): FormBuilderField[] {
    return this.predefinedFieldsService.predefinedFields
      .filter((field) => field.field.groupOptions?.id === groupId)
      .map((field: CustomFormBuilderField) => field.field);
  }

  public sidebarPredefinedColumnSelected(column: FieldColumn, tableId: number) {
    const genericItemInColumn = this.getFieldsFromColumnFromPredefinedTable(column.id, tableId)[0];

    console.log(genericItemInColumn);

    if (genericItemInColumn?.groupOptions) {
      const groupOptions: FormBuilderFieldGroupOptions = {
        id: genericItemInColumn.groupOptions?.id,
        name: genericItemInColumn.groupOptions?.name,
        type: genericItemInColumn.fieldType,
      };
      const fields: FormBuilderField[] = [];
      fields.push(...this.getPredefinedFieldsByGroupId(genericItemInColumn.groupOptions.id));
      this.selectedItemFromColumn = {
        groupOptions: groupOptions,
        fields: fields,
        fieldValidators: genericItemInColumn.fieldValidators,
      };
    } else {
      this.selectedItemFromColumn = genericItemInColumn;
    }
    this.sidebarColumnWasSelected.next(this.selectedItemFromColumn);
  }
}
