import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilderField, FormBuilderItem, ModalType, Themes } from '@iapplication2/interfaces';
import { CanvasService, DarkThemeService, InteractiveFormBuilderService } from '@iapplication2/services';
import { MenuItem } from 'primeng/api';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-form-builder-field-display',
  templateUrl: './form-builder-field-display.component.html',
  styleUrls: ['./form-builder-field-display.component.scss'],
})
export class FormBuilderFieldDisplayComponent implements OnInit, OnDestroy {
  @Input() formBuilderField: FormBuilderField;
  @Input() selectedCanvasObject: FormBuilderField;
  @Input() selectedFormField: FormBuilderField;
  @Output() deletedField: EventEmitter<number> = new EventEmitter();
  items: MenuItem[];
  isDarkTheme: boolean;

  private unsubscribe: Subject<unknown> = new Subject();

  constructor(
    private canvasService: CanvasService,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private darkThemeService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.darkThemeService.themeChanged.subscribe(value => {
      this.isDarkTheme = value === Themes.DARK;
    });

    this.createFieldSettingsMenu();
  }

  highlightFieldOnCanvas(): void {
    this.canvasService.hoveredOverFormBuilderField(this.formBuilderField);
  }

  unHighlightFieldOnCanvas(): void {
    this.canvasService.leftHoverOverFormBuilderField(this.formBuilderField);
  }

  toggleMenu(item: FormBuilderField, menu: any, ev: any): void {
    this.setEditOrViewMenuOption(item);

    const editMenuItem = this.items[0].items[0];
    const deleteMenuItem = this.items[0].items[1];
    const displayConditionsMenuItem = this.items[0].items[2];
    editMenuItem.command = () => this.editField(item, ev);
    deleteMenuItem.command = () => this.deleteField(item);
    displayConditionsMenuItem.command = () => this.openDisplayConditionsDialog(item);
    menu.toggle(ev);
  }

  private deleteField(field: FormBuilderField): void {
    this.interactiveFormBuilderService.deleteField(field);
  }

  private editField(field: FormBuilderField, ev: Event): void {
    ev.stopPropagation();
    ev.preventDefault();
    this.interactiveFormBuilderService.sidebarItemSelected(field);
    this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.EDIT);
  }

  private openDisplayConditionsDialog(item: FormBuilderItem) {
    this.interactiveFormBuilderService.showDisplayConditionsDialog.next(void 0);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions = new ReplaySubject(1);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions.next(item);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions.complete();
  }

  private createFieldSettingsMenu(): void {
    this.items = [
      {
        label: 'Field settings',
        items: [
          {
            label: 'Edit field',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete field',
            icon: 'pi pi-trash',
          },
          {
            label: 'Set display conditions',
            icon: 'pi pi-sitemap',
          },
        ],
      },
    ];
  }

  setEditOrViewMenuOption(item: FormBuilderField) {
    if (this.items && this.items[0]?.items[0]) {
      this.items[0].items[0].label = item?.predefinedFieldId ? 'View field' : 'Edit field';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
