import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FieldDetailsOptions,
  FormBuilderField,
  FormBuilderFieldGroupOptions,
  FormBuilderFieldOptions,
  FormBuilderGroup,
  ModalType,
  FormBuilderFieldTypeType,
} from '@iapplication2/interfaces';
import { InteractiveFormBuilderService } from '@iapplication2/services';
import { FormHelper } from '@iapplication2/superclass';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-field-details-group',
  templateUrl: './field-details-group.component.html',
  styleUrls: ['./field-details-group.component.scss'],
})
export class FieldDetailsGroupComponent extends FormHelper implements OnInit, OnDestroy, OnChanges {
  @Input() fieldGroup: FormBuilderGroup;
  @Input() isInEditMode = false;
  @Input() mainForm: FormGroup;
  @Input() isInModal = false;
  @Input() isInFieldBuilder?: boolean = false;
  @Input() modalType: ModalType;

  @Output()
  modifiedGroup: EventEmitter<FormBuilderGroup> = new EventEmitter<FormBuilderGroup>();

  modalTypeEnum = ModalType;
  isInGroupOptionEdit = false;
  fieldItems: MenuItem[];
  newOptionsMenuModel: MenuItem[];

  settingsDialogOptions: ModalType = ModalType.EDIT;
  selectedField: FormBuilderField;
  isFieldDialogOpen = false;

  formValidity = true;
  newOptions: string[] = [];

  newOptionForm: FormGroup;
  fieldLabelForm: FormGroup;

  displayDeleteOption = false;

  isGroupFromTable = false;

  FieldDetailsOptionsEnum = FieldDetailsOptions;
  FormBuilderFieldTypeTypeEnum = FormBuilderFieldTypeType;

  requiredAndDefault = FieldDetailsOptions.MARKED_AS_REQUIRED + ' ' + FieldDetailsOptions.MARKED_AS_DEFAULT;
  defaultAndRequired = FieldDetailsOptions.MARKED_AS_DEFAULT + ' ' + FieldDetailsOptions.MARKED_AS_REQUIRED;

  private unsubscribe = new Subject<void>();

  constructor(private interactiveFormBuilderService: InteractiveFormBuilderService) {
    super();
  }

  ngOnInit(): void {
    this.createGroupOptionForm();
    this.createFieldLabelForm();
    this.createFieldSettingsMenu();
    this.mainForm.controls['groupForm'] = new FormGroup({
      name: new FormControl(null, [Validators.required]),
    });
    this.mainForm.controls['groupForm'] = this.fillFormWithObjectValues<FormBuilderFieldGroupOptions>(
      <FormGroup>this.mainForm.controls['groupForm'],
      this.fieldGroup?.groupOptions
    );
    this.toggleOptionDeletionPossibility();
    this.isGroupFromTable = this.fieldGroup.fields.some((field) => field.table?.type);
  }

  createGroupOptionForm(): void {
    this.newOptionForm = new FormGroup({
      label: new FormControl(null, [Validators.required]),
    });
  }

  createFieldLabelForm(): void {
    this.fieldLabelForm = new FormGroup({
      label: new FormControl(null, [Validators.required]),
    });
  }

  toggleMenu(item: FormBuilderField, menu: any, ev: any): void {
    const editMenuItem = this.fieldItems[0].items[0];
    const markAsDefaultValue = this.fieldItems[0].items[1];
    const markAsRequiredValue = this.fieldItems[0].items[2];
    const deleteMenuItem = this.fieldItems[0].items[3];
    editMenuItem.command = () => this.editField(item);
    markAsDefaultValue.command = () => this.toggleDefaultValue(item);
    markAsRequiredValue.command = () => this.toggleRequiredValue(item);
    deleteMenuItem.command = () => this.deleteField(item);
    this.setMenuLabelForDefaultValue(item);
    this.setMenuLabelForRequiredValue(item);
    menu.toggle(ev);
  }

  private setMenuLabelForDefaultValue(item: FormBuilderField) {
    this.fieldItems[0].items[1].disabled = this.fieldGroup.groupOptions.type.type === FormBuilderFieldTypeType.DISCLOSURE;

    if (item.options.fieldDetails === FieldDetailsOptions.MARKED_AS_DEFAULT) {
      this.fieldItems[0].items[1].label = 'Remove default value mark from option';
    } else {
      this.fieldItems[0].items[1].label = 'Mark option as default value';
    }
  }

  private setMenuLabelForRequiredValue(item: FormBuilderField) {
    if (item.options.fieldDetails === FieldDetailsOptions.MARKED_AS_REQUIRED) {
      this.fieldItems[0].items[2].label = 'Remove required value mark from option';
    } else {
      this.fieldItems[0].items[2].label = 'Mark option as required option';
    }
  }

  private toggleRequiredValue(field: FormBuilderField): void {
    this.fieldGroup.fields.forEach((fieldInGroup: FormBuilderField) => {
      if (fieldInGroup === field) {
        if (fieldInGroup.options.fieldDetails.includes(FieldDetailsOptions.MARKED_AS_REQUIRED)) {
          fieldInGroup.options.fieldDetails = fieldInGroup.options.fieldDetails.replace(FieldDetailsOptions.MARKED_AS_REQUIRED, '').trim();
        } else {
          fieldInGroup.options.fieldDetails = fieldInGroup.options.fieldDetails
            ? fieldInGroup.options.fieldDetails.concat(' ', FieldDetailsOptions.MARKED_AS_REQUIRED)
            : FieldDetailsOptions.MARKED_AS_REQUIRED;
        }
      } else {
        fieldInGroup.options.fieldDetails = fieldInGroup.options.fieldDetails.replace(FieldDetailsOptions.MARKED_AS_REQUIRED, '').trim();
      }
    });
  }

  private toggleDefaultValue(field: FormBuilderField): void {
    this.fieldGroup.fields.forEach((fieldInGroup: FormBuilderField) => {
      if (fieldInGroup === field) {
        if (fieldInGroup.options.fieldDetails.includes(FieldDetailsOptions.MARKED_AS_DEFAULT)) {
          fieldInGroup.options.fieldDetails = fieldInGroup.options.fieldDetails.replace(FieldDetailsOptions.MARKED_AS_DEFAULT, '').trim();
        } else {
          fieldInGroup.options.fieldDetails = fieldInGroup.options.fieldDetails
            ? fieldInGroup.options.fieldDetails.concat(' ', FieldDetailsOptions.MARKED_AS_DEFAULT)
            : FieldDetailsOptions.MARKED_AS_DEFAULT;
        }
      } else {
        fieldInGroup.options.fieldDetails = fieldInGroup.options.fieldDetails.replace(FieldDetailsOptions.MARKED_AS_DEFAULT, '').trim();
      }
    });
  }

  private deleteField(field: FormBuilderField): void {
    switch (this.isInFieldBuilder) {
      case true:
        this.fieldGroup.fields = this.fieldGroup.fields.filter((groupField) => groupField !== field);
        this.interactiveFormBuilderService.updateSidebarList();
        break;
      case false:
        switch (this.isInModal) {
          case true:
            this.fieldGroup.fields = this.fieldGroup.fields.filter((fieldGroupField) => fieldGroupField !== field);
            break;
          case false:
            field.toDelete = true;
            this.fieldGroup.fields = this.fieldGroup.fields.filter((fieldGroupField) => fieldGroupField !== field);
            break;
          default:
            break;
        }
        break;
    }
    this.toggleOptionDeletionPossibility();
  }

  private editField(field: FormBuilderField): void {
    // TODO: REFACTOR
    this.fieldLabelForm.controls['label'].setValidators([Validators.required]);
    field.isGroupOptionInEdit = true;
    this.fieldLabelForm.controls['label'].setValue(field.options.customFieldLabel);
    this.fieldItems[0].items[0].disabled = true;
  }

  saveOptionChange(field: FormBuilderField): void {
    // TODO: REFACTOR
    switch (this.isInFieldBuilder) {
      case true:
        // TODO:FIX AS IT DOES NOTHING
        this.updateField(field);
        field.markedForUpdate = true;
        this.interactiveFormBuilderService.updateSidebarList();
        break;
      case false:
        switch (this.isInModal) {
          case true:
            this.updateField(field);
            break;
          case false:
            this.updateField(field);
            field.markedForUpdate = true;
            break;
          default:
            break;
        }
        break;
    }

    this.fieldItems[0].items[0].disabled = false;
    field.isGroupOptionInEdit = false;
  }

  updateField(field: FormBuilderField): void {
    const newLabel = this.fieldLabelForm.controls['label'].value;

    this.fieldGroup.fields.find((fieldGroup) => {
      if (fieldGroup === field) {
        fieldGroup.options.customFieldLabel = newLabel;
        fieldGroup.options.customFieldName = _.camelCase(newLabel);
      }
    });
  }

  getUpdatedField(field: FormBuilderField): FormBuilderField {
    const newLabel = this.fieldLabelForm.controls['label'].value;

    let updatedField: FormBuilderField;

    this.fieldGroup.fields.find((fieldGroup) => {
      if (fieldGroup === field) {
        updatedField = _.cloneDeep(fieldGroup);
        updatedField.options.customFieldLabel = newLabel;
        updatedField.options.customFieldName = _.camelCase(newLabel);
      }
    });
    return updatedField;
  }

  addOption(): void {
    const newField: FormBuilderField = this.createField();

    this.fieldGroup.fields.push(newField);

    this.resetOptionsFormLabelValue();
    this.toggleOptionDeletionPossibility();
  }

  toggleOptionDeletionPossibility(): void {
    const numberOfFieldsInProvidedFieldGroup: number = this.fieldGroup?.fields?.length;
    this.fieldItems[0].items[3].disabled = numberOfFieldsInProvidedFieldGroup <= 2;
  }

  resetOptionsFormLabelValue(): void {
    this.newOptionForm?.controls['label'].setValue(undefined);
  }

  createField(): FormBuilderField {
    // TODO: REFACTOR
    const newOptions: FormBuilderFieldOptions = {
      customFieldLabel: this.newOptionForm.get('label').value,
      customFieldName: _.camelCase(this.newOptionForm.get('label').value),
      visibleOnPdf: true,
      fieldDetails: '',
    };
    const groupOptions = this.fieldGroup.fields[0] ? this.fieldGroup.fields[0].groupOptions : this.fieldGroup.groupOptions;
    const newField: FormBuilderField = {
      options: newOptions,
      groupOptions: groupOptions,
      fieldType: this.fieldGroup.fields[0] ? this.fieldGroup.fields[0].fieldType : groupOptions.type,
    };
    return newField;
  }

  closeFieldEditDialog(): void {
    this.isFieldDialogOpen = false;
    this.isInGroupOptionEdit = false;
  }

  private createFieldSettingsMenu(): void {
    this.fieldItems = [
      {
        label: 'Options settings',
        items: [
          {
            label: 'Edit option',
            icon: 'pi pi-pencil',
          },
          this.getDefaultOptionMenuEntry(),
          this.getRequiredOptionMenuEntry(),
          {
            label: 'Delete option',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
  }

  private getRequiredOptionMenuEntry(): MenuItem {
    if (this.fieldGroup.groupOptions.type.type !== FormBuilderFieldTypeType.DISCLOSURE) {
      return {
        label: 'Mark option as required value',
        icon: 'pi pi-flag',
      };
    }
    return {
      label: 'Mark option as the required disclosure option',
      icon: 'pi pi-flag',
    };
  }

  private getDefaultOptionMenuEntry(): MenuItem {
    return {
      label: 'Mark option as default value',
      icon: 'pi pi-star',
    };
  }

  newOptionFormInputValueChanged(): void {
    if (this.newOptionForm.get('label').errors?.duplicateFieldAndGroupOptionLabel || this.newOptionForm.get('label').errors?.required) {
      this.formValidity = false;
    } else {
      this.formValidity = true;
    }
  }

  groupNameInputValueChanged(): void {
    const newGroupName = this.mainForm.controls['groupForm'].get('name').value;

    switch (this.isInFieldBuilder) {
      case true:
        this.fieldGroup.groupOptions.name = newGroupName;
        break;
      case false:
        switch (this.isInModal) {
          case true:
            this.fieldGroup.groupOptions.name = newGroupName;
            this.fieldGroup.fields.forEach((field) => (field.groupOptions = this.fieldGroup.groupOptions));
            break;
          case false:
            this.fieldGroup.groupOptions.name = newGroupName;
            break;
          default:
            break;
        }
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.fieldGroup?.isFirstChange() && changes?.fieldGroup?.currentValue != changes?.fieldGroup?.previousValue) {
      this.mainForm.controls['groupForm'].get('name').setValue(this.fieldGroup.groupOptions.name);
    }
  }
}
