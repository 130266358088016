/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { StringRegexConstants } from '@iapplication2/constants';

export class StringValidator {
  public static firstNamePart(): ValidatorFn {
    const regexp = /(^[A-Za-z][A-Za-z'-\s]*$)|(^\d*$)/i;

    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;

      return value && !new RegExp(regexp).test(value) ? { invalidFirstName: value } : null;
    };
  }

  public static namePart(): ValidatorFn {
    const regexp = /^[a-z ,.'-]+$/i;

    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;

      return value && !new RegExp(regexp).test(value) ? { invalidLastName: value } : null;
    };
  }

  public static requiredNoOnlySpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;

      return (value && value.length === 0) || StringRegexConstants.ONLY_SPACES.test(value) ? { required: true } : null;
    };
  }

  public static creditCard(): ValidatorFn {
    const amexRegex = /^3[47][0-9]{13}$/i;
    const BCGlobalRegex = /^(6541|6556)[0-9]{12}$/i;
    const carteBlancheRegex = /^389[0-9]{11}$/i;
    const dinersClubRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/i;
    const discoverRegex =
      /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/i;
    const instaPaymentRegex = /^63[7-9][0-9]{13}$/i;
    const jCBRegex = /^(?:2131|1800|35\d{3})\d{11}$/i;
    const koreanLocalRegex = /^9[0-9]{15}$/i;
    const laserRegex = /^(6304|6706|6709|6771)[0-9]{12,15}$/i;
    const maestroRegex = /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/i;
    const mastercard = /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/i;
    const soloRegex = /^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/i;
    const switchRegex =
      /^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/i;
    const unionPayRegex = /^(62[0-9]{14,17})$/i;
    const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/i;
    const visaMasterRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/i;

    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;

      const anyRegexValid =
        new RegExp(amexRegex).test(value) ||
        new RegExp(BCGlobalRegex).test(value) ||
        new RegExp(carteBlancheRegex).test(value) ||
        new RegExp(dinersClubRegex).test(value) ||
        new RegExp(discoverRegex).test(value) ||
        new RegExp(instaPaymentRegex).test(value) ||
        new RegExp(jCBRegex).test(value) ||
        new RegExp(koreanLocalRegex).test(value) ||
        new RegExp(laserRegex).test(value) ||
        new RegExp(maestroRegex).test(value) ||
        new RegExp(mastercard).test(value) ||
        new RegExp(soloRegex).test(value) ||
        new RegExp(switchRegex).test(value) ||
        new RegExp(unionPayRegex).test(value) ||
        new RegExp(visaRegex).test(value) ||
        new RegExp(visaMasterRegex).test(value);

      return value && !anyRegexValid ? { invalidCard: value } : null;
    };
  }
}
