import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  ApplicationFormData,
  ApplicationProduct,
  CardResponse,
  ENV,
  FieldTypeEnum,
  FormArea,
  FormBuilderField,
  FormBuilderFieldTypeType,
  FormBuilderItem,
  FormGroupStatus,
  FormSection,
  LanguagesEnum,
  PaymentMethods,
  PaymentPayload,
  Product,
  SectionTypes,
  StaticFieldLinkType,
  envType,
} from '@iapplication2/interfaces';
import {
  ApplicationsProcessService,
  CanvasService,
  DatesService,
  FormFieldConditionsService,
  InteractiveFormBuilderService,
  InteractiveFormBuilderValidatorsService,
  PaymentService,
  ProgressSpinnerService,
  StaticFieldLinkService,
} from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { concat, forkJoin, Observable, Observer, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { PaymentType } from '../application-payment-popup/application-payment-popup.component';
import { STATIC_FIELD_LINK_ALTERNATE_VALUES } from '@iapplication2/constants';
import { TabView } from 'primeng/tabview';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Iapp1Ids } from '../../../../../../apps/api-service/src/app/common/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FormDisplayAreaComponent } from '../../../../../dynamic-form-display/src/lib/form-container-page/form-display-area/form-display-area.component';
import _ = require('lodash');
import { ConfirmationService } from 'primeng/api';
import { ProductsResponse } from '../client-selection/product-selection/product-selection.component';
import { APP_CONFIG } from '@iapplication2/app-config';

export interface SelectedForm {
  productTitle: string;
  formId: number;
  productFormId: number;
  sectionList: FormSection[];
  position: number;
}
@Component({
  selector: 'iapplication2-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
  providers: [ConfirmationService],
})
export class ApplicationFormComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() applicationId: number;
  @Input() isViewApplication = false;
  @Output() signedAtDateFormData: EventEmitter<string[]> = new EventEmitter();
  @Output() formLoadingStateChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() getApplicationTriggered: EventEmitter<boolean> = new EventEmitter();
  @Output() waitingForChanges: EventEmitter<boolean> = new EventEmitter();

  unsubscribe: Subject<unknown> = new Subject();
  applicationFormGroup: FormGroup = new FormGroup({});
  displayModal = false;
  recordingPaused = false;
  selectedProducts: Product[];
  selectedLanguage;
  selectableForms: SelectedForm[] = [];
  hiddenFields: { [key: number]: number[] } = {};
  currentlySelectedForm: SelectedForm;
  formDataObject: { [key: string]: ApplicationFormData[] };
  listOfItems: FormBuilderItem[] = [];
  paymentErrorMessage: string;
  displayInvalidFormMessage = false;
  loading = true;
  applicationFormInViewIsLoaded = false;
  tabViewIndex = 0;
  @ViewChild('tabView') tabView: TabView;
  @ViewChildren(FormDisplayAreaComponent) areasDom: QueryList<FormDisplayAreaComponent>;
  scrollToFirstInvalidField: Subject<void> = new Subject();
  startNavigateThroughBreadcrumbs: Subject<string[]> = new Subject();
  possiblePaymentMethods: string[] = [];

  handleDebouncedAutosave = _.debounce(() => {
    this.waitingForChanges.emit(false);
    this.handleAutosave();
  }, 2000);
  triggerStaticFieldLinkDebounced = _.debounce(() => {
    this.waitingForChanges.emit(false);
    this.triggerStaticFieldLink();
  }, 1000);
  clearHiddenFieldValidationsDebounced = _.debounce(() => this.clearHiddenFieldValidations(), 1100);

  paymentForm: FormGroup;

  paymentTypeEnum = PaymentType;
  FormGroupType = FormGroup;
  sectionTypesEnum = SectionTypes;
  envType = envType;

  fieldIdsWithErrorInSection = [];
  invalidFieldsTooltip: string;

  selectedPaymentMethod: string | null;
  paymentText: {
    pad: string;
    creditCard: string;
    paymentResetConfirmationText: {
      message: string;
      acceptLabel: string;
      rejectLabel: string;
    };
  } = {
    pad: '',
    creditCard: '',
    paymentResetConfirmationText: {
      message: '',
      acceptLabel: '',
      rejectLabel: '',
    },
  };

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private applicationsProcessService: ApplicationsProcessService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private validatorsService: InteractiveFormBuilderValidatorsService,
    private formFieldConditionsService: FormFieldConditionsService,
    private canvasService: CanvasService,
    private staticFieldLinkService: StaticFieldLinkService,
    private paymentService: PaymentService,
    private progressSpinnerService: ProgressSpinnerService,
    private confirmationService: ConfirmationService,
    private datesService: DatesService,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit(): void {
    this.applicationsProcessService.isViewApplication.next(this.isViewApplication);
    this.formLoadingStateChanged.emit(true);
    this.selectedLanguage = this.translate.currentLang;

    if (this.isViewApplication) {
      this.handleViewApplicationPage();
    } else {
      this.applicationsProcessService.selectedProducts.pipe(takeUntil(this.unsubscribe)).subscribe((res: Product[]) => {
        this.selectedProducts = res;
        this.createForm();
      });
    }

    this.interactiveFormBuilderService.itemValueUpdated.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.triggerPredefinedLink(res);
    });
    this.watchStepChange();

    this.applicationsProcessService.saveFormCalledFromStep2.subscribe((value) => {
      if (value) this.handleSave();
    });
    this.watchScrollToInvalidFieldSubscriptions();
    this.getTranslations();
    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  }

  createForm() {
    this.selectedProducts.sort((a, b) => a.id - b.id);
    this.mapProductPricingInfoToStaticLink();
    this.extractFormsFromProductsBasedOnLanguage();
    this.preparePredefinedLinkObject();
    this.applicationsProcessService
      .getFormData(this.applicationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: { [key: string]: ApplicationFormData[] }) => {
        this.formDataObject = res;

        this.prepareFormObjects();
        this.currentlySelectedForm = this.selectableForms[0];
      });
  }

  handleViewApplicationPage() {
    this.applicationsProcessService.applicationChanged.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res) => {
        if (!res) return;
        if (res?.products && !this.applicationFormInViewIsLoaded) {
          this.selectedProducts = [];
          const products: ApplicationProduct[] = [];

          res.products.forEach((product) => {
            products.push(product);
          });

          this.applicationsProcessService
            .getProductsByClientDOB(this.applicationsProcessService.currentApplication?.client.birthDate)
            .pipe(take(1))
            .subscribe((result: ProductsResponse) => {
              const allProducts = [...result?.products, ...result?.productsEvolution];

              const filteredProducts = [];
              products.forEach((product) => {
                const index = allProducts.findIndex((obj) => obj?.iappProduct?.id === product?.productId);
                if (index !== -1) {
                  filteredProducts.push(allProducts[index]?.iappProduct);
                }
              });

              this.selectedProducts = filteredProducts;
              this.createForm();
              this.applicationFormGroup.disable();
              this.applicationFormInViewIsLoaded = true;
            });
        }
      },
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  openPaymentInformation() {
    if (this.applicationsProcessService.currentApplication) {
      this.selectedPaymentMethod = this.applicationsProcessService.currentApplication.products?.find(
        (product: ApplicationProduct) => product.payment?.method
      )?.payment?.method;
      this.getPaymentTranslations();

      if (this.selectedPaymentMethod) {
        this.showpaymentResetConfirmation();
      } else {
        this.showModalDialog();
      }
    }
  }

  showModalDialog() {
    this.paymentErrorMessage = '';
    this.displayModal = true;
    this.paymentService.pauseRecording().subscribe({
      next: (res) => {
        if (res) {
          this.recordingPaused = true;
        }
      },
      error: () => (this.recordingPaused = false),
    });
  }

  private showpaymentResetConfirmation() {
    this.confirmationService.confirm({
      target: event.target,
      message: this.paymentText.paymentResetConfirmationText.message,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.paymentText.paymentResetConfirmationText.acceptLabel,
      rejectLabel: this.paymentText.paymentResetConfirmationText.rejectLabel,
      accept: () => {
        this.showModalDialog();
      },
    });
  }

  paymentDialogClosed() {
    this.paymentService.paymentFormFinished.next();
    this.paymentService.resumeRecording().subscribe({
      next: (res) => {
        if (res) {
          this.recordingPaused = false;
        }
      },
      error: () => (this.recordingPaused = true),
    });
  }

  paymentDialogClosedNoSave() {
    this.displayModal = false;
  }

  forceRecordingPause() {
    this.recordingPaused = true;
  }

  getPossiblePaymentMethods() {
    this.listOfItems.filter((item: FormBuilderField) => {
      if (item.staticValueLink?.fieldKey && item.staticValueLink?.iapp1Id === Iapp1Ids.PAYMENT_METHOD) {
        const alternateStaticFieldGroupValues = STATIC_FIELD_LINK_ALTERNATE_VALUES;

        let possibleValues = [_.camelCase(item.options.customFieldLabel)];

        if (alternateStaticFieldGroupValues[Iapp1Ids.PAYMENT_METHOD]) {
          const possibleValueBranches = alternateStaticFieldGroupValues[Iapp1Ids.PAYMENT_METHOD];

          Object.values(possibleValueBranches).forEach((branch: string[]) => {
            if (branch.includes(this.prepareValueForComparison(item.options.customFieldLabel))) {
              possibleValues = branch;
            }
          });
        }

        possibleValues.forEach((possibleValue: string) => {
          if (!this.possiblePaymentMethods.includes(possibleValue)) {
            this.possiblePaymentMethods = [...this.possiblePaymentMethods, possibleValue];
          }
        });
      }
    });
  }

  onPaymentMethodChanged(form: FormGroup) {
    const paymentMethodType = form.get('type').value;
    this.paymentForm = form;
    this.paymentErrorMessage = '';
    this.paymentForm.markAsPristine();

    this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(
      StaticFieldLinkType.PAYMENT_INFO,
      Iapp1Ids.PAYMENT_METHOD,
      paymentMethodType
    );
  }

  onPaymentFormFinished(form: FormGroup) {
    this.paymentErrorMessage = '';
    switch (form.get('type').value) {
      case PaymentType.PAD:
        this.removeCreditCardInformationFromApplication();
        this.sendPADFormValuesToStaticLink(form);
        break;
      case PaymentType.CARD:
        this.clearPADValuesOnStaticLink(form);
    }
    this.saveApplicationPaymentDetails(form.get('type').value);
    this.waitingForChanges.emit(true);
    this.triggerStaticFieldLinkDebounced();
    this.displayModal = false;
  }

  saveApplicationPaymentDetails(paymentMethod: PaymentMethods) {
    if (paymentMethod) {
      this.applicationsProcessService.currentApplication?.products?.forEach((product) => {
        product.payment.method = paymentMethod;
      });
      this.applicationsProcessService.updateApplication(this.applicationId, this.applicationsProcessService.currentApplication).subscribe();
    }
  }

  clearPADValuesOnStaticLink(form: FormGroup) {
    const controlsInPADForm = (form.get('PADForm') as FormGroup).controls;
    Object.keys(controlsInPADForm).forEach((key: string) => {
      this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.PAYMENT_INFO, key, null);
    });
  }

  sendPADFormValuesToStaticLink(form: FormGroup) {
    const controlsInPADForm = (form.get('PADForm') as FormGroup).controls;
    Object.keys(controlsInPADForm).forEach((key: string) => {
      if (controlsInPADForm[key].value) {
        this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(StaticFieldLinkType.PAYMENT_INFO, key, controlsInPADForm[key].value);
      }
    });
  }

  setCreditCardInformationOnApplication(cardResponse: CardResponse) {
    // TODO: Refactor this to only be applied to current product when we allow for multiple payments
    this.applicationsProcessService.currentApplication.products.forEach((product: ApplicationProduct) => {
      product.payment.cardResponse = cardResponse;
    });
  }

  removeCreditCardInformationFromApplication() {
    // They need to be temporarility be deleted one by one as there can be other values inside the payment object, such as value or payment method.
    // This could've been improved, but it will be refactored when we will support multiple payments and will no longer save the payment per application
    delete this.applicationsProcessService.currentApplication.payment?.cardholderId;
    delete this.applicationsProcessService.currentApplication.payment?.sslResult;
    delete this.applicationsProcessService.currentApplication.payment?.success;
    delete this.applicationsProcessService.currentApplication.payment?.token;

    if (
      this.applicationsProcessService.currentApplication.payment &&
      Object.keys(this.applicationsProcessService.currentApplication.payment)?.length
    ) {
      delete this.applicationsProcessService.currentApplication.payment;
    }
    this.applicationsProcessService.currentApplication.products.forEach((product: ApplicationProduct) => {
      delete product.payment.cardResponse;
    });
  }

  submitPayment() {
    if (this.paymentForm.get('type').value === PaymentType.CARD && this.paymentForm?.controls['cardForm']?.valid) {
      this.paymentErrorMessage = '';
      const paymentPayload: PaymentPayload = {
        cc_number: this.paymentForm.get('cardForm').get('cardNumber').value,
        cc_cvv: this.paymentForm.get('cardForm').get('cvvNumber').value,
        cc_exp_month: this.paymentForm.get('cardForm').get('expirationMonth').value,
        cc_exp_year: this.paymentForm.get('cardForm').get('expirationYear').value,
        client_postal_code: this.paymentForm.get('cardForm').get('holderZipCode').value,
        client_address: this.paymentForm.get('cardForm').get('holderAddress').value,
        client_first_name: this.paymentForm.get('cardForm').get('holderFirstName').value,
        client_last_name: this.paymentForm.get('cardForm').get('holderLastName').value,
      };

      this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
      this.paymentService.createToken(paymentPayload, this.applicationId).subscribe({
        next: (res: any) => {
          this.setCreditCardInformationOnApplication(res.body);
        },
        complete: () => {
          this.paymentDialogClosed();
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        },
        error: (e) => {
          if (e?.error?.errorMessage) {
            this.paymentErrorMessage = e.error.errorMessage;
          } else {
            this.paymentErrorMessage = this.translate.instant('applicationProcess.step.applicationForms.payment.card.generalError');
          }
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        },
      });
    }
  }

  donePAD() {
    if (this.paymentForm?.controls['PADForm']?.valid) {
      this.paymentDialogClosed();
    }
  }

  continueForm() {
    this.clearHiddenFieldValidations();

    if (this.applicationFormGroup.valid) {
      this.triggerCompletedAtTime();
    } else {
      this.displayInvalidFormMessage = this.applicationFormGroup.get(this.currentlySelectedForm.productFormId.toString()).invalid;
      this.scrollToFirstInvalidField.next();
      this.applicationFormGroup.markAllAsTouched();
      this.markAllInvalidAsDirty(this.applicationFormGroup);
      this.applicationFormGroup.updateValueAndValidity({ emitEvent: false });
    }
  }

  triggerCompletedAtTime() {
    if (this.applicationsProcessService.currentApplication?.completedAt) {
      this.onContinueFormValid();
    } else {
      this.applicationsProcessService.triggerCompletetAtDateSave(this.applicationsProcessService.currentApplication?.id).subscribe({
        next: () => {
          this.getApplicationTriggered.emit(true);
          this.onContinueFormValid();
        },
      });
    }
  }

  onContinueFormValid() {
    this.applicationsProcessService.formValidCanMoveToSummary.next(true);
    this.generatePdf();
    this.displayInvalidFormMessage = false;
  }

  watchScrollToInvalidFieldSubscriptions() {
    this.scrollToFirstInvalidField.pipe(takeUntil(this.unsubscribe), debounceTime(200)).subscribe({
      next: () => {
        this.moveToFirstInvalidField();
      },
    });
    this.startNavigateThroughBreadcrumbs.pipe(takeUntil(this.unsubscribe), debounceTime(200)).subscribe({
      next: (res) => {
        this.navigateThroughBreadCrumbs(res);
      },
    });
  }

  moveToFirstInvalidField() {
    const emptyBreadcrumbs: string[] = [];
    let foundBreadcrumbs = [];
    if (this.isCurrentlyLoadedProductFormValid()) {
      foundBreadcrumbs = this.findBreadcrumbsOfFirstInvalidControl(this.applicationFormGroup, emptyBreadcrumbs);
    } else {
      emptyBreadcrumbs.push(this.currentlySelectedForm.productFormId.toString());
      const groupOfCurrentlySelectedForm = this.applicationFormGroup.get(this.currentlySelectedForm.productFormId.toString());
      foundBreadcrumbs = this.findBreadcrumbsOfFirstInvalidControl(groupOfCurrentlySelectedForm, emptyBreadcrumbs);
    }
    if (foundBreadcrumbs.length) {
      this.startNavigateThroughBreadcrumbs.next(foundBreadcrumbs);
    }
  }

  navigateThroughBreadCrumbs(breadcrumbs: string[]) {
    if (this.isCurrentProductInvalid(breadcrumbs)) {
      const sectionIndex = this.getSectionIndex(breadcrumbs);
      if (this.isFirstInvalidSectionAlreadyActive(sectionIndex)) {
        this.navigateToAreaAndField(breadcrumbs);
      } else {
        this.navigateToSection(sectionIndex, breadcrumbs);
      }
    } else {
      this.selectInvalidProduct(breadcrumbs[0], breadcrumbs);
    }
  }

  selectInvalidProduct(productFormId: string, breadcrumbs: string[]) {
    this.currentlySelectedForm = this.selectableForms.find((selectableForm) => selectableForm.productFormId.toString() === productFormId);
    this.sortFormAreas(this.currentlySelectedForm);
    this.startNavigateThroughBreadcrumbs.next(breadcrumbs);
  }

  private findBreadcrumbsOfFirstInvalidControl(currentFormControl: AbstractControl, breadcrumbs: string[]): string[] {
    if (currentFormControl.invalid) {
      const childControls = (currentFormControl as FormGroup).controls;
      if (childControls) {
        const childControlsArray = Object.entries(childControls);
        if (this.breadcrumbsContainProduct(breadcrumbs)) {
          this.sortSectionControlsArrayBasedOnSectionList(childControlsArray);
        }
        if (this.breadcrumbsContainArea(breadcrumbs)) {
          this.sortControlsArrayBasedOnAreaItems(childControlsArray, breadcrumbs);
        }
        const firstInvalidChild = childControlsArray.find((control) => control[1].invalid);
        if (firstInvalidChild) {
          breadcrumbs.push(firstInvalidChild[0]);
          return this.findBreadcrumbsOfFirstInvalidControl(firstInvalidChild[1], breadcrumbs);
        }
      } else {
        return breadcrumbs;
      }
    }
  }

  private breadcrumbsContainProduct(breadcrumbs) {
    return breadcrumbs.length === 1;
  }

  private breadcrumbsContainArea(breadcrumbs) {
    return breadcrumbs.length === 3;
  }

  private isCurrentProductInvalid(breadcrumbs: string[]) {
    return this.currentlySelectedForm.productFormId.toString() === breadcrumbs[0];
  }

  private isFirstInvalidSectionAlreadyActive(sectionIndex: number) {
    return this.tabView.activeIndex === sectionIndex;
  }

  private navigateToSection(sectionIndex: number, breadcrumbs: string[]) {
    this.tabViewIndex = sectionIndex;
    this.startNavigateThroughBreadcrumbs.next(breadcrumbs);
  }

  private getSectionIndex(breadcrumbs) {
    return this.currentlySelectedForm.sectionList.findIndex((section) => section.id.toString() === breadcrumbs[1]);
  }

  private isCurrentlyLoadedProductFormValid() {
    return this.applicationFormGroup.get(this.currentlySelectedForm.productFormId.toString()).valid;
  }

  private navigateToAreaAndField(breadcrumbs: string[]) {
    const invalidArea = this.areasDom.find(
      (areaComponent: FormDisplayAreaComponent) => areaComponent.area.id.toString() === breadcrumbs[2]
    );
    if (invalidArea) {
      invalidArea.navigateToField(breadcrumbs[3]);
    }
  }

  private sortSectionControlsArrayBasedOnSectionList(childControlsArray: [string, AbstractControl][]) {
    childControlsArray.sort((a, b) => {
      const indexOfFirstSectionControl = this.currentlySelectedForm.sectionList.findIndex((section) => section.id.toString() === a[0]);
      const indexOfSecondSectionControl = this.currentlySelectedForm.sectionList.findIndex((section) => section.id.toString() === b[0]);
      return indexOfFirstSectionControl - indexOfSecondSectionControl;
    });
  }

  private sortControlsArrayBasedOnAreaItems(childControlsArray: [string, AbstractControl][], breadcrumbs: string[]) {
    const invalidArea = this.areasDom.find(
      (areaComponent: FormDisplayAreaComponent) => areaComponent.area.id.toString() === breadcrumbs[2]
    );
    if (invalidArea) {
      childControlsArray.sort((a, b) => {
        const indexOfFirstControl = invalidArea.itemsInArea.findIndex((item) => item.control === a[1]);
        const indexOfSecondConrol = invalidArea.itemsInArea.findIndex((item) => item.control === b[1]);
        return indexOfFirstControl - indexOfSecondConrol;
      });
    }
  }

  saveApplication() {
    this.applicationsProcessService
      .updateApplication(this.applicationId, this.applicationsProcessService.currentApplication)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  private markAllInvalidAsDirty(item: any) {
    if (item.invalid) {
      item.markAsDirty();
    }

    if (item.controls) {
      const controls = Object.values(item.controls);
      if (controls) {
        controls.forEach((control) => {
          this.markAllInvalidAsDirty(control);
        });
      }
    }
  }

  private prepareFormObjects() {
    const $obs: Observable<unknown>[] = [];
    this.selectableForms.forEach((selectedForm) => {
      $obs.push(this.getSectionsByProductFormId(selectedForm).pipe(takeUntil(this.unsubscribe)));
    });
    concat(...$obs).subscribe({
      next: (res: SelectedForm) => {
        this.fillFormWithData(res.productFormId?.toString());
      },
      complete: () => {
        this.checkFieldsConditionsForAllProducts();
        this.watchFormChanges();
        this.sortFormAreas(this.currentlySelectedForm);
        this.setPredefinedValuesInAllProductForms();
        this.waitingForChanges.emit(true);
        this.triggerStaticFieldLinkDebounced();
        this.loading = false;
        this.formLoadingStateChanged.emit(false);
      },
    });
  }

  private sortFormAreas(selectedForm: SelectedForm) {
    selectedForm?.sectionList?.forEach((section) => {
      section?.formAreas?.sort((a, b) => a.position - b.position);
    });
  }

  private fillFormWithData(productFormId: string) {
    const formData = this.formDataObject[productFormId];
    if (formData) {
      formData.forEach((item) => {
        item.fieldId = parseInt(item.fieldId?.toString());
      });
      this.applicationsProcessService.applicationFormDataArray = [...this.applicationsProcessService.applicationFormDataArray, ...formData];
      Object.values((this.applicationFormGroup.get(productFormId) as FormGroup).controls).forEach((section) => {
        Object.values((section as FormGroup).controls).forEach((area) => {
          Object.entries((area as FormGroup).controls).forEach((item) => {
            const itemValue = this.formDataObject[productFormId].filter((valueItem) => (valueItem.fieldId as unknown) == item[0])[0];
            if (itemValue) {
              itemValue.fillValue = itemValue.value;
              if (itemValue.fieldTypeId === FieldTypeEnum.date) {
                itemValue.fillValue = new Date(this.datesService.convertDayMonthYearToYearMonthDay(itemValue.fillValue));
              }
              if (itemValue.fieldTypeId === FieldTypeEnum.checkboxGroup) {
                itemValue.fillValue = itemValue.fillValue.split(',').map((value) => parseInt(value));
              }
              if (itemValue.fieldTypeId === FieldTypeEnum.disclosure) {
                const splitValue = itemValue.fillValue.split(',').map((value) => parseInt(value));
                const disclosureItems = this.listOfItems.filter((item) => splitValue.includes(item.id));
                if (disclosureItems.every((item) => item.fieldDisclosureOption.disclosureFieldTypeId === FieldTypeEnum.checkboxGroup)) {
                  itemValue.fillValue = splitValue;
                }
              }
              item[1].setValue(itemValue.fillValue, { emitEvent: false });
            }
          });
        });
      });
    }
  }

  private extractFormsFromProductsBasedOnLanguage() {
    this.selectedProducts.forEach((product) => {
      if (this.selectedLanguage === LanguagesEnum.EN) {
        this.selectableForms.push({
          productTitle: product.lokaliseKey,
          formId: product.form,
          productFormId: product.productFormId,
          sectionList: [],
          position: product.position,
        });
        this.applicationFormGroup.addControl(product.productFormId?.toString(), new FormGroup({}));
      } else if (this.selectedLanguage === LanguagesEnum.FR) {
        this.selectableForms.push({
          productTitle: product.lokaliseKey,
          formId: product.frForm,
          productFormId: product.productFormId,
          sectionList: [],
          position: product.position,
        });
        this.applicationFormGroup.addControl(product.productFormId?.toString(), new FormGroup({}));
      }
    });
    this.selectableForms.sort(
      (selectableForm1: SelectedForm, selectableForm2: SelectedForm) => selectableForm1.position - selectableForm2.position
    );
  }

  private getSectionsByProductFormId(selectedForm: SelectedForm): Observable<SelectedForm> {
    return new Observable((observer: Observer<SelectedForm>) => {
      this.interactiveFormBuilderService
        .getFormSectionsListByProductFormId(selectedForm.productFormId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: FormSection[]) => {
          selectedForm.sectionList = res;
          selectedForm.sectionList.sort((a, b) => a.sectionFormPosition - b.sectionFormPosition);
          const observables: Observable<unknown>[] = [];
          if (!this.isViewApplication) {
            selectedForm.sectionList = selectedForm.sectionList.filter((section) => section.type.name !== SectionTypes.QUESTIONNAIRE);
          }
          selectedForm.sectionList.forEach((section) => {
            (this.applicationFormGroup.get(selectedForm.productFormId.toString()) as FormGroup).addControl(
              section.id?.toString(),
              new FormGroup({})
            );
            observables.push(this.getAreasAndFieldsBySectionId(section, selectedForm));
          });
          forkJoin(observables)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              observer.next(selectedForm);
              observer.complete();
            });
        });
    });
  }

  private setValueOnApplication(fieldToSetValueFrom: FormBuilderField, controlOfField: FormControl) {
    const fieldApplicationKey = fieldToSetValueFrom.staticValueLink?.fieldApplicationKey
      ? fieldToSetValueFrom.staticValueLink?.iapp1Id
      : null;

    if (fieldApplicationKey) {
      if (!this.applicationsProcessService.currentApplication[fieldApplicationKey] && controlOfField.value) {
        if (fieldToSetValueFrom?.groupOptions) {
          this.applicationsProcessService.currentApplication[fieldApplicationKey] = this.getFieldNameById(controlOfField?.value);
        } else {
          this.applicationsProcessService.currentApplication[fieldApplicationKey] = controlOfField.value;
        }
      }

      controlOfField.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe({
        next: (res) => {
          if (fieldToSetValueFrom?.groupOptions) {
            this.handleFieldApplicationKeyFields(fieldApplicationKey, this.getFieldNameById(res));
          } else {
            this.handleFieldApplicationKeyFields(fieldApplicationKey, res);
          }
        },
      });
    }
  }

  getFieldNameById(id: number): string {
    return this.applicationsProcessService.allFieldsInAllForms?.find((field) => field?.id === id)?.options?.customFieldName;
  }

  addProductFormToAllFields(formAreas: FormArea[], productFormId: number): FormArea[] {
    formAreas.forEach((formArea: FormArea) => {
      formArea.fields.forEach((field: FormBuilderField) => {
        field.productFormId = productFormId;
      });
    });
    return formAreas;
  }

  private getAreasAndFieldsBySectionId(section: FormSection, selectableForm: SelectedForm): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.interactiveFormBuilderService
        .getFormAreasBySectionId(section.id?.toString())
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: FormArea[]) => {
          section.formAreas = this.addProductFormToAllFields(res, selectableForm.productFormId);
          section.formAreas.forEach((area) => {
            (
              (this.applicationFormGroup.get(selectableForm.productFormId?.toString()) as FormGroup).get(
                section.id?.toString()
              ) as FormGroup
            ).addControl(area.id?.toString(), new FormGroup({}));
            if (area) {
              area.fields.forEach((field: FormBuilderField) => {
                this.listOfItems.push(field);
                if (this.interactiveFormBuilderService.isFormBuilderItemField(field)) {
                  const control = new FormControl(null, this.validatorsService.getValidators(field));
                  this.setValueOnApplication(field, control);
                  (
                    (
                      (this.applicationFormGroup.get(selectableForm.productFormId?.toString()) as FormGroup).get(
                        section.id?.toString()
                      ) as FormGroup
                    ).get(area.id?.toString()) as FormGroup
                  ).addControl(field.id?.toString(), control);
                } else if (this.interactiveFormBuilderService.isFormBuilderItemGroup(field)) {
                  const control = new FormControl(null);
                  (
                    (
                      (this.applicationFormGroup.get(selectableForm.productFormId?.toString()) as FormGroup).get(
                        section.id?.toString()
                      ) as FormGroup
                    ).get(area.id?.toString()) as FormGroup
                  ).addControl((field as FormBuilderField).groupOptions.id?.toString(), control);

                  if ((field as any)?.staticValueLink?.iapp1Id === Iapp1Ids.BILLING_FREQUENCY) {
                    this.setValueOnApplication(field, control);
                  }
                }
              });
            }
          });
          observer.next(true);
          observer.complete();

          this.getPossiblePaymentMethods();
        });
    });
  }

  private watchFormChanges(): void {
    this.onSelectedFormChange();
    this.applicationFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(() => {
      if (this.applicationFormGroup.valid) {
        this.displayInvalidFormMessage = false;
      }
      if (this.applicationFormGroup.dirty) {
        this.waitingForChanges.emit(true);
        this.handleDebouncedAutosave();
      }
      this.checkFieldsConditionsForCurrentProduct();
    });
  }

  private handleFieldApplicationKeyFields(fieldApplicationKey: string, value: any) {
    if (!this.isViewApplication) {
      this.applicationsProcessService.currentApplication[_.camelCase(fieldApplicationKey)] = value;
      this.saveApplication();
    }
  }

  private watchStepChange() {
    this.applicationsProcessService.onMovedToSummary.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: boolean) => {
        if (res) {
          this.continueForm();
        }
      },
    });
  }

  private handleAutosave() {
    this.handleSave();
  }

  handleSave() {
    const allFormsToSave = Object.entries(this.applicationFormGroup.controls);
    allFormsToSave.forEach((formControl) => {
      const currentProductFormId = formControl[0];
      const allSectionsFromForm = Object.values((formControl[1] as FormGroup).controls);
      allSectionsFromForm.forEach((sectionControl) => {
        const allAreasFromSection = Object.values((sectionControl as FormGroup).controls);
        allAreasFromSection.forEach((areaControl) => {
          const allFieldsFromArea = Object.entries((areaControl as FormGroup).controls);
          allFieldsFromArea.forEach(([fieldId, fieldControl]: [string, FormControl]) => {
            const indexOfFieldAndProductFormId = this.getDataArrayIndexByFieldIdAndProductFormId(fieldId, currentProductFormId);
            const value = this.getValueFromControl(fieldControl);
            const preparedValue = this.prepareValue(value);

            if (value || (typeof value === 'number' && (value as number) === 0)) {
              if (indexOfFieldAndProductFormId === -1) {
                this.handleAddNewValueToFormDataArray(fieldId, currentProductFormId, preparedValue);
              } else {
                this.handleUpdateExistingValueInFormDataArray(indexOfFieldAndProductFormId, currentProductFormId, preparedValue);
              }
            } else {
              if (value === '') {
                if (indexOfFieldAndProductFormId !== -1) {
                  this.handleRemoveFieldValue(indexOfFieldAndProductFormId);
                }
              }
            }
          });
        });
      });
    });
    this.applicationsProcessService.applicationFormDataArray = this.getNonNullValuesFromFormDataArray(
      this.applicationsProcessService.applicationFormDataArray
    );

    this.saveProcessedData();
  }

  private getDataArrayIndexByFieldIdAndProductFormId(fieldId: string, productFormId: string): number {
    return this.applicationsProcessService.applicationFormDataArray.findIndex(
      (element) => element?.productFormId === parseInt(productFormId) && element?.fieldId === parseInt(fieldId)
    );
  }

  private handleAddNewValueToFormDataArray(fieldId: string, currentProductFormId: string, value: string) {
    const fieldTypeId = this.getFieldTypeIdFromFieldId(fieldId);
    if (
      fieldTypeId === FieldTypeEnum.radioGroup ||
      fieldTypeId === FieldTypeEnum.checkboxGroup ||
      fieldTypeId === FieldTypeEnum.disclosure
    ) {
      this.handleAddNewGroupValueToFormDataArray(currentProductFormId, fieldTypeId, value);
    }
    this.handleAddNewFieldValueToFormDataArray(fieldId, currentProductFormId, fieldTypeId, value);
  }

  private handleUpdateExistingValueInFormDataArray(index: number, currentProductFormId: string, value: string) {
    const fieldTypeId = this.getFieldTypeIdFromFieldId(value);
    const oldFieldTypeId = this.getFieldTypeIdFromFieldId(this.applicationsProcessService.applicationFormDataArray[index]?.value);

    if (this.applicationsProcessService.applicationFormDataArray[index].value !== value) {
      if (
        fieldTypeId === FieldTypeEnum.radioGroup ||
        fieldTypeId === FieldTypeEnum.checkboxGroup ||
        oldFieldTypeId === FieldTypeEnum.radioGroup ||
        oldFieldTypeId === FieldTypeEnum.checkboxGroup ||
        fieldTypeId === FieldTypeEnum.disclosure ||
        oldFieldTypeId === FieldTypeEnum.disclosure
      ) {
        this.handleRemovePreviousGroupValueFromDataArray(
          this.applicationsProcessService.applicationFormDataArray[index].value,
          value,
          currentProductFormId
        );
      }

      if (this.applicationsProcessService.applicationFormDataArray[index]) {
        this.applicationsProcessService.applicationFormDataArray[index].value = value;
      }
    }
  }

  private handleRemovePreviousGroupValueFromDataArray(oldFieldId: string, newFieldId: string, currentProductFormId: string) {
    if (oldFieldId.includes(',') || newFieldId.includes(',')) {
      const allOldFields = oldFieldId.split(',');
      const allNewFields = newFieldId.split(',');

      const fieldsToBeRemoved = allOldFields.filter((fieldIdToBeRemoved: string) => !allNewFields.includes(fieldIdToBeRemoved));

      fieldsToBeRemoved.forEach((fieldIdToBeRemoved: string) => {
        const index = this.applicationsProcessService.applicationFormDataArray.findIndex(
          (data: ApplicationFormData) =>
            data?.fieldId.toString() === fieldIdToBeRemoved.toString() && data?.productFormId.toString() === currentProductFormId.toString()
        );
        this.handleUpdateExistingValueInFormDataArray(index, currentProductFormId, '');
      });

      const fieldsToBeAdded = allNewFields.filter((fieldIdToBeAdded: string) => !allOldFields.includes(fieldIdToBeAdded));

      fieldsToBeAdded.forEach((fieldIdToBeAdded: string) => {
        const index = this.applicationsProcessService.applicationFormDataArray.findIndex(
          (data: ApplicationFormData) =>
            data?.fieldId.toString() === allOldFields[0].toString() && data?.productFormId.toString() === currentProductFormId.toString()
        );
        this.handleAddNewFieldValueToFormDataArray(
          fieldIdToBeAdded,
          currentProductFormId,
          this.applicationsProcessService.applicationFormDataArray[index].fieldTypeId,
          'x'
        );
      });
    } else {
      const index = this.applicationsProcessService.applicationFormDataArray.findIndex(
        (data: ApplicationFormData) =>
          data?.fieldId?.toString() === oldFieldId?.toString() &&
          data?.productFormId.toString() === currentProductFormId &&
          data?.fieldId?.toString() !== data?.value?.toString()
      );
      this.handleAddNewGroupValueToFormDataArray(currentProductFormId, this.getFieldTypeIdFromFieldId(newFieldId), newFieldId);
      if (index !== -1) {
        this.handleUpdateExistingValueInFormDataArray(index, currentProductFormId, '');
      }
    }
  }

  private handleRemoveFieldValue(index: number) {
    const dataForFieldIndex = this.applicationsProcessService.applicationFormDataArray.findIndex(
      (data: ApplicationFormData) =>
        data?.fieldId.toString() === this.applicationsProcessService.applicationFormDataArray[index].value.toString()
    );
    if (dataForFieldIndex !== -1 && this.applicationsProcessService.applicationFormDataArray[dataForFieldIndex]) {
      this.applicationsProcessService.applicationFormDataArray[dataForFieldIndex].value = '';
    }
    this.applicationsProcessService.applicationFormDataArray[index].value = '';
  }

  private handleAddNewGroupValueToFormDataArray(currentProductFormId, fieldTypeId, value: string) {
    const items: FormBuilderItem[] = this.getItemsArrayByValue(value);
    items.forEach((item) => {
      this.handleAddNewFieldValueToFormDataArray(item.id, currentProductFormId, fieldTypeId, 'x');
    });
  }

  private handleAddNewFieldValueToFormDataArray(fieldId: string, currentProductFormId: string, fieldTypeId: number, value: string) {
    this.applicationsProcessService.applicationFormDataArray.push({
      productFormId: parseInt(currentProductFormId),
      fieldId: parseInt(fieldId),
      fieldTypeId: fieldTypeId,
      value: value,
    });
  }

  private getItemsArrayByValue(value: string): FormBuilderItem[] {
    const splitValue = value.split(',');
    const items: FormBuilderItem[] = [];
    splitValue.forEach((valuePart) => {
      const item = this.listOfItems.find((item) => item.id === parseInt(valuePart));
      if (item) {
        items.push(item);
      }
    });
    return items;
  }

  private getValueFromControl(control: FormControl): string {
    return control.value !== null && control.value !== undefined ? control.value : null;
  }

  private getOneIdIfListContainsMultiple(fieldId: string): string {
    return fieldId.toString().includes(',') ? fieldId.toString().split(',')[0] : fieldId.toString();
  }

  private getFieldTypeIdFromFieldId(fieldId: string): number {
    if (fieldId) {
      fieldId = this.getOneIdIfListContainsMultiple(fieldId);

      const field = this.listOfItems.find((item) => item.id.toString() === fieldId.toString());
      if (field) {
        return field.fieldType.id;
      }
      const group = this.listOfItems.find((item) => item.groupOptions?.id.toString() === fieldId?.toString());
      if (group) {
        return group.fieldType.id;
      }
    }
    return null;
  }

  private prepareValue(value: any): string {
    switch (true) {
      case typeof value === 'number':
        return value?.toString();
      case Array.isArray(value):
        return value?.toString();
      default:
        return value;
    }
  }

  private getNonNullValuesFromFormDataArray(formDataArray: ApplicationFormData[]): ApplicationFormData[] {
    return formDataArray.filter((formData) => formData?.value !== null && formData?.value !== undefined);
  }

  private saveProcessedData() {
    this.applicationsProcessService
      .saveFormData(this.applicationId, this.applicationsProcessService.applicationFormDataArray)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: ApplicationFormData[]) => {
        this.waitingForChanges.emit(false);
        this.applicationsProcessService.applicationFormDataArray = this.processFieldIds(res);
      });
  }

  private processFieldIds(data: ApplicationFormData[]): ApplicationFormData[] {
    data.forEach((dataItem) => {
      if (dataItem?.fieldId && typeof dataItem.fieldId === 'string') {
        dataItem.fieldId = parseInt(dataItem.fieldId);
      }
    });
    return data;
  }

  private generatePdf() {
    this.applicationsProcessService.generatePdf(this.applicationId);
  }

  private getFieldsForDisplayConditions(productFormId?: number): FormBuilderItem[] {
    const fields: FormBuilderItem[] = [];

    if (productFormId) {
      const form = this.selectableForms.find((element) => element.productFormId === productFormId);

      form.sectionList.forEach((section) => {
        section.formAreas?.forEach((area) => {
          area.fields?.forEach((field) => {
            fields.push(field);
          });
        });
      });
    }
    return fields;
  }

  clearHiddenFieldValidations() {
    this.hiddenFields[this.currentlySelectedForm?.productFormId]?.forEach((field) => {
      const fieldFormControl = this.formFieldConditionsService.getFieldFormControl(
        field,
        this.applicationFormGroup,
        this.currentlySelectedForm?.productFormId.toString()
      );
      fieldFormControl?.reset();
      fieldFormControl?.setErrors(null);
    });
    this.applicationFormGroup.updateValueAndValidity();
  }

  onSelectedFormChange() {
    this.checkFieldsConditionsForCurrentProduct();
    this.clearHiddenFieldValidationsDebounced();
    this.sortFormAreas(this.currentlySelectedForm);
  }

  private checkFieldsConditionsForAllProducts() {
    this.selectableForms?.forEach((form) => {
      const currentProductFormId = form?.productFormId.toString();
      const fields = this.getFieldsForDisplayConditions(form.productFormId);

      this.fieldIdsWithErrorInSection = [];
      fields.forEach((field: FormBuilderField) => {
        this.setRequiredFieldsBadge(field);
        const fieldConditions = this.formFieldConditionsService.checkFieldConditions(
          field.conditions,
          this.applicationFormGroup,
          currentProductFormId,
          field
        );
        const itemId = field.groupOptions ? field.groupOptions.id : field.id;
        if (field.conditions && !field?.table && !fieldConditions) {
          if (!this.hiddenFields[form.productFormId]?.includes(itemId)) {
            this.addHiddenField(Number(currentProductFormId), itemId, field);
          }
        } else {
          if (this.hiddenFields[form.productFormId]?.includes(itemId)) {
            this.removeHiddenField(Number(currentProductFormId), itemId, field);
          }
        }
      });
    });
  }

  private checkFieldsConditionsForCurrentProduct() {
    const currentProductFormId = this.currentlySelectedForm?.productFormId?.toString();
    const fields = this.getFieldsForDisplayConditions(this.currentlySelectedForm?.productFormId);

    this.fieldIdsWithErrorInSection = [];
    fields.forEach((field: FormBuilderField) => {
      this.setRequiredFieldsBadge(field);
      const fieldConditions = this.formFieldConditionsService.checkFieldConditions(
        field.conditions,
        this.applicationFormGroup,
        currentProductFormId,
        field
      );
      const itemId = field.groupOptions ? field.groupOptions.id : field.id;
      if (field.conditions && !field?.table && !fieldConditions) {
        if (!this.hiddenFields[this.currentlySelectedForm.productFormId]?.includes(itemId)) {
          this.addHiddenField(Number(currentProductFormId), itemId, field);
        }
      } else {
        if (this.hiddenFields[this.currentlySelectedForm.productFormId]?.includes(itemId)) {
          this.removeHiddenField(Number(currentProductFormId), itemId, field);
        }
      }
    });
  }

  private addHiddenField(productFormId: number, itemId: number, field: FormBuilderField) {
    this.hiddenFields = {
      ...this.hiddenFields,
      [productFormId]: [...(this.hiddenFields?.[productFormId] || []), itemId],
    };

    this.formFieldConditionsService.getFieldFormControl(itemId, this.applicationFormGroup, productFormId.toString())?.setValue('');
    this.formFieldConditionsService.getFieldFormControl(itemId, this.applicationFormGroup, productFormId.toString())?.setErrors(null);
    this.formFieldConditionsService
      .getFieldFormControl(itemId, this.applicationFormGroup, productFormId.toString())
      ?.disable({ emitEvent: false });
    if (field.staticValueLink?.fieldKey) {
      this.waitingForChanges.emit(true);
      this.triggerStaticFieldLinkDebounced();
    }
  }

  private removeHiddenField(productFormId: number, itemId: number, field: FormBuilderField) {
    this.hiddenFields[productFormId].splice(this.hiddenFields[productFormId].indexOf(itemId), 1);
    this.hiddenFields = {
      ...this.hiddenFields,
      [productFormId]: this.hiddenFields[productFormId].filter((hiddenField: number) => hiddenField !== itemId),
    };

    this.formFieldConditionsService
      .getFieldFormControl(itemId, this.applicationFormGroup, productFormId.toString())
      ?.enable({ emitEvent: false });
    if (field.staticValueLink?.fieldKey) {
      this.waitingForChanges.emit(true);
      this.triggerStaticFieldLinkDebounced();
    }
  }

  getFirstInvalidItemInFieldTable(field: FormBuilderField): number {
    const formControlForFieldArea = (
      ((this.applicationFormGroup?.get(field.productFormId.toString()) as FormGroup)?.get(field.sectionId.toString()) as FormGroup)?.get(
        field.formAreaId.toString()
      ) as FormGroup
    )?.controls;

    if (formControlForFieldArea) {
      let ifOfFirstInvalidFieldInTable: number;
      this.listOfItems.find((item: FormBuilderField) => {
        const itemIsInSameTable = field.table?.type.id === item.table?.type.id;

        if (itemIsInSameTable) {
          const itemId = item.groupOptions ? item.groupOptions.id : item.id;
          const formControlInvalid = formControlForFieldArea[itemId]?.status === FormGroupStatus.INVALID;

          if (formControlInvalid) {
            ifOfFirstInvalidFieldInTable = itemId;
          }
        }
      });
      return ifOfFirstInvalidFieldInTable;
    }
  }

  setRequiredFieldsBadge(field: FormBuilderField) {
    let itemId: number;

    switch (true) {
      case !!field.table: {
        const firstInvalidFieldInTable = this.getFirstInvalidItemInFieldTable(field);
        itemId = firstInvalidFieldInTable ? firstInvalidFieldInTable : field.groupOptions ? field.groupOptions.id : field.id;
        break;
      }
      case !!field.groupOptions:
        itemId = field.groupOptions.id;
        break;
      default:
        itemId = field.id;
    }

    const itemIsInvalid =
      this.formFieldConditionsService.getFieldFormControl(
        itemId,
        this.applicationFormGroup,
        this.currentlySelectedForm?.productFormId.toString()
      )?.status === FormGroupStatus.INVALID;

    const itemIsNotHidden = !this.hiddenFields[this.currentlySelectedForm?.productFormId]?.includes(field.id);

    const fieldIsWithErrorInSectionEntryForField = this.fieldIdsWithErrorInSection[field.sectionId];

    if (itemIsInvalid && itemIsNotHidden) {
      if (fieldIsWithErrorInSectionEntryForField) {
        const indexOfFieldId = fieldIsWithErrorInSectionEntryForField?.indexOf(itemId);
        if (indexOfFieldId === -1) {
          this.fieldIdsWithErrorInSection[field.sectionId].push(itemId);
        }
      } else {
        this.fieldIdsWithErrorInSection[field.sectionId] = [itemId];
      }
    } else {
      if (fieldIsWithErrorInSectionEntryForField) {
        const indexOfFieldId = fieldIsWithErrorInSectionEntryForField.indexOf(itemId);
        if (indexOfFieldId !== -1) {
          this.fieldIdsWithErrorInSection[field.sectionId].splice(indexOfFieldId, 1);
        }
      }
    }
  }

  private setPredefinedValuesInAllProductForms() {
    this.applicationsProcessService.allFieldsInAllForms.forEach((firstField) => {
      switch (true) {
        case !!firstField?.groupOptions?.predefinedGroupId: {
          const firstGroupValue = this.getFromControlForItemByProductFormId(firstField)?.value;

          if (firstGroupValue?.toString() === firstField.id.toString()) {
            const allPredefinedGroupItemsWithSameId = this.applicationsProcessService.allFieldsInAllForms.filter((field) => {
              const fieldsWithTheSamePredefinedGroupId =
                field.groupOptions?.predefinedGroupId === firstField?.groupOptions?.predefinedGroupId;
              const notFieldInTheSameGroup = field.groupOptions?.id !== firstField?.groupOptions?.id;
              const fieldInSameGroupButDifferentProductForm =
                field.groupOptions?.id === firstField?.groupOptions.id && field.productFormId !== firstField?.productFormId;

              return (fieldsWithTheSamePredefinedGroupId && notFieldInTheSameGroup) || fieldInSameGroupButDifferentProductForm;
            });

            const fieldsInPredefinedGroupWithSamePredefinedId = allPredefinedGroupItemsWithSameId.filter((field) => {
              return field.predefinedFieldId === firstField?.predefinedFieldId;
            });

            fieldsInPredefinedGroupWithSamePredefinedId.forEach((uniqueFieldByPredefinedGroup) => {
              this.getFromControlForItemByProductFormId(uniqueFieldByPredefinedGroup)?.setValue(uniqueFieldByPredefinedGroup?.id, {
                emitEvent: false,
              });
            });
          }
          break;
        }
        case !!firstField?.predefinedFieldId: {
          const firstFieldValue = this.getFromControlForItemByProductFormId(firstField)?.value;

          if (firstFieldValue) {
            this.applicationsProcessService.allFieldsInAllForms.forEach((secondField) => {
              const secondFieldValue = this.getFromControlForItemByProductFormId(secondField)?.value;
              if (secondField?.predefinedFieldId === firstField?.predefinedFieldId && firstFieldValue !== secondFieldValue) {
                this.getFromControlForItemByProductFormId(secondField)?.setValue(firstFieldValue, { emitEvent: false });
              }
            });
          }
          break;
        }
      }
    });
    this.saveProcessedData();
  }

  triggerPredefinedLink(item: FormBuilderItem) {
    switch (true) {
      case !!item.groupOptions:
        this.mapPredefinedGroupValue(item);
        break;

      default:
        this.mapPredefinedFieldValue(item);
        break;
    }
  }

  private mapPredefinedFieldValue(item: FormBuilderItem) {
    const foundField = this.applicationsProcessService.allFieldsInAllForms.find(
      (field) => field?.id === item?.id && field?.productFormId === item?.productFormId
    );
    if (foundField?.predefinedFieldId) {
      const foundFieldValue = this.getFormControlForItemInSelectedProduct(foundField)?.value;
      this.applicationsProcessService.allFieldsInAllForms.forEach((field) => {
        if (
          field.predefinedFieldId === foundField.predefinedFieldId &&
          (field.id !== foundField.id || (field.id === foundField.id && field.productFormId !== foundField.productFormId))
        ) {
          const formControlsForFieldToSetValueTo = this.getFromControlForItemByProductFormId(field);

          if (formControlsForFieldToSetValueTo && formControlsForFieldToSetValueTo?.value !== foundFieldValue) {
            formControlsForFieldToSetValueTo.setValue(foundFieldValue, {
              emitEvent: false,
            });
          }
        }
      });
    }
  }

  private mapPredefinedGroupValue(item: FormBuilderItem) {
    const foundFieldInGroup = this.applicationsProcessService.allFieldsInAllForms.filter(
      (field) => field?.groupOptions?.id === item?.groupOptions?.id && field?.productFormId === item?.fields[0].productFormId
    )[0];

    if (foundFieldInGroup?.groupOptions.predefinedGroupId) {
      const allPredefinedGroupItemsWithSameId = this.applicationsProcessService.allFieldsInAllForms.filter((field) => {
        const fieldsWithTheSamePredefinedGroupId =
          field.groupOptions?.predefinedGroupId === foundFieldInGroup.groupOptions.predefinedGroupId;
        const notFieldInTheSameGroup = field.groupOptions?.id !== foundFieldInGroup.groupOptions.id;
        const fieldInSameGroupButDifferentProductForm =
          field.groupOptions?.id === foundFieldInGroup.groupOptions.id && field.productFormId !== foundFieldInGroup.productFormId;

        return (fieldsWithTheSamePredefinedGroupId && notFieldInTheSameGroup) || fieldInSameGroupButDifferentProductForm;
      });

      const foundGroupValue = this.getFormControlForItemInSelectedProduct(foundFieldInGroup)?.value;
      const uniquePredefinedGroupsFound = _.uniqBy(allPredefinedGroupItemsWithSameId, 'groupOptions.id');

      if (foundGroupValue !== foundFieldInGroup.groupOptions.id) {
        const predefinedFieldOptionIdsOfValue = this.getPredefinedIdsByFieldIds(foundGroupValue);
        uniquePredefinedGroupsFound.forEach((group) => {
          this.setGroupControlValue(group, predefinedFieldOptionIdsOfValue, foundGroupValue);
        });
      } else {
        uniquePredefinedGroupsFound.forEach((field: FormBuilderField) => {
          const otherGroupControls = this.getFromControlsById(field.groupOptions.id.toString());
          otherGroupControls.forEach((groupControl: FormControl) => {
            groupControl.setValue(field.groupOptions.id, { emitEvent: false });
          });
        });
        allPredefinedGroupItemsWithSameId.forEach((field: FormBuilderField) => {
          this.getFromControlsForItemInAllProducts(field).forEach((control) => {
            if (control.value === 'x') {
              control.setValue('', { emitEvent: false });
            }
          });
        });
      }
    }
  }

  private getPredefinedIdsByFieldIds(foundGroupValue): number[] {
    const predefinedFieldOptionIdsOfValue = [];
    if (Array.isArray(foundGroupValue)) {
      foundGroupValue.forEach((foundOptionId) => {
        predefinedFieldOptionIdsOfValue.push(
          this.applicationsProcessService.allFieldsInAllForms.find((field) => field?.id === foundOptionId)?.predefinedFieldId
        );
      });
    } else {
      predefinedFieldOptionIdsOfValue.push(
        this.applicationsProcessService.allFieldsInAllForms.find((field) => field?.id === foundGroupValue)?.predefinedFieldId
      );
    }
    return predefinedFieldOptionIdsOfValue;
  }

  private setGroupControlValue(group: FormBuilderField, predefinedFieldOptionIdsOfValue, foundGroupValue) {
    if (group.formAreaId && group.id && group.formId && group.sectionId) {
      const values = [];
      predefinedFieldOptionIdsOfValue.forEach((predefinedId) => {
        const fieldIdForPredefinedFieldInPredefinedGroup = this.applicationsProcessService.allFieldsInAllForms.find(
          (field) => field.predefinedFieldId === predefinedId && field.groupOptions?.id === group.groupOptions.id
        )?.id;
        values.push(fieldIdForPredefinedFieldInPredefinedGroup);
      });
      const valueToSet = Array.isArray(foundGroupValue) ? values : values[0];

      const formControlsForFieldToSetValueTo = this.getFromControlsForItemInAllProducts(group);
      formControlsForFieldToSetValueTo.forEach((formControlForFieldToSetValueTo) => {
        if (
          (Array.isArray(foundGroupValue) && !_.isEqual(formControlForFieldToSetValueTo?.value, valueToSet)) ||
          (!Array.isArray(foundGroupValue) && formControlForFieldToSetValueTo?.value !== valueToSet)
        ) {
          formControlForFieldToSetValueTo?.setValue(valueToSet);
        }
      });
    }
  }

  private getFromControlsById(controlId: string) {
    const foundControls: FormControl[] = [];

    const allProductFormIds = Object.keys(this.applicationFormGroup.controls);

    allProductFormIds.forEach((productFormId) => {
      Object.values((this.applicationFormGroup.get(productFormId) as FormGroup).controls).forEach((section) => {
        Object.values((section as FormGroup).controls).forEach((area) => {
          Object.entries((area as FormGroup).controls).forEach((item) => {
            if (item[0] === controlId) {
              foundControls.push(item[1] as FormControl);
            }
          });
        });
      });
    });

    return foundControls;
  }

  private getFromControlForItemByProductFormId(field: FormBuilderField): FormControl {
    const itemId = field.groupOptions ? field.groupOptions.id : field.id;

    return this.applicationFormGroup
      ?.get(field.productFormId.toString())
      ?.get(field.sectionId?.toString())
      ?.get(field.formAreaId?.toString())
      ?.get(itemId?.toString()) as FormControl;
  }

  private getFromControlsForItemInAllProducts(field: FormBuilderField): FormControl[] {
    const itemId = field.groupOptions ? field.groupOptions.id : field.id;

    const allProductFormIds = Object.keys(this.applicationFormGroup.controls);

    const foundControls: FormControl[] = [];

    allProductFormIds.forEach((productFormId) => {
      const foundControl = this.applicationFormGroup
        ?.get(productFormId)
        ?.get(field.sectionId?.toString())
        ?.get(field.formAreaId?.toString())
        ?.get(itemId?.toString()) as FormControl;

      if (foundControl) {
        foundControls.push(foundControl);
      }
    });

    return foundControls;
  }

  private getFormControlForItemInSelectedProduct(field: FormBuilderField): FormControl {
    const itemId = field.groupOptions ? field.groupOptions.id : field.id;

    return this.applicationFormGroup
      ?.get(this.currentlySelectedForm?.productFormId?.toString())
      ?.get(field.sectionId?.toString())
      ?.get(field.formAreaId?.toString())
      ?.get(itemId?.toString()) as FormControl;
  }

  private preparePredefinedLinkObject() {
    this.applicationsProcessService.allFieldsInAllForms = [];
    this.selectableForms.forEach((form) => {
      (this.canvasService.getAllProductFormFields(form.productFormId, false) as Observable<FormBuilderField[]>)
        ?.pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (res: FormBuilderField[]) => {
            const allFieldsWithProductFormId: FormBuilderField[] = [];
            res.forEach((field) => {
              field.productFormId = form.productFormId;
              allFieldsWithProductFormId.push(field);
            });
            this.applicationsProcessService.allFieldsInAllForms.push(...allFieldsWithProductFormId);
          },
        });
    });
  }

  private triggerStaticFieldLink() {
    const allFieldsWithStaticLink = this.applicationsProcessService.allFieldsInAllForms.filter(
      (field: FormBuilderField) => field.staticValueLinkId
    );
    const allFieldsWithStaticLinkThatAreNotHidden = allFieldsWithStaticLink.filter(
      (field) => !this.hiddenFields?.[this.currentlySelectedForm?.productFormId]?.includes(field.id)
    );
    allFieldsWithStaticLinkThatAreNotHidden.forEach((field: FormBuilderField) => {
      if (field.groupOptions) {
        this.mapStaticFieldLinkToGroup(field);
      } else {
        this.mapStaticFieldLinkToField(field);
      }
      this.setRequiredFieldsBadge(field);
    });
  }

  private mapStaticFieldLinkToField(field: FormBuilderField) {
    if (field.staticValueLink.fieldKey) {
      const staticValue = this.staticFieldLinkService.getStaticFieldLinkDataByTypeAndKey(
        field.staticValueLink.type,
        field.staticValueLink.iapp1Id
      );

      if (staticValue !== null && staticValue !== undefined) {
        let setValue;
        if (typeof staticValue === 'object' && field.fieldType.type !== FormBuilderFieldTypeType.DATE) {
          setValue = staticValue ? staticValue[field.productFormId] : null;
        } else {
          setValue = staticValue;
        }
        const formControlsForFieldToSetValueTo = this.getFromControlForItemByProductFormId(field);

        if (formControlsForFieldToSetValueTo?.value !== setValue) {
          switch (field.staticValueLink.keepInitialValue) {
            case true:
              if (formControlsForFieldToSetValueTo && !formControlsForFieldToSetValueTo.value) {
                formControlsForFieldToSetValueTo?.setValue(setValue, { emitEvent: false });
              }
              break;
            case false:
              formControlsForFieldToSetValueTo?.setValue(setValue, { emitEvent: false });
              break;
          }
        }
      }
    }
  }

  private mapStaticFieldLinkToGroup(field: FormBuilderField) {
    if (field.staticValueLink.fieldKey) {
      const staticValue = this.staticFieldLinkService.getStaticFieldLinkDataByTypeAndKey(
        field.staticValueLink.type,
        field.staticValueLink.iapp1Id
      );

      const alternateStaticFieldGroupValues = STATIC_FIELD_LINK_ALTERNATE_VALUES;

      if (staticValue) {
        let possibleValues = [staticValue];
        const clientFormKeyByIapp1Id = this.staticFieldLinkService.getClientFormKeyByIapp1Id(field.staticValueLink.iapp1Id);
        const possibleValueBranches = alternateStaticFieldGroupValues[clientFormKeyByIapp1Id];

        if (possibleValueBranches) {
          Object.values(possibleValueBranches).forEach((branch: string[]) => {
            if (branch.includes(this.prepareValueForComparison(staticValue))) {
              possibleValues = branch;
            }
          });
        }

        const preparedValue = this.prepareValueForComparison(field.options.customFieldLabel);
        const preparedPossibleValuesForComparison = possibleValues.map((value) => this.prepareValueForComparison(value));
        if (possibleValues.includes(preparedValue) || preparedPossibleValuesForComparison.includes(preparedValue)) {
          const formControlsForFieldToSetValueTo = this.getFromControlsForItemInAllProducts(field);
          formControlsForFieldToSetValueTo.forEach((formControlForFieldToSetValueTo) => {
            if (formControlForFieldToSetValueTo && formControlForFieldToSetValueTo.value !== field.id) {
              formControlForFieldToSetValueTo.setValue(field.id);
            }
          });
        }
      }
    }
  }

  private prepareValueForComparison(value: string): string {
    return value.replace(/[^a-zA-Z0-9]/g, '')?.toUpperCase();
  }

  private mapProductPricingInfoToStaticLink() {
    const language = this.selectedLanguage;
    this.selectedProducts.forEach((product) => {
      // TODO: Refactor when forms is changed to object and contains an ID for each language
      const productFormId = language === 'en' ? product.productFormId : product.productFormId;
      this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(
        StaticFieldLinkType.APPLICATION_INFO,
        'monthly-premium',
        product.monthlyPremium,
        productFormId?.toString()
      );
      this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(
        StaticFieldLinkType.APPLICATION_INFO,
        'yearly-premium',
        product.yearlyPremium,
        productFormId?.toString()
      );
      this.staticFieldLinkService.setStaticFieldLinkDataByTypeAndKey(
        StaticFieldLinkType.APPLICATION_INFO,
        'benefit-amount',
        product.coverage,
        productFormId?.toString()
      );
    });
  }

  getTranslations() {
    this.translate
      .get('applicationProcess.step.applicationForms.tabs.header.tooltips.invalidFields')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.invalidFieldsTooltip = res;
      });
  }

  getPaymentTranslations() {
    this.translate
      .get('applicationProcess.step.summary.information.payment')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.paymentText.pad = res?.pad;
        this.paymentText.creditCard = res?.creditCard;
        this.getPaymentResetConfirmationTextTranslations();
      });
    this.translate
      .get('applicationProcess.step.applicationForms.payment.dialog.confirmationDialog')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.paymentText.paymentResetConfirmationText.acceptLabel = res?.acceptLabel;
        this.paymentText.paymentResetConfirmationText.rejectLabel = res?.rejectLabel;
      });
  }

  getPaymentResetConfirmationTextTranslations() {
    let paymentMethod: string;
    switch (this.selectedPaymentMethod?.toLowerCase()?.replace(/\s/g, '')) {
      case PaymentMethods.CREDIT_CARD.toLowerCase():
        paymentMethod = this.paymentText.creditCard.toLowerCase();
        break;
      case PaymentMethods.PAD:
        paymentMethod = this.paymentText.pad;
        break;
    }

    this.translate
      .get('applicationProcess.step.applicationForms.payment.dialog.confirmationDialog.message', {
        paymentMethod: paymentMethod,
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.paymentText.paymentResetConfirmationText.message = res;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
