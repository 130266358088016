<div class="canvas-container-div" [style]="showCanvas ? 'display: block' : 'display: none'">
  <canvas id="canvas" #htmlCanvas></canvas>
</div>
<div class="mx-3">
  <p-skeleton width="820px" height="1061px" *ngIf="!showCanvas"></p-skeleton>
</div>
<button pButton pRipple label="Log canvas data to console" (click)="log()" class="mt-3 ml-3"></button>
<button pButton pRipple label="Delete all objects" (click)="deleteAllObjects()" class="mt-3 ml-3"></button>
<button *ngIf="isEnvDevelop" pButton pRipple label="Delete all objects" (click)="deleteAllObjects()" class="mt-3 ml-3"></button>
<button
  pTooltip="This action will only delete the field from the canvas, if there is no field linked to it in the database. The field will not be deleted from the database if it exists."
  pButton
  pRipple
  label="Delete selected object"
  (click)="deleteSelectedObject()"
  class="mt-3 ml-3 p-button-danger p-button-text"
></button>
<p-confirmDialog></p-confirmDialog>
