<div *ngIf="!showSuccessPage && !isSignatureAlreadySubmitted && !isApplicationExpired">
  <h4>{{ 'signaturePad.title' | translate }}</h4>
  <div #container class="flex flex-row align-items-center justify-content-center w-full signature-container">
    <signature-pad [options]="signaturePadOptions"></signature-pad>
  </div>

  <div class="flex flex-row align-items-center justify-content-end">
    <p-button class="mr-2" (click)="clearSignature()">{{ 'signaturePad.clearSignature' | translate }}</p-button>
    <p-button (click)="savePad()">{{ 'signaturePad.saveSignature' | translate }}</p-button>
  </div>
</div>
<div class="logo-container mt-6" *ngIf="showSuccessPage || isSignatureAlreadySubmitted || isApplicationExpired">
  <img class="logo" src="https://media.isi.ca/iapplication/SLI-Logo-Horizontal-en.png" />
</div>
<div *ngIf="isApplicationExpired" class="large-text">{{ 'applicationDigitalSignatures.applicationExpiredMessage' | translate }}</div>
<div *ngIf="(showSuccessPage && !isSignatureAlreadySubmitted) || isApplicationExpired" class="large-text">
  {{ 'applicationDigitalSignatures.thankYou' | translate }}
</div>
<p-toast key="signature-pad-messages"></p-toast>

<router-outlet></router-outlet>
