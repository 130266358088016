import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FieldTable, FormArea, FormBuilderField, FormBuilderItem, Themes } from '@iapplication2/interfaces';
import { DarkThemeService, InteractiveFormBuilderService } from '@iapplication2/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-form-builder-area',
  templateUrl: './form-builder-area.component.html',
  styleUrls: ['./form-builder-area.component.scss'],
})
export class FormBuilderAreaComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formArea: FormArea;
  @Input() selectedCanvasObject: FormBuilderField;
  @Input() selectedFormField: FormBuilderField;
  @Input() connectedTo;
  @Output() droppedArea = new EventEmitter();
  @Output() deleteFormArea = new EventEmitter<FormArea>();
  unsubscribe: Subject<void> = new Subject();
  fieldsToBeGrouped = [];
  isDarkTheme: boolean;
  formBuilderItems: FormBuilderItem[] = [];

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private cdr: ChangeDetectorRef,
    private darkThemeService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.darkThemeService.themeChanged.subscribe(value => {
      this.isDarkTheme = value === Themes.DARK;
    })
    this.formBuilderItems = this.interactiveFormBuilderService.convertListOfFieldsToListOfItems(this.formArea.fields);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  drop(ev: any): void {
    this.droppedArea.emit(ev);
  }

  getFieldsFromTable(table: FieldTable): FormBuilderField[] {
    return this.formArea.fields.filter((field) => field.table?.type === table) as FormBuilderField[];
  }

  deleteArea(formArea: FormArea) {
    this.deleteFormArea.emit(formArea);
  }

  deletedField(id: number) {
    this.formArea.fields = this.formArea.fields.filter((item) => item.id !== id);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
    this.fieldsToBeGrouped = [];
  }
}
