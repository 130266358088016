import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicTopbarInformationService, DynamicTopbarInformationOptions } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-dynamic-topbar-information',
  templateUrl: './dynamic-topbar-information.component.html',
  styleUrls: ['./dynamic-topbar-information.component.scss'],
})
export class DynamicTopbarInformationComponent implements OnInit, OnDestroy {
  dynamicTopbarInformationOptions: DynamicTopbarInformationOptions;
  private unsubscribe: Subject<void> = new Subject();

  constructor(private dynamicTopbarInformationService: DynamicTopbarInformationService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.dynamicTopbarInformationService.displayDynamicInformation.pipe(takeUntil(this.unsubscribe)).subscribe((displayOptions) => {
      this.dynamicTopbarInformationOptions = displayOptions;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
