import { Component, Input } from '@angular/core';
import { ApplicationStatus, ApplicationStatusOptions } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-product-status-tag',
  templateUrl: './product-status-tag.component.html',
  styleUrls: ['./product-status-tag.component.scss'],
})
export class ProductStatusTagComponent {
  @Input() status: ApplicationStatus;
  @Input() scheduled: Date | null | undefined;
  @Input() statuses: ApplicationStatus[];
  applicationStatusListEnum = ApplicationStatusOptions;
}
