import { ApplicationStatusOptions } from '@iapplication2/interfaces';

export const applicationStatusMenuItem = [
  {
    id: 1,
    label: ApplicationStatusOptions.ACTIVE,
  },
  {
    id: 2,
    label: ApplicationStatusOptions.SCHEDULED,
  },
  {
    id: 3,
    label: ApplicationStatusOptions.SUBMITTED,
  },
  {
    id: 4,
    label: ApplicationStatusOptions.EXPIRED,
  },
  {
    id: 5,
    label: ApplicationStatusOptions.AWAITING_SIGNATURES,
  },
  {
    id: 6,
    label: ApplicationStatusOptions.FINALIZED,
  },
];
