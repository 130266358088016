<div class="mt-4" fxFlex fxLayout="column">
  <div class="details-field-header flex justify-content-between">
    <h4 class="details-field-header-title">Field attachments</h4>
  </div>
  <div fxFlex fxLayout="column">
    <div *ngIf="fieldAttachment" fxFlex fxLayout="column" class="pl-1">
      <div class="details-field-content-property my-2" fxLayout="row">
        <div class="details-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">Field label:</div>
        <div class="details-field-content-property-content my-2" fxLayout="row">
          {{ fieldAttachment?.fileName?.length > 20 ? (fieldAttachment?.fileName | slice: 0:20) + '..' : fieldAttachment?.fileName }}
        </div>
      </div>
      <img class="mb-2" style="width: 100%" [src]="fieldAttachment.s3Key" />

      <button
        *ngIf="isInEditMode"
        pButton
        pRipple
        type="button"
        label="Delete"
        class="p-button-secondary"
        (click)="deleteFieldAttachment()"
      ></button>
    </div>
    <div *ngIf="!fieldAttachment" fxFlex fxLayout="column">
      <div class="mb-2">No attachments yet.</div>
      <div *ngIf="isInEditMode" fxFlex fxLayout="row" fxLayoutAlign="start center" class="pl-1 mt-3">
        <p class="mb-0">Select attachment:</p>
        <p-fileUpload
          class="ml-2 custom-upload-button"
          accept=".jpg,.jpeg,.png,.heif,.heic"
          name="files[]"
          mode="basic"
          [customUpload]="true"
          [auto]="true"
          (uploadHandler)="addFieldAttachment($event)"
          chooseLabel="Upload"
        >
        </p-fileUpload>
      </div>
    </div>
  </div>
</div>
