export interface Page {
  id: number;
  number: number;
  formId: number;
  pageStatus: 'active' | 'inactive';
  thumbnail: string;
  canvasImage: string;
  canvasData: string;
  createdAt?: Date;
}
