import { Directive, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBuilderGroup } from '@iapplication2/interfaces';

@Directive()
export abstract class BaseGroupDataDirective {
  @Input() group: FormBuilderGroup;
  @Input() formFieldControl: FormControl;
  @Input() parentFormGroup: FormGroup;
  // @Input() isPreviewMode: boolean;
  @Input() showFieldAsRequired: boolean;
  @Input() showFieldAsMIBRequired: boolean;
  @Input() isReadonly: boolean;
}
