import { Component, Input } from '@angular/core';

@Component({
  selector: 'iapplication2-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
})
export class FormFieldErrorComponent {
  @Input() control;
}
