<div #overlayPanelId>
  <div fxFlex fxLayout="row" fxLayoutAlign="start center" *ngIf="currentNewBusinessUser">
    <div>{{ currentNewBusinessUser.firstName }} {{ currentNewBusinessUser.lastName }}</div>
    <p-button
      icon="pi pi-pencil"
      styleClass="p-button-rounded p-button-text p-button-xsm ml-2"
      (click)="op.toggle($event); $event.stopPropagation()"
    ></p-button>
  </div>
  <div *ngIf="!currentNewBusinessUser">
    <a (click)="op.toggle($event); $event.stopPropagation()">Assign to</a>
  </div>

  <p-overlayPanel appendTo="body" #op (onShow)="onChangeNewBusinessUserOverlayShow()" fxLayout="column">
    <p class="mb-2">Search for an user below</p>
    <ng-template pTemplate>
      <p-autoComplete
        [(ngModel)]="selectedNewBusinessUser"
        [suggestions]="filteredNewBusinessUsersList"
        (completeMethod)="filterNewBusinessUsers($event)"
        field="firstName"
        [dropdown]="true"
        (onClear)="clearedInput()"
      >
        <ng-template let-newBusinessUserDropdown pTemplate="item">
          <iapplication2-user-card [user]="newBusinessUserDropdown"></iapplication2-user-card>
        </ng-template>
      </p-autoComplete>
      <div class="mt-3" *ngIf="selectedNewBusinessUser?.firstName">
        <iapplication2-user-card [user]="selectedNewBusinessUser"></iapplication2-user-card>
      </div>
      <div class="my-3">
        <a (click)="assignToMe()">Assign to me</a>
      </div>
      <div class="mt-2">
        <button pButton pRipple label="Cancel" class="p-button-secondary p-button-text mr-1" (click)="cancelOverlay()"></button>
        <p-button
          label="Continue with this user"
          [disabled]="!selectedNewBusinessUser?.firstName || selectedNewBusinessUser?.id === currentNewBusinessUser?.id"
          (onClick)="changeNewBusinessUser()"
        ></p-button>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
