<form [formGroup]="quickQuoteForm" (submit)="submit()">
  <div class="">
    <div class="card">
      <div>
        <div class="p-field flex flex-row mb-4 justify-content-start align-items-end">
          <div class="flex flex-column pr-2 width-100">
            <label for="birthDate" class="flex">
              <div>
                {{ 'pages.quickQuotes.singlePage.fields.birthDate.label' | translate }}
              </div>
              <div *ngIf="hasRequiredField(quickQuoteForm.get('birthDate'))" class="p-error ml-1">*</div>
            </label>
            <p-calendar
              class="flex flex-column mt-2"
              id="birthDate"
              formControlName="birthDate"
              [showIcon]="true"
              inputId="icon"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="1900:2021"
              dateFormat="dd/mm/yy"
              [placeholder]="'pages.quickQuotes.singlePage.fields.birthDate.placeholder' | translate"
              iapplication2DateMask
            ></p-calendar>
            <iapplication2-form-field-error
              *ngIf="quickQuoteForm.get('birthDate').touched || formSubmissionTriggered"
              [control]="quickQuoteForm.get('birthDate')"
              style="bottom: -8px"
            ></iapplication2-form-field-error>
          </div>
        </div>
      </div>

      <div>
        <div class="p-field flex flex-row-reverse justify-content-end">
          <label for="everest" class="flex mb-0 ml-2">
            <div>
              {{ 'pages.quickQuotes.singlePage.fields.withEverest.label' | translate }}
            </div>
            <div *ngIf="hasRequiredField(quickQuoteForm.get('everest'))" class="p-error ml-1">*</div>
          </label>
          <p-checkbox id="everest" name="everest" formControlName="everest" [binary]="true"></p-checkbox>
          <iapplication2-form-field-error
            *ngIf="quickQuoteForm.get('everest').touched || formSubmissionTriggered"
            [control]="quickQuoteForm.get('everest')"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>

      <div>
        <div class="p-field flex flex-row mb-4 justify-content-start align-items-end">
          <div class="flex flex-column pr-2 width-100">
            <label for="sex" class="flex">
              <div>
                {{ 'pages.quickQuotes.singlePage.fields.sex.label' | translate }}
              </div>
              <div *ngIf="hasRequiredField(quickQuoteForm.get('sex'))" class="p-error ml-1">*</div></label
            >
            <div class="flex" id="sex">
              <div *ngFor="let sexOption of sex" class="p-field-checkbox mr-3 my-2">
                <p-radioButton [inputId]="sexOption" name="sex" [value]="sexOption" formControlName="sex"></p-radioButton>
                <label [for]="sexOption">{{ sexOption | titlecase }}</label>
              </div>
            </div>
            <iapplication2-form-field-error
              *ngIf="quickQuoteForm.get('sex').touched || formSubmissionTriggered"
              [control]="quickQuoteForm.get('sex')"
              style="bottom: -8px"
            ></iapplication2-form-field-error>
          </div>
        </div>
      </div>
      <div>
        <div class="p-field flex flex-row mb-4 justify-content-start align-items-end">
          <div class="flex flex-column pr-2 width-100">
            <label for="smokerStatus" class="flex">
              <div>
                {{ 'pages.quickQuotes.singlePage.fields.smokerStatus.label' | translate }}
              </div>
              <div *ngIf="hasRequiredField(quickQuoteForm.get('smokerStatus'))" class="p-error ml-1">*</div></label
            >
            <div class="flex" id="smokerStatus">
              <div *ngFor="let smokerStatus of smokerStatuses" class="p-field-checkbox mr-3 my-2">
                <p-radioButton
                  [inputId]="smokerStatus"
                  name="smokerStatus"
                  [value]="smokerStatus"
                  formControlName="smokerStatus"
                ></p-radioButton>
                <label [for]="smokerStatus">{{ smokerStatus | titlecase }}</label>
              </div>
            </div>
            <iapplication2-form-field-error
              *ngIf="quickQuoteForm.get('smokerStatus').touched || formSubmissionTriggered"
              [control]="quickQuoteForm.get('smokerStatus')"
              style="bottom: -8px"
            ></iapplication2-form-field-error>
          </div>
        </div>
      </div>
      <div>
        <div class="p-field flex flex-column">
          <label for="faceAmount" class="flex">
            <div>
              {{ 'pages.quickQuotes.singlePage.fields.faceAmount.label' | translate }}
            </div>
            <div *ngIf="hasRequiredField(quickQuoteForm.get('faceAmount'))" class="p-error ml-1">*</div>
          </label>
          <span class="p-input-icon-left flex flex-column">
            <i class="pi pi-dollar"></i>
            <input
              class="p-inputtext"
              type="text"
              pInputText
              [value]="quickQuoteForm.controls['faceAmount']?.value"
              [iapplication2AmountFormat]="quickQuoteForm.controls['faceAmount']"
              formControlName="faceAmount"
              id="faceAmount"
            />
          </span>
          <iapplication2-form-field-error
            *ngIf="quickQuoteForm.get('faceAmount').touched || formSubmissionTriggered"
            [control]="quickQuoteForm.get('faceAmount')"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
      <div>
        <div class="p-field flex flex-column">
          <label for="province" class="flex">
            <div>
              {{ 'pages.quickQuotes.singlePage.fields.province.label' | translate }}
            </div>
            <div *ngIf="hasRequiredField(quickQuoteForm.get('province'))" class="p-error ml-1">*</div>
          </label>
          <p-dropdown
            id="province"
            [options]="provinces"
            formControlName="province"
            dataKey="value"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            [placeholder]="translatedPlaceholdersForDropdowns.province"
            class="flex flex-column"
          ></p-dropdown>
          <iapplication2-form-field-error
            *ngIf="quickQuoteForm.get('province').touched || formSubmissionTriggered"
            [control]="quickQuoteForm.get('province')"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
      <div>
        <div class="p-field flex flex-column">
          <label for="firstName" class="flex">
            <div>
              {{ 'pages.quickQuotes.singlePage.fields.firstName.label' | translate }}
            </div>
            <div *ngIf="hasRequiredField(quickQuoteForm.get('firstName'))" class="p-error ml-1">*</div>
          </label>
          <input
            id="firstName"
            type="text"
            class="p-inputtext p-component"
            pInputText
            formControlName="firstName"
            [placeholder]="'pages.quickQuotes.singlePage.fields.firstName.placeholder' | translate"
          />
          <iapplication2-form-field-error
            *ngIf="quickQuoteForm.get('firstName').touched || formSubmissionTriggered"
            [control]="quickQuoteForm.get('firstName')"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
      <div>
        <div class="p-field flex flex-column">
          <label for="middleName" class="flex">
            <div>
              {{ 'pages.quickQuotes.singlePage.fields.middleName.label' | translate }}
            </div>
            <div *ngIf="hasRequiredField(quickQuoteForm.controls['middleName'])" class="p-error ml-1">*</div>
          </label>
          <input
            id="middleName"
            type="text"
            class="p-inputtext p-component"
            pInputText
            [placeholder]="'pages.quickQuotes.singlePage.fields.middleName.placeholder' | translate"
            formControlName="middleName"
          />
          <iapplication2-form-field-error
            *ngIf="quickQuoteForm.get('middleName').touched || formSubmissionTriggered"
            [control]="quickQuoteForm.get('middleName')"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
      <div>
        <div class="p-field flex flex-column">
          <label for="lastName" class="flex">
            <div>
              {{ 'pages.quickQuotes.singlePage.fields.lastName.label' | translate }}
            </div>
            <div *ngIf="hasRequiredField(quickQuoteForm.get('lastName'))" class="p-error ml-1">*</div>
          </label>
          <input
            id="lastName"
            type="text"
            class="p-inputtext p-component"
            pInputText
            [placeholder]="'pages.quickQuotes.singlePage.fields.lastName.placeholder' | translate"
            formControlName="lastName"
          />
          <iapplication2-form-field-error
            *ngIf="quickQuoteForm.get('lastName').touched || formSubmissionTriggered"
            [control]="quickQuoteForm.get('lastName')"
            style="bottom: -8px"
          ></iapplication2-form-field-error>
        </div>
      </div>
    </div>
  </div>
  <button
    pButton
    pRipple
    [label]="'pages.quickQuotes.singlePage.buttons.compare' | translate"
    class="mt-3"
    type="submit"
    [disabled]="!quickQuoteForm.valid"
  ></button>
  <button
    pButton
    pRipple
    [label]="(quickQuoteId ? 'pages.quickQuotes.singlePage.buttons.saveChanges' : 'pages.quickQuotes.singlePage.buttons.save') | translate"
    class="p-button-success p-button-text mt-3 ml-2"
    type="button"
    (click)="saveQuickQuote()"
    [disabled]="!quickQuoteForm.valid || !quickQuoteForm.touched"
  ></button>
</form>
<p-toast> </p-toast>
