<div
  (click)="readNotification()"
  [class.cursor-pointer]="!notification.isRead"
  [class.dismissable-notification]="notification.isRead"
  class="notification-card flex p-w-full flex-column"
>
  <div class="flex p-w-full flex-row align-items-center justify-content-between">
    <div class="flex p-w-full flex-row align-items-center">
      <span *ngIf="!notification.isRead" class="unread-notification-badge"></span>
      <div class="notification-dismiss-button">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-trash"
          class="mr-3 p-2 p-button-secondary"
          (click)="dismissNotification()"
        ></button>
      </div>
      <p class="mb-0" [class.ml-2]="!notification.isRead">{{ notification.text }}</p>
    </div>
    <div class="flex p-w-fill flex-row align-items-center">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-arrow-right"
        class="ml-3 p-2"
        *ngIf="notification.hasLink"
        [routerLink]="'/' + notification.link"
      ></button>
    </div>
  </div>
  <div class="flex p-w-full align-items-center justify-content-between flex-row mt-2">
    <p class="timestamp-text mb-0">{{ notification.timestamp | date }}</p>
    <p class="timestamp-text mb-0">{{ notification.timestamp | date: 'shortTime' }}</p>
  </div>
</div>
