import { FormArea } from './form-area.model';
import { FormSectionType } from './form-section-type.model';

export interface FormSection {
  id: number;
  formId: number;
  title: string;
  lokaliseKey?: string;
  sectionFormPosition?: number;
  formAreas?: FormArea[];
  type?: FormSectionType;
  [key: string]: any;
}
