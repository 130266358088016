/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { ENV } from '@iapplication2/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsManagementService {
  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  getApplications(page?: number, limit?: string, sort?: string, order?: string, globalSearchValue?: string, language?: number) {
    const url: string = 'application';
    return this.getListFromDatabase(url + '/getAllFiltered', page, limit, sort, order, globalSearchValue, language);
  }

  getApplicationsByClientId(id: number) {
    return this.http.get('application/getAllByClientId/' + id);
  }

  getPolicies(page?: number, limit?: string, sort?: string, order?: string, globalSearchValue?: string) {
    const url: string = 'policy/getAll';
    return this.getListFromDatabase(url, page, limit, sort, order, globalSearchValue);
  }

  getDtcApplications(page?: number, limit?: string, sort?: string, order?: string, globalSearchValue?: string) {
    const url: string = 'dtc-policy/getAllFiltered';
    return this.getListFromDatabase(url, page, limit, sort, order, globalSearchValue);
  }

  getApplicationById(applicationId: number) {
    const url: string = 'application' + `/${applicationId}`;
    return this.http.get(url);
  }

  getListFromDatabase(
    url: string,
    page?: number,
    limit?: string,
    sort?: string,
    order?: string,
    globalSearchValue?: string,
    language: number = 2
  ) {
    let params = new HttpParams();
    if (page) {
      params = params.append('page', page + '');
    }
    if (limit) {
      params = params.append('take', limit);
    } else {
      // TODO: Send null/undefined insetead of hardcoded number, and have BE return all in that case (needed for e.g. global search)
      params = params.append('take', '10');
    }
    if (sort) {
      params = params.append('sort', sort);
    }
    if (order) {
      params = params.append('order', order);
    }
    if (globalSearchValue) {
      params = params.append('page', '0');
      params = params.append('q', globalSearchValue);
    }
    params = params.append('languageId', language);

    return this.http.get(url, { params });
  }
}
