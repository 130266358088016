<div class="card">
  <p-tabView [(activeIndex)]="activeIndex" (onChange)="tabChanged($event)">
    <p-tabPanel [header]="'applicationManagement.headers.applications' | translate">
      <iapplication2-applications-table></iapplication2-applications-table>
    </p-tabPanel>
    <p-tabPanel *ngIf="isEnvDevelop" [header]="'applicationManagement.headers.policies' | translate">
      <ng-template pTemplate="content">
        <iapplication2-policies-table></iapplication2-policies-table>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel *ngIf="isEnvDevelop" [header]="'applicationManagement.headers.dtcPolicies' | translate">
      <ng-template pTemplate="content">
        <iapplication2-dtc-table></iapplication2-dtc-table>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
