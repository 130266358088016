<div
  class="card mb-2 p-custom-shadow-3 px-2 py-2 hoverBorder"
  cdkDrag
  fxLayout="column"
  (mouseenter)="highlightGroupOnCanvas()"
  (mouseleave)="unHighlightGroupOnCanvas()"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <span cdkDragHandle>
        <img [src]="isDarkTheme ? './assets/custom/icons/drag-icon-light.svg' : './assets/custom/icons/drag-icon.svg'" class="drag-icon" />
      </span>
      <p class="mb-1 ml-2" *ngIf="formBuilderGroup.groupOptions?.name && !isGroupLabelInEditMode">
        {{ formBuilderGroup.groupOptions.name }}
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div *ngIf="formBuilderGroup.fields" [class]="'fieldType-chip ' + formBuilderGroup?.fields[0].fieldType?.type">
        {{ formBuilderGroup.fields[0].fieldType?.label }}
      </div>
      <i
        *ngIf="!formBuilderGroup?.fields[0]?.options?.visibleOnPdf"
        class="ml-3 mr-1 pi pi-eye-slash"
        style="color: #856404"
        pTooltip="This group will not be visible on PDF"
        tooltipPosition="bottom"
      ></i>
      <button
        pButton
        pRipple
        icon="pi pi-cog"
        (click)="toggleGroupMenu(formBuilderGroup, groupMenu, $event)"
        class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
      ></button>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" (click)="toggleExpandedContent($event)">
    <i class="pi pi-chevron-down expand-icon" *ngIf="!showExpandedContent"></i>
    <i class="pi pi-chevron-up expand-icon" *ngIf="showExpandedContent"></i>
    <p class="text-no-select">
      <small *ngIf="formBuilderGroup.fields"
        >Field group - {{ formBuilderGroup.fields.length }} options inside (click to
        {{ showExpandedContent ? 'collapse' : 'expand' }})</small
      >
    </p>
  </div>
  <div *ngIf="showExpandedContent" fxLayout="column" fxFlex>
    <p-divider type="dashed"></p-divider>
    <div class="px-4 py-1" cdkDropList [cdkDropListData]="formBuilderGroup.fields" (cdkDropListDropped)="drop($event)">
      <div
        *ngFor="let field of formBuilderGroup.fields"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="mb-3 px-3 py-2 group-value-container"
        [pTooltip]="
          field.options.fieldDetails === FieldDetailsOptionsEnum.MARKED_AS_DEFAULT
            ? 'This is the default option'
            : field.options.fieldDetails === FieldDetailsOptionsEnum.MARKED_AS_REQUIRED
            ? 'This is the required option'
            : field.options.fieldDetails === requiredAndDefault || field.options.fieldDetails === defaultAndRequired
            ? 'This is both the required and the default option'
            : ''
        "
        tooltipPosition="left"
        [class.default-group-option]="field.options.fieldDetails === FieldDetailsOptionsEnum.MARKED_AS_DEFAULT"
        [class.required-group-option]="field.options.fieldDetails === FieldDetailsOptionsEnum.MARKED_AS_REQUIRED"
        [class.required-and-default-group-option]="
          field.options.fieldDetails === requiredAndDefault || field.options.fieldDetails === defaultAndRequired
        "
      >
        <div fxLayoutAlign="space-between center" fxLayout="row" fxFlex>
          <p class="mb-0" *ngIf="field.options.customFieldLabel && !field.isGroupOptionInEdit">
            {{ field.options.customFieldLabel }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<p-menu #groupMenu [popup]="true" appendTo="body" [model]="groupItems" styleClass="larger-p-menu"></p-menu>
