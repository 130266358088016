<div>
  <div class="details-category-field-header flex justify-content-between">
    <h4 class="details-category-field-header-title">Field category</h4>
  </div>
  <div class="details-category-field-content-property my-2" fxLayout="row">
    <div class="details-category-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">Field category:</div>
    <div class="details-category-field-content-property-content" fxLayoutAlign="start center">
      <div *ngIf="!isInEditMode">
        {{ fieldCategory?.title }}
      </div>
      <div *ngIf="isInEditMode">
        <form [formGroup]="form">
          <p-dropdown
            [options]="fieldCategories"
            appendTo="body"
            optionLabel="title"
            optionValue="slug"
            formControlName="fieldCategory"
            placeholder="Select a category"
          ></p-dropdown>
        </form>
      </div>
    </div>
  </div>
</div>
