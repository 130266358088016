<div
  [pTooltip]="displayLabel.length > 50 ? displayLabel : ''"
  [fxLayout]="isHorizontal ? 'row' : 'column'"
  [fxLayoutAlign]="isHorizontal ? 'center center' : 'start start'"
  [class.my-2]="!isHorizontal"
>
  <p [class]="isHorizontal ? 'p-mb-0' : 'p-mb-1'" [class.mr-2]="isHorizontal">
    {{ (displayLabel.length > 50 ? (displayLabel | slice: 0:50) : displayLabel) + (displayLabel.length > 50 ? '...' : '') }}
  </p>
  <div [class]="'fieldType-chip ' + item.fieldType.type" style="align-self: auto">
    {{ item.fieldType.label }}
  </div>
</div>
