import { Component, Input, OnInit } from '@angular/core';
import { Policy } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-client-policies',
  templateUrl: './client-policies.component.html',
  styleUrls: ['./client-policies.component.scss'],
})
export class ClientPoliciesComponent implements OnInit {
  @Input() clientPolicies: Policy[];
  items = [];

  ngOnInit(): void {
    this.items = [
      {
        label: 'Policies options',
        items: [
          {
            label: 'Option 1',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Option 2',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
    console.log(this.clientPolicies);
  }

  toggleMenu(policy: Policy, menu, ev) {
    ev.preventDefault();
    menu.toggle(ev);
  }
}
