import { Component, Input } from '@angular/core';
import { FieldTableType, FormBuilderFieldType, FormBuilderGroupType, FormBuilderItem } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-custom-field-card',
  templateUrl: './custom-field-card.component.html',
  styleUrls: ['./custom-field-card.component.scss'],
})
export class CustomFieldCardComponent {
  @Input() item: FormBuilderItem;

  FormBuilderFieldType = FormBuilderFieldType;
  FormBuilderGroupType = FormBuilderGroupType;
  FormBuilderTableType = FieldTableType;
}
