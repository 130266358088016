import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldDisclosureDetails, FieldTypeEnum, FieldDetailsOptions, ValidatorTypeEnum } from '@iapplication2/interfaces';
import { DisclosuresService, CustomValidatorsService, ApplicationsProcessService } from '@iapplication2/services';
import _ = require('lodash');
import { Subject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { BaseGroupDataDirective } from '../../../../baseGroupData.directive';
import { register } from '../../../../registryComponent';

@register('disclosure')
@Component({
  selector: 'iapplication2-disclosure-group-display',
  templateUrl: './disclosure-group-display.component.html',
  styleUrls: ['./disclosure-group-display.component.scss'],
})
export class DisclosureGroupDisplayComponent extends BaseGroupDataDirective implements OnInit, OnDestroy {
  disclosureDetails: FieldDisclosureDetails;
  isDisclosurePlaying = false;
  formControlName: string;
  requiredOptionId: number;
  showWarningText = false;
  isGroupRequired = false;
  disclosureSuccessfullyPlayed = false;
  tabIndex = -1;
  isViewApplication: boolean;

  unsubscribe: Subject<void> = new Subject();

  FieldTypeEnum = FieldTypeEnum;
  constructor(
    public disclosuresService: DisclosuresService,
    private customValidatorsService: CustomValidatorsService,
    private applicationsProcessService: ApplicationsProcessService
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkViewApplicationDisplay();
    this.isReadonly = true;
    this.formControlName = this.group.groupOptions.id?.toString();
    this.disclosureDetails = this.group.fields[0]?.fieldDisclosureOption;
    this.requiredOptionId = this.findRequiredOption();
    if (this.disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.checkboxGroup) {
      this.mapCheckboxGroupValue(this.formControl.value);
    }
    this.watchFormChanges();
    this.setDisclosureAnswering();
    this.checkValidators();
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.isViewApplication = value;
    });
  }

  toggleDisclosurePlaying() {
    if (!this.isDisclosurePlaying) {
      this.disclosuresService.playDisclosure(this.disclosureDetails.mp3FileName).subscribe({
        next: () => {
          this.isDisclosurePlaying = !this.isDisclosurePlaying;
          const value =
            this.disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.checkboxGroup
              ? [this.group?.groupOptions?.id]
              : this.group?.groupOptions?.id;
          this.formControl.setValue(value);
        },
      });
    } else {
      this.disclosuresService.stopDisclosure(this.disclosureDetails.mp3FileName).subscribe({
        next: () => {
          this.enableDisclosureAnswering();
          this.isDisclosurePlaying = !this.isDisclosurePlaying;
        },
      });
    }
  }

  onCheckboxChange() {
    if (this.formControl.value?.length > 1 && this.formControl.value?.includes(this.group?.groupOptions?.id)) {
      const value = this.formControl.value.filter((value) => value !== this.group?.groupOptions?.id);
      this.formFieldControl.setValue(value);
    }
  }

  checkIfDisclosureHasRequiredValidator(): boolean {
    let hasRequiredValidator = false;
    this.group.fields[0]?.fieldValidators?.manualValidators?.forEach((manualValidator) => {
      if (manualValidator.validatorKey === ValidatorTypeEnum.REQUIRED) {
        hasRequiredValidator = true;
      }
    });

    return hasRequiredValidator;
  }

  checkIfDisclosureHasRequiredPlayValidator(): boolean {
    let hasRequiredPlayValidator = false;
    this.group.fields[0]?.fieldValidators?.manualValidators?.forEach((manualValidator) => {
      if (manualValidator.validatorKey === ValidatorTypeEnum.DISCLOSURE_REQUIRED_PLAY) {
        hasRequiredPlayValidator = true;
      }
    });

    return hasRequiredPlayValidator;
  }

  private checkValidators() {
    switch (true) {
      case this.checkIfDisclosureHasRequiredValidator(): {
        this.formControl.setValidators([this.customValidatorsService.disclosureRequired(this.group?.groupOptions?.id?.toString())]);
        if (this.requiredOptionId) {
          this.formControl.addValidators([this.customValidatorsService.specificOptionSelected(this.requiredOptionId.toString())]);
        }
        this.formControl.updateValueAndValidity();
        this.isGroupRequired = true;
        this.shouldShowWarningText();
        break;
      }
      case this.checkIfDisclosureHasRequiredPlayValidator(): {
        this.formControl.setValidators([this.customValidatorsService.disclosureRequiredPlay()]);
        this.formControl.updateValueAndValidity();
        this.isGroupRequired = true;
        this.shouldShowWarningText();
        break;
      }
      default: {
        this.isReadonly = false;
        this.tabIndex = null;
        this.isGroupRequired = false;
        this.shouldShowWarningText();
      }
    }
  }

  private enableDisclosureAnswering() {
    this.disclosureSuccessfullyPlayed = true;
    this.isReadonly = false;
    this.tabIndex = null;
    this.formControl.enable({ emitEvent: false });
  }

  private setDisclosureAnswering() {
    if (this.formControl.value && !_.isEmpty(this.formControl.value) && this.formControl.value[0] !== null) {
      this.disclosureSuccessfullyPlayed = true;
      this.isReadonly = false;
      this.tabIndex = null;
    } else {
      this.isReadonly = true;
      this.tabIndex = -1;
    }
  }

  private watchFormChanges() {
    const initialValue = this.formControl.value ? parseInt(this.formControl.value) : null;
    this.formControl.valueChanges.pipe(startWith(initialValue), pairwise(), takeUntil(this.unsubscribe)).subscribe((data) => {
      this.shouldShowWarningText();

      if (
        data[0] === data[1] &&
        data[0] !== null &&
        !this.isReadonly &&
        this.disclosureDetails?.disclosureFieldTypeId === FieldTypeEnum.radioGroup
      ) {
        this.formControl.setValue(this.group.groupOptions.id);
      }
    });
  }

  private mapCheckboxGroupValue(value: any) {
    if (value === null || value === undefined) return;
    if (!Array.isArray(value)) {
      this.formControl.setValue([value]);
    }
  }

  private shouldShowWarningText() {
    if (this.isGroupRequired) {
      this.showWarningText = !this.formControl.valid;
    }
  }

  private findRequiredOption() {
    const requiredOption = this.group.fields.find((fieldInGroup) => {
      return fieldInGroup.options?.fieldDetails?.includes(FieldDetailsOptions.MARKED_AS_REQUIRED);
    });
    if (requiredOption) {
      requiredOption.isRequiredDisclosureOption = true;
      return requiredOption.id;
    }
    return null;
  }

  get formControl() {
    return <FormControl>this.parentFormGroup?.controls[this.group?.groupOptions?.id];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
