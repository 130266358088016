import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Advisor, Client } from '@iapplication2/interfaces';
import { AdvisorsService, ClientManagementService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-client-actions',
  templateUrl: './client-actions.component.html',
  styleUrls: ['./client-actions.component.scss'],
})
export class ClientActionsComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  @Input() currentTabIndex: number;
  @Input() clientForm: FormGroup;
  @Output() updateClientEmitter: EventEmitter<unknown> = new EventEmitter();
  @ViewChild('op') overlayPanel;
  advisor: Advisor;
  advisorList: Advisor[] = [];
  filteredAdvisorsList: Advisor[] = [];
  selectedAdvisor: Advisor;

  private unsubscribe: Subject<unknown> = new Subject();

  constructor(private clientManagementService: ClientManagementService, private advisorsService: AdvisorsService) {}

  ngOnInit(): void {
    this.getAdvisorById();
  }

  getAdvisorById() {
    if (this.client.advisorId) {
      this.advisorsService
        .getAdvisorByAdvisorId(this.client.advisorId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.advisor = res as Advisor;
          console.log(this.advisor);
        });
    }
  }

  updateClient() {
    if (!this.clientForm.pristine) {
      this.updateClientEmitter.emit();
    }
  }

  onChangedAdvisor(advisor: Advisor) {
    this.client.advisorId = advisor.id;
    this.clientManagementService.updateClient(this.client);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
