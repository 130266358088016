import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { Carrier, ENV } from '@iapplication2/interfaces';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarrierManagementService {
  loadedCarrier: ReplaySubject<Carrier> = new ReplaySubject<Carrier>(1);
  isSingleProductPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshCarriers: Subject<boolean> = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  getCarriers(): Observable<unknown> {
    return this.http.get('carriers');
  }

  getCarrierById(id: string) {
    return this.http.get(`carriers/${id}`);
  }

  loadCarrier(carrier: Carrier) {
    this.loadedCarrier.next(carrier);
  }

  updateCarrier(carrier: Carrier) {
    return this.http.put(`carriers/${carrier.id}`, carrier);
  }

  createCarrier(carrier: Carrier) {
    return this.http.post(`carriers`, carrier);
  }

  uploadLogo(logo: any, carrier: Carrier) {
    return this.http.put(`carriers/${carrier.id}/logo`, logo);
  }

  deleteCarrier(carrier: Carrier) {
    return this.http.delete(`carriers/${carrier.id}`);
  }

  get _isSingleProductPage(): Observable<boolean> {
    return this.isSingleProductPage.asObservable();
  }

  updateProductPageStatus(value: boolean): void {
    this.isSingleProductPage.next(value);
  }
}
