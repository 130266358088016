import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfDrawingComponent } from './pdf-drawing.component';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [PdfDrawingComponent],
  imports: [CommonModule, ButtonModule, SkeletonModule, TooltipModule, ConfirmDialogModule],
  exports: [PdfDrawingComponent],
})
export class PdfDrawingModule {}
