/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilderField, FormBuilderFieldTypeOption } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-field-details-type-option',
  templateUrl: './field-details-type-option.component.html',
  styleUrls: ['./field-details-type-option.component.scss'],
})
export class FieldDetailsTypeOptionComponent {
  @Input() option: FormBuilderFieldTypeOption;
  @Input() field: FormBuilderField;
  @Input() isInEditMode: boolean = false;
  @Input() isInFieldBuilder: boolean = false;
  @Input() isInModal: boolean = false;
  @Output() optionDeleted: EventEmitter<FormBuilderFieldTypeOption> = new EventEmitter();

  deleteOptionFromSelectedOptions(): void {
    this.field.fieldTypeOptions = this.field.fieldTypeOptions.filter((option) => option != this.option);
    this.optionDeleted.emit(this.option);
  }
}
