import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface DynamicTopbarInformationOptions {
  displayInfo: boolean;
  displayComponent: 'formBuilder' | 'fieldBuilder' | 'formDisplay';
}

@Injectable({
  providedIn: 'root',
})
export class DynamicTopbarInformationService {
  displayDynamicInformation: ReplaySubject<DynamicTopbarInformationOptions> = new ReplaySubject<DynamicTopbarInformationOptions>(1);

  currentSectionIdForFormDisplay: ReplaySubject<number> = new ReplaySubject<number>(1);
}
