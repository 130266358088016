import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import {
  RatesProductAdvisor,
  Application,
  ApplicationFormData,
  ENV,
  FormBuilderField,
  Product,
  ProductType,
  ApplicationFormPdfResponseType,
  ApplicationProduct,
} from '@iapplication2/interfaces';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { DatesService } from '../utils/dates/dates.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsProcessService {
  selectedProducts: ReplaySubject<Product[]> = new ReplaySubject(1);
  onMovedToSummary: ReplaySubject<boolean> = new ReplaySubject(1);
  formValidCanMoveToSummary: ReplaySubject<boolean> = new ReplaySubject(1);
  isReflexPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  pdfGenerationCompleted: ReplaySubject<boolean> = new ReplaySubject(1);
  saveFormCalledFromStep2: BehaviorSubject<boolean> = new BehaviorSubject(false);
  saveApplication: ReplaySubject<boolean> = new ReplaySubject(1);
  isHumaniaCoverageOverMax: ReplaySubject<boolean> = new ReplaySubject(1);
  isViewApplication: ReplaySubject<boolean> = new ReplaySubject(1);

  applicationFormDataArray: ApplicationFormData[] = [];
  allFieldsInAllForms: FormBuilderField[] = [];
  availableProducts: RatesProductAdvisor[] = [];

  currentApplication: Application = {} as Application;
  applicationChanged: ReplaySubject<Application> = new ReplaySubject<Application>(1);

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(APP_CONFIG) private appConfig: ENV,
    private http: HttpClient,
    private authService: AuthService,
    private datesService: DatesService
  ) {}

  reset() {
    this.selectedProducts.next([]);
    this.onMovedToSummary.next(false);
    this.formValidCanMoveToSummary.next(false);
    this.isReflexPage.next(false);
    this.pdfGenerationCompleted.next(null);
    this.saveFormCalledFromStep2.next(false);
    this.saveApplication.next(null);
    this.applicationFormDataArray = [];
    this.allFieldsInAllForms = [];
    this.availableProducts = [];
    this.currentApplication = null;
    this.applicationChanged.next(null);
    this.isHumaniaCoverageOverMax.next(null);
  }

  getIsReflexPage(): Observable<boolean> {
    return this.isReflexPage.asObservable();
  }

  setCurrentApplication(application: Application, isFromPUTCall = false) {
    this.currentApplication = application;

    if (!isFromPUTCall) {
      this.applicationChanged.next(application);
    }
  }

  setReflexPage(value: boolean) {
    this.isReflexPage.next(value);
  }

  getApplicationById(id) {
    return this.http.get(`application/${id}`);
  }

  createNewApplicationId() {
    const payload = {
      advisorId: this.authService.user.id,
    };

    return this.http.post('application/empty', payload);
  }

  rescheduleApplication(
    id: number,
    scheduledDate: string,
    products: Pick<
      ApplicationProduct,
      | ApplicationProduct['id']
      | ApplicationProduct['productId']
      | ApplicationProduct['monthlyPremium']
      | ApplicationProduct['yearlyPremium']
    >[]
  ) {
    const payload = {
      date: scheduledDate,
      products: products,
    };
    return this.http.put(`application/reschedule/${id}`, payload);
  }

  updateApplication(id, payload: Application) {
    // eslint-disable-next-line no-restricted-syntax
    if (payload && Object.keys(payload)?.length !== 0) {
      return this.http.put(`application/${id}`, payload);
    }
  }

  getFna(id) {
    return this.http.get(`application/fna/applicationId=${id}`);
  }

  uploadFnaFile(id, fna: any) {
    const formData: FormData = new FormData();
    formData.append('file', fna, fna?.name);
    return this.http.post(`application/fna/applicationId=${id}`, formData);
  }

  uploadFnaForm(id, formInfo: any) {
    return this.http.post(`application/fna/applicationId=${id}`, { ...formInfo, isApplicable: true });
  }

  setFnaNonApplicable(id) {
    return this.http.post(`application/fna/applicationId=${id}`, { isApplicable: false });
  }

  deleteUploadedFnaFile(applicationId) {
    return this.http.delete('application/fna/file/applicationId=' + applicationId);
  }

  saveFormData(applicationId: number, items: ApplicationFormData[]) {
    return this.http.put(`applications/${applicationId}/form-field-list`, items);
  }

  getFormData(applicationId: number) {
    return this.http.get(`applications/${applicationId}/product-form-field-list`);
  }

  triggerCompletetAtDateSave(applicationId: number) {
    return this.http.put(`application/completed-at/${applicationId}`, null);
  }

  generatePdf(applicationId: number) {
    this.http.post(`applications/${applicationId}/pdf`, null).subscribe({
      next: () => {
        this.pdfGenerationCompleted.next(true);
      },
    });
  }

  getPdf(applicationId: number, formId: number, type: 'link' | 'object') {
    return this.http.get(`applications/${applicationId}/forms/${formId}/pdf/${type}`);
  }

  getApplicationFormsPdf(applicationId: number, type: ApplicationFormPdfResponseType) {
    return this.http.get(`applications/${applicationId}/application-forms-pdf/${type}`);
  }

  createQuestion() {
    //
  }

  getAllQuestions() {
    return this.http.get('question/getAllTemplateQuestions');
  }

  getAllProducts() {
    let params = new HttpParams();
    params = params.append('take', '20');
    params = params.append('page', '1');
    return this.http.get('product/template/getAll', { params });
  }

  getProductsByClientDOB(dob: string) {
    const convertedDob = this.datesService.convertDayMonthYearToYearMonthDay(dob);
    return this.http.post('product/products-for-client', { dateOfBirth: convertedDob });
  }

  getProductRates(productType: ProductType, body) {
    return this.http.post(`product/product-rates/${productType?.type}`, body);
  }

  getProductsByAppId(id) {
    let params = new HttpParams();
    params = params.append('take', '20');
    params = params.append('page', '1');
    params = params.append('applicationId', id);
    return this.http.get('product/template', { params });
  }

  deleteApplicationByProductId(id) {
    return this.http.delete('product/template' + `/${id}`);
  }

  getPossibleApplicationStatuses() {
    return this.http.get('applications/getAllStatuses');
  }

  getEverestInfo(data: { province: string; postal_code: string }) {
    return this.http.post('product/everest-info', data);
  }

  createOrUpdateReflexData(reflexData: any) {
    return this.http.put('reflex', reflexData);
  }

  getReflexData(applicationId: any) {
    return this.http.get(`reflex/${applicationId}`);
  }

  deleteReflexData(applicationId: any) {
    return this.http.delete(`reflex/${applicationId}`);
  }

  getReflexHistoryData(applicationId: any) {
    return this.http.get(`reflex/history/${applicationId}`);
  }

  finishApplication(applicationId: number) {
    return this.http.post(`application/finishApplication/${applicationId}`, null);
  }
}
