<div fxFlex fxLayout="column">
  <div class="flex flex-row">
    <div fxFlex fxLayout="column" *ngIf="group.fields.length">
      <div *ngIf="!group.fields[0].table" fxFlex fxLayoutAlign="row">
        <iapplication2-required
          *ngIf="!isViewApplication"
          [required]="formFieldControl.errors?.required && !group.fields?.[0]?.table || formFieldControl.errors?.radioGroupRequired && !group.fields?.[0]?.table"
        ></iapplication2-required>
        <div>
          {{ group.groupOptions.name }}
        </div>
      </div>
      <div [class.radio-group-row-options]="flexRow">
        <div
          class="flex mb-2 mt-2 radio-group-option"
          [class.displayReadonlyValue]="isReadonly"
          [class.requiredOption]="field.isRequiredOption && showWarningText"
          *ngFor="let field of group.fields"
          [formGroup]="parentFormGroup"
        >
          <iapplication2-required
            *ngIf="!isViewApplication"
            [optionRequired]="field.isRequiredOption && showWarningText"
          ></iapplication2-required>
          <p-radioButton
            [value]="field.id"
            class="mr-1"
            [formControlName]="formControlName"
            [class.isReadOnlyRadioButton]="isReadonly"
            [tabindex]="tabIndex"
            [disabled]="isViewApplication"
          ></p-radioButton>
          {{
            (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
              | translate: { Default: field?.options?.customFieldLabel }
          }}
        </div>
      </div>
    </div>
    <iapplication2-required
      *ngIf="!isViewApplication"
      class="flex"
      style="min-width: 20px"
      [rowRequired]="group?.requiredByTableRow"
    ></iapplication2-required>
  </div>
  <iapplication2-form-field-error
    *ngIf="formControl?.invalid && formControl.touched && formControl.dirty && !group.fields?.[0]?.table && !isViewApplication"
    [control]="formControl"
  ></iapplication2-form-field-error>
</div>
