import { Pipe, PipeTransform } from '@angular/core';
import { FormAreaWidthOptions } from '@iapplication2/interfaces';

@Pipe({
  name: 'convertFormAreaWidth',
})
export class ConvertFormAreaWidthPipe implements PipeTransform {
  transform(value: string): number {
    switch (value) {
      case FormAreaWidthOptions.FULL:
        return 100;
      case FormAreaWidthOptions.HALF:
        return 50;
      default:
        return 100;
    }
  }
}
