import { FormGroup } from '@angular/forms';
import { Illustration, Language } from '@iapplication2/interfaces';
import { Carrier } from './carrier.model';
import { ProductType } from './product-type.model';

export interface Product {
  id?: number;
  name: string;
  carrier?: Carrier;
  carrierId?: number;
  form: number;
  frForm: number;
  versionNumber: string;
  rates: string;
  language?: Language;
  illustrations?: Illustration[];
  initiallySelected?: boolean;
  isCompleted?: boolean;
  coverage?: number;
  monthlyPremium?: number;
  yearlyPremium?: number;
  addProductLabel?: string;
  disclosures?: Disclosure[];
  maxCoverage: number;
  maxCoverageNoQuestionnaire: number;
  productTypeId: number;
  productType: ProductType;
  productFormId: number;
  ratesProductId: number;
  hasReflexQuestionnaire: boolean;
  hasEverestAddon: boolean;
  ageType: string;
  position: number;
  productForms: ProductFormVariation[];
  lokaliseKey: string;
  [key: string]: any;
}

export interface ProductFormVariation {
  id?: number;
  language?: Language;
  formId?: number;
  enabled?: boolean;
  control?: FormGroup;
}

export interface Disclosure {
  id?: number;
  disclosure?: string;
}
