import { Client } from '../client.model';
import { Advisor } from './advisor.model';
import { Currency } from './currency.model';
import { DisplayProduct } from './display-product.model';

export interface Policy {
  id: string;
  client: Client;
  products: DisplayProduct[];
  premium: number;
  currency: Currency;
  leadId: 'iApp Sale';
  appoinment: string;
  emailedBy: Advisor;
  emailedDate: Date;
  viewed: boolean;
  applicationId?: number;
  effectiveDate?: Date;
  deathBenefitAmount?: number;
  premiumDueDate?: Date;
}
