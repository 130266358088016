import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import {
  ENV,
  FormBuilderFieldTypeEntriesPredefined,
  FormBuilderFieldTypeIdEnum,
  FormBuilderFieldTypeOption,
  FormBuilderFieldTypeType,
  FormBuilderFieldValidator,
} from '@iapplication2/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InteractiveFormBuilderTypeOptionsService {
  formBuilderFieldTypeIdEnum = FormBuilderFieldTypeIdEnum;

  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  getPossibleOptionsByType(type: FormBuilderFieldTypeType): Observable<FormBuilderFieldTypeOption[]> {
    return this.http.get<FormBuilderFieldTypeOption[]>(`form-builder/fieldTypeOptions/${this.formBuilderFieldTypeIdEnum[type]}`);
  }

  getPossiblePredefinedEntriesByType(type: FormBuilderFieldTypeType): Observable<FormBuilderFieldTypeEntriesPredefined[]> {
    return this.http.get<FormBuilderFieldTypeEntriesPredefined[]>(
      `predefined-items/predefinedEntries/${this.formBuilderFieldTypeIdEnum[type]}`
    );
  }

  getPossiblePredefinedValidatorsByType(type: FormBuilderFieldTypeType): Observable<FormBuilderFieldValidator[]> {
    return this.http.get<FormBuilderFieldValidator[]>(
      `form-builder/fieldTypePredefinedValidators/${this.formBuilderFieldTypeIdEnum[type]}`
    );
  }

  getPossibleManualValidatorsByType(type: FormBuilderFieldTypeType): Observable<FormBuilderFieldValidator[]> {
    return this.http.get<FormBuilderFieldValidator[]>(`form-builder/fieldTypeManualValidators/${this.formBuilderFieldTypeIdEnum[type]}`);
  }
}
