import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Client, Message, Severity } from '@iapplication2/interfaces';
import { ClientManagementService } from '@iapplication2/services';
import { FormHelper } from '@iapplication2/superclass';
import { StringValidator } from '@iapplication2/validators';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-client-new',
  templateUrl: './client-new.component.html',
  styleUrls: ['./client-new.component.scss'],
  providers: [MessageService],
})
export class ClientNewComponent extends FormHelper implements OnInit, OnDestroy {
  clientForm: FormGroup;
  formSubmissionTriggered = false;

  client: Client = {} as Client;

  messages: {
    success: Message;
    error: Message;
  } = {
    success: {
      summary: 'Success',
      detail: 'The client was successfully created',
    },
    error: {
      summary: 'Error',
      detail: 'The form is invalid',
    },
  };

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private clientManagementService: ClientManagementService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.getTranslationsForMessages();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForMessages();
    });
    this.createClientForm();
  }

  getTranslationsForMessages() {
    this.translateService
      .get('pages.newClient.messages')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.messages.error.summary = res.error?.summary;
        this.messages.error.detail = res.error?.detail;
        this.messages.success.summary = res.success?.summary;
        this.messages.success.detail = res.success?.detail;
      });
  }

  createClientForm() {
    this.clientForm = new FormGroup({
      firstName: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      middleName: new FormControl(null, [Validators.maxLength(50)]),
      lastName: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      language: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [
        StringValidator.requiredNoOnlySpaces(),
        Validators.required,
        Validators.email,
        Validators.maxLength(100),
      ]),
      birthDate: new FormControl(null, [Validators.required]),
      smokerStatus: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      countryOfBirth: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      province: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required, Validators.maxLength(50)]),
      postalCode: new FormControl(null, [StringValidator.requiredNoOnlySpaces(), Validators.required]),
      note: new FormControl(null, [Validators.maxLength(500)]),
    });
  }

  createClient() {
    this.formSubmissionTriggered = true;

    this.client = this.createObjectFromFormValues(this.clientForm, this.client);
    //TODO integration add advisor id
    // this.client.birthDate = new Date(this.client.birthDate).toLocaleDateString(
    //   'en-US'
    // );
    if (this.clientForm.status === 'VALID') {
      this.clientManagementService
        .createClient(this.client)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: Client) => {
          this.showSuccessMessage();
          this.clientForm.reset();
          this.formSubmissionTriggered = false;
          this.router.navigate(['clients', 'client', res.id]);
        });
    } else {
      this.showErrorMessage();
    }
  }

  showSuccessMessage() {
    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: this.messages.success.summary,
      detail: this.messages.success.detail,
    });
  }

  showErrorMessage() {
    this.messageService.add({
      severity: Severity.ERROR,
      summary: this.messages.error.summary,
      detail: this.messages.error.detail,
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
