import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { HttpClient } from '@angular/common/http';
import { ENV } from '@iapplication2/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApplicationPolicyService {
  // policyApiUrl = this.appConfig.apiEndpoints.applications + '/api/policy';

  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  createPolicy(payload: any) {
    return this.http.post('policy', payload);
  }

  getPolicyById(id: string) {
    return this.http.get('policy' + `/policyId=${id}`);
  }
}
