import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessagesModel, Severity, StaticApplicationDisclosure, StaticApplicationDisclosureAnswer } from '@iapplication2/interfaces';
import { ApplicationsProcessService, DisclosuresService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import _ = require('lodash');
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-static-disclosure',
  templateUrl: './static-disclosure.component.html',
  styleUrls: ['./static-disclosure.component.scss'],
  providers: [MessageService],
})
export class StaticDisclosureComponent implements OnInit, OnDestroy {
  @Input() staticApplicationDisclosure: StaticApplicationDisclosure;
  @Output() changedStaticApplicationDisclosureAnswer: EventEmitter<unknown> = new EventEmitter();
  disclosureText: string;
  disclosureError: string;
  staticApplicationDisclosureAnswerForm: FormGroup;
  isDisclosurePlaying = false;
  showWarningText = false;
  unsubscribe: Subject<unknown> = new Subject();
  disclosureSuccessfullyPlayed = false;
  audioDisclosureMessageKey = 'audio-disclosure-message';

  // TODO: Refactor bellow to not require local initialization of object with empty values
  translatedDisclosurePlayMessages: MessagesModel = {
    success: {
      summary: '',
      detail: '',
    },
    error: {
      summary: '',
      detail: '',
    },
  };

  translatedDisclosureStopMessages: MessagesModel = {
    success: {
      summary: '',
      detail: '',
    },
    error: {
      summary: '',
      detail: '',
    },
  };
  constructor(
    private translate: TranslateService,
    public disclosuresService: DisclosuresService,
    private applicationsProcessService: ApplicationsProcessService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.createStaticApplicationDisclosureAnswerForm();
    this.watchFormChanges();
    this.fillFormWithData();
    this.setTranslatedStrings();
  }

  startPlayingDisclosure() {
    if (!this.isDisclosurePlaying) {
      this.disclosuresService
        .playDisclosure(this.staticApplicationDisclosure.mp3File)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.isDisclosurePlaying = true;
            this.showDisclosurePlaySuccessMessage();
          },
          error: () => {
            this.showDisclosurePlayErrorMessage();
          },
        });
    }
  }

  stopPlayingDisclosure() {
    if (this.isDisclosurePlaying) {
      this.disclosuresService
        .stopDisclosure(this.staticApplicationDisclosure.mp3File)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.isDisclosurePlaying = false;
            this.disclosureSuccessfullyPlayed = true;
            this.staticApplicationDisclosureAnswerForm.get('answer').enable();
            this.showDisclosureStopSuccessMessage();
          },
          error: () => {
            this.showDisclosureStopErrorMessage();
          },
        });
    }
  }

  private fillFormWithData() {
    const disclosureAnswer = this.applicationsProcessService.currentApplication?.staticApplicationDisclosureAnswers.find(
      (answer) => answer.disclosureType === this.staticApplicationDisclosure.disclosureType
    );
    if (disclosureAnswer) {
      this.staticApplicationDisclosureAnswerForm.get('answer').patchValue(disclosureAnswer.answer);
      this.disclosureSuccessfullyPlayed = true;
      this.staticApplicationDisclosureAnswerForm.get('answer').enable();
    }
  }

  private getTranslatedDisclosureText(disclosureType: string) {
    this.translate
      .get(`applicationProcess.staticApplicationDisclosures.${disclosureType}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.disclosureText = res.text;
        this.disclosureError = res.error;
      });
  }

  private createStaticApplicationDisclosureAnswerForm() {
    this.staticApplicationDisclosureAnswerForm = new FormGroup({
      answer: new FormControl({ value: null, disabled: true }, [Validators.required]),
    });
  }

  private watchFormChanges() {
    this.staticApplicationDisclosureAnswerForm
      .get('answer')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.showWarningText = this.staticApplicationDisclosureAnswerForm.get('answer').value === false;
        if (!this.applicationsProcessService.currentApplication?.staticApplicationDisclosureAnswers.length) {
          this.applicationsProcessService.currentApplication.staticApplicationDisclosureAnswers = [];
        }
        const answerIndex = this.applicationsProcessService.currentApplication?.staticApplicationDisclosureAnswers.findIndex(
          (answer) => answer.disclosureType === this.staticApplicationDisclosure.disclosureType
        );
        if (answerIndex !== -1 && this.applicationsProcessService.currentApplication) {
          this.applicationsProcessService.currentApplication.staticApplicationDisclosureAnswers[answerIndex] = {
            ...this.applicationsProcessService.currentApplication?.staticApplicationDisclosureAnswers[answerIndex],
            ...this.prepareAnswerForApplication(),
          };
        } else {
          this.applicationsProcessService.currentApplication?.staticApplicationDisclosureAnswers.push(this.prepareAnswerForApplication());
        }
        this.changedStaticApplicationDisclosureAnswer.emit();
      });
  }

  private prepareAnswerForApplication(): StaticApplicationDisclosureAnswer {
    const staticApplicationDisclosureAnswer: StaticApplicationDisclosureAnswer = {
      disclosureType: this.staticApplicationDisclosure.disclosureType,
      answer: this.staticApplicationDisclosureAnswerForm.get('answer').value,
    };
    return staticApplicationDisclosureAnswer;
  }

  showDisclosurePlaySuccessMessage() {
    this.messageService.clear();
    this.messageService.add({
      key: this.audioDisclosureMessageKey,
      severity: Severity.SUCCESS,
      summary: this.translatedDisclosurePlayMessages.success.summary,
      detail: this.translatedDisclosurePlayMessages.success.detail,
    });
  }

  showDisclosurePlayErrorMessage() {
    this.messageService.clear();
    this.messageService.add({
      key: this.audioDisclosureMessageKey,
      severity: Severity.ERROR,
      summary: this.translatedDisclosurePlayMessages.error.summary,
      detail: this.translatedDisclosurePlayMessages.error.detail,
    });
  }

  showDisclosureStopSuccessMessage() {
    this.messageService.clear();
    this.messageService.add({
      key: this.audioDisclosureMessageKey,
      severity: Severity.SUCCESS,
      summary: this.translatedDisclosureStopMessages.success.summary,
      detail: this.translatedDisclosureStopMessages.success.detail,
    });
  }

  showDisclosureStopErrorMessage() {
    this.messageService.clear();
    this.messageService.add({
      key: this.audioDisclosureMessageKey,
      severity: Severity.ERROR,
      summary: this.translatedDisclosureStopMessages.error.summary,
      detail: this.translatedDisclosureStopMessages.error.detail,
    });
  }

  setTranslatedStrings() {
    this.getTranslatedDisclosureText(this.staticApplicationDisclosure.disclosureType);
    this.getTranslationsForDisclosurePlayingMessages();

    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslatedDisclosureText(this.staticApplicationDisclosure.disclosureType);
      this.getTranslationsForDisclosurePlayingMessages();
    });
  }

  getTranslationsForDisclosurePlayingMessages() {
    this.translate
      .get('applicationProcess.staticApplicationDisclosures.humania')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.translatedDisclosurePlayMessages.success.summary = res.playMessages.success.summary;
        this.translatedDisclosurePlayMessages.success.detail = res.playMessages.success.detail;
        this.translatedDisclosurePlayMessages.error.summary = res.playMessages.error.summary;
        this.translatedDisclosurePlayMessages.error.detail = res.playMessages.error.detail;

        this.translatedDisclosureStopMessages.success.summary = res.stopMessages.success.summary;
        this.translatedDisclosureStopMessages.success.detail = res.stopMessages.success.detail;
        this.translatedDisclosureStopMessages.error.summary = res.stopMessages.error.summary;
        this.translatedDisclosureStopMessages.error.detail = res.stopMessages.error.detail;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
