/* eslint-disable max-len */
import { Inject, Injectable } from '@angular/core';
import { ENV, FormListDisplay, LanguagesEnum, Page, PredefinedFormBuilderWizardSection } from '@iapplication2/interfaces';
import { InteractiveFormBuilderService } from '../interactive-form-builder/interactive-form-builder.service';
import { Subject } from 'rxjs';
import { FormManagementService } from '../form-management/form-management.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@iapplication2/app-config';
@Injectable({
  providedIn: 'root',
})
export class FormBuilderWizardService {
  restartWizardSubject: Subject<unknown> = new Subject();

  private _processedPages: Page[];
  private _selectedSections: PredefinedFormBuilderWizardSection[];
  private _finalPages: Page[];
  public get finalPages(): Page[] {
    return this._finalPages;
  }
  public set finalPages(value: Page[]) {
    this._finalPages = value;
  }

  public finishedDrawingEverything: Subject<unknown> = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig: ENV,
    private formManagementService: FormManagementService,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private router: Router,
    private http: HttpClient
  ) {}

  restartWizard() {
    this.restartWizardSubject.next(void 0);
    this.processedPages = [];
    this.selectedSections = [];
  }

  getFormBuilderWizardPredefinedSections() {
    return this.http.get(`predefined-items/wizard-fields`);
  }

  uploadPdf(file) {
    const form = new FormData();
    form.append('file', file);
    return this.http.post(`form-page/generate-from-pdf`, form);
  }

  set processedPages(processedPages: Page[]) {
    this._processedPages = processedPages;
  }

  get processedPages(): Page[] {
    return this._processedPages;
  }

  set selectedSections(sections: PredefinedFormBuilderWizardSection[]) {
    this._selectedSections = [];
    sections.forEach((section) => {
      if (section.selected) {
        this._selectedSections.push(section);
      }
    });
  }

  get selectedSections() {
    return this._selectedSections;
  }

  createFormAndAddToBuilder(language: LanguagesEnum, name: string, carrierId: string) {
    this.selectedSections.forEach((section) => {
      delete section.concatenatedFields;
      delete section.active;
      delete section.selected;
      delete section.deletedItemFromSection;
    });
    const reqObj = {
      name: name,
      language: language,
      pages: this.finalPages,
      formSections: this.selectedSections,
    };
    this.http.post(`form-builder/by-carrier/${carrierId}`, reqObj).subscribe((res: { id: string }) => {
      const interval = setInterval(() => this.updateCanvasWithFormId(res.id, interval), 500);
    });
  }

  private updateCanvasWithFormId(id, interval) {
    this.http.get(`form-builder/form/${id}`).subscribe((form: FormListDisplay) => {
      if (form.pages.length > 0) {
        clearInterval(interval);
        form.pages.forEach((page) => {
          const parsedData = JSON.parse(page.canvasData);
          parsedData.objects?.forEach((object) => {
            object.customProps.formId = id;
          });
          page.canvasData = JSON.stringify(parsedData);
        });
        this.http.put(`form-builder/form/${id}`, form).subscribe(() => {
          this.router.navigate(['/formBuilder', 'form', id, 'page', this.finalPages[0].id, 'draw']);
        });
      }
    });
  }
}
