/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnInit } from '@angular/core';
import { ProductPdf } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
})
export class PdfPreviewComponent implements OnInit {
  @Input() productPdfs: ProductPdf[];
  @Input() displayPdfsToChange: boolean = true;
  selectedProductPdf: ProductPdf;
  thumbnailProductPdf: ProductPdf[];
  currentPage: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.changeSelectedPdf(this.productPdfs[0]);
  }

  changeSelectedPdf(productPdf: ProductPdf) {
    this.selectedProductPdf = this.productPdfs.filter((pdf) => pdf.id === productPdf.id)[0];
    this.thumbnailProductPdf = this.productPdfs.filter((pdf) => pdf.id !== productPdf.id);
    this.currentPage = 0;
  }

  prevPage() {
    this.currentPage = this.currentPage > 0 ? (this.currentPage -= 1) : this.currentPage;
  }

  nextPage() {
    this.currentPage = this.currentPage < this.selectedProductPdf.pages.length - 1 ? (this.currentPage += 1) : this.currentPage;
  }

  onPageChange(event) {
    this.currentPage = event.page;
  }
}
