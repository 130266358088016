<h4 class="mt-2">{{ 'pages.clients.policies.title' | translate }}</h4>
<div class="card mb-2">
  <p-table
    *ngIf="clientPolicies.length > 0"
    [value]="clientPolicies"
    [scrollable]="true"
    scrollHeight="200px"
    [autoLayout]="true"
    #customtable
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="med-col">{{ 'pages.clients.applications.client' | translate }}</th>
        <th class="med-col">{{ 'pages.clients.applications.premium' | translate }}</th>
        <th>{{ 'pages.clients.applications.products' | translate }}</th>
        <th class="small-col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-policy>
      <tr>
        <td class="med-col">{{ policy?.client?.firstName }} {{ policy?.client?.lastName }}</td>
        <td class="med-col">
          {{ policy.currency.symbol }}{{ policy.premium }} {{ policy.currency.name }}{{ 'pages.clients.applications.perMonth' | translate }}
        </td>
        <td>
          <iapplication2-product-list-display></iapplication2-product-list-display>
        </td>
        <td class="small-col" style="text-align: right">
          <p-button icon="pi pi-cog" class="p-button-secondary" (click)="toggleMenu(policy, menu, $event)"></p-button>
          <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="clientPolicies.length === 0">{{ 'pages.clients.policies.empty' | translate }}</div>
</div>
