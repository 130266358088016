import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotificationCenterComponent } from './notification-center.component';
import { NotificationsModule } from '../notifications.module';

@NgModule({
  declarations: [NotificationCenterComponent],
  imports: [CommonModule, RouterModule.forChild([{ path: '', component: NotificationCenterComponent }]), NotificationsModule],
})
export class NotificationsCenterModule {}
