import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Advisor } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-advisor-card',
  templateUrl: './advisor-card.component.html',
  styleUrls: ['./advisor-card.component.scss'],
})
export class AdvisorCardComponent implements OnInit, OnChanges {
  @Input() advisor: Advisor;
  profileImageLink: string;

  ngOnInit(): void {
    this.createImageLink();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.advisor.isFirstChange()) {
      this.createImageLink();
    }
  }

  private createImageLink() {
    this.profileImageLink =
      'assets/placeholder-images/' + this.advisor?.profileImage + '.jpeg';
  }
}
