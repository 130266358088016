import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilderFieldStaticFieldLink, FormBuilderItem } from '@iapplication2/interfaces';
import { InteractiveFormBuilderStaticFieldLinkService } from '@iapplication2/services';
import { TreeNode } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface StaticTreeObject {
  label: string;
  data: string | number;
  expandedIcon?: string;
  collapsedIcon?: string;
  selectable?: boolean;
  children?: StaticTreeObject[];
}

@Component({
  selector: 'iapplication2-field-details-static-field-link',
  templateUrl: './field-details-static-field-link.component.html',
  styleUrls: ['./field-details-static-field-link.component.scss'],
})
export class FieldDetailsStaticFieldLinkComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isInFieldBuilder = false;
  @Input() isInEditMode = false;
  @Input() isInModal = false;
  @Input() selectedField: FormBuilderItem;

  staticFields: FormBuilderFieldStaticFieldLink[];

  selectedNode: TreeNode;

  unsubscribe: Subject<unknown> = new Subject();

  staticFieldsTreeSelectObjects: StaticTreeObject[] = [];

  constructor(private interactiveFormBuilderStaticFieldLinkService: InteractiveFormBuilderStaticFieldLinkService) {}

  ngOnInit(): void {
    if (this.selectedField?.fields?.length) {
      this.selectedField.staticValueLink = this.selectedField.fields[0].staticValueLink;
      this.selectedField.staticValueLinkId = this.selectedField.fields[0].staticValueLinkId;
    }
    this.getStaticFieldsToLinkWith();
  }

  private getStaticFieldsToLinkWith(): void {
    this.interactiveFormBuilderStaticFieldLinkService
      .getAllStaticFields()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.staticFields = res;
        this.prepareTreeSelectObject(res);
        this.setSelectedNode();
      });
  }

  nodeSelected() {
    if (this.selectedNode.data !== this.selectedField?.staticValueLinkId) {
      this.selectedField.staticValueLinkId = this.selectedNode.data;
      this.selectedField.staticValueLink = this.staticFields.filter((staticField) => staticField.id === this.selectedNode.data)[0];
    }
  }

  selectedNodeCleared() {
    this.selectedNode = null;
    this.selectedField.staticValueLinkId = null;
    this.selectedField.staticValueLink = null;
  }

  setSelectedNode() {
    this.staticFieldsTreeSelectObjects.forEach((staticFieldsTreeSelectObject) => {
      let foundChildren = null;
      staticFieldsTreeSelectObject.children?.forEach((children) => {
        if (children.data === this.selectedField?.staticValueLink?.id) {
          foundChildren = children;
        }
      });
      if (foundChildren) {
        this.selectedNode = {
          data: foundChildren.data,
          label: foundChildren.label,
          parent: staticFieldsTreeSelectObject,
        };
      }
    });
  }

  prepareTreeSelectObject(staticFieldsToLinkWith: FormBuilderFieldStaticFieldLink[]) {
    const parents: StaticTreeObject[] = [];

    staticFieldsToLinkWith.forEach((staticField) => {
      const parentWithSameTypeExists = parents.filter((parent) => parent.label === staticField.type)[0];

      if (!parentWithSameTypeExists) {
        const parentTreeObjectToAdd: StaticTreeObject = {
          label: staticField.type,
          data: staticField.type,
          expandedIcon: 'pi pi-folder-open',
          collapsedIcon: 'pi pi-folder',
          selectable: false,
          children: [],
        };

        parentTreeObjectToAdd.children = [
          {
            label: staticField.name,
            data: staticField.id,
          },
        ];
        parents.push(parentTreeObjectToAdd);

        this.staticFieldsTreeSelectObjects.push(parentTreeObjectToAdd);
      } else {
        const childTreeObjectToAdd: StaticTreeObject = {
          label: staticField.name,
          data: staticField.id,
        };
        this.staticFieldsTreeSelectObjects.some((staticFieldTreeSelectObject) => {
          if (staticFieldTreeSelectObject.children && staticFieldTreeSelectObject.label === staticField.type) {
            staticFieldTreeSelectObject.children.push(childTreeObjectToAdd);
            return true;
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isInEditMode && changes.isInEditMode?.currentValue != changes.isInEditMode?.previousValue) {
      if (changes.isInEditMode?.currentValue === false && changes.isInEditMode?.previousValue === true) {
        this.selectedNode = null;
      }
    }
  }
}
