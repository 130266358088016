import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Country, Language, LanguagesEnum, Province } from '@iapplication2/interfaces';
import { AbstractControl, FormGroup } from '@angular/forms';
import { UtilsService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss'],
})
export class ClientInformationComponent implements OnInit, OnDestroy {
  @Input() clientForm: FormGroup;
  @Input() formSubmissionTriggered = false;
  @Input() showFullContent = true;
  @Input() isEditable = true;

  languages: Language[];

  countries: Country[];

  provinces: Province[];

  smokerStatuses: string[] = [];

  translatedPlaceholdersForDropdowns: {
    language: string;
    countryOfBirth: string;
    province: string;
  } = {
    language: 'Pick a language.',
    countryOfBirth: 'Select country.',
    province: 'Select province.',
  };

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(private utilsService: UtilsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.utilsService
      .getLanguages()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.languages = res as Language[];
        }
      });
    this.getCountries();
    this.getProvinces();

    this.smokerStatuses = this.utilsService.getSmokerStatuses();

    // Method needed to set the placeholder of a dropdown from i18n
    this.getTranslationsForDropdownPlaceholders();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForDropdownPlaceholders();
    });

    if (!this.isEditable) {
      this.clientForm.disable();
    }
    this.setOptionsOnLangChange();
  }

  setOptionsOnLangChange() {
    this.translateService.onLangChange.pipe().subscribe(() => {
      this.getProvinces();
      this.getCountries();
    });
  }

  getProvinces() {
    this.utilsService
      .getProvincesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.provinces = res?.map((province, index) => {
            const name = this.getNameKeyBySelectedLanguage();
            return {
              id: index + 1,
              name: province[name],
              abbreviation: province.prov_short,
            };
          });
        },
      });
  }

  getNameKeyBySelectedLanguage(): string {
    let name: string;
    switch (this.translateService.currentLang) {
      case LanguagesEnum.EN:
        name = 'name';
        break;
      default:
        name = `name_${this.translateService.currentLang}`;
    }
    return name;
  }

  getCountries() {
    this.utilsService
      .getCountriesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.countries = res?.data
            ?.map((country) => {
              const name = this.getNameKeyBySelectedLanguage();
              return {
                id: country.id,
                name: country[name],
                code: country.code,
              };
            })
            .sort((country1, country2) => {
              return country1.name?.localeCompare(country2.name);
            });
        },
      });
  }

  getTranslationsForDropdownPlaceholders() {
    this.translateService
      .get('pages.newClient.sections')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.translatedPlaceholdersForDropdowns.language = res.clientInformation?.fields?.language?.placeholder;
        this.translatedPlaceholdersForDropdowns.countryOfBirth = res.clientInformation?.fields?.countryOfBirth?.placeholder;
        this.translatedPlaceholdersForDropdowns.province = res.address?.fields?.province?.placeholder;
      });
  }

  hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator && this.isEditable) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  submit() {
    console.log(this.clientForm);

    console.log('submit');
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
