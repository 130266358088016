import { Pipe, PipeTransform } from '@angular/core';
import { FormBuilderField, FormBuilderFieldTypeType } from '@iapplication2/interfaces';

@Pipe({
  name: 'fieldTypeCheck',
})
export class FieldTypeCheckPipe implements PipeTransform {
  transform(field: FormBuilderField, type: FormBuilderFieldTypeType): boolean {
    switch (type) {
      case FormBuilderFieldTypeType.CHECKBOX:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.CHECKBOX);
      case FormBuilderFieldTypeType.DATE:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.DATE);
      case FormBuilderFieldTypeType.DISCLOSURE:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.DISCLOSURE);
      case FormBuilderFieldTypeType.DROPDOWN:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.DROPDOWN);
      case FormBuilderFieldTypeType.FREETEXT:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.FREETEXT);
      case FormBuilderFieldTypeType.IMAGE:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.IMAGE);
      case FormBuilderFieldTypeType.NUMBER:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.NUMBER);
      case FormBuilderFieldTypeType.SIGNATURE:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.SIGNATURE);
      case FormBuilderFieldTypeType.STATICTEXT:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.STATICTEXT);
      case FormBuilderFieldTypeType.PDF:
        return ((field as FormBuilderField).fieldType?.type === FormBuilderFieldTypeType.PDF);
      default:
        return false;
    }
  }
}
