export enum StaticFieldLinkType {
  INSURED_PERSON = 'Insured Person',
  OWNER = 'Owner',
  PAYOR = 'Payor',
  BENEFICIARIES = 'Beneficiaries',
  TRUSTEE = 'Trustee',
  ADVISORS = 'Advisors',
  PAYMENT_INFO = 'Payment Info',
  APPLICATION_INFO = 'Application Info',
  APPLICATION_PDFS = 'Application PDFs',
}
