import { Client } from '../client.model';
import { Currency } from './currency.model';
import { DisplayProduct } from './display-product.model';

export interface DtcPolicy {
  id: string;
  client: Client;
  products: DisplayProduct[];
  premium: number;
  currency: Currency;
  leadId: string;
  paymentMethod: PaymentOptions;
}

export enum PaymentOptions {
  CREDIT_CARD = 'Credit Card',
  CHECK = 'Check',
  PAD = 'PAD',
}
