import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomFormBuilderField, ENV, FormBuilderItem } from '@iapplication2/interfaces';
import { ReplaySubject } from 'rxjs';
import { APP_CONFIG } from '@iapplication2/app-config';
import _ = require('lodash');
import { ProgressSpinnerService } from '../progress-spinner/progress-spinner.service';

@Injectable({
  providedIn: 'root',
})
export class PredefinedFieldsService {
  private _fields: CustomFormBuilderField[] = [];

  public get fields(): CustomFormBuilderField[] {
    return this._fields;
  }
  public set fields(value: CustomFormBuilderField[]) {
    this._fields = value;
  }

  $fetchedFields: ReplaySubject<CustomFormBuilderField[]> = new ReplaySubject();
  predefinedFields: CustomFormBuilderField[];

  getPredefinedFieldsDebounced = _.debounce(() => this.getPredefinedFields(), 750);
  setFetchedPredefinedFieldsToSubjectDebounced = _.debounce(() => this.setFetchedPredefinedFieldsToSubject(), 750);

  constructor(
    @Inject(APP_CONFIG) private appConfig: ENV,
    private http: HttpClient,
    private progressSpinnerService: ProgressSpinnerService
  ) {}

  getPredefinedFields() {
    if (!this.fields.length) this.setFetchedPredefinedFieldsToSubject();
  }

  setFetchedPredefinedFieldsToSubject() {
    this.http.get('predefined-items/fields').subscribe((res: any) => {
      this.fields = res;
      this.$fetchedFields.next(res);
      this.predefinedFields = res;
    });
  }

  createPredefinedCustomField(customField: CustomFormBuilderField) {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.http.post('predefined-items/fields', customField).subscribe(() => {
      this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
      this.setFetchedPredefinedFieldsToSubjectDebounced();
    });
  }

  createNewPredefinedCustomField(customField: CustomFormBuilderField) {
    return this.http.post('predefined-items/fields', customField);
  }

  createPredefinedCustomFieldFromTable(customFields: CustomFormBuilderField[], table?: number) {
    if (table) customFields[0].field.table.type.id = table;
    return this.http.post('predefined-items/fields', customFields[0]).subscribe((res: CustomFormBuilderField) => {
      if (res) {
        if (!table) customFields[0].field.table.type.id = res.field.table.type.id;
        customFields = customFields.splice(1, customFields.length);

        if (customFields.length) this.createPredefinedCustomFieldFromTable(customFields, res.field.table.type.id);
        else {
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
          this.setFetchedPredefinedFieldsToSubjectDebounced();
        }
      }
    });
  }

  createPredefinedCustomFieldFromGroup(customFields: CustomFormBuilderField[], groupId?: number) {
    if (groupId) customFields[0].field.groupOptions.id = groupId;
    return this.http.post('predefined-items/fields', customFields[0]).subscribe((res: CustomFormBuilderField) => {
      if (res) {
        if (!groupId) customFields[0].field.groupOptions.id = res.field.groupOptions.id;
        customFields = customFields.splice(1, customFields.length);
        customFields.forEach((field) => (field.field.predefinedFieldDisclosureOption = res.field.predefinedFieldDisclosureOption));

        if (customFields.length) this.createPredefinedCustomFieldFromGroup(customFields, res.field.groupOptions.id);
        else {
          this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
          this.setFetchedPredefinedFieldsToSubjectDebounced();
        }
      }
    });
  }

  checkIfFieldWithProvidedNameExists(title: string, currentValue: string): boolean {
    return !!this.fields.filter((field) => {
      return field.field.options.customFieldName.toUpperCase() == title.toUpperCase() && title.toUpperCase() != currentValue.toUpperCase();
    })?.length;
  }

  updatePredefinedCustomField(customField: CustomFormBuilderField) {
    this.http.put(`predefined-items/fields/${customField.field.id}`, customField).subscribe(() => {
      this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
      this.setFetchedPredefinedFieldsToSubjectDebounced();
    });
  }

  deleteCustomField(item: any) {
    this.http.delete(`predefined-items/fields/${item?.id ? item?.id : item.field.id}`).subscribe(() => {
      this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
      this.setFetchedPredefinedFieldsToSubjectDebounced();
    });
  }

  checkIfAnyFieldIsCreatedFromPredefinedField(item: FormBuilderItem) {
    console.log(item);

    switch (true) {
      case !!item?.columns:
        return this.http.get(`predefined-items/in-use/table/${item.id}`);
      case !!item.groupOptions?.id:
        return this.http.get(`predefined-items/in-use/group/${item.groupOptions?.id}`);
      case !!item?.id:
        return this.http.get(`predefined-items/in-use/field/${item?.id}`);
      default:
        return null;
    }
  }
}
