import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FnaOption, Severity } from '@iapplication2/interfaces';
import { ApplicationsProcessService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-fna-selection',
  templateUrl: './fna-selection.component.html',
  styleUrls: ['./fna-selection.component.scss'],
})
export class FnaSelectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fnaOptions: FnaOption[];
  @Input() fnaSelection: FnaOption = null;
  @Input() fnaLiabilitiesAerobicHoursOptions;
  @Input() applicationId: number;
  @Input() fileKey: any;
  @Input() form: FormGroup;
  @Output() fnaFormIsValid: EventEmitter<boolean> = new EventEmitter();

  fnaAttachments = [];

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private translate: TranslateService,
    private applicationsProcessService: ApplicationsProcessService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.fnaSelectionChanged();
    this.watchLanguageChange();
    this.watchFormValueChanges();
    this.setPreviouslyUploadedFnaFileToAttachments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.fnaSelection) {
      this.fnaSelectionChanged();
    }
    if (changes?.fileKey) {
      this.setPreviouslyUploadedFnaFileToAttachments();
    }
  }

  watchLanguageChange(): void {
    this.getTranslationsForDropdownPlaceholders();
    this.getTranslationsForFnaOptions();

    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForDropdownPlaceholders();
      this.getTranslationsForFnaOptions();
    });
  }

  getTranslationsForDropdownPlaceholders() {
    this.translate
      .get('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek30OrLess')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.setLiabilitiesAerobicHours(res, 0);
      });
    this.translate
      .get('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek30-2')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.setLiabilitiesAerobicHours(res, 1);
      });
    this.translate
      .get('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek2-5')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.setLiabilitiesAerobicHours(res, 2);
      });
    this.translate
      .get('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek5-10')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.setLiabilitiesAerobicHours(res, 3);
      });
    this.translate
      .get('client_selection.fna.fnaLiabilitiesAerobicHoursPerWeek10OrMore')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.setLiabilitiesAerobicHours(res, 4);
      });
  }

  getTranslationsForFnaOptions() {
    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.translate
        .get('client_selection.fna.fillInForm')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: string) => {
          this.setFnaOptionTranslationByValue(res, 0);
        });
      this.translate
        .get('client_selection.fna.fillIfNeeded')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: string) => {
          this.setFnaOptionTranslationByValue(res, 1);
        });
      this.translate
        .get('client_selection.fna.fillUpload.label')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: string) => {
          this.setFnaOptionTranslationByValue(res, 2);
        });
    });
  }

  private setFnaOptionTranslationByValue(label: string, key: number) {
    const optionObject = {
      label: label,
      value: key,
    };
    this.fnaOptions[key] = optionObject;

    if (this.fnaSelection.value === key) {
      this.fnaSelection = optionObject;
    }
  }

  private setLiabilitiesAerobicHours(label: string, key: number) {
    this.fnaLiabilitiesAerobicHoursOptions[key] = label;
  }

  private setPreviouslyUploadedFnaFileToAttachments(): void {
    if (this.fileKey) {
      const attachment = {
        s3Link: this.fileKey?.s3Link,
        name: this.fileKey?.s3Key?.split('/fna/')?.[1],
      };

      this.fnaAttachments = [attachment];
    }
  }

  private watchFormValueChanges() {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .pipe(skip(1), debounceTime(2500))
      .subscribe({
        next: () => {
          // Only trigger when selected option is the "Fill form" as the other triggers are in the fnaSelection value changes
          if (this.fnaSelection.value === 0) {
            this.uploadFnaForm(this.form.value);
            this.fnaFormIsValid.emit(this.form.valid);
          }
        },
      });
  }

  private uploadFnaForm(values: []) {
    this.applicationsProcessService.uploadFnaForm(this.applicationId, values).subscribe();
  }

  onUpload(event, form?) {
    const uploadedFile = event.files[0];
    form.clear();

    this.applicationsProcessService
      .uploadFnaFile(this.applicationId, uploadedFile)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          const fnaAttachment = uploadedFile;
          fnaAttachment.s3Link = res.s3Link;
          this.fnaAttachments = [fnaAttachment];
        },
        complete: () => {
          this.messageService.add({ severity: Severity.INFO, summary: 'File uploaded', detail: '' });
          this.fnaFormIsValid.emit(true);
        },
        error: () => {
          this.messageService.add({ severity: Severity.ERROR, summary: 'File upload failed', detail: '' });
        },
      });
  }

  deleteFile() {
    this.applicationsProcessService
      .deleteUploadedFnaFile(this.applicationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.fnaAttachments = [];
        },
      });
  }

  fnaSelectionChanged() {
    switch (this.fnaSelection?.value) {
      case 0:
        if (this.fnaAttachments.length) this.deleteFile();
        this.fnaFormIsValid.emit(this.form.valid);
        break;
      case 1:
        this.form.reset();
        if (this.fnaAttachments.length) this.deleteFile();
        this.applicationsProcessService.setFnaNonApplicable(this.applicationId).pipe(takeUntil(this.unsubscribe)).subscribe();
        this.fnaFormIsValid.emit(true);
        break;
      case 2:
        this.checkIfFnaFileIsUploaded();
        break;
    }
  }

  checkIfFnaFileIsUploaded() {
    this.fnaFormIsValid.emit(!!this.fileKey);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
