export interface Currency {
  id?: number;
  symbol: string;
  name: string;
  position?: CurrencyPosition;
}

export enum CurrencyPosition {
  BEFORE = 'before',
  AFTER = 'after',
}
