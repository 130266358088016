export interface ApplicationQuestion{
    questionTemplateId: number;
    response: string;
}

export interface Question {
  id: string;
  order: string;
  title: string;
  response?: string;
  questions?: Question[];
}
