<div *ngIf="isInEditMode; else notInEditMode" fxLayout="row">
  <div *ngIf="fieldValidatorConfiguration.type === validatorConfigurationTypeEnum.INPUT">
    <input
      type="text"
      class="p-inputtext p-component"
      pInputText
      [(ngModel)]="fieldValidatorConfiguration.value"
      [maxlength]="getInputMaxLength()"
    />
  </div>
  <div *ngIf="fieldValidatorConfiguration.type === validatorConfigurationTypeEnum.NUMBERINPUT">
    <p-inputNumber
      mode="decimal"
      [useGrouping]="false"
      [(ngModel)]="fieldValidatorConfiguration.value"
      [maxlength]="getInputMaxLength()"
      [min]="getInputMinValue()"
    ></p-inputNumber>
  </div>
  <div *ngIf="fieldValidatorConfiguration.type === validatorConfigurationTypeEnum.CHIP">
    <p-chips
      [(ngModel)]="chipValue"
      separator=","
      [allowDuplicate]="false"
      maxlength="1"
      [addOnBlur]="true"
      (onAdd)="chipAdded($event)"
      (onRemove)="chipRemoved()"
    ></p-chips>
  </div>
  <div *ngIf="fieldValidatorConfiguration.type === validatorConfigurationTypeEnum.DROPDOWN">DROPDOWN</div>
  <div *ngIf="fieldValidatorConfiguration.type === validatorConfigurationTypeEnum.CHECKBOX">CHECKBOX</div>
</div>

<ng-template #notInEditMode>
  <div class="mb-1">
    {{ fieldValidatorConfiguration.value }}
  </div>
</ng-template>
