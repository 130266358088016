import { ApplicationStatusOptions } from './application-status-list.enum';

export interface ApplicationStatusList {
  statuses: ApplicationStatus[];
}

export interface ApplicationStatus {
  id: number;
  name: ApplicationStatusOptions;
  lokaliseKey?: string; // TODO: IAPP2CA-1416: put mandatory wen FE impl done
  displayedInDropdown?: boolean;
  status?: ApplicationStatus[];
}

export interface ApplicationStatusIntern {
  id?: number;
  name: ApplicationStatusOptions;
  displayedInDropdown: boolean;
  parentId: number;
  lokaliseKey?: string; // TODO: IAPP2CA-1416: put mandatory wen FE impl done
  status?: ApplicationStatus[];
}
