import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilderItem, FormBuilderStaticText, Themes } from '@iapplication2/interfaces';
import {
  DarkThemeService,
  FieldManagementService,
  InteractiveFormBuilderService,
  ProgressSpinnerService
} from '@iapplication2/services';
import { MenuItem } from 'primeng/api';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-form-builder-static-text-display',
  templateUrl: './form-builder-static-text-display.component.html',
  styleUrls: ['./form-builder-static-text-display.component.scss'],
})
export class FormBuilderStaticTextDisplayComponent implements OnInit, OnDestroy {
  @Input() formBuilderStaticText: FormBuilderStaticText;
  @Output() deletedField: EventEmitter<number> = new EventEmitter();
  sanitized: SafeHtml;
  isDarkTheme: boolean;

  unsubscribe: Subject<unknown> = new Subject();

  tempText: string;

  items: MenuItem[];

  displayStaticTextEditor: boolean;

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private fieldManagementService: FieldManagementService,
    private sanitizer: DomSanitizer,
    private progressSpinnerService: ProgressSpinnerService,
    private darkThemeService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.darkThemeService.themeChanged.subscribe(value => {
      this.isDarkTheme = value === Themes.DARK;
    });
    this.createFieldSettingsMenu();
    this.sanitized = this.sanitizer.bypassSecurityTrustHtml(this.formBuilderStaticText.content);
    this.displayStaticTextEditor = false;
    this.tempText = this.formBuilderStaticText.content;
  }

  toggleMenu(item: FormBuilderStaticText, menu: any, ev: any): void {
    const editMenuItem = this.items[0].items[0];
    const deleteMenuItem = this.items[0].items[1];
    const displayConditionsMenuItem = this.items[0].items[2];
    deleteMenuItem.command = () => this.deleteStaticText(item);
    editMenuItem.command = () => this.editField();
    displayConditionsMenuItem.command = () => this.openDisplayConditionsDialog(item);
    menu.toggle(ev);
  }

  private openDisplayConditionsDialog(item: FormBuilderItem) {
    this.interactiveFormBuilderService.showDisplayConditionsDialog.next(void 0);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions = new ReplaySubject(1);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions.next(item);
    this.interactiveFormBuilderService.selectedItemForDisplayConditions.complete();
  }

  editField(): void {
    this.tempText = this.formBuilderStaticText.content;
    this.displayStaticTextEditor = true;
  }

  saveNewText(): void {
    this.displayStaticTextEditor = false;

    this.sanitized = this.sanitizer.bypassSecurityTrustHtml(this.tempText);
    this.formBuilderStaticText.content = this.tempText;
    this.interactiveFormBuilderService.updateField(this.formBuilderStaticText);
  }

  cancelTextEdit(): void {
    this.displayStaticTextEditor = false;
    this.tempText = this.formBuilderStaticText.content;
  }

  deleteStaticText(field: FormBuilderStaticText): void {
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.interactiveFormBuilderService.deleteField(field);
  }

  private createFieldSettingsMenu(): void {
    this.items = [
      {
        label: 'Field settings',
        items: [
          {
            label: 'Edit static text',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete static text',
            icon: 'pi pi-trash',
          },
          {
            label: 'Set display conditions',
            icon: 'pi pi-sitemap',
          },
        ],
      },
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
