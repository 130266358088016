import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSettings } from '@iapplication2/interfaces';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  constructor(private http: HttpClient) {}

  getUserSeetingsByUserId(userId: number) {
    return of(null);
  }

  updateUserSettingsByUserId(userSettings: UserSettings) {
    of(null).subscribe();
  }
}
