<div fxLayout="row">
  <p-dropdown
    [options]="formSectionsList"
    class="mr-2"
    optionLabel="title"
    optionValue="id"
    placeholder="Select a section to jump to"
    [(ngModel)]="sectionId"
    (ngModelChange)="changedSection()"
  >
  </p-dropdown>
  <button
    pButton
    pRipple
    (click)="moveToPreviousSection()"
    label="Previous"
    type="button"
    class="mr-2 p-button-secondary p-button-text"
    *ngIf="!checkIfIsFirstSection()"
  ></button>
  <button pButton pRipple (click)="moveToNextSection()" label="Next" type="button" class="mr-2" *ngIf="!checkIfIsLastSection()"></button>
  <button pButton pRipple label="Complete application" type="button" class="mr-2" *ngIf="checkIfIsLastSection()"></button>
</div>
