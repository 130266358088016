/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'iapplication2-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss']
})
export class PhoneVerificationComponent implements OnInit {

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  codeSent: boolean = false;

  phoneVerificationForm: FormGroup = new FormGroup({
    currentPassword: new FormControl(null, [
      Validators.required
    ]),
    newPhoneNumber: new FormControl(null, [
      Validators.required
    ]),
    phoneValidation: new FormControl('', [
      Validators.required
    ])
  });

  phoneVerificationCodeForm: FormGroup = new FormGroup({
    validationCode: new FormControl(null, [
      Validators.required
    ]),
  })

  constructor() { }

  ngOnInit(): void {
  }

  requestValidationCode() {
    if (this.phoneVerificationForm.valid) {
      this.codeSent = true;
    }
  }

  requestPhoneNumberChange() {
    this.onClose();
  }

  onClose() {
    this.closeModal.emit(true);
  }

}
