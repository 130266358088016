<div fxFlex fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2>{{ 'applicationProcess.applicationProcess' | translate }}</h2>
    <div *ngIf="true; then navigations"></div>
    <ng-template #navigations>
      <div fxLayout="row" fxLayoutAlign="end center" class="px-3 py-3">
        <button
          *ngIf="currentStep !== 0"
          pButton
          label="{{ 'applicationProcess.previousStep' | translate }}"
          icon="pi pi-chevron-left"
          iconPos="left"
          (click)="previousStep()"
          class="mr-2"
          [disabled]="formIsLoading"
        ></button>
        <div [pTooltip]="canGoToNextStepTooltip" tooltipPosition="left" style="display: initial">
          <button
            *ngIf="currentStep !== 2"
            pButton
            label="{{ 'client_selection.save' | translate }}"
            class="mr-2"
            (click)="saveApplication()"
            [disabled]="formIsLoading"
          ></button>
          <button
            *ngIf="currentStep !== 2"
            pButton
            label="{{ 'applicationProcess.nextStep' | translate }}"
            icon="pi pi-chevron-right"
            iconPos="right"
            (click)="nextStep()"
            [disabled]="!canGoToNextStep || formIsLoading"
          ></button>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="p-card mb-3">
    <div class="p-3">
      <p-steps [model]="applicationProcess" [readonly]="true" [activeIndex]="currentStep"></p-steps>
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlex>
      <ng-template pTemplate="header">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <div class="px-3 pt-3">
            <div [ngSwitch]="currentStep">
              <div *ngSwitchCase="0">
                <h5 class="m-0">
                  {{ 'applicationProcess.step.productSelection.title' | translate }}
                </h5>
              </div>
              <div *ngSwitchCase="1">
                <h5 class="m-0">
                  {{ 'applicationProcess.step.applicationForms.title' | translate }}
                </h5>
              </div>
              <div *ngSwitchCase="2">
                <h5 class="m-0">
                  {{ 'applicationProcess.step.summary.title' | translate }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <div [ngSwitch]="currentStep">
        <div *ngSwitchCase="0">
          <iapplication2-client-selection
            [clientForm]="clientForm"
            [fnaForm]="fnaForm"
            (medicalQuestionnairesAreValid)="onMedicalQuestionnairesAreValidChanged($event)"
            (productSelectionChanged)="onProductSelectionChanged($event)"
            (fnaFormIsValid)="onFnaFormIsValid($event)"
            (staticApplicationDisclosureAnswerChanged)="onStaticApplicationDisclosureAnswerChanged()"
            (isAnyHumaniaProductSelectedChanged)="onIsAnyHumaniaProductSelected($event)"
          ></iapplication2-client-selection>
        </div>
        <div *ngSwitchCase="1">
          <iapplication2-application-form
            [applicationId]="applicationId"
            (formLoadingStateChanged)="onFormLoadingStateChanged($event)"
            (getApplicationTriggered)="onGetApplicationTriggered($event)"
            (waitingForChanges)="onWaitingForChanges($event)"
          ></iapplication2-application-form>
        </div>
        <div *ngSwitchCase="2">
          <iapplication2-summary (finishApplicationTriggered)="onFinishApplicationTriggered($event)"></iapplication2-summary>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentStep === 1 && !formIsLoading; then navigations"></div>
</div>

<p-dialog
  [(visible)]="displayApplicationWarning"
  [style]="{ width: '550px', borderRadius: '20px' }"
  [draggable]="false"
  [resizable]="false"
  header="Warning!"
  [contentStyle]="{ borderBottomRightRadius: '25px', borderBottomLeftRadius: '25px' }"
  (onHide)="goToViewApplication()"
  [modal]="displayApplicationWarning"
>
  <div class="px-5 pb-2">
    <p [style]="{ fontSize: '16px' }">
      <b>{{ 'applicationProcess.applicationStatusWarning' | translate }}, {{ currentApplicationStatus?.name?.toUpperCase() }}.</b>
    </p>
    <div class="flex flex-row justify-content-center align-items-center mb-5 mt-6">
      <p-button (click)="goToViewApplication()" styleClass="px-6 py-3"
        ><p class="m-0">
          <b>{{ 'applicationProcess.viewApplication' | translate }}</b>
        </p></p-button
      >
    </div>
  </div>
</p-dialog>
<p-toast key="save-application-message"></p-toast>
