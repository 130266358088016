import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSummaryService {
  constructor(private http: HttpClient) {}

  getApplicationAttachments(applicationId: string) {
    return this.http.get('application/attachments/' + applicationId);
  }

  deleteApplicationAttachmentsById(attachmentId: string | number) {
    return this.http.delete('application/attachments/' + attachmentId);
  }

  createAttachmentByApplicationId(applicationId: number | string, file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file, file?.name);
    return this.http.post(`application/attachments/${applicationId}`, formData);
  }

  createAttachmentWithFieldIdByApplicationId(applicationId: number | string, fieldId: number | string, file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file, file?.name);
    return this.http.post(`application/attachments-fields/${applicationId}/field-id/${fieldId}`, formData);
  }

  getUpsellProducts(applicationId: number | string) {
    return this.http.get(`product/upsale-products-for-client/${applicationId}`);
  }

  getPremiumsForScheduledApplication(applicationId: number) {
    return this.http.get(`application/premium/${applicationId}`);
  }
}
