import { Component, Input } from '@angular/core';
import { Application } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-application-notes',
  templateUrl: './application-notes.component.html',
  styleUrls: ['./application-notes.component.scss'],
})
export class ApplicationNotesComponent {
  @Input() application: Application;
}
