<div class="loginPage-body flex flex-column align-items-center justify-content-center">
  <div class="card">
    <div class="loginPage-panel flex flex-column align-items-center p-6">
      <a tabindex="0" id="logo-link" class="layout-topbar-logo">
        <img [src]="getLogoPathForCurrentLightMode()" alt="freya-layout" class="login-logo" />
      </a>

      <h5>{{ 'login.login' | translate }}</h5>

      <form [formGroup]="loginForm" class="flex flex-column width-100" (submit)="submitLoginForm()">
        <div class="flex flex-column align-items-start mb-3">
          <input pInputText id="email" placeholder="Email" formControlName="email" class="p-inputtext-lg width-100" />
          <iapplication2-form-field-error
            *ngIf="loginForm.get('email').touched || formSubmissionTriggered"
            [control]="loginForm.get('email')"
          ></iapplication2-form-field-error>
        </div>
        <div class="mb-3 flex flex-column align-items-start">
          <p-password
            id="password"
            placeholder="Password"
            type="password"
            [toggleMask]="true"
            formControlName="password"
            [feedback]="false"
            #passwordInput
            class="p-inputtext-lg"
          >
          </p-password>
          <iapplication2-form-field-error
            *ngIf="loginForm.get('password').touched || formSubmissionTriggered"
            [control]="loginForm.get('password')"
          ></iapplication2-form-field-error>
        </div>
        <button pButton pRipple label="Login" type="submit"></button>
      </form>
      <p class="mt-3 mb-1">
        {{ 'login.forgotPasswordQuestion' | translate }}
        <a href="https://reset.iapplication.ca/" target="_blank">{{ 'login.resetPassword' | translate }}</a>
      </p>
      <div fxLayout="row" class="mt-3" fxLayoutAlign="center center" *ngIf="loginErrorMessage">
        <p-message severity="error" [text]="loginErrorMessage"></p-message>
      </div>
      <!-- <p class="mt-1">
        Don’t have an account?
        <a [routerLink]="['/register']">Create one now</a>
      </p> -->
    </div>
  </div>
</div>
