import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { UiModule } from '@iapplication2/ui';
import { ServicesModule } from '@iapplication2/services';

@NgModule({
  imports: [CommonModule, SharedTranslateModule, UiModule, ServicesModule],
  exports: [SharedTranslateModule, UiModule, ServicesModule],
})
export class SharedModule {}
