
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProgressSpinnerService } from '@iapplication2/services';

@Injectable()
export class PendingRequestInterceptor implements HttpInterceptor {
  constructor(public progressSpinner: ProgressSpinnerService) { }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const listOfMethodsToTriggerSpinner = ['POST', 'PUT', 'DELETE', 'PATCH', 'GET'];
    if (listOfMethodsToTriggerSpinner.includes(request.method)) {
      this.progressSpinner.toggleGlobalLoadingSpinner(true);
    }
    return next.handle(request).pipe(
      tap((res: any) => {
        if (res?.body || res?.error) {
          this.progressSpinner.toggleGlobalLoadingSpinner(false);
        }
      })
    );
  }
}
