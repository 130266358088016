<div class="p-datatable p-component mb-4">
  <label>{{
    (formTableItem?.lokaliseKey ? formTableItem?.lokaliseKey : 'missing_key') | translate: { Default: formTableItem?.name }
  }}</label>
  <small *ngIf="invalidValuesInLinkedColumnsMessage && !isViewApplication" class="flex p-error mb-2">{{ invalidValuesInLinkedColumnsMessage }}</small>
  <small *ngIf="displayRequiredErrorMessage && !invalidValuesInLinkedColumnsMessage && !isViewApplication" class="flex p-error mb-2 mt-5">{{
    'errorMessages.invalidTable' | translate
  }}</small>
  <table>
    <thead class="p-datatable-thead">
      <tr>
        <th *ngFor="let column of formTableItem.columns">
          <div class="flex">
            {{ column.name }}
            <div *ngIf="column.linkedWith" class="flex align-items-center">
              <i
                class="ml-2 pi pi-link"
                [pTooltip]="'applicationProcess.step.applicationForms.displayItem.table.linkedTooltip' | translate"
                tooltipPosition="bottom"
              ></i>
            </div>
          </div>
        </th>
        <th>
          <div class="flex">{{ 'applicationProcess.step.applicationForms.displayItem.table.actionHeader' | translate }}</div>
        </th>
      </tr>
    </thead>
    <tbody class="p-datatable-tbody">
      <tr *ngFor="let in of counter(formTableItem.numberOfRows); let i = index">
        <td *ngFor="let item of itemsInTable[i]">
          <div *ngIf="item?.groupOptions; else isField">
            <iapplication2-form-display-group
              [formGroupItem]="item | cast: FormBuilderGroup"
              [formFieldControl]="parentFormGroup.controls[item.groupOptions.id] | cast: FormControlType"
              [parentFormGroup]="parentFormGroup"
            ></iapplication2-form-display-group>
          </div>
          <ng-template #isField>
            <iapplication2-form-display-field
              [formField]="item | cast: FormBuilderField"
              [formFieldControl]="parentFormGroup.controls[item.id] | cast: FormControlType"
              [parentFormGroup]="parentFormGroup"
              [isPreviewMode]="true"
              [hideLabel]="true"
            ></iapplication2-form-display-field>
          </ng-template>
        </td>
        <td>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-text"
            (click)="resetRow(i)"
            [disabled]="isViewApplication"
          ></button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
