<h4 class="mt-5">{{ 'pages.clients.applications.title' | translate }}</h4>
<div class="card mb-2">
  <div *ngIf="clientApplications.length > 0">
    <p-table [value]="clientApplications" [scrollable]="true" scrollHeight="200px" [autoLayout]="true" #customtable>
      <ng-template pTemplate="header">
        <tr>
          <th class="med-col">{{ 'pages.clients.applications.client' | translate }}</th>
          <th class="med-col">{{ 'pages.clients.applications.premium' | translate }}</th>
          <th>{{ 'pages.clients.applications.products' | translate }}</th>
          <th>{{ 'pages.clients.applications.status' | translate }}</th>
          <th class="small-col"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-application>
        <tr>
          <td class="med-col">{{ application.client.firstName }} {{ application.client.lastName }}</td>
          <td class="med-col">
            {{ application.currency.symbol }}{{ application.premium }} {{ application.currency.name
            }}{{ 'pages.clients.applications.perMonth' | translate }}
          </td>
          <td>
            <iapplication2-product-list-display></iapplication2-product-list-display>
          </td>
          <td>
            <iapplication2-product-status-tag [status]="application.status"></iapplication2-product-status-tag>
          </td>
          <td class="small-col" style="text-align: right">
            <p-button icon="pi pi-cog" class="p-button-secondary" (click)="toggleMenu(application, menu, $event)"></p-button>
            <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div *ngIf="clientApplications.length === 0">{{ 'pages.clients.applications.empty' | translate }}</div>
</div>
