/* eslint-disable max-len */
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Page, FormBuilderField, envType, ENV } from '@iapplication2/interfaces';
import { CanvasService, FormManagementService, FieldManagementService, CustomObject } from '@iapplication2/services';
import { fabric } from 'fabric';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActivatedRoute, Params } from '@angular/router';
import { APP_CONFIG } from '@iapplication2/app-config';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'iapplication2-pdf-drawing',
  templateUrl: './pdf-drawing.component.html',
  styleUrls: ['./pdf-drawing.component.scss'],
  providers: [ConfirmationService],
})
export class PdfDrawingComponent implements OnInit, OnDestroy, OnChanges {
  unsubscribe: Subject<void> = new Subject();

  @Output()
  navigateToAnotherPage: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('htmlCanvas') htmlCanvas: ElementRef;
  private canvas: fabric.Canvas;
  private clickedInsideCanvas: boolean;
  userDrawing: boolean;
  @Input() page: Page;
  selectedPage: Page;
  showCanvas = false;

  isEnvDevelop: boolean;

  constructor(
    private canvasService: CanvasService,
    private eRef: ElementRef,
    private formManagementService: FormManagementService,
    private route: ActivatedRoute,
    private fieldManagementService: FieldManagementService,
    private confirmationService: ConfirmationService,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit(): void {
    this.canvasService.userDrawing.pipe(takeUntil(this.unsubscribe)).subscribe((userDrawing) => {
      this.userDrawing = userDrawing;
    });
    this.formManagementService.changedPage.pipe(takeUntil(this.unsubscribe)).subscribe((res: Page) => {
      if (res) {
        this.changedPage(res);
      }
    });
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params: Params) => {
      this.getAllFormFields(parseInt(params['formId']));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageId && changes.pageId?.currentValue != changes.pageId?.previousValue && !changes.pageId?.firstChange) {
      this.setCanvasInfo();
    }
    if (
      changes.page &&
      !_.isEqual(changes.page?.previousValue?.canvasData, changes.page?.currentValue?.canvasData) &&
      !changes.page.firstChange
    ) {
      this.setCanvasInfo();
    }
  }

  checkIfEnvIsDevelop() {
    this.isEnvDevelop = this.appConfig.environmentType === envType.DEVELOP;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    this.eRef.nativeElement.contains(event.target) ? (this.clickedInsideCanvas = true) : (this.clickedInsideCanvas = false);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyUpKeyboardEvent(event: KeyboardEvent): void {
    if (this.clickedInsideCanvas) {
      this.canvasService.handleKeyboardDownEvents(event);
    }
  }

  private prepareInitialCanvas() {
    if (this.page) {
      this.canvas = this.canvasService.buildInitialCanvas(this.canvas, this.htmlCanvas, this.page.canvasImage);
      this.setCanvasInfo();
    }

    this.canvas.on('mouse:over', (o) => {
      this.setCursorAsCrosshair(o);
    });
    this.canvas.on('mouse:down', (o) => {
      this.canvasService.onCanvasMouseDown(this.canvas, o);
    });
    this.canvas.on('mouse:move', (o) => {
      this.setCursorAsCrosshair(o);
      this.canvasService.onCanvasMouseMove(this.canvas, o);
    });
    this.canvas.on('mouse:up', () => {
      this.canvasService.onCanvasMouseUp(this.canvas);
      // this.updateCanvasData();
    });
    this.canvas.on('selection:created', () => {
      this.canvasService.onCanvasSelectionCreated();
    });
    this.canvas.on('selection:updated', () => {
      this.canvasService.onCanvasSelectionUpdated();
    });
    this.canvas.on('selection:cleared', () => {
      this.canvasService.onCanvasSelectionCleared();
    });
    this.canvas.on('object:modified', () => {
      this.canvasService.objectModified();
    });
    this.canvas.on('object:moving', () => {
      this.canvasService.keepObjectInCanvas();
    });
  }

  private getAllFormFields(formId: number) {
    this.fieldManagementService
      .getAllFieldsByFormId(formId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FormBuilderField[]) => {
        this.canvasService.listOfAllFormFields = res;
        this.showCanvas = true;
        this.prepareInitialCanvas();
      });
  }

  changedPage(res: Page): void {
    this.selectedPage = res;
    this.navigateToAnotherPage.emit(this.selectedPage.id);
    this.page.canvasData = JSON.stringify(this.canvas.toJSON(['id']));
  }

  setCursorAsCrosshair(o: fabric.IEvent): void {
    if (this.userDrawing) {
      (o.e.target as HTMLElement).style.cursor = 'crosshair';
    }
  }

  updateCanvasData(): void {
    this.page.canvasData = JSON.stringify(this.canvas.toJSON(['id']));
  }

  saveCanvas(): void {
    this.canvasService.saveCanvasToJSON();
  }

  log(): void {
    console.log(this.canvas.toJSON(['id']));
  }

  deleteAllObjects() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete all form objects? This action is irreversible',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.canvasService.canvas.forEachObject((object) => this.canvasService.canvas.remove(object));
      },
    });
  }

  deleteSelectedObject() {
    const activeObject: CustomObject = this.canvasService.canvas.getActiveObject() as CustomObject;
    const fieldExistsInDatabase = this.canvasService.listOfAllFormFields.filter(
      (field: FormBuilderField) => field && field?.id === activeObject?.id
    )[0];
    if (activeObject && !fieldExistsInDatabase && this.canvasService.listOfAllFormFields.length) {
      this.canvasService.canvas.remove(this.canvasService.canvas.getActiveObject());
    }
  }

  loadCanvas(): void {
    this.canvasService.loadCanvasFromJSON();
  }

  private setCanvasInfo(): void {
    this.canvasService.setCanvas(this.canvas, this.page.canvasData, this.page.canvasImage);
    console.log(this.canvas.toJSON(['id']));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
