export interface FieldCategory {
  id: number;
  title: string;
  slug: string;
  position: number;
  isDefault: boolean;
}

export interface FieldCategory {
  id: number;
  title: string;
  slug: string;
  position: number;
  isDefault: boolean;
}

export enum CategorySlugs {
  UNASSIGNED = 'unassigned',
}
