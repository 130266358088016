import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldTypeEnum, FormBuilderFieldTypeType, FormBuilderItem, FieldDisclosureDetails } from '@iapplication2/interfaces';
import _ = require('lodash');
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DisclosuresService } from '@iapplication2/services';

@Component({
  selector: 'iapplication2-field-disclosure-details',
  templateUrl: './field-disclosure-details.component.html',
  styleUrls: ['./field-disclosure-details.component.scss'],
})
export class FieldDisclosureDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() item: FormBuilderItem;
  @Input() isInFieldBuilder: boolean;
  @Input() isInEditMode = true;
  disclosureDetails: FieldDisclosureDetails;
  disclosureInformationForm: FormGroup;
  mp3FileNames: string[] = [];
  disclosureTypeOptions: { name: string; value: number }[] = [
    {
      name: _.startCase(FormBuilderFieldTypeType.RADIOGROUP),
      value: FieldTypeEnum.radioGroup,
    },
    {
      name: _.startCase(FormBuilderFieldTypeType.CHECKBOXGROUP),
      value: FieldTypeEnum.checkboxGroup,
    },
  ];
  isDisclosure = false;

  FieldTypeEnum = FieldTypeEnum;

  unsubscribe: Subject<void> = new Subject();

  constructor(private disclosuresService: DisclosuresService) {}

  ngOnInit(): void {
    this.prepareFormAndFillWithData();
    this.getListOfMp3s();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item?.currentValue !== changes.item?.previousValue) {
      this.prepareFormAndFillWithData();
    }
  }

  getListOfMp3s() {
    this.disclosuresService
      .getListOfMp3Files()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any[]) => {
          if (res) {
            this.mp3FileNames = res.map((mp3File) => mp3File.Key.replace('.mp3', ''));
          }
        },
      });
  }

  private prepareFormAndFillWithData() {
    this.isDisclosure = this.item.groupOptions && this.item.groupOptions.type.id === FieldTypeEnum.disclosure;
    this.createInitialForm();
    this.watchFormChanges();
    if (this.item.predefinedFieldDisclosureOption || this.item.fieldDisclosureOption) {
      this.fillFormWithData();
    }
  }

  private createInitialForm() {
    this.disclosureInformationForm = new FormGroup({
      disclosureText: new FormControl(null, [Validators.required]),
      warningText: new FormControl(null),
      mp3FileName: new FormControl(null, [Validators.required]),
      disclosureType: new FormControl(null, [Validators.required]),
    });
  }

  private watchFormChanges() {
    this.disclosureInformationForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: () => {
        this.prepareDisclosureDetailsObject();
      },
    });
  }

  private fillFormWithData() {
    const dataToFill = this.isInFieldBuilder
      ? this.item.predefinedFieldDisclosureOption
      : this.item.fieldDisclosureOption
      ? this.item.fieldDisclosureOption
      : this.item.predefinedFieldDisclosureOption;
    this.disclosureInformationForm.get('disclosureText').setValue(dataToFill?.disclosureText);
    this.disclosureInformationForm.get('warningText').setValue(dataToFill?.warningText);
    this.disclosureInformationForm.get('mp3FileName').setValue(dataToFill?.mp3FileName);
    this.disclosureInformationForm.get('disclosureType').setValue(dataToFill?.disclosureFieldTypeId);
  }

  private prepareDisclosureDetailsObject() {
    this.disclosureDetails = {
      id: this.isInFieldBuilder ? this.item.predefinedFieldDisclosureOption?.id : this.item.fieldDisclosureOption?.id,
      disclosureText: this.disclosureInformationForm.get('disclosureText').value,
      warningText: this.disclosureInformationForm.get('warningText').value,
      mp3FileName: this.disclosureInformationForm.get('mp3FileName').value,
      disclosureFieldTypeId: this.disclosureInformationForm.get('disclosureType').value,
    };
    if (this.isInFieldBuilder) {
      this.item.predefinedFieldDisclosureOption = this.disclosureDetails;
    } else {
      this.item.fieldDisclosureOption = this.disclosureDetails;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
