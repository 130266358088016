<div class="advisor-dashboard" fxLayout="column">
  <div class="shortcuts" fxLayout="row wrap">
    <div class="mb-4" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-lg="25" fxFlex.gt-md="33">
      <div
        class="card overview-box blue cursor-pointer"
        fxLayout="row"
        fxFlex="100"
        fxLayoutAlign="space-between center"
        (click)="createApplication()"
      >
        <div class="overview-info unselectable">
          <h6>
            {{ 'pages.dashboard.shortcuts.newApplication.description' | translate }}
          </h6>
          <h2 class="m-0">
            {{ 'pages.dashboard.shortcuts.newApplication.action' | translate }}
          </h2>
        </div>
        <i class="pi pi-file-o"></i>
      </div>
    </div>
    <div class="custom-middle-card-padding mb-4" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-lg="25" fxFlex.gt-md="33">
      <div
        class="card overview-box gray cursor-pointer"
        fxFlex="100"
        [routerLink]="['clients', 'new']"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="overview-info unselectable">
          <h6>
            {{ 'pages.dashboard.shortcuts.newClient.description' | translate }}
          </h6>
          <h2 class="m-0">
            {{ 'pages.dashboard.shortcuts.newClient.description' | translate }}
          </h2>
        </div>
        <i class="pi pi-user-plus"></i>
      </div>
    </div>
    <div *ngIf="isEnvDevelop" class="mb-4" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-lg="25" fxFlex.gt-md="33">
      <div
        class="card overview-box orange cursor-pointer"
        fxFlex="100"
        [routerLink]="['quick-quotes', 'new']"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="overview-info unselectable">
          <h6>
            {{ 'pages.dashboard.shortcuts.quickQuote.description' | translate }}
          </h6>
          <h2 class="m-0">
            {{ 'pages.dashboard.shortcuts.quickQuote.description' | translate }}
          </h2>
        </div>
        <i class="pi pi-forward"></i>
      </div>
    </div>
  </div>
  <div class="widgets" fxLayout="column" fxLayoutGap="20px">
    <div class="row-1" fxLayout="row" fxLayoutGap="20px">
      <div class="recent-policies" fxFlex="100">
        <iapplication2-dashboard-applications-widget></iapplication2-dashboard-applications-widget>
      </div>
      <div *ngIf="isEnvDevelop" class="notification-center" fxFlex="30">
        <div class="card" style="min-height: 100%">
          <p>{{ 'pages.dashboard.sections.recentNotifications.title' | translate }}</p>
          <iapplication2-dashboard-notifications></iapplication2-dashboard-notifications>
        </div>
      </div>
    </div>
    <div class="row-2" fxLayout="row" fxLayoutGap="20px">
      <div class="recent-clients" fxFlex="100">
        <div class="card">
          <p-tabView>
            <p-tabPanel [header]="'pages.dashboard.sections.recentClients.tabs.recentClients.title' | translate">
              <iapplication2-result-section-clients [clients]="clients" [loading]="loadingClients"></iapplication2-result-section-clients>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
      <div *ngIf="isEnvDevelop" class="email-center" fxFlex="30">
        <div class="card" style="min-height: 100%">
          {{ 'pages.dashboard.sections.recentEmails.title' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
