<div
  class="menu-wrapper"
  [ngClass]="{ 'layout-sidebar-active': isMenuActive }"
  (mouseover)="onSidebarMouseOver($event)"
  (mouseleave)="onSidebarMouseLeave($event)"
>
  <div class="sidebar-logo">
    <a [routerLink]="['/']">
      <img [src]="getLogoPathForCurrentLightMode()" alt="logo" />
    </a>
    <a class="sidebar-pin cursor-pointer" (click)="onToggleMenu($event)">
      <span class="pin"></span>
    </a>
  </div>
  <div class="layout-menu-container">
    <ul class="layout-menu">
      <li app-menuitem *ngFor="let item of model; let i = index" [item]="item" [index]="i" [root]="true"></li>
    </ul>
  </div>
</div>
<i
  id="commit-hash"
  class="pi pi-info-circle"
  [hideDelay]="2000"
  appendTo="body"
  style="position: fixed; left: 27px; bottom: 40px; z-index: 10000"
  tooltipPosition="right"
  pTooltip="Commit: commit-hash"
></i>
