<p-dialog
  class="app-loader-dialog"
  [(visible)]="showProgressSpinner"
  [transitionOptions]="'30ms'"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [blockScroll]="true"
  [draggable]="false"
  [showHeader]="false"
>
  <p-progressSpinner strokeWidth="4" class="app-loader-spinner"></p-progressSpinner>
</p-dialog>
