import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationsProcessService, DatesService } from '@iapplication2/services';
import { CalendarOptions, EventClickArg, EventApi, EventInput } from '@fullcalendar/angular';
import { ApplicationProduct } from '@iapplication2/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import frLocale from '@fullcalendar/core/locales/fr';
import enLocale from '@fullcalendar/core/locales/en-gb';

enum SelectedDateIds {
  MANUALLY = 'manually-selected-date',
  SELECTED = 'selected-date',
}

@Component({
  selector: 'iapplication2-schedule-application-modal',
  templateUrl: './schedule-application-modal.component.html',
  styleUrls: ['./schedule-application-modal.component.scss'],
})
export class ScheduleApplicationModalComponent implements OnInit, AfterViewInit {
  @Input() premiums;
  @Output() scheduledDateSelected: EventEmitter<Date> = new EventEmitter();
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

  selectedDate: string;
  manuallySelectedDate: string;
  products: ApplicationProduct[] = [];
  events: EventInput[] = [];

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: '',
      center: 'title',
    },
    footerToolbar: false,
    initialView: 'dayGridMonth',
    initialEvents: this.events,
    editable: true,
    selectable: true,
    eventClick: this.handleEventClick.bind(this),
    locales: [frLocale, enLocale],
  };
  currentEvents: EventApi[] = [];
  exceptedDaysFromFuturePayment: number[] = [29, 30, 31];

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private datesService: DatesService,
    private applicationsProcessService: ApplicationsProcessService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setInitialEvents();
    this.calendarOptions.locale = this.translateService.currentLang;
    this.listenToLangChange();
  }

  listenToLangChange() {
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res) => {
        this.calendarOptions.locale = res.lang;
      },
    });
  }

  ngAfterViewInit() {
    this.setSelectedDate();
    this.setManuallySelectedDate();
    this.addListenerToMonthNavigationButtons();
    this.cdr.detectChanges();
  }

  addListenerToMonthNavigationButtons() {
    const nextButton = document.getElementsByClassName('fc-next-button')?.[0];
    const prevButton = document.getElementsByClassName('fc-prev-button')?.[0];
    if (prevButton && !prevButton.getAttribute('listened')) {
      prevButton.setAttribute('listened', '');
      prevButton.addEventListener('click', () => {
        this.setSelectedDate();
        this.setManuallySelectedDate();
      });
    }
    if (nextButton && !nextButton.getAttribute('listened')) {
      nextButton.setAttribute('listened', '');
      nextButton.addEventListener('click', () => {
        this.setSelectedDate();
        this.setManuallySelectedDate();
      });
    }
  }

  setSelectedDate() {
    this.selectedDate = this.applicationsProcessService.currentApplication?.scheduleDate
      ? new Date(this.applicationsProcessService.currentApplication?.scheduleDate)?.toISOString()
      : null;

    if (this.selectedDate) {
      const selectedDataDate = this.selectedDate?.substring(0, 10);
      const tableElementForSelectedDate = document.querySelectorAll(`[data-date="${selectedDataDate}"]`)?.[0];
      tableElementForSelectedDate?.getElementsByClassName('fc-daygrid-event')?.[0]?.setAttribute('id', SelectedDateIds.SELECTED);
    }
  }

  setManuallySelectedDate() {
    const tableElementForManuallySelectedDate = document.querySelectorAll(`[data-date="${this.manuallySelectedDate}"]`)?.[0];
    tableElementForManuallySelectedDate?.querySelectorAll('.fc-daygrid-event')?.[0]?.setAttribute('id', SelectedDateIds.MANUALLY);
  }

  setInitialEvents() {
    this.setProductsFromApplication();
    this.premiums.forEach((premium, index) => {
      const dateFromPremium = new Date(premium.date);

      const newEvent: EventInput = {
        id: this.datesService.formatForFullCalendar(dateFromPremium),
        title: this.setTitleForEvent(index, premium),
        start: this.datesService.formatForFullCalendar(dateFromPremium),
        end: this.datesService.formatForFullCalendar(dateFromPremium),
        allDay: true,
      };

      this.events.push(newEvent);
    });
  }

  setTitleForEvent(index: number, premium): string {
    return `
      Age: ${premium.age}
      Rate: ${premium.premium.toFixed(2)}
      Products: ${this.products.map((product) => product.name).join(', ')}
    `;
  }

  setProductsFromApplication() {
    this.applicationsProcessService.currentApplication?.products?.forEach((product: ApplicationProduct) => {
      this.products.push(product);
    });
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.manuallySelectedDate = clickInfo.el.closest('td')?.getAttribute('data-date');

    const existingElementId = clickInfo.el.getAttribute('id');

    if (existingElementId !== SelectedDateIds.SELECTED) {
      const previousSelectedDate = document.getElementById(SelectedDateIds.MANUALLY);
      if (previousSelectedDate) {
        previousSelectedDate.removeAttribute('id');
      }
      clickInfo.el.setAttribute('id', SelectedDateIds.MANUALLY);
      this.selectedDate = clickInfo.event._def.publicId;
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  setScheduledDate() {
    if (this.selectedDate) {
      this.scheduledDateSelected.emit(new Date(this.selectedDate));
      this.closeDialog.emit(true);
      this.switchToNewSelectedDate();
    }
  }

  switchToNewSelectedDate() {
    const previousSelectedDate = document.getElementById(SelectedDateIds.SELECTED);
    const newSelectedDate = document.getElementById(SelectedDateIds.MANUALLY);

    if (previousSelectedDate) {
      previousSelectedDate.removeAttribute('id');
    }
    if (newSelectedDate) {
      this.manuallySelectedDate = null;
      newSelectedDate.setAttribute('id', SelectedDateIds.SELECTED);
    }
  }
}
