import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FieldColumn,
  FieldTable,
  FieldType,
  FormBuilderField,
  FormBuilderFieldGroupOptions,
  FormBuilderGroup,
  FormBuilderItem,
  ModalType,
} from '@iapplication2/interfaces';
import { InteractiveFormBuilderService } from '@iapplication2/services';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-dynamic-topbar-field-builder-information',
  templateUrl: './dynamic-topbar-field-builder-information.component.html',
  styleUrls: ['./dynamic-topbar-field-builder-information.component.scss'],
})
export class DynamicTopbarFieldBuilderInformationComponent implements OnInit, OnDestroy {
  defaultItems: FieldType[];
  isFieldDialogOpen: boolean = false;
  isCategoryCreatorOpen: boolean = false;
  isCategoryManagerOpen: boolean = false;
  // selectedField: FormBuilderField;
  // selectedGroup: FormBuilderGroup;
  // selectedTable: FieldTable;
  selectedItem: FormBuilderItem;

  unsubscribe: Subject<unknown> = new Subject();

  settingsDialogOptions: ModalType;

  @ViewChild('op') overlayPanel;

  categoryManagerOptions: MenuItem[];

  constructor(private interactiveFormBuilderService: InteractiveFormBuilderService) {}

  ngOnInit(): void {
    this.settingsDialogOptions = ModalType.CREATE;
    this.interactiveFormBuilderService
      .getFieldTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: FieldType[]) => {
        this.setVisibleFieldTypes(res);
        this.interactiveFormBuilderService.defaultItems.next(res);
      });
    this.categoryManagerOptions = [
      {
        items: [
          {
            label: 'Create category',
            icon: 'pi pi-plus',
            command: () => {
              this.createCategory();
            },
          },
          {
            label: 'View categories',
            icon: 'pi pi-eye',
            command: () => {
              this.viewCategories();
            },
          },
        ],
      },
    ];
  }

  setVisibleFieldTypes(allDefaultItems: FieldType[]): void {
    this.defaultItems = allDefaultItems?.filter((item) => item.canBeDrawn);
  }

  fieldSelected(type: FieldType) {
    switch (true) {
      case this.interactiveFormBuilderService.isTypeGroup(type):
        {
          const groupOptions: FormBuilderFieldGroupOptions = {
            name: '',
            type: type,
          };
          const group: FormBuilderGroup = {
            groupOptions: groupOptions,
            fields: [],
          };
          this.selectedItem = group;
        }
        break;
      case this.interactiveFormBuilderService.isTypeTable(type): {
        const columns: FieldColumn[] = [];
        const table: FieldTable = {
          name: '',
          columns: columns,
          numberOfRows: 0,
        };
        this.selectedItem = table;
        break;
      }
      default: {
        const field: FormBuilderField = {
          fieldType: type,
          options: {
            customFieldLabel: '',
            customFieldName: '',
            visibleOnPdf: null,
          },
        };

        this.selectedItem = field as FormBuilderField;
      }
    }
    this.overlayPanel.hide();
    this.isFieldDialogOpen = true;
  }

  closeFieldDialog() {
    this.isFieldDialogOpen = false;
  }

  closeCategoryCreatorDialog() {
    this.isCategoryCreatorOpen = false;
  }

  createCategory() {
    this.isCategoryCreatorOpen = true;
  }

  viewCategories() {
    this.isCategoryManagerOpen = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
