/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldCategory } from '@iapplication2/interfaces';
import { FieldCategoryService, InteractiveFormBuilderService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-field-details-category',
  templateUrl: './field-details-category.component.html',
  styleUrls: ['./field-details-category.component.scss'],
})
export class FieldDetailsCategoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isInEditMode?: boolean = false;
  @Input() fieldCategory?: FieldCategory;
  @Input() form?: FormGroup;

  fieldCategories: FieldCategory[];

  private unsubscribe = new Subject<void>();

  constructor(private interactiveFormBuilderService: InteractiveFormBuilderService, private fieldCategoryService: FieldCategoryService) {}

  ngOnInit(): void {
    if (this.fieldCategory) {
      this.setFormFieldCategoryValue();
    }
    this.getCustomFieldCategories();
    this.interactiveFormBuilderService.sidebarListWasUpdated.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.fieldCategory) {
        this.setFormFieldCategoryValue();
      }
      this.getCustomFieldCategories();
    });
  }

  setFormFieldCategoryValue(): void {
    this.form.controls['fieldCategory'].setValue(this.fieldCategory?.slug);
  }

  getCustomFieldCategories(): void {
    this.fieldCategoryService.getFieldCategories();
    this.fieldCategoryService.fatchedCategories.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => (this.fieldCategories = res));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.fieldCategory?.isFirstChange() && changes.fieldCategory?.currentValue != changes.fieldCategory?.previousValue) {
      this.form.controls['fieldCategory'].setValue(this.fieldCategory?.slug);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
