export interface Title {
  label: TitleEnumEn | TitleEnumFr;
  value: TitleEnumEn;
}

export enum TitleEnumEn {
  MR = 'Mr.',
  MRS = 'Mrs.',
  MS = 'Ms.',
  MISS = 'Miss',
  REV = 'Rev.',
  DR = 'Dr.',
}

export enum TitleEnumFr {
  M = 'M.',
  MME = 'Mme',
  MLLE = 'Mlle',
  DR = 'Dr/Dre',
  REV = 'Le révérend père/la révérende mère/soeur',
  AUTRE = 'Autre',
}
