import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { Disclosure, ENV, Product } from '@iapplication2/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ProductManagementService {
  selectedProduct: Product;

  constructor(@Inject(APP_CONFIG) private appConfig: ENV, private http: HttpClient) {}

  getProducts() {
    return this.http.get('product/template/getAll');
  }

  getProductsByCarrierId(carrierId: string) {
    return this.http.get(`product/template/getAllByCarrier/${carrierId}`);
  }

  getProductById(productId: number) {
    return this.http.get(`product/template/${productId}`);
  }

  updateProduct(product: Product) {
    return this.http.put(`product/template/${product.id}`, product);
  }

  updateProducts(products: Product[]) {
    return this.http.put(`product/template/list`, products);
  }

  saveNewProduct(product: Product) {
    return this.http.post('product/template', product);
  }

  deleteDisclosureById(disclosure: Disclosure) {
    return this.http.delete(`product/template/disclosure/${disclosure.id}`);
  }

  getProductTypes() {
    return this.http.get('product/product-types');
  }

  getIsiPlatformProductsByProductType(productType: string) {
    return this.http.get(`product/available-products-form-isi-zone/${productType}`);
  }

  updateSelectedProduct(product: Product) {
    this.selectedProduct = product;
  }
}
