import { FieldType } from './field-type.model';
import { FormBuilderField } from './form-builder-field.model';

export interface FieldTable {
  id?: number;
  name: string;
  columns?: FieldColumn[];
  predefinedTableId?: number;
  numberOfRows: number;
  fields?: FormBuilderField[];
  [key: string]: any;
}

export interface FieldColumn {
  id?: number;
  name: string;
  type: FieldType;
  number: number;
  linkedWith?: FieldColumn;
  [key: string]: any;
}

export interface FieldTablePosition {
  id?: number;
  row: number;
  column: {
    number: number;
    id: number;
    [key: string]: any;
  };
}

export class FieldTableType implements FieldTable {
  id?: number;
  name: string;
  columns?: FieldColumn[];
  numberOfRows: number;
  [key: string]: any;
}
