<div class="details card">
  <div *ngIf="selectedField">
    <div
      [pTooltip]="isFieldLinkedToPredefinedField ? 'Cannot edit a field created from a predefined field' : undefined"
      tooltipPosition="left"
      style="display: initial"
    >
      <button
        pButton
        label="Edit"
        *ngIf="displayConditions?.showEditButton && !isInEditMode"
        [disabled]="isFieldLinkedToPredefinedField"
        (click)="enableEditing()"
        class="mr-2"
      ></button>
    </div>
    <button
      pButton
      label="Save"
      *ngIf="displayConditions?.showEditButton && !displayConditions?.showTableDetails && isInEditMode"
      (click)="saveChanges()"
      class="mr-2"
    ></button>
    <button
      pButton
      label="Save table"
      *ngIf="!displayConditions?.showFieldDetails && displayConditions?.showTableDetails && isInEditMode"
      (click)="saveTableChanges()"
      class="mr-2"
    ></button>
    <button
      pButton
      pRipple
      label="Cancel"
      *ngIf="displayConditions?.showEditButton && isInEditMode"
      (click)="cancelEditing()"
      class="mr-2 p-button-secondary p-button-text"
    ></button>
    <button
      pButton
      label="Delete field"
      *ngIf="
        displayConditions?.showFieldDetails &&
        !displayConditions?.showTableDetails &&
        !displayConditions?.showGroupDetails &&
        (isInEditMode || isFieldLinkedToPredefinedField)
      "
      (click)="confirmFieldDeletion($event)"
      class="mr-2 p-button-danger p-button-text"
    ></button>
    <button
      pButton
      label="Delete group"
      *ngIf="displayConditions?.showGroupDetails && (isInEditMode || isFieldLinkedToPredefinedField)"
      (click)="confirmGroupDeletion($event)"
      class="mr-2 p-button-danger p-button-text"
    ></button>
    <button
      pButton
      label="Delete table"
      *ngIf="displayConditions?.showTableDetails && isInEditMode"
      (click)="confirmTableDeletion($event)"
      class="mr-2 p-button-danger p-button-text"
    ></button>
    <p-confirmPopup></p-confirmPopup>
    <p-divider></p-divider>
  </div>
  <div
    class="mb-4"
    *ngIf="
      (displayConditions?.showFieldDetails ||
        displayConditions?.showTypeDetails ||
        displayConditions?.showGroupDetails ||
        displayConditions?.showTableDetails) &&
      selectedField
    "
  >
    <iapplication2-field-details-field
      *ngIf="displayConditions?.showFieldDetails"
      [isInEditMode]="isInEditMode"
      [field]="selectedField"
      [mainForm]="fieldDetailsForm"
    >
    </iapplication2-field-details-field>
    <iapplication2-field-details-attachment
      [isInEditMode]="isInEditMode"
      *ngIf="displayConditions?.showAttachments"
      [selectedField]="selectedField"
    >
    </iapplication2-field-details-attachment>
    <iapplication2-field-details-type
      *ngIf="displayConditions?.showTypeDetails"
      class="details-type-content-property my-2"
      [isInEditMode]="isInEditMode"
      [selectedField]="selectedGroup ? selectedGroup.fields[0] : selectedField"
    >
    </iapplication2-field-details-type>
    <iapplication2-field-details-group
      *ngIf="displayConditions?.showGroupDetails"
      [isInEditMode]="isInEditMode"
      [fieldGroup]="selectedGroup"
      [mainForm]="fieldDetailsForm"
      [modalType]="modalType"
    >
    </iapplication2-field-details-group>
    <iapplication2-field-details-validator
      *ngIf="displayConditions?.showGroupDetails"
      [isInEditMode]="isInEditMode"
      [item]="selectedGroup"
    ></iapplication2-field-details-validator>
    <iapplication2-field-details-validator
      *ngIf="displayConditions?.showFieldValidators"
      [isInEditMode]="isInEditMode"
      [item]="selectedField"
    ></iapplication2-field-details-validator>
    <iapplication2-field-disclosure-details
      *ngIf="
        selectedGroup?.groupOptions?.type?.type === FormBuilderFieldTypeTypeEnum.DISCLOSURE ||
        selectedField?.fieldType?.type === FormBuilderFieldTypeTypeEnum.DISCLAIMER
      "
      [item]="selectedField ? selectedField : selectedGroup"
      [isInEditMode]="isInEditMode"
    ></iapplication2-field-disclosure-details>
    <iapplication2-field-details-table
      *ngIf="displayConditions?.showTableDetails"
      [isInEditMode]="isInEditMode"
      [isInCreation]="false"
      [table]="selectedTable"
      [mainForm]="fieldDetailsForm"
    ></iapplication2-field-details-table>
    <iapplication2-field-details-static-field-link
      [isInEditMode]="isInEditMode"
      [selectedField]="selectedGroup ? selectedGroup : selectedField"
    ></iapplication2-field-details-static-field-link>
  </div>
  <div *ngIf="displayConditions?.showEmptyState">
    <div class="details-emptyState" fxLayout="row" fxLayoutAlign="end center">
      <h6>Select a field to see its details.</h6>
      <img src="./assets/custom/icons/large-details.svg" />
    </div>
  </div>
</div>
