import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@iapplication2/app-config';
import { ENV } from '@iapplication2/interfaces';
import _ = require('lodash');
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressSpinnerService {
  toggleProgressSpinnerDebounced = _.debounce((show: boolean) => this.toggleProgressSpinner(show), 300);
  toggleGlobalLoadingSpinner = _.debounce((show: boolean) => this.globalLoadingSpinner(show), 300);

  showProgressSpinner: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  showGlobalLoadingSpinner = false;


  constructor(@Inject(APP_CONFIG) private appConfig: ENV) {}

  private toggleProgressSpinner(show: boolean) {
    if (!show) {
      setTimeout(() => {
        this.showProgressSpinner.next(show);
      }, 500);
    } else {
      this.showProgressSpinner.next(show);
    }
  }

  private globalLoadingSpinner(show: boolean) {
    if (!show) {
      setTimeout(() => {
        this.showGlobalLoadingSpinner = show;
      }, 1000);
    } else {
      this.showGlobalLoadingSpinner = show;
    }
  }
}
