<div clsas="mt-4">
  <div class="details-field-header flex justify-content-between">
    <h4 class="details-field-header-title">Table details</h4>
  </div>
  <form [formGroup]="mainForm">
    <div formGroupName="tableForm">
      <div class="details-field-content-property my-2" fxLayout="row">
        <div class="details-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">Table name:</div>
        <div class="details-field-content-property-content" fxLayoutAlign="start center">
          <div *ngIf="!isInEditMode">
            {{ table?.name }}
          </div>
          <div *ngIf="isInEditMode">
            <input
              style="width: 100%"
              type="text"
              class="p-inputtext p-component"
              pInputText
              placeholder="Table name"
              formControlName="tableName"
            />
            <p *ngIf="mainForm.controls.tableForm.get('tableName')?.errors?.duplicateFieldLabel">This name is already in use</p>
          </div>
        </div>
      </div>
      <div class="details-field-content-property my-2" fxLayout="row">
        <div class="details-field-content-property-label pr-3 text-right" fxLayoutAlign="end center">Rows:</div>
        <div class="details-field-content-property-content" fxLayoutAlign="start center">
          <div *ngIf="!isInEditMode || !isInCreation">
            {{ table?.numberOfRows }}
          </div>
          <div *ngIf="isInEditMode && isInCreation">
            <p-inputNumber [maxlength]="2" placeholder="Number of rows" formControlName="numberOfRows"></p-inputNumber>
          </div>
          <div *ngIf="isInEditMode && !isInCreation" fxFlex>
            <i
              class="pi pi-exclamation-circle ml-2"
              pTooltip="The number of rows in a table can only be changed during creation."
              style="color: #a3c4f5; font-size: 0.9rem"
            ></i>
          </div>
        </div>
      </div>
      <div class="table-columns mt-5">
        <div class="table-columns-header" fxLayout="row" fxLayoutAlign="start center">
          <h5>This table contains the following columns</h5>
        </div>
        <div class="table-columns-content">
          <div>
            <div>
              <div class="details-field-content-property my-2" fxLayout="row">
                <div class="details-field-content-property-label pr-3 mt-4 text-right" fxLayoutAlign="end flex-start">Columns:</div>
                <div cdkDropList [id]="table?.id?.toString()" [cdkDropListData]="table?.columns" (cdkDropListDropped)="drop($event)">
                  <div *ngFor="let column of table?.columns">
                    <div
                      class="card p-custom-shadow-3 table-columns-field my-2 px-3 py-2"
                      style="width: 100%"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      cdkDrag
                      [cdkDragDisabled]="!isInEditMode"
                    >
                      <span cdkDragHandle *ngIf="isInEditMode || isInCreation" class="mt-1 mr-3">
                        <img
                          [src]="isDarkTheme ? './assets/custom/icons/drag-icon-light.svg' : './assets/custom/icons/drag-icon.svg'"
                          class="drag-icon"
                        />
                      </span>
                      <div fxLayout="column">
                        <div class="my-2">
                          {{ column.name }}
                        </div>
                      </div>
                      <div fxFlex fxLayoutAlign="end center">
                        <div fxLayout="column" *ngIf="!isInCreation">
                          <div class="fieldType-card mx-3 justify-content-center" fxLayout="row" [title]="column?.type?.label">
                            <div [class]="'fieldType-chip ' + column.type.type">
                              {{ column?.type?.label }}
                            </div>
                          </div>
                        </div>
                        <div>
                          <i
                            *ngIf="column.linkedWith"
                            class="mr-1 pi pi-link"
                            pTooltip="This column is linked with another column"
                            tooltipPosition="bottom"
                          ></i>
                        </div>
                        <div *ngIf="isInEditMode">
                          <button
                            *ngIf="!isInFieldBuilder"
                            pButton
                            pRipple
                            icon="pi pi-cog"
                            (click)="toggleColumnMenu(column, tableColumnsMenu, $event)"
                            class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
                          ></button>
                          <button
                            *ngIf="isInFieldBuilder && isInEditMode"
                            pButton
                            pRipple
                            icon="pi pi-cog"
                            (click)="toggleFieldBuilderColumnMenu(column, predefinedTableColumnsMenu, $event)"
                            class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" class="table-columns-field" fxLayoutAlign="start start" *ngIf="isInCreation">
                    <form [formGroup]="newColumnForm" *ngIf="isInEditMode" fxFlex>
                      <input
                        class="p-inputtext p-component mt-2"
                        pInputText
                        placeholder=""
                        formControlName="name"
                        (input)="newColumnFormInputValueChanged()"
                        type="text"
                        label="Add new column"
                      />
                      <p-dropdown
                        class="column-types ml-3"
                        [options]="columnTypes"
                        optionLabel="name"
                        optionValue="value"
                        formControlName="columnType"
                        placeholder="Column field type"
                        appendTo="body"
                        [style]="{ width: 'auto' }"
                      ></p-dropdown>

                      <div
                        class="group-options mt-5 ml-5"
                        *ngIf="
                          newColumnForm.controls['columnType']?.value === 'radioGroup' ||
                          newColumnForm.controls['columnType']?.value === 'checkboxGroup'
                        "
                      >
                        <div class="group-options-header" fxLayout="row" fxLayoutAlign="start center">
                          <h6>This group contains the following options</h6>
                          <i
                            class="pi pi-exclamation-circle ml-2 mb-3"
                            pTooltip="This group type requires at least 2 options to be added for it to
                              be used."
                            style="color: #a3c4f5; font-size: 0.9rem"
                          ></i>
                        </div>
                        <div class="group-options-content">
                          <div *ngFor="let option of newOptions">
                            <div
                              class="card p-custom-shadow-3 group-options-field my-2 px-3 py-2"
                              fxLayout="row"
                              fxLayoutAlign="space-between center"
                            >
                              <div fxLayout="column">
                                {{ option }}
                              </div>
                            </div>
                          </div>
                          <div fxLayout="column" class="group-options-field" fxLayoutAlign="start start">
                            <form [formGroup]="newOptionForm">
                              <input
                                class="p-inputtext p-component mt-2"
                                pInputText
                                placeholder=""
                                formControlName="label"
                                type="text"
                                label="Add new option"
                              />
                            </form>
                            <button
                              class="p-button p-button-text my-2"
                              (click)="addOption()"
                              [disabled]="!newOptionForm.controls['label'].value"
                            >
                              Add option
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <p class="mt-2" *ngIf="newColumnForm.controls['name'].errors?.duplicateColumnName">
                      <em> This column name is already in use.</em>
                    </p>
                    <button
                      *ngIf="isInEditMode"
                      class="p-button p-button-text my-2"
                      (click)="addColumn()"
                      [disabled]="
                        !formValidity ||
                        !newColumnForm.controls['name'].value ||
                        !newColumnForm.controls['columnType'].value ||
                        newColumnForm.controls['name'].errors ||
                        (newOptions.length === 0 &&
                          newColumnForm.controls['columnType']?.value === FormBuilderFieldTypeTypeEnum.RADIOGROUP) ||
                        (newOptions.length === 0 &&
                          newColumnForm.controls['columnType']?.value === FormBuilderFieldTypeTypeEnum.CHECKBOXGROUP)
                      "
                    >
                      Add column
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<p-menu #tableColumnsMenu [popup]="true" appendTo="body" [model]="tableColumnsItems"></p-menu>
<p-menu #predefinedTableColumnsMenu [popup]="true" appendTo="body" [model]="predefinedTableColumnItems"></p-menu>

<p-dialog
  *ngIf="isColumnLinkingDialogOpen"
  [modal]="true"
  [(visible)]="isColumnLinkingDialogOpen"
  [style]="{ width: '40vw', minWidth: '600px' }"
  showEffect="fade"
>
  <div *ngFor="let column of table?.columns; let i = index">
    <div>
      <div
        *ngIf="selectedColumnForLinking !== column && (column.type.type === 'number' || column.type.type === 'radioGroup')"
        class="card p-custom-shadow-3 my-3 px-4 py-3"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            {{ column.name }}
          </div>
        </div>
        <div *ngIf="columnToLinkWith?.length && columnToLinkWith[0] !== column">
          <p-checkbox class="mr-2" [value]="column" [(ngModel)]="columnToLinkWith" [disabled]="true"></p-checkbox>
        </div>
        <div *ngIf="!columnToLinkWith?.length || columnToLinkWith[0] === column">
          <p-checkbox class="mr-2" [value]="column" [(ngModel)]="columnToLinkWith"></p-checkbox>
        </div>
      </div>
      <div
        *ngIf="selectedColumnForLinking === column"
        class="card slightGrayout p-custom-shadow-3 my-3 px-4 py-3"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        pTooltip="Cannot link column with itself"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            {{ column.name }}
          </div>
        </div>
      </div>
      <div
        *ngIf="!(column.type.type === 'number' || column.type.type === 'radioGroup')"
        class="card slightGrayout p-custom-shadow-3 my-3 px-4 py-3"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        pTooltip="Only columns of type number and radio group can be linked together"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            {{ column.name }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <button pButton pRipple label="Link columns" (click)="setSelectedColumnsForLinking()"></button>
</p-dialog>
