/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FieldsSidebarComponent } from './fields-sidebar/fields-sidebar.component';
import { FieldDetailsComponent } from './field-details/field-details.component';
import { BuilderContainerPageComponent } from './builder-container-page/builder-container-page.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FieldsSidebarCategoryComponent } from './fields-sidebar/fields-sidebar-category/fields-sidebar-category.component';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FormBuilderUiModule } from './../../../ui/src/lib/form-builder/form-builder-ui.module';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { SortablejsModule } from 'ngx-sortablejs';
import { MenuModule } from 'primeng/menu';
import { FormBuilderHeaderComponent } from './form-builder/form-builder-header/form-builder-header.component';
import { TooltipModule } from 'primeng/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InplaceModule } from 'primeng/inplace';
import { DropdownModule } from 'primeng/dropdown';
import { DirectivesModule } from '@iapplication2/directives';
import { FormBuilderFieldDisplayComponent } from './form-builder/form-builder-item-display/form-builder-field-display/form-builder-field-display.component';
import { FormBuilderAreaComponent } from './form-builder/form-builder-area/form-builder-area.component';
import { FormBuilderAreaHeaderComponent } from './form-builder/form-builder-area/form-builder-area-header/form-builder-area-header.component';
import { UnassignedFieldsAreaComponent } from './form-builder/form-builder-area/unassigned-fields-area/unassigned-fields-area.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { MessagesModule } from 'primeng/messages';
import { FormBuilderItemDisplayComponent } from './form-builder/form-builder-item-display/form-builder-item-display.component';
import { FormBuilderGroupDisplayComponent } from './form-builder/form-builder-item-display/form-builder-group-display/form-builder-group-display.component';
import { EditorModule } from 'primeng/editor';
import { FormBuilderStaticTextDisplayComponent } from './form-builder/form-builder-item-display/form-builder-static-text-display/form-builder-static-text-display.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { FieldSettingsModalModule } from './field-settings-modal/field-settings-modal.module';
import { FieldCategoriesModule } from './field-categories/field-categories.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { FormBuilderTableDisplayComponent } from './form-builder/form-builder-item-display/form-builder-table-display/form-builder-table-display.component';
import { PdfDrawingModule } from './pdf-drawing/pdf-drawing.module';
import { FormSectionsManagerComponent } from './form-builder/form-builder-header/form-sections-manager/form-sections-manager.component';
import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { DisplayConditionsModalComponent } from './display-conditions-modal/display-conditions-modal.component';
import { PipesModule } from '@iapplication2/pipes';
import { DisplayConditionValueSelectionComponent } from './display-conditions-modal/display-condition-value-selection/display-condition-value-selection.component';
import { DisplayConditionsCustomDropdownFieldComponent } from './display-conditions-modal/display-conditions-custom-dropdown-field/display-conditions-custom-dropdown-field.component';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    FlexLayoutModule,
    CheckboxModule,
    FormsModule,
    InputTextModule,
    FormBuilderUiModule,
    ButtonModule,
    MenuModule,
    CalendarModule,
    RadioButtonModule,
    DragDropModule,
    DialogModule,
    InplaceModule,
    MessagesModule,
    TooltipModule,
    PdfDrawingModule,
    SortablejsModule.forRoot({ animation: 150 }),
    RouterModule.forChild([
      {
        path: 'form/:formId/page/:pageId/draw',
        component: BuilderContainerPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'form/:formId/page/:pageId/form',
        component: BuilderContainerPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'form/:formId/page/:pageId/preview',
        loadChildren: () => import('@iapplication2/dynamic-form-display').then((m) => m.DynamicFormDisplayModule),
      },
      {
        path: 'fieldBuilder',
        component: FieldBuilderComponent,
        pathMatch: 'full',
      },
    ]),
    SelectButtonModule,
    DividerModule,
    FieldsetModule,
    ReactiveFormsModule,
    DropdownModule,
    OverlayPanelModule,
    EditorModule,
    FieldSettingsModalModule,
    FieldCategoriesModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ToastModule,
    SharedTranslateModule,
    PipesModule,
    ProgressSpinnerModule,
  ],
  declarations: [
    FormBuilderComponent,
    FieldsSidebarComponent,
    FieldDetailsComponent,
    BuilderContainerPageComponent,
    FieldsSidebarCategoryComponent,
    FormBuilderHeaderComponent,
    FormBuilderFieldDisplayComponent,
    FormBuilderAreaComponent,
    FormBuilderAreaHeaderComponent,
    UnassignedFieldsAreaComponent,
    FormBuilderItemDisplayComponent,
    FormBuilderGroupDisplayComponent,
    FormBuilderStaticTextDisplayComponent,
    FieldBuilderComponent,
    FormBuilderTableDisplayComponent,
    FormSectionsManagerComponent,
    DisplayConditionsModalComponent,
    DisplayConditionValueSelectionComponent,
    DisplayConditionsCustomDropdownFieldComponent,
  ],
})
export class InteractiveFormBuilderModule {}
