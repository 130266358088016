<h2>{{ 'pages.globalSearch.title' | translate }}</h2>
<iapplication2-global-search-section-search
  [globalSearchForm]="globalSearchForm"
  [availableEntities]="availableEntities"
></iapplication2-global-search-section-search>
<div class="card mt-4" *ngIf="policies?.length && displayConditions.showPoliciesSection">
  <h4>{{ 'pages.globalSearch.sections.policies.title' | translate }}</h4>
  <iapplication2-result-section-policies
    [loading]="loadingPolicies"
    [policies]="policies"
    [searchedString]="globalSearchForm.controls['searchQuery'].value"
  ></iapplication2-result-section-policies>
</div>
<div class="card mt-4" *ngIf="applications?.length && displayConditions.showApplicationsSection">
  <h4>{{ 'pages.globalSearch.sections.applications.title' | translate }}</h4>
  <iapplication2-result-section-applications
    [loading]="loadingApplications"
    [applications]="applications"
    [searchedString]="globalSearchForm.controls['searchQuery'].value"
  ></iapplication2-result-section-applications>
</div>
<div class="card mt-4" *ngIf="DTCApplications?.length && displayConditions.showDTCApplicationsSection">
  <h4>{{ 'pages.globalSearch.sections.dtcApplications.title' | translate }}</h4>
  <iapplication2-result-section-dtc
    [loading]="loadingDTCApplications"
    [dtcApplications]="DTCApplications"
    [searchedString]="globalSearchForm.controls['searchQuery'].value"
  ></iapplication2-result-section-dtc>
</div>
<div class="card mt-4" *ngIf="quickQuotes?.length && displayConditions.showQuotesSection">
  <h4>{{ 'pages.globalSearch.sections.quickQuotes.title' | translate }}</h4>
  <iapplication2-result-section-quotes
    [loading]="loadingQuickQuotes"
    [quickQuotes]="quickQuotes"
    [searchedString]="globalSearchForm.controls['searchQuery'].value"
  ></iapplication2-result-section-quotes>
</div>
<div class="card mt-4" *ngIf="clients?.length && displayConditions.showClientsSection">
  <h4>{{ 'pages.globalSearch.sections.clients.title' | translate }}</h4>
  <iapplication2-result-section-clients
    [loading]="loading"
    [clients]="clients"
    [searchedString]="globalSearchForm.controls['searchQuery'].value"
  ></iapplication2-result-section-clients>
</div>
<div
  *ngIf="!policies?.length && !applications?.length && !DTCApplications?.length && !clients?.length && !quickQuotes?.length"
  class="mt-5 empty-state"
>
  <div class="empty-state-text"><strong>{{ 'pages.globalSearch.noResults' | translate }}</strong></div>
  <img class="empty-state-icon" src="/assets/custom/icons/global-searcg-empty-state.svg" />
</div>
