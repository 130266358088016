import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListDisplayComponent } from './product-list-display.component';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { SharedTranslateModule } from '@iapplication2/shared-translate';

@NgModule({
  declarations: [ProductListDisplayComponent],
    imports: [CommonModule, TableModule, TooltipModule, SharedTranslateModule],
  exports: [ProductListDisplayComponent],
})
export class ProductListDisplayModule {}
