export interface PaymentPayload {
  cc_number: string;
  cc_cvv: string;
  cc_exp_month: string;
  cc_exp_year: string;
  client_postal_code: string;
  client_address: string;
  client_first_name: string;
  client_last_name: string;
}

export enum PaymentMethods {
  PAD = 'pad',
  CREDIT_CARD = 'creditCard',
}

export enum PaymentFrequencyEnum {
  MONTHLY = 'monthly',
  ANNUALY = 'annually',
}
