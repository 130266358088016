<div #fcContainer [style]="{ minHeight: '800px', width: '100%' }">
  <full-calendar [options]="calendarOptions"></full-calendar>
</div>

<p-button
  [disabled]="!this.selectedDate"
  icon="pi pi-check"
  [label]="'applicationProcess.step.summary.dates.scheduleDate.button' | translate"
  (click)="setScheduledDate()"
></p-button>
