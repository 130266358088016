import { FormGroup } from '@angular/forms';

export class FormHelper {
  fillFormWithObjectValues<T>(form: FormGroup, object: T): FormGroup {
    const objectKeys: string[] = Object.keys(object);
    objectKeys.forEach((key) => this.assignObjectValueToFormValue(form, object, key));
    return form;
  }

  extractObjectFromFormValues<T>(form: FormGroup, object: T): T {
    const objectKeys: string[] = Object.keys(object);
    objectKeys.forEach((key) => this.assignFormValueToObjectValue(form, object, key));
    return object;
  }

  createObjectFromFormValues<T>(form: FormGroup, object: T): T {
    object = {} as T;
    const formKeys: string[] = Object.keys(form.controls);
    formKeys.forEach((key) => this.assignFormValueToObjectValue(form, object, key));
    return object;
  }

  fillFormWithObjectSelectedValues<T>(form: FormGroup, object: T): FormGroup {
    const objectKeys: string[] = Object.keys(object);
    objectKeys.forEach((key) => this.assignObjectSelectedValuesToFormValue(form, object, key));
    return form;
  }

  extractSelectedObjectValuesFromFormValues<T>(form: FormGroup, object: T): T {
    const objectKeys: string[] = Object.keys(object);
    objectKeys.forEach((key) => this.assignFormValueToObjectSelectedValues(form, object, key));
    return object;
  }

  private assignObjectValueToFormValue(form: FormGroup, object: unknown, key: string) {
    if (form.controls[key]) {
      form.controls[key].setValue(object[key]);
    }
  }

  private assignFormValueToObjectValue(form: FormGroup, object: unknown, key: string) {
    if (form.controls[key]) {
      object[key] = form.controls[key].value;
    }
  }

  private assignObjectSelectedValuesToFormValue(form: FormGroup, object: unknown, key: string) {
    if (form.controls[key]) {
      form.controls[key].setValue(object[key].selectedValue);
    }
  }

  private assignFormValueToObjectSelectedValues(form: FormGroup, object: unknown, key: string) {
    if (form.controls[key]) {
      object[key].selectedValue = form.controls[key].value;
    }
  }
}
