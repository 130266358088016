export interface WeightUnit {
  name: string;
  abreviation: string;
}

export interface HeightUnit {
  name: string;
  abreviation: string;
}

export interface DateFormat {
  format: string;
}
