import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { register } from '../../../../registryComponent';
import { BaseFieldDataDirective } from '../../../../baseFieldData.directive';
import SignaturePad from 'signature_pad';

@register('signature')
@Component({
  selector: 'iapplication2-signature-field-display',
  templateUrl: './signature-field-display.component.html',
  styleUrls: ['./signature-field-display.component.scss'],
})
export class SignatureFieldDisplayComponent extends BaseFieldDataDirective implements AfterViewInit {
  @ViewChild('sPad') signaturePadElement: ElementRef;
  signaturePad;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    //We are using this library: https://github.com/szimek/signature_pad
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  clearSignature() {
    this.signaturePad.clear();
  }
}
