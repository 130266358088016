import { FormBuilderFieldTypeType } from './form-builder-field-type-option.model';

export interface FormBuilderFieldTypeEntries {
  predefined?: FormBuilderFieldTypeEntriesPredefined;
  entries?: FormBuilderFieldTypeEntry[];
}

export interface FormBuilderFieldTypeEntriesPredefined {
  id?: number;
  label: string;
  info: string;
  type?: FormBuilderFieldTypeType;
  entries: FormBuilderFieldTypeEntry[];
}

export interface FormBuilderFieldTypeEntry {
  id?: number;
  value: string;
}
