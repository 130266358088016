/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '@iapplication2/shared-translate';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { GlobalSearchPageComponent } from './global-search-page/global-search-page.component';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { GlobalSearchSectionSearchComponent } from './global-search-section-search/global-search-section-search.component';
import { ResultSectionsModule } from '../../../result-sections/src/lib/result-sections.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HighlightModule } from '../../../ui/src/lib/highlight/highlight.module';

@NgModule({
  imports: [
    InputTextModule,
    MultiSelectModule,
    FormsModule,
    CommonModule,
    TableModule,
    MenuModule,
    ButtonModule,
    ResultSectionsModule,
    TagModule,
    ReactiveFormsModule,
    HighlightModule,
    TranslateModule.forChild({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RouterModule.forChild([
      {
        path: '',
        component: GlobalSearchPageComponent,
      },
    ]),
  ],
  declarations: [GlobalSearchPageComponent, GlobalSearchSectionSearchComponent],
})
export class GlobalSearchModule {}
