import { Directive, HostListener, Input } from '@angular/core';
import * as _ from 'lodash';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[iapplication2TitleFormat]'
})
export class TitleFormatDirective {
  private control: AbstractControl;

  @Input() set iapplication2TitleFormat(control: AbstractControl) {
    this.control = control;
  }

  @HostListener('input', ['$event'])
  onInputEvent() {
    this.capitalizeText();
  }

  capitalizeText() {
    const value: string = this.control?.value;

    const words: string[] = value.split(' ');
    const result: string[] = [];

    words.forEach((word) => {
      result.push(_.capitalize(word));
    });

    this.control?.setValue(result.join(' '));
  }
}
