<div *ngIf="!codeSent; then codeNotSentContent; else codeSentContent"></div>
<ng-template #codeSentContent>
  <div class="flex flex-column">
    <span class="my-2"> Enter the code received via the chosen validation method: </span>
    <form [formGroup]="phoneVerificationCodeForm" (submit)="requestPhoneNumberChange()">
      <div class="p-field flex flex-column col-12 mb-2 lg:col-8 lg:mb-0 pl-0 pb-4">
        <label for="valicationCode">Current password</label>
        <input
          id="validationCode"
          type="text"
          class="p-inputtext p-component"
          pInputText
          placeholder="Validation code"
          formControlName="validationCode"
        />
      </div>
      <button pButton label="Change phone number" type="submit"></button>
    </form>
  </div>
</ng-template>
<ng-template #codeNotSentContent>
  <div class="flex flex-column">
    <span class="my-2"> Enter your new phone number and choose your prefered validation method. </span>
    <form [formGroup]="phoneVerificationForm" (submit)="requestValidationCode()">
      <div class="p-field flex flex-column col-12 mb-2 lg:col-8 lg:mb-0 pl-0">
        <label for="password">Current password</label>
        <input
          id="password"
          type="password"
          class="p-inputtext p-component"
          pInputText
          placeholder="Current password"
          formControlName="currentPassword"
        />
      </div>
      <div class="p-field flex flex-column col-12 mb-2 lg:col-8 lg:mb-0 pl-0">
        <label for="newPhoneNumber">New phone number</label>
        <input
          id="newPhoneNumber"
          type="tel"
          class="p-inputtext p-component"
          pInputText
          placeholder="New phone number"
          formControlName="newPhoneNumber"
        />
      </div>
      <div class="p-field flex flex-column">
        <span class="py-2">Select validation method</span>
        <div class="p-field flex flex-row align-items-center mb-1">
          <p-radioButton class="py-1 pr-2" name="phoneValidation" value="sms" formControlName="phoneValidation" inputId="SMSPhoneValidator">
          </p-radioButton>
          <label for="SMSPhoneValidator" class="mb-0"> SMS </label>
        </div>
        <div class="p-field flex flex-row align-items-center mb-1">
          <p-radioButton
            class="py-1 pr-2"
            name="phoneValidation"
            value="voice"
            formControlName="phoneValidation"
            inputId="voicePhoneValidator"
          ></p-radioButton>
          <label for="voicePhoneValidator" class="mb-0"> Voice </label>
        </div>
      </div>
      <button pButton label="Request validation code" type="submit"></button>
    </form>
  </div>
</ng-template>
