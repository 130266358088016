<div class="topbar" fxLayoutAlign="start center" fxLayout="row">
  <div fxFlex fxLayoutAlign="start center" fxLayout="row" class="mr-4" style="min-width: max-content">
    <span>Current form builder mode:</span>
    <p-selectButton
      [options]="pageViewStateOptions"
      [(ngModel)]="pageViewState"
      optionLabel="label"
      optionValue="value"
      class="ml-2"
      (onChange)="chagnedSelectButtonValue()"
    >
    </p-selectButton>
  </div>
  <div class="p-3" fxLayout="row" fxLayoutAlign="start center" *ngIf="pagesOfThisForm && selectedPage">
    <p class="mb-0 mr-2">Select a page to switch the content</p>
    <p-dropdown
      [options]="pagesOfThisForm"
      optionLabel="number"
      [(ngModel)]="selectedPage"
      placeholder="Pick a page"
      (ngModelChange)="changedPage()"
    >
      <ng-template pTemplate="selectedItem"> Page {{ selectedPage?.number }} </ng-template>
      <ng-template let-page pTemplate="item"> Page {{ page?.number }} </ng-template>
    </p-dropdown>
  </div>
  <div *ngIf="userDrawing" fxFlex fxLayoutAlign="start center" fxLayout="row" class="currentlyDrawingInformation mr-4">
    <span class="mr-2"> Currently drawing a </span>
    <div
      class="fieldDetails-content-property-content fieldType-chip mr-2"
      [ngClass]="(selectedFieldForDrawing | cast: FormBuilderFieldType)?.fieldType?.type"
    >
      {{ (selectedFieldForDrawing | cast: FormBuilderFieldType)?.fieldType?.label }}
    </div>
    <span class="mr-1"> field with label "{{ (selectedFieldForDrawing | cast: FormBuilderFieldType)?.options?.customFieldLabel }}" </span>
    <span *ngIf="(selectedFieldForDrawing | cast: FormBuilderFieldType)?.groupOptions?.id">
      from group "{{ (selectedFieldForDrawing | cast: FormBuilderFieldType)?.groupOptions?.name }}"
    </span>
    <span *ngIf="(selectedFieldForDrawing | cast: FormBuilderFieldType)?.table">
      from table "{{ (selectedFieldForDrawing | cast: FormBuilderFieldType).table?.type.name }}"
    </span>
  </div>
</div>
