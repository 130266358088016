<div fxFlex fxLayout="row" fxLayoutAlign="start center" *ngIf="currentAdvisor">
  <iapplication2-advisor-card [advisor]="currentAdvisor"></iapplication2-advisor-card>
  <div class="mr-1" fxLayout="column" fxLayoutAlign="center start">
    <p class="pl-3 mb-0">Responsible advisor</p>
    <button pButton pRipple type="button" label="Change advisor" class="p-button-text" (click)="op.toggle($event)"></button>
  </div>
</div>
<p-overlayPanel #op (onShow)="onChangeAdvisorOverlayShow()" fxLayout="column">
  <p class="mb-2">Search for an advisor below</p>
  <ng-template pTemplate>
    <p-autoComplete
      [(ngModel)]="selectedAdvisor"
      [suggestions]="filteredAdvisorsList"
      (completeMethod)="filterAdvisors($event)"
      field="firstName"
      [dropdown]="true"
      (onClear)="clearedInput()"
    >
      <ng-template let-advisorDropdown pTemplate="item">
        <iapplication2-advisor-card [advisor]="advisorDropdown"></iapplication2-advisor-card>
      </ng-template>
    </p-autoComplete>
    <div class="mt-3">
      <iapplication2-advisor-card *ngIf="selectedAdvisor?.firstName" [advisor]="selectedAdvisor"></iapplication2-advisor-card>
    </div>
    <div class="mt-2">
      <button pButton pRipple label="Cancel" class="p-button-secondary p-button-text mr-1" (click)="cancelOverlay()"></button>
      <p-button
        label="Continue with this advisor"
        [disabled]="!selectedAdvisor?.firstName || selectedAdvisor?.id === currentAdvisor.id"
        (onClick)="changeAdvisor()"
      ></p-button>
    </div>
  </ng-template>
</p-overlayPanel>
