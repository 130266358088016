<div *ngFor="let product of selectedProducts">
  <p-card *ngIf="product.questionnaireSections && product.coverage > product.maxCoverageNoQuestionnaire" fxFlex class="pt-3 pr-3">
    <iapplication2-product-questionnaire
      [changeTriggered]="changeTriggered"
      [product]="product"
      [productQuestionnaireForm]="dynamicQuestionnaireForm | cast: FormGroupType"
      (medicalQuestionnaireChanged)="onMedicalQuestionnaireChanged()"
      [formDataObject]="formDataObject"
      [applicationId]="applicationId"
      [listOfItems]="listOfItems"
    ></iapplication2-product-questionnaire>
  </p-card>
</div>
