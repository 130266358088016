<div class="card" style="height: 100%" [fxFlex]="area.width === 'full' ? '100%' : '50%'">
  <h6>{{ (area?.lokaliseKey ? area?.lokaliseKey : 'missing_key') | translate: { Default: area?.title } }}</h6>
  <div *ngFor="let item of itemsInArea; let i = index">
    <iapplication2-form-display-item
      [formItem]="item.item"
      [formFieldControl]="item.control"
      [parentFormGroup]="areaForm"
      [isPreviewMode]="isPreviewMode"
      [showStaticText]="showStaticText"
    ></iapplication2-form-display-item>
  </div>
</div>
