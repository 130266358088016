import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfPreviewComponent } from './pdf-preview.component';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedTranslateModule } from '@iapplication2/shared-translate';

@NgModule({
  declarations: [PdfPreviewComponent],
    imports: [CommonModule, ButtonModule, CalendarModule, PaginatorModule, FlexLayoutModule, SharedTranslateModule],
  exports: [PdfPreviewComponent],
})
export class PdfPreviewModule {}
