import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Language, Client, Country, Province, TableCol, TablesList, LanguagesEnum } from '@iapplication2/interfaces';
import { Table } from 'primeng/table';
import { ClientManagementService, DatesService, StrictTypingService, UtilsService } from '@iapplication2/services';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { UserSettings } from '@iapplication2/interfaces';
import { UserSettingsService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iapplication2-client-management-list',
  templateUrl: './client-management-list.component.html',
  styleUrls: ['./client-management-list.component.scss'],
})
export class ClientManagementListComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: Table;
  userSettings: UserSettings;
  languages: Language[];
  countries: Country[];
  provinces: Province[];
  clients: Client[];
  scrollableCols: TableCol[];
  selectedCols: TableCol[];
  totalRecords: number;
  totalPages = [];
  loading = true;
  matchModeOptions: SelectItem[];
  page = 0;
  rows = 10;
  previousTableEvent: any;
  rowsPerPage: string[] = ['10', '20', '50', '100'];
  first: number;

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private clientManagementService: ClientManagementService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userSettingsService: UserSettingsService,
    private datesService: DatesService,
    public strictTypingService: StrictTypingService
  ) {}

  ngOnInit(): void {
    this.utilsService
      .getLanguages()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.languages = res as Language[];
        }
      });
    this.getCountries();
    this.getProvinces();

    this.scrollableCols = [
      {
        field: 'firstName',
        header: '',
        width: 200,
      },
      {
        field: 'lastName',
        header: '',
        width: 250,
      },
      {
        field: 'middleName',
        header: '',
        width: 250,
      },
      {
        field: 'language',
        header: '',
        width: 175,
      },
      {
        field: 'email',
        header: '',
        width: 350,
      },
      {
        field: 'birthDate',
        header: '',
        width: 175,
      },
      {
        field: 'age',
        header: '',
        width: 150,
      },
      {
        field: 'smokerStatus',
        header: '',
        width: 200,
      },
      {
        field: 'phoneNumber',
        header: '',
        width: 200,
      },
      {
        field: 'address',
        header: '',
        width: 250,
      },
      {
        field: 'countryOfBirth',
        header: '',
        width: 200,
      },
      {
        field: 'province',
        header: '',
        width: 175,
      },
      {
        field: 'city',
        header: '',
        width: 200,
      },
      {
        field: 'postalCode',
        header: '',
        width: 175,
      },
    ];

    this.getTranslationsForScrollableCols();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForScrollableCols();
    });

    this.selectedCols = this.scrollableCols;
    this.getUserTableColumns();
    this.setOptionsOnLangChange();
  }

  setOptionsOnLangChange() {
    this.translateService.onLangChange.pipe().subscribe(() => {
      this.getProvinces();
      this.getCountries();
    });
  }

  getProvinces() {
    this.utilsService
      .getProvincesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.provinces = res?.map((province, index) => {
            const name = this.getNameKeyBySelectedLanguage();
            return {
              id: index + 1,
              name: province[name],
              abbreviation: province.prov_short,
            };
          });
        },
      });
  }

  getNameKeyBySelectedLanguage(): string {
    let name: string;
    switch (this.translateService.currentLang) {
      case LanguagesEnum.EN:
        name = 'name';
        break;
      default:
        name = `name_${this.translateService.currentLang}`;
    }
    return name;
  }

  getCountries() {
    this.utilsService
      .getCountriesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.countries = res?.data
            ?.map((country) => {
              const name = this.getNameKeyBySelectedLanguage();
              return {
                id: country.id,
                name: country[name],
                code: country.code,
              };
            })
            .sort((country1, country2) => {
              return country1.name.localeCompare(country2.name);
            });
        },
      });
  }

  navigateToEdit(client: Client) {
    this.router.navigate(['client/' + client.id], {
      relativeTo: this.route,
    });
  }

  getTranslationsForScrollableCols() {
    this.translateService
      .get('pages.clients.table.columns')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.scrollableCols[0].header = res.firstName;
        this.scrollableCols[1].header = res.lastName;
        this.scrollableCols[2].header = res.middleName;
        this.scrollableCols[3].header = res.language;
        this.scrollableCols[4].header = res.email;
        this.scrollableCols[5].header = res.birthDate;
        this.scrollableCols[6].header = res.age;
        this.scrollableCols[7].header = res.smokerStatus;
        this.scrollableCols[8].header = res.phoneNumber;
        this.scrollableCols[9].header = res.address;
        this.scrollableCols[10].header = res.countryOfBirth;
        this.scrollableCols[11].header = res.province;
        this.scrollableCols[12].header = res.city;
        this.scrollableCols[13].header = res.postalCode;
      });
  }

  onRowEditInit(client: Client) {
    this.cancelOtherRowEdit();

    this.router.navigate(['client', client.id], {
      relativeTo: this.route,
    });
  }

  onRowDelete(client: Client) {
    console.log(client);
  }

  cancelOtherRowEdit() {
    // This method is needed as this is a known limitation of primeNg: https://github.com/primefaces/primeng/issues/5352
    const cancelEditButtonElements = document.querySelectorAll('button[pCancelEditableRow]')[0];
    if (cancelEditButtonElements instanceof HTMLElement) {
      cancelEditButtonElements.click();
    }
  }

  filterGlobal(value: string) {
    console.log(value);
    this.loadClients(null, value);
  }

  rowsChange(event) {
    if (event.value) {
      this.loadClients(this.previousTableEvent, '', event.value);
    }
  }

  setCurrentPage(event) {
    if (event.value) {
      const first = event.value * Number(this.table.rows) - Number(this.table.rows);
      this.loadClients(undefined, undefined, undefined, Math.round(first));
    }
  }

  loadClients(event?: LazyLoadEvent, globalSearchValue?: string, rowsNumber?: string, firstItemOnPage?: number) {
    let rows: string;
    if (event) {
      this.previousTableEvent = event;
    }

    if (rowsNumber) {
      rows = rowsNumber;
      this.table.rows = Number(rowsNumber);
      this.previousTableEvent = { ...this.previousTableEvent, rows: rowsNumber };
    } else {
      rows = this.previousTableEvent?.rows?.toString();
    }

    if (firstItemOnPage !== undefined) {
      this.table.first = firstItemOnPage;
      this.table.firstChange.emit(this.table.first);
      this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
      this.previousTableEvent = { ...this.previousTableEvent, first: firstItemOnPage };
    }

    this.page = Math.round(this.previousTableEvent.first / this.previousTableEvent.rows + 1);
    const sortField: string = this.previousTableEvent.sortField;
    const sortOrder: string = this.previousTableEvent.sortOrder === -1 ? 'ASC' : 'DESC';
    this.loading = true;

    this.clientManagementService
      .getAllClientsFiltered(this.page, rows, sortField, sortOrder, globalSearchValue)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.clients = res.data;
        this.totalRecords = res.count;
        this.loading = false;
        this.getPages();
      });
  }

  getPages() {
    const pagesTotal = Math.ceil(this.totalRecords / this.previousTableEvent.rows + 1);
    this.totalPages = [];
    if (pagesTotal) {
      const pages = [...Array(pagesTotal).keys()];
      pages.forEach((page) => {
        this.totalPages.push({
          value: page,
        });
      });
    }
  }

  getAge(date: string): string {
    return this.datesService.calculateAge(date).toString();
  }

  getUserTableColumns() {
    this.userSettingsService
      .getUserSeetingsByUserId(1234)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          console.log(res[0]);
          this.userSettings = res[0];
          const tableColsForUser = this.userSettings.selectedTableColumns.filter((table) => table.name === TablesList.CLIENTS)[0];
          if (tableColsForUser) {
            this.selectedCols = tableColsForUser.columns;
          }
        }
      });
  }

  selectedColsChanged() {
    this.updateUserSettingsWithSelectedTableCols();
  }

  updateUserSettingsWithSelectedTableCols() {
    const tableFromUserSettings = this.userSettings.selectedTableColumns.filter(
      (selectedTable) => selectedTable.name === TablesList.CLIENTS
    )[0];
    if (tableFromUserSettings) {
      tableFromUserSettings.columns = this.selectedCols;
    } else {
      this.userSettings.selectedTableColumns.push({
        name: 'clients',
        columns: this.selectedCols,
      });
    }
    this.userSettingsService.updateUserSettingsByUserId(this.userSettings);
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
