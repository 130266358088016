import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, DarkThemeService } from '@iapplication2/services';
import { Themes } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  formSubmissionTriggered = false;
  isLightTheme: boolean;
  isPasswordResetSuccessfull: boolean;
  errorMessage: string

  constructor(
    private authService: AuthService,
    private darkThemeService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.createForgotPasswordForm();
    this.isLightTheme = JSON.parse(localStorage.getItem('isLightTheme'));
  }

  public getLogoPathForCurrentLightMode() {
    this.darkThemeService.themeChanged.subscribe((theme) => {
      this.isLightTheme = theme === Themes.LIGHT;
    });
    return this.isLightTheme ? '/assets/layout/images/logo-full.svg' : '/assets/layout/images/logo-full-black.svg';
  }

  createForgotPasswordForm() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      newPassword: new FormControl(null, [Validators.required]),
      newPasswordConfirm: new FormControl(null, [Validators.required])
    });
  }

  submitForgotPassowrdForm() {
    this.formSubmissionTriggered = true;
    this.authService.resetPassword(this.email.value, this.newPassword.value, this.newPasswordConfirm.value)
      .subscribe((res: any) => {
      if (res) {
        this.isPasswordResetSuccessfull = true;
      }
    }, error => {
        this.errorMessage = error.error.message[0];
        this.isPasswordResetSuccessfull = false;
      });
  }

  get email() {
    return this.forgotPasswordForm?.get('email');
  }

  get newPassword() {
    return this.forgotPasswordForm?.get('newPassword');
  }

  get newPasswordConfirm() {
    return this.forgotPasswordForm?.get('newPasswordConfirm');
  }
}
