import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[iapplication2OnlyNumbers]',
})
export class OnlyNumbersDirective {
  regexStr = '^[0-9]*$';
  @Input() iapplication2OnlyNumbers: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const keyboardEvent = <KeyboardEvent>event;
    if (this.iapplication2OnlyNumbers) {
      if (
        ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].indexOf(keyboardEvent.code) !== -1 ||
        // Allow: Ctrl+A
        (keyboardEvent.code == 'KeyA' && (keyboardEvent.ctrlKey || keyboardEvent.metaKey) === true) ||
        // Allow: Ctrl+C
        (keyboardEvent.code == 'KeyC' && (keyboardEvent.ctrlKey || keyboardEvent.metaKey) === true) ||
        // Allow: Ctrl+V
        (keyboardEvent.code == 'KeyV' && (keyboardEvent.ctrlKey || keyboardEvent.metaKey) === true) ||
        // Allow: Ctrl+X
        (keyboardEvent.code == 'KeyX' && (keyboardEvent.ctrlKey || keyboardEvent.metaKey) === true) ||
        // Allow: home, end, left, right
        ['End', 'Home', 'ArrowLeft', 'ArrowRight'].indexOf(keyboardEvent.code) !== -1
      ) {
        // let it happen, don't do anything
        return;
      }
      const regEx = new RegExp(this.regexStr);
      if (regEx.test(keyboardEvent.key)) return;
      else keyboardEvent.preventDefault();
    }
  }
}
