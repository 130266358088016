import { LanguagesEnum } from './language.model';
import { Page } from './page.model';
import { PredefinedFormBuilderWizardSection } from './predefined-form-builder-wizard-section.model';

export interface FormListDisplay {
  id: number;
  name: string;
  numberOfPages: number;
  pages?: Page[];
  language?: LanguagesEnum;
}

export interface FormPayload {
  id?: number;
  carrierId?: number;
  name: string;
  language?: LanguagesEnum;
  pages?: Page[];
  formSections?: PredefinedFormBuilderWizardSection[];
}
