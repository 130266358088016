import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldDisclosureDetails, FieldTypeEnum, ValidatorTypeEnum } from '@iapplication2/interfaces';
import { ApplicationsProcessService, DisclosuresService } from '@iapplication2/services';
import { BaseFieldDataDirective } from '../../../../baseFieldData.directive';
import { register } from '../../../../registryComponent';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@register('disclaimer')
@Component({
  selector: 'iapplication2-disclaimer-field-display',
  templateUrl: './disclaimer-field-display.component.html',
  styleUrls: ['./disclaimer-field-display.component.scss'],
})
export class DisclaimerFieldDisplayComponent extends BaseFieldDataDirective implements OnInit {
  disclosureDetails: FieldDisclosureDetails;
  isDisclaimerPlaying = false;
  isViewApplication: boolean;
  unsubscribe: Subject<unknown> = new Subject();

  FieldTypeEnum = FieldTypeEnum;

  constructor(public disclosuresService: DisclosuresService, private applicationsProcessService: ApplicationsProcessService) {
    super();
  }

  ngOnInit(): void {
    this.checkViewApplicationDisplay();
    this.disclosureDetails = this.field.fieldDisclosureOption;
    if (this.field.fieldValidators.predefinedValidators[0]?.validatorKey === ValidatorTypeEnum.REQUIRED) {
      this.formFieldControl.setValidators([Validators.required]);
    }
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.isViewApplication = value;
    });
  }

  toggleDisclaimerPlaying() {
    if (!this.isDisclaimerPlaying) {
      this.disclosuresService.playDisclosure(this.disclosureDetails.mp3FileName).subscribe({
        next: () => {
          this.isDisclaimerPlaying = !this.isDisclaimerPlaying;
        },
      });
    } else {
      this.disclosuresService.stopDisclosure(this.disclosureDetails.mp3FileName).subscribe({
        next: () => {
          this.isDisclaimerPlaying = !this.isDisclaimerPlaying;
          const dateWhenDisclosureWasPlayed = new Date().toLocaleTimeString();
          this.formFieldControl.setValue(`played at ${dateWhenDisclosureWasPlayed}`);
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
