<div fxFlex fxLayout="column">
  <div class="flex flex-row">
    <div fxFlex fxLayout="column" *ngIf="group.fields.length">
      <div *ngIf="!group.fields[0].table" fxFlex fxLayoutAlign="row">
        <iapplication2-required *ngIf="!isViewApplication" [required]="formFieldControl.errors?.required && !group.fields?.[0]?.table"></iapplication2-required>
        <div>
          {{ group.groupOptions.name }}
        </div>
      </div>
      <div [class.checkbox-group-row-options]="flexRow">
        <div
          class="mb-2 mt-2 checkbox-group-option"
          *ngFor="let field of group.fields"
          [formGroup]="parentFormGroup"
          [class.displayReadonlyValue]="isReadonly"
        >
          <p-checkbox
            [value]="field.id"
            class="mr-1"
            [formControl]="formControl"
            [class.isReadOnlyRadioButton]="isReadonly"
            [tabindex]="tabIndex"
          ></p-checkbox>

          {{
            (field?.options?.lokaliseKey ? field?.options?.lokaliseKey : 'missing_key')
              | translate: { Default: field?.options?.customFieldLabel }
          }}
        </div>
      </div>
    </div>
    <iapplication2-required *ngIf="!isViewApplication" class="flex" style="min-width: 20px" [rowRequired]="group?.requiredByTableRow"></iapplication2-required>
  </div>
  <iapplication2-form-field-error
    *ngIf="formControl?.invalid && formControl.touched && formControl.dirty && !isViewApplication"
    [control]="formControl"
  ></iapplication2-form-field-error>
</div>
