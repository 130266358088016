/* eslint-disable max-len */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormContainerPageComponent } from './form-container-page/form-container-page.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormDisplayFieldComponent } from './form-container-page/form-display-field/form-display-field.component';
import { FormDisplayGroupComponent } from './form-container-page/form-display-group/form-display-group.component';
import { FormDisplayTextComponent } from './form-container-page/form-display-text/form-display-text.component';
import { FormDisplayItemComponent } from './form-container-page/form-display-item/form-display-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FormDisplayAreaComponent } from './form-container-page/form-display-area/form-display-area.component';
import { FreetextFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/freetext-field-display/freetext-field-display.component';
import { NumberFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/number-field-display/number-field-display.component';
import { CheckboxFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/checkbox-field-display/checkbox-field-display.component';
import { DropdownFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/dropdown-field-display/dropdown-field-display.component';
import { DateFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/date-field-display/date-field-display.component';
import { SignatureFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/signature-field-display/signature-field-display.component';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { PipesModule } from '@iapplication2/pipes';
import { RadioGroupDisplayComponent } from './form-container-page/form-display-group/form-display-group-types/radio-group-display/radio-group-display.component';
import { CheckboxGroupDisplayComponent } from './form-container-page/form-display-group/form-display-group-types/checkbox-group-display/checkbox-group-display.component';
import { FormDisplayTableComponent } from './form-container-page/form-display-table/form-display-table.component';
import { RequiredModule } from '@iapplication2/interface-design';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ImageFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/image-field-display/image-field-display.component';
import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { FormFieldErrorModule } from '@iapplication2/ui';
import { PdfFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/pdf-field-display/pdf-field-display.component';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { DateMaskModule } from '@iapplication2/ui';
import { DisclosureGroupDisplayComponent } from './form-container-page/form-display-group/form-display-group-types/disclosure-group-display/disclosure-group-display.component';
import { SeeMoreModule } from '@iapplication2/ui';
import { DirectivesModule } from '@iapplication2/directives';
import { DisclaimerFieldDisplayComponent } from './form-container-page/form-display-field/form-display-field-types/disclaimer-field-display/disclaimer-field-display.component';

@NgModule({
  imports: [
    FlexLayoutModule,
    DropdownModule,
    TooltipModule,
    CheckboxModule,
    PipesModule,
    InputTextModule,
    InputNumberModule,
    TableModule,
    RadioButtonModule,
    CalendarModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RequiredModule,
    SelectButtonModule,
    FormFieldErrorModule,
    SharedTranslateModule,
    InputTextareaModule,
    InputMaskModule,
    FileUploadModule,
    DateMaskModule,
    SeeMoreModule,
    DirectivesModule,
    RouterModule.forChild([
      {
        path: 'form/:formId',
        component: FormContainerPageComponent,
      },
      {
        path: 'form/:formId/section/:sectionId',
        component: FormContainerPageComponent,
      },
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ]),
  ],
  declarations: [
    FormContainerPageComponent,
    FormDisplayFieldComponent,
    FormDisplayGroupComponent,
    FormDisplayTextComponent,
    FormDisplayItemComponent,
    FormDisplayAreaComponent,
    FreetextFieldDisplayComponent,
    NumberFieldDisplayComponent,
    PdfFieldDisplayComponent,
    CheckboxFieldDisplayComponent,
    DropdownFieldDisplayComponent,
    DateFieldDisplayComponent,
    SignatureFieldDisplayComponent,
    RadioGroupDisplayComponent,
    CheckboxGroupDisplayComponent,
    FormDisplayTableComponent,
    ImageFieldDisplayComponent,
    DisclosureGroupDisplayComponent,
    DisclaimerFieldDisplayComponent,
  ],
  exports: [
    FormContainerPageComponent,
    FormDisplayFieldComponent,
    FormDisplayGroupComponent,
    FormDisplayTextComponent,
    FormDisplayItemComponent,
    FormDisplayAreaComponent,
    FreetextFieldDisplayComponent,
    NumberFieldDisplayComponent,
    PdfFieldDisplayComponent,
    CheckboxFieldDisplayComponent,
    DropdownFieldDisplayComponent,
    DateFieldDisplayComponent,
    SignatureFieldDisplayComponent,
    RadioGroupDisplayComponent,
    CheckboxGroupDisplayComponent,
    DisclosureGroupDisplayComponent,
    DisclaimerFieldDisplayComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [TitleCasePipe],
})
export class DynamicFormDisplayModule {}
