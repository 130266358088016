<div class="col-12 px-0">
  <div class="card ClientManagement-table">
    <h5>{{ 'pages.clients.table.title' | translate }}</h5>
    <p-table
      #dt
      [columns]="selectedCols"
      frozenWidth="75px"
      [value]="clients"
      dataKey="id"
      styleClass="p-datatable-customers"
      [rowHover]="true"
      (onLazyLoad)="loadClients($event)"
      [lazy]="true"
      [loading]="loading"
      [paginator]="true"
      [totalRecords]="totalRecords"
      [rows]="rows"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [currentPageReportTemplate]="'pages.clients.table.entries' | translate: { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' }"
      [globalFilterFields]="[
        'firstName',
        'lastName',
        'middleName',
        'language',
        'email',
        'birthDate',
        'age',
        'phoneNumber',
        'address',
        'countryOfBirth',
        'province',
        'city',
        'postalCode'
      ]"
      [scrollable]="true"
      [style]="{ width: '100%' }"
    >
      <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between table-header">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="filterGlobal($any($event.target).value)"
              [placeholder]="'pages.clients.table.globalSearchPlaceholder' | translate"
            />
          </span>
          <p-multiSelect
            [options]="scrollableCols"
            [(ngModel)]="selectedCols"
            optionLabel="header"
            [selectedItemsLabel]="'pages.clients.table.selectedColumns' | translate: { number: '{0}' }"
            [style]="{ minWidth: '200px' }"
            (onChange)="selectedColsChanged()"
            [placeholder]="'pages.clients.table.chooseColumns' | translate"
          >
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="frozenheader" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th pFrozenColumn>{{ 'pages.clients.table.actions' | translate }}</th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter
              [matchModeOptions]="matchModeOptions"
              type="text"
              [field]="col.field"
              display="menu"
              class="ml-auto"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-client let-editing="editing" let-ri="rowIndex" let-columns="columns">
        <tr class="p-selectable-row" [pEditableRow]="client">
          <td pFrozenColumn style="max-width: 79px">
            <div>
              <button
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                (click)="navigateToEdit(client)"
                class="p-button-rounded p-button-text ml-2"
              ></button>
            </div>
          </td>
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="output">
                <div *ngIf="col.field === 'age'">
                  {{ getAge(strictTypingService.asClient(client).birthDate) }}
                </div>
                <div *ngIf="col.field === 'language'">
                  {{ strictTypingService.asClient(client).language?.name }}
                </div>
                <div *ngIf="col.field === 'smokerStatus'">
                  {{ strictTypingService.asClient(client).smokerStatus | titlecase }}
                </div>
                <div *ngIf="col.field === 'countryOfBirth'">
                  {{ strictTypingService.asClient(client).countryOfBirth?.name }}
                </div>
                <div *ngIf="col.field === 'province'">
                  {{ strictTypingService.asClient(client).province?.name }}
                </div>
                <div *ngIf="col.field === 'birthDate'">
                  {{ strictTypingService.asClient(client).birthDate | date }}
                </div>

                <div
                  *ngIf="
                    col.field !== 'age' &&
                    col.field !== 'language' &&
                    col.field !== 'countryOfBirth' &&
                    col.field !== 'province' &&
                    col.field !== 'smokerStatus' &&
                    col.field !== 'birthDate'
                  "
                >
                  {{ strictTypingService.asClient(client)[col.field] }}
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">
            {{ 'pages.clients.table.emptyMessage' | translate }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
        <div>
          <span>{{ 'pages.clients.table.rowsDisplay' | translate }}: </span>
          <p-dropdown styleClass="p-paginator-rpp-options" [options]="rowsPerPage" (onChange)="rowsChange($event)"></p-dropdown>
        </div>
      </ng-template>
      <ng-template pTemplate="paginatorright">
        <div>
          <span>{{ 'pages.clients.table.jumpToPage' | translate }}: </span>
          <p-dropdown
            styleClass="p-paginator-rpp-options"
            [filter]="true"
            filterBy="value"
            optionLabel="value"
            optionValue="value"
            [options]="totalPages"
            [(ngModel)]="page"
            (onChange)="setCurrentPage($event)"
            [placeholder]="'pages.clients.table.jumpToPagePlaceholder' | translate"
            [style]="{'width':'140px'}"
          ></p-dropdown>
        </div>
      </ng-template>
    </p-table>
  </div>
</div>
