import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { StringRegexConstants } from '@iapplication2/constants';
import { FormBuilderField, FormBuilderFieldValidator } from '@iapplication2/interfaces';
import { ValidatorTypeEnum } from '@iapplication2/interfaces';
import { CustomValidatorsService } from '../utils/custom-validators.service';

@Injectable({
  providedIn: 'root',
})
export class InteractiveFormBuilderValidatorsService {
  constructor(private customValidatorsService: CustomValidatorsService) {}

  getValidators(fieldDetails: FormBuilderField): ValidatorFn[] {
    const validatorsList: ValidatorFn[] = [];
    if (fieldDetails.fieldValidators) {
      if (fieldDetails.fieldValidators.manualValidators) {
        fieldDetails.fieldValidators.manualValidators.forEach((validator) => {
          this.addValidator(validator, validatorsList);
        });
      }
      if (fieldDetails.fieldValidators.predefinedValidators) {
        fieldDetails.fieldValidators.predefinedValidators.forEach((validator) => {
          this.addValidator(validator, validatorsList);
        });
      }
    }

    return validatorsList;
  }

  // Validator declared locally to avoid circular dependency between service and validator library
  private required(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;

      return (value && value.length === 0) || StringRegexConstants.ONLY_SPACES.test(value) ? { required: true } : null;
    };
  }

  private addValidator(validator: FormBuilderFieldValidator, validatorsList: ValidatorFn[]) {
    if (Object.values(ValidatorTypeEnum).some((value) => value === validator.validatorKey) && validator) {
      switch (validator.validatorKey) {
        case ValidatorTypeEnum.REQUIRED:
          {
            validatorsList.push(this.required());
            validatorsList.push(Validators.required);
          }
          break;

        case ValidatorTypeEnum.NAME:
          {
            validatorsList.push(this.customValidatorsService.nameFormat);
          }
          break;

        case ValidatorTypeEnum.MIN_LENGTH:
          {
            const min = Number(validator?.configuration[0]?.value);
            if (min > 0) validatorsList.push(Validators.minLength(min));
          }
          break;

        case ValidatorTypeEnum.MAX_LENGTH:
          {
            const max = Number(validator?.configuration[0]?.value);
            if (max > 0) validatorsList.push(Validators.maxLength(max));
          }
          break;

        case ValidatorTypeEnum.LENGTH_BETWEEN:
          {
            const min = Number(validator?.configuration[0]?.value);
            const max = Number(validator?.configuration[1]?.value);
            validatorsList.push(this.customValidatorsService.hasLengthBetween(min, max));
          }
          break;

        case ValidatorTypeEnum.FIXED_LENGTH:
          {
            const lengthValues = [];
            validator?.configuration.forEach((configuration) => {
              lengthValues.push(Number(configuration.value));
            });
            validatorsList.push(this.customValidatorsService.hasFixedLength(lengthValues));
          }
          break;

        case ValidatorTypeEnum.EMAIL:
          {
            validatorsList.push(Validators.email);
          }
          break;

        case ValidatorTypeEnum.DOES_NOT_CONTAIN_VALUE:
          {
            const values = [];
            validator?.configuration.forEach((configuration) => {
              values.push(configuration.value);
            });
            validatorsList.push(this.customValidatorsService.doesNotContainValue(values));
          }
          break;

        case ValidatorTypeEnum.CONTAINS_VALUE:
          {
            const values = [];
            validator?.configuration.forEach((configuration) => {
              values.push(configuration.value);
            });
            validatorsList.push(this.customValidatorsService.containsValue(values));
          }
          break;
      }
    }
  }
}
