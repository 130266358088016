<div class="card p-2" fxLayout="column">
  <iapplication2-form-builder-header
    (addNewArea)="addArea($event)"
    (sectionWasChanged)="loadFormAreasBySection($event)"
    [formSectionsList]="formSectionsList"
    [formId]="formId"
    *ngIf="formSectionsList"
  >
  </iapplication2-form-builder-header>
  <p-divider></p-divider>
  <iapplication2-unassigned-fields-area
    *ngIf="unassignedFields"
    [unassignedFields]="unassignedFields"
    [connectedTo]="connectedTo"
    [selectedFormField]="selectedFormField"
    [selectedCanvasObject]="currentlySelectedCanvasField"
    (droppedArea)="drop($event)"
    #unassignedFieldsArea
  >
  </iapplication2-unassigned-fields-area>
  <div
    [sortablejs]="formAreas"
    [sortablejsOptions]="sortableJsOptions"
    fxLayout="row wrap"
    fxFlex="100"
    class="area-settings"
    *ngIf="formAreas && connectedTo"
  >
    <iapplication2-form-builder-area
      *ngFor="let area of formAreas"
      [formArea]="area"
      [fxFlex]="area.width | convertFormAreaWidth"
      [selectedFormField]="selectedFormField"
      [selectedCanvasObject]="currentlySelectedCanvasField"
      [connectedTo]="connectedTo"
      (droppedArea)="drop($event)"
      (deleteFormArea)="deleteArea($event)"
    >
    </iapplication2-form-builder-area>
  </div>
</div>
