/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { QuickQuote } from '@iapplication2/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-result-section-quotes',
  templateUrl: './result-section-quotes.component.html',
  styleUrls: ['./result-section-quotes.component.scss'],
})
export class ResultSectionQuotesComponent implements OnInit, OnDestroy {
  @Input() quickQuotes: QuickQuote[];
  @Input() loading: boolean = true;
  @Input() searchedString: string;
  items: MenuItem[];

  private unsubscribe = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.items = [
      {
        label: '',
        items: [
          {
            label: '',
            icon: 'pi pi-pencil',
          },
          {
            label: '',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
    this.getTranslationsForActionButton();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForActionButton();
    });
  }

  toggleMenu(quickQuote: QuickQuote, menu, ev) {
    console.log(quickQuote);
    ev.preventDefault();
    menu.toggle(ev);
  }

  private getTranslationsForActionButton() {
    this.translateService
      .get('pages.globalSearch.sections.quickQuotes.table.button')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        this.items[0].label = res.title;
        this.items[0].items[0].label = res.options?.one;
        this.items[0].items[1].label = res.options?.two;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
