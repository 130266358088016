import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Advisor } from '@iapplication2/interfaces';
import { AdvisorsService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-change-advisor',
  templateUrl: './change-advisor.component.html',
  styleUrls: ['./change-advisor.component.scss'],
})
export class ChangeAdvisorComponent implements OnInit, OnDestroy {
  @Input() currentAdvisor: Advisor;
  advisorList: Advisor[] = [];
  filteredAdvisorsList: Advisor[] = [];
  selectedAdvisor: Advisor;
  @ViewChild('op') overlayPanel;
  @Output() advisorChanged: EventEmitter<Advisor> = new EventEmitter();

  unsubscribe: Subject<unknown> = new Subject();

  constructor(private advisorsService: AdvisorsService) {}

  ngOnInit(): void {
    console.log(this.currentAdvisor);
  }

  filterAdvisors(event) {
    this.advisorsService
      .searchAdvisors(event.query)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.filteredAdvisorsList = res as Advisor[];
      });
  }

  onChangeAdvisorOverlayShow() {
    if (this.advisorList.length === 0) {
      this.advisorsService
        .getAdvisorList()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.advisorList = res as Advisor[];
        });
    }
  }

  clearedInput() {
    this.filteredAdvisorsList = this.advisorList;
  }

  cancelOverlay() {
    this.overlayPanel.hide();
  }

  changeAdvisor() {
    this.currentAdvisor = this.selectedAdvisor;
    // this.client.advisorId = this.selectedAdvisor.id;
    // this.changeAdvisorEmitter.emit(this.client.advisorId);
    // this.clientManagementService.updateClient(this.client);
    // this.getAdvisorById();
    this.advisorChanged.emit(this.currentAdvisor);
    this.cancelOverlay();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
