<div fxLayout="column">
  <div>
    <h4>Set display conditions for {{ item.groupOptions?.id ? item.groupOptions.name : item.options.customFieldLabel }}</h4>
    <p-progressSpinner *ngIf="loadingExistingConditions" [style]="{ marginLeft: 'calc(50% - 50px)' }"></p-progressSpinner>
    <div *ngIf="!loadingExistingConditions">
      <div *ngFor="let condition of conditionsForm.controls; let i = index">
        <form [formGroup]="condition | cast: formGroupType">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center" class="condition-area mb-3">
            <div fxLayout="row">
              <div class="p-3" fxLayout="column">
                <label for="fieldSelect" class="pb-2">Field</label>
                <p-dropdown
                  class="width-100"
                  [options]="listOfFields"
                  [showClear]="true"
                  placeholder="Select a field"
                  [filter]="true"
                  appendTo="body"
                  filterBy="options.customFieldLabel"
                  formControlName="conditionFieldId"
                  id="fieldSelect"
                  (onChange)="changedFieldSelection(condition)"
                >
                  <ng-template let-selectedField pTemplate="selectedItem">
                    <iapplication2-display-conditions-custom-dropdown-field
                      [item]="selectedField"
                      [isHorizontal]="true"
                    ></iapplication2-display-conditions-custom-dropdown-field>
                  </ng-template>
                  <ng-template let-field pTemplate="item">
                    <iapplication2-display-conditions-custom-dropdown-field
                      [item]="field"
                      [isHorizontal]="false"
                    ></iapplication2-display-conditions-custom-dropdown-field>
                  </ng-template>
                </p-dropdown>
              </div>
              <div fxLayout="column" class="p-3">
                <label for="conditionType" class="pb-2">Condition Type</label>
                <p-dropdown
                  [options]="conditionTypeObject[condition.get('conditionFieldId').value?.fieldType?.id]"
                  [disabled]="!condition.get('conditionFieldId').value"
                  id="conditionType"
                  optionLabel="displayLabel"
                  appendTo="body"
                  formControlName="conditionType"
                  placeholder="Select a condition type"
                ></p-dropdown>
              </div>
              <div fxLayout="column" class="p-3" *ngIf="condition.get('conditionType').value?.acceptsValue">
                <label class="pb-2">Value</label>
                <iapplication2-display-condition-value-selection
                  [conditionFormGroup]="condition | cast: formGroupType"
                  [listOfFields]="originalListOfFields"
                ></iapplication2-display-condition-value-selection>
              </div>
            </div>
            <div class="pr-3">
              <button pButton icon="pi pi-trash" (click)="removeCondition(i)"></button>
            </div>
          </div>
        </form>
      </div>
      <div fxLayout="row">
        <button pButton label="Add a new condition" (click)="addCondition()" class="mr-3"></button>
        <button pButton label="Save conditions" (click)="saveConditions()"></button>
      </div>
    </div>
  </div>
</div>
