import { ProductStatus } from './product-status.model';
import { ProductVersion } from './product-version.model';
import { PaymentMethods } from './payment.enum';
import { CardResponse } from './application.model';

export interface ApplicationProduct {
  id?: number;
  productId: number;
  name: string;
  minCoverage: number;
  maxCoverage: number;
  coverage: number;
  payment: {
    value: number;
    method?: PaymentMethods;
    cardResponse?: CardResponse;
  };
  withEverest: boolean;
  selected: number;
  requiresQuestionnaire: number;
  productStatusId: number;
  hasEverest?: boolean;
  version?: ProductVersion;
  policyId?: string;
  status?: ProductStatus;
  risk: number;
  [key: string]: any;
}
