import { Component, Input } from '@angular/core';
import { FieldType } from '@iapplication2/interfaces';

@Component({
  selector: 'iapplication2-field-type-card',
  templateUrl: './field-type-card.component.html',
  styleUrls: ['./field-type-card.component.scss'],
})
export class FieldTypeCardComponent {
  @Input() defaultItem: FieldType;
}
