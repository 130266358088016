<div fxLayout="row" fxLayoutAlign="space-between start" class="py-2">
  <div fxLayout="row" fxLayoutAlign="center center">
    <span class="sub-section-handle">
      <img [src]="isDarkTheme ? './assets/custom/icons/drag-icon-light.svg' : './assets/custom/icons/drag-icon.svg'" class="drag-icon" />
    </span>
    <p-inplace [closable]="true" closeIcon="pi pi-check" class="ml-2" (onDeactivate)="onDeactivateInplace()">
      <ng-template pTemplate="display">
        <span *ngIf="formBuilderArea.title; else emptyTitle">
          <b class="sub-section-title">{{ formBuilderArea.title }}</b>
          <i class="pi pi-pencil ml-2" style="font-size: 0.8rem"></i>
        </span>
        <ng-template #emptyTitle>Click here to set a title for this area..</ng-template>
      </ng-template>
      <ng-template pTemplate="content">
        <input type="text" [(ngModel)]="formBuilderArea.title" pInputText placeholder="Area title" />
      </ng-template>
    </p-inplace>
  </div>
  <button
    pButton
    pRipple
    icon="pi pi-cog"
    class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
    (click)="toggleMenu(formBuilderArea, menu, $event)"
  ></button>
</div>
<p-menu #menu [popup]="true" appendTo="body" [model]="items" styleClass="larger-p-menu"></p-menu>
