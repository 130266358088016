import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/* This directive checks if the input value is a multiple of 500, and if not, it rounds the number */
@Directive({
  selector: '[iapplication2MultipleOfNumber]'
})
export class MultipleOfNumberDirective {
  private control: AbstractControl;

  @Input() set iapplication2MultipleOfNumber(control: AbstractControl) {
    this.control = control;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown() {
    if (this.control.value % 500 !== 0 )
      this.roundNumber();
  }

  private roundNumber() {
    let value = Number(this.control?.value);
    value = Math.round(value/500)*500;

    this.control?.setValue(value, { emitEvent: true, bubbles: true });
  }
}
