import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { LanguagesEnum, Province, QuickQuote, Severity } from '@iapplication2/interfaces';
import { QuickQuoteManagementService, UtilsService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormHelper } from '@iapplication2/superclass';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'iapplication2-quick-quote-form',
  templateUrl: './quick-quote-form.component.html',
  styleUrls: ['./quick-quote-form.component.scss'],
})
export class QuickQuoteFormComponent extends FormHelper implements OnInit, OnDestroy {
  @Input() quickQuoteForm: FormGroup;
  @Output() formSubmisionTriggered: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() quickQuoteId?: number;
  formSubmissionTriggered = false;

  provinces: Province[];
  selectedProvince: Province;

  smokerStatuses: string[] = [];

  messages: {
    successCreation: Message;
    successEdit: Message;
    errorCreation: Message;
    errorEdit: Message;
  } = {
    successCreation: {
      severity: Severity.SUCCESS,
      summary: 'Success',
      detail: 'The quick-quote was successfully created',
    },
    successEdit: {
      severity: Severity.SUCCESS,
      summary: 'Success',
      detail: 'The quick-quote was successfully modified',
    },
    errorCreation: {
      severity: Severity.ERROR,
      summary: 'Error',
      detail: 'There was an error with the quick-quote creation',
    },
    errorEdit: {
      severity: Severity.ERROR,
      summary: 'Error',
      detail: 'There was an error while modifying the quick-quote',
    },
  };

  sex: string[] = [];

  translatedPlaceholdersForDropdowns: {
    province: string;
  } = {
    province: 'Select province.',
  };

  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private location: Location,
    private translateService: TranslateService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private quickQuoteManagementService: QuickQuoteManagementService
  ) {
    super();
  }

  ngOnInit() {
    this.getProvinces();
    this.smokerStatuses = this.utilsService.getSmokerStatuses();
    this.sex = this.utilsService.getSex();

    // Method needed to set the placeholder of a dropdown from i18n
    this.getTranslationsForDropdownPlaceholders();
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslationsForDropdownPlaceholders();
    });

    if (this.quickQuoteId) {
      this.quickQuoteManagementService
        .getQuickQuoteById(this.quickQuoteId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: QuickQuote) => {
          if (res) {
            this.getQuickQuoteAndFillForm(res);
          }
        });
    }
    this.setOptionsOnLangChange();
  }

  setOptionsOnLangChange() {
    this.translateService.onLangChange.pipe().subscribe(() => {
      this.getProvinces();
    });
  }

  getProvinces() {
    this.utilsService
      .getProvincesFromApi()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          this.provinces = res?.map((province, index) => {
            const name = this.getNameKeyBySelectedLanguage();
            return {
              id: index + 1,
              name: province[name],
              abbreviation: province.prov_short,
            };
          });
        },
      });
  }

  getNameKeyBySelectedLanguage(): string {
    let name: string;
    switch (this.translateService.currentLang) {
      case LanguagesEnum.EN:
        name = 'name';
        break;
      default:
        name = `name_${this.translateService.currentLang}`;
    }
    return name;
  }

  getQuickQuoteAndFillForm(quickQuote: QuickQuote) {
    const existingQuickQuote = quickQuote;
    existingQuickQuote.birthDate
      ? (existingQuickQuote.birthDate = new Date(existingQuickQuote.birthDate))
      : (existingQuickQuote.birthDate = null);

    this.provinces?.forEach((province) => {
      if (province.id && existingQuickQuote.province.id && province.id === existingQuickQuote.province.id) {
        existingQuickQuote.province = province;
      }
    });

    this.quickQuoteForm = this.fillFormWithObjectValues<QuickQuote>(this.quickQuoteForm, existingQuickQuote);
  }

  getTranslationsForDropdownPlaceholders() {
    this.translateService
      .get('pages.quickQuotes.singlePage.fields.province.placeholder')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: string) => {
        this.translatedPlaceholdersForDropdowns.province = res;
      });
  }

  hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  submit() {
    this.formSubmissionTriggered = true;
    this.formSubmisionTriggered.emit(true);
  }

  saveQuickQuote() {
    if (this.quickQuoteId) {
      let modifiedQuickQuote: QuickQuote = null;
      this.quickQuoteForm.controls['faceAmount'].setValue(+this.quickQuoteForm.controls['faceAmount']?.value);
      modifiedQuickQuote = this.createObjectFromFormValues<QuickQuote>(this.quickQuoteForm, modifiedQuickQuote);
      modifiedQuickQuote.id = this.quickQuoteId;

      this.quickQuoteManagementService
        .updateQuickQuote(modifiedQuickQuote)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.showEditSuccessMessage();
        }),
        (error) => {
          this.showEditErrorMessage();
        };
    } else {
      let newSavedQuickQuote: QuickQuote = null;
      this.quickQuoteForm.controls['faceAmount'].setValue(+this.quickQuoteForm.controls['faceAmount']?.value);
      newSavedQuickQuote = this.createObjectFromFormValues<QuickQuote>(this.quickQuoteForm, newSavedQuickQuote);

      this.quickQuoteManagementService
        .createQuickQuote(newSavedQuickQuote)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: QuickQuote) => {
          this.showCreationSuccessMessage();
          this.changeBehaviorFromCreateToModify(response);
        }),
        (error) => {
          this.showCreationErrorMessage();
        };
    }
  }

  changeBehaviorFromCreateToModify(newSavedQuickQuote: QuickQuote) {
    this.updateRoute(newSavedQuickQuote);
    this.quickQuoteForm.markAsUntouched();
  }

  updateRoute(newSavedQuickQuote: QuickQuote) {
    const newQuickQuoteRoute = `/quick-quotes/quick-quote/${newSavedQuickQuote.id}`;
    this.location.go(newQuickQuoteRoute);
    this.quickQuoteId = newSavedQuickQuote.id;
  }

  showCreationSuccessMessage() {
    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: this.messages.successCreation.summary,
      detail: this.messages.successCreation.detail,
    });
  }

  showEditSuccessMessage() {
    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: this.messages.successEdit.summary,
      detail: this.messages.successEdit.detail,
    });
  }

  showCreationErrorMessage() {
    this.messageService.add({
      severity: Severity.ERROR,
      summary: this.messages.errorCreation.summary,
      detail: this.messages.errorCreation.detail,
    });
  }

  showEditErrorMessage() {
    this.messageService.add({
      severity: Severity.ERROR,
      summary: this.messages.errorEdit.summary,
      detail: this.messages.errorEdit.detail,
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
