import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ApplicationFormData,
  ApplicationStatusOptions,
  AttachedFile,
  ENV,
  envType,
  FormBuilderField,
  Premium,
  Product,
  RatesProductAdvisor,
  SummaryPageInfo,
} from '@iapplication2/interfaces';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Iapp1Ids } from '../../../../../../apps/api-service/src/app/common/enums';
import { ApplicationsProcessService, ApplicationSummaryService, ESignatureService, ProgressSpinnerService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from '@iapplication2/app-config';
import _ = require('lodash');
import * as moment from 'moment';
import Decimal from 'decimal.js';

@Component({
  selector: 'iapplication2-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  productList: RatesProductAdvisor[];

  summaryPageInfo: SummaryPageInfo;
  applicationId: number;
  applicationAttachments: AttachedFile[] = [];
  selectedUpsellProducts: Product[] = [];

  showScheduleApplicationModal = false;
  scheduleApplicationPremiumReceived = false;
  premiums: Premium[];
  ratesForSelectedProducts: any[];

  unsubscribe: Subject<unknown> = new Subject();
  isApplicationSentForSignature = false;
  isEnvDevelop: boolean;
  disabledSubmitButtons = true;
  currentApplication;
  ApplicationStatusOptions = ApplicationStatusOptions;

  @Output() finishApplicationTriggered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private applicationSummaryService: ApplicationSummaryService,
    private applicationsProcessService: ApplicationsProcessService,
    private eSignatureService: ESignatureService,
    private router: Router,
    private progressSpinnerService: ProgressSpinnerService,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit(): void {
    this.productList = this.applicationsProcessService.availableProducts;

    this.applicationId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currentApplication = this.applicationsProcessService.currentApplication;
    this.getApplicationAttachments(this.applicationId);
    this.getSelectedProducts(this.applicationsProcessService.currentApplication);
    this.getSignatureSubmissionInfo();
    // TODO: upsell option temporary removed - IAPP2CA-973
    // this.getUpsellProducts(this.applicationId);
  }

  getSelectedProducts(application) {
    const products: Product[] = [];

    this.productList.forEach((product) => {
      application.products?.forEach((item) => {
        if (product.iappProduct.id === item.productId) {
          item = {
            ...item,
            productType: product?.iappProduct?.productType,
            iappProduct: product.iappProduct,
            product_code: product.code,
          };
          products.push(item);
        }
      });
    });

    this.summaryPageInfo = {
      ...this.summaryPageInfo,
      client: application?.client,
      products: products,
      currencySymbol: application?.currency?.symbol,
      paymentMethod: this.applicationsProcessService.currentApplication?.products[0]?.payment?.method,
      dates: {
        ...this.summaryPageInfo?.dates,
        schedule: application?.scheduleDate,
        withdrawal: application?.withdrawalDate,
      },
    };
  }

  checkIfEnvIsDevelop() {
    this.isEnvDevelop = this.appConfig.environmentType === envType.DEVELOP;
  }

  // TODO: upsell option temporary removed - IAPP2CA-973
  // updateSelectedUpsellProducts(product) {
  //   /* Search for the modified product and update the selected upsell products */
  //
  //   this.summaryPageInfo?.upsellProducts?.forEach((upsellProduct) => {
  //     if (upsellProduct.productId === product.productId) {
  //       if (product.selected) {
  //         this.selectedUpsellProducts = [...this.selectedUpsellProducts, upsellProduct];
  //       } else {
  //         if (this.selectedUpsellProducts.some((item) => item.id === product.id)) {
  //           this.selectedUpsellProducts = this.selectedUpsellProducts.filter((prod) => prod.id !== product.id);
  //         }
  //       }
  //     }
  //   });
  // }

  private getApplicationAttachments(applicationId: number): void {
    this.applicationSummaryService
      .getApplicationAttachments(applicationId.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: AttachedFile[]) => {
        if (res) {
          this.applicationAttachments = res;
        }
      });
  }

  private getSignatureSubmissionInfo() {
    this.eSignatureService.isAppSignatureSubmittedSubject.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.isApplicationSentForSignature = value;
    });
  }

  // TODO: upsell option temporary removed - IAPP2CA-973
  // private getUpsellProducts(applicationId: number): void {
  //   this.applicationSummaryService
  //     .getUpsellProducts(applicationId)
  //     .pipe(takeUntil(this.unsubscribe))
  //     .subscribe((res: any) => {
  //       if (res) {
  //         this.summaryPageInfo = {
  //           ...this.summaryPageInfo,
  //           upsellProducts: res,
  //         };
  //       }
  //     });
  // }

  sendApplication() {
    this.updateSignedAtDateFields();
    this.disabledSubmitButtons = true;
  }

  private finishApplication() {
    this.finishApplicationTriggered.emit(true);
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.applicationsProcessService.finishApplication(this.applicationId).subscribe({
      next: () => {
        this.router.navigate(['applications', 'application', this.applicationId]);
        this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
      },
      error: () => {
        this.finishApplicationTriggered.emit(false);
        this.progressSpinnerService.toggleProgressSpinnerDebounced(false);
        this.disabledSubmitButtons = false;
      },
      complete: () => {
        this.disabledSubmitButtons = true;
      },
    });
  }

  enableSubmit(loadingComplete: boolean) {
    this.disabledSubmitButtons = !loadingComplete;
  }

  private updateSignedAtDateFields() {
    const allFieldsWithSignedAtDateStaticLink = this.applicationsProcessService.allFieldsInAllForms.filter(
      (field: FormBuilderField) => !!field.staticValueLink?.fieldKey && field.staticValueLink?.iapp1Id === Iapp1Ids.SIGNED_AT_DATE
    );

    let foundFields = 0;

    allFieldsWithSignedAtDateStaticLink.forEach((field: FormBuilderField) => {
      const indexOfFormDataForSignedAtDateField = this.applicationsProcessService.applicationFormDataArray.findIndex(
        (data: ApplicationFormData) => data?.value && data?.fieldId === field?.id
      );
      if (indexOfFormDataForSignedAtDateField !== -1) {
        foundFields++;
        this.applicationsProcessService.applicationFormDataArray[indexOfFormDataForSignedAtDateField].value = new Date().toISOString();
      }
    });

    if (foundFields) {
      this.saveProcessedData().subscribe({
        complete: () => {
          this.saveAndFinishApplication();
        },
      });
    } else {
      this.saveAndFinishApplication();
    }
  }

  private saveAndFinishApplication() {
    this.saveApplication().subscribe({
      complete: () => {
        this.finishApplication();
      },
    });
  }

  openScheduleApplicationModal() {
    this.showScheduleApplicationModal = true;
    this.applicationSummaryService.getPremiumsForScheduledApplication(this.applicationId).subscribe({
      next: (res: Premium[]) => {
        if (res) {
          this.ratesForSelectedProducts = res;
          this.getTotalPremiums(res);
        }
      },
      error: () => {
        this.scheduleApplicationPremiumReceived = false;
      },
    });
  }

  private saveProcessedData() {
    return this.applicationsProcessService.saveFormData(this.applicationId, this.applicationsProcessService.applicationFormDataArray);
  }

  getTotalPremiums(premiums) {
    if (premiums.length) {
      const premiumAges = _.cloneDeep(premiums.filter((premium) => premium.premiumAges?.length)[0]?.premiumAges);
      if (premiumAges) {
        this.scheduleApplicationPremiumReceived = true;
        premiumAges.forEach((premiumAge) => {
          premiumAge.premium = 0;
        });
        premiums.forEach((premium) => {
          premium.premiumAges.forEach((premiumAge, index) => {
            premiumAges[index].premium = premiumAges[index].premium + premiumAge.premium;
          });
        });
        this.premiums = premiumAges;
      }
    }
  }

  scheduleApplicationModalClosed() {
    this.scheduleApplicationPremiumReceived = false;
  }

  onCloseScheduleApplicationModal() {
    this.showScheduleApplicationModal = false;

    this.applicationsProcessService.updateApplication(this.applicationId, this.applicationsProcessService.currentApplication).subscribe({
      next: (res: Premium[]) => {
        if (res) {
          this.finishApplication();
        }
      },
    });
  }

  onScheduledDateSelected(scheduledDate: Date) {
    const scheduledDay = moment(scheduledDate);
    this.ratesForSelectedProducts.forEach((product) => {
      product.premiumAges.forEach((premiumAge) => {
        if (moment(premiumAge.date).isSame(scheduledDay, 'day')) {
          const updatedProducts = this.applicationsProcessService.currentApplication.products.map((serviceProduct) => {
            if (serviceProduct.id === product.productId) {
              let roundedPremium;
              if (premiumAge.premium.toString().slice(premiumAge.premium.toString().length - 5) === '99999') {
                roundedPremium = new Decimal(new Decimal(premiumAge.premium).toFixed(3)).toFixed(2);
              } else {
                roundedPremium = new Decimal(premiumAge.premium).toFixed(2);
              }
              serviceProduct.monthlyPremium = roundedPremium;
              serviceProduct.yearlyPremium = (roundedPremium * 12).toFixed(2);
            }
            return serviceProduct;
          });
          const applicationToUpdate = _.cloneDeep(this.applicationsProcessService.currentApplication);
          applicationToUpdate.products = updatedProducts;
          this.applicationsProcessService.setCurrentApplication(applicationToUpdate);
        }
      });
    });
    this.summaryPageInfo.dates.schedule = scheduledDate;

    const currentApplicationToUpdate = _.cloneDeep(this.applicationsProcessService.currentApplication);
    currentApplicationToUpdate.scheduleDate = scheduledDate;
    this.applicationsProcessService.setCurrentApplication(currentApplicationToUpdate);
  }

  saveApplication() {
    return this.applicationsProcessService.updateApplication(this.applicationId, this.applicationsProcessService.currentApplication);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
