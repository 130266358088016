<div>
  <p-table [value]="clients" [lazy]="true" [scrollable]="true" [loading]="loading" scrollHeight="200px" [autoLayout]="true" #customtable>
    <ng-template pTemplate="header">
      <tr>
        <th class="med-col">
          {{ 'pages.dashboard.sections.recentClients.tabs.recentClients.table.columns.client' | translate }}
        </th>
        <th class="small-col">
          {{ 'pages.dashboard.sections.recentClients.tabs.recentClients.table.columns.age' | translate }}
        </th>
        <th class="med-col">
          {{ 'pages.dashboard.sections.recentClients.tabs.recentClients.table.columns.email' | translate }}
        </th>
        <th class="small-col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-client>
      <tr>
        <td
          class="med-col name-column"
          [highlight]="searchedString"
          [innerHTML]="client?.fullName"
          [pTooltip]="client?.fullNameTooltip"
        ></td>
        <td class="med-col" [highlight]="searchedString" [innerHTML]="client.age"></td>
        <td class="med-col name-column" [highlight]="searchedString" [innerHTML]="client.email" [pTooltip]="client.email"></td>
        <td class="small-col">
          <p-button icon="pi pi-cog" class="p-button-secondary" (click)="toggleMenu(client, menu, $event)"></p-button>
          <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="pt-3 pl-2">
    <a [routerLink]="['/clients']"
      >{{ 'pages.dashboard.sections.recentClients.tabs.recentClients.seeAll' | translate }}
      <i class="pi pi-arrow-right" style="font-size: 0.8rem"></i
    ></a>
  </div>
</div>
