import { Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormArea, FormBuilderField, FormBuilderItem } from '@iapplication2/interfaces';
import { ApplicationsProcessService, InteractiveFormBuilderService } from '@iapplication2/services';
import { FormDisplayItemComponent } from '../form-display-item/form-display-item.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-form-display-area',
  templateUrl: './form-display-area.component.html',
  styleUrls: ['./form-display-area.component.scss'],
})
export class FormDisplayAreaComponent implements OnChanges {
  unsubscribe: Subject<unknown> = new Subject();
  @Input() area: FormArea;
  @Input() areaForm: FormGroup;
  @Input() isPreviewMode: boolean;
  @Input() showStaticText: boolean;
  @Input() showField: boolean;
  @Input() hiddenFieldIds: number[];
  @ViewChildren(FormDisplayItemComponent) formBuilderItemsDom: QueryList<FormDisplayItemComponent>;
  @ViewChildren(FormDisplayItemComponent, { read: ElementRef }) formBuilderItemsDomRef: QueryList<ElementRef>;

  isViewApplication: boolean;
  itemsInArea: { item: FormBuilderItem; control: FormControl }[] = [];

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private applicationsProcessService: ApplicationsProcessService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.areaForm) {
      this.checkViewApplicationDisplay();
      if (this.area.fields) {
        this.area.fields = this.area.fields.sort((a, b) => a.formAreaPosition - b.formAreaPosition);
      }
      this.itemsInArea = [];
      this.getFieldsInArea();

      this.hiddenFieldIds?.forEach((id) => {
        this.itemsInArea = this.itemsInArea.filter((item) => item.item?.id !== id && item.item.groupOptions?.id !== id);
      });
    }
  }

  private checkViewApplicationDisplay() {
    this.applicationsProcessService.isViewApplication.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.isViewApplication = value;
    });
  }

  getFieldsInArea() {
    this.area.fields?.forEach((field) => {
      // An item group has to be manually created and added to the list

      if (this.isGroup(field) && !this.isTable(field)) {
        if (this.isFieldFirstInGroup(field)) {
          this.itemsInArea.push({
            item: this.createGroupWithAllFields(field),
            control: this.areaForm.get(field.groupOptions.id.toString()) as FormControl,
          });
        }
      } else if (this.isTable(field)) {
        if (this.isFieldFirstInTable(field)) {
          this.itemsInArea.push({
            item: this.createTableWithAllFields(field),
            control: this.areaForm.get((field.groupOptions ? field.groupOptions.id : field.id).toString()) as FormControl,
          });
        }
      } else this.itemsInArea.push({ item: field, control: this.areaForm.get(field.id?.toString()) as FormControl });
    });
  }

  isGroup(item: FormBuilderItem) {
    return this.interactiveFormBuilderService.isFormBuilderItemGroup(item);
  }

  isTable(item: FormBuilderItem) {
    return item.table?.type.id;
  }

  createGroupWithAllFields(item: FormBuilderItem) {
    const fields: FormBuilderField[] = [];
    this.area.fields.filter((object) => {
      if (object.groupOptions?.id == item.groupOptions?.id) {
        fields.push(object as FormBuilderField);
      }
    });
    if (item.table) {
      return {
        table: item.table,
        groupOptions: item.groupOptions,
        fields: fields,
      };
    } else
      return {
        groupOptions: item.groupOptions,
        fields: fields,
      };
  }

  createTableWithAllFields(item: FormBuilderItem) {
    // TODO: Work in progress
    const items: FormBuilderItem[] = [];
    this.area.fields.filter((object) => {
      if (object.table?.type.id === item.table?.type.id) {
        if (this.isGroup(object)) {
          if (this.isFieldFirstInGroup(object)) items.push(this.createGroupWithAllFields(object));
        } else items.push(object as FormBuilderField);
      }
    });
    return {
      id: item.table?.type.id,
      table: item.table?.type,
      name: item.table?.type.name,
      columns: item.table?.type.columns,
      numberOfRows: item.table?.type.numberOfRows,
      items: items,
    } as FormBuilderItem;
  }

  isFieldFirstInGroup(item: FormBuilderItem) {
    const indexOfThisField = this.area.fields.findIndex((foundItem) => foundItem === item);
    const indexOfAnyFieldWithProvidedGroupId = this.area.fields.findIndex(
      (foundItem) => foundItem.groupOptions?.id === item.groupOptions?.id
    );
    return indexOfThisField === indexOfAnyFieldWithProvidedGroupId;
  }

  isFieldFirstInTable(item: FormBuilderItem) {
    const indexOfThisField = this.area.fields.findIndex((foundItem) => foundItem === item);
    const indexOfAnyFieldWithProvidedTableId = this.area.fields.findIndex((foundItem) => foundItem.table?.type.id === item.table?.type.id);

    return indexOfThisField === indexOfAnyFieldWithProvidedTableId;
  }

  navigateToField(itemId: string) {
    const foundInvalidFieldComponentIndex = this.findIndex(itemId);
    if (foundInvalidFieldComponentIndex !== null && !this.isViewApplication) {
      const elementToScrollTo = this.formBuilderItemsDomRef.toArray()[foundInvalidFieldComponentIndex];
      const elementTopPosition = elementToScrollTo.nativeElement.getBoundingClientRect().top;
      const headerOffset = 80;
      const offsetPosition = elementTopPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  private findIndex(itemId: string): number | null {
    if (this.getIndexFromField(itemId) !== -1) {
      return this.getIndexFromField(itemId);
    }
    if (this.getIndexFromGroup(itemId) !== -1) {
      return this.getIndexFromGroup(itemId);
    }
    if (this.getIndexFromTable(itemId) !== -1) {
      return this.getIndexFromTable(itemId);
    }
    return null;
  }

  private getIndexFromField(fieldId: string): number {
    return this.formBuilderItemsDom.toArray().findIndex((itemComponent) => itemComponent.formItem.id?.toString() === fieldId);
  }

  private getIndexFromGroup(groupId: string): number {
    return this.formBuilderItemsDom.toArray().findIndex((itemComponent) => itemComponent.formItem.groupOptions?.id?.toString() === groupId);
  }

  private getIndexFromTable(tableItemId: string): number {
    return this.formBuilderItemsDom
      .toArray()
      .findIndex(
        (itemComponent) =>
          itemComponent.formItem.table?.id && itemComponent.formItem.items?.some((item) => item.id?.toString() === tableItemId)
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
