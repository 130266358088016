<div *ngIf="isGroup && !isStaticText && !isGroupFromTable">
  <iapplication2-form-builder-group-display [formBuilderGroup]="formBuilderItem | cast: FormBuilderGroupType">
  </iapplication2-form-builder-group-display>
</div>
<div *ngIf="isTable">
  <iapplication2-form-builder-table-display [table]="formBuilderItem | cast: FormBuilderTableType">
  </iapplication2-form-builder-table-display>
</div>
<div *ngIf="isField && !isTable && !isGroupFromTable">
  <iapplication2-form-builder-field-display
    [formBuilderField]="formBuilderItem | cast: FormBuilderFieldType"
    [selectedCanvasObject]="selectedCanvasObject"
    [selectedFormField]="selectedFormField"
    (deletedField)="deletedField($event)"
  >
  </iapplication2-form-builder-field-display>
</div>
<div *ngIf="isStaticText">
  <iapplication2-form-builder-static-text-display
    [formBuilderStaticText]="formBuilderItem | cast: FormBuilderStatcTextType"
    (deletedField)="deletedField($event)"
  >
  </iapplication2-form-builder-static-text-display>
</div>
