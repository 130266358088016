import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FieldTable, FormBuilderField, FormBuilderItem, Themes } from '@iapplication2/interfaces';
import { DarkThemeService, InteractiveFormBuilderService } from '@iapplication2/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'iapplication2-unassigned-fields-area',
  templateUrl: './unassigned-fields-area.component.html',
  styleUrls: ['./unassigned-fields-area.component.scss'],
})
export class UnassignedFieldsAreaComponent implements OnChanges, OnDestroy {
  @Input() unassignedFields: FormBuilderField[] = [];
  @Input() selectedCanvasObject: FormBuilderField;
  @Input() selectedFormField: FormBuilderField;
  @Input() connectedTo;
  @Output() droppedArea = new EventEmitter();

  unassignedItems: FormBuilderItem[] = [];
  unsubscribe: Subject<unknown> = new Subject();
  isDarkTheme: boolean;

  constructor(
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private darkThemeService: DarkThemeService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.darkThemeService.themeChanged.subscribe(value => {
      this.isDarkTheme = value === Themes.DARK;
    })

    if (changes.unassignedFields?.currentValue !== changes.unassignedFields?.previousValue) {
      if (this.unassignedFields.length) {
        this.unassignedFields = this.unassignedFields.sort((a, b) => a.formAreaPosition - b.formAreaPosition);
        this.unassignedItems = this.interactiveFormBuilderService.convertListOfFieldsToListOfItems(this.unassignedFields);
      } else {
        this.unassignedFields = [];
        this.unassignedItems = [];
      }
    }
  }

  drop(ev: any): void {
    this.droppedArea.emit(ev);
  }

  getFieldsFromContainerByTableId(container, tableId: string): FormBuilderField[] {
    const foundFields: FormBuilderField[] = [];
    container.forEach((field) => {
      if (field.table?.type.id === tableId) {
        foundFields.push(field);
      }
    });
    return foundFields;
  }

  getAllFieldsFromTableFromUnassignedList(table: FieldTable): FormBuilderField[] {
    return this.unassignedFields.filter((field) => field.table?.type === table) as FormBuilderField[];
  }

  fieldIsGroupAndPartOfTable(field: FormBuilderItem): boolean {
    return field.fields?.find((field) => field.table?.type && field.table?.position.row === 0 && field.table?.position.column.number === 0);
  }

  deletedField(id: number) {
    this.unassignedFields = this.unassignedFields.filter((item) => item.id !== id);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
