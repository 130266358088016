<form [formGroup]="conditionFormGroup">
  <input type="text" pInputText *ngIf="fieldType?.id === fieldTypeEnum.freeText" formControlName="value" />
  <input type="number" pInputText *ngIf="fieldType?.id === fieldTypeEnum.number" formControlName="value" />
  <p-calendar
    *ngIf="fieldType?.id === fieldTypeEnum.date"
    formControlName="value"
    appendTo="body"
    dateFormat="dd/mm/yy"
    iapplication2DateMask
  ></p-calendar>
  <div *ngIf="fieldType?.id === fieldTypeEnum.radioGroup || fieldType?.id === fieldTypeEnum.disclosure">
    <div *ngFor="let option of options" class="mb-2">
      <p-radioButton name="value" formControlName="value" [inputId]="option.id" [value]="option.id">{{ option.options }}</p-radioButton>
      <label [for]="option.id"> {{ option.options.customFieldLabel }}</label>
    </div>
  </div>
  <div *ngIf="fieldType?.id === fieldTypeEnum.checkboxGroup">
    <div *ngFor="let option of options" class="mb-2">
      <p-checkbox
        name="value"
        [formControl]="conditionFormGroup.controls['value'] | cast: FormControlType"
        [inputId]="option.id"
        [value]="option.id"
        >{{ option.options }}</p-checkbox
      >
      <label [for]="option.id"> {{ option.options.customFieldLabel }}</label>
    </div>
  </div>
  <div *ngIf="fieldType?.id === fieldTypeEnum.dropdown">
    <p-dropdown
      [options]="dropdownOptions"
      formControlName="value"
      optionLabel="value"
      optionValue="value"
      placeholder="Please select a value"
      appendTo="body"
    ></p-dropdown>
  </div>
</form>
