<div
  class="card--noBackground mb-2 px-2 py-2 hoverBorder formBuilder-staticText-display"
  cdkDrag
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div style="width: 100%" fxLayout="row" fxLayoutAlign="center center">
    <span cdkDragHandle fxLayoutAlign="center center">
      <img [src]="isDarkTheme ? './assets/custom/icons/drag-icon-light.svg' : './assets/custom/icons/drag-icon.svg'" class="drag-icon" />
    </span>
    <div
      *ngIf="!displayStaticTextEditor; else staticTextEditor"
      style="max-height: 60px; overflow: auto; width: 100%; padding: 0.5rem 0.5rem; margin-right: 1.5rem"
      [innerHTML]="sanitized"
    ></div>
    <ng-template #staticTextEditor>
      <div class="ml-2 mr-3" fxLayout="column" style="width: 100%">
        <p-editor *ngIf="displayStaticTextEditor" [(ngModel)]="tempText" [style]="{ height: '95px' }">
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button type="button" class="ql-italic" aria-label="Italic"></button>
              <button type="button" class="ql-underline" aria-label="Underline"></button>
              <select class="ql-color"></select>
            </span>
          </ng-template>
        </p-editor>
        <div class="mt-2">
          <button pButton pRipple class="p-button-primary" label="Save" (click)="saveNewText()" [disabled]="!tempText"></button>
          <button pButton pRipple class="p-button-text p-button-secondary ml-2" label="Cancel" (click)="cancelTextEdit()"></button>
        </div>
      </div>
    </ng-template>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="fieldType-chip staticText">Static text</div>
    <button
      pButton
      pRipple
      icon="pi pi-cog"
      (click)="toggleMenu(formBuilderStaticText, menu, $event)"
      class="p-button-rounded p-button-secondary p-button-text cog-menu-handle"
    ></button>
  </div>
</div>

<p-menu #menu [popup]="true" [model]="items"></p-menu>
