import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldColumn, FieldTable, FormBuilderField, ModalType, Themes } from '@iapplication2/interfaces';
import { CanvasService, DarkThemeService, InteractiveFormBuilderService, ProgressSpinnerService } from '@iapplication2/services';
import { MenuItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'iapplication2-form-builder-table-display',
  templateUrl: './form-builder-table-display.component.html',
  styleUrls: ['./form-builder-table-display.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class FormBuilderTableDisplayComponent implements OnInit, OnChanges {
  @Input() table: FieldTable;
  showExpandedContent = false;
  tableColumnsItems: MenuItem[];
  tableItems: MenuItem[];
  isTableLabelInEditMode = false;
  isDarkTheme: boolean;

  tableLabel: FormGroup;
  tableColumnLabel: FormGroup;

  constructor(
    private canvasService: CanvasService,
    private interactiveFormBuilderService: InteractiveFormBuilderService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private progressSpinnerService: ProgressSpinnerService,
    private darkThemeService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.table.columns.sort((a, b) => a.number - b.number);
    this.createTableOptionSettingsMenu();
    this.createTableSettingsMenu();
    this.createTableLabel();
    this.createTableColumnLabel();
  }

  ngOnChanges() {
    this.darkThemeService.themeChanged.subscribe((value) => {
      this.isDarkTheme = value === Themes.DARK;
    });
  }

  highlightTabledOnCanvas(): void {
    this.canvasService.hoveredOverFormBuilderTable(this.table);
  }

  unHighlightTabledOnCanvas(): void {
    this.canvasService.leftHoverOverFormBuilderTable(this.table);
  }

  createTableLabel(): void {
    this.tableLabel = new FormGroup({
      label: new FormControl(null, [Validators.required]),
    });
  }

  createTableColumnLabel(): void {
    this.tableColumnLabel = new FormGroup({
      label: new FormControl(null, [Validators.required]),
    });
  }

  toggleExpandedContent(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.showExpandedContent = !this.showExpandedContent;
  }

  drop(ev: CdkDragDrop<FormBuilderField[]>): void {
    const previousColumnPositionInTable = ev.previousIndex;
    const newColumnPositionInTable = ev.currentIndex;
    //TODO: REFACTOR
    // this.canvasService.moveColumnInTable(this.table, previousColumnPositionInTable, newColumnPositionInTable);
  }

  toggleColumnMenu(column: FieldColumn, menu: any, ev: any): void {
    const editMenuItem = this.tableColumnsItems[0].items[0];
    const deleteMenuItem = this.tableColumnsItems[0].items[1];

    // TODO: REFACTOR ↓
    // Deleting linked columns should be handled separately, as this should also unlink the other field from the deleted one.
    // Temporary workaround is to disable deletion of linked columns.
    if (column.linkedWith) {
      deleteMenuItem.disabled = true;
    }

    editMenuItem.command = () => this.editTableColumn(column);
    deleteMenuItem.command = () => this.deleteColumnFromTable(column);
    menu.toggle(ev);
  }

  toggleTableMenu(item: FieldTable, menu: any, ev: any): void {
    const editMenuItem = this.tableItems[0].items[0];
    const deleteMenuItem = this.tableItems[0].items[1];
    editMenuItem.command = () => this.editTable();
    deleteMenuItem.command = () => this.confirmTableDeletion(item);
    menu.toggle(ev);
  }

  confirmTableDeletion(table: FieldTable): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this table and all the fields within it?',
      accept: () => {
        this.deleteTable(table);
      },
    });
  }

  private deleteTable(table: FieldTable): void {
    const tableId = table?.id;
    if (tableId) {
      this.interactiveFormBuilderService.deleteTable(tableId);
    }

    // TODO: Update list of form fields fields after deletion
  }

  private deleteColumnFromTable(column) {
    this.table.columns = this.table.columns.filter((tableColumn) => tableColumn !== column);
    this.toggleColumnDeletionPossibility();
  }

  toggleColumnDeletionPossibility(): void {
    const numberOfColumnsInTable: number = this.table?.columns.length;
    this.tableColumnsItems[0].items[1].disabled = numberOfColumnsInTable <= 2;
  }

  private editTable(): void {
    this.isTableLabelInEditMode = true;
    this.tableLabel.controls['label'].setValue(this.table.name);
  }

  saveTableNameChange(): void {
    this.table.name = this.tableLabel.controls['label'].value;
    this.isTableLabelInEditMode = false;
    this.tableItems[0].items[0].disabled = false;
    this.progressSpinnerService.toggleProgressSpinnerDebounced(true);
    this.interactiveFormBuilderService.updateTable(this.table);

    // TODO: Update list of form fields fields after table update
  }

  public editTableColumn(column: FieldColumn): void {
    this.interactiveFormBuilderService.sidebarColumnSelected(column, this.table.id);
    this.interactiveFormBuilderService.settingsModalOptionsSelected(ModalType.EDIT);
  }

  public saveTableColumnChanges() {
    const updatedTable = this.canvasService.getFieldsFromTable(this.table.id)[0].table.type;
    this.table.columns = updatedTable.columns;
    this.table.name = updatedTable.name;

    this.interactiveFormBuilderService.updateTable(this.table);
    this.interactiveFormBuilderService.deleteColumns(this.table);

    // TODO: Update list of form fields fields after table update
  }

  private createTableOptionSettingsMenu(): void {
    this.tableColumnsItems = [
      {
        label: 'Table column settings',
        items: [
          {
            label: 'Edit table column',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete table column',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
  }

  private createTableSettingsMenu(): void {
    this.tableItems = [
      {
        label: 'Table settings',
        items: [
          {
            label: 'Edit table',
            icon: 'pi pi-pencil',
          },
          {
            label: 'Delete table',
            icon: 'pi pi-trash',
          },
        ],
      },
    ];
  }
}
