import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { ProductStatusTagComponent } from './product-status-tag.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ProductStatusTagComponent],
  imports: [CommonModule, TagModule, TranslateModule],
  exports: [ProductStatusTagComponent],
})
export class ProductStatusTagModule {}
