import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DashboardApplicationsWidgetComponent } from './dashboard-applications-widget/dashboard-applications-widget.component';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '@iapplication2/shared-translate';
import { ResultSectionsModule } from '../../../result-sections/src/lib/result-sections.module';
import { NotificationsModule } from '@iapplication2/notifications';
@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    TabViewModule,
    SkeletonModule,
    MenuModule,
    FlexLayoutModule,
    TagModule,
    NotificationsModule,
    ResultSectionsModule,
    TranslateModule.forChild({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RouterModule.forChild([
      {
        path: '',
        component: DashboardPageComponent,
      },
    ]),
  ],
  exports: [],
  declarations: [DashboardPageComponent, DashboardApplicationsWidgetComponent],
})
export class AdvisorDashboardModule {}
