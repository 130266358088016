import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { register } from '../../../../registryComponent';
import { BaseFieldDataDirective } from '../../../../baseFieldData.directive';
import { FormBuilderFieldTypeOption } from '@iapplication2/interfaces';
import { DatesService } from '@iapplication2/services';
import { Subject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';

@register('date')
@Component({
  selector: 'iapplication2-date-field-display',
  templateUrl: './date-field-display.component.html',
  styleUrls: ['./date-field-display.component.scss'],
})
export class DateFieldDisplayComponent extends BaseFieldDataDirective implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('calendar') calendar;
  private unsubscribe: Subject<void> = new Subject<void>();
  currentYear;
  minimumDate: Date;
  maximumDate: Date;

  constructor(private datesService: DatesService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.setBehaviorBasedOnFieldOptions();
    this.getValueChanges();
  }

  getValueChanges() {
    this.formFieldControl.valueChanges.pipe(startWith(this.formFieldControl.value), pairwise(), takeUntil(this.unsubscribe)).subscribe({
      next: (data) => {
        if (data[0] !== null && data[1] === null) {
          this.formFieldControl.setValue('', { emitEvent: false });
        }
      },
    });
  }

  ngAfterViewInit(): void {
    if (this.isReadonly) {
      this.calendar.el?.nativeElement.children[0].children[1].setAttribute('tabindex', -1);
    }
  }

  setBehaviorBasedOnFieldOptions() {
    switch (true) {
      case this.field?.fieldTypeOptions?.findIndex((option: FormBuilderFieldTypeOption) => option.id === 10) !== -1:
        this.formFieldControl.setValue(this.datesService.fromLocalToUTC(new Date()));
    }
    switch (true) {
      case this.field?.fieldTypeOptions?.some(option => option.id === 24):
        this.minimumDate = new Date();
        break;
      case this.field?.fieldTypeOptions?.some(option => option.id === 25):
        this.maximumDate = new Date();
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
}
