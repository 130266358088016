<div style="overflow: auto">
  <p-editor [(ngModel)]="staticTextField.content" [style]="{ height: '320px' }">
    <ng-template pTemplate="header">
      <span class="ql-formats">
        <button type="button" class="ql-bold" aria-label="Bold"></button>
        <button type="button" class="ql-italic" aria-label="Italic"></button>
        <button type="button" class="ql-underline" aria-label="Underline"></button>
        <select class="ql-color"></select>
      </span>
    </ng-template>
  </p-editor>
</div>
