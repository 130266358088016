import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FormBuilderField,
  FormBuilderItem,
  FormBuilderStaticText,
  FormBuilderGroupType,
  FieldTableType,
  FormBuilderFieldType,
  FormBuilderStaticTextType,
} from '@iapplication2/interfaces';
import { InteractiveFormBuilderService } from '@iapplication2/services';

@Component({
  selector: 'iapplication2-form-builder-item-display',
  templateUrl: './form-builder-item-display.component.html',
  styleUrls: ['./form-builder-item-display.component.scss'],
})
export class FormBuilderItemDisplayComponent implements OnInit, OnChanges {
  @Input() formBuilderItem: FormBuilderItem;
  @Input() selectedCanvasObject: FormBuilderField;
  @Input() selectedFormField: FormBuilderField;
  @Input() staticText: FormBuilderStaticText;
  @Input() formAreaId: number;
  @Output() deletedFieldEvent: EventEmitter<number> = new EventEmitter();

  isField: boolean;
  isGroup: boolean;
  isTable: boolean;
  isGroupFromTable: boolean;
  isStaticText: boolean;

  FormBuilderFieldType = FormBuilderFieldType;
  FormBuilderGroupType = FormBuilderGroupType;
  FormBuilderTableType = FieldTableType;
  FormBuilderStatcTextType = FormBuilderStaticTextType;

  constructor(private interactiveFormBuilderService: InteractiveFormBuilderService) {}

  ngOnInit() {
    this.isField = this.interactiveFormBuilderService.isItemField(this.formBuilderItem);
    this.isGroup = this.interactiveFormBuilderService.isItemGroup(this.formBuilderItem);
    this.isTable = this.interactiveFormBuilderService.isItemTable(this.formBuilderItem);
    this.isGroupFromTable = this.interactiveFormBuilderService.isItemGroupFromTable(this.formBuilderItem);
    this.isStaticText = this.interactiveFormBuilderService.isItemStaticText(this.formBuilderItem);
    this.formBuilderItem.formAreaId = this.formAreaId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formAreaId?.currentValue !== changes.formAreaId?.previousValue) {
      this.formBuilderItem.formAreaId = this.formAreaId;
    }
  }

  deletedField(id: number) {
    this.deletedFieldEvent.emit(id);
  }
}
