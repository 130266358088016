import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
// import { DropdownModule } from 'primeng/dropdown';
import { FieldCategoriesComponent } from './field-categories.component';
import { TooltipModule } from 'primeng/tooltip';
import { FieldCategoriesCreatorComponent } from './field-categories-creator/field-categories-creator.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [FieldCategoriesComponent, FieldCategoriesCreatorComponent],
  imports: [
    CommonModule,
    FormsModule,
    MenuModule,
    ToastModule,
    ButtonModule,
    FlexLayoutModule,
    TooltipModule,
    ReactiveFormsModule,
    DragDropModule,
    ConfirmDialogModule,
  ],
  exports: [FieldCategoriesComponent, FieldCategoriesCreatorComponent],
})
export class FieldCategoriesModule {}
