import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { APP_CONFIG } from '@iapplication2/app-config';
import {
  SignatureRecipientTypeEnum,
  SignatureChanelTypeEnum,
  SignatureTypeEnum,
  Application,
  ENV,
  Severity,
  MessagesModel,
  IApplicationDigitalSignature,
  SignatureStatusEnum,
} from '@iapplication2/interfaces';

import { ApplicationsManagementService, ApplicationsProcessService, ESignatureService } from '@iapplication2/services';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject, combineLatest } from 'rxjs';
import { share, startWith, takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'iapplication2-application-signature-info',
  templateUrl: './application-signature-info.component.html',
  styleUrls: ['./application-signature-info.component.scss'],
  providers: [MessageService],
})
export class ApplicationSignatureComponent implements OnInit {
  private unsubscribe: Subject<unknown> = new Subject<unknown>();

  signatureRecipients: IApplicationDigitalSignature[];
  signatureForm = new FormGroup({});
  signatureTableData: any[];
  ChanelTypeEnum = SignatureChanelTypeEnum;
  SignatureTypeEnum = SignatureTypeEnum;
  signatureLink = '';
  showCopySignatureLink = false;

  translatedSaveSignatureMessages: MessagesModel = {
    success: {
      summary: '',
      detail: '',
    },
    error: {
      summary: '',
      detail: '',
    },
  };

  constructor(
    private eSignatureService: ESignatureService,
    private applicationsManagementService: ApplicationsManagementService,
    private applicationsProcessService: ApplicationsProcessService,
    private messageService: MessageService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) public appConfig: ENV
  ) {}

  ngOnInit(): void {
    this.loadSignaturesList();
    this.listenToLangChanges();
  }

  loadSignaturesList(): void {
    this.eSignatureService
      .getApplicationDigitalSignatures(this.applicationsProcessService.currentApplication.id)
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), share(), debounceTime(300))
      .subscribe((signatures: IApplicationDigitalSignature[]) => {
        this.signatureRecipients = signatures;
        this.buildTableStructure();
        this.initializeFormControls();
        this.setSignatureLinks(signatures);
      });
  }

  repopulateForm() {
    this.eSignatureService
      .getApplicationDigitalSignatures(this.applicationsProcessService.currentApplication.id)
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), share(), debounceTime(300))
      .subscribe((signatures: IApplicationDigitalSignature[]) => {
        this.signatureRecipients = signatures;
        this.buildTableStructure();

        this.signatureRecipients?.forEach((recipient) => {
          this.getFormControl(`signature-${recipient.signatureFor}`).setValue(
            recipient.method.includes(SignatureChanelTypeEnum.VOICESIGNATURE)
              ? SignatureTypeEnum.VOICESIGNATURE
              : recipient.method.includes(SignatureChanelTypeEnum.SMS) || recipient.method.includes(SignatureChanelTypeEnum.EMAIL)
              ? SignatureTypeEnum.ESIGNATURE
              : null
          );
          this.getFormControl(`email-${recipient.signatureFor}`).setValue(recipient.method.includes(SignatureChanelTypeEnum.EMAIL));
          this.getFormControl(`sms-${recipient.signatureFor}`).setValue(recipient.method.includes(SignatureChanelTypeEnum.SMS));
          this.getFormControl(`phoneNumber-${recipient.signatureFor}`).setValue(
            (recipient.method.includes(SignatureChanelTypeEnum.SMS) && recipient.phone) || ''
          );
          this.getFormControl(`emailValue-${recipient.signatureFor}`).setValue(
            (recipient.method.includes(SignatureChanelTypeEnum.EMAIL) && recipient.email) || ''
          );
          this.getFormControl(`fullName-${recipient.signatureFor}`).setValue(recipient.fullName || '');
          this.getFormControl(`notificationSent-${recipient.signatureFor}`).setValue(recipient.status === SignatureStatusEnum.SIGNED);
          this.checkForFormChanges(recipient.signatureFor);
        });
      });
  }

  initializeFormControls() {
    this.signatureRecipients?.forEach((recipient) => {
      this.signatureForm.addControl(
        `signature-${recipient.signatureFor}`,
        new FormControl(
          recipient.method.includes(SignatureChanelTypeEnum.VOICESIGNATURE)
            ? SignatureTypeEnum.VOICESIGNATURE
            : recipient.method.includes(SignatureChanelTypeEnum.SMS) || recipient.method.includes(SignatureChanelTypeEnum.EMAIL)
            ? SignatureTypeEnum.ESIGNATURE
            : null,
          []
        )
      );
      this.signatureForm.addControl(
        `email-${recipient.signatureFor}`,
        new FormControl(
          {
            value: recipient.method.includes(SignatureChanelTypeEnum.EMAIL),
            disabled:
              recipient.status === SignatureStatusEnum.SIGNED ||
              recipient.method.includes(SignatureChanelTypeEnum.VOICESIGNATURE) ||
              !recipient.method,
          },
          []
        )
      );
      this.signatureForm.addControl(
        `sms-${recipient.signatureFor}`,
        new FormControl(
          {
            value: recipient.method.includes(SignatureChanelTypeEnum.SMS),
            disabled:
              recipient.status === SignatureStatusEnum.SIGNED ||
              recipient.method.includes(SignatureChanelTypeEnum.VOICESIGNATURE) ||
              !recipient.method,
          },
          []
        )
      );
      this.signatureForm.addControl(
        `phoneNumber-${recipient.signatureFor}`,
        new FormControl(
          {
            value: (recipient.method.includes(SignatureChanelTypeEnum.SMS) && recipient.phone) || '',
            disabled: recipient.status === SignatureStatusEnum.SIGNED || !recipient.method.includes(SignatureChanelTypeEnum.SMS),
          },
          []
        )
      );
      this.signatureForm.addControl(
        `emailValue-${recipient.signatureFor}`,
        new FormControl(
          {
            value: (recipient.method.includes(SignatureChanelTypeEnum.EMAIL) && recipient.email) || '',
            disabled: recipient.status === SignatureStatusEnum.SIGNED || !recipient.method.includes(SignatureChanelTypeEnum.EMAIL),
          },
          [Validators.email]
        )
      );
      this.signatureForm.addControl(
        `fullName-${recipient.signatureFor}`,
        new FormControl(
          { value: recipient.fullName || '', disabled: recipient.status === SignatureStatusEnum.SIGNED || !recipient.method.length },
          [Validators.required]
        )
      );
      this.signatureForm.addControl(`notificationSent-${recipient.signatureFor}`, new FormControl(false, [Validators.required]));
      this.signatureForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(300), takeUntil(this.unsubscribe)).subscribe(() => {
        this.checkForFormChanges(recipient.signatureFor);
      });
    });
  }

  getTranslatedMessages() {
    this.translateService
      .get('applicationDigitalSignatures.messages')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((messages: any) => {
        this.translatedSaveSignatureMessages.success.summary = messages.sendForSignatureSuccess;
        this.translatedSaveSignatureMessages.error.summary = messages.sendForSignatureError;
      });
  }

  listenToLangChanges() {
    const combinedLangChanges$ = combineLatest([this.translateService.onDefaultLangChange, this.translateService.onLangChange]);
    combinedLangChanges$
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(() => {
          this.getTranslatedMessages();
        })
      )
      .subscribe({
        next: () => {
          this.getTranslatedMessages();
        },
      });
  }

  checkForFormChanges(recipient) {
    const hasInvalidSignatureFields = this.signatureRecipients?.some(
      (recipient) => !this.checkIfSignatureRecipientIsValid(recipient.signatureFor)
    );
    this.eSignatureService.isAppSignatureSubmittedSubject.next(hasInvalidSignatureFields);
    this.checkEmailFields(recipient);
    this.checkSmsFields(recipient);
    this.checkSignatureType(recipient);
  }

  getFormControl(controlName: string) {
    return this.signatureForm?.controls[controlName] as FormControl;
  }
  getFormControlValue(controlName: string) {
    return this.signatureForm?.controls[controlName]?.value;
  }

  checkIfSignatureRecipientIsValid(recipient) {
    if (this.getFormControlValue(`signature-${recipient}`)) {
      if (this.getFormControlValue(`signature-${recipient}`) === SignatureTypeEnum.ESIGNATURE) {
        const fullName = !!this.getFormControlValue(`fullName-${recipient}`);
        const isEmail = !!this.getFormControlValue(`email-${recipient}`);
        const isSMS = !!this.getFormControlValue(`sms-${recipient}`);

        const email = this.getFormControlValue(`emailValue-${recipient}`);
        const phone = this.getFormControlValue(`phoneNumber-${recipient}`);

        const notificationSent = this.getFormControlValue(`notificationSent-${recipient}`);

        const oldRecipientData = this.signatureRecipients.find((signature) => signature.signatureFor === recipient);
        const isFormDirty = (isEmail && oldRecipientData.email !== email) || (isSMS && oldRecipientData.phone !== phone);

        if (!fullName || (!isEmail && !isSMS)) {
          return false;
        }

        if (isEmail && !email) {
          return false;
        }
        if (isSMS && !phone) {
          return false;
        }

        if (!notificationSent && isFormDirty) {
          return false;
        }
      }
      return true; // possibly voice signature
    }
    return false; // no signature type selected
  }

  checkSignatureType(recipient: SignatureRecipientTypeEnum) {
    const emailCheckboxControl = this.getFormControl(`email-${recipient}`);
    const smsCheckboxControl = this.getFormControl(`sms-${recipient}`);
    const fullNameInputControl = this.getFormControl(`fullName-${recipient}`);

    const oldRecipientData = this.signatureRecipients.find((signature) => signature.signatureFor === recipient);
    if (
      this.getFormControlValue(`signature-${recipient}`) === SignatureTypeEnum.VOICESIGNATURE &&
      !oldRecipientData?.method?.includes(SignatureChanelTypeEnum.VOICESIGNATURE)
    ) {
      this.eSignatureService
        .sendForESignature({ signatureFor: recipient, type: [SignatureChanelTypeEnum.VOICESIGNATURE] })
        .pipe(takeUntil(this.unsubscribe), debounceTime(300), share())
        .subscribe(() => {
          this.repopulateForm();
        });
    }

    if (this.getFormControlValue(`signature-${recipient}`) === SignatureTypeEnum.VOICESIGNATURE) {
      this.disableAndResetFields(recipient);
    } else {
      this.checkEmailFields(recipient);
      this.checkSmsFields(recipient);
      this.enableFields([emailCheckboxControl, smsCheckboxControl, fullNameInputControl]);
    }
  }

  checkEmailFields(recipient: SignatureRecipientTypeEnum) {
    const emailInputControl = this.getFormControl(`emailValue-${recipient}`);
    const emailCheckboxValue = !!this.getFormControlValue(`email-${recipient}`);

    if (emailCheckboxValue) {
      emailInputControl.enable({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      emailInputControl.disable({
        onlySelf: true,
        emitEvent: false,
      });
      emailInputControl.setValue('', { emitEvent: false });
      emailInputControl.setErrors(null, { emitEvent: false });
    }
  }

  checkSmsFields(recipient: SignatureRecipientTypeEnum): void {
    const phoneInputControl = this.getFormControl(`phoneNumber-${recipient}`);
    const smsCheckboxValue = !!this.getFormControlValue(`sms-${recipient}`);

    if (smsCheckboxValue) {
      phoneInputControl.enable({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      phoneInputControl.disable({
        onlySelf: true,
        emitEvent: false,
      });
      phoneInputControl.setValue('', { emitEvent: false });
      phoneInputControl.setErrors(null, { emitEvent: false });
    }
  }

  disableAndResetFields(recipient: SignatureRecipientTypeEnum) {
    const emailInputControl = this.getFormControl(`emailValue-${recipient}`);
    const emailCheckboxControl = this.getFormControl(`email-${recipient}`);
    const phoneInputControl = this.getFormControl(`phoneNumber-${recipient}`);
    const smsCheckboxControl = this.getFormControl(`sms-${recipient}`);
    const fullNameInputControl = this.getFormControl(`fullName-${recipient}`);
    emailInputControl.setValue('', { emitEvent: false });
    emailInputControl.disable({ onlySelf: true, emitEvent: false });
    emailInputControl.setErrors(null, { emitEvent: false });

    smsCheckboxControl.setValue(false, { emitEvent: false });
    smsCheckboxControl.disable({ onlySelf: true, emitEvent: false });

    emailCheckboxControl.setValue(false, { emitEvent: false });
    emailCheckboxControl.disable({ onlySelf: true, emitEvent: false });

    phoneInputControl.setValue('', { emitEvent: false });
    phoneInputControl.disable({ onlySelf: true, emitEvent: false });

    fullNameInputControl.disable({ onlySelf: true, emitEvent: false });
  }

  enableFields(fields: FormControl[]) {
    fields.forEach((field) => {
      field.enable({ onlySelf: true, emitEvent: false });
    });
  }

  buildTableStructure() {
    const tableStructure = [];
    this.signatureRecipients?.forEach((signature) => {
      tableStructure.push({
        recipientType: signature.signatureFor,
        signatureTypeSelection: true,
        signatureLinkDeliveryChanel: true,
        sendForSignatureButton: true,
        signatureLink: '',
        showCopySignatureLink: false,
        isSubmitButtonDisabled: false,
      });
    });
    this.signatureTableData = tableStructure;
  }

  sendForSignature(recipientType: SignatureRecipientTypeEnum) {
    const payload = { signatureFor: recipientType };
    const signatureChanel = [];

    if (this.signatureForm.get(`email-${recipientType}`).value) {
      signatureChanel.push(SignatureChanelTypeEnum.EMAIL);
      Object.assign(payload, { email: this.signatureForm.get(`emailValue-${recipientType}`).value });
    }
    if (this.signatureForm.get(`fullName-${recipientType}`).value) {
      Object.assign(payload, { fullName: this.signatureForm.get(`fullName-${recipientType}`).value });
    }
    if (this.signatureForm.get(`sms-${recipientType}`).value) {
      signatureChanel.push(SignatureChanelTypeEnum.SMS);
      Object.assign(payload, { phone: this.signatureForm.get(`phoneNumber-${recipientType}`).value });
    }
    Object.assign(payload, { type: signatureChanel });
    if (this.signatureForm.get(`signature-${recipientType}`).value === SignatureTypeEnum.ESIGNATURE) {
      this.eSignatureService
        .sendForESignature(payload)
        .pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), debounceTime(500), share())
        .subscribe({
          next: (res: { signatureId?: string }) => {
            if (res) {
              this.messageService.add({
                key: 'summary-signature-messages',
                severity: Severity.SUCCESS,
                summary: this.translatedSaveSignatureMessages.success.summary,
                detail: '',
              });
              this.signatureTableData[
                this.signatureTableData.findIndex((signatureData) => signatureData.recipientType === recipientType)
              ].isSubmitButtonDisabled = true;
              this.buildClipboardSignatureLink(recipientType, res.signatureId);
              this.getFormControl(`notificationSent-${recipientType}`).setValue(true);

              this.applicationsManagementService
                .getApplicationById(this.applicationsProcessService.currentApplication.id)
                .subscribe((res) => {
                  this.applicationsProcessService.setCurrentApplication(res as Application);
                });
            }
          },
          error: () => {
            this.messageService.add({
              key: 'summary-signature-messages',
              severity: Severity.ERROR,
              summary: this.translatedSaveSignatureMessages.error.summary,
              detail: '',
            });
          },
        });
    }
  }

  setSignatureLinks(signatures: IApplicationDigitalSignature[]) {
    signatures.forEach((signature) => {
      if (signature.guid) {
        this.buildClipboardSignatureLink(signature.signatureFor, signature.guid);
      }
    });
  }

  buildClipboardSignatureLink(recipient: SignatureRecipientTypeEnum, guid: string) {
    const signatureLink = `${window.location.origin}/signature/${guid}`;
    const idx = this.signatureTableData.findIndex((tableRow) => tableRow.recipientType === recipient);
    this.signatureTableData[idx].showCopySignatureLink = true;
    this.signatureTableData[idx].signatureLink = signatureLink;
  }

  copyToClipboardSignatureLink(recipient) {
    const targetedTableRow = this.signatureTableData.find((row) => row.recipientType === recipient);
    navigator.clipboard.writeText(targetedTableRow.signatureLink);
  }
}
