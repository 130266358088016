/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChipsModule } from 'primeng/chips';
import { FileUploadModule } from 'primeng/fileupload';
import { FieldSettingsModalComponent } from './field-settings-modal.component';
import { FieldDetailsAttachmentComponent } from '../field-details/field-details-attachment/field-details-attachment.component';
import { FieldDetailsGroupComponent } from '../field-details/field-details-group/field-details-group.component';
import { FieldDetailsFieldComponent } from '../field-details/field-details-field/field-details-field.component';
import { FieldDetailsTableComponent } from '../field-details/field-details-table/field-details-table.component';
import { FieldDetailsCategoryComponent } from '../field-details/field-details-category/field-details-category.component';
import { FieldDetailsTypeComponent } from '../field-details/field-details-type/field-details-type.component';
import { FieldDetailsTypeDropdownComponent } from '../field-details/field-details-type/field-details-type-dropdown/field-details-type-dropdown.component';
import { FieldDetailsTypeFreeTextComponent } from '../field-details/field-details-type/field-details-type-free-text/field-details-type-free-text.component';
import { FieldDetailsTypeRadioComponent } from '../field-details/field-details-type/field-details-type-radio/field-details-type-radio.component';
import { FieldDetailsTypeCheckboxComponent } from '../field-details/field-details-type/field-details-type-checkbox/field-details-type-checkbox.component';
import { FieldDetailsTypeNumberComponent } from '../field-details/field-details-type/field-details-type-number/field-details-type-number.component';
import { FieldDetailsTypeDateComponent } from '../field-details/field-details-type/field-details-type-date/field-details-type-date.component';
import { FieldDetailsTypeSignatureComponent } from '../field-details/field-details-type/field-details-type-signature/field-details-type-signature.component';
import { FieldDetailsTypeOptionComponent } from '../field-details/field-details-type/field-details-type-option/field-details-type-option.component';
import { FieldDetailsTypeEntriesComponent } from '../field-details/field-details-type/field-details-type-entries/field-details-type-entries.component';
import { FieldDetailsValidatorComponent } from '../field-details/field-details-validator/field-details-validator.component';
import { FieldDetailsValidatorConfigurationComponent } from '../field-details/field-details-validator/field-details-validator-configuration/field-details-validator-configuration.component';
import { FieldDetailsStaticTextComponent } from '../field-details/field-details-static-text/field-details-static-text.component';
import { FieldDetailsStaticFieldLinkComponent } from '../field-details/field-details-static-field-link/field-details-static-field-link.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { EditorModule } from 'primeng/editor';
import { PipesModule } from '@iapplication2/pipes';
import { RippleModule } from 'primeng/ripple';
import { TreeModule } from 'primeng/tree';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { TreeSelectModule } from 'primeng/treeselect';
import { SharedTranslateModule } from '@iapplication2/shared-translate';
import { FieldDisclosureDetailsComponent } from '../field-details/field-disclosure-details/field-disclosure-details.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SeeMoreModule } from '../../../../ui/src/lib/see-more/see-more.module';

@NgModule({
  declarations: [
    FieldSettingsModalComponent,
    FieldDetailsAttachmentComponent,
    FieldDetailsGroupComponent,
    FieldDetailsFieldComponent,
    FieldDetailsTableComponent,
    FieldDetailsCategoryComponent,
    FieldDetailsTypeComponent,
    FieldDetailsTypeDropdownComponent,
    FieldDetailsTypeFreeTextComponent,
    FieldDetailsTypeRadioComponent,
    FieldDetailsTypeCheckboxComponent,
    FieldDetailsTypeNumberComponent,
    FieldDetailsTypeDateComponent,
    FieldDetailsTypeSignatureComponent,
    FieldDetailsTypeOptionComponent,
    FieldDetailsTypeEntriesComponent,
    FieldDetailsValidatorComponent,
    FieldDetailsValidatorConfigurationComponent,
    FieldDetailsStaticTextComponent,
    FieldDetailsStaticFieldLinkComponent,
    FieldDisclosureDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    DialogModule,
    MenuModule,
    DropdownModule,
    CheckboxModule,
    TooltipModule,
    ReactiveFormsModule,
    ButtonModule,
    OverlayPanelModule,
    SelectButtonModule,
    DragDropModule,
    InputNumberModule,
    ChipsModule,
    FileUploadModule,
    EditorModule,
    PipesModule,
    RippleModule,
    TreeSelectModule,
    SharedTranslateModule,
    TreeModule,
    CascadeSelectModule,
    ConfirmPopupModule,
    InputTextareaModule,
    SeeMoreModule,
  ],
  exports: [
    FieldSettingsModalComponent,
    FieldDetailsAttachmentComponent,
    FieldDetailsGroupComponent,
    FieldDetailsFieldComponent,
    FieldDetailsTableComponent,
    FieldDetailsCategoryComponent,
    FieldDetailsTypeComponent,
    FieldDetailsTypeDropdownComponent,
    FieldDetailsTypeFreeTextComponent,
    FieldDetailsTypeRadioComponent,
    FieldDetailsTypeCheckboxComponent,
    FieldDetailsTypeNumberComponent,
    FieldDetailsTypeDateComponent,
    FieldDetailsTypeSignatureComponent,
    FieldDetailsTypeOptionComponent,
    FieldDetailsTypeEntriesComponent,
    FieldDetailsValidatorComponent,
    FieldDetailsValidatorConfigurationComponent,
    FieldDetailsStaticTextComponent,
    FieldDetailsStaticFieldLinkComponent,
    FieldDisclosureDetailsComponent,
  ],
})
export class FieldSettingsModalModule {}
